export const DomainType = {
  Ticket: 1,
  Person: 2,
  Product: 3,
  Chat: 4,
  Organization: 5
}

export const FieldType = {
  Text: 1,
  Number: 5,
  Decimal: 6,
  Date: 7
}
