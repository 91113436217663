import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import { CreateElement, RenderContext } from 'vue'

dayjs.extend(RelativeTime)

export default {
  name: 'OctaTimeFormat',
  functional: true,
  props: {
    time: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'span'
    },
    short: Boolean,
    withTime: Boolean,
    fromNow: Boolean
  },
  render(h: CreateElement, context: RenderContext) {
    if (context.props.fromNow) {
      return h(context.props.tag, dayjs(context.props.time).fromNow())
    }

    if (context.props.short) {
      return h(
        context.props.tag,
        dayjs(context.props.time).format('DD/MM/YYYY')
      )
    }

    return h(
      context.props.tag,
      dayjs(context.props.time).format('DD/MM/YYYY HH:mm:ss')
    )
  }
}
