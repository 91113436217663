import format from './format'

function timeFormat(date) {
  if (date === undefined) {
    return '-'
  }

  return format(date, 'HH:mm')
}

export default timeFormat
