import {
  MessengerState,
  MessengerFacebookPages,
  MessengerInstagramPage,
  MessengerPageItemToRemove
} from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_AUTHORIZED_PAGES](
    state: MessengerState,
    value: {
      facebookPages: Array<MessengerFacebookPages>
      instagramAccounts: Array<MessengerInstagramPage>
    }
  ) {
    state.authorizedFacebookPages = value && value.facebookPages

    state.authorizedInstagramAccounts = value && value.instagramAccounts
  },
  [types.SET_IS_AUTHORIZED_PAGES_MODAL_OPEN](
    state: MessengerState,
    value: boolean
  ) {
    state.isAuthorizedPagesModalOpen = value
  },
  [types.SET_IS_REMOVE_PAGE_CONFIRMATION_MODAL_OPEN](
    state: MessengerState,
    value: boolean
  ) {
    state.isRemovePageConfirmationModalOpen = value
  },
  [types.SET_IS_INSTAGRAM_APP_SETTINGS_MODAL_OPEN](
    state: MessengerState,
    value: boolean
  ) {
    state.isInstagramAppSettingsModalOpen = value
  },
  [types.SET_PAGE_ITEM_TO_REMOVE](
    state: MessengerState,
    value: MessengerPageItemToRemove
  ) {
    state.pageItemToRemove = value
  },
  [types.SET_FACEBOOK_PAGES](
    state: MessengerState,
    value: Array<MessengerFacebookPages>
  ) {
    state.facebookPages = value
  },
  [types.SET_INSTAGRAM_PAGES](
    state: MessengerState,
    value: Array<MessengerInstagramPage>
  ) {
    state.instagramPages = value
  }
}
