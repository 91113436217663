export default {
  path: '/commerce',
  name: 'commerce',
  redirect: '/commerce/permissions/list',
  component: () => import('@/views/Commerce.vue'),
  children: [
    {
      name: 'commerce-permissions',
      path: 'permissions',
      component: () => import('./permissions/components/Permissions.vue'),
      children: [
        {
          name: 'commerce-permissions-list',
          path: 'list',
          component: () =>
            import('./permissions/components/list/ListConnections.vue')
        },
        {
          name: 'commerce-permissions-connect',
          path: 'connect',
          component: () =>
            import('./permissions/components/connect/PermissionsConnect.vue')
        }
      ]
    },
    {
      name: 'commerce-integrations',
      path: 'integrations',
      component: () => import('./integrations/components/Integrations.vue'),
      children: [
        {
          name: 'commerce-integrations-list',
          path: 'list',
          component: () =>
            import(
              './integrations/components/list-itegrations/ListIntegrationsContainer.vue'
            )
        },
        {
          name: 'commerce-integrations-create',
          path: 'create',
          component: () =>
            import(
              './integrations/components/create-integration/CreateIntegrationContainer.vue'
            )
        }
      ]
    },
    {
      name: 'commerce-catalog',
      path: 'catalog',
      component: () => import('./catalog/components/CatalogContainer.vue')
    },
    {
      name: 'commerce-catalog-products',
      path: 'products',
      component: () => import('./products/components/Products.vue'),
      children: [
        {
          name: 'commerce-catalog-products-product',
          path: 'product/:type/:productId?',
          component: () =>
            import('./products/components/product-form/ProductForm.vue')
        }
      ]
    }
  ]
}
