const isBrowser = browser =>
  navigator &&
  browser &&
  navigator.userAgent &&
  navigator.userAgent.toLowerCase().includes(browser.toLowerCase())

export default {
  isBrowser,
  isFirefox: () => isBrowser('firefox'),
  isMicrosoftEdge: () => isBrowser('edg/'),
  isIOS: () => navigator && /(iPhone|iPod|iPad)/i.test(navigator.platform),
  isMacLike: () =>
    navigator && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
}
