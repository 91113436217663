import { MetaAdsState, MetaAdsWhatsAppNumbers } from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_META_ADS_INITIAL_DATA_FETCH](
    state: MetaAdsState,
    dataFetch: boolean
  ) {
    state.initialDataFetched = dataFetch
  },
  [types.SET_META_ADS_WHATSAPP_NUMBERS](
    state: MetaAdsState,
    whatsAppNumbers: Array<MetaAdsWhatsAppNumbers>
  ) {
    state.whatsAppNumbers = whatsAppNumbers
  },
  [types.SET_META_ADS_ACCESS_TOKEN](state: MetaAdsState, accessToken: string) {
    state.accessToken = accessToken
  }
}
