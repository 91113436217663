import { OnboardingState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_CHANNEL](state: OnboardingState, channel: Record<string, string>) {
    state.channel = channel
  },
  [types.SET_QRCODE_MODAL](state: OnboardingState, qrcodeModal: boolean) {
    state.qrcodeModal = qrcodeModal
  },
  [types.SET_HAS_SELECTED_CHANNEL](
    state: OnboardingState,
    hasSelectedChannel: boolean
  ) {
    state.hasSelectedChannel = hasSelectedChannel
  },
  [types.SET_CURRENT_STEP](state: OnboardingState, currentStep: number) {
    state.currentStep = currentStep
  },
  [types.SET_GROUP_TYPE](state: OnboardingState, groupType: string) {
    state.groupType = groupType
  },
  [types.SET_GROUP_MODAL](state: OnboardingState, groupModal: boolean) {
    state.groupModal = groupModal
  },
  [types.SET_LOADING_ERROR](state: OnboardingState, loadingError: boolean) {
    state.loadingError = loadingError
  },
  [types.SET_MODAL_SEQUENCE](state: OnboardingState, modalSequence: boolean) {
    state.modalSequence = modalSequence
  },
  [types.SET_LOADED_WIDGET](state: OnboardingState, loadedWidget: boolean) {
    state.loadedWidget = loadedWidget
  }
}
