import { ActionContext } from 'vuex'

import dayjs from 'dayjs'

import { PermissionsFacebookService } from '@/modules/Commerce/permissions/permissions-facebook.service'
import { PermissionsSteps } from '@/modules/Commerce/permissions/permissions.enum'
import { PermissionsService } from '@/modules/Commerce/permissions/permissions.service'

import i18n from '@/common/i18n'

import { ChatService } from '@/common/services/chat'
import { FacebookSdkService } from '@/common/services/facebook-sdk.service'

import { RootState } from '@/store/interfaces'

import {
  PermissionsCommerceState,
  WhatsAppNumbers,
  CatalogItem,
  FacebookSelectedAccount
} from './interfaces'
import * as types from './mutation-types'

const getCommerceCatalogsAction = 'commerceCatalogs/getCommerceCatalog'

const permissionsFacebookService = new PermissionsFacebookService()

const permissionsService = new PermissionsService()

const facebookSdkService = FacebookSdkService.getInstance()

export const setCommercePermissionsCurrentStep = (
  context: ActionContext<PermissionsCommerceState, RootState>,
  step: { name: PermissionsSteps; number: number }
) => {
  context.commit(types.SET_COMMERCE_PERMISSIONS_CURRENT_STEP, step)
}

export const getCommerceWppNumbers = async (
  context: ActionContext<PermissionsCommerceState, RootState>
) => {
  const numbers = await permissionsService.getCommerceWppNumbers()

  let businessAccountsId: Array<WhatsAppNumbers> = []

  if (numbers.length) {
    businessAccountsId = numbers.map(number => {
      const wabaAccount = number.customFields?.partnerChannel?.waba_account

      context.dispatch('setCommercePermissionsCurrentStep', {
        name: PermissionsSteps.facebook,
        number: 0
      })

      return {
        id: number.id,
        name: number.name,
        number: number.number,
        businessAccount: {
          id: wabaAccount?.on_behalf_of_business_info?.id,
          name: wabaAccount?.on_behalf_of_business_info?.name
        }
      }
    })
  } else {
    context.dispatch('setCommercePermissionsCurrentStep', {
      name: PermissionsSteps.octaCatalog,
      number: 0
    })
  }

  context.commit(types.SET_COMMERCE_WHATSAPP_NUMBERS, businessAccountsId)

  return businessAccountsId
}

export const setCommerceFacebookPermissionsError = (
  context: ActionContext<PermissionsCommerceState, RootState>,
  isError: boolean
) => context.commit(types.SET_COMMERCE_FACEBOOK_PERMISSIONS_ERROR, isError)

export const setCommerceFacebookTwoFactoryStep = (
  context: ActionContext<PermissionsCommerceState, RootState>,
  payload: { show: boolean; loader: boolean }
) => context.commit(types.SET_COMMERCE_FACEBOOK_TWO_FACTORY_STEP, payload)

export const commerceRequestLoginAndPermissions = async (
  context: ActionContext<PermissionsCommerceState, RootState>
) => {
  interface LoginAndPermissions {
    accessToken: string
    grantedScopes: string
    status: 'unknown' | 'connected'
  }

  const { commerceFacebookTwoFactoryStep } = context.getters

  await facebookSdkService.requestLoginAndPermissions({
    callback: async ({
      grantedScopes,
      accessToken,
      status
    }: LoginAndPermissions) => {
      if (!grantedScopes || status === 'unknown') {
        if (commerceFacebookTwoFactoryStep.show) {
          context.dispatch('setCommerceFacebookPermissionsError', true)
        }

        context.dispatch('setCommerceFacebookTwoFactoryStep', {
          show: true,
          loader: false
        })

        const facebookTokenStepNumber = 1

        context.dispatch('setCommercePermissionsCurrentStep', {
          name: PermissionsSteps.facebookToken,
          number: facebookTokenStepNumber
        })
      } else {
        if (!grantedScopes.includes('business_management')) {
          context.dispatch('setCommerceFacebookPermissionsError', true)

          const facebookStepNumber = 0

          context.dispatch('setCommercePermissionsCurrentStep', {
            name: PermissionsSteps.facebook,
            number: facebookStepNumber
          })
        } else {
          let facebookBusinessAccounts =
            await permissionsFacebookService.getFacebookBusinessAccounts(
              accessToken
            )

          const availablebusinessAccounts = await context.dispatch(
            'getCommerceWppNumbers'
          )

          if (!availablebusinessAccounts.length) {
            facebookBusinessAccounts = []
          }

          context.commit(
            types.SET_COMMERCE_FACEBOOK_BUSINESS_ACCOUNTS,
            facebookBusinessAccounts
          )

          context.commit(types.SET_COMMERCE_FACEBOOK_TOKEN, accessToken)

          const stepNumber = commerceFacebookTwoFactoryStep.show ? 2 : 1

          context.dispatch('setCommercePermissionsCurrentStep', {
            name: PermissionsSteps.catalog,
            number: stepNumber
          })
        }
      }
    },
    scopes: ['commerce']
  })
}

export const setCommerceFacebookSelectedAccount = (
  context: ActionContext<PermissionsCommerceState, RootState>,
  payload: FacebookSelectedAccount
) => {
  context.commit(types.SET_COMMERCE_FACEBOOK_SELECTED_ACCOUNT, payload)
}

export const createCommerceOctaCatalogWithIntegrator = async (
  context: ActionContext<PermissionsCommerceState, RootState>,
  { name, id }: CatalogItem
) => {
  const commerceCatalog =
    context.rootGetters['commerceCatalogs/commerceCatalog']

  const { commerceIsSyncCatalog } = context.getters

  const { commerceFacebookTwoFactoryStep, commerceFacebookSelectedAccount } =
    context.getters

  try {
    const integrationProps = {
      integrationId: id,
      integrationName: 'facebook'
    }

    if (commerceIsSyncCatalog) {
      await permissionsService.linkCommerceOctaCatalogWithIntegrator({
        catalogId: commerceCatalog.id,
        ...integrationProps
      })
    } else {
      await permissionsService.createCommerceOctaCatalog({
        name,
        ...integrationProps
      })
    }

    await context.dispatch(getCommerceCatalogsAction, {}, { root: true })

    const stepNumber = commerceFacebookTwoFactoryStep.show ? 3 : 2

    const stepName = () => {
      if (!commerceFacebookSelectedAccount.whatsAppBusinessAccountId) {
        return PermissionsSteps.connectCatalogToWhatsApp
      }

      return PermissionsSteps.products
    }

    context.dispatch('setCommercePermissionsCurrentStep', {
      name: stepName(),
      number: stepNumber
    })
  } catch {
    throw new Error('Error on set Commerce Catalog')
  }
}

export const setCommerceCreateCatalogError = (
  context: ActionContext<PermissionsCommerceState, RootState>,
  payload: { show: boolean; i18nMessageKey: string }
) => {
  context.commit(types.SET_COMMERCE_CREATE_CATALOG_ERROR, payload)
}

export const createCommerceReadyMessages = async (
  context: ActionContext<PermissionsCommerceState, RootState>
) => {
  const { userLogged } = context.rootGetters

  try {
    const chatService = new ChatService()

    const allMessagesReady: Array<{
      name: string
      comment: string
    }> = i18n.t('commerce.readyMessages') as any

    const readyMessages = allMessagesReady.map(item => ({
      ...item,
      creatorId: userLogged.id,
      permission: 'all-view-edit'
    }))

    for await (const message of readyMessages) {
      await chatService.createReadyMessages(message)
    }
  } catch (err) {
    console.error('Error on create commerce ready messages', err)
  }
}

export const createCommerceOctaCatalog = async (
  context: ActionContext<PermissionsCommerceState, RootState>
) => {
  const commerceCatalogName =
    context.rootGetters['commercePermissions/commerceCatalogName']

  try {
    await permissionsService.createCommerceOctaCatalog({
      name: commerceCatalogName
    })

    await context.dispatch(getCommerceCatalogsAction, {}, { root: true })
  } catch {
    throw new Error('Error on create Octa Commerce Catalog')
  }
}

export const createCommerceFacebookCatalog = async (
  context: ActionContext<PermissionsCommerceState, RootState>
) => {
  const {
    commerceFacebookToken,
    commerceFacebookSelectedAccount: {
      businessAccountId,
      whatsAppBusinessAccountId
    }
  } = context.getters

  const commerceCatalogName =
    context.rootGetters['commercePermissions/commerceCatalogName']

  const { userLogged } = context.rootGetters

  const currentDate = dayjs().format('DD/MM HH:mm[h]')

  const catalogName = `${commerceCatalogName} - ${currentDate} - ${userLogged.subDomain}`

  try {
    const payload = {
      name: catalogName,
      businessAccountId,
      token: commerceFacebookToken
    }

    if (whatsAppBusinessAccountId) {
      Object.assign(payload, { whatsAppBusinessAccountId })
    }

    const { externalId, name } =
      await permissionsFacebookService.createCommerceFacebookCatalog(payload)

    await context.dispatch('createCommerceOctaCatalogWithIntegrator', {
      name,
      businessAccountId,
      id: externalId
    })

    await context.dispatch(getCommerceCatalogsAction, {}, { root: true })
  } catch (err) {
    context.dispatch('dismissAlert', {}, { root: true })

    const messageTypes: { [key: string]: string } = {
      'WhatsApp Business Account Already Linked to Another Product Catalog':
        'linkedCatalogWhatsApp',
      '(OAuthException - #10) Application does not have permission for this action':
        'adminPermission',
      'WABA not found': 'wabaNotFound',
      'User not found': 'userNotFound',
      genericError: 'genericError'
    }

    const payload = {
      show: true,
      i18nMessageKey:
        messageTypes[(err as any).message] || messageTypes['genericError']
    }

    context.dispatch('setCommerceCreateCatalogError', payload)

    throw new Error('Error on create Commerce Catalog')
  }
}
