import { ActionContext } from 'vuex'

import { MessagesService } from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v1/services/messages.service'
import { SpreadsheetService } from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v1/services/spreadsheet.service'
import { WhatsAppIntegratorService } from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v1/services/whatsapp-integrator.service'

import { RootState } from '@/store/interfaces'

import { BulkMessageState } from './interfaces'
import * as types from './mutations-types'

const whatsAppIntegratorService = WhatsAppIntegratorService.getInstance()

const messageIntegratorService = MessagesService.getInstance()

const spreadsheetService = SpreadsheetService.getInstance()
export const setBulkMessagesModalContent = (
  context: ActionContext<BulkMessageState, RootState>,
  value: number
) => {
  context.commit(types.SET_BULK_MESSAGES_MODAL_CONTENT, value)
}

export const setBulkMessagesModalIsOpen = (
  context: ActionContext<BulkMessageState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_BULK_MESSAGES_MODAL_IS_OPEN, value)
}

export const setBulkMessagesConfirm = (
  context: ActionContext<BulkMessageState, RootState>,
  value: any
) => {
  context.commit(types.SET_BULK_MESSAGES_CONFIRM, value)
}

export const setSelectedBulkMessage = (
  context: ActionContext<BulkMessageState, RootState>,
  value: any
) => {
  context.commit(types.SET_SELECTED_BULK_MESSAGE, value)
}

export const getSpreadsheetImporterUrl = () =>
  messageIntegratorService.getSpreadsheetImporterUrl()

export const responseUploadFile = async (
  _context: ActionContext<BulkMessageState, RootState>,
  payload: any
) => {
  return await messageIntegratorService.responseUploadFile(payload)
}

export const responseDonwloadSpreadSheet = async (
  _context: ActionContext<BulkMessageState, RootState>,
  url: string
) => {
  return await spreadsheetService.downloadSpreadsheet(url)
}

export const getWhatsappNumbers = async (
  context: ActionContext<BulkMessageState, RootState>
) => {
  const whatsappOfficialNumbers =
    await whatsAppIntegratorService.getIntegratorWhatsappNumbers()

  context.commit(types.SET_WHATSAPP_OFFICIAL_NUMBERS, whatsappOfficialNumbers)

  return whatsappOfficialNumbers
}

export const getChatIntegrators = async (
  context: ActionContext<BulkMessageState, RootState>
) => {
  const chatIntegrators = await whatsAppIntegratorService.getChatIntegrators()
  context.commit(types.SET_CHAT_INTEGRATORS, chatIntegrators)

  return chatIntegrators
}

export const getApprovedTemplateMessages = async (
  context: ActionContext<BulkMessageState, RootState>,
  payload: { name: string }
) => {
  const { data: approvedTemplateMessages } =
    await messageIntegratorService.retrieveApprovedTemplateMessages(payload)

  context.commit(types.SET_APPROVED_TEMPLATE_MESSAGES, approvedTemplateMessages)

  return approvedTemplateMessages
}

export const setBulkMessagesNumber = (
  context: ActionContext<BulkMessageState, RootState>,
  value: {
    code: string
    customSelect: string
    externalAccountId: string
    integrator: string
    label: string
    number: string
  }
) => {
  context.commit(types.SET_BULK_MESSAGES_NUMBER, value)
}

export const setBulkMessagesData = (
  context: ActionContext<BulkMessageState, RootState>,
  value: any
) => {
  context.commit(types.SET_BULK_MESSAGES_DATA, value)
}

export const retrieveBulkMessageReport = async (
  context: ActionContext<BulkMessageState, RootState>,
  id: string
) => {
  const bulkMessagesReport =
    await messageIntegratorService.retrieveBulkMessageReport(id)

  context.commit(types.SET_BULK_MESSAGES_REPORT, bulkMessagesReport)

  return bulkMessagesReport
}

export const retrieveBulkMessages = async (
  context: ActionContext<BulkMessageState, RootState>,
  { search, status, page }: { search: string; status: string; page: number }
) => {
  context.commit(types.SET_CURRENT_LIST_LOADER, true)

  const { bulkMessages } = context.getters

  const currentBulkMessages = bulkMessages

  context.commit(types.SET_BULK_MESSAGES, [])

  const { items: bulkMessagesList, total } =
    await messageIntegratorService.retrieveBulkMessages({
      search,
      status,
      page
    })

  context.commit(types.SET_CURRENT_LIST_PAGE, page)

  context.commit(types.SET_TOTAL_BULK_MESSAGES, total)

  const payload =
    page === 1
      ? bulkMessagesList
      : [...currentBulkMessages, ...bulkMessagesList]

  context.commit(types.SET_BULK_MESSAGES, payload)

  context.commit(types.SET_CURRENT_LIST_LOADER, false)
}

export const retrieveTemplateMessage = async (
  context: ActionContext<BulkMessageState, RootState>,
  id: any
) => {
  const retrievedTemplateMessage =
    await messageIntegratorService.retrieveTemplateMessage(id)

  context.commit(types.SET_RETRIEVED_TEMPLATE_MESSAGE, retrievedTemplateMessage)

  return retrievedTemplateMessage
}

export const getHasApprovedMessages = async () =>
  messageIntegratorService.getHasApprovedMessages()
