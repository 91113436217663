export default {
  settingsChannelsButtons: {
    whatsapp: {
      sidebarOption: {
        maxTextLength: 500,
        showStepNumber: true
      }
    },
    default: {
      sidebarOption: {
        maxTextLength: 35,
        showStepNumber: false
      }
    }
  }
}
