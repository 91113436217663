import Storage from '@octadesk-tech/storage'

import { BulkMessageState } from './interfaces'

export const bulkMessagesModalContent = ({
  bulkMessagesModalContent
}: BulkMessageState) => bulkMessagesModalContent

export const bulkMessagesModalIsOpen = ({
  bulkMessagesModalIsOpen
}: BulkMessageState) => bulkMessagesModalIsOpen

export const bulkMessagesConfirm = ({
  bulkMessagesConfirm
}: BulkMessageState) => bulkMessagesConfirm

export const whatsappOfficialNumbers = ({
  whatsappOfficialNumbers
}: BulkMessageState) => whatsappOfficialNumbers

export const approvedTemplateMessages = ({
  approvedTemplateMessages
}: BulkMessageState) => approvedTemplateMessages

export const bulkMessages = ({ bulkMessages }: BulkMessageState) => bulkMessages

export const selectedBulkMessage = ({
  selectedBulkMessage
}: BulkMessageState) => selectedBulkMessage

export const bulkMessagesReport = ({ bulkMessagesReport }: BulkMessageState) =>
  bulkMessagesReport

export const retrievedTemplateMessage = ({
  retrievedTemplateMessage
}: BulkMessageState) => retrievedTemplateMessage

export const availableTemplateMessages = ({
  availableTemplateMessages
}: BulkMessageState) => availableTemplateMessages

export const bulkMessagesNumber = ({ bulkMessagesNumber }: BulkMessageState) =>
  bulkMessagesNumber

export const bulkMessagesData = ({ bulkMessagesData }: BulkMessageState) =>
  bulkMessagesData

export const language = () =>
  ((Storage.getItem('language') as string) || 'pt-br').toLowerCase()

export const currentListPage = ({ currentListPage }: BulkMessageState) =>
  currentListPage

export const currentListLoader = ({ currentListLoader }: BulkMessageState) =>
  currentListLoader

export const showBtnLoadMore = ({
  bulkMessages,
  totalBulkMessages
}: BulkMessageState) => bulkMessages.length < totalBulkMessages
