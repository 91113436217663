export * from './commerce'

export * from './ticket'

export * from './commerce-orders'

export * from './commerce-integrations'

export const SET_CHAT = 'SET_CHAT'

export const SET_CHAT_KEY = 'SET_CHAT_KEY'

export const UPDATE_CHAT = 'UPDATE_CONVERSATION_CHAT'

export const SET_MESSAGE_TYPE = 'SET_MESSAGE_TYPE'

export const SET_MESSAGE_TAB = 'SET_MESSAGE_TAB'

export const SET_CHAT_HISTORY = 'SET_CHAT_HISTORY'

export const ADD_ATTACHMENT = 'ADD_ATTACHMENT'

export const IS_UPLOADING_RECORDED_AUDIO = 'IS_UPLOADING_RECORDED_AUDIO'

export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT'

export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS'

export const START_TYPING = 'START_TYPING'

export const STOP_TYPING = 'STOP_TYPING'

export const TYPING_USER = 'TYPING_USER'

export const CREATE_MESSAGE = 'CREATE_MESSAGE'

export const SET_MESSAGE_SENDING = 'SET_MESSAGE_SENDING'

export const SET_MESSAGE_SENDED = 'SET_MESSAGE_SENDED'

export const SET_MESSAGE_ERROR = 'SET_MESSAGE_ERROR'

export const UPDATE_CURRENT_CHAT_PROPERTY = 'UPDATE_CURRENT_CHAT_PROPERTY'

export const STORE_UNSENDEND_COMMENT = 'STORE_UNSENDEND_COMMENT'

export const REMOVE_UNSENDEND_COMMENT = 'REMOVE_UNSENDEND_COMMENT'

export const STORE_UNSENDEND_QUOTEDMESSAGE = 'STORE_UNSENDEND_QUOTEDMESSAGE'

export const REMOVE_UNSENDEND_QUOTEDMESSAGE = 'REMOVE_UNSENDEND_QUOTEDMESSAGE'

export const SET_FOCUSED_MESSAGE_KEY = 'SET_FOCUSED_MESSAGE_KEY'

export const CLEAR_FOCUSED_MESSAGE_KEY = 'CLEAR_FOCUSED_MESSAGE_KEY'

export const SET_IS_ADD_CONTACT_MODAL_OPEN = 'SET_IS_ADD_CONTACT_MODAL_OPEN'

export const SET_SELECTED_CONTACT_TO_ADD = 'SET_SELECTED_CONTACT_TO_ADD'

export const SET_SELECTED_CONTACT_TO_UPDATE = 'SET_SELECTED_CONTACT_TO_UPDATE'

export const SET_EXISTING_CONTACTS = 'SET_EXISTING_CONTACTS'

export const SET_ADD_CONTACT_MODAL_STEP = 'SET_ADD_CONTACT_MODAL_STEP'

export const SET_ADD_CONTACT_LAST_PAYLOAD = 'SET_ADD_CONTACT_LAST_PAYLOAD'

export const SET_UPDATE_CONTACT_LAST_PAYLOAD = 'SET_UPDATE_CONTACT_LAST_PAYLOAD'

export const SET_SELECTED_TEMPLATE_MESSAGE = 'SET_SELECTED_TEMPLATE_MESSAGE'

export const SET_CURRENT_CHAT_PAGINATION = 'SET_CURRENT_CHAT_PAGINATION'

export const SET_CURRENT_CHAT_MESSAGES_PAGE = 'SET_CURRENT_CHAT_MESSAGES_PAGE'

export const SET_CURRENT_CHAT_PAGES_LOADED = 'SET_CURRENT_CHAT_PAGES_LOADED'

export const SET_CURRENT_CHAT_NEW_PAGE_LOADING =
  'SET_CURRENT_CHAT_NEW_PAGE_LOADING'

export const SET_DATE_TO_UPDATE_LAST_MESSAGE_TIMER =
  'SET_DATE_TO_UPDATE_LAST_MESSAGE_TIMER'

export const SET_BUILDING_CHAT_MESSAGE_LIST = 'SET_BUILDING_CHAT_MESSAGE_LIST'

export const CLEAN_CHAT = 'CLEAN_CHAT'

// QUOTE
export const QUOTE_MESSAGE = 'QUOTE_MESSAGE'

export const CANCEL_QUOTE_MESSAGE = 'CANCEL_QUOTE_MESSAGE'

export const SET_MENTIONED_MESSAGE_KEY = 'SET_MENTIONED_MESSAGE_KEY'

export const SET_LIST_MENTIONED_MESSAGE_KEY = 'SET_LIST_MENTIONED_MESSAGE_KEY'

// MACRO
export const MACRO_CREATION_MODAL = 'MACRO_CREATION_MODAL'

export const MACRO_EDIT_MODAL = 'MACRO_EDIT_MODAL'

export const SET_MACRO_CONFIRM_DELETE_MODAL = 'SET_MACRO_CONFIRM_DELETE_MODAL'

export const SET_SHOW_VARIABLES_MODAL = 'SET_SHOW_VARIABLES_MODAL'

export const SET_CATEGORY_CHANGE_MODAL = 'SET_CATEGORY_CHANGE_MODAL'

export const SET_MACROS = 'SET_MACROS'

export const CREATE_MACRO = 'CREATE_MACRO'

export const UPDATE_MACRO = 'UPDATE_MACRO'

export const REMOVE_MACRO = 'REMOVE_MACRO'

export const SELECT_MACRO = 'SELECT_MACRO'

// conversation scroll
export const SET_CONVERSATION_PAGE = 'SET_CONVERSATION_PAGE'

export const SET_SCROLL_BOTTOM = 'SET_SCROLL_BOTTOM'

export const SET_IS_TAGS_POPOVER_OPEN = 'SET_IS_TAGS_POPOVER_OPEN'

export const SET_CURRENT_CHAT_TAGS = 'SET_CURRENT_CHAT_TAGS'

// integrators
export const SET_CURRENT_INTEGRATOR = 'SET_CURRENT_INTEGRATOR'

export const SET_SEND_WINDOW = 'SET_SEND_WINDOW'

export const SET_INTEGRATOR_MESSAGE_ERROR = 'SET_INTEGRATOR_MESSAGE_ERROR'

export const SET_REF_AUTO_FOCUS = 'SET_REF_AUTO_FOCUS'

// tags
export const SET_UPDATED_TAGS_MAP = 'SET_UPDATED_TAGS_MAP'

export const SET_REMOVED_UPDATED_TAGS_MAP = 'SET_REMOVED_UPDATED_TAGS_MAP'

export const SET_AUTOCOMPLETE_PUBLIC_TAGS = 'SET_AUTOCOMPLETE_PUBLIC_TAGS'

// conversation assume
export const SET_SHOW_CONVERSATION_ASSUME_ERROR_MODAL =
  'SET_SHOW_CONVERSATION_ASSUME_ERROR_MODAL'

// fullscreen
export const OPEN_ATTACHMENT_FULLSCREEN = 'OPEN_ATTACHMENT_FULLSCREEN'

export const CLOSE_ATTACHMENT_FULLSCREEN = 'CLOSE_ATTACHMENT_FULLSCREEN'

export const SET_ATTACHMENT_FULLSCREEN_INDEX = 'SET_ATTACHMENT_FULLSCREEN_INDEX'

// person
export const SET_PERSON = 'SET_PERSON'

export const UPDATE_PERSON_PROPERTY = 'UPDATE_PERSON_PROPERTY'

export const SET_CONTACT_STATUS_LIST = 'SET_CONTACT_STATUS_LIST'

//custom fields
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS'

export const SET_ORGANIZATIONS_CUSTOM_FIELDS = 'SET_ORGANIZATIONS_CUSTOM_FIELDS'

export const SET_SELECT_ORGANIZATION_OPTIONS = 'SET_SELECT_ORGANIZATION_OPTIONS'

export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION'

export const SET_DEFAULT_EMAIL_ADDRESS = 'SET_DEFAULT_EMAIL_ADDRESS'

export const SET_CURRENT_CHAT_ASIDE = 'SET_CURRENT_CHAT_ASIDE'

export const SET_CHAT_AI_MODAL_PROPS = 'SET_CHAT_AI_MODAL_PROPS'

export const SET_DROPDOWN_READY_MESSAGES_OPEN =
  'SET_DROPDOWN_READY_MESSAGES_OPEN'
