export enum TemplateCategories {
  Marketing = 'MARKETING',
  Utility = 'UTILITY',
  Authentication = 'AUTHENTICATION'
}

export enum FilterKeys {
  Phone = 'phone',
  StartDate = 'startDate',
  EndDate = 'endDate'
}
