export const SET_SETTINGS = 'octadesk/chat/setSettings'

export const SET_SHOW_AGENTS = 'octadesk/chat/setShowAgents'

export const SET_TITLE = 'octadesk/chat/setTitle'

export const SET_INTRODUCTION = 'octadesk/chat/setIntroduction'

export const SET_UNAVAILABLE_MESSAGE = 'octadesk/chat/setUnavailableMessage'

export const SHOW_SEND_INSTRUCTIONS_MODAL = 'SHOW_SEND_INSTRUCTIONS_MODAL'

export const HIDE_SEND_INSTRUCTIONS_MODAL = 'HIDE_SEND_INSTRUCTIONS_MODAL'

export const SET_EMBED_CODE = 'octadesk/chat/setEmbedCode'

export const INSTRUCTIONS_SENT = 'INSTRUCTIONS_SENT'

export const SET_POSITION = 'octadesk/chat/setPosition'

export const SET_FONT_COLOR_RGBA = 'octadesk/chat/setFontColorRgba'

export const SET_BG_COLOR_RGBA = 'octadesk/chat/setBgColorRgba'

export const SET_LIGHTNESS = 'octadesk/chat/setLightness'
