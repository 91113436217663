import { LanguageTypes } from '@/common/helpers/enums/language'

import { LanguageState } from '@/store/interfaces'

const state: LanguageState = {
  lang: {
    name: LanguageTypes.ptBR,
    display: 'Português'
  }
}

export default state
