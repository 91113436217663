import { ActionContext } from 'vuex'

import {
  DomainType,
  FieldType
} from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v1/template-messages.enum'
import { TemplateMessagesService } from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v1/template-messages.service'

import { RootState, TemplateMessageFieldType } from '@/store/interfaces'

import {
  TemplateMessagesState,
  TemplateMessageModel,
  TemplateMessagePayload,
  TemplateMessageNumber,
  TemplateMessageConfirm,
  TemplateMessageSelectedMessage,
  TemplateMessageItem
} from './interfaces'
import * as types from './mutations-types'

const templateMessagesService = new TemplateMessagesService()

export const setCreateMessageModalIsOpen = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_CREATE_MESSAGE_MODAL_IS_OPEN, value)
}

export const setCreateMessageStep = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: number
) => {
  context.commit(types.SET_CREATE_MESSAGE_STEP, value)
}

export const setCreateMessageModel = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: TemplateMessageModel
) => {
  context.commit(types.SET_CREATE_MESSAGE_MODEL, value)
}

export const setCreateMessagePayload = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: TemplateMessagePayload
) => {
  context.commit(types.SET_CREATE_MESSAGE_PAYLOAD, value)
}

export const setCreateMessageError = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: any
) => {
  context.commit(types.SET_CREATE_MESSAGE_ERROR, value)
}

export const setCreateMessageNumber = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: TemplateMessageNumber
) => {
  context.commit(types.SET_CREATE_MESSAGE_NUMBER, value)
}

export const setCreateMessageSuccess = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: any
) => {
  context.commit(types.SET_CREATE_MESSAGE_SUCCESS, value)
}

export const setCreateMessageConfirm = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: TemplateMessageConfirm
) => {
  context.commit(types.SET_CREATE_MESSAGE_CONFIRM, value)
}

export const setSelectedMessage = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: TemplateMessageSelectedMessage
) => {
  context.commit(types.SET_SELECTED_MESSAGE, value)
}

export const setTemplateMessages = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: TemplateMessageItem
) => {
  context.commit(types.SET_TEMPLATE_MESSAGES, value)
}

export const updateTemplateMessages = async (
  context: ActionContext<TemplateMessagesState, RootState>,
  payload: any
) => {
  context.dispatch('setTemplateMessagesIsLoaded', false)

  try {
    const data = await templateMessagesService.retrieveTemplateMessages(payload)

    if (Array.isArray(data)) {
      context.commit(types.SET_TEMPLATE_MESSAGES, data)
    }
  } catch {
    throw new Error('Error on retrieveTemplateMessages')
  } finally {
    context.dispatch('setTemplateMessagesIsLoaded', true)
  }
}

export const setTemplateMessagesIsLoaded = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_TEMPLATE_MESSAGES_IS_LOADED, value)
}

export const fetchCustomFields = async (
  context: ActionContext<TemplateMessagesState, RootState>
) => {
  const fields = await templateMessagesService.getCustomFields()

  const { isResponsibleContactEnabled } = context.rootGetters

  const personFields = [
    {
      key: 'nome-contato',
      fieldId: 'nome-contato',
      property: 'room.createdBy.name',
      type: 'text',
      domainType: DomainType.Person
    },
    {
      key: 'email-contato',
      fieldId: 'email-contato',
      property: 'room.createdBy.email',
      type: 'text',
      domainType: DomainType.Person
    },
    ...fields.filter(
      (f: TemplateMessageFieldType) => f.domainType === DomainType.Person
    )
  ]

  if (isResponsibleContactEnabled) {
    personFields.splice(2, 0, {
      key: 'responsavel-contato',
      fieldId: 'responsavel-contato',
      property: 'room.createdBy.responsibleContact',
      type: 'text',
      domainType: DomainType.Person
    })
  }

  const chatFields = [
    {
      key: 'id-conversa',
      fieldId: 'id-conversa',
      property: 'room.key',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: true,
      fieldType: FieldType.Text
    },
    {
      key: 'primeira-mensagem-cliente',
      fieldId: 'primeira-mensagem-cliente',
      property: 'room.messages[0].comment',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: true,
      fieldType: FieldType.Text
    },
    {
      key: 'numero-conversa',
      fieldId: 'numero-conversa',
      property: 'room.number',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: true,
      fieldType: FieldType.Text
    },
    {
      key: 'nome-empresa',
      fieldId: 'nome-empresa',
      property: 'room.organization.name',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: false,
      fieldType: FieldType.Text
    },
    {
      key: 'nome-agente',
      fieldId: 'nome-agente',
      property: 'room.agent.name',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: false,
      fieldType: FieldType.Text
    },
    ...fields.filter(
      (f: TemplateMessageFieldType) => f.domainType === DomainType.Chat
    )
  ]

  context.dispatch('setPersonFields', personFields)

  context.dispatch('setChatFields', chatFields)

  return fields
}

export const setPersonFields = (
  context: ActionContext<TemplateMessagesState, RootState>,
  fields: Array<TemplateMessageFieldType>
) => {
  context.commit(types.SET_PERSON_FIELDS, fields)
}

export const setChatFields = (
  context: ActionContext<TemplateMessagesState, RootState>,
  fields: Array<TemplateMessageFieldType>
) => {
  context.commit(types.SET_CHAT_FIELDS, fields)
}

export const createCustomField = async (
  context: ActionContext<TemplateMessagesState, RootState>,
  payload: TemplateMessageFieldType
) => {
  context.commit(types.SET_CREATE_LOADER, true)

  await templateMessagesService.createCustomField(payload)

  await context.dispatch('fetchCustomFields')

  context.commit(types.SET_CREATE_LOADER, false)
}
