import * as actions from './actions'
import * as actionsSocket from './actions/actions-socket'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'
import state from './state'

export default {
  state,
  mutations,
  actions: { ...actions, ...actionsSocket },
  getters,
  modules
}
