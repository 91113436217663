import Vue from 'vue'

import {
  ContactState,
  Contact,
  PayloadToggle,
  Agent,
  ContactStatus,
  IUpdateContactsProperties
} from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_CONTACTS](state: ContactState, contacts: Array<Contact>) {
    state.contacts = contacts
  },
  [types.SET_CONTACT](state: ContactState, contact: Contact) {
    state.contact = contact
  },
  [types.SET_CONTACTS_COUNT](state: ContactState, contactsCounter: number) {
    state.contactsCounter = contactsCounter
  },
  [types.FETCHING_PAGINATED_CONTACTS](state: ContactState, fetching: boolean) {
    state.fetchingPaginatedContacts = fetching
  },
  [types.SET_AGENTS](state: ContactState, agents: Array<Agent>) {
    state.agents = agents
  },
  [types.APPEND_CONTACTS](state: ContactState, contacts: Array<Contact>) {
    state.contacts = state.contacts?.concat(contacts)
  },
  [types.UPDATE_CONTACT](state: ContactState, contact: Contact) {
    state.contacts = state.contacts?.map(con => {
      if (con.id === contact.id) {
        return contact
      }
      return con
    })
  },
  [types.TOGGLE_CONTACT](state: ContactState, payload: PayloadToggle) {
    const { id, toggle } = payload
    state.contacts = state.contacts?.map(cont => {
      if (cont.id === id) {
        return { ...cont, isEnabled: toggle }
      }
      return cont
    })
  },
  [types.SET_CONTACT_STATUS_LIST](
    state: ContactState,
    contactStatusArray: Array<any>
  ) {
    const contactStatusList: Array<ContactStatus> = contactStatusArray.map(
      contactStatus => {
        return {
          idContactStatus: contactStatus.id,
          label: contactStatus.name
        }
      }
    )
    state.contactStatusList = contactStatusList
  },
  [types.SET_GROUP_AGENTS](state: ContactState, agents: Array<Agent>) {
    state.groupAgents = agents
  },
  [types.SET_SUBDOMAIN_TOTAL_CONTACTS](
    state: ContactState,
    totalContacts: number
  ) {
    state.totalContacts = totalContacts
  },
  [types.UPDATE_CONTACTS_PROPERTIES](
    state: ContactState,
    updateContactsProperties: IUpdateContactsProperties
  ) {
    const { contactId, properties } = updateContactsProperties

    const propKeys = Object.keys(properties)
    if (!propKeys.length)
      throw new Error(
        `Missing parameter 'props' in '${types.UPDATE_CONTACTS_PROPERTIES}'`
      )

    const updatePropKeys = (contact: Contact) =>
      propKeys.forEach(key => Vue.set(contact, key, properties[key]))

    if (!contactId)
      return state.contacts?.forEach(contact => updatePropKeys(contact))

    const contact = state.contacts?.find(
      (contact: Contact) => contact.id == contactId
    )
    updatePropKeys(contact)
  }
}
