export default id => ({
  filter: {
    'users._id': id
  },
  remove: [
    'agent._id',
    'messages.mentions._id',
    'channel',
    'status',
    '$or',
    'flux.botId',
    'botInfo.hasStarted',
    'lastMessageUserType',
    'lastMessageTimeExceeded',
    'group._id'
  ]
})
