import * as types from '@/modules/Chat/components/conversation/store/mutations-types/commerce'

export default {
  [types.SET_COMMERCE_CATALOGS_LOADER](state, isLoader) {
    state.commerce.catalogLoader = isLoader
  },
  [types.SET_COMMERCE_CATALOGS](state, catalog) {
    state.commerce.catalog = catalog
  },
  [types.SET_COMMERCE_PRODUCTS](state, items) {
    state.commerce.products.items.push(...items)
  },
  [types.SET_COMMERCE_PRODUCTS_PAGE](state, page) {
    state.commerce.products.page = page
  },
  [types.SET_COMMERCE_ALL_PRODUCTS_LOADED](state, allProductsLoaded) {
    state.commerce.products.allProductsLoaded = allProductsLoaded
  },
  [types.SET_COMMERCE_PRODUCTS_PAGINATE_LOADER](state, paginateLoader) {
    state.commerce.products.paginateLoader = paginateLoader
  },
  [types.SET_COMMERCE_PRODUCT_DETAILS_LOADER](state, isLoader) {
    state.commerce.productDetails.loader = isLoader
  },
  [types.SET_COMMERCE_PRODUCT_DETAILS](state, product) {
    state.commerce.productDetails.product = product
  },
  [types.SET_COMMERCE_PRODUCT_DETAILS_ATTRIBUTES](state, attributes) {
    state.commerce.productDetails.attributes = attributes
  },
  [types.SET_COMMERCE_PRODUCT_DETAILS_VARIATION](state, productVariation) {
    state.commerce.productDetails.productVariation = productVariation
  },
  [types.SET_COMMERCE_PRODUCT_DETAILS_CLICKED_TO_SEND](state, isClicked) {
    state.commerce.productDetails.clickedToSend = isClicked
  },
  [types.SET_COMMERCE_FILTERS_LOADER](state, isLoader) {
    state.commerce.filter.loader = isLoader
  },
  [types.SET_COMMERCE_FILTER_ITEMS](state, filters) {
    state.commerce.filter.items = filters
  },
  [types.SET_COMMERCE_FILTERED_ITEMS](state, products) {
    state.commerce.products.filteredItems = products
  },
  [types.SET_COMMERCE_FILTER_QUERY_STRING](state, term) {
    state.commerce.filter.queryString = term
  },
  [types.SET_COMMERCE_ORDER_BY](state, orderBy) {
    state.commerce.orderBy = orderBy
  },
  [types.SET_COMMERCE_SELECTED_PRODUCTS](state, selectedProducts) {
    state.commerce.selectedProducts = selectedProducts
  },
  [types.SET_COMMERCE_PRODUCTS_SENDED](state, products) {
    state.commerce.productsSended = products
  },
  [types.SET_COMMERCE_CART](state, cart) {
    state.commerce.cart = cart
  },
  [types.SET_COMMERCE_CART_LOADER](state, isLoader) {
    state.commerce.cartLoader = isLoader
  },
  [types.SET_COMMERCE_METHOD_TYPE](state, type) {
    state.commerce.type = type
  },
  [types.UPDATE_CREATE_CART_PRODUCTS](state, products) {
    state.commerce.createCart.products = products
  },
  [types.UPDATE_CHECKOUT_FIELDS](state, checkout) {
    state.commerce.createCart.checkout = checkout
  },
  [types.SET_COMMERCE_CREATE_CART_ORDER](state, order) {
    state.commerce.createCart.order = order
  }
}
