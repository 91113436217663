export default class DataLayerService {
  insertDataLayerEvent(event: any, name = 'GenericEvent') {
    if (!event) {
      throw new Error('missing dataLayer event')
    }

    if (
      typeof window !== 'undefined' &&
      window.dataLayer &&
      window.dataLayer instanceof Array
    ) {
      window.dataLayer.push({ ...event, event: name })
    }
  }
}
