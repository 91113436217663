/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-nested-template-literals */
export default channel => {
  let connections = []

  if (channel !== 'web') {
    connections = [
      {
        id: 'step-0',
        type: 'common',
        parentActionId: '',
        nextActionId: 'initial-greeting',
        connectionRules: []
      }
    ]
  }

  connections = [
    ...connections,
    {
      id: 'step-1',
      type: 'common',
      parentActionId: channel !== 'web' ? 'initial-greeting' : '',
      nextActionId: 'collect-customer-name',
      connectionRules: []
    },
    {
      id: 'step-2',
      type: 'common',
      parentActionId: 'collect-customer-name',
      nextActionId: 'confirm-collected-name',
      connectionRules: []
    },
    {
      id: 'step-3',
      type: 'common',
      parentActionId: 'collect-customer-email',
      nextActionId: 'confirm-collected-email',
      connectionRules: []
    },
    {
      id: 'step-4',
      type: 'common',
      parentActionId: 'confirm-collected-email',
      nextActionId: 'collect-customer-email',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-email-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-5',
      type: 'common',
      parentActionId: 'confirm-collected-name',
      nextActionId: 'closing-message',
      connectionRules: []
    },
    {
      id: 'step-6',
      type: 'common',
      parentActionId: 'confirm-collected-email',
      nextActionId: 'reason-for-conversation',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-email-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-7',
      type: 'fallback',
      parentActionId: 'connect-with-the-support-team',
      nextActionId: 'closing-message',
      connectionRules: []
    },
    {
      id: 'step-8',
      type: 'common',
      parentActionId: 'reason-for-conversation',
      nextActionId: 'connect-with-the-support-team',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['reason-for-conversation-doubts'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-9',
      type: 'common',
      parentActionId: 'reason-for-conversation',
      nextActionId: 'check-if-you-are-already-a-customer',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['reason-for-conversation-purchase'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-10',
      type: 'fallback',
      parentActionId: 'connect-to-the-sales-team',
      nextActionId: 'closing-message',
      connectionRules: []
    },
    {
      id: 'step-11',
      type: 'common',
      parentActionId: 'check-if-you-are-already-a-customer',
      nextActionId: 'connect-to-the-sales-team',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['check-if-you-are-already-a-customer-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-12',
      type: 'common',
      parentActionId: 'check-if-you-are-already-a-customer',
      nextActionId: 'identify-who-the-seller-was',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['check-if-you-are-already-a-customer-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-13',
      type: 'common',
      parentActionId: 'identify-who-the-seller-was',
      nextActionId: 'connect-with-seller-1',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['identify-who-the-seller-was-seller-1'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-14',
      type: 'fallback',
      parentActionId: 'connect-with-seller-1',
      nextActionId: 'closing-message',
      connectionRules: []
    },
    {
      id: 'step-15',
      type: 'common',
      parentActionId: 'identify-who-the-seller-was',
      nextActionId: 'connect-with-seller-2',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['identify-who-the-seller-was-seller-2'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-16',
      type: 'fallback',
      parentActionId: 'connect-with-seller-2',
      nextActionId: 'closing-message',
      connectionRules: []
    },
    {
      id: 'step-17',
      type: 'common',
      parentActionId: 'identify-who-the-seller-was',
      nextActionId: 'connect-with-seller-3',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['identify-who-the-seller-was-seller-3'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-18',
      type: 'fallback',
      parentActionId: 'connect-with-seller-3',
      nextActionId: 'closing-message',
      connectionRules: []
    },
    {
      id: 'step-19',
      type: 'common',
      parentActionId: 'confirm-collected-name',
      nextActionId: 'collect-customer-email',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-name-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-20',
      type: 'common',
      parentActionId: 'confirm-collected-name',
      nextActionId: 'collect-customer-name',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-name-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-21',
      type: 'common',
      parentActionId: 'identify-who-the-seller-was',
      nextActionId: 'connect-to-the-sales-team',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['identify-who-the-seller-was-do-not-remember'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    }
  ]

  return connections
}
