import { ActionContext } from 'vuex'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import { WhatsAppOfficialService } from '@/common/services/whatsapp-official.service'

import { RootState } from '@/store/interfaces'

import * as types from './mutation-types'
import { WhatsAppOfficialState } from './state'

const service = WhatsAppOfficialService.getInstance()

export const getWhatsAppOfficialBalance = async (
  context: ActionContext<WhatsAppOfficialState, RootState>
) => {
  const { balanceLastCheck } = context.getters

  const lastCheckWasOneMinuteAgo =
    balanceLastCheck && dayjs().diff(dayjs(balanceLastCheck), 'minute') < 1

  if (lastCheckWasOneMinuteAgo) {
    return
  }

  context.commit(types.GET_WHATSAPP_OFFICIAL_BALANCE_STARTED)

  try {
    const balancies = await service.getCreditsBalance()
    context.commit(types.GET_WHATSAPP_OFFICIAL_BALANCE_SUCCEED, balancies)
  } catch {
    context.commit(types.GET_WHATSAPP_OFFICIAL_BALANCE_FAILED)
  } finally {
    context.commit(
      types.SET_WHATSAPP_OFFICIAL_LAST_CHECK,
      dayjs().utc().toDate()
    )
  }
}
