import * as actions from './actions'
import * as actionsSocket from './actions-socket'
import * as getters from './getters'
import mutations from './mutations'
import state from './state'

export default {
  state,
  mutations,
  actions: { ...actions, ...actionsSocket },
  getters
}
