export const SET_WHATSAPP_NUMBERS = 'SET_WHATSAPP_NUMBERS'

export const UPDATE_WHATSAPP_NUMBERS_STATUS = 'UPDATE_WHATSAPP_NUMBERS_STATUS'

export const UPDATE_WHATSAPP_NAME = 'UPDATE_WHATSAPP_NAME'

export const SET_NUMBER_STATUS = 'SET_NUMBER_STATUS'

export const REMOVE_WHATSAPP_NUMBER = 'REMOVE_WHATSAPP_NUMBER'

export const SET_COMPANY_MODEL = 'SET_COMPANY_MODEL'
