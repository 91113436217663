import { AxiosInstance } from 'axios'

import { CommerceProduct } from '@/modules/Commerce/interfaces'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import {
  CommerceSingleProduct,
  CommerceAvailableAttributes
} from './store/interfaces'

export class ProductsService {
  getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async getCommerceProduct({
    productId
  }: {
    productId: string
  }): Promise<CommerceSingleProduct> {
    try {
      const { data } = await this.getClient('commerce').then(client =>
        client.get(`/products/${productId}`, {
          ...legacyHeaders()
        })
      )

      return {
        ...data
      }
    } catch (err) {
      throw new Error('Error on get product: ' + err)
    }
  }

  async getCommerceAttributes(): Promise<Array<CommerceAvailableAttributes>> {
    try {
      const { data } = await this.getClient('commerce').then(client =>
        client.get(`/attributes?withValues=true&limit=100`, {
          ...legacyHeaders()
        })
      )

      return data.results
    } catch (err) {
      throw new Error('Error on get attributes: ' + err)
    }
  }

  async createCommerceProduct({
    product,
    catalogId
  }: {
    product: CommerceProduct
    catalogId: string
  }): Promise<CommerceProduct> {
    try {
      const { data } = await this.getClient('commerce').then(client =>
        client.post(`/catalogs/${catalogId}/products`, product, {
          ...legacyHeaders()
        })
      )

      return data
    } catch (err) {
      throw new Error((err as any).response.data.message)
    }
  }

  async updateCommerceProduct(payload: {
    product: CommerceProduct
    productId: string
    catalogId: string
  }): Promise<CommerceProduct> {
    const { product, productId, catalogId } = payload

    try {
      const { data } = await this.getClient('commerce').then(client =>
        client.put(
          `/products/${productId}`,
          { ...product, catalogId },
          {
            ...legacyHeaders()
          }
        )
      )

      return data
    } catch (err) {
      throw new Error('Error on update product: ' + err)
    }
  }

  async uploadCommerceImage({
    file
  }: {
    subDomain: string
    file: any
  }): Promise<string> {
    try {
      const formData = new FormData()

      formData.append('file', file)

      const { data } = await this.getClient('chatUrl').then(client =>
        client.post('/upload', formData, legacyHeaders())
      )

      return data.url
    } catch (err) {
      throw new Error('Error in upload product image: ' + err)
    }
  }
}
