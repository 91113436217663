import { TagsManagerState } from '@/store/interfaces'

export const tags = ({ tags }: TagsManagerState) => tags

export const searchFilter = ({ searchFilter }: TagsManagerState) => searchFilter

export const activeTagsCount = ({ tagsCounts }: TagsManagerState) =>
  tagsCounts.active

export const archivedTagsCount = ({ tagsCounts }: TagsManagerState) =>
  tagsCounts.archived

export const newTag = ({ newTag }: TagsManagerState) => newTag

export const tagsConfirmAction = ({ tagsConfirmAction }: TagsManagerState) =>
  tagsConfirmAction
