export const overwriteObject = (
  target: Record<string, unknown>,
  source: Record<string, unknown>,
  strict = true
) => {
  Object.keys(source).forEach(prop => {
    if (strict && target[prop] === undefined) {
      return
    }
    target[prop] = source[prop]
  })
}
