import types from '@/modules/Bot/store/mutations-types'

const configurationMutations = {
  [types.ADD_PAGE_CONDITION](state, pageCondition) {
    state.currentChatBotFlux.conditions = [
      ...state.currentChatBotFlux.conditions,
      pageCondition
    ]
  },
  [types.REMOVE_PAGE_CONDITION](state, conditionToRemove) {
    const index = state.currentChatBotFlux.conditions.findIndex(
      condition => condition.id === conditionToRemove.id
    )

    if (index >= 0) {
      state.currentChatBotFlux.conditions.splice(index, 1)
    }
  },
  [types.SET_CURRENT_CONDITIONS_VIEW](state, view) {
    state.currentConditionsView = view
  }
}

const botFluxMutations = {
  [types.SET_CURRENT_CHATBOT_FLUX](state, chatBotFlux) {
    if (chatBotFlux) {
      state.currentChatBotFlux = {
        ...chatBotFlux
      }

      state.currentChatBotFluxChannel = chatBotFlux.channel || 'web'
    } else {
      state.currentChatBotFlux = null

      state.currentChatBotFluxChannel = null
    }
  },
  [types.SET_CHATBOTS_FLUXES](state, chatBotFluxes) {
    state.chatBotFluxes = chatBotFluxes
  },
  [types.UPSERT_CHATBOT_FLUX](state, chatBotFlux) {
    if (chatBotFlux) {
      const index = state.chatBotFluxes.findIndex(
        flux => flux.id === chatBotFlux.id
      )

      if (index >= 0) {
        state.chatBotFluxes.splice(index, 1, chatBotFlux)
      } else {
        state.chatBotFluxes = [...state.chatBotFluxes, chatBotFlux]
      }
    }
  },
  [types.DELETE_BOT_FLUX](state, botId) {
    const index = state.chatBotFluxes.findIndex(flux => flux.id === botId)

    if (index >= 0) {
      state.chatBotFluxes.splice(index, 1)
    }
  },
  [types.SORT_BOT_FLUXES](state, fluxes) {
    state.chatBotFluxes = fluxes
  },
  [types.ADD_BOT_FLUX](state, botFlux) {
    state.chatBotFluxes.push(botFlux)
  },
  [types.ADD_BOT_FLUX_AFTER](state, request) {
    const index = state.chatBotFluxes.findIndex(f => f.id === request.fluxId)

    state.chatBotFluxes.splice(index + 1, 0, request.newFlux)
  }
}

export default {
  ...configurationMutations,
  ...botFluxMutations
}
