import { ActionContext } from 'vuex'

import {
  RootState,
  ReminderState,
  ReminderAlert,
  Reminder
} from '@/store/interfaces'

import * as types from './mutations-types'
export const dispatchReminderAlert = (
  context: ActionContext<ReminderState, RootState>,
  payload: Partial<ReminderAlert>
) => {
  const reminderAlert = {
    id: Math.floor(Math.random() * 1000),
    props: {
      dismissible: true,
      variant: 'warning'
    },
    ...payload
  }
  reminderAlert.dismiss = () => {
    if (typeof payload.dismiss === 'function') {
      payload.dismiss()
    }

    context.dispatch('dismissReminderAlert', reminderAlert)
  }

  context.commit(types.PUSH_REMINDER_ALERT, reminderAlert)

  return reminderAlert
}

export const dismissReminderAlert = (
  context: ActionContext<ReminderState, RootState>,
  payload: Partial<ReminderAlert>
) => {
  context.commit(types.REMOVE_REMINDER_ALERT, payload)
}
export const clearReminderAlerts = (
  context: ActionContext<ReminderState, RootState>
) => {
  context.commit(types.CLEAR_REMINDER_ALERTS)
}

export const socketNotifyReminder = (
  context: ActionContext<ReminderState, RootState>,
  reminder: Reminder
) => {
  context.dispatch('dispatchReminderAlert', {
    body: { comment: reminder?.comment, date: reminder?.date },
    id: reminder?.relation?.id
  })
}
