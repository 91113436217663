import { FormSteps } from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/bulk-messages.enum'

import { BulkMessageState, FormPayload, SuccessModal } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_INITIAL_DATA_LOADER](state: BulkMessageState, isLoader: boolean) {
    state.initialData.loader = isLoader
  },
  [types.SET_INITIAL_DATA_ERROR](state: BulkMessageState, hasError: any) {
    state.initialData.hasError = hasError
  },
  [types.SET_WHATSAPP_OFFICIAL_NUMBERS](state: BulkMessageState, value: any) {
    state.whatsappOfficialNumbers = value
  },
  [types.SET_APPROVED_TEMPLATE_MESSAGES](state: BulkMessageState, value: any) {
    state.approvedTemplateMessages = value
  },
  [types.SET_BULK_MESSAGES](state: BulkMessageState, value: any) {
    state.bulkMessages = value
  },
  [types.SET_SELECTED_BULK_MESSAGE](state: BulkMessageState, value: any) {
    state.selectedBulkMessage = value
  },
  [types.SET_BULK_MESSAGES_REPORT](state: BulkMessageState, value: any) {
    state.bulkMessagesReport = value
  },
  [types.SET_RETRIEVED_TEMPLATE_MESSAGE](state: BulkMessageState, value: any) {
    state.retrievedTemplateMessage = value
  },
  [types.SET_CURRENT_LIST_PAGE](state: BulkMessageState, page: number) {
    state.currentListPage = page
  },
  [types.SET_TOTAL_BULK_MESSAGES](state: BulkMessageState, total: number) {
    state.totalBulkMessages = total
  },
  [types.SET_CURRENT_LIST_LOADER](state: BulkMessageState, isLoader: boolean) {
    state.currentListLoader = isLoader
  },
  [types.SET_TEMPLATE_MESSAGE_STEP](state: BulkMessageState, step: FormSteps) {
    state.bulkMessage.step = step
  },
  [types.SET_BULK_MESSAGE_INITIAL_FORM_PAYLOAD](
    state: BulkMessageState,
    initialFormPayload: FormPayload
  ) {
    state.bulkMessage.initialFormPayload = initialFormPayload
  },
  [types.SET_BULK_MESSAGE_FORM_PAYLOAD](
    state: BulkMessageState,
    formPayload: FormPayload
  ) {
    state.bulkMessage.formPayload = { ...formPayload }
  },
  [types.SET_IS_SPREAD_SHEET_EMPTY](
    state: BulkMessageState,
    isSpreadSheetEmpty: boolean
  ) {
    state.bulkMessage.isSpreadSheetEmpty = isSpreadSheetEmpty
  },
  [types.SET_TEMPLATE_BULK_SHOW_DISCARD_CONTENT_ALERT](
    state: BulkMessageState,
    showAlert: boolean
  ) {
    state.bulkMessage.showDiscardContentAlert = showAlert
  },
  [types.SET_TEMPLATE_MESSAGE_SHOW_REVIEW](
    state: BulkMessageState,
    showReview: boolean
  ) {
    state.bulkMessage.showReviewModal = showReview
  },
  [types.SET_TEMPLATE_MESSAGE_SUCCESS_MODAL](
    state: BulkMessageState,
    payload: SuccessModal
  ) {
    state.bulkMessage.successModal = payload
  }
}
