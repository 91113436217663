import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import i18n from '@/common/i18n'

import { DomainType } from '@/common/helpers/enums/domain-type.enum'
import { FieldAreaType } from '@/common/helpers/enums/field-area-type.enum'
import { FieldTypes } from '@/common/helpers/enums/field-types.enum'
import { OperatorTypes } from '@/common/helpers/enums/operator-types.enum'
import { EventHandler } from '@/common/helpers/interfaces/event-handler'
import { FilterField } from '@/common/helpers/interfaces/field'

import legacyHeaders from '@/common/services/legacy-headers'

const EventHandlerEvent = 'EventHandler.Event'
const labelEvent = 'event'
const CONTACT_FIELDS: FilterField[] = [
  {
    fieldId: 'Name',
    labelCode: 'name',
    operators: [
      OperatorTypes.EQUALS,
      OperatorTypes.CONTAINS,
      OperatorTypes.NOT_CONTAINS
    ],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.TEXT,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true
  },
  {
    fieldId: 'Email',
    labelCode: 'email',
    operators: [
      OperatorTypes.EQUALS,
      OperatorTypes.CONTAINS,
      OperatorTypes.NOT_CONTAINS
    ],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.TEXT,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true
  },
  {
    fieldId: 'PhoneContacts.Number',
    labelCode: 'phone-contacts-number',
    operators: [
      OperatorTypes.EQUALS,
      OperatorTypes.IS_NOT_EQUALS,
      OperatorTypes.CONTAINS,
      OperatorTypes.NOT_CONTAINS
    ],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.NUMBER,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true
  },
  {
    fieldId: 'PhoneContacts.CountryCode',
    labelCode: 'phone-contacts-country-code',
    operators: [
      OperatorTypes.EQUALS,
      OperatorTypes.IS_NOT_EQUALS,
      OperatorTypes.CONTAINS,
      OperatorTypes.NOT_CONTAINS
    ],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.NUMBER,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true
  },
  {
    fieldId: 'Responsible.Id',
    labelCode: 'contact-responsible',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'agents'
  },
  {
    fieldId: 'Organization.Id',
    labelCode: 'contact-organization',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'organizations'
  },
  {
    fieldId: EventHandlerEvent,
    labelCode: labelEvent,
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'contactsEvents'
  }
]

const CHAT_FIELDS: FilterField[] = [
  {
    fieldId: EventHandlerEvent,
    labelCode: labelEvent,
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'roomEvents'
  },
  {
    fieldId: 'Channel',
    labelCode: 'channel',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'roomChannels'
  },
  {
    fieldId: 'Status',
    labelCode: 'room-status',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'roomStatus'
  },
  {
    fieldId: 'WithBot',
    labelCode: 'room-with-bot',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.RADIO,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true
  },
  {
    fieldId: 'Group.Id',
    labelCode: 'room-group',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'chatGroups'
  },
  {
    fieldId: 'Agent.Id',
    labelCode: 'room-agent',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'agents'
  },
  {
    fieldId: 'WaitingTime',
    labelCode: 'room-waiting-time-seconds',
    operators: [
      OperatorTypes.EQUALS,
      OperatorTypes.GREATER_THAN,
      OperatorTypes.LESS_THAN
    ],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.NUMBER,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true
  },
  {
    fieldId: 'MessagesCount',
    labelCode: 'room-messages-count',
    operators: [
      OperatorTypes.EQUALS,
      OperatorTypes.GREATER_THAN,
      OperatorTypes.LESS_THAN
    ],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.NUMBER,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true
  },
  {
    fieldId: 'PublicTags.Id',
    labelCode: 'room-tags',
    operators: [OperatorTypes.CONTAINS, OperatorTypes.NOT_CONTAINS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: false,
    dataSource: 'chatPublicTags'
  },
  {
    fieldId: 'LastMessageUserType',
    labelCode: 'room-last-message-user-type',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: false,
    dataSource: 'personType'
  },
  {
    fieldId: 'Messages[-1].Origin',
    labelCode: 'room-last-message-origin',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: false,
    dataSource: 'messageOrigin'
  }
]

const TICKET_FIELDS: FilterField[] = [
  {
    fieldId: EventHandlerEvent,
    labelCode: labelEvent,
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'ticketEvents'
  },
  {
    fieldId: 'Number',
    labelCode: 'number',
    operators: [
      OperatorTypes.EQUALS,
      OperatorTypes.IS_NOT_EQUALS,
      OperatorTypes.GREATER_THAN,
      OperatorTypes.LESS_THAN
    ],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.NUMBER,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true
  },
  {
    fieldId: 'IdCurrentStatus',
    labelCode: 'ticket-status',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'ticketStatus'
  },
  {
    fieldId: 'IdPriority',
    labelCode: 'ticket-priority',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'ticketPriorities'
  },
  {
    fieldId: 'IdType',
    labelCode: 'ticket-type',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'ticketTypes'
  },
  {
    fieldId: 'IdChannel',
    labelCode: 'ticket-channel',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'ticketChannels'
  },
  {
    fieldId: 'IdAssigned',
    labelCode: 'ticket-agent',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'agents'
  },
  {
    fieldId: 'IdGroupAssigned',
    labelCode: 'ticket-group',
    operators: [OperatorTypes.EQUALS, OperatorTypes.IS_NOT_EQUALS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'groups'
  },
  {
    fieldId: 'Tags',
    labelCode: 'ticket-tags',
    operators: [OperatorTypes.CONTAINS, OperatorTypes.NOT_CONTAINS],
    area: FieldAreaType.EVENT_HANDLER,
    fieldType: FieldTypes.STORAGE_LIST,
    isValuePropertyCompare: true,
    dataSourceItemEmpty: true,
    dataSource: 'ticketTags'
  }
]

const DOMAIN_FIELDS: Record<number, FilterField[]> = {
  [DomainType.Chat]: CHAT_FIELDS,
  [DomainType.Ticket]: TICKET_FIELDS,
  [DomainType.Contact]: CONTACT_FIELDS
}

let _client: AxiosInstance

const getClient = async (): Promise<AxiosInstance> => {
  if (_client) {
    return _client
  }

  _client = (await services.createClient('eventHandlers')) as AxiosInstance

  return _client
}

export const createEventHandler = (eventHandler: EventHandler) =>
  getClient().then(client =>
    client
      .post<EventHandler>('', eventHandler, legacyHeaders())
      .then(res => res.data)
  )

export const updateEventHandler = (eventHandler: EventHandler) =>
  getClient().then(client =>
    client
      .put<EventHandler>(`/${eventHandler.id}`, eventHandler, legacyHeaders())
      .then(res => res.data)
  )

export const getEventHandler = (eventHandlerId: string) =>
  getClient().then(client =>
    client
      .get<EventHandler>(`/${eventHandlerId}`, legacyHeaders())
      .then(res => res.data)
  )

export const disableEventHandler = (eventHandlerId: string) =>
  getClient()
    .then(client =>
      client.patch<EventHandler>(
        `/${eventHandlerId}/disable`,
        '',
        legacyHeaders()
      )
    )
    .then(res => res.data)

export const enableEventHandler = (eventHandlerId: string) =>
  getClient()
    .then(client =>
      client.patch<EventHandler>(
        `/${eventHandlerId}/enable`,
        '',
        legacyHeaders()
      )
    )
    .then(res => res.data)

export const getEventHandlers = () =>
  getClient().then(client =>
    client.get<EventHandler[]>('', legacyHeaders()).then(res => res.data)
  )

export const getDomainFields = (
  domainType: DomainType
): Promise<FilterField[]> =>
  Promise.resolve(
    DOMAIN_FIELDS[domainType].map(f => ({
      ...f,
      label: i18n?.t(`fields.label.${f.labelCode}`) as string
    }))
  )
