import i18n from '@/common/i18n'

import { DataSourceResolver } from './interfaces'

export const roomEvents: DataSourceResolver = () =>
  Promise.resolve([
    {
      id: 'after-close',
      name: i18n?.t('dataSources.roomEvents.room-after-close') as string
    },
    {
      id: 'after-set-agent',
      name: i18n?.t('dataSources.roomEvents.room-after-set-agent') as string
    },
    {
      id: 'after-change-status',
      name: i18n?.t('dataSources.roomEvents.room-after-change-status') as string
    },
    {
      id: 'after-insert-message',
      name: i18n?.t(
        'dataSources.roomEvents.room-after-insert-message'
      ) as string
    },
    {
      id: 'after-insert-automatic-message',
      name: i18n?.t(
        'dataSources.roomEvents.room-after-insert-automatic-message'
      ) as string
    }
  ])
