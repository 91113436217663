import { getPrefixedDomain } from '@/common/helpers/utils/get-prefixed-domain'

export const getCookie = (name: string) => {
  if (typeof window !== 'undefined') {
    const value = '; ' + window.document.cookie

    const parts = value.split('; ' + name + '=').pop()

    return parts?.split(';')?.shift()
  }
}

export const setCookie = (name: string, value: string, fromIframe = false) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const domain =
      import.meta.env.MODE === 'production'
        ? `${getPrefixedDomain()}.com`
        : 'localhost'

    const cookie = `${name}=${value};path=/;domain=${domain}`

    if (!fromIframe) document.cookie = `${cookie};SameSite=None; Secure`
    else document.cookie = cookie
  }
}
