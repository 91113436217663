import { headers } from '@octadesk-tech/services'

import { getBaseClient } from '@/modules/Bot/services/http'

let _client

export const getClient = async () => {
  if (_client) {
    return _client
  }

  _client = await getBaseClient('calendar', headers.getAuthorizedHeaders())

  _client.defaults.baseURL += '/api/v1/office-calendar/'

  return _client
}

export const createNewOfficeHours = data =>
  getClient()
    .then(client => client.post('', data))
    .then(res => res.data)

export const updateOfficeHours = data =>
  getClient()
    .then(client => client.put('', data))
    .then(res => res.data)

export const getListOfficeHours = () =>
  getClient()
    .then(client => client.get(''))
    .then(res => res.data)

export const getOfficeHourById = officeHoursId =>
  getClient()
    .then(client => client.get(`/${officeHoursId}`))
    .then(res => res.data)

export const deleteOfficeHours = officeHoursId =>
  getClient()
    .then(client => client.delete(`/${officeHoursId}`))
    .then(res => res.data)

export const validateOfficeHourName = officeHourName =>
  getClient()
    .then(client =>
      client.get(`/is-available`, {
        params: { name: escape(officeHourName) }
      })
    )
    .then(res => res.data)

export const timezonesList = ({ culture }) =>
  getClient()
    .then(client =>
      client.get(`/timezones`, {
        headers: { culture }
      })
    )
    .then(res => res.data)
