import { headers } from '@octadesk-tech/services'

import { getBaseClient } from '@/modules/Bot/services/http'

const getMultiChannelClient = () => getBaseClient('multiChannel')

export const instagramRetrievePages = async subDomain =>
  getMultiChannelClient().then(client => {
    return client.get('/api/instagram/business-account', {
      params: {
        subDomain
      },
      ...headers.getAuthorizedHeaders()
    })
  })

export const retrievePages = subDomain =>
  getMultiChannelClient().then(client =>
    client.get(`/accounts/${subDomain}`, headers.getAuthorizedHeaders())
  )

export const retrievePagesToAutocomplete = AppSubDomain =>
  getMultiChannelClient().then(client =>
    client.get('/api/facebook/FilterPages', {
      params: {
        term: ''
      },
      headers: {
        ...headers.getAuthorizedHeaders().headers,
        AppSubDomain
      }
    })
  )
