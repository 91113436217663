import { EVENTS } from '@/common/helpers/enums/events.enum'

import { NEW_PRODUCT_ROLLBACK_TRACKING } from './new-product-rollback'
import { SEND_FEEDBACK_TRACKING } from './send-feedback'
import { USER_ADD_PAGEVIEW } from './user-add-pageview'

export const trackingHandlers = {
  [EVENTS.NEW_PRODUCT_ROLLBACK]: NEW_PRODUCT_ROLLBACK_TRACKING,
  [EVENTS.SEND_FEEDBACK]: SEND_FEEDBACK_TRACKING,
  [EVENTS.USER_ADD_PAGEVIEW]: USER_ADD_PAGEVIEW
}
