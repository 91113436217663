export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const SET_ORGANIZATIONS_COUNT = 'SET_ORGANIZATIONS_COUNT'
export const SET_ORGANIZATION_MEMBERS = 'SET_ORGANIZATION_MEMBERS'
export const APPEND_ORGANIZATION_MEMBERS = 'APPEND_ORGANIZATION_MEMBERS'
export const SET_ORGANIZATION_MEMBERS_COUNT = 'SET_ORGANIZATION_MEMBERS_COUNT'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const REMOVE_ORGANIZATION = 'REMOVE_ORGANIZATION'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const ENABLE_ORGANIZATIONS = 'ENABLE_ORGANIZATIONS'
export const DISABLE_ORGANIZATIONS = 'DISABLE_ORGANIZATIONS'
export const TOGGLE_ORGANIZATION = 'TOGGLE_ORGANIZATION'
export const APPEND_ORGANIZATIONS = 'APPEND_ORGANIZATIONS'
