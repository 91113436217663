import { TokensApiState, Token } from './interfaces'

export const tokens = ({ tokens }: TokensApiState): Token[] => tokens

export const isLoading = ({ tokensLoading }: TokensApiState): boolean =>
  tokensLoading

export const hasError = ({ tokensError }: TokensApiState): boolean =>
  tokensError

export const editToken = ({ editToken }: TokensApiState): Token | object =>
  editToken

export const removeToken = ({ removeToken }: TokensApiState): Token | object =>
  removeToken
