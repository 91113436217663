import { getClient } from './http'
import legacyHeaders from './legacy-headers'

const API_NAME = 'chatUrl'

export default class FluxesService {
  getClient() {
    return getClient(API_NAME)
  }

  getFlux(fluxId: string) {
    return this.getClient()
      .then(client => client.get(`flux/${fluxId}`, legacyHeaders()))
      .then(res => res.data)
  }

  async updateFlux(flux: any) {
    const client = await this.getClient()
    return await client.put(`flux/${flux.id}`, flux, legacyHeaders())
  }
}
