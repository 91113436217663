import { BulkMessageState } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_BULK_MESSAGES_MODAL_CONTENT](
    state: BulkMessageState,
    value: number
  ) {
    state.bulkMessagesModalContent = value
  },
  [types.SET_BULK_MESSAGES_MODAL_IS_OPEN](
    state: BulkMessageState,
    value: boolean
  ) {
    state.bulkMessagesModalIsOpen = value
  },
  [types.SET_WHATSAPP_OFFICIAL_NUMBERS](state: BulkMessageState, value: any) {
    state.whatsappOfficialNumbers = value
  },
  [types.SET_APPROVED_TEMPLATE_MESSAGES](state: BulkMessageState, value: any) {
    state.approvedTemplateMessages = value
  },
  [types.SET_BULK_MESSAGES](state: BulkMessageState, value: any) {
    state.bulkMessages = value
  },
  [types.SET_BULK_MESSAGES_DATA](state: BulkMessageState, value: any) {
    state.bulkMessagesData = value
  },
  [types.SET_SELECTED_BULK_MESSAGE](state: BulkMessageState, value: any) {
    state.selectedBulkMessage = value
  },
  [types.SET_BULK_MESSAGES_REPORT](state: BulkMessageState, value: any) {
    state.bulkMessagesReport = value
  },
  [types.SET_BULK_MESSAGES_CONFIRM](state: BulkMessageState, value: any) {
    state.bulkMessagesConfirm = value
  },
  [types.SET_RETRIEVED_TEMPLATE_MESSAGE](state: BulkMessageState, value: any) {
    state.retrievedTemplateMessage = value
  },
  [types.SET_BULK_MESSAGES_NUMBER](state: BulkMessageState, value: any) {
    state.bulkMessagesNumber = value
  },
  [types.SET_CHAT_INTEGRATORS](state: BulkMessageState, value: any) {
    state.chatIntegrators = value
  },
  [types.SET_CURRENT_LIST_PAGE](state: BulkMessageState, page: number) {
    state.currentListPage = page
  },
  [types.SET_TOTAL_BULK_MESSAGES](state: BulkMessageState, total: number) {
    state.totalBulkMessages = total
  },
  [types.SET_CURRENT_LIST_LOADER](state: BulkMessageState, isLoader: boolean) {
    state.currentListLoader = isLoader
  }
}
