export const commerceClientOrdersLoader = ({ commerce }) =>
  commerce.orders.loader

export const commerceClientOrderProductsLoader = ({ commerce }) =>
  commerce.orders.productsLoader

export const commerceClientOrders = ({ commerce }) => commerce.orders.items

export const commerceClientOrdersNotFound = ({ commerce }) =>
  commerce.orders.clientNotFound

export const commerceClientPaymentUrl = ({ commerce }) =>
  commerce.orders.paymentUrl
