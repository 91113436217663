import { BspName } from '@/modules/ChatSettings/whatsapp-oficial/numbers/whatsapp.enum'

import { WhatsAppState } from './interfaces'

export * from './modules/getters'

export const whatsappOfficialNumbers = ({
  whatsappOfficialNumbers
}: WhatsAppState) => whatsappOfficialNumbers

export const dialog360WhatsAppNumbers = ({
  whatsappOfficialNumbers
}: WhatsAppState) =>
  whatsappOfficialNumbers.filter(
    number => number.integrator === BspName['360_DIALOG']
  )

export const companyModelFeatures = ({ companyModel }: WhatsAppState) =>
  companyModel?.company?.features

export const isWppActiveNumber = ({ isWppActiveNumber }: WhatsAppState) =>
  isWppActiveNumber

export const migrationNumber = ({ migration: { number } }: WhatsAppState) =>
  number

export const migrationNumberValidationData = ({
  migration: { numberValidationData }
}: WhatsAppState) => numberValidationData

export const migrationPhoneNumberId = ({
  migration: { phoneNumberId }
}: WhatsAppState) => phoneNumberId
