import { WozPreferencesState } from './interfaces'

export const wozPreferencesLoading = (state: WozPreferencesState) =>
  state.fetchLoading

export const wozPreferencesError = (state: WozPreferencesState) =>
  state.fetchError

export const wozPreferences = (state: WozPreferencesState) => state.preferences

export const wozTranscriptPreferences = (state: WozPreferencesState) =>
  state.preferences.transcript

export const wozAgentPreferences = (state: WozPreferencesState) =>
  state.preferences.agent

export const wozSummaryPreferences = (state: WozPreferencesState) =>
  state.preferences.summary
