import i18n from '@/common/i18n'

import { DataSourceResolver } from './interfaces'

export const personType: DataSourceResolver = () =>
  Promise.resolve([
    {
      id: '1',
      name: i18n?.t('dataSources.personTypes.agent') as string
    },
    {
      id: '2',
      name: i18n?.t('dataSources.personTypes.contact') as string
    },
    {
      id: '4',
      name: i18n?.t('dataSources.personTypes.system') as string
    }
  ])
