export enum ProductsColors {
  WHATSAPP = '#86DFA7',
  WOZ_COPILOT = '#BDBFF0',
  WOZ_TRANSCRIPT = '#F8A0C2',
  DEFAULT = '#1366c9'
}

export enum FilterKeys {
  Product = 'product',
  Phone = 'phone',
  StartDate = 'startDate',
  EndDate = 'endDate'
}

export enum SubscriptionDetailsColumns {
  Quantity = 'quantity',
  Specific = 'specific',
  General = 'general',
  Expended = 'expended'
}

export enum CreditPackages {
  MONTHLY = 'MONTHLY',
  EXPANSION = 'EXPANSION',
  MIGRATION = 'MIGRATION',
  SINGLE = 'SINGLE'
}

export enum GeneralFilters {
  Product = 'product',
  Date = 'date'
}

export enum GeneralFiltersData {
  All = 'all'
}

export enum Filters {
  Phone = 'phone',
  Date = 'date'
}

export enum Products {
  WhatsApp = 'WHATSAPP',
  WozTranscript = 'WOZ_TRANSCRIPT',
  WozCopilot = 'WOZ_COPILOT'
}
