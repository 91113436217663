export const SET_INBOX_COUNTERS = 'SET_INBOX_COUNTERS'

export const UPDATE_CHAT_SETTINGS = 'UPDATE_CHAT_SETTINGS'

export const OPEN_CHAT_CONVERSATION_ROOM_KEY = 'OPEN_CHAT_CONVERSATION_ROOM_KEY'

export const SET_INBOX_GROUPS = 'SET_INBOX_GROUPS'

export const SET_GROUP_INBOX_NAME_SELECTED = 'SET_GROUP_INBOX_NAME_SELECTED'

export const SET_INBOX_GROUPS_EDIT = 'SET_INBOX_GROUPS_EDIT'
