<template>
  <div :class="$style['octa-dialog']" v-show="value">
    <div
      :class="$style['octa-dialog-shadow']"
      @click="closeByPersistentCondition('click:outside')"
    />
    <div
      v-if="value"
      :class="{ [$style['octa-dialog-content']]: true, [contentClasses]: true }"
      :style="{ maxWidth: `${maxWidth}px`, width: `${maxWidth}px` }"
    >
      <octa-icon
        :class="$style['octa-dialog-close']"
        @click="closeDialog"
        v-if="closeIcon"
        icon="close-circle"
      />

      <octa-transition transition="slide-y">
        <slot />
      </octa-transition>
    </div>
  </div>
</template>

<script>
  import { OctaIcon } from '@octadesk-tech/web-components'

  import OctaTransition from '@/modules/shareds/components/OctaTransition/OctaTransition.vue'

  export default {
    components: {
      OctaTransition,
      OctaIcon
    },
    props: {
      value: Boolean,
      persistent: Boolean,
      closeIcon: {
        type: Boolean,
        default: true
      },
      maxWidth: {
        type: Number,
        default: 540
      }
    },
    data: () => ({
      contentClasses: ''
    }),
    updated() {
      this.triggerEscEvent()
    },
    methods: {
      triggerEscEvent() {
        if (!window.document) {
          return
        }

        const closeFunction = ev => {
          ev.key === 'Escape' && this.closeByPersistentCondition('keyup:escape')
        }

        if (this.value) {
          window.document.addEventListener('keyup', closeFunction)
          return
        }

        window.document.removeEventListener('keyup', closeFunction)
      },
      emitInput(value) {
        this.$emit('input', value)
      },
      closeByPersistentCondition(eventName) {
        this.$emit(eventName)
        if (this.persistent) {
          this.triggerAnimation()
          return
        }

        this.closeDialog()
      },
      closeDialog() {
        this.emitInput(false)
      },
      triggerAnimation() {
        if (
          this.contentClasses.indexOf(
            this.$style['octa-content--close-anime']
          ) !== -1
        ) {
          return
        }
        this.contentClasses = this.$style['octa-content--close-anime']
        setTimeout(() => {
          this.contentClasses = ''
        }, 300)
      }
    }
  }
</script>

<style lang="scss" module>
  .octa-dialog {
    transition: all ease-in 587ms;
    @apply w-screen h-screen fixed left-0 top-0 flex justify-center items-center z-20;
  }
  .octa-dialog-shadow {
    @apply w-full h-full absolute bg-black opacity-60;
  }
  .octa-dialog-content {
    @apply z-50 relative;
  }
  .octa-content--close-anime {
    animation: dialog-pulse linear 300ms;
  }

  .octa-dialog-close {
    width: 29px;
    height: 29px;
    @apply top-1 right-1 absolute cursor-pointer text-blueGray-600;
  }

  @keyframes dialog-pulse {
    0% {
      transform: scale(1.08);
    }
    50% {
      transform: scale(0.956);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
