import Storage from '@octadesk-tech/storage'

import { getContactStatus } from '@/modules/Bot/services/persons'
import types from '@/modules/Bot/store/mutations-types'

export const addSessionChatProperty = ({ commit, dispatch }, chatProperty) => {
  commit(types.ADD_SESSION_CHAT_PROPERTY, chatProperty.value)

  if (!chatProperty.onMounted) {
    dispatch('setBotUnsavedChanges')
  }
}

const FIXED_PERSONS_ALLOWED_VALUES_GETTERS = {
  contactStatus: getContactStatus
}

export const getAllowedValuesFromFixedFields = async ({ commit, getters }) => {
  const { fixedPersonProperties } = getters

  const fields = fixedPersonProperties.filter(field => field.dataSource)

  const allowedValuesByFieldName = []

  for (const field of fields) {
    const storageKey = `fixedPerson.${field.dataSource}`

    let allowedValues = Storage.getItem(storageKey)

    if (allowedValues == null) {
      const dataSourceMethod =
        FIXED_PERSONS_ALLOWED_VALUES_GETTERS[field.dataSource]

      if (dataSourceMethod) {
        allowedValues = await dataSourceMethod()

        Storage.setItem(storageKey, allowedValues)
      }
    }

    allowedValuesByFieldName.push({ fieldName: field.name, allowedValues })
  }

  commit(types.SET_ALLOWED_VALUES, {
    allowedValuesByFieldName,
    fixedPersonProperties
  })
}
