import { AxiosInstance } from 'axios'

import { SystemTypes } from '@/common/helpers/enums/system-types'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import { Policy } from './store/interfaces'

export class TicketsSettingsService {
  async getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async getTicketsCustomFields() {
    try {
      const client = await this.getClient('customFields')
      const { data } = await client.get(
        `/system-type/${SystemTypes.TICKET}?showEnabledItems=false`,
        {
          ...legacyHeaders()
        }
      )
      return data
    } catch (ex) {
      throw new Error(
        `Could not get ticket custom fields: ${
          (ex as any).response?.data?.message
        }`
      )
    }
  }

  async getTicketReopenPolicy() {
    try {
      const client = await this.getClient('ticket')
      const { data } = await client.get<Policy>('/reopen-policy', {
        ...legacyHeaders()
      })
      return data
    } catch (ex) {
      throw new Error(`Could not get ticket policy: ${(ex as any)?.message}`)
    }
  }

  async setTicketReopenPolicy(policy: Policy) {
    try {
      const client = await this.getClient('ticket')
      const { data } = await client.post('/reopen-policy', policy, {
        ...legacyHeaders()
      })
      return data
    } catch (ex) {
      throw new Error(`Could not set ticket policy: ${(ex as any)?.message}`)
    }
  }
}
