export const CAN_SHOW_BAR = 'CAN_SHOW_BAR'

export const DISABLE_REOPEN = 'DISABLE_REOPEN'

export const REMOVE_FROM_SOCKET_QUEUE = 'REMOVE_FROM_SOCKET_QUEUE'

export const REMOVE_NUMBER = 'REMOVE_NUMBER'

export const ADD_NUMBER = 'ADD_NUMBER'

export const SET_LOADER_SHOW_BAR = 'SET_LOADER_SHOW_BAR'
