import i18n from '@/common/i18n'

import { DataSourceResolver } from './interfaces'

export const messageOrigin: DataSourceResolver = () =>
  Promise.resolve([
    {
      id: 'USER',
      name: i18n?.t('dataSources.messageOrigin.user') as string
    },
    {
      id: 'API',
      name: i18n?.t('dataSources.messageOrigin.api') as string
    },
    {
      id: 'BOT',
      name: i18n?.t('dataSources.messageOrigin.bot') as string
    },
    {
      id: 'WOZ',
      name: i18n?.t('dataSources.messageOrigin.woz') as string
    }
  ])
