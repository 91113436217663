export const ModalSteps = {
  HasKey: 0,
  NumberStatus: 1,
  Integrator: 2,
  Key: 3,
  RegisterRequirementsPart1: 4,
  RegisterRequirementsPart2: 5,
  RegisterDisableAccount: 6,
  Register: 7,
  RegisterSuccess: 8,
  RegisterError: 9,
  ProfileDescription: 10,
  ProfileDetails: 11,
  ProfileSuccess: 12,
  MigrateActivation: 13,
  MigrateActivationSelect: 14,
  MigrateRequirementsPart1: 15,
  MigrateRequirementsPart2: 16,
  Migrate: 17,
  MigrateAddNumber: 18,
  MigrateConfirmation: 19,
  MigrateSuccess: 20,
  ConfirmDeleteNumber: 21,
  0: 'HasKey',
  1: 'NumberStatus',
  2: 'Integrator',
  3: 'Key',
  4: 'RegisterRequirementsPart1',
  5: 'RegisterRequirementsPart2',
  6: 'RegisterDisableAccount',
  7: 'Register',
  8: 'RegisterSuccess',
  9: 'RegisterError',
  10: 'ProfileDescription',
  11: 'ProfileDetails',
  12: 'ProfileSuccess',
  13: 'MigrateActivation',
  14: 'MigrateActivationSelect',
  15: 'MigrateRequirementsPart1',
  16: 'MigrateRequirementsPart2',
  17: 'Migrate',
  18: 'MigrateAddNumber',
  19: 'MigrateConfirmation',
  20: 'MigrateSuccess',
  21: 'ConfirmDeleteNumber'
}
