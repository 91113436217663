import { Store } from 'vuex'

import { initializeApp } from 'firebase/app'
import Vue, { VueConstructor } from 'vue'
import VueRouter from 'vue-router'

import { environment } from '@octadesk-tech/services'

import BillingBoot from '@/modules/Billing/boot'
import PromotionalUpgradeBoot from '@/modules/PromotionalUpgrade/boot'
import { DependencyContainer } from '@/modules/support/define-bootstrap'

import privateRouter from '@/routes/router/private-router'

import configs from './helpers/configs'
import octaConfig from './helpers/configs/octa-config'

function configEnvironment() {
  octaConfig()

  environment.resolveEnvironment(configs.prefix)

  if (import.meta.env.VITE_ENVIRONMENT_NAME) {
    environment.resolveEnvironment(import.meta.env.VITE_ENVIRONMENT_NAME)
    return
  }

  environment.resolveEnvironment()
}

function destroyCredentialsIfIsTrayCallback(store: Store<any>) {
  if (window && window.location.href.indexOf('/tray/callback') !== -1) {
    store.dispatch('clearCredentials')
  }
}

export default (
  app: Vue,
  vue: VueConstructor,
  store: Store<any>,
  router: VueRouter
): Promise<void> => {
  configEnvironment()

  const container: DependencyContainer = {
    app,
    router: { private: privateRouter, public: router },
    store,
    vue
  }

  destroyCredentialsIfIsTrayCallback(store)

  BillingBoot(container)

  PromotionalUpgradeBoot(container)

  initializeApp(configs.firebase)

  return Promise.resolve()
}
