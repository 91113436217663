import { ActionContext } from 'vuex'

import {
  IntegratorSteps,
  CommerceIntegratorTypes
} from '@/modules/Commerce/integrations/integrations.enum'
import { CommerceIntegrationsService } from '@/modules/Commerce/integrations/integrations.service'

import { RootState } from '@/store/interfaces'

import { IntegrationsCommerceState, IntegrationsItems } from './interfaces'
import * as types from './mutation-types'

const commerceIntegrationsService = new CommerceIntegrationsService()

export const getCommerceIntegrations = async (
  context: ActionContext<IntegrationsCommerceState, RootState>
) => {
  const integrations =
    await commerceIntegrationsService.getCommerceIntegrations()

  context.commit(types.SET_COMMERCE_INTEGRATIONS, integrations)
}

export const getCommerceAvailableIntegrations = async (
  context: ActionContext<IntegrationsCommerceState, RootState>
) => {
  const { isTrayWhitelabel } = context.rootGetters

  let availableIntegrations =
    await commerceIntegrationsService.getCommerceAvailableIntegrations()

  if (availableIntegrations) {
    if (isTrayWhitelabel) {
      availableIntegrations = availableIntegrations.filter(
        item => item.name !== CommerceIntegratorTypes.magento
      )
    }

    context.commit(
      types.SET_COMMERCE_CONNECT_AVAILABLE_INTEGRATIONS,
      availableIntegrations
    )
  }
}

export const setCommerceAvailableIntegrationsFields = async (
  context: ActionContext<IntegrationsCommerceState, RootState>,
  fields: any
) => {
  context.commit(types.SET_COMMERCE_CONNECT_FIELDS, fields)
}

export const setCommerceConnectIntegrationCurrentStep = (
  context: ActionContext<IntegrationsCommerceState, RootState>,
  step: IntegratorSteps
) => {
  context.commit(types.SET_COMMERCE_CONNECT_INTEGRATION_CURRENT_STEP, step)
}

export const createCommerceConnectIntegration = async (
  context: ActionContext<IntegrationsCommerceState, RootState>
) => {
  const commerceCatalog =
    context.rootGetters['commerceCatalogs/commerceCatalog']

  const {
    commerceConnectIntegrationFields: { name, settings },
    commerceIntegrationsItems
  } = context.getters

  try {
    const integration =
      await commerceIntegrationsService.setCommerceIntegration({
        name,
        settings
      })

    if (integration?.id) {
      await commerceIntegrationsService.syncCommerceIntegration({
        integrationId: integration?.id,
        catalogId: commerceCatalog.id
      })

      integration.lastSync = { syncing: true }
    }

    const integrations = [...commerceIntegrationsItems, integration]

    context.commit(types.SET_COMMERCE_INTEGRATIONS, integrations)

    context.dispatch(
      'setCommerceConnectIntegrationCurrentStep',
      IntegratorSteps.storeIntegration
    )

    context.dispatch('setCommerceAvailableIntegrationsFields', {
      name: '',
      settings: []
    })
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const deleteCommerceIntegration = async (
  context: ActionContext<IntegrationsCommerceState, RootState>,
  integrationId: string
) => {
  const { commerceIntegrationsItems } = context.getters

  await commerceIntegrationsService.deleteCommerceIntegration({ integrationId })

  context.commit(
    types.SET_COMMERCE_INTEGRATIONS,
    commerceIntegrationsItems.filter(
      (integration: IntegrationsItems) => integration.id !== integrationId
    )
  )
}
