import axios, { AxiosInstance } from 'axios'

import { GRAPH_API_VERSION } from '@/common/helpers/utils/meta-constants'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

type T = Window

const API_FACEBOOK_TOKEN = '/api/Facebook/token'

interface MyWindow extends T {
  FB: any
}

export class FacebookSdkService {
  private static instance?: FacebookSdkService

  public static getInstance(): FacebookSdkService {
    if (!this.instance) {
      this.instance = new FacebookSdkService()
    }

    return this.instance
  }

  private scriptID = 'facebook-sdk-script'

  private scopes: Array<string> = []

  private client: AxiosInstance

  private constructor() {
    this.client = axios.create({})
  }

  getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async getFacebookAppID(): Promise<string | undefined> {
    try {
      const { data } = await this.getClient('company').then(client =>
        client.get('/settings/facebook-app-id', legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error in get facebook app ID: ' + err)
    }
  }

  isFacebookSDKScriptInjected(): boolean {
    return !!document.getElementById(this.scriptID)
  }

  async injectFacebookSDKScript(): Promise<void> {
    if (!this.isFacebookSDKScriptInjected()) {
      const appId = await this.getFacebookAppID()

      const script = document.createElement('script')

      script.src = 'https://connect.facebook.net/pt_BR/sdk.js'

      script.async = true

      script.id = this.scriptID
      ;(window as MyWindow & typeof globalThis).FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: GRAPH_API_VERSION,
        cookie: true
      })

      document.body.append(script)
    }
  }

  async getScopes(scopes: Array<string>): Promise<void> {
    try {
      const { data } = await this.getClient('multiChannel').then(client =>
        client.get('/admin/accounts/scopes', {
          ...legacyHeaders()
        })
      )

      this.scopes = Object.values(
        Object.fromEntries(
          Object.entries(data.data).filter(([key]) => scopes.includes(key))
        )
      ).flat() as Array<string>
    } catch (err) {
      throw new Error('Error in get scopes: ' + err)
    }
  }

  async requestLoginAndPermissions({
    callback,
    scopes,
    localScopes,
    extras
  }: {
    callback: (data: any) => void
    scopes: Array<string>
    localScopes?: Array<string>
    extras?: { [key: string]: any }
  }): Promise<void> {
    interface FBResponse {
      authResponse: {
        grantedScopes: string
        accessToken: string
      }
      status: 'unknown' | 'connected'
    }

    if (!localScopes?.length) {
      await this.getScopes(scopes)
    } else {
      this.scopes = localScopes
    }

    ;(window as MyWindow & typeof globalThis).FB.login(
      (response: FBResponse) => {
        callback({
          grantedScopes: response?.authResponse?.grantedScopes,
          accessToken: response?.authResponse?.accessToken,
          status: response?.status
        })
      },
      {
        scope: this.scopes,
        return_scopes: true,
        extras
      }
    )
  }

  async setPermanentToken({
    accessToken
  }: {
    accessToken: string
  }): Promise<void> {
    try {
      await this.getClient('multiChannel').then(client =>
        client.post(API_FACEBOOK_TOKEN, { accessToken }, { ...legacyHeaders() })
      )
    } catch (err) {
      throw new Error(`Error in setPermanentToken: ${err}`)
    }
  }

  async getPermanentToken(): Promise<string> {
    try {
      const { data } = await this.getClient('multiChannel').then(client =>
        client.get(API_FACEBOOK_TOKEN, { ...legacyHeaders() })
      )

      return data.accessToken
    } catch (err) {
      throw new Error(`Error in getPermanentToken: ${err}`)
    }
  }

  async removePermanentToken(): Promise<void> {
    try {
      await this.getClient('multiChannel').then(client =>
        client.delete(API_FACEBOOK_TOKEN, { ...legacyHeaders() })
      )
    } catch (err) {
      throw new Error(`Error in removePermanentToken: ${err}`)
    }
  }

  async uninstallFbe({
    externalBusinessId,
    accessToken
  }: {
    externalBusinessId: string
    accessToken: string
  }): Promise<void> {
    await this.client.delete(
      `https://graph.facebook.com/${GRAPH_API_VERSION}/fbe_business/fbe_installs`,
      {
        params: {
          fbe_external_business_id: externalBusinessId,
          access_token: accessToken
        }
      }
    )
  }
}
