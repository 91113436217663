import { ActionContext } from 'vuex'

import { WhatsappIntegratorService } from '@/common/services/whatsapp-integrator'
import WhatsappServices from '@/common/services/whatsapp-services'

import { RootState, WhatsappState } from '@/store/interfaces'

import * as types from './mutations-types'

export const getWhatsappNumbers = async (
  context: ActionContext<WhatsappState, RootState>,
  options = { fetchIntegrators: false }
) => {
  const { fetchIntegrators } = options

  const whatsapp = new WhatsappServices()

  const qrCodeNumbers = await whatsapp.getWhatsappNumbers()

  let numbers = [...qrCodeNumbers]

  if (fetchIntegrators) {
    const whatsappIntegratorService = new WhatsappIntegratorService()

    const integratorNumbers =
      await whatsappIntegratorService.getIntegratorWhatsappNumbers()

    numbers = [...numbers, ...integratorNumbers]
  }

  context.commit(types.SET_WHATSAPP_NUMBERS, numbers)

  return numbers
}
