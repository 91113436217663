import {
  FormSteps,
  SendTypes,
  ViewingConversations
} from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/bulk-messages.enum'

import { BulkMessageState, FormPayload } from './interfaces'

export const BULK_MESSAGE_FORM_PAYLOAD_INITIAL_DATA: FormPayload = {
  name: '',
  origin: {
    channel: '',
    from: '',
    integrator: '',
    externalAccountId: ''
  },
  content: {
    type: 'quickReply',
    quickReply: undefined
  },
  target: {
    type: 'spreadsheet',
    spreadsheet: {
      url: '',
      name: '',
      rowsCount: 0
    },
    columns: []
  },
  startAt: {
    type: SendTypes.Immediate,
    date: '',
    time: ''
  },
  assignment: [],
  viewingConversations: ViewingConversations.Immediate
}

const state: BulkMessageState = {
  initialData: {
    loader: true,
    hasError: false
  },
  whatsappOfficialNumbers: [],
  approvedTemplateMessages: [],
  bulkMessages: [],
  selectedBulkMessage: false,
  bulkMessagesReport: '',
  availableTemplateMessages: 0,
  retrievedTemplateMessage: undefined,
  currentListPage: 1,
  currentListLoader: false,
  totalBulkMessages: 0,
  bulkMessage: {
    step: FormSteps.BasicInformations,
    initialFormPayload: BULK_MESSAGE_FORM_PAYLOAD_INITIAL_DATA,
    formPayload: BULK_MESSAGE_FORM_PAYLOAD_INITIAL_DATA,
    showDiscardContentAlert: false,
    showReviewModal: false,
    successModal: {
      show: false,
      type: undefined
    },
    isSpreadSheetEmpty: undefined
  }
}

export default state
