import { RoleTypes } from '@/common/helpers/enums/role-types'

export default {
  path: '/octa-store',
  name: 'octa-store',
  component: () => import('./OctaStore.vue'),
  redirect: {
    name: 'additionals'
  },
  children: [
    {
      path: '',
      name: 'additionals',
      component: () => import('./additionals/components/Additionals.vue'),
      meta: {
        permissionLevel: RoleTypes.Admin
      }
    }
  ]
}
