const CONNECTION_TYPE_FALLBACK = 'fallback'

import * as helpers from '@/modules/Bot/store/helpers'
import {
  ClientBotAction,
  ClientBotActionTypes,
  ClientBotConnection
} from '@/modules/Bot/store/types'

export const currentChatBot = ({ currentChatBot }) => currentChatBot

export const editingPersona = ({ editingPersona }) => editingPersona

export const hasBotUnsavedChanges = ({ hasBotUnsavedChanges }) =>
  hasBotUnsavedChanges

export const showExitWarningModal = ({
  hasBotUnsavedChanges,
  isExitingConfiguration
}) => hasBotUnsavedChanges && isExitingConfiguration

export const isExternalExiting = ({ isExternalExiting }) => isExternalExiting

export const botConnections = ({ connections }) => connections

export const botActions = ({ actions }) => actions

export const botPersona = ({ persona }) => persona

export const currentEditingAction = ({ currentEditingAction }) =>
  currentEditingAction

export const hasDuplicateWarning = ({ currentChatBot }) => {
  return currentChatBot?.warning
}

export const currentBotStructure = ({
  connections,
  actions,
  persona,
  proActiveMessage
}) => {
  return {
    connections,
    actions,
    persona,
    proActiveMessage
  }
}

export const firstBotAction = ({ actions, connections, proActiveMessage }) => {
  return (
    proActiveMessage ||
    firstBotStructureAction({
      actions,
      connections
    })
  )
}

export const firstBotStructureConnection = ({ connections }) => {
  return connections.find(
    connection => !connection.parentActionId && connection.nextActionId
  )
}

export const firstBotStructureAction = ({ actions, connections }) => {
  const firstConnection = firstBotStructureConnection({
    connections
  })

  return (
    firstConnection &&
    actions.find(action => action.id === firstConnection.nextActionId)
  )
}

export const handleValidateWarning = ({ actions }) => {
  const warnings = []
  actions.forEach(element => {
    if (element.warning) {
      element.content.warnings.forEach(warning =>
        warnings.push({
          warning,
          id: element.id
        })
      )
    }
  })
  return warnings
}

const getConnectionType = (action, defaultValue) =>
  action.isFallbackFlow && action.isFallbackFlow()
    ? CONNECTION_TYPE_FALLBACK
    : defaultValue

export const botActionsTree = (
  state,
  { firstBotAction, getNextPossibleActions }
) => {
  let treeActions = []

  if (firstBotAction) {
    treeActions.push(firstBotAction)

    let connectionType = getConnectionType(firstBotAction)

    let nextActions = getNextPossibleActions({
      currentAction: firstBotAction,
      connectionType
    })

    while (nextActions.length) {
      const lastNextAction = nextActions[nextActions.length - 1]
      const alreadyExistsOnTreeAction = treeActions.some(treeAction =>
        nextActions.find(next => next.id === treeAction.id)
      )

      if (alreadyExistsOnTreeAction) {
        const recursionAction = new ClientBotAction({
          type: ClientBotActionTypes.Recursion,
          id: `recursion-${lastNextAction.id}`,
          content: {
            action: lastNextAction
          }
        })

        treeActions.push(recursionAction)

        break
      } else {
        treeActions = treeActions.concat(nextActions[0])

        connectionType = getConnectionType(lastNextAction, connectionType)

        nextActions = getNextPossibleActions({
          currentAction: lastNextAction,
          connectionType
        })
      }
    }
  }

  return treeActions
}

export const getConnectionsBetween =
  ({ connections }) =>
  (parentAction, nextAction) =>
    connections.filter(
      connection =>
        connection.parentActionId === parentAction.id &&
        connection.nextActionId === nextAction.id
    )

export const getPreviousConnections =
  ({ connections, proActiveMessage }) =>
  ({ currentAction }) => {
    if (currentAction.type === ClientBotActionTypes.ProActiveMessage) {
      return []
    } else {
      const previousConnections = connections.filter(
        connection => connection.nextActionId === currentAction.id
      )

      if (!previousConnections.length && proActiveMessage) {
        return [
          new ClientBotConnection({
            parentActionId: proActiveMessage.id,
            nextActionId: currentAction.id
          })
        ]
      } else {
        return previousConnections
      }
    }
  }

export const getSelectedButton = () => action => {
  return (action && action.getSelectedButton()) || null
}

export const getFirstButton = () => action => {
  const buttons = action?.content?.buttons

  if (!buttons || !buttons.length) return null

  return buttons[0]
}

export const getNextPossibleConnections =
  ({ connections }) =>
  ({
    currentAction,
    considerMultipleConnections,
    connectionType,
    currentButton
  }) => {
    if (!currentAction) return []

    if (currentAction.type === ClientBotActionTypes.ProActiveMessage) {
      const connection = firstBotStructureConnection({
        connections
      })
      return connection ? [connection] : []
    }

    let selectedButton = currentButton

    if (!selectedButton && !considerMultipleConnections) {
      selectedButton =
        getSelectedButton()(currentAction) || getFirstButton()(currentAction)
    }

    if (selectedButton) {
      return getNextPossibleButtonConnections({
        connections
      })({
        currentAction,
        button: selectedButton
      })
    } else {
      const possibleConnections = connections.filter(
        connection => connection.parentActionId === currentAction.id
      )

      if (connectionType === CONNECTION_TYPE_FALLBACK) {
        const fallbackConnections = possibleConnections.filter(
          connection => connection.type === CONNECTION_TYPE_FALLBACK
        )

        if (fallbackConnections?.length) return fallbackConnections
      }

      return possibleConnections.filter(
        connection => connection.type !== 'fallback'
      )
    }
  }

export const getNextPossibleButtonConnections =
  ({ connections }) =>
  ({ currentAction, button }) => {
    if (!button) return []

    const nextPossibleConnections = connections.filter(
      connection => connection.parentActionId === currentAction.id
    )

    let filteredNextPossibleConnections = nextPossibleConnections.filter(
      connection =>
        connection.connectionRules &&
        connection.connectionRules.some(rule =>
          helpers.isValidButtonConnectionRule(rule, button)
        )
    )

    if (!filteredNextPossibleConnections.length) {
      filteredNextPossibleConnections = nextPossibleConnections.filter(
        connection =>
          !connection.connectionRules || !connection.connectionRules.length
      )
    }

    return filteredNextPossibleConnections
  }

export const getNextPossibleActions =
  ({ connections, actions }) =>
  ({ currentAction, considerMultipleConnections, connectionType }) => {
    const filteredConnections = getNextPossibleConnections({
      connections,
      actions
    })({
      currentAction,
      considerMultipleConnections,
      connectionType
    })

    return filteredConnections
      .map(connection =>
        actions.find(action => action.id === connection.nextActionId)
      )
      .filter(action => action)
  }

export const getNextPossibleButtonActions =
  ({ connections, actions }) =>
  ({ currentAction, button }) => {
    if (!button) return []

    const filteredConnections = getNextPossibleButtonConnections({
      connections,
      actions
    })({
      currentAction,
      button
    })

    return filteredConnections
      .map(connection =>
        actions.find(action => action.id === connection.nextActionId)
      )
      .filter(action => action)
  }

export const getNextButtonAction =
  (_, { getNextPossibleButtonActions }) =>
  ({ currentAction, currentButton }) => {
    if (currentAction) {
      const actions = getNextPossibleButtonActions({
        currentAction: currentAction,
        button: currentButton
      })

      return actions[0]
    }
    return undefined
  }

export const canRemoveButton =
  (
    _,
    {
      getNextPossibleButtonActions,
      getNextButtonAction,
      getPreviousConnections
    }
  ) =>
  ({ currentAction, currentButton }) => {
    const currentNextAction = getNextButtonAction({
      currentAction,
      currentButton
    })

    if (!currentNextAction) {
      //opção sem destino pode ser removido
      return true
    }

    const buttons = currentAction.getButtons()

    if (!buttons || !buttons.length) {
      console.warn('No buttons! Something is widely wrong.')

      return false
    }

    const otherButtons = buttons.filter(
      btn => btn.label !== currentButton.label
    )

    const otherButtonsSameCurrentAction = otherButtons.some(button => {
      const actions = getNextPossibleButtonActions({
        currentAction: currentAction,
        button
      })

      return actions.some(act => act.id === currentNextAction.id)
    })

    if (otherButtonsSameCurrentAction) {
      //outros botões apontam para a mesma ação
      return true
    }

    const currentNextActionConnections = getPreviousConnections({
      currentAction: currentNextAction
    })

    // if (currentNextActionConnections.length > 1) {
    //   //quando a ação conectada à opção possui outras ligações (de outras ações, por exemplo)
    //   return true
    // }

    return currentNextActionConnections.length > 1
  }

export const getActionById =
  ({ actions, proActiveMessage }) =>
  id => {
    if (proActiveMessage && proActiveMessage.id === id) {
      return proActiveMessage
    }

    return actions.find(action => action.id == id)
  }

export const getActionByDisplayName =
  ({ actions, proActiveMessage }) =>
  displayName => {
    if (proActiveMessage && proActiveMessage.displayName === displayName) {
      return proActiveMessage
    }

    return actions.find(action => action.displayName === displayName)
  }

export const getActionsWithoutDirection =
  ({ connections, actions }, { getButtonsWithoutConnection }) =>
  () => {
    const actionsWithoutConnectionsAsParent = actions.filter(
      action =>
        !connections.some(connection => connection.parentActionId === action.id)
    )

    const optionActions = actions
      .filter(action => {
        const buttons = action.getButtons()
        return buttons && buttons.length
      })
      .filter(action =>
        actionsWithoutConnectionsAsParent.every(
          action2 => action.id !== action2.id
        )
      )

    const optionActionsWithoutDirection = optionActions.filter(
      action => getButtonsWithoutConnection(action).length
    )

    return [
      ...optionActionsWithoutDirection,
      ...actionsWithoutConnectionsAsParent
    ]
  }

export const getButtonsWithoutConnection =
  (_, { getNextButtonAction }) =>
  action => {
    const buttonHasDirection = (currentAction, currentButton) => {
      const directedAction = getNextButtonAction({
        currentAction,
        currentButton
      })

      return !!directedAction
    }

    const buttons = action.getButtons()
    return buttons.reduce((agg, curr) => {
      if (!buttonHasDirection(action, curr)) agg.push(curr)

      return agg
    }, [])
  }

export const getInvalidActions =
  ({ actions }) =>
  () => {
    return actions.filter(
      action => action && action.validate && !action.validate()
    )
  }

export const sidebarFocusEl = ({ sidebarFocusEl }) => sidebarFocusEl

export const branchWarningModal = ({ branchWarningModal }) => branchWarningModal

export const botOfficeHours = ({ botOfficeHours }) => botOfficeHours

export const invalidConfigurations = ({ invalidConfigurations }) =>
  invalidConfigurations

export const hasInvalidConfigurations = ({ invalidConfigurations }) =>
  invalidConfigurations.length > 0

export const actionsInvalid = ({ actionsInvalid }) => actionsInvalid
