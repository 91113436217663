import { firstBuyRoutesChildren } from '@/modules/Billing/first-buy-routes-children'

import { BILLING_STORE_ACTIONS } from './enums'
import { useStore } from './store'

export const firstBuyRoutes = {
  path: '/billing/',
  component: () => import('@/views/Billing.vue'),
  beforeEnter(to: any, from: any, next: (() => void) | null | undefined) {
    useStore()
      .dispatch(BILLING_STORE_ACTIONS.SET_UNLOGGED_INFO)
      .finally(() =>
        useStore().dispatch(BILLING_STORE_ACTIONS.LOAD_INITAL).finally(next)
      )
  },
  children: [firstBuyRoutesChildren]
}
