import { RoleTypes } from '@/modules/Chat/components/sidebar/enums/role-types'
import { enums } from '@/modules/Chat/components/sidebar/services/enums'

const CHAT_AGENT_GETTER_NAME = 'chat/agent'

const filterChats = ({ chats, status, filter }) => {
  return chats.filter(c => {
    let response = false

    //per status section
    if (filter && filter.status && filter.status.length) {
      response = filter.status.includes(c.status)
    } else {
      response = status.includes(c.status)
    }

    //per channel
    if (filter && filter.channel) {
      response = response && c['channel'] === filter.channel
    }

    return response
  })
}

export const selectedSidebarTab = ({ selectedSidebarTab }) => selectedSidebarTab

export const hasMorePages = ({ hasMorePages }) => hasMorePages

export const chatsLength = ({ chatsLength }) => chatsLength

export const status = ({ status }) => status

export const filterName = ({ filterName }) => filterName

export const chats = ({ chats, status, filter }) =>
  filterChats({
    chats,
    status,
    filter
  })

export const waitingChats = ({ waitingChats }) => waitingChats

export const unrededChats = ({ unrededChats }) => unrededChats

export const showFilterPanel = ({ showFilterPanel }) => showFilterPanel

export const selectedFilter = ({ selectedFilter }) => selectedFilter

export const temporaryFilter = ({ temporaryFilter }) => temporaryFilter

export const hasSelectedFilter = (
  { selectedFilter },
  getters,
  _,
  rootGetters
) => {
  const validPropertyFuncion = f => f !== undefined && f !== '' && f !== null

  const valuesSelectedFilter = Object.values(selectedFilter || {})

  const defaultFilterResult = defaultFilterFunction(rootGetters)

  const isMyConversations =
    defaultFilterResult === 'byAgent' &&
    selectedFilter.func === 'byAgent' &&
    rootGetters[CHAT_AGENT_GETTER_NAME] &&
    selectedFilter.id == rootGetters[CHAT_AGENT_GETTER_NAME].id

  const isAllConversations =
    selectedFilter.func === 'all' && defaultFilterResult === 'all'

  if (isMyConversations) {
    return valuesSelectedFilter.filter(validPropertyFuncion).length > 3
  } else if (isAllConversations) {
    return valuesSelectedFilter.filter(validPropertyFuncion).length > 2
  } else {
    return valuesSelectedFilter.some(validPropertyFuncion)
  }
}

export const phoneContacts = ({ phoneContacts }) => phoneContacts

export const hasMoreContacts = ({ hasMoreContacts }) => hasMoreContacts

export const contactPage = ({ contactPage }) => contactPage

export const listFilters = state => ({
  status: state.status,
  ...state.filter
})

export const botFluxes = ({ botFluxes }) => botFluxes

export const hasBots = ({ botFluxes }) => botFluxes && botFluxes.length

const hasActiveRoulette = ({
  agent,
  settings,
  visibleGroups,
  useDistributor
}) => {
  if (useDistributor) return true

  const agentGroups =
    (visibleGroups &&
      visibleGroups.length &&
      visibleGroups.filter(
        group => group.agents && group.agents.some(usr => usr.id === agent.id)
      )) ||
    []

  const rouletteByGroup =
    agentGroups.length &&
    agentGroups.every(
      group => group.advancedSettings && group.advancedSettings.roulette
    )

  const rouletteBySettings =
    settings && settings.conversation && settings.conversation.roulette

  return rouletteByGroup || rouletteBySettings
}

export const searchStatus = ({ searchStatus }) => searchStatus

export const isLoadingMore = ({ isLoadingMore }) => isLoadingMore

export const channelsUsage = ({ channelsUsage }) => channelsUsage

export const isAdmin = agent =>
  agent && [RoleTypes.admin, RoleTypes.owner].includes(agent.roleType)

const defaultFilterFunction = getters => {
  if (isAdmin(getters[CHAT_AGENT_GETTER_NAME]) || !hasActiveRoulette(getters)) {
    return 'all'
  } else {
    return 'byAgent'
  }
}

const defaultFilterName = getters => {
  if (isAdmin(getters[CHAT_AGENT_GETTER_NAME]) || !hasActiveRoulette(getters)) {
    return 'chat.sidebar.filter.atribution.allConversations'
  } else {
    return 'chat.sidebar.filter.atribution.myConversations'
  }
}

export const selectedFilterName = (
  { selectedFilter },
  getters,
  _,
  rootGetters
) => {
  const hasSelectedFilterResult = hasSelectedFilter(
    {
      selectedFilter
    },
    getters
  )

  if (hasSelectedFilterResult && selectedFilter.name) {
    return selectedFilter.name
  } else {
    return defaultFilterName(rootGetters)
  }
}

export const temporarySelectedFilterName = ({ temporaryFilter }, getters) => {
  if (temporaryFilter && temporaryFilter.name) {
    return temporaryFilter.name
  } else {
    return defaultFilterName(getters)
  }
}

export const defaultFilter = (_, getters, rootState, rootGetters) => {
  const { agent } = getters

  if (isAdmin(agent) || !hasActiveRoulette(rootGetters)) {
    return {
      filter: {
        filter: {},
        remove: ['clear-all']
      },
      name: 'all'
    }
  } else {
    return {
      filter: {
        filter: {
          'agent._id': agent.id
        },
        remove: ['clear-all']
      },
      name: 'Minhas Conversas'
    }
  }
}

export const userLoggedAssignedCount = ({ userLoggedAssignedCount }) =>
  userLoggedAssignedCount

export const getCurrentContact = ({ currentContact }) => currentContact

export const currentInbox = ({ currentInbox }, getters, _, rootGetters) => {
  const { agent } = getters

  if (!currentInbox.inboxName) {
    if (isAdmin(agent) || !hasActiveRoulette(rootGetters)) {
      return {
        ...currentInbox,
        inboxName: enums.inbox.all
      }
    } else {
      return {
        ...currentInbox,
        inboxName: enums.inbox.byAgent
      }
    }
  }

  return currentInbox
}

export const currentInboxName = (_, { currentInbox }) => currentInbox?.inboxName

export const conversationsListType = ({ conversationsListType }) =>
  conversationsListType

export const showCheckboxBatch = ({ conversationsListType }) => {
  return conversationsListType === enums.listTypes.open
}

export const conversationListOnOpen = ({ conversationsListType }) => {
  return conversationsListType === enums.listTypes.open
}

export const getChatKeysChecked = ({ chats }) =>
  chats?.filter(chat => chat?.checked)?.map(chat => chat.key) || []

export const getChatListUnreadMessagesAmountWhenFinalizingInBatch = ({
  chats
}) =>
  chats.length && chats.filter(chat => chat.checked && !chat.agentRead).length

export const getLoadingConversationList = ({ loadingConversationList }) =>
  loadingConversationList

export const getQtdSelectedFilters = ({ qtdSelectedFilters }) =>
  qtdSelectedFilters

export const getFetchingChatList = ({ fetchingChatList }) => fetchingChatList

export const getResponseServiceTimeoutCount = ({
  responseServiceTimeoutCount
}) => responseServiceTimeoutCount

export const getUpdateSelectedFilters = ({ updateSelectedFilters }) =>
  updateSelectedFilters
