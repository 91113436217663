import { PermissionsSteps } from '@/modules/Commerce/permissions/permissions.enum'

import { PermissionsCommerceState } from './interfaces'

const state: PermissionsCommerceState = {
  currentStep: {
    name: PermissionsSteps.facebook,
    number: 0
  },
  whatsappNumbers: [],
  facebook: {
    businessAccounts: [],
    token: '',
    permissionsError: false,
    twoFactoryStep: {
      show: false,
      loader: false
    },
    selectedAccount: {
      businessAccountId: '',
      whatsAppBusinessAccountId: ''
    }
  },
  createCatalogError: {
    show: false,
    i18nMessageKey: ''
  }
}

export default state
