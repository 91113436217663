import { getClient as getHttpClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import { SaveWebhooksRequestBody, WebhooksResponse } from './store/interfaces'

class WebhookService {
  private WEBHOOKS_PATH = 'webhooks'

  private async getClient() {
    return getHttpClient(this.WEBHOOKS_PATH)
  }

  async fetchWebhooks(): Promise<WebhooksResponse> {
    try {
      const client = await this.getClient()
      const { data } = await client.get<WebhooksResponse>('', legacyHeaders())
      return data
    } catch (error) {
      throw new Error(
        `Error in get webhook: ${(error as any).response?.data?.message || ''}`
      )
    }
  }

  async removeWebhook(id: string) {
    try {
      const client = await this.getClient()
      await client.delete(`/${id}`, legacyHeaders())
    } catch (error) {
      throw new Error(
        `Error in remove webhook: ${(error as any).response?.data?.message}`
      )
    }
  }

  async saveWebhooks(webhooks: SaveWebhooksRequestBody) {
    try {
      const client = await this.getClient()
      await client.post('', webhooks, legacyHeaders())
    } catch (error) {
      throw new Error(
        `Error in save webhooks: ${(error as any).response?.data?.message}`
      )
    }
  }
}

export default new WebhookService()
