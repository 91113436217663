import all from './all'
import byAgent from './byAgent'
import chatBot from './chatbot'
import mentioned from './mentioned'
import participation from './participation'
import timeExceeded from './time-exceeded'
import unread from './unread-answered'
import withoutResponsible from './without-responsible'

const LIMIT = 1000

export const inboxFilters = (
  userLoggedId: string,
  isAdmin: boolean,
  isResponseServiceTimeoutEnabled: boolean
) => [
  all(LIMIT),
  byAgent(LIMIT, userLoggedId),
  unread(LIMIT, userLoggedId),
  mentioned(LIMIT, userLoggedId),
  participation(LIMIT, userLoggedId),
  withoutResponsible(LIMIT),
  chatBot(LIMIT),
  ...(isResponseServiceTimeoutEnabled
    ? [timeExceeded(LIMIT, userLoggedId, isAdmin)]
    : [])
]
