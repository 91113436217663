import { SUB_TYPE } from '@/modules/Bot/enums'
import { ASSIGN_TO } from '@/modules/Bot/enums/assign-to.enum'

import i18n from '@/common/i18n'

export const hasContactStatus = ({ personProperties }) => {
  const contactStatus = personProperties.find(
    propertie => propertie.dataSource === 'contactStatus'
  )

  return contactStatus && contactStatus.resolvedAllowedValues
}

export const mountDynamicRules = ({ personProperties, rules }) => {
  const contactStatus = personProperties.find(
    propertie => propertie.dataSource === 'contactStatus'
  )

  const conditionalRule = [...rules]

  if (rules.find(rule => rule.input.dataSource === 'contactStatus')) {
    conditionalRule.forEach((rule, ruleIndex) => {
      if (rule.input.dataSource === 'contactStatus') {
        conditionalRule[ruleIndex].input.resolvedAllowedValues =
          contactStatus.resolvedAllowedValues

        if (
          rule.operator !== '$empty' &&
          contactStatus.resolvedAllowedValues?.length
        ) {
          const { key, value } = contactStatus.resolvedAllowedValues[0]

          conditionalRule[ruleIndex].comparators.push({
            value: key,
            displayValue: value,
            properties: []
          })

          conditionalRule[ruleIndex].comparator = {
            value: key,
            label: value
          }
        }
      }
    })
  }

  return conditionalRule
}

export const responsibleOption = () => {
  const assignToResponsibleTranlation = i18n?.t(
    'bot.newChatBot.actions.edit.assignToOptions'
  )

  return {
    group: assignToResponsibleTranlation,
    name: assignToResponsibleTranlation,
    optionType: ASSIGN_TO.agent,
    label: assignToResponsibleTranlation,
    isResponsibleOption: true,
    subType: SUB_TYPE.responsibleContact
  }
}
