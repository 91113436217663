import { OnboardingState } from '@/store/interfaces'

const state: OnboardingState = {
  channel: undefined,
  qrcodeModal: false,
  hasSelectedChannel: false,
  currentStep: 0,
  groupType: undefined,
  groupModal: false,
  loadingError: false,
  modalSequence: false,
  loadedWidget: false
}

export default state
