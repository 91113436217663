export default {
  path: '/bot-builder/activation/channel',
  name: 'activation',
  redirect: '/bot-builder/activation/channel/web',
  component: () => import('@/views/ActivationBot.vue'),
  children: [
    {
      path: 'web',
      name: 'webbot',
      component: () => import('./bot-activation/channels/web/container.vue')
    },
    {
      name: 'whatsbot',
      path: 'whatsapp',
      component: () => import('./bot-activation/channels/whatsapp.vue')
    },
    {
      name: 'instabot',
      path: 'instagram',
      component: () => import('./bot-activation/channels/instagram.vue')
    },
    {
      name: 'messengebot',
      path: 'messenger',
      component: () =>
        import('./bot-activation/channels/facebook-messenger.vue')
    }
  ]
}
