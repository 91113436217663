import * as officeHourTypes from '@/modules/Bot/store/types'

export default {
  connections: [],
  actions: [],
  persona: undefined,
  currentEditingAction: undefined,
  proActiveMessage: undefined,
  editingPersona: false,
  hasBotUnsavedChanges: false,
  showExitWarningModal: false,
  isExitingConfiguration: false,
  isExternalExiting: false,
  sidebarFocusEl: {
    element: undefined,
    type: undefined
  },
  branchWarningModal: {
    show: false,
    description: '',
    actionId: ''
  },
  botOfficeHours: {
    show: false,
    id: '',
    actionId: undefined,
    modal: officeHourTypes.modalTypes.default
  },
  invalidConfigurations: [],
  actionsInvalid: []
}
