import { WozContentState } from './interfaces'

export const documentsLoading = ({ documentsLoading }: WozContentState) =>
  documentsLoading

export const documents = ({ documents }: WozContentState) => documents

export const hasAnyDocuments = ({ hasAnyDocuments }: WozContentState) =>
  hasAnyDocuments

export const tableDocuments = ({ tableDocuments }: WozContentState) =>
  tableDocuments

export const agentDocumentsLoading = ({ agent }: WozContentState) =>
  agent.documentsLoading

export const agentDocuments = ({ agent }: WozContentState) => agent.documents

export const agentHasAnyDocuments = ({ agent }: WozContentState) =>
  agent.hasAnyDocuments

export const agentTableDocuments = ({ agent }: WozContentState) =>
  agent.tableDocuments

export const lastSearchParameters = ({
  lastSearchParameters
}: WozContentState) => lastSearchParameters
