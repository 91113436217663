import { AuthState } from '@/store/interfaces'

const state: AuthState = {
  apis: undefined,
  access_token: undefined,
  octaAuthenticated: undefined,
  userlogged: undefined,
  roles: undefined,
  tenants: undefined,
  redirectAfterLogin: undefined,
  keepConnected: true,
  idOctadesk: undefined,
  prevLoginType: undefined,
  loginInfo: {
    type: undefined,
    token: undefined,
    tenantId: undefined,
    email: undefined,
    password: undefined
  },
  connectionStatus: undefined,
  trialOverModal: undefined,
  pendingPaymentModal: undefined,
  subDomainStatus: {
    featureFlag: {},
    features: [],
    isTrial: false,
    isBlocked: false,
    paymentIssue: false,
    isTrialExpired: false,
    trialDays: 0,
    whiteLabel: undefined,
    environmentId: undefined
  },
  currentAgentAppsIds: [],
  tenantConfigs: [],
  migratedEnvironment: false,
  passwordExpired: false,
  ssoTenants: []
}

export default state
