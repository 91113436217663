import { CommerceCatalogProduct } from '@/modules/Commerce/interfaces'

import { CommerceState, CommerceCatalogInfo } from './interfaces'
import * as types from './mutation-types'

function hasCatalog(state: CommerceState): boolean {
  return Boolean(state.catalog)
}

export default {
  [types.SET_COMMERCE_INITIAL_DATA_FETCHED](
    state: CommerceState,
    isFetched: boolean
  ) {
    state.initialDataFetched = isFetched
  },
  [types.DELETE_COMMERCE_CATALOG](state: CommerceState) {
    state.catalog = undefined
  },
  [types.SET_COMMERCE_CATALOG_INFO](
    state: CommerceState,
    catalogInfo: CommerceCatalogInfo
  ) {
    state.catalog = catalogInfo.catalog
    state.nextProductsPage = catalogInfo.nextProductsPage
    state.totalProducts = catalogInfo.totalProducts
  },
  [types.SET_COMMERCE_CATALOG_NEXT_PRODUCTS_PAGE](
    state: CommerceState,
    nextPage?: string
  ) {
    state.nextProductsPage = nextPage
  },
  [types.SET_COMMERCE_CATALOG_PRODUCTS](
    state: CommerceState,
    products: CommerceCatalogProduct[]
  ) {
    if (!hasCatalog(state)) return

    const currentProducts = state.catalog!.products ?? []
    state.catalog!.products = [...currentProducts, ...products]

    if (!state.totalProducts) {
      state.totalProducts = products?.length ?? 0
    }
  },
  [types.RESET_COMMERCE_CATALOG_PRODUCTS](state: CommerceState) {
    if (!hasCatalog(state)) return

    state.catalog!.products = []
  },
  [types.SET_COMMERCE_CATALOG_PRODUCTS_LOADER](
    state: CommerceState,
    isLoader: boolean
  ) {
    state.productsLoader = isLoader
  },
  [types.UPDATE_COMMERCE_CATALOG_PRODUCTS](
    state: CommerceState,
    products: CommerceCatalogProduct[]
  ) {
    if (!hasCatalog(state)) return

    state.catalog!.products = products
  }
}
