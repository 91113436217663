export const SET_PRODUCT_EXPENSES_ERROR = 'SET_PRODUCT_EXPENSES_ERROR'

export const SET_PRODUCT_EXPENSES_LOADER = 'SET_PRODUCT_EXPENSES_LOADER'

export const SET_PRODUCT_EXPENSES = 'SET_PRODUCT_EXPENSES'

export const SET_PRODUCT_EXPENSES_FILTER = 'SET_PRODUCT_EXPENSES_FILTER'

export const SET_CONSUMPTION_ESTIMATION = 'SET_CONSUMPTION_ESTIMATION'

export const SET_CATEGORIZED_REPORT = 'SET_CATEGORIZED_REPORT'

export const SET_INTEGRATOR_WHATSAPP_NUMBERS_LOADER =
  'SET_INTEGRATOR_WHATSAPP_NUMBERS_LOADER'

export const SET_INTEGRATOR_WHATSAPP_NUMBERS_ERROR =
  'SET_INTEGRATOR_WHATSAPP_NUMBERS_ERROR'

export const SET_INTEGRATOR_WHATSAPP_NUMBERS = 'SET_INTEGRATOR_WHATSAPP_NUMBERS'
