export default {
  validateIntegration: {
    loader: false,
    success: false,
    error: false,
    errorDetails: false
  },
  validateResponse: {
    url: '',
    success: false,
    status: undefined,
    headersSent: undefined,
    response: undefined
  },
  validateRequest: undefined
}
