import {
  getCommerceCatalogService,
  getCommerceProductsService
} from '@/modules/Bot/services/commerce'
import types from '@/modules/Bot/store/mutations-types'

export const getCommerceCatalog = async context => {
  const { commerceCatalog } = context.getters

  if (Object.entries(commerceCatalog).length === 0) {
    context.commit(types.SET_COMMERCE_CATALOG_LOADER, true)

    const [catalog] = await getCommerceCatalogService()

    context.commit(types.SET_COMMERCE_CATALOG, catalog)

    if (catalog) {
      await context.dispatch('getCommerceProducts')
    }

    context.commit(types.SET_COMMERCE_CATALOG_LOADER, false)
  }
}

export const getCommerceProducts = async context => {
  const items = await getCommerceProductsService()

  const products = items.map(product => ({
    ...product,
    retailerId: product.sku
  }))

  context.commit(types.SET_COMMERCE_PRODUCTS, products)
}
