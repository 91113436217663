import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import legacyHeaders from '@/common/services/legacy-headers'

import { TicketStatus } from './store/interfaces'

export class TicketService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.createClient('ticket')) as AxiosInstance
  }

  getTicketStatus() {
    return this.getClient()
      .then(client => client.get<TicketStatus[]>('status', legacyHeaders()))
      .then(res => res.data)
  }
}
