import { CustomField } from '@/store/interfaces'

import { CustomFieldState } from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_CUSTOM_FIELDS](
    state: CustomFieldState,
    customFields: CustomField[]
  ) {
    state.customFields = customFields
  },
  [types.SET_ORGANIZATION_CUSTOM_FIELDS](
    state: CustomFieldState,
    customFields: CustomField[]
  ) {
    state.organizationCustomFields = customFields
  }
}
