export enum FieldAreaType {
  TICKETS_SEARCH = 1,
  TICKETS_AUTOMATION = 2,
  TICKETS_TRIGGER = 3,
  TICKETS_MACRO = 4,
  TICKETS_REPORT = 5,
  TICKETS_APP = 6,
  TICKETS_CUSTOM_WORKFLOW = 7,
  TICKETS_SLA = 8,
  TICKETS_SMART_FORM = 9,
  TICKETS_LIST = 10,
  PERSON_PROFILE = 11,
  ORGANIZATION_PROFILE = 12,
  EVENT_HANDLER = 13
}
