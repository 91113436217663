import { ActionContext } from 'vuex'

import { MessengerService } from '@/modules/ChatSettings/other-channels/messenger/messenger.service'

import { RootState } from '@/store/interfaces'

import {
  MessengerState,
  MessengerFacebookPages,
  MessengerInstagramPage,
  MessengerPageItemToRemove
} from './interfaces'

const messengerService = new MessengerService()

import * as types from './mutations-types'

export const setAuthorizedPages = (
  context: ActionContext<MessengerState, RootState>,
  value: {
    facebookPages: Array<MessengerFacebookPages>
    instagramAccounts: Array<MessengerInstagramPage>
  }
) => {
  const { subDomain } = context.rootGetters.userLogged

  const payload = {
    facebookPages: value.facebookPages.map(item => ({
      ...item,
      subDomain
    })),
    instagramAccounts: value.instagramAccounts.map(item => ({
      ...item,
      subDomain
    }))
  }

  context.commit(types.SET_AUTHORIZED_PAGES, payload)
}

export const setIsAuthorizedPagesModalOpen = (
  context: ActionContext<MessengerState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_IS_AUTHORIZED_PAGES_MODAL_OPEN, value)
}

export const setIsRemovePageConfirmationModalOpen = (
  context: ActionContext<MessengerState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_IS_REMOVE_PAGE_CONFIRMATION_MODAL_OPEN, value)
}

export const setIsInstagramAppSettingsModalOpen = (
  context: ActionContext<MessengerState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_IS_INSTAGRAM_APP_SETTINGS_MODAL_OPEN, value)
}

export const setPageItemToRemove = (
  context: ActionContext<MessengerState, RootState>,
  value: MessengerPageItemToRemove
) => {
  context.commit(types.SET_PAGE_ITEM_TO_REMOVE, value)
}

export const setFacebookPages = (
  context: ActionContext<MessengerState, RootState>,
  value: Array<MessengerFacebookPages>
) => {
  context.commit(types.SET_FACEBOOK_PAGES, value)
}

export const setInstagramPages = (
  context: ActionContext<MessengerState, RootState>,
  value: Array<MessengerInstagramPage>
) => {
  context.commit(types.SET_INSTAGRAM_PAGES, value)
}

export const getFacebookPages = async (
  context: ActionContext<MessengerState, RootState>
) => {
  const { subDomain } = context.rootGetters.userLogged

  await messengerService
    .retrievePages(subDomain)
    .then(response => {
      if (!response || !response.data) return

      const { facebookPages, instagramAccounts } = response.data

      context.dispatch('setFacebookPages', facebookPages)

      context.dispatch('setInstagramPages', instagramAccounts)
    })
    .catch(err => {
      console.error('Error at getting facebook pages: ', err)
    })
}

export const getInstagramPages = async (
  context: ActionContext<MessengerState, RootState>
) => {
  const { subDomain } = context.rootGetters.userLogged

  await messengerService
    .instagramRetrievePages(subDomain)
    .then(response => {
      context.dispatch('setInstagramPages', response)
    })
    .catch(err => {
      console.error('Error at getting instagram pages: ', err)
    })
}
