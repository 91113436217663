export enum IntegratorSteps {
  storeConnect = 'StoreConnect',
  storeFields = 'StoreFields',
  storeIntegration = 'StoreIntegration'
}

export enum CommerceIntegratorTypes {
  magento = 'magento',
  tray = 'tray'
}
