export default {
  companyModel: undefined,
  whatsappNumbers: {
    id: '',
    number: 5513981858616,
    syncSince: 1657737725359,
    isEnabled: true,
    name: '',
    dateCreation: '',
    subDomain: '',
    status: 2,
    info: {
      battery: 100,
      platform: '',
      connected: true,
      pushname: '',
      wid: '',
      lc: '',
      phone: {
        mcc: '',
        mnc: '',
        osVersion: '',
        deviceManufacturer: '',
        deviceModel: '',
        osBuildNumber: '',
        waVersion: ''
      },
      plugged: true,
      tos: 0,
      lg: '',
      is24h: true
    },
    lastInactiveCause: ''
  },
  integrator: {
    id: '',
    _id: '',
    subDomain: '',
    name: '',
    number: '',
    integrator: '',
    customFields: {
      aboutDescription: '',
      thumbUrl: '',
      businessProfile: {
        address: '',
        description: '',
        email: '',
        websites: [],
        vertical: ''
      },
      partnerChannel: {
        id: '',
        client_id: '',
        integration: {
          app_id: ''
        },
        waba_account: {
          id: '',
          on_behalf_of_business_info: {
            id: '',
            name: ''
          }
        }
      },
      externalAccountId: ''
    },
    privateCustomFields: {},
    createdAt: '',
    updatedAt: ''
  }
}
