import * as templates from './template-models'

export const getTemplate =
  (_, getters) => (fluxName, channel, templateName) => {
    const { getPersonProperty, userLogged, language } = getters

    if (templateName === 'blank') {
      return templates.blank(language, fluxName, channel)
    } else {
      return templates[templateName](
        getPersonProperty,
        userLogged,
        language,
        fluxName,
        channel
      )
    }
  }

export const templateList = ({ templateList }) => templateList

export const emptyTemplate = ({ emptyTemplate }) => emptyTemplate
