export const SET_SELECTED_SIDEBAR_TAB = 'SET_SELECTED_SIDEBAR_TAB'

export const SET_STATUS = 'SET_STATUS'

export const SET_PAGE = 'SET_PAGE'

export const SET_CHATS = 'SET_CHATS'

export const SET_CHATS_FILTER = 'SET_CHATS_FILTER'

export const SET_CHATS_SORT_FILTER = 'SET_CHATS_SORT_FILTER'

export const SET_UNREDEDCHATS = 'SET_UNREDEDCHATS'

export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT'

export const SET_IS_SEARCHING = 'SET_IS_SEARCHING'

export const SET_IS_LOADING_MORE = 'SET_IS_LOADING_MORE'

export const APPEND_CHATS = 'APPEND_CHATS'

export const APPEND_CHAT = 'APPEND_CHAT'

export const REMOVE_CHAT = 'REMOVE_CHAT'

export const TOGGLE_FILTER_PANEL = 'TOGGLE_FILTER_PANEL'

export const SET_TEMPORARY_FILTER = 'SET_TEMPORARY_FILTER'

export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER'

export const CLEAN_FILTERS = 'CLEAN_FILTERS'

export const UPDATE_CHAT = 'UPDATE_SIDEBAR_CHAT'

export const UPDATE_CHAT_LIST_PROPERTIES = 'UPDATE_CHAT_LIST_PROPERTIES'

export const SET_PHONE_CONTACTS = 'SET_PHONE_CONTACTS'

export const SET_HAS_MORE_CONTATCS = 'SET_HAS_MORE_CONTATCS'

export const SET_CONTACT_PAGE = 'SET_CONTACT_PAGE'

export const SET_WAITING_COUNT = 'SET_WAITING_COUNT'

export const SET_BOTS_FLUXES = 'SET_BOTS_FLUXES'

export const LOAD_CACHED_FILTER = 'LOAD_CACHED_FILTER'

export const SET_HAS_CHAT_CHANNEL = 'SET_HAS_CHAT_CHANNEL'

export const SET_USER_LOGGED_ASSIGNED_COUNT = 'SET_USER_LOGGED_ASSIGNED_COUNT'

export const SET_INFOS_MODAL_BAN = 'SET_SHOW_MODAL_BAN'

export const SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT'

export const SET_ENABLE_MODAL_BAN = 'SET_ENABLE_MODAL_BAN'

export const SET_CURRENT_INBOX = 'SET_CURRENT_INBOX'

export const SET_CONVERSATIONS_LIST_TYPE = 'SET_CONVERSATIONS_LIST_TYPE'

export const SET_LOADING_CONVERSATION_LIST = 'SET_LOADING_CONVERSATION_LIST'

export const SET_QTD_SELECTED_FILTERS = 'SET_QTD_SELECTED_FILTERS'

export const UPDATE_PHONE_CONTACT_PROPERTY = 'UPDATE_PHONE_CONTACT_PROPERTY'

export const FETCHING_CHAT_LIST = 'FETCHING_CHAT_LIST'

export const SET_RESPONSE_SERVICE_TIMEOUT_COUNT =
  'SET_RESPONSE_SERVICE_TIMEOUT_COUNT'

export const UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS'

export const SET_RESPONSE_SERVICE_TIMEOUT_INBOX =
  'SET_RESPONSE_SERVICE_TIMEOUT_INBOX'
