import types from '@/modules/Bot/store/mutations-types'
import * as officeHourTypes from '@/modules/Bot/store/types'

const findIndexByDayOfWeek = (state, dayOfWeek) =>
  state.findIndex(day => day.dayOfWeek === dayOfWeek)

export default {
  [types.SET_MODAL_CONTENT](state, modalType) {
    state.currentModalContent = modalType
  },
  [types.SET_TIMEZONES_LIST](state, timezonesList) {
    state.timezonesList = timezonesList
  },
  [types.SET_OFFICE_HOURS_LIST](state, officeHoursList) {
    state.officeHoursList = officeHoursList.reverse()
  },
  [types.SET_OFFICE_HOURS](state, officeHoursModel) {
    state.currentOfficeHours = { ...officeHoursModel }

    state.currentOfficeHoursOriginal = { ...officeHoursModel }

    if (
      officeHoursModel.daysOfWeek &&
      officeHoursModel.daysOfWeek.sameSchedule &&
      officeHoursModel.daysOfWeek.days &&
      officeHoursModel.daysOfWeek.days.length
    ) {
      state.sameScheduleHours = officeHoursModel.daysOfWeek.days[0].hours
    }
  },
  [types.CLEAR_CURRENT_OFFICE_HOURS](state) {
    const currentState = state

    state = {
      ...currentState,
      currentModalContent: undefined,
      currentOfficeHours: undefined,
      sameScheduleHours: [],
      formLoading: false
    }
  },
  [types.SET_CURRENT_OFFICE_HOURS_ID](state, officeHoursId) {
    state.currentOfficeHours = {
      ...state.currentOfficeHours,
      id: officeHoursId
    }
  },
  [types.SET_OFFICE_HOURS_NAME](state, officeHourName) {
    state.currentOfficeHours.name = officeHourName
  },
  [types.SET_TIMEZONE](state, officeHourTimeZone) {
    state.currentOfficeHours.timeZone = officeHourTimeZone
  },
  [types.SET_OPERATION_24_HOURS](state, is24hours) {
    state.currentOfficeHours.daysOfWeek.is24hours = is24hours

    if (is24hours) {
      const officeHours = state.currentOfficeHours

      delete officeHours.daysOfWeek.days

      delete officeHours.daysOfWeek.sameSchedule

      state.currentOfficeHours = officeHours
    } else {
      state.currentOfficeHours.daysOfWeek = {
        ...state.currentOfficeHours.daysOfWeek,
        days: officeHourTypes.officeHourDays,
        sameSchedule: false
      }
    }
  },
  [types.TOGGLE_SAME_SCHEDULE](state) {
    const sameScheduleIsActive =
      state.currentOfficeHours.daysOfWeek.sameSchedule

    state.currentOfficeHours.daysOfWeek.sameSchedule = !sameScheduleIsActive

    state.sameScheduleHours = sameScheduleIsActive
      ? []
      : [{ start: null, end: null }]

    if (!sameScheduleIsActive) {
      state.currentOfficeHours.daysOfWeek.days.forEach((day, dayIndex) => {
        state.currentOfficeHours.daysOfWeek.days[dayIndex].hours = [
          { start: null, end: null }
        ]
      })
    }
  },
  [types.SET_SAME_SCHEDULE_HOUR](state, payload) {
    const { hourIndex, hour, type } = payload

    state.sameScheduleHours[hourIndex][type] = hour

    state.currentOfficeHours.daysOfWeek.days.forEach((day, dayIndex) => {
      state.currentOfficeHours.daysOfWeek.days[dayIndex].hours[hourIndex][
        type
      ] = hour
    })
  },
  [types.ADD_SAME_SCHEDULE_HOUR](state) {
    state.sameScheduleHours.push({ start: null, end: null })

    state.currentOfficeHours.daysOfWeek.days.forEach((day, index) => {
      state.currentOfficeHours.daysOfWeek.days[index].hours = [
        ...state.currentOfficeHours.daysOfWeek.days[index].hours,
        {
          start: null,
          end: null
        }
      ]
    })
  },
  [types.DELETE_SAME_SCHEDULE_HOUR](state, hourIndex) {
    state.sameScheduleHours.splice(hourIndex, 1)

    state.currentOfficeHours.daysOfWeek.days.forEach((day, dayIndex) => {
      state.currentOfficeHours.daysOfWeek.days[dayIndex].hours.splice(
        hourIndex,
        1
      )
    })
  },
  [types.TOGGLE_AVAILABLE_DAY](state, dayOfWeek) {
    const dayIndex = findIndexByDayOfWeek(
      state.currentOfficeHours.daysOfWeek.days,
      dayOfWeek
    )

    if (dayIndex >= 0) {
      state.currentOfficeHours.daysOfWeek.days.splice(dayIndex, 1)
    } else {
      let hours = []

      if (state.currentOfficeHours.daysOfWeek.sameSchedule) {
        hours = [...state.sameScheduleHours]
      } else {
        hours = [{ start: null, end: null }]
      }

      state.currentOfficeHours.daysOfWeek.days.push({
        dayOfWeek,
        hours
      })
    }

    state.currentOfficeHours.daysOfWeek.days =
      state.currentOfficeHours.daysOfWeek.days.sort((a, b) =>
        a.dayOfWeek < b.dayOfWeek ? -1 : 0
      )
  },
  [types.SET_HOUR_IN_DAY](state, payload) {
    const { hourIndex, hour, type, dayOfWeek } = payload

    const dayIndex = findIndexByDayOfWeek(
      state.currentOfficeHours.daysOfWeek.days,
      dayOfWeek
    )

    if (dayIndex >= 0) {
      state.currentOfficeHours.daysOfWeek.days[dayIndex].hours[hourIndex][
        type
      ] = hour
    }
  },
  [types.DELETE_HOUR_IN_DAY](state, payload) {
    const { hourIndex, dayOfWeek } = payload

    const dayIndex = findIndexByDayOfWeek(
      state.currentOfficeHours.daysOfWeek.days,
      dayOfWeek
    )

    if (dayIndex >= 0) {
      state.currentOfficeHours.daysOfWeek.days[dayIndex].hours.splice(
        hourIndex,
        1
      )
    }
  },
  [types.ADD_NEW_HOUR_IN_DAY](state, dayOfWeek) {
    const dayIndex = findIndexByDayOfWeek(
      state.currentOfficeHours.daysOfWeek.days,
      dayOfWeek
    )

    if (dayIndex >= 0) {
      state.currentOfficeHours.daysOfWeek.days[dayIndex].hours.push({
        start: null,
        end: null
      })
    }
  },
  [types.TOGGLE_SPECIAL_HOURS](state) {
    state.currentOfficeHours.specialDates = {
      active: !state.currentOfficeHours.specialDates.active,
      dates: []
    }
  },
  [types.ADD_NEW_SPECIAL_DATE](state) {
    state.currentOfficeHours.specialDates.dates.push({
      date: '',
      hours: [
        {
          start: null,
          end: null
        }
      ]
    })
  },
  [types.DELETE_SPECIAL_DATE](state, dateIndex) {
    state.currentOfficeHours.specialDates.dates.splice(dateIndex, 1)
  },
  [types.SET_SPECIAL_DATE_DAY](state, payload) {
    const { date, dateIndex } = payload

    state.currentOfficeHours.specialDates.dates[dateIndex].date = date
  },
  [types.UPDATE_SPECIAL_DATE_AVAILABLE](state, payload) {
    const { isAvailable, dateIndex } = payload

    if (isAvailable) {
      state.currentOfficeHours.specialDates.dates[dateIndex].hours.push({
        start: null,
        end: null
      })
    } else {
      state.currentOfficeHours.specialDates.dates[dateIndex].hours = []
    }
  },
  [types.SET_SPECIAL_DATE_HOUR_IN_DATE](state, payload) {
    const { hourIndex, hour, type, dateIndex } = payload

    state.currentOfficeHours.specialDates.dates[dateIndex].hours[hourIndex][
      type
    ] = hour
  },
  [types.DELETE_SPECIAL_DATE_HOUR_IN_DATE](state, payload) {
    const { hourIndex, dateIndex } = payload

    state.currentOfficeHours.specialDates.dates[dateIndex].hours.splice(
      hourIndex,
      1
    )
  },
  [types.ADD_SPECIAL_DATE_NEW_HOUR_IN_DATE](state, dateIndex) {
    state.currentOfficeHours.specialDates.dates[dateIndex].hours.push({
      start: null,
      end: null
    })
  },
  [types.SET_FORM_LOADING](state, isLoading) {
    state.formLoading = isLoading
  },
  [types.SET_BOTS_WITH_OFFICE_HOURS](state, bots) {
    state.botsWithOfficeHours = [...bots]
  },
  [types.CLEAR_BOTS_WITH_OFFICE_HOURS](state) {
    state.botsWithOfficeHours = []
  }
}
