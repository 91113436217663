import { WozContentState, WozDocument, TableWozDocument } from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_WOZ_CONTENT_DOCUMENTS_LOADING](
    state: WozContentState,
    loading: boolean
  ) {
    state.documentsLoading = loading
  },
  [types.SET_WOZ_CONTENT_DOCUMENTS](
    state: WozContentState,
    documents: WozDocument[]
  ) {
    state.documents = documents
  },
  [types.SET_HAS_ANY_DOCUMENTS](state: WozContentState, hasDocuments: boolean) {
    state.hasAnyDocuments = hasDocuments
  },
  [types.SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE](
    state: WozContentState,
    tableDocuments: TableWozDocument[]
  ) {
    state.tableDocuments = tableDocuments
  },
  [types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING](
    state: WozContentState,
    loading: boolean
  ) {
    state.agent.documentsLoading = loading
  },
  [types.SET_AGENT_WOZ_CONTENT_DOCUMENTS](
    state: WozContentState,
    documents: WozDocument[]
  ) {
    state.agent.documents = documents
  },
  [types.SET_AGENT_HAS_ANY_DOCUMENTS](
    state: WozContentState,
    hasDocuments: boolean
  ) {
    state.agent.hasAnyDocuments = hasDocuments
  },
  [types.SET_AGENT_WOZ_CONTENT_DOCUMENTS_FOR_TABLE](
    state: WozContentState,
    tableDocuments: TableWozDocument[]
  ) {
    state.agent.tableDocuments = tableDocuments
  },
  [types.SET_LAST_SEARCH_PARAMETERS](
    state: WozContentState,
    lastSearchParameters: unknown
  ) {
    state.lastSearchParameters = lastSearchParameters
  }
}
