import { headers } from '@octadesk-tech/services'

import { getChatClient } from '@/modules/Chat/services/http'

const PUBLIC_TAGS = '/public-tags'

export const createRoomPublicTags = ({ roomKey, payload }) =>
  getChatClient().then(client =>
    client.post(
      `/rooms/${roomKey}/public-tags`,
      payload,
      headers.getAuthorizedHeaders()
    )
  )

export const recordRoomPublicTags = ({ roomKey }) =>
  getChatClient().then(client =>
    client.get(`/rooms/${roomKey}/public-tags`, headers.getAuthorizedHeaders())
  )

export const deleteRoomPublicTags = ({ roomKey, payload }) =>
  getChatClient().then(client =>
    client.delete(`/rooms/${roomKey}/public-tags`, {
      data: payload,
      ...headers.getAuthorizedHeaders()
    })
  )

export const createPublicTags = ({ payload }) =>
  getChatClient().then(client =>
    client.post(PUBLIC_TAGS, payload, headers.getAuthorizedHeaders())
  )

export const recordAutocompletePublicTags = ({ params } = {}) =>
  getChatClient().then(client =>
    client.get('/public-tags/autocomplete', {
      params,
      ...headers.getAuthorizedHeaders()
    })
  )

export const getTagsByIds = async (ids = []) => {
  const mappedIds = ids.map(id => `id=${id}`)

  const client = await getChatClient()

  const { data } = await client.get(
    `/public-tags/?${mappedIds.join('&')}`,
    headers.getAuthorizedHeaders()
  )

  return data
}
