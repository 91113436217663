import {
  Credits,
  ProductExpenses,
  ConsumptionEstimation,
  CategorizedReport,
  WppNumber,
  ProductExpensesFilters
} from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_PRODUCT_EXPENSES_ERROR](state: Credits, hasError: boolean) {
    state.productExpenses.hasError = hasError
  },
  [types.SET_PRODUCT_EXPENSES_LOADER](state: Credits, isLoading: boolean) {
    state.productExpenses.isLoading = isLoading
  },
  [types.SET_PRODUCT_EXPENSES](
    state: Credits,
    productExpenses: ProductExpenses
  ) {
    state.productExpenses.expenses = productExpenses.expenses

    state.productExpenses.total = productExpenses.total
  },
  [types.SET_PRODUCT_EXPENSES_FILTER](
    state: Credits,
    filters: ProductExpensesFilters
  ) {
    state.productExpenses.filters = filters
  },
  [types.SET_CONSUMPTION_ESTIMATION](
    state: Credits,
    consumptionEstimation: ConsumptionEstimation
  ) {
    state.consumptionEstimation = consumptionEstimation
  },
  [types.SET_CATEGORIZED_REPORT](
    state: Credits,
    categorizedReport: CategorizedReport
  ) {
    state.categorizedReport = categorizedReport
  },
  [types.SET_INTEGRATOR_WHATSAPP_NUMBERS_LOADER](
    state: Credits,
    isLoading: boolean
  ) {
    state.integratorWhatsappNumbers.isLoading = isLoading
  },
  [types.SET_INTEGRATOR_WHATSAPP_NUMBERS_ERROR](
    state: Credits,
    hasError: boolean
  ) {
    state.integratorWhatsappNumbers.hasError = hasError
  },
  [types.SET_INTEGRATOR_WHATSAPP_NUMBERS](
    state: Credits,
    integratorWhatsappNumbers: WppNumber[]
  ) {
    state.integratorWhatsappNumbers.numbers = integratorWhatsappNumbers
  }
}
