/* eslint-disable sonarjs/no-duplicate-string */
export default [
  {
    id: 'step-1',
    type: 'common',
    parentActionId: '',
    nextActionId: 'initial-greeting',
    connectionRules: []
  },
  {
    id: 'step-2',
    type: 'common',
    parentActionId: 'initial-greeting',
    nextActionId: 'collect-the-name',
    connectionRules: []
  },
  {
    id: 'step-3',
    type: 'common',
    parentActionId: 'collect-the-name',
    nextActionId: 'confirm-the-collected-name',
    connectionRules: []
  },
  {
    id: 'step-4',
    type: 'common',
    parentActionId: 'confirm-the-collected-name',
    nextActionId: 'collect-the-name',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['confirm-the-collected-name-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-5',
    type: 'common',
    parentActionId: 'confirm-the-collected-name',
    nextActionId: 'collect-email',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['confirm-the-collected-name-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-6',
    type: 'common',
    parentActionId: 'collect-email',
    nextActionId: 'confirm-collected-email',
    connectionRules: []
  },
  {
    id: 'step-7',
    type: 'common',
    parentActionId: 'confirm-collected-email',
    nextActionId: 'collect-email',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['confirm-collected-email-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-8',
    type: 'common',
    parentActionId: 'confirm-collected-email',
    nextActionId: 'is-the-team-available',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['confirm-collected-email-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-9',
    type: 'common',
    parentActionId: 'is-the-team-available',
    nextActionId: 'support-options',
    connectionRules: [
      {
        source: 'CURRENT_SESSION',
        matchType: '$eq',
        values: ['@OFFICE_HOURS_TRUE'],
        referenceProperty: 'properties.SESSION.is-the-team-available.value',
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-10',
    type: 'common',
    parentActionId: 'is-the-team-available',
    nextActionId: 'out-of-hours',
    connectionRules: [
      {
        source: 'CURRENT_SESSION',
        matchType: '$eq',
        values: ['@OFFICE_HOURS_FALSE'],
        referenceProperty: 'properties.SESSION.is-the-team-available.value',
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-11',
    type: 'common',
    parentActionId: 'out-of-hours',
    nextActionId: 'closing-message',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['out-of-hours-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-12',
    type: 'common',
    parentActionId: 'out-of-hours',
    nextActionId: 'fe-support-options',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['out-of-hours-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-13',
    type: 'common',
    parentActionId: 'fe-collect-another-reason',
    nextActionId: 'fe-feedback-another-motive',
    connectionRules: []
  },
  {
    id: 'step-14',
    type: 'common',
    parentActionId: 'fe-feedback-another-motive',
    nextActionId: 'fe-everythings-solved-4',
    connectionRules: []
  },
  {
    id: 'step-15',
    type: 'common',
    parentActionId: 'fe-everythings-solved-4',
    nextActionId: 'self-service-closure',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-4-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-16',
    type: 'common',
    parentActionId: 'fe-everythings-solved-4',
    nextActionId: 'fe-support-options',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-4-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-17',
    type: 'common',
    parentActionId: 'fe-support-options',
    nextActionId: 'fe-collect-another-reason',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-support-options-other'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-18',
    type: 'common',
    parentActionId: 'fe-support-options',
    nextActionId: 'fe-instructions-change-to-plan',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-support-options-change-plan'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-19',
    type: 'common',
    parentActionId: 'fe-instructions-change-to-plan',
    nextActionId: 'fe-everythings-solved-3',
    connectionRules: []
  },
  {
    id: 'step-20',
    type: 'common',
    parentActionId: 'fe-everythings-solved-3',
    nextActionId: 'self-service-closure',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-3-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-21',
    type: 'common',
    parentActionId: 'fe-everythings-solved-3',
    nextActionId: 'fe-support-options',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-3-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-22',
    type: 'common',
    parentActionId: 'fe-collect-complaint',
    nextActionId: 'fe-feedback-complaint',
    connectionRules: []
  },
  {
    id: 'step-23',
    type: 'common',
    parentActionId: 'fe-feedback-complaint',
    nextActionId: 'fe-everythings-solved-2',
    connectionRules: []
  },
  {
    id: 'step-24',
    type: 'common',
    parentActionId: 'fe-everythings-solved-2',
    nextActionId: 'self-service-closure',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-2-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-25',
    type: 'common',
    parentActionId: 'fe-everythings-solved-2',
    nextActionId: 'fe-support-options',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-2-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-26',
    type: 'common',
    parentActionId: 'fe-support-options',
    nextActionId: 'fe-collect-complaint',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-support-options-complaint'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-27',
    type: 'common',
    parentActionId: 'fe-support-options',
    nextActionId: 'fe-financial-doubts',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-support-options-doubts'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-28',
    type: 'common',
    parentActionId: 'fe-financial-doubts',
    nextActionId: 'fe-everythings-solved-1',
    connectionRules: []
  },
  {
    id: 'step-29',
    type: 'common',
    parentActionId: 'fe-everythings-solved-1',
    nextActionId: 'self-service-closure',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-1-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-30',
    type: 'common',
    parentActionId: 'fe-everythings-solved-1',
    nextActionId: 'fe-support-options',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-1-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-31',
    type: 'common',
    parentActionId: 'financial-doubts',
    nextActionId: 'connect-with-financial-team',
    connectionRules: []
  },
  {
    id: 'step-32',
    type: 'common',
    parentActionId: 'financial-doubts',
    nextActionId: 'self-service-closure',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['financial-doubts-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-33',
    type: 'common',
    parentActionId: 'financial-doubts',
    nextActionId: 'support-options',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['financial-doubts-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-34',
    type: 'fallback',
    parentActionId: 'connect-with-financial-team',
    nextActionId: 'closing-message',
    connectionRules: []
  },
  {
    id: 'step-35',
    type: 'common',
    parentActionId: 'support-options',
    nextActionId: 'financial-doubts',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['support-options-doubts'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-36',
    type: 'common',
    parentActionId: 'support-options',
    nextActionId: 'collect-complaint',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['support-options-complaint'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-37',
    type: 'common',
    parentActionId: 'collect-complaint',
    nextActionId: 'complaint-connect-with-the-team',
    connectionRules: []
  },
  {
    id: 'step-38',
    type: 'fallback',
    parentActionId: 'complaint-connect-with-the-team',
    nextActionId: 'closing-message',
    connectionRules: []
  },
  {
    id: 'step-39',
    type: 'common',
    parentActionId: 'support-options',
    nextActionId: 'change-plan',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['support-options-change-plan'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-40',
    type: 'common',
    parentActionId: 'change-plan',
    nextActionId: 'everythings-solved-0',
    connectionRules: []
  },
  {
    id: 'step-41',
    type: 'common',
    parentActionId: 'everythings-solved-0',
    nextActionId: 'self-service-closure',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['everythings-solved-0-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-42',
    type: 'common',
    parentActionId: 'everythings-solved-0',
    nextActionId: 'support-options',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['everythings-solved-0-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-43',
    type: 'common',
    parentActionId: 'support-options',
    nextActionId: 'collect-another-reason',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['support-options-other'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-44',
    type: 'common',
    parentActionId: 'collect-another-reason',
    nextActionId: 'feedback-another-reason',
    connectionRules: []
  },
  {
    id: 'step-45',
    type: 'common',
    parentActionId: 'feedback-another-reason',
    nextActionId: 'fe-everythings-solved-2-1',
    connectionRules: []
  },
  {
    id: 'step-46',
    type: 'common',
    parentActionId: 'fe-everythings-solved-2-1',
    nextActionId: 'self-service-closure',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-2-1-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-47',
    type: 'common',
    parentActionId: 'fe-everythings-solved-2-1',
    nextActionId: 'support-options',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['fe-everythings-solved-2-1-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-48',
    type: 'common',
    parentActionId: 'support-options',
    nextActionId: 'connect-with-support',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['support-options-human'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-49',
    type: 'fallback',
    parentActionId: 'connect-with-support',
    nextActionId: 'closing-message',
    connectionRules: []
  }
]
