import types from '@/modules/Bot/store/mutations-types'

export default {
  [types.SET_COMMERCE_CATALOG_LOADER](state, isLoader) {
    state.commerce.catalogLoader = isLoader
  },
  [types.SET_COMMERCE_CATALOG](state, catalog) {
    state.commerce.catalog = catalog
  },
  [types.SET_COMMERCE_PRODUCTS](state, items) {
    state.commerce.products.items.push(...items)
  }
}
