import { headers } from '@octadesk-tech/services'

import { getBaseClient } from '@/modules/Chat/services/http'

const getCommerceIntegrator = () => getBaseClient('commerceIntegrator')

export const getCommerceIntegrationsService = async () => {
  try {
    const { data } = await getCommerceIntegrator().then(client =>
      client.get('/configs', headers.getAuthorizedHeaders())
    )

    return data
  } catch (ex) {
    throw new Error('Error on get commerce integrations: ' + ex)
  }
}
