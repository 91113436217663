import { ProductsCommerceState } from './interfaces'

export const commerceProductAvailableAttributes = (
  state: ProductsCommerceState
) => state.attributes.items

export const commerceProductAttributes = (state: ProductsCommerceState) =>
  state.data.attributes

export const commerceProductVariants = (state: ProductsCommerceState) =>
  state.data.variants

export const commerceProduct = (state: ProductsCommerceState) => state.data

export const commerceProductLoader = (state: ProductsCommerceState) =>
  state.loader

export const commerceAvailableAttributesLoader = (
  state: ProductsCommerceState
) => state.attributes.loader
