import { TalktousState } from './interfaces'

const state: TalktousState = {
  area: 'helpCenter',
  formSelected: {
    id: '',
    name: '',
    link: '',
    linkStyle: '',
    embeddedCode: '',
    loading: false,
    formLinkCss: {},
    formSelectedError: false
  },
  forms: [],
  idLastForm: '',
  formsLoading: false,
  formsError: false
}

export default state
