import VueRouter from 'vue-router'

import { BILLING_STORE_ACTIONS, useStore } from '@/modules/Billing'
import modulesRoutes from '@/modules/routes'

import { IFRAME_IDS } from '@/common/helpers/enums/iframe-ids.enum'
import { RoleTypes } from '@/common/helpers/enums/role-types'
import { entryToTheSocketChat } from '@/common/helpers/middlewares/entryToTheSocketChat'
import { entryToTheSocketChatRoom } from '@/common/helpers/middlewares/entryToTheSocketChatRoom'
import { handleRouterError } from '@/common/helpers/utils/handle-router-error.util'
import { homeRedirect } from '@/common/helpers/utils/home-redirect.util'
import { isAuthorized } from '@/common/helpers/utils/is-authorized'
import { newWidgetRedirect } from '@/common/helpers/utils/new-widget-redirect.util'

import store from '@/store'

const TICKET_DATA_IMPORT = 'data-import'

const routes = [
  ...modulesRoutes,
  {
    path: '',
    beforeEnter: (to, from, next) => {
      return homeRedirect(next)
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/bot-builder/channel/:channel/version/:version/:fluxid',
    name: 'botBuilder',
    meta: {
      permissionLevel: RoleTypes.Admin,
      iframeId: IFRAME_IDS.CHAT_BOT_BUILDER
    }
  },
  {
    path: '/upgrade-chatbot',
    name: 'upgrade-chatbot',
    component: () =>
      import('@/modules/PromotionalUpgrade/pages/ChatbotUpgradePage.vue'),
    meta: {
      permissionLevel: RoleTypes.Admin
    }
  },
  {
    path: '/graphics/dashboard/:channel/:id',
    name: 'graphics',
    component: () => import('@/views/DashboardBot.vue'),
    meta: {
      permissionLevel: RoleTypes.Admin
    }
  },
  {
    path: '/settings',
    name: 'settings',
    redirect: '/chat-settings/whatsapp-oficial/numbers',
    children: [
      {
        name: 'settings.profile',
        path: '/profile/',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/person/profile/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        name: 'settings.apps',
        path: 'apps',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/market-place/list//',
          permissionLevel: RoleTypes.Admin
        }
      }
    ]
  },
  {
    path: '/apps',
    name: 'apps',
    children: [
      {
        path: 'nfbox',
        name: 'nfbox',
        meta: {
          internalRoute: 'embed/main/home/nfbox/nfbox//',
          iframeId: 'Main',
          permissionLevel: RoleTypes.Agent
        }
      }
    ]
  },
  {
    path: '/people',
    name: 'people',
    redirect: '/people/contacts',

    component: () => import('@/views/People.vue'),
    children: [
      {
        name: 'people-contacts-edit',
        path: 'contacts/edit/:id',
        props: true,
        meta: {
          permissionLevel: RoleTypes.CorporatePerson
        },
        component: () =>
          import(
            '@/common/components/contacts/ContactEdit/ContactEditContainer.vue'
          )
      },
      {
        name: 'people-contacts-create',
        path: 'contacts/create',
        props: true,
        meta: {
          permissionLevel: RoleTypes.CorporatePerson
        },
        component: () =>
          import(
            '@/common/components/contacts/ContactEdit/ContactEditContainer.vue'
          )
      },
      {
        name: 'people-contacts',
        path: 'contacts',
        meta: {
          permissionLevel: RoleTypes.CorporatePerson
        },
        component: () =>
          import('@/common/components/contacts/ContactList/ContactList.vue')
      },
      {
        path: 'contact-profile',
        redirect: to => {
          return {
            name: `contacts`,
            query: undefined,
            params: {
              id: to?.query?.routeId,
              origin: to?.query?.origin
            }
          }
        }
      },
      {
        name: 'organizations',
        path: 'organizations',
        meta: {
          permissionLevel: RoleTypes.CorporatePerson
        },

        component: () => import('@/views/Organizations.vue')
      },
      {
        name: 'fields',
        path: 'fields',
        meta: {
          permissionLevel: RoleTypes.CorporatePerson,
          iframeId: 'Main',
          internalRoute: 'embed/main/home/fields/list/2/2'
        }
      },
      {
        name: 'organization-fields',
        path: 'organization-fields',
        meta: {
          permissionLevel: RoleTypes.CorporatePerson,
          iframeId: 'Main',
          internalRoute: 'embed/main/home/fields/list/5/5'
        }
      },
      {
        path: TICKET_DATA_IMPORT,
        name: TICKET_DATA_IMPORT,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/data-import/list//',
          permissionLevel: RoleTypes.Admin
        }
      }
    ]
  },
  {
    name: 'settings.tray',
    path: '/chat-settings/tray-settings',
    beforeEnter: (to, from, next) => {
      return newWidgetRedirect(next)
    },
    component: () => import('@/modules/Tray/Chat.vue')
  },
  {
    path: '/edit/:userId',
    name: 'edit-user',
    alias: '/settings/edit/:userId',
    component: () => import('@/views/EditUser.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/ChangePassword.vue')
  },
  {
    name: 'widget-settings',
    path: '/settings/widget',
    meta: {
      permissionLevel: RoleTypes.Admin
    },
    component: () => import('@/views/WidgetSettings.vue')
  },
  {
    path: '/ticket/lp',
    name: 'ticket-lp',
    component: () => import('@/views/TicketLandingPage.vue'),
    beforeEnter(_to, _from, next) {
      useStore().dispatch(BILLING_STORE_ACTIONS.LOAD_INITAL).finally(next)
    }
  },
  {
    path: '/woz/lp',
    name: 'woz-lp',
    component: () =>
      import('@/modules/Woz/landing-page/components/WozLandingPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

router.onError(error => {
  handleRouterError(error, store)
})

router.beforeEach((to, from, next) => {
  const noRouteMatch = !router.resolve(to.path).resolved.matched.length

  entryToTheSocketChat(to, from)

  entryToTheSocketChatRoom(to, from, store.getters?.chatKey)

  if (to?.meta?.permissionLevel) {
    if (isAuthorized(to.meta.permissionLevel)) {
      next()
    } else {
      next('/')
    }
  } else if (noRouteMatch) {
    next('/')
  } else {
    next()
  }
})

router.afterEach(({ path }) => {
  const _hsq = (window._hsq = window._hsq || [])

  _hsq.push(['setPath', path])

  _hsq.push(['trackPageView'])
})

export default router
