import { headers } from '@octadesk-tech/services'

import { DomainType } from '@/modules/Bot/enums/custom-fields-enum'
import { getBaseClient } from '@/modules/Bot/services/http'

const getCustomFieldsClient = () => getBaseClient('customFields')

export const getCustomFields = async () => {
  const client = await getCustomFieldsClient()

  const { Person, Chat, Organization } = DomainType

  const values = await Promise.all([
    client.get(
      `/system-type/${Person}?showEnabledItems=true`,
      headers.getAuthorizedHeaders()
    ),
    client.get(
      `/system-type/${Chat}?showEnabledItems=true`,
      headers.getAuthorizedHeaders()
    ),
    client.get(
      `/system-type/${Organization}?showEnabledItems=true`,
      headers.getAuthorizedHeaders()
    )
  ])

  return values.reduce((fields, current) => fields.concat(current.data), [])
}

export const updatePersonFields = async person => {
  await getCustomFieldsClient()
    .then(client => client.post('/', person, headers.getAuthorizedHeaders()))
    .catch(error => {
      console.error('Error on update custom field', error)
    })
}
