import { Store } from 'vuex'

export const mappedErrors = [
  'Failed to fetch dynamically imported module',
  'Importing a module script failed',
  'Failed to load module script',
  'Expected a JavaScript module script but the server responded with a MIME type of "text/html"',
  'Strict MIME type checking is enforced for module scripts per HTML spec.'
]

export const handleRouterError = (error: Error, store: Store<any>) => {
  console.log(
    '%cRouter',
    'background: #D43B11; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
    error.message
  )

  const dispatchReloadPageAlert = mappedErrors.some(errorMessage =>
    error.message.includes(errorMessage)
  )

  if (dispatchReloadPageAlert) {
    store.dispatch('setReloadPageAlert')
  }
}
