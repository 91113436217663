import { RoleTypes } from '@/common/helpers/enums/role-types'

export default {
  path: 'other-channels',
  name: 'chat-settings-other-channels',
  redirect: '/chat-settings/other-channels/whatsapp-web',
  component: () => import('./OtherChannels.vue'),
  children: [
    {
      path: 'whatsapp-web',
      name: 'chat-settings-other-channels-whatsapp-web',
      component: () => import('./whatsapp-web/components/WebNumbers.vue')
    },
    {
      path: 'chat',
      name: 'chat-settings-other-channels-chat',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      component: () =>
        import('./chat/components/SettingsAppearanceAndInstalation.vue')
    },
    {
      path: 'messenger',
      name: 'chat-settings-other-channels-messenger',
      component: () => import('./messenger/components/SettingsMessenger.vue')
    },
    {
      path: 'email',
      name: 'chat-settings-other-channels-email',
      component: () => import('./email/components/EmailForwarding.vue')
    }
  ]
}
