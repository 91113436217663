import { PermissionsSteps } from '@/modules/Commerce/permissions/permissions.enum'

import {
  PermissionsCommerceState,
  WhatsAppNumbers,
  FacebookBusinessAccounts,
  FacebookSelectedAccount
} from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_COMMERCE_PERMISSIONS_CURRENT_STEP](
    state: PermissionsCommerceState,
    step: { name: PermissionsSteps; number: number }
  ) {
    state.currentStep = step
  },
  [types.SET_COMMERCE_WHATSAPP_NUMBERS](
    state: PermissionsCommerceState,
    whatsappNumbers: Array<WhatsAppNumbers>
  ) {
    state.whatsappNumbers = whatsappNumbers
  },
  [types.SET_COMMERCE_FACEBOOK_BUSINESS_ACCOUNTS](
    state: PermissionsCommerceState,
    facebookBusinessAccounts: Array<FacebookBusinessAccounts>
  ) {
    state.facebook.businessAccounts = facebookBusinessAccounts
  },
  [types.SET_COMMERCE_FACEBOOK_TOKEN](
    state: PermissionsCommerceState,
    token: string
  ) {
    state.facebook.token = token
  },
  [types.SET_COMMERCE_FACEBOOK_PERMISSIONS_ERROR](
    state: PermissionsCommerceState,
    isError: boolean
  ) {
    state.facebook.permissionsError = isError
  },
  [types.SET_COMMERCE_FACEBOOK_TWO_FACTORY_STEP](
    state: PermissionsCommerceState,
    payload: { show: boolean; loader: boolean }
  ) {
    state.facebook.twoFactoryStep = payload
  },
  [types.SET_COMMERCE_FACEBOOK_SELECTED_ACCOUNT](
    state: PermissionsCommerceState,
    payload: FacebookSelectedAccount
  ) {
    state.facebook.selectedAccount = payload
  },
  [types.SET_COMMERCE_CREATE_CATALOG_ERROR](
    state: PermissionsCommerceState,
    payload: { show: boolean; i18nMessageKey: string }
  ) {
    state.createCatalogError = payload
  }
}
