import dayjs from 'dayjs'
import lodash from 'lodash'
import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import { createI18n } from 'vue-i18n-composable'

import Storage from '@octadesk-tech/storage'

import 'dayjs/locale/en'

import 'dayjs/locale/pt-br'

import { LanguageTypes } from '@/common/helpers/enums/language'

import NucleusService from '@/common/services/nucleus'

import messages from './locales'

Vue.use(VueI18n)

const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key]

export const getCulture = () =>
  (Storage.getItem('culture') || Storage.getItem('language')) as string

export const language = getCulture() || LanguageTypes.ptBR

if (language) {
  if (language.startsWith('en')) {
    dayjs.locale('en')
  } else {
    dayjs.locale(language.toLowerCase())
  }
}

const missingKeyHandler = function (lng: string, ns: string) {
  console.warn('missing key', ns)

  // const nucleusService = new NucleusService()

  // nucleusService.createKeyFromFallback(ns).then(
  //   response => response && console.info(response),
  //   err => console.error('Error on missingKeyHandler: ', err)
  // )
}

const i18n = createI18n({
  missing: missingKeyHandler,
  silentTranslationWarn: false,
  silentFallbackWarn: false
})

export function loadLanguageAsync(lng: string) {
  const nucleusService = new NucleusService()

  return nucleusService.getTranslationByLang(lng.toLowerCase()).then(
    nucleusTags => {
      i18n.locale = lng

      const localTags = getKeyValue<LocaleMessages, keyof LocaleMessages>(
        messages,
        lng
      )

      const tagsMerge = lodash.merge(nucleusTags, localTags)

      i18n.setLocaleMessage(lng, tagsMerge)
    },
    err => console.error('Error on loadLanguageAsync: ', lng, err)
  )
}

export const mergeLocaleMessage = (
  locale: string,
  messages: VueI18n.LocaleMessageObject
) => {
  i18n.mergeLocaleMessage(locale, messages)
}

export const translate = (key: string, values?: VueI18n.Values) =>
  i18n?.t(key, values)

export default i18n
