import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const handleParsePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return ''

  if (!/^\+/.test(phoneNumber)) phoneNumber = `+${phoneNumber}`

  const parsedNumber = parsePhoneNumberFromString(phoneNumber)

  return `+${
    parsedNumber?.countryCallingCode
  } ${parsedNumber?.formatNational()}`
}
