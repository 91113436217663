import { TicketBaseAttribute } from '@/common/helpers/interfaces/ticket-base'

import PersonsService from '@/common/services/persons'

import { DataSourceResolver } from './interfaces'

let service: PersonsService
const getService = () => {
  if (service) {
    return service
  }
  service = new PersonsService()
  return service
}

export const groups: DataSourceResolver = () =>
  getService()
    .getGroups()
    .then((status: TicketBaseAttribute[]) =>
      status.map(status => ({
        id: status.id as string,
        name: status.name as string
      }))
    )
