export const isEnableNumberModalOpen = ({ isEnableNumberModalOpen }: any) =>
  isEnableNumberModalOpen

export const enableNumberCurrentStep = ({ enableNumberCurrentStep }: any) =>
  enableNumberCurrentStep

export const enableNumberStatusPayload = ({ enableNumberStatusPayload }: any) =>
  enableNumberStatusPayload

export const enableNumberIntegratorPayload = ({
  enableNumberIntegratorPayload
}: any) => enableNumberIntegratorPayload

export const enableNumberHasKeyPayload = ({ enableNumberHasKeyPayload }: any) =>
  enableNumberHasKeyPayload

export const enableNumberNumberStatusPayload = ({
  enableNumberNumberStatusPayload
}: any) => enableNumberNumberStatusPayload

export const enableNumberKeyPayload = ({ enableNumberKeyPayload }: any) =>
  enableNumberKeyPayload

export const enableNumberProfileDescriptionPayload = ({
  enableNumberProfileDescriptionPayload
}: any) => enableNumberProfileDescriptionPayload

export const enableNumberProfileDetailsPayload = ({
  enableNumberProfileDetailsPayload
}: any) => enableNumberProfileDetailsPayload

export const editableNumberProfile = ({ editableNumberProfile }: any) =>
  editableNumberProfile

export const integratorAccountStatus = ({ integratorAccountStatus }: any) =>
  integratorAccountStatus
