import { CUSTOM_FIELD_DOMAIN_TYPE } from '@/modules/Bot/enums/CustomFieldDomainType.enum'

import i18n from '@/common/i18n'

const fieldTypes = {
  1: 'string',
  2: 'string',
  3: 'string',
  4: 'boolean',
  5: 'number',
  6: 'float',
  7: 'date'
}

const resolveExample = type => {
  switch (type) {
    case 'string':
      return i18n?.t('bot.newChatBot.properties.example.string')
    case 'boolean':
      return i18n?.t('bot.newChatBot.properties.example.boolean')
    case 'number':
      return i18n?.t('10203040')
    case 'float':
      return i18n?.t('bot.newChatBot.properties.example.float')
    case 'date':
      return i18n?.t('bot.newChatBot.properties.example.date')
  }

  return ''
}

const filterActionReturnMap = returnMap => {
  return returnMap.filter(
    prop =>
      prop?.returnProperty &&
      prop.returnProperty.domain === CUSTOM_FIELD_DOMAIN_TYPE.CHAT
  )
}

const getChatPropertiesFromReturnMap = (property, returnObject) => {
  if (!returnObject.keys.includes(property.name)) {
    returnObject.keys.push(property.name)
    returnObject.properties.push({
      ...property,
      example: resolveExample(property.type)
    })
  }
}

export const fixedPersonProperties = () => [
  {
    token: i18n?.t('bot.newChatBot.properties.person.name.token'),
    example: i18n?.t('bot.newChatBot.properties.person.name.example'),
    domain: 'PERSON',
    type: 'string',
    name: 'name'
  },
  {
    token: i18n?.t('bot.newChatBot.properties.person.email.token'),
    example: i18n?.t('bot.newChatBot.properties.person.email.example'),
    domain: 'PERSON',
    type: 'email',
    name: 'email'
  },
  {
    token: i18n?.t('bot.newChatBot.properties.person.phone.token'),
    example: i18n?.t('bot.newChatBot.properties.person.phone.example'),
    domain: 'PERSON',
    type: 'phone',
    name: 'phone'
  },
  {
    token: i18n?.t('bot.newChatBot.properties.person.business.token'),
    example: i18n?.t('bot.newChatBot.properties.person.phone.business.example'),
    domain: 'PERSON',
    type: 'phone',
    name: 'phoneContact.business'
  },
  {
    token: i18n?.t('bot.newChatBot.properties.person.phone.home.token'),
    example: i18n?.t('bot.newChatBot.properties.person.phone.business.example'),
    domain: 'PERSON',
    type: 'phone',
    name: 'phoneContact.home'
  },
  {
    token: i18n?.t('bot.newChatBot.properties.person.contactStatus.token'),
    example: '',
    domain: 'PERSON',
    type: 'select',
    name: 'idContactStatus',
    dataSource: 'contactStatus'
  }
]

export const fixedOrganizationProperties = () => [
  {
    token: i18n?.t('bot.newChatBot.properties.organization.name.token'),
    example: i18n?.t('bot.newChatBot.properties.organization.name.example'),
    domain: 'ORGANIZATION',
    type: 'string',
    name: 'name'
  },
  {
    token: i18n?.t(
      'bot.newChatBot.properties.organization.firstPhoneContact.token'
    ),
    example: i18n?.t(
      'bot.newChatBot.properties.organization.firstPhoneContact.example'
    ),
    domain: 'ORGANIZATION',
    type: 'string',
    name: 'firstPhoneContact'
  },
  {
    token: i18n?.t('bot.newChatBot.properties.organization.firstDomain.token'),
    example: i18n?.t(
      'bot.newChatBot.properties.organization.firstDomain.example'
    ),
    domain: 'ORGANIZATION',
    type: 'string',
    name: 'firstDomain'
  }
]

export const personProperties = (
  state,
  { personFields, fixedPersonProperties },
  _,
  { isResponsibleContactEnabled }
) => {
  const customPersonProperties = personFields
    .filter(personF => !personF.property)
    .map(h => {
      const type = fieldTypes[h.type] || 'string'

      return {
        type,
        token: `#${h.fieldId.replace(/_/g, '-')}-contato`,
        domain: CUSTOM_FIELD_DOMAIN_TYPE.PERSON,
        name: `customField.${h.fieldId}`,
        example: resolveExample(type)
      }
    })

  const parsedFixedPersonProperties = [...fixedPersonProperties]

  if (isResponsibleContactEnabled) {
    parsedFixedPersonProperties.splice(2, 0, {
      token: 'bot.newChatBot.properties.person.responsibleContact.token',
      example: 'bot.newChatBot.properties.person.responsibleContact.example',
      domain: 'PERSON',
      type: 'responsavel-contato',
      name: 'responsavel-contato'
    })
  }

  return [...parsedFixedPersonProperties, ...customPersonProperties]
}

export const organizationProperties = (
  _,
  { organizationFields, fixedOrganizationProperties }
) => {
  const customOrganizationProperties = organizationFields.map(h => {
    const type = fieldTypes[h.type] || 'string'

    return {
      type,
      token: `#${h.fieldId.replace(/_/g, '-')}-organizacao`,
      domain: CUSTOM_FIELD_DOMAIN_TYPE.ORGANIZATION,
      name: `customField.${h.fieldId}`,
      example: resolveExample(type)
    }
  })

  return [...fixedOrganizationProperties, ...customOrganizationProperties]
}

export const selectableChatProperties = (
  _,
  { chatFields, existentChatProperties }
) => {
  const defaultProperties = chatFields
    .filter(f => f.defaultOnBot)
    .map(h => {
      const type =
        typeof h.type === 'string' ? h.type : fieldTypes[h.type] || 'string'

      return {
        type,
        token: `#${h.fieldId.replace(/_/g, '-')}`,
        domain: CUSTOM_FIELD_DOMAIN_TYPE.CHAT,
        name: h.property || `customField.${h.fieldId}`,
        example: resolveExample(type),
        readOnly: !!h.property
      }
    })

  return [...defaultProperties, ...existentChatProperties]
}

export const writeableChatProperties = (_, { selectableChatProperties }) => {
  return selectableChatProperties.filter(h => !h.readOnly)
}

export const actionsWithProperties = (_, { botActions }) => {
  return botActions.filter(action => action.content?.property)
}

export const actionsWithReturnMap = (_, { botActions }) => {
  return botActions.filter(
    action =>
      action.content?.returnMap?.length &&
      action.content.returnMap.some(prop => prop?.returnProperty)
  )
}

export const propertiesFromActions = (_, { actionsWithProperties }) =>
  actionsWithProperties
    .filter(
      action =>
        action.content?.property &&
        action.content.property.domain === CUSTOM_FIELD_DOMAIN_TYPE.CHAT
    )
    .map(action => {
      return {
        ...action.content.property,
        example: resolveExample(action.content.property.type)
      }
    })

export const propertiesFromReturnMap = (
  _,
  { actionsWithReturnMap, propertiesFromActions }
) =>
  actionsWithReturnMap.reduce(
    (agg, action) => {
      const filteredMap = filterActionReturnMap(action.content.returnMap)
      filteredMap.forEach(prop =>
        getChatPropertiesFromReturnMap(prop.returnProperty, agg)
      )
      return agg
    },
    {
      keys: propertiesFromActions.map(p => p.name),
      properties: propertiesFromActions
    }
  )

export const treeChatProperties = (_, { propertiesFromReturnMap }) => [
  ...propertiesFromReturnMap.properties
]

export const existentChatProperties = (
  { sessionChatProperties },
  { treeChatProperties }
) => {
  const chatProps = [...treeChatProperties, ...sessionChatProperties].reduce(
    (acc, curr) => {
      acc[curr.name] = curr
      return acc
    },
    {}
  )

  return Object.values(chatProps)
    .sort((a, b) => (a.token > b.token ? 1 : 0))
    .map(item => {
      return {
        ...item,
        example: resolveExample(item.type)
      }
    })
}

export const getPersonProperty =
  (_, { personProperties }) =>
  name =>
    personProperties.find(
      prop =>
        prop.domain === CUSTOM_FIELD_DOMAIN_TYPE.PERSON && prop.name === name
    )

export const getTreeProperties = (
  _,
  { actionsWithProperties, treeChatProperties }
) => {
  const personProperties = actionsWithProperties
    .filter(
      action =>
        action.content.property.domain === CUSTOM_FIELD_DOMAIN_TYPE.PERSON
    )
    .map(action => action.content.property)

  const props = [...treeChatProperties, ...personProperties].reduce(
    (acc, curr) => {
      acc[curr.name + curr.domain] = curr
      return acc
    },
    {}
  )

  return Object.values(props)
}
