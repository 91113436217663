import {
  OrganizationState,
  Organization,
  OrganizationMembers,
  PayloadToggle
} from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_ORGANIZATIONS](
    state: OrganizationState,
    organizations: Array<Organization>
  ) {
    state.organizations = organizations
  },
  [types.APPEND_ORGANIZATIONS](
    state: OrganizationState,
    organizations: Array<Organization>
  ) {
    state.organizations = state.organizations?.concat(organizations)
  },
  [types.CREATE_ORGANIZATION](
    state: OrganizationState,
    organization: Organization
  ) {
    state.organizations?.push(organization)
  },
  [types.REMOVE_ORGANIZATION](
    state: OrganizationState,
    organization: Organization
  ) {
    if (state.organizations) {
      state.organizations = state.organizations.filter(
        org => org.id !== organization.id
      )
    }
  },
  [types.UPDATE_ORGANIZATION](
    state: OrganizationState,
    organization: Organization
  ) {
    state.organizations = state.organizations?.map(org => {
      if (org.id === organization.id) {
        return organization
      }
      return org
    })
  },
  [types.TOGGLE_ORGANIZATION](
    state: OrganizationState,
    payload: PayloadToggle
  ) {
    const { id, toggle } = payload
    state.organizations = state.organizations?.map(org => {
      if (org.id === id) {
        return { ...org, isEnabled: toggle }
      }
      return org
    })
  },
  [types.ENABLE_ORGANIZATIONS](
    state: OrganizationState,
    organizations: Array<string>
  ) {
    state.organizations = state.organizations?.map(org => {
      if (organizations.includes(org.id)) {
        return { ...org, isEnabled: true }
      }
      return org
    })
  },
  [types.DISABLE_ORGANIZATIONS](
    state: OrganizationState,
    organizations: Array<string>
  ) {
    state.organizations = state.organizations?.map(org => {
      if (organizations.includes(org.id)) {
        return { ...org, isEnabled: false }
      }
      return org
    })
  },
  [types.SET_ORGANIZATIONS_COUNT](
    state: OrganizationState,
    organizationsCounter: number
  ) {
    state.organizationsCounter = organizationsCounter
  },
  [types.SET_ORGANIZATION_MEMBERS](
    state: OrganizationState,
    organizationMembers: Array<OrganizationMembers>
  ) {
    state.organizationMembers = organizationMembers
  },
  [types.SET_ORGANIZATION_MEMBERS_COUNT](
    state: OrganizationState,
    organizationMembersCount: number
  ) {
    state.organizationMembersCount = organizationMembersCount
  },
  [types.APPEND_ORGANIZATION_MEMBERS](
    state: OrganizationState,
    organizationMembers: Array<OrganizationMembers>
  ) {
    state.organizationMembers =
      state.organizationMembers?.concat(organizationMembers)
  }
}
