import {
  DataSourceState,
  DataSourceValue
} from '@/store/interfaces/data-source-state'

const state: DataSourceState = {
  storageDataSources: new Map<string, DataSourceValue[]>()
}

export default state
