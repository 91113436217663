import { Component } from 'vue'

import {
  NucleusPlanFeature,
  NucleusRecurrence,
  NucleusRecurrencePlan
} from '@/modules/Billing'

export type CheckoutItem = {
  code: string
  name: string
  description?: string
  qty: number
  secondaryQty: number
  price: number
  total?: number
  // This flag intent's to exclude item from checkout
  isExcludedFromCheckout?: boolean
  component?: Component
  priority?: number
  disclaimer?: string
  proRata?: number
  recurring?: number
  benefits?: string[]
  isAdditional?: boolean
  baseLimit: number
  balanceQty: number
  secondaryBalanceQtyToBeReleased: number
  isFranchise?: boolean
  expirationDate?: string
  billingControlType?: number
  balanceQtyToBeReleased: number
}

export type CheckoutDiscount = {
  code: string
  cupom?: string
  description: string
  value: string
  component?: Component
  order?: number
}

export interface CheckoutDisclaimer {
  message: string
  component?: Component
  order?: number
}

export type CheckoutPaymentMethod = {
  code: string
  description?: string
  details: Record<string, unknown>
}

export interface CheckoutTerm extends CheckoutDisclaimer {
  code: string
  accept: boolean
}

export type CheckoutCompany = {
  name: string
  email: string
  phone: string
  postal: string
  address1: string
  address2: string
  number?: string
  district: string
  city: string
  state: string
  country: string
  contactName: string
  contactEmail: string
}

export type CheckoutPaymentType = {
  code: string
  description: string
  name: string
  value: number
}

export enum CHECKOUT_TYPES {
  CHANGE_PLAN = 'CHANGE_PLAN',
  BUY_TICKET = 'BUY_TICKET',
  ADDITIONALS = 'ADDITIONALS'
}

export interface UnloggedInfo {
  access_token: string
  tenantId: string
  subdomain: string
  user: {
    tenant: {
      contactName: string | null
    }
  }
}

interface PlanFeature extends NucleusPlanFeature {
  qty?: number
}

export interface Plan extends Omit<NucleusRecurrencePlan, 'features'> {
  features: PlanFeature[]
}

export interface Checkout {
  currentRecurrence: Omit<NucleusRecurrence, 'plans'> | null
  currentPlan: Plan | null
  type?: CHECKOUT_TYPES
  tenantId: string
  items: CheckoutItem[]
  previewItems: CheckoutItem[]
  discounts: CheckoutDiscount[]
  disclaimers: CheckoutDisclaimer[]
  payment: CheckoutPaymentMethod
  terms: CheckoutTerm[]
  company: CheckoutCompany
  payments?: CheckoutPaymentType[]
  total: number
  discountTotal?: number
  subTotal?: number
  unloggedInfo?: UnloggedInfo
  typeStore?: boolean
  isFranchiseCheckout?: boolean
  isOneTime?: boolean
  userItem: CheckoutItem | null
  planItem: CheckoutItem | null
  couponCode: string
}
