export const SET_IS_ENABLE_NUMBER_MODAL_OPEN = 'SET_IS_ENABLE_NUMBER_MODAL_OPEN'

export const SET_ENABLE_NUMBER_CURRENT_STEP = 'SET_ENABLE_NUMBER_CURRENT_STEP'

export const SET_ENABLE_NUMBER_STEP_PAYLOAD = 'SET_ENABLE_NUMBER_STEP_PAYLOAD'

export const CLEAR_ENABLE_NUMBER_STEP_PAYLOADS =
  'CLEAR_ENABLE_NUMBER_STEP_PAYLOADS'

export const SET_EDITABLE_NUMBER_PROFILE = 'SET_EDITABLE_NUMBER_PROFILE'

export const SET_INTEGRATOR_ACCOUNT_STATUS = 'SET_INTEGRATOR_ACCOUNT_STATUS'
