import * as types from '@/modules/Chat/components/conversation/store/mutations-types/ticket'

export default {
  [types.SET_USER_TICKETS_COUNT](state, count) {
    state.ticket.userTicketsCount = count
  },
  [types.SET_USER_LATEST_TICKETS](state, latestTickets = []) {
    state.ticket.latestTickets = latestTickets
  }
}
