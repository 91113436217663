import { AxiosInstance } from 'axios'

import { headers } from '@octadesk-tech/services'

import { WidgetSettings } from '@/modules/Widget/types'

import { getClient } from './http'

export interface CreateSettingsPayload {
  data?: Partial<WidgetSettings>
  subDomain: string
  agentId: string
}

export interface UpdateSettingsPayload {
  id: string
  data: WidgetSettings
  subDomain: string
  agentId: string
}

export class WidgetManagerService {
  getClient(): Promise<AxiosInstance> {
    return getClient('widgetManagerApi')
  }

  async createSettings(payload: CreateSettingsPayload) {
    try {
      const client = await this.getClient()
      const { data } = await client?.post(
        `/configuration/${payload?.subDomain}/`,
        payload,
        headers.getAuthorizedHeaders()
      )
      return data
    } catch (ex) {
      console.error('Error on create: ', ex)
    }
  }

  async publishSettings(payload: UpdateSettingsPayload): Promise<any> {
    try {
      const client = await this.getClient()
      return await client?.put(
        `/configuration/${payload.subDomain}/`,
        payload,
        headers.getAuthorizedHeaders()
      )
    } catch (ex) {
      console.error('Error on updatedSettings: ', ex)
      return ex
    }
  }

  async getSettings(subDomain: string) {
    try {
      const client = await this.getClient()
      const { data } = await client.get(
        `/configuration/${subDomain}`,
        headers.getAuthorizedHeaders()
      )
      return data
    } catch (ex) {
      console.error('Error on saveSettings: ', ex)
    }
  }
}
