import { BILLING_STORE_ACTIONS, useStore } from '@/modules/Billing'

import store from '@/store'

import { FINANCIAL_MANAGEMENT_ROUTES } from '../financial-management/enums'

export default {
  path: 'whatsapp-oficial',
  name: 'chat-settings-whatsapp-oficial',
  redirect: '/chat-settings/whatsapp-oficial/numbers',
  component: () => import('./WhatsAppOficial.vue'),
  children: [
    {
      path: 'numbers',
      name: 'chat-settings-whatsapp-oficial-numbers',
      component: () => import('./numbers/components/OfficialNumbers.vue'),
      beforeEnter: (_: any, __: any, next: any) => {
        const hasWhatsAppFeature =
          store.getters.isAvailableWhatsAppWeb ||
          store.getters.isWhatsAppApiEnabled

        if (!hasWhatsAppFeature) {
          next('/chat-settings/whatsapp-oficial/lp')
        }

        next()
      }
    },
    {
      path: 'lp',
      name: 'chat-settings-whatsapp-oficial-lp',
      component: () => import('./landing-pages/WhatsAppLP.vue')
    },
    {
      path: 'credit-management',
      name: 'chat-settings-whatsapp-oficial-credit-management',
      redirect: '/chat-settings/whatsapp-oficial/credit-management/report',
      component: () =>
        import('./credit-management/components/CreditManagement.vue'),
      children: [
        {
          path: 'report',
          name: 'chat-settings-whatsapp-oficial-credit-management-report',
          redirect: {
            name: FINANCIAL_MANAGEMENT_ROUTES.CREDITS
          }
        },
        {
          path: 'buy-credits',
          name: 'chat-settings-whatsapp-oficial-credit-management-buy-credits',
          redirect: {
            name: 'octa-store'
          }
        }
      ],
      beforeEnter(_to: any, _from: any, next: any) {
        useStore().dispatch(BILLING_STORE_ACTIONS.LOAD_INITAL).finally(next)
      }
    },
    {
      path: 'template-messages',
      name: 'chat-settings-whatsapp-oficial-template-messages',
      redirect: '/chat-settings/whatsapp-oficial/template-messages/v1',
      component: () => import('./template-messages/TemplateMessages.vue'),
      children: [
        {
          path: 'v1',
          name: 'chat-settings-whatsapp-oficial-template-messages-v1',
          component: () =>
            import('./template-messages/v1/components/TemplateMessages.vue')
        },
        {
          path: 'v2',
          name: 'chat-settings-whatsapp-oficial-template-messages-v2',
          redirect: '/chat-settings/whatsapp-oficial/template-messages/v2/list',
          component: () =>
            import('./template-messages/v2/components/TemplateMessages.vue'),
          children: [
            {
              path: 'list',
              name: 'chat-settings-whatsapp-oficial-template-messages-v2-list',
              component: () =>
                import(
                  './template-messages/v2/components/list/TemplateMessagesList.vue'
                )
            },
            {
              path: 'create',
              name: 'chat-settings-whatsapp-oficial-template-messages-v2-create',
              component: () =>
                import(
                  './template-messages/v2/components/form/TemplateMessageForm.vue'
                ),
              meta: {
                formType: 'create'
              }
            },
            {
              path: 'update/:id',
              name: 'chat-settings-whatsapp-oficial-template-messages-v2-update',
              component: () =>
                import(
                  './template-messages/v2/components/form/TemplateMessageForm.vue'
                ),
              meta: {
                formType: 'update'
              }
            }
          ]
        }
      ]
    },
    {
      path: 'bulk-messages',
      name: 'chat-settings-whatsapp-oficial-bulk-messages',
      redirect: '/chat-settings/whatsapp-oficial/bulk-messages/v1',
      component: () =>
        import(
          '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/BulkMessages.vue'
        ),
      children: [
        {
          path: 'v1',
          name: 'chat-settings-whatsapp-oficial-bulk-messages-list-v1',
          component: () =>
            import(
              '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v1/components/list/BulkMessagesList.vue'
            )
        },
        {
          path: 'v2',
          name: 'chat-settings-whatsapp-oficial-bulk-messages-v2',
          redirect: '/chat-settings/whatsapp-oficial/bulk-messages/v2/list',
          component: () =>
            import(
              '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/components/BulkMessages.vue'
            ),
          children: [
            {
              path: 'list',
              name: 'chat-settings-whatsapp-oficial-bulk-messages-v2-list',
              component: () =>
                import(
                  '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/components/list/BulkMessagesList.vue'
                )
            },
            {
              path: 'create',
              name: 'chat-settings-whatsapp-oficial-bulk-messages-v2-create',
              component: () =>
                import(
                  '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/components/form/BulkMessageForm.vue'
                ),
              meta: {
                formType: 'create'
              }
            },
            {
              path: 'update/:id',
              name: 'chat-settings-whatsapp-oficial-bulk-messages-v2-update',
              component: () =>
                import(
                  '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/components/form/BulkMessageForm.vue'
                ),
              meta: {
                formType: 'update'
              }
            }
          ]
        }
      ]
    }
  ]
}
