import { TicketVoiceState } from './interfaces'

export const settings = (ticketVoiceState: TicketVoiceState) =>
  ticketVoiceState.settings

export const providerSettings = (ticketVoiceState: TicketVoiceState) =>
  ticketVoiceState.providerSettings

export const providers = (ticketVoiceState: TicketVoiceState) =>
  ticketVoiceState.providers

export const baseURL = (ticketVoiceState: TicketVoiceState) =>
  ticketVoiceState.baseURL
