export const SET_COMMERCE_INITIAL_DATA_FETCHED =
  'SET_COMMERCE_INITIAL_DATA_FETCHED'

export const DELETE_COMMERCE_CATALOG = 'DELETE_COMMERCE_CATALOG'

export const SET_COMMERCE_CATALOG_INFO = 'SET_COMMERCE_CATALOG_INFO'

export const SET_COMMERCE_CATALOG_NEXT_PRODUCTS_PAGE =
  'SET_COMMERCE_CATALOG_NEXT_PRODUCTS_PAGE'

export const SET_COMMERCE_CATALOG_PRODUCTS = 'SET_COMMERCE_CATALOG_PRODUCTS'

export const RESET_COMMERCE_CATALOG_PRODUCTS = 'RESET_COMMERCE_CATALOG_PRODUCTS'

export const SET_COMMERCE_CATALOG_PRODUCTS_LOADER =
  'SET_COMMERCE_CATALOG_PRODUCTS_LOADER'

export const UPDATE_COMMERCE_CATALOG_PRODUCTS =
  'UPDATE_COMMERCE_CATALOG_PRODUCT'
