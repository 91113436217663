export const currentConversationReminder = (_, { currentChat, agentId }) =>
  currentChat?.reminders?.find(reminder => reminder.ownerId === agentId)

// export const currentConversationReminder = (_, { currentChat, agentId }) =>
//   currentChat?.reminders?.reminders?.find(
//     reminder => reminder.ownerId === agentId
//   )

export const reminderCrudRequesting = ({ reminderCrudRequesting }) =>
  reminderCrudRequesting
