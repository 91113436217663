import bot from './bot'
import botChannelConfig from './bot-channel-config'
import botFlux from './bot-flux'
import breadcrumb from './breadcrumb'
import commerce from './commerce'
import contentProperties from './content-properties'
import customFields from './custom-fields'
import interactions from './interactions'
import officeHours from './office-hours'
import sidebar from './sidebar'
import templates from './templates'

export default {
  currentChatBot: undefined,
  chatBots: [],
  botChannel: undefined,
  botBuilderVersion: 2,
  openModalChannelRestrictions: false,
  botSpecifications: {
    specifications: [],
    channels: [],
    channelsInfo: []
  },
  duplicateBotFlux: [],
  duplicateFluxId: undefined,
  whatsappNumbers: undefined,
  facebookPages: [],
  instagramPages: [],
  ...breadcrumb,
  ...bot,
  ...contentProperties,
  ...botFlux,
  ...interactions,
  ...templates,
  ...sidebar,
  ...botChannelConfig,
  ...commerce,
  ...officeHours,
  ...customFields
}
