import { ASSIGN_TO } from '@/modules/Bot/enums/assign-to.enum'
import { generateDefaultButton } from '@/modules/Bot/store/getters/template-models'

import i18n from '@/common/i18n'

/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-nested-template-literals */
export default (i18nTemplatesKey, channel, getPersonProperty, userLogged) => {
  const name = getPersonProperty('name')

  const email = getPersonProperty('email')

  const feCollectComplaintVarName = i18n?.t(
    `${i18nTemplatesKey}.fe-collect-complaint.var-name`
  )

  const collectComplaintVarName = i18n?.t(
    `${i18nTemplatesKey}.collect-complaint.var-name`
  )

  return [
    {
      id: 'initial-greeting',
      displayName: i18n?.t(`${i18nTemplatesKey}.initial-greeting.displayName`),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.initial-greeting.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'collect-the-name',
      displayName: i18n?.t(`${i18nTemplatesKey}.collect-the-name.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          ...name,
          token: i18n?.t(name.token),
          example: i18n?.t(name.example)
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-the-name.description`),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'confirm-the-collected-name',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-the-collected-name.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-the-collected-name.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-the-collected-name.buttons.item0`,
            'confirm-the-collected-name-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-the-collected-name.buttons.item1`,
            'confirm-the-collected-name-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'collect-email',
      displayName: i18n?.t(`${i18nTemplatesKey}.collect-email.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          ...email,
          token: i18n?.t(email.token),
          example: i18n?.t(email.example)
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-email.description`),
        buttons: [],
        validationMessage: i18n?.t(
          `${i18nTemplatesKey}.collect-email.validationMessage`
        ),
        validationMessages: [
          {
            level: 0,
            message: i18n?.t(
              `${i18nTemplatesKey}.collect-email.validationMessages.item0`
            )
          }
        ],
        allowUserInput: true
      }
    },
    {
      id: 'confirm-collected-email',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-collected-email.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-collected-email.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-email.buttons.item0`,
            'confirm-collected-email-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-email.buttons.item1`,
            'confirm-collected-email-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'is-the-team-available',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.is-the-team-available.displayName`
      ),
      type: 'office-hours',
      content: {
        messages: [],
        calendarId: '',
        buttons: [
          {
            id: 'true',
            value: '@OFFICE_HOURS_TRUE',
            label: '@OFFICE_HOURS_TRUE',
            selected: false
          },
          {
            id: 'false',
            value: '@OFFICE_HOURS_FALSE',
            label: '@OFFICE_HOURS_FALSE',
            selected: true
          }
        ]
      }
    },
    {
      id: 'support-options',
      displayName: i18n?.t(`${i18nTemplatesKey}.support-options.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.support-options.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.support-options.buttons.item0`,
            'support-options-doubts'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.support-options.buttons.item1`,
            'support-options-complaint'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.support-options.buttons.item2`,
            'support-options-change-plan'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.support-options.buttons.item3`,
            'support-options-other'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.support-options.buttons.item4`,
            'support-options-human'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'closing-message',
      displayName: i18n?.t(`${i18nTemplatesKey}.closing-message.displayName`),
      type: 'close-conversation',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.closing-message.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'out-of-hours',
      displayName: i18n?.t(`${i18nTemplatesKey}.out-of-hours.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.out-of-hours.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.out-of-hours.buttons.item0`,
            'out-of-hours-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.out-of-hours.buttons.item1`,
            'out-of-hours-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'fe-support-options',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-support-options.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.fe-support-options.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-support-options.buttons.item0`,
            'fe-support-options-doubts'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-support-options.buttons.item1`,
            'fe-support-options-complaint'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-support-options.buttons.item2`,
            'fe-support-options-change-plan'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-support-options.buttons.item3`,
            'fe-support-options-other'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'fe-collect-another-reason',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-collect-another-reason.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          name: i18n?.t(
            `${i18nTemplatesKey}.fe-collect-another-reason.var-name`
          ),
          domain: 'CHAT',
          type: 'string',
          token: `#${i18n?.t(
            `${i18nTemplatesKey}.fe-collect-another-reason.var-name`
          )}`
        },
        prompt: i18n?.t(
          `${i18nTemplatesKey}.fe-collect-another-reason.description`
        ),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'fe-feedback-another-motive',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-feedback-another-motive.displayName`
      ),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.fe-feedback-another-motive.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'fe-everythings-solved-4',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-everythings-solved-4.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.fe-everythings-solved-4.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-4.buttons.item0`,
            'fe-everythings-solved-4-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-4.buttons.item1`,
            'fe-everythings-solved-4-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'self-service-closure',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.self-service-closure.displayName`
      ),
      type: 'close-conversation',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.self-service-closure.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'fe-instructions-change-to-plan',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-instructions-change-to-plan.displayName`
      ),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.fe-instructions-change-to-plan.description.item0`
            )
          },
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.fe-instructions-change-to-plan.description.item1`
            )
          },
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.fe-instructions-change-to-plan.description.item2`
            )
          }
        ]
      }
    },
    {
      id: 'fe-everythings-solved-3',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-everythings-solved-3.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.fe-everythings-solved-3.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-3.buttons.item0`,
            'fe-everythings-solved-3-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-3.buttons.item1`,
            'fe-everythings-solved-3-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'fe-collect-complaint',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-collect-complaint.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          name: feCollectComplaintVarName,
          domain: 'CHAT',
          type: 'string',
          token: `#${feCollectComplaintVarName}`
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.fe-collect-complaint.description`),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'fe-feedback-complaint',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-feedback-complaint.displayName`
      ),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.fe-feedback-complaint.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'fe-everythings-solved-2',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-everythings-solved-2.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.fe-everythings-solved-2.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-2.buttons.item0`,
            'fe-everythings-solved-2-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-2.buttons.item1`,
            'fe-everythings-solved-2-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'fe-financial-doubts',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-financial-doubts.displayName`
      ),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.fe-financial-doubts.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'fe-everythings-solved-1',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-everythings-solved-1.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.fe-everythings-solved-1.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-1.buttons.item0`,
            'fe-everythings-solved-1-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-1.buttons.item1`,
            'fe-everythings-solved-1-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'connect-with-financial-team',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.connect-with-financial-team.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.connect-with-financial-team.description.item0`
            )
          }
        ],
        referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: userLogged?.id || '',
        successMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.connect-with-financial-team.successMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.connect-with-financial-team.fallbackMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: channel === 'web',
        selectedView: null
      }
    },
    {
      id: 'financial-doubts',
      displayName: i18n?.t(`${i18nTemplatesKey}.financial-doubts.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.financial-doubts.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.financial-doubts.buttons.item0`,
            'financial-doubts-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.financial-doubts.buttons.item1`,
            'financial-doubts-no'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.financial-doubts.buttons.item2`,
            'financial-doubts-human'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'collect-complaint',
      displayName: i18n?.t(`${i18nTemplatesKey}.collect-complaint.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          name: collectComplaintVarName,
          domain: 'CHAT',
          type: 'string',
          token: `#${collectComplaintVarName}`
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-complaint.description`),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'complaint-connect-with-the-team',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.complaint-connect-with-the-team.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.complaint-connect-with-the-team.description.item0`
            )
          }
        ],
        referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: userLogged?.id || '',
        successMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.complaint-connect-with-the-team.successMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.complaint-connect-with-the-team.fallbackMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: channel === 'web',
        selectedView: null
      }
    },
    {
      id: 'change-plan',
      displayName: i18n?.t(`${i18nTemplatesKey}.change-plan.displayName`),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(`${i18nTemplatesKey}.change-plan.description.item0`)
          },
          {
            text: i18n?.t(`${i18nTemplatesKey}.change-plan.description.item1`)
          },
          {
            text: i18n?.t(`${i18nTemplatesKey}.change-plan.description.item2`)
          }
        ]
      }
    },
    {
      id: 'everythings-solved-0',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.everythings-solved-0.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.everythings-solved-0.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.everythings-solved-0.buttons.item0`,
            'everythings-solved-0-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.everythings-solved-0.buttons.item1`,
            'everythings-solved-0-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'collect-another-reason',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.collect-another-reason.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          name: i18n?.t(`${i18nTemplatesKey}.collect-another-reason.var-name`),
          domain: 'CHAT',
          type: 'string',
          token: `#${i18n?.t(
            `${i18nTemplatesKey}.collect-another-reason.var-name`
          )}`
        },
        prompt: i18n?.t(
          `${i18nTemplatesKey}.collect-another-reason.description`
        ),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'feedback-another-reason',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.feedback-another-reason.displayName`
      ),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.feedback-another-reason.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'fe-everythings-solved-2-1',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.fe-everythings-solved-2-1.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.fe-everythings-solved-2-1.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-2-1.buttons.item0`,
            'fe-everythings-solved-2-1-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.fe-everythings-solved-2-1.buttons.item1`,
            'fe-everythings-solved-2-1-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'connect-with-support',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.connect-with-support.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.connect-with-support.description.item0`
            )
          }
        ],
        referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: userLogged?.id || '',
        successMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.connect-with-support.successMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.connect-with-support.fallbackMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: channel === 'web',
        selectedView: 'available'
      }
    }
  ]
}
