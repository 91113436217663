import { headers } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

import { prdMockSubDomainStatus } from '@/common/helpers/mocks'

import { getClient } from './http'
import { getUserLogged } from './storage'

const API_NAME = 'authentication'

export default class AuthenticationService {
  getClient() {
    return getClient(API_NAME)
  }

  async getStatus() {
    try {
      const userLogged = getUserLogged()

      if (!userLogged) {
        throw new Error('getStatus error: missing userLogged')
      }

      const { data } = await this.getClient().then(client =>
        client.get('authentication/getStatus', headers.getAuthorizedHeaders())
      )

      Storage.setItem('status', data)
      return data
    } catch (ex) {
      return prdMockSubDomainStatus
    }
  }

  async getConfig() {
    try {
      const client = await this.getClient()

      const { data } = await client.get(
        'config',
        headers.getAuthorizedHeaders()
      )

      return data
    } catch (ex) {
      throw new Error('Error at getConfig: ' + ex)
    }
  }
}
