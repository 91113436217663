import i18n from '@/common/i18n'

import { DataSourceResolver } from './interfaces'

export const ticketEvents: DataSourceResolver = () =>
  Promise.resolve([
    {
      id: 'ticket-created',
      name: i18n.t('dataSources.ticketEvents.ticket-created') as string
    },
    {
      id: 'ticket-updated',
      name: i18n.t('dataSources.ticketEvents.ticket-updated') as string
    }
  ])
