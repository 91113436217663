import { ActionContext } from 'vuex'

import { TagsManagerService } from '@/modules/ChatSettings/general/tags-manager/tags-manager.service'

import { objectId } from '@/common/helpers/utils/object-id'

import { RootState } from '@/store/interfaces'

import {
  TagsManagerState,
  TagsManagerTag,
  TagsManagerTagsConfirmAction
} from './interfaces'
import * as types from './mutations-types'

const tagsManagerService = new TagsManagerService()

export const getTagsCounts = async (
  context: ActionContext<TagsManagerState, RootState>
) => {
  try {
    const tagsCounts = await tagsManagerService.getTagsCounts()

    context.commit(types.SET_TAGS_COUNTS, tagsCounts)
  } catch (ex) {
    throw new Error('Error on get tags counts')
  }
}

export const getTagsByStatus = async (
  context: ActionContext<TagsManagerState, RootState>,
  status = 'active'
) => {
  try {
    const tags = await tagsManagerService.getTagsByStatus(status)

    return context.dispatch('sortTagsByStatus', {
      status,
      tags
    })
  } catch (ex) {
    console.error(ex)

    throw new Error('Error on get tags by status')
  }
}

export const sortTagsByStatus = async (
  context: ActionContext<TagsManagerState, RootState>,
  { status, tags }: { status: string; tags: Array<TagsManagerTag> }
) => {
  if (!tags) {
    const { tags: stateTags } = context.getters

    context.commit(types.SORT_TAGS_BY_STATUS, stateTags)
  }

  const sortProperty = status === 'active' ? 'createdAt' : 'updatedAt'

  /* eslint-disable */
  const sortedTags = tags.sort(
    (a: any, b: any) =>
      new Date(b[sortProperty]).getTime() - new Date(a[sortProperty]).getTime()
  )
  /* eslint-enable */

  context.commit(types.SORT_TAGS_BY_STATUS, sortedTags)
}

export const archiveTag = async (
  context: ActionContext<TagsManagerState, RootState>,
  tag: TagsManagerTag
) => {
  try {
    const archivedTag = {
      ...tag,
      status: 'archived'
    }

    const updatedTag = await tagsManagerService.updateTag(archivedTag)

    context.commit(types.REMOVE_TAG, updatedTag.id)

    await context.dispatch('getTagsCounts')

    return updatedTag
  } catch (ex) {
    throw new Error('Error on archive tag')
  }
}

export const bulkArchiveTag = async (
  { dispatch }: ActionContext<TagsManagerState, RootState>,
  tags: Array<TagsManagerTag>
) => {
  const archivedTags = tags.map(tag => ({
    ...tag,
    status: 'archived'
  }))

  await dispatch('bulkUpdateTag', archivedTags)
}

export const updateTag = async (
  context: ActionContext<TagsManagerState, RootState>,
  tag: TagsManagerTag
) => {
  try {
    const updatedTag = await tagsManagerService.updateTag(tag)

    context.commit(types.UPDATE_TAG, updatedTag)

    return updatedTag
  } catch (ex) {
    throw new Error('Error on update tag')
  }
}

export const bulkUpdateTag = async (
  context: ActionContext<TagsManagerState, RootState>,
  tags: Array<TagsManagerTag>
) => {
  try {
    const updatedTags = await tagsManagerService.bulkUpdate(tags)

    context.commit(types.REMOVE_TAGS, updatedTags)

    await context.dispatch('getTagsCounts')
    return updatedTags
  } catch (ex) {
    throw new Error('Error on bulk update tags')
  }
}

export const activateTag = async (
  context: ActionContext<TagsManagerState, RootState>,
  tag: TagsManagerTag
) => {
  try {
    const activatedTag = {
      ...tag,
      status: 'active'
    }

    const updatedTag = await tagsManagerService.updateTag(activatedTag)

    context.commit(types.REMOVE_TAG, updatedTag.id)

    await context.dispatch('getTagsCounts')

    return updatedTag
  } catch (ex) {
    throw new Error('Error on activate tag')
  }
}

export const bulkActivateTag = async (
  { dispatch }: ActionContext<TagsManagerState, RootState>,
  tags: Array<TagsManagerTag>
) => {
  const activeTags = tags.map(tag => ({
    ...tag,
    status: 'active'
  }))

  await dispatch('bulkUpdateTag', activeTags)
}

export const deleteTag = async (
  context: ActionContext<TagsManagerState, RootState>,
  tag: TagsManagerTag
) => {
  try {
    await tagsManagerService.deleteTag(tag.id)

    context.commit(types.REMOVE_TAG, tag.id)

    await context.dispatch('getTagsCounts')
  } catch (ex) {
    throw new Error('Error on delete tag')
  }
}

export const bulkDeleteTag = async (
  context: ActionContext<TagsManagerState, RootState>,
  tags: Array<TagsManagerTag>
) => {
  try {
    const ids = tags.map(tag => tag.id)

    await tagsManagerService.bulkDelete(ids)

    context.commit(types.REMOVE_TAGS, tags)

    await context.dispatch('getTagsCounts')
  } catch (ex) {
    throw new Error('Error on bulk delete tags')
  }
}

export const addNewTag = (
  context: ActionContext<TagsManagerState, RootState>
) => {
  const newTag = {
    id: objectId(),
    isNew: true,
    name: '',
    status: 'active',
    totalUsed: 0
  }

  context.commit(types.SET_NEW_TAG, newTag)
}

export const cancelNewTag = (
  context: ActionContext<TagsManagerState, RootState>
) => {
  context.commit(types.SET_NEW_TAG, undefined)
}

export const confirmAddNewTag = async (
  context: ActionContext<TagsManagerState, RootState>,
  newTag: { name: string; status: string; totalUsed: number }
) => {
  const { name, status, totalUsed } = newTag

  const tag = {
    name,
    status,
    totalUsed
  }

  try {
    const createdTag = await tagsManagerService.createTag(tag)

    context.dispatch('cancelNewTag')

    context.commit(types.ADD_TAG, createdTag)

    context.dispatch('sortTagsByStatus', { status: 'active' })

    await context.dispatch('getTagsCounts')
  } catch (ex) {
    throw new Error('Error on confirm add new tag')
  }
}

export const verifyTagName = async (_: any, name: string) => {
  try {
    return await tagsManagerService.verifyTagName(name)
  } catch (ex) {
    throw new Error('Error on verify tag name')
  }
}

export const updateTagSearchFilter = (
  context: ActionContext<TagsManagerState, RootState>,
  searchFilter: string
) => {
  context.commit(types.UPDATE_SEARCH_FILTER, searchFilter)
}

export const setTagsConfirmAction = (
  context: ActionContext<TagsManagerState, RootState>,
  payload: TagsManagerTagsConfirmAction
) => {
  context.commit(types.SET_TAGS_CONFIRM_ACTION, { ...payload, show: true })
}

export const clearTagsConfirmAction = (
  context: ActionContext<TagsManagerState, RootState>
) => {
  context.commit(types.SET_TAGS_CONFIRM_ACTION, {
    show: false,
    tag: undefined,
    actionName: '',
    i18nKey: '',
    analyticsEventName: ''
  })
}
