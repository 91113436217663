import { ChatStatus } from '@/common/helpers/enums/chat-status'
import { PersonTypes } from '@/common/helpers/enums/person-types'

export default (limit: number, userLoggedId: string) => ({
  'agent._id': userLoggedId,
  status: ChatStatus.TALKING,
  lastMessageUserType: {
    $in: [PersonTypes.System, PersonTypes.Customer, PersonTypes.None]
  },
  limit
})
