const state = {
  wppApiNumber: {
    name: '',
    number: '',
    customFields: {
      aboutDescription: '',
      thumbUrl: '',
      businessProfile: {
        address: '',
        description: '',
        email: '',
        websites: [],
        vertical: ''
      }
    }
  },
  currentState: undefined,
  previousState: undefined
}

export default state
