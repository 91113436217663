const pagesName = {
  CONVERSATION_PAGE: 'conversation',
  ACTIVATION_PAGE: 'activation'
}

export default {
  breadcrumbSteps: [
    {
      displayName: 'bot.newChatBot.breadcrumb.roadmap',
      name: pagesName.CONVERSATION_PAGE,
      order: 0,
      active: true
    },
    {
      displayName: 'bot.newChatBot.breadcrumb.activation',
      name: pagesName.ACTIVATION_PAGE,
      order: 1,
      active: false
    }
  ],
  breadcrumbPages: {
    ...pagesName
  }
}
