import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    name: 'promotional.upgrade-chat-bot',
    path: '/upgrade-chatbot',
    component: () => import('./pages/ChatbotUpgradePage.vue')
  },
  {
    name: 'promotional.upgrade-servicegroup',
    path: '/upgrade-servicegroup',
    component: () => import('./pages/ServiceGroupUpgradePage.vue')
  }
]

export default routes
