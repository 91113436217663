export default {
  waiting: 0,
  talking: 1,
  closed: 2,
  missed: 3,
  started: 4,
  offline: 5,
  closedKb: 6,
  closedMissed: 7
}
