import { channels } from '@/modules/Chat/components/sidebar/enums/chat-channels'
import roomStatus from '@/modules/Chat/helpers/room-status'

import i18n from '@/common/i18n'

import * as types from './mutations-types'

function validStatus(filters, room) {
  return (
    !filters.status ||
    !filters.status.length ||
    filters.status.includes(room.status)
  )
}

function validChannel(filters, room) {
  if (!filters?.channel) return true

  if (room.channel !== filters.channel) return false

  if (channels.whatsapp === filters.channel && filters.domainFrom?.$in?.length)
    return filters.domainFrom.$in.includes(room.domainFrom)

  return true
}

function validSearch(filters, room) {
  if (filters.searches && filters.searches.length) {
    return filters.searches.some(item => {
      const regexSearch = new RegExp(item.value, 'i')

      if (item.property.startsWith('messages.')) {
        return (room.messages || []).some(m => regexSearch.test(m.comment))
      } else if (item.property.startsWith('createdBy.')) {
        if (!room.createdBy) return false

        return [room.createdBy.email, room.createdBy.name].some(
          createdByValue => regexSearch.test(createdByValue)
        )
      }

      return false
    })
  }

  return true
}

function validCreatedRange(filters, room) {
  if (filters && filters.created) {
    const createdReference = room.created || room.lastMessageDate

    const roomCreated = new Date(createdReference)

    if (roomCreated < new Date(filters.created['$gte'])) {
      return false
    }

    if (roomCreated > new Date(filters.created['$lte'])) {
      return false
    }
  }

  return true
}

function validMentions(filters, room) {
  if (Object.prototype.hasOwnProperty.call(filters, 'messages.mentions._id')) {
    if (!room?.mentionsIds?.length) return false

    if (!room.mentionsIds.includes(filters['messages.mentions._id']))
      return false
  }

  return true
}

function validAgentAttribuition(filters, room) {
  if (Object.prototype.hasOwnProperty.call(filters, 'agent._id')) {
    if (filters['agent._id'] === null && !room.agent) {
      return true
    }

    if (!room.agent) {
      return false
    }

    if (room.agent.id !== filters['agent._id']) {
      return false
    }
  }

  return true
}

function validGroupAttribution(filters, room) {
  if (Object.prototype.hasOwnProperty.call(filters, 'group._id')) {
    if (!room.group) {
      return false
    }

    if (room.group.id !== filters['group._id']) {
      return false
    }
  }

  return true
}

function validParticipants(filters, room) {
  if (Object.prototype.hasOwnProperty.call(filters, 'users._id')) {
    if (!room?.usersIds?.length) return false

    if (!room.usersIds.includes(filters['users._id'])) return false
  }

  return true
}

function validChatbot(filters, room) {
  if (Object.prototype.hasOwnProperty.call(filters, 'flux.botId')) {
    return (
      !(room.agent && room.agent._id) &&
      room.status === roomStatus.started &&
      room.flux &&
      room.flux.botId &&
      room.lastMessage &&
      room.lastMessage.comment
    )
  }

  return true
}

function validPublicTags(filters, room) {
  const prop = 'publicTags._id'

  if (!Object.prototype.hasOwnProperty.call(filters, prop)) return true

  const publicTagsFilter = filters[prop]

  if (!publicTagsFilter.length) {
    return true
  }

  return (room.publicTags || []).some(tag => publicTagsFilter.includes(tag._id))
}

function validAnswered(filters, room) {
  if (filters && filters.lastMessageUserType) {
    if (!room.agent) return false

    if (room.agent.id === room.lastMessage?.user?.id) return false
  }

  return true
}

function validTimeExceeded(filters, room) {
  return !filters.lastMessageTimeExceeded || room.lastMessageTimeExceeded
}

function validOrganization(filters, room) {
  if (!filters['createdBy.organization.id']) {
    return true
  }

  return filters['createdBy.organization.id.$in']?.includes(
    room.createdBy.organization._id
  )
}

const filtersHandlers = [
  validStatus,
  validChannel,
  validCreatedRange,
  validMentions,
  validAgentAttribuition,
  validGroupAttribution,
  validParticipants,
  validChatbot,
  validSearch,
  validPublicTags,
  validAnswered,
  validTimeExceeded,
  validOrganization
]

let updateBufferContext

const updateBuffer = []

;(function dequeueBuffer() {
  if (updateBufferContext) {
    const room = updateBuffer.shift()

    if (room) {
      try {
        const { chats, listFilters } = updateBufferContext.getters

        if (filtersHandlers.every(f => f(listFilters, room))) {
          updateBufferContext.commit(types.APPEND_CHAT, room)
        } else if (chats.some(c => c.key === room.key)) {
          updateBufferContext.commit(types.REMOVE_CHAT, room)
        }
      } catch (err) {
        console.error('dequeueBuffer error', err)
      }
    }
  }

  setTimeout(dequeueBuffer, 100)
})()

const onUpdateRoom = (context, room) => {
  updateBufferContext = context

  const index = updateBuffer.findIndex(b => b.key === room.key)

  if (index > -1) {
    updateBuffer[index] = room
  } else {
    updateBuffer.push(room)
  }
}
export const socketRoomUpdateList = (context, rooms) =>
  rooms.forEach(r => onUpdateRoom(context, r))

export const socketRoomsUpdate = (context, rooms) =>
  rooms.forEach(r => onUpdateRoom(context, r))

export const socketUnrededchats = (context, count) =>
  context.commit(types.SET_UNREDEDCHATS, count)

export const socketWaitingchats = (context, count) =>
  count &&
  !context.rootGetters.hasNewAreaNotificationsEnabled &&
  context.dispatch(
    'startNotification',
    i18n?.t(
      'chat.conversation.browserNotification.windowTitle.generalChatUpdate'
    )
  )

export const socketRoomSetAgent = (context, room) => {
  if (
    !context.rootGetters.hasNewAreaNotificationsEnabled &&
    room.agent &&
    room.agent.id === context.getters.agent.id &&
    context.getters.chatKey !== room.key
  ) {
    context.dispatch(
      'startNotification',
      i18n?.t('chat.conversation.browserNotification.windowTitle.roomSetAgent')
    )
  }
  onUpdateRoom(context, room)
}

export const socketRoomCreated = (context, room) => {
  if (!context.rootGetters.hasNewAreaNotificationsEnabled) {
    context.dispatch(
      'startNotification',
      i18n?.t('chat.conversation.browserNotification.windowTitle.roomCreated')
    )
  }
  onUpdateRoom(context, room)
}

function verifyHasGroupsInboxAndExecuteAction(context, action, params) {
  const hasGroupsInbox = context.rootGetters.hasGroupsInbox

  if (!hasGroupsInbox) return

  context.dispatch(action, params)
}

function upsertGroupInbox(context, groupInbox) {
  verifyHasGroupsInboxAndExecuteAction(context, 'upsertGroupInbox', [
    groupInbox
  ])
}

function removeGroupInbox(context, groupId) {
  verifyHasGroupsInboxAndExecuteAction(context, 'removeGroupInbox', groupId)
}
export const socketInboxGroupsTotalRoomsUpdated = (context, totalRooms) => {
  verifyHasGroupsInboxAndExecuteAction(context, 'upsertGroupInbox', totalRooms)
}

export const socketInboxGroupNameUpdated = (context, groupInbox) => {
  upsertGroupInbox(context, groupInbox)
}

export const socketAddCreatedGroupInUserInbox = (context, groupInbox) => {
  upsertGroupInbox(context, groupInbox)
}

export const socketDeleteGroupFromUserInbox = (context, groupInbox) => {
  removeGroupInbox(context, groupInbox.id)
}
export const socketAgentAddedToGroupInbox = (context, groupInbox) => {
  upsertGroupInbox(context, groupInbox)
}

export const socketAgentRemovedFromGroupInbox = (context, groupInbox) => {
  removeGroupInbox(context, groupInbox.id)
}

export const socketGroupBecameRestricted = (context, { roomKeys }) => {
  const chats = context.getters.chats

  const roomKeysMap = new Map(roomKeys.map(key => [key, true]))

  for (const room of chats) {
    if (roomKeysMap.has(room.key)) {
      context.commit(types.REMOVE_CHAT, room)
    }
  }
}

export const socketRoomSetToRestrictedGroup = (context, { roomKey }) => {
  const chats = context.getters.chats

  const room = chats.find(r => r.key === roomKey)

  if (room) {
    context.commit(types.REMOVE_CHAT, room)
  }
}

export const socketResponseServiceTimeoutInbox = (context, { inboxCount }) => {
  context.dispatch('setResponseServiceTimeoutInbox', inboxCount)
}
