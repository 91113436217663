import { headers } from '@octadesk-tech/services'

import { getBaseClient } from '@/modules/Bot/services/http'

export const getClient = () => getBaseClient('persons')

export const getContactStatus = async () => {
  const client = await getClient()
  const response = await client.get(
    `/contact-status`,
    headers.getAuthorizedHeaders()
  )

  return response.data
}
