/* eslint-disable sonarjs/no-duplicate-string */
export default channel => {
  let connections = []

  if (channel !== 'web') {
    connections = [
      {
        id: 'step-0',
        type: 'common',
        parentActionId: '',
        nextActionId: 'initial-greeting',
        connectionRules: []
      }
    ]
  }

  connections = [
    ...connections,
    {
      id: 'step-1',
      type: 'common',
      parentActionId: channel !== 'web' ? 'initial-greeting' : '',
      nextActionId: 'collect-the-name',
      connectionRules: []
    },
    {
      id: 'step-2',
      type: 'common',
      parentActionId: 'collect-the-name',
      nextActionId: 'confirm-the-collected-name',
      connectionRules: []
    },
    {
      id: 'step-3',
      type: 'common',
      parentActionId: 'confirm-the-collected-name',
      nextActionId: 'collect-the-name',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-the-collected-name-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-4',
      type: 'common',
      parentActionId: 'confirm-the-collected-name',
      nextActionId: 'already-a-customer',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-the-collected-name-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-5',
      type: 'common',
      parentActionId: 'already-a-customer',
      nextActionId: 'collect-customer-cpf',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['already-a-customer-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-6',
      type: 'common',
      parentActionId: 'already-a-customer',
      nextActionId: 'collect-email',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['already-a-customer-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-7',
      type: 'common',
      parentActionId: 'collect-customer-cpf',
      nextActionId: 'confirm-collected-cpf',
      connectionRules: []
    },
    {
      id: 'step-8',
      type: 'common',
      parentActionId: 'confirm-collected-cpf',
      nextActionId: 'choose-sectors',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-cpf-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-9',
      type: 'common',
      parentActionId: 'collect-email',
      nextActionId: 'confirm-collected-email',
      connectionRules: []
    },
    {
      id: 'step-10',
      type: 'common',
      parentActionId: 'confirm-collected-email',
      nextActionId: 'collect-the-phone',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-email-confirm'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-11',
      type: 'common',
      parentActionId: 'confirm-collected-email',
      nextActionId: 'collect-email',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-email-correct'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-12',
      type: 'common',
      parentActionId: 'collect-the-phone',
      nextActionId: 'confirm-collected-phone',
      connectionRules: []
    },
    {
      id: 'step-13',
      type: 'common',
      parentActionId: 'confirm-collected-phone',
      nextActionId: 'choose-sectors',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-phone-confirm'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-14',
      type: 'common',
      parentActionId: 'confirm-collected-phone',
      nextActionId: 'collect-the-phone',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-phone-correct'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-15',
      type: 'common',
      parentActionId: 'choose-sectors',
      nextActionId: 'scheduling-forecast',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['choose-sectors-sector-1'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-16',
      type: 'common',
      parentActionId: 'scheduling-forecast',
      nextActionId: 'add-additional-details',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['scheduling-forecast-possible'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-17',
      type: 'common',
      parentActionId: 'scheduling-forecast',
      nextActionId: 'add-additional-details',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['scheduling-forecast-days'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-18',
      type: 'common',
      parentActionId: 'scheduling-forecast',
      nextActionId: 'add-additional-details',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['scheduling-forecast-week'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-19',
      type: 'common',
      parentActionId: 'add-additional-details',
      nextActionId: 'additional-details',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['add-additional-details-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-20',
      type: 'common',
      parentActionId: 'add-additional-details',
      nextActionId: 'confirmation-without-details',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['add-additional-details-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-21',
      type: 'common',
      parentActionId: 'confirmation-without-details',
      nextActionId: 'pre-booking-confirmation',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirmation-without-details-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-22',
      type: 'common',
      parentActionId: 'confirmation-without-details',
      nextActionId: 'choose-sectors',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirmation-without-details-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-23',
      type: 'common',
      parentActionId: 'pre-booking-confirmation',
      nextActionId: 'forward-pre-scheduling',
      connectionRules: []
    },
    {
      id: 'step-24',
      type: 'common',
      parentActionId: 'additional-details',
      nextActionId: 'confirmation-with-details',
      connectionRules: []
    },
    {
      id: 'step-25',
      type: 'common',
      parentActionId: 'confirmation-with-details',
      nextActionId: 'pre-booking-confirmation',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirmation-with-details-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-26',
      type: 'common',
      parentActionId: 'confirmation-with-details',
      nextActionId: 'choose-sectors',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirmation-with-details-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-27',
      type: 'common',
      parentActionId: 'confirm-collected-cpf',
      nextActionId: 'collect-customer-cpf',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-cpf-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-28',
      type: 'common',
      parentActionId: 'choose-sectors',
      nextActionId: 'scheduling-forecast',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['choose-sectors-sector-2'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-29',
      type: 'common',
      parentActionId: 'choose-sectors',
      nextActionId: 'scheduling-forecast',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['choose-sectors-sector-3'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    }
  ]

  return connections
}
