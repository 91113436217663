import { ActionContext } from 'vuex'

import { WidgetState, WidgetSettings } from '@/modules/Widget/types'

import {
  WidgetManagerService,
  CreateSettingsPayload,
  UpdateSettingsPayload
} from '@/common/services/widget-manager'

import { RootState } from '@/store/interfaces'

import * as types from './mutations-types'

export const getSettings = async (
  context: ActionContext<WidgetState, RootState>
) => {
  const { subDomain } = context?.rootGetters

  if (!subDomain) {
    console.error('error on widget getSettings: missing subDomain')
  } else {
    const widgetManager = new WidgetManagerService()

    const { settings } = await widgetManager.getSettings(subDomain)

    if (settings) {
      context.commit(types.SET_WIDGET_SETTINGS, settings)
    } else {
      const newSettings = await context.dispatch('setupWidget')

      if (newSettings) {
        context.commit(types.SET_WIDGET_SETTINGS, newSettings)
      }
    }
  }
}

export const publishSettings = async (
  context: ActionContext<WidgetState, RootState>,
  settings: WidgetSettings
): Promise<any> => {
  const { subDomain } = context?.rootGetters
  const { id: userLoggedId } = context.rootGetters.userLogged

  if (!subDomain) {
    console.error('error on widget settings: missing subDomain')
  } else {
    const payload: UpdateSettingsPayload = {
      id: subDomain,
      subDomain,
      data: settings,
      agentId: userLoggedId
    }

    const widgetManager = new WidgetManagerService()

    return await widgetManager.publishSettings(payload)
  }
}

export const setupWidget = async (
  context: ActionContext<WidgetState, RootState>
) => {
  const { subDomain, isTrayWhitelabel } = context?.rootGetters
  const { id: userLoggedId } = context.rootGetters.userLogged

  if (!subDomain) {
    console.error('error on setup widget: missing subDomain')
  } else {
    const payload: CreateSettingsPayload = {
      subDomain,
      agentId: userLoggedId,
      data: { isTrayWhitelabel }
    }

    const widgetManager = new WidgetManagerService()

    const { settings } = await widgetManager.createSettings(payload)

    if (settings) {
      return settings
    } else {
      console.error('error on setup widget: settings not available')
    }
  }
}
