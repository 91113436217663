import { v4 as uuid } from 'uuid'

import {
  BotAction,
  BotConnection,
  MessageContent,
  QuickReplyContent,
  MediaContent,
  CommerceContent,
  AssignRoomContent,
  SetPropertyValueContent,
  WebhookContent,
  ConditionalContent,
  OfficeHoursContent
} from '@octadesk-tech/chat-bot-domain'

import { getButtonConnectionRule } from '@/modules/Bot/store/helpers'

import i18n from '@/common/i18n'

export const ClientBotActionTypes = {
  OfficeHours: 'office-hours',
  AssignRoom: 'assign-room',
  CloseConversation: 'close-conversation',
  Media: 'media',
  CommerceCatalog: 'commerce-catalog',
  Message: 'message',
  NotSpecified: 'not-specified',
  ProActiveMessage: 'pro-active-message',
  QuickReply: 'quick-reply',
  QuickReplyInteractiveButtons: 'quick-reply:interactive-buttons',
  QuickReplyInteractiveList: 'quick-reply:interactive-list',
  Recursion: 'recursion',
  SetPropertyValue: 'set-property-value',
  Webhook: 'webhook',
  Conditional: 'conditional'
}

export const QuickReplyTypes = {
  Text: 'text',
  Buttons: 'buttons',
  InteractiveButtons: 'interactive-buttons',
  InteractiveList: 'interactive-list'
}

const getOrCreateId = object => {
  if (!object.id) object.id = uuid()
  return object.id
}

const createDefaultMessage = action => {
  if (action.messages) {
    if (!action.messages.length) {
      action.messages.push(CreateMessage(''))
    }
    action.messages.forEach(getOrCreateId)
  }
}

export const CreateMessage = text => {
  return {
    id: uuid(),
    text: text,
    placeholder: i18n?.t(
      'bot.newChatBot.actions.edit.message.defaultPlaceholder'
    )
  }
}

export const CreateMessageContent = text => {
  return new MessageContent({
    delay: {
      time: 0,
      style: 'typing'
    },
    messages: [CreateMessage(text)]
  })
}

export const BotContentResolver = {
  [ClientBotActionTypes.OfficeHours]: content =>
    new OfficeHoursContent(content),
  [ClientBotActionTypes.ProActiveMessage]: content =>
    new ClientMessageContent(content),
  [ClientBotActionTypes.Message]: content => new ClientMessageContent(content),
  [ClientBotActionTypes.QuickReply]: (content, subType) =>
    new ClientQuickReplyContent(content, subType),
  [ClientBotActionTypes.Media]: content => new ClientMediaContent(content),
  [ClientBotActionTypes.CommerceCatalog]: content =>
    new ClientCommerceContent(content),
  [ClientBotActionTypes.AssignRoom]: (content, subType) =>
    new ClientAssignRoomContent(content, subType),
  [ClientBotActionTypes.CloseConversation]: content =>
    new ClientMessageContent(content),
  [ClientBotActionTypes.SetPropertyValue]: content =>
    new SetPropertyValueContent(content),
  [ClientBotActionTypes.Webhook]: content => new ClientWebhookContent(content),
  [ClientBotActionTypes.Conditional]: content =>
    new ClientConditionalContent(content)
}

export class ClientBotAction extends BotAction {
  constructor(document) {
    super(document)

    if (this.type === ClientBotActionTypes.ProActiveMessage) {
      this.id = 'pro-active-message'
    } else {
      getOrCreateId(this)
    }

    const contentFunction = BotContentResolver[this.type]

    if (contentFunction) {
      this.content = contentFunction(this.content)
    }

    if (this.content) {
      if (this.content?.buttons?.length) {
        this.content.buttons.forEach(button => {
          button.selected = false
          getOrCreateId(button)
        })

        this.content.buttons[0].selected = true
      }

      if (this.content.list?.sections[0]?.options?.length) {
        this.content.list.sections[0].options.forEach(button => {
          button.selected = false
          getOrCreateId(button)
        })

        this.content.list.sections[0].options[0].selected = true
      }

      if (this.content.messages && this.content.messages.length) {
        this.content.messages.forEach(getOrCreateId)
      }

      if (this.content.empty) {
        this.created = new Date()
      }
    }

    this._isFallback = false

    this._isDirty = false
  }

  validate(errorsBag) {
    errorsBag = errorsBag || []

    if (!this.type) {
      errorsBag.push('Action type is required')
    }

    if (!this.content || !Object.keys(this.content).length) {
      errorsBag.push('Action content is required')
    }

    if (this.content && this.content.validate) {
      return this.content.validate(
        {
          action: this
        },
        errorsBag
      )
    }

    return true
  }

  canAddNewActionBefore() {
    return ![
      ClientBotActionTypes.ProActiveMessage,
      ClientBotActionTypes.Recursion
    ].includes(this.type)
  }

  setFallbackFlow() {
    this._isFallback = true
  }

  setSuccessFlow() {
    this._isFallback = false
  }

  isFallbackFlow() {
    return this._isFallback
  }

  getConnectionRules() {
    const actionTypesThatNeedConnectionRules = [
      ClientBotActionTypes.Webhook,
      ClientBotActionTypes.OfficeHours,
      ClientBotActionTypes.Conditional
    ]

    if (!actionTypesThatNeedConnectionRules.includes(this.type)) {
      return
    }

    const selectedButton = this.getSelectedButton()
    return this.getConnectionRule(selectedButton)
  }

  getConnectionRule(button) {
    const typeNeedRule = [
      ClientBotActionTypes.Webhook,
      ClientBotActionTypes.OfficeHours,
      ClientBotActionTypes.Conditional,
      ClientBotActionTypes.QuickReply
    ].includes(this.type)

    if (!button || !typeNeedRule) return

    const rule = getButtonConnectionRule(button)

    if (this.type == ClientBotActionTypes.QuickReply) return [rule]

    const referenceProperty = `properties.SESSION.${this.id}.value${
      this.type == ClientBotActionTypes.Webhook ? '.status' : ''
    }`

    return [
      {
        ...rule,
        referenceProperty
      }
    ]
  }

  canAddNewActionAfter() {
    const preValid = ![
      ClientBotActionTypes.CloseConversation,
      ClientBotActionTypes.Recursion,
      ClientBotActionTypes.NotSpecified
    ].includes(this.type)

    return (
      preValid &&
      (this.type !== ClientBotActionTypes.AssignRoom || this.isFallbackFlow())
    )
  }

  getSelectedButton() {
    if (this.content?.buttons?.length) {
      return this.content.buttons.find(button => button.selected)
    }

    if (this.content.list?.sections[0]?.options?.length) {
      return this.content.list.sections[0].options.find(
        button => button.selected
      )
    }

    return null
  }

  getButtons() {
    if (this.content?.buttons?.length) {
      return this.content.buttons
    }

    if (this.content.list?.sections[0]?.options?.length) {
      return this.content.list.sections[0].options
    }

    return []
  }

  getMessages() {
    if (this.content && this.content.messages && this.content.messages.length) {
      return this.content.messages
    }

    return []
  }

  getPrompt() {
    if (this.content && this.content.prompt) return this.content.prompt

    return null
  }

  getProperty() {
    if (this.content && this.content.property) return this.content.property

    return null
  }

  isNotSpecifiedAction() {
    return this.type === ClientBotActionTypes.NotSpecified
  }

  setDirty() {
    this._isDirty = true
  }

  isDirty() {
    return (
      this._isDirty ||
      this.getPrompt() ||
      this.getMessages().some(msg => msg.text) ||
      this.getButtons().some(btn => btn.label) ||
      this.getProperty()
    )
  }

  static getNotSpecifiedAction() {
    return new ClientBotAction({
      type: ClientBotActionTypes.NotSpecified,
      displayName: i18n?.t('bot.newChatBot.bot.newInteraction'),
      content: {
        empty: true
      }
    })
  }
}

export class ClientBotConnection extends BotConnection {
  constructor(document) {
    super(document)

    if (!this.id) {
      this.id = uuid()
    }
  }
}

export class ClientMediaContent extends MediaContent {
  constructor(document) {
    super(document || {})
  }

  validate(params, errorsBag) {
    errorsBag = errorsBag || []

    if (params.action.content.warning) {
      const errorMessage = 'Medias'

      errorsBag.push(errorMessage)

      return { status: false, messages: errorsBag }
    }

    return { status: true, messages: '' }
  }
}

export class ClientCommerceContent extends CommerceContent {
  constructor(document) {
    super(document || {})
  }
}

export class ClientMessageContent extends MessageContent {
  constructor(document) {
    super(document || {})

    createDefaultMessage(this)
  }
}

export class ClientWebhookContent extends WebhookContent {
  constructor(document) {
    super(document || {})
  }
}

export class ClientConditionalContent extends ConditionalContent {
  constructor(document) {
    super(document || {})
  }
}

export class ClientOfficeHoursContent extends OfficeHoursContent {
  constructor(document) {
    super(document || {})
  }
}

export class ClientAssignRoomContent extends AssignRoomContent {
  constructor(document, subType) {
    super(document || {})

    if (subType) this.subType = subType

    createDefaultMessage(this)

    if (document.mustHasAvailability === undefined) {
      this.mustHasAvailability = undefined
    }

    this.selectedView = 'available'
  }

  validate(params, errorsBag) {
    errorsBag = errorsBag || []

    if (!this.referenceProperty && !params.action.isNew) {
      const errorMessage = i18n?.t(
        'bot.newChatBot.actions.edit.assignRoom.missingOptionValue'
      )

      errorsBag.push(errorMessage)

      return { status: false, messages: errorsBag }
    }

    return { status: true, messages: '' }
  }
}

export class ClientQuickReplyContent extends QuickReplyContent {
  constructor(document, subType) {
    super(document || {})

    if (subType) this.subType = subType

    if (this.buttons) {
      this.buttons.forEach(getOrCreateId)

      if (this.buttons.length) {
        this.allowUserInput = false
      }
    }

    if (this.messages) {
      this.messages.forEach(getOrCreateId)
    }
  }

  validate(_, errorsBag) {
    errorsBag = errorsBag || []

    const buttonsNotEdit = this.buttons
      .filter(btn => !btn.editing)
      .some(btn => !btn.label.length)

    if (this.buttons && this.buttons.length && buttonsNotEdit) {
      const errorMessage = i18n?.t(
        'bot.newChatBot.actions.edit.quickReplyButton.missingOptionValue'
      )
      errorsBag.push(errorMessage)

      return { status: false, messages: errorsBag }
    }

    return { status: true, messages: '' }
  }
}

export const GetValidationMessages = (sender, type) => {
  return [
    {
      id: uuid(),
      level: 0,
      message: sender.$t(
        `bot.newChatBot.actions.edit.quickReply.fallbacks.${type}.first`
      )
    },
    {
      id: uuid(),
      level: 1,
      message: sender.$t(
        `bot.newChatBot.actions.edit.quickReply.fallbacks.${type}.second`
      )
    },
    {
      id: uuid(),
      level: 2,
      message: sender.$t(
        `bot.newChatBot.actions.edit.quickReply.fallbacks.${type}.third`
      )
    }
  ]
}
