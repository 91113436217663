import { MetaAdsState } from './interfaces'

export const metaAdsInitialDataFetched = ({
  initialDataFetched
}: MetaAdsState) => initialDataFetched

export const metaAdsAccessToken = ({ accessToken }: MetaAdsState) => accessToken

export const metaAdsWhatsAppNumbers = ({ whatsAppNumbers }: MetaAdsState) =>
  !!whatsAppNumbers.length
