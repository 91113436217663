import { CreditManagementState } from './interfaces'

const state: CreditManagementState = {
  report: {
    loader: true,
    hasError: false,
    categorized: {
      businessInitiated: {
        total: {
          conversations: 0,
          credits: 0
        },
        user: {
          total: {
            conversations: 0,
            credits: 0
          },
          marketing: {
            conversations: 0,
            credits: 0
          },
          utility: {
            conversations: 0,
            credits: 0
          },
          authentication: {
            conversations: 0,
            credits: 0
          }
        },
        bulk: {
          total: {
            conversations: 0,
            credits: 0
          },
          marketing: {
            conversations: 0,
            credits: 0
          },
          utility: {
            conversations: 0,
            credits: 0
          },
          authentication: {
            conversations: 0,
            credits: 0
          }
        },
        api: {
          total: {
            conversations: 0,
            credits: 0
          },
          marketing: {
            conversations: 0,
            credits: 0
          },
          utility: {
            conversations: 0,
            credits: 0
          },
          authentication: {
            conversations: 0,
            credits: 0
          }
        }
      },
      clientInitiated: {
        total: {
          conversations: 0,
          credits: 0
        },
        user: {
          conversations: 0,
          credits: 0
        },
        bot: {
          conversations: 0,
          credits: 0
        }
      },
      freeEntryPoint: {
        total: {
          conversations: 0,
          credits: 0
        }
      },
      total: {
        conversations: 0,
        credits: 0
      }
    },
    filter: {
      phone: '',
      startDate: '',
      endDate: ''
    }
  }
}

export default state
