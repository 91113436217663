import sanitizeHtml from 'sanitize-html'

export default {
  methods: {
    sanitizeHtml(text, options) {
      return sanitizeHtml(text, {
        disallowedTagsMode: 'recursiveEscape',
        ...options
      })
    }
  }
}
