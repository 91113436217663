/**
 * Represents HTTP response status codes.
 * @enum {number}
 */
export enum STATUS_CODE {
  /**
   * The request has succeeded.
   */
  OK = 200,

  /**
   * The request has been fulfilled and a new resource has been created.
   */
  CREATED = 201,

  /**
   * The request has been accepted for processing, but the processing has not been completed.
   */
  ACCEPTED = 202,

  /**
   * The server successfully processed the request, but there is no content to return.
   */
  NO_CONTENT = 204,

  /**
   * The server cannot understand the request due to client error.
   */
  BAD_REQUEST = 400,

  /**
   * The request requires user authentication or the provided credentials are invalid.
   */
  UNAUTHORIZED = 401,

  /**
   * Payment Required.
   */
  PAYMENT_REQUIRED = 402,

  /**
   * The server understood the request but refuses to authorize it.
   */
  FORBIDDEN = 403,

  /**
   * The server could not find the requested resource.
   */
  NOT_FOUND = 404,

  /**
   * The server does not support the requested HTTP method.
   */
  METHOD_NOT_ALLOWED = 405,

  /**
   * The request could not be completed due to a conflict with the current state of the resource.
   */
  CONFLICT = 409,

  /**
   * The user has sent too many requests in a given amount of time ("rate limiting").
   */
  TOO_MANY_REQUESTS = 429,

  /**
   * The server encountered an unexpected condition that prevented it from fulfilling the request.
   */
  INTERNAL_SERVER_ERROR = 500,

  /**
   * The server was acting as a gateway or proxy and received an invalid response from an upstream server.
   */
  BAD_GATEWAY = 502,

  /**
   * The server acting as a gateway or proxy did not receive a timely response from an upstream server.
   */
  GATEWAY_TIMEOUT = 504
}
