import { NavigationGuardNext } from 'vue-router'

import store from '@/store'

export const homeRedirect = (next: NavigationGuardNext) => {
  const featureHome = store.getters.isHomeEnabled
  const trial = store.getters?.isTrial

  if (!trial && featureHome) {
    return next('/home-octa')
  } else {
    return next('/chat/empty')
  }
}
