import { CommerceMethodType } from '@/modules/Chat/components/conversation/enums/commerce'

export const CHECKOUT_INITIAL_STATE = {
  name: '',
  email: '',
  phone: '',
  cpf: '',
  address: {
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'BR',
    complement: ''
  },
  notes: ''
}

export default {
  commerce: {
    type: CommerceMethodType.sendProducts,
    catalog: {},
    catalogLoader: false,
    products: {
      loader: false,
      items: [],
      page: 1,
      allProductsLoaded: false,
      paginateLoader: false,
      filteredItems: []
    },
    productDetails: {
      loader: true,
      clickedToSend: false,
      product: {},
      attributes: {},
      productVariation: {}
    },
    filter: {
      loader: false,
      queryString: '',
      items: []
    },
    orderBy: [],
    selectedProducts: [],
    productsSended: [],
    cart: null,
    cartLoader: true,
    orders: {
      loader: false,
      productsLoader: false,
      clientNotFound: false,
      items: [],
      paymentUrl: ''
    },
    integrations: {
      loader: false,
      selectedIntegrationId: '',
      items: []
    },
    createCart: {
      products: [],
      checkout: CHECKOUT_INITIAL_STATE,
      order: undefined
    }
  }
}
