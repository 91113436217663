import { phoneNumbers } from '@/modules/ChatSettings/whatsapp-oficial/numbers/helpers'

import * as types from './mutations-types'

export default {
  [types.CAN_SHOW_BAR](state: any, barState: any) {
    state.canShowBar = barState
  },
  [types.SET_LOADER_SHOW_BAR](state: any, loaderState: any) {
    state.inLoaderShowBar = loaderState
  },
  [types.DISABLE_REOPEN](state: any, disableReopen: any) {
    state.disableReconnectingReOpen = disableReopen
  },
  [types.REMOVE_FROM_SOCKET_QUEUE](state: any, number: any) {
    const shallow = { ...state.socketEventsQueue }

    delete shallow[number.id]

    state.socketEventsQueue = { ...shallow }
  },
  [types.REMOVE_NUMBER](state: any, { number, queue, queues }: any) {
    queues = queues || []

    if (queue) {
      queues.push(queue)
    }

    queues.forEach((queue: any) => {
      const queueName = `${queue}Numbers`

      if (state[queueName]) {
        state[queueName] = state[queueName].filter(
          (item: any) => !phoneNumbers.equals(item, number)
        )
      }
    })
  },
  [types.ADD_NUMBER](state: any, { number, queue }: any) {
    const queueName = `${queue}Numbers`

    if (state[queueName]) {
      const others = state[queueName].filter(
        (item: any) => !phoneNumbers.equals(item, number)
      )

      state[queueName] = [...others, number].sort((a, b) => {
        return a.number > b.number ? 1 : -1
      })
    }
  }
}
