import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

import i18n from '@/common/i18n'

import { getClient as getBaseClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

export class WhatsAppIntegratorService {
  private static instance: WhatsAppIntegratorService

  public static getInstance(): WhatsAppIntegratorService {
    if (!this.instance) {
      this.instance = new WhatsAppIntegratorService()
    }

    return this.instance
  }

  _client: any

  private _clients: any = {}

  async getClient() {
    if (this._client) {
      return this._client
    }

    // eslint-disable-next-line
    if (!services['chatIntegrator']) {
      throw new Error('Cannot get http client: missing service chatIntegrator')
    }

    // eslint-disable-next-line
    this._client = await services['chatIntegrator'].getClient(legacyHeaders())

    return this._client
  }

  async getChatIntegratorClient() {
    if (this._clients && this._clients['chatIntegrator']) {
      return this._clients['chatIntegrator']
    }

    if (!services['chatIntegrator']) {
      throw new Error('Cannot get http client: missing service chatIntegrator')
    }

    this._clients['chatIntegrator'] = await services[
      'chatIntegrator'
    ].getClient(legacyHeaders())

    return this._clients['chatIntegrator']
  }

  async chatClient(): Promise<AxiosInstance> {
    return (await services.chat.getClient()) as AxiosInstance
  }

  async getIntegratorWhatsappNumbers() {
    try {
      const client = await this.getClient()

      const response = await client.get('/whatsapp/numbers')

      if (response.data) {
        return response.data
      } else {
        throw new Error('Error on getIntegratorWhatsappNumbers')
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async addWhatsappApiNumber(number: any, integratorName: any) {
    const client = await this.getClient()

    return client.post(`/whatsapp/${integratorName}/numbers/`, number)
  }

  async updateWhatsappApiNumber(number: any) {
    const client = await this.getClient()

    return client.put(`/whatsapp/numbers/${number.id}`, number)
  }

  async deleteWhatsappApiNumber(numberId: any) {
    const client = await this.getClient()

    return client.delete(`/whatsapp/numbers/${numberId}`)
  }

  async getChatIntegrators() {
    const integrators = Storage.getItem('chat:integrator')

    if (integrators) {
      return integrators
    }

    const res = await this.getClient().then(client =>
      client.get('/', legacyHeaders())
    )

    if (res.data) {
      Storage.setItem('chat:integrator', res.data, 3600)
    }

    return res.data
  }

  async getCompanyModel() {
    const client = await getBaseClient('company')

    return client.get('/model', legacyHeaders()).then(r => r.data)
  }

  async uploadFile(data: any, params = {}) {
    return this.chatClient().then(client => {
      const options = {
        ...legacyHeaders(),
        progress: data.progress,
        params
      }

      return client
        .post('upload', data.body, options)
        .then(res => res.data)
        .then(data.success)
        .catch(data.error)
    })
  }

  translateName(name = '') {
    if (!name.startsWith('untitled')) {
      return name
    }

    const splittedName = name.split('-')

    return [
      i18n?.t(`listNumbers.webList.label.${splittedName[0]}`),
      splittedName[1]
    ].join(' ')
  }

  async createNumbersByChannelID(integratorName: string, payload: any) {
    return (await this.getChatIntegratorClient()).post(
      `/whatsapp/${integratorName}/ids`,
      payload
    )
  }

  async activateNumber(integratorName: string, number: string) {
    const client = await this.getChatIntegratorClient()

    const response = await client.put(
      `/whatsapp/${integratorName}/numbers/activate/${number}`,
      legacyHeaders()
    )

    return response.data
  }
}
