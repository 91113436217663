import { getClient as getHttpClient } from './http'
import legacyHeaders from './legacy-headers'
const STATUS_OFFLINE = 1

export class ChatAgentsService {
  private API_NAME = 'chatAgentsUrl'

  async getClient() {
    return getHttpClient(this.API_NAME)
  }

  async getAgent(agentId: string) {
    const client = await this.getClient()
    const { data } = await client.get(`/${agentId}`, legacyHeaders())
    return data
  }

  async getAgents() {
    const client = await this.getClient()
    const { data } = await client.get('/', legacyHeaders())
    return data
  }

  async getAgentsByGroupId(groupId = '') {
    if (!groupId)
      throw new Error('error on getAgentsByGroupId, missing groupId param')

    const client = await this.getClient()
    const { data } = await client.get(
      `/filter/group/${groupId}`,
      legacyHeaders()
    )
    return data
  }

  async setAgentStatus(status: number, agentId: string) {
    const client = await this.getClient()
    const { data } = await client.put(
      `/${agentId}/status`,
      { status },
      legacyHeaders()
    )
    return data
  }

  async setAllAgentStatus(status: number, agentsIds: string[]) {
    const client = await this.getClient()
    const { data } = await client.put(
      '/status/bulk',
      { status, agentsIds },
      legacyHeaders()
    )
    return data
  }

  async setAgentOffline(agentId: string) {
    const client = await this.getClient()
    const { data } = await client.put(
      `/${agentId}/status`,
      { status: STATUS_OFFLINE },
      legacyHeaders()
    )
    return data
  }

  async setAllAgentsOffline() {
    const client = await this.getClient()
    const { data } = await client.put(
      'status',
      { status: STATUS_OFFLINE },
      legacyHeaders()
    )
    return data
  }

  async disconnectAgentsList(idList: Array<string>) {
    const promises: Array<Promise<any>> = []
    idList.forEach(id => {
      promises.push(this.setAgentOffline(id))
    })
    let result
    try {
      result = await Promise.all(promises)
    } catch (e) {
      result = e
    }
    return result
  }
}
