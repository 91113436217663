import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const format = (number: any) => {
  const strNumber = number.toString()

  if (strNumber.startsWith('55')) {
    const phoneNumber = parsePhoneNumberFromString('+ ' + strNumber)

    return phoneNumber!.formatNational()
  } else {
    return `+${number}`
  }
}

export const equals = (p1: any, p2: any) => p1.number === p2.number
