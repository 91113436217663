import { FIRST_BUY_ROUTES } from './enums'
import {} from './store'

export const firstBuyRoutesChildren = {
  path: 'first-buy/',
  component: () =>
    import(
      '@/modules/Billing/pages/FirstPlanChooseExperience/FirstPlanChooseExperiencePage.vue'
    ),
  children: [
    {
      name: FIRST_BUY_ROUTES.SELECT_PLAN,
      path: 'select-plan',
      component: () =>
        import(
          '@/modules/Billing/pages/FirstPlanChooseExperience/SelectPlanPage.vue'
        )
    },
    {
      name: FIRST_BUY_ROUTES.BILLING_INFO,
      path: 'tax-info',
      component: () =>
        import(
          '@/modules/Billing/pages/FirstPlanChooseExperience/BillingTaxInfoPage.vue'
        )
    },
    {
      name: FIRST_BUY_ROUTES.ADDITIONALS,
      path: 'additionals',
      component: () =>
        import('@/modules/Billing/pages/BuyAddOn/AdditionalsPage.vue')
    },
    {
      name: FIRST_BUY_ROUTES.BUY_RESUME,
      path: 'resume',
      component: () =>
        import('@/modules/Billing/pages/Checkout/CheckoutFirstBuyPage.vue')
    },
    {
      name: FIRST_BUY_ROUTES.PAYMENT,
      path: 'payment',
      component: () =>
        import('@/modules/Billing/pages/Checkout/CheckoutPaymentPage.vue')
    },
    {
      name: FIRST_BUY_ROUTES.APPROVED_PAYMENT,
      path: 'approved-payment',
      component: () =>
        import('@/modules/Billing/pages/Checkout/CheckoutApprovedPayment.vue')
    }
  ]
}
