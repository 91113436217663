import { AbVariants } from '@/common/helpers/enums/ab-variants'

export const getAbVariant = (variantPercentage = 0.5) => {
  const dice = Math.random()
  let variant = ''
  if (dice <= variantPercentage) {
    variant = AbVariants.A
  } else {
    variant = AbVariants.B
  }
  return variant
}
