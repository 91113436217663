import { ActionContext } from 'vuex'

import { v4 as uuid } from 'uuid'

import { ProductsService } from '@/modules/Commerce/products/products.service'
import {
  mountProductPayload,
  slugify
} from '@/modules/Commerce/products/products.utils'

import { RootState } from '@/store/interfaces'

import {
  ProductsCommerceState,
  CommerceSingleProduct,
  CommerceAvailableAttributes,
  CommerceProductVariants,
  CommerceProductAttributes
} from './interfaces'
import * as types from './mutation-types'
import { productInitialState } from './state'

const productsService = new ProductsService()

export const getCommerceProduct = async (
  context: ActionContext<ProductsCommerceState, RootState>,
  productId: string
) => {
  context.dispatch('setProductLoader', true)

  const product: CommerceSingleProduct =
    await productsService.getCommerceProduct({
      productId
    })

  context.commit(types.SET_COMMERCE_PRODUCT, product)

  context.dispatch('setProductLoader', false)
}

export const updateCommerceProductField = (
  context: ActionContext<ProductsCommerceState, RootState>,
  payload: { fieldName: string; fieldValue: string }
) => {
  const { commerceProduct } = context.getters

  const { fieldName, fieldValue } = payload

  const product = {
    ...commerceProduct,
    [fieldName]: fieldValue
  }

  context.commit(types.SET_COMMERCE_PRODUCT, product)
}

export const getCommerceAvailableAttributes = async (
  context: ActionContext<ProductsCommerceState, RootState>
) => {
  context.commit(types.TOGGLE_COMMERCE_AVAILABLE_ATTRIBUTES_LOADER, true)

  const availableAttributes: Array<CommerceAvailableAttributes> =
    await productsService.getCommerceAttributes()

  context.commit(
    types.SET_COMMERCE_PRODUCT_AVAILABLE_ATTRIBUTES,
    availableAttributes
  )

  context.commit(types.TOGGLE_COMMERCE_AVAILABLE_ATTRIBUTES_LOADER, false)
}

export const setCommerceProductAttributes = (
  context: ActionContext<ProductsCommerceState, RootState>,
  attributes: Array<CommerceProductAttributes>
) => {
  const { commerceProductVariants } = context.getters

  const attributeIds = attributes.map(attribute => attribute.id)

  let productVariants = commerceProductVariants.map(
    (variant: CommerceProductVariants) => ({
      ...variant,
      attributeValues: variant.attributeValues.filter(attributeValue =>
        attributeIds.includes(attributeValue.attributeId)
      )
    })
  )

  context.commit(types.SET_COMMERCE_PRODUCT_ATTRIBUTES, attributes)

  if (attributes.length <= 0) {
    productVariants = []
  }

  context.commit(types.UPDATE_COMMERCE_PRODUCT_VARIANTS, productVariants)
}

export const createCommerceProductAttribute = (
  context: ActionContext<ProductsCommerceState, RootState>,
  name: string
) => {
  const { commerceProductAvailableAttributes, commerceProductAttributes } =
    context.getters

  const newAttribute = {
    id: uuid(),
    code: slugify(name),
    name,
    values: [],
    isNew: true
  }

  const availableAttributes = [
    newAttribute,
    ...commerceProductAvailableAttributes
  ]

  context.commit(
    types.SET_COMMERCE_PRODUCT_AVAILABLE_ATTRIBUTES,
    availableAttributes
  )

  context.dispatch('setCommerceProductAttributes', [
    newAttribute,
    ...commerceProductAttributes
  ])
}

export const createCommerceProductAttributeValue = (
  context: ActionContext<ProductsCommerceState, RootState>,
  { name, attributeId }: { name: string; attributeId: string }
) => {
  const { commerceProductAvailableAttributes, commerceProductAttributes } =
    context.getters

  const mountAttributes = (attributes: Array<CommerceProductAttributes>) =>
    attributes.map(attribute => {
      if (attribute.id === attributeId) {
        return {
          ...attribute,
          values: [
            ...attribute.values,
            {
              id: uuid(),
              attributeId,
              code: slugify(name),
              value: name,
              isNew: true
            }
          ]
        }
      }

      return attribute
    })

  context.commit(
    types.SET_COMMERCE_PRODUCT_AVAILABLE_ATTRIBUTES,
    mountAttributes(commerceProductAvailableAttributes)
  )

  context.dispatch(
    'setCommerceProductAttributes',
    mountAttributes(commerceProductAttributes)
  )
}

export const addCommerceProductVariants = (
  context: ActionContext<ProductsCommerceState, RootState>,
  productVariants: Array<CommerceProductVariants>
) => {
  const { commerceProductVariants } = context.getters

  const toRemove = productVariants.filter(variant => {
    return !commerceProductVariants.find(
      (registeredVariant: CommerceProductVariants) =>
        JSON.stringify(variant.attributeValues) ===
        JSON.stringify(registeredVariant.attributeValues)
    )
  })

  context.commit(types.ADD_COMMERCE_PRODUCT_VARIANTS, toRemove)
}

export const clearCommerceProductVariants = (
  context: ActionContext<ProductsCommerceState, RootState>
) => {
  context.commit(types.CLEAR_COMMERCE_PRODUCT_VARIANTS)
}

export const updateCommerceProductVariants = (
  context: ActionContext<ProductsCommerceState, RootState>,
  productVariants: Array<CommerceProductVariants>
) => {
  context.commit(types.UPDATE_COMMERCE_PRODUCT_VARIANTS, productVariants)
}

export const createCommerceProduct = async (
  context: ActionContext<ProductsCommerceState, RootState>
) => {
  const commerceCatalog =
    context.rootGetters['commerceCatalogs/commerceCatalog']

  const { commerceProduct } = context.getters

  const product = mountProductPayload(commerceProduct)

  try {
    const createdProduct = await productsService.createCommerceProduct({
      product,
      catalogId: commerceCatalog.id
    })

    context.dispatch(
      'commerceCatalogs/setCommerceCatalogProduct',
      createdProduct,
      {
        root: true
      }
    )

    return createdProduct.id
  } catch (err) {
    const messageTypes: { [key: string]: string } = {
      'sku already exists': 'warning',
      genericError: 'danger'
    }

    throw new Error(
      messageTypes[(err as any).message] || messageTypes['genericError']
    )
  }
}

export const updateCommerceProduct = async (
  context: ActionContext<ProductsCommerceState, RootState>,
  payload: {
    productId: string
  }
) => {
  const { productId } = payload

  const commerceCatalog =
    context.rootGetters['commerceCatalogs/commerceCatalog']

  const { commerceProduct } = context.getters

  await productsService.updateCommerceProduct({
    product: mountProductPayload(commerceProduct),
    productId,
    catalogId: commerceCatalog.id
  })

  context.dispatch(
    'commerceCatalogs/updateCommerceCatalogProduct',
    commerceProduct
  )

  return productId
}

export const setProductLoader = (
  context: ActionContext<ProductsCommerceState, RootState>,
  isLoader: boolean
) => {
  context.commit(types.TOGGLE_COMMERCE_PRODUCT_LOADER, isLoader)
}

export const resetCommerceProductState = (
  context: ActionContext<ProductsCommerceState, RootState>
) => {
  context.commit(types.SET_COMMERCE_PRODUCT, productInitialState)

  context.commit(types.TOGGLE_COMMERCE_PRODUCT_LOADER, true)

  context.commit(types.TOGGLE_COMMERCE_AVAILABLE_ATTRIBUTES_LOADER, true)
}
