import { headers } from '@octadesk-tech/services'

import { getChatClient } from '@/modules/Chat/services/http'

const getClient = () => getChatClient()

export const getGroups = () =>
  getClient()
    .then(client => client.get('/groups', headers.getAuthorizedHeaders()))
    .then(res => res.data)

export const getRestrictedGroups = () =>
  getClient()
    .then(client =>
      client.get('/groups/restricted', headers.getAuthorizedHeaders())
    )
    .then(res => res.data)
