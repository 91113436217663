export enum InboxTypes {
  ALL = 'all',
  BY_AGENT = 'byAgent',
  UNREAD = 'unread',
  MENTIONED = 'mentioned',
  PARTICIPATION = 'participation',
  WITHOUT_RESPONSIBLE = 'withoutResponsible',
  CHATBOT = 'chatbot',
  TIME_EXCEEDED = 'timeExceeded'
}
