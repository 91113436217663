import { TicketState, TicketStatus } from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_LATEST_LIST_ROUTE](state: TicketState, latestListRoute: string) {
    state.latestListRoute = latestListRoute
  },
  [types.SET_TICKET_STATUS](state: TicketState, ticketStatues: TicketStatus[]) {
    state.ticketStatus = ticketStatues
  },
  [types.SET_ROULETTE_STATUS](state: TicketState, rouletteStatus: boolean) {
    state.userRouletteStatus = rouletteStatus
  }
}
