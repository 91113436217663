import * as types from './mutations-types'

export default {
  [types.SET_CURRENT_WPP_API_STATE](state: any, updatedState: any) {
    state.previousState = state.currentState

    state.currentState = updatedState
  },
  [types.SET_WPP_API_NUMBER](state: any, updatedValues: any) {
    state.wppApiNumber = { ...state.wppApiNumber, ...updatedValues }

    // delete state.wppApiNumber.privateCustomFields
  },
  [types.CLEAR_CURRENT_WPP_API_NUMBER](state: any) {
    state.wppApiNumber = {
      name: '',
      number: '',
      customFields: {
        aboutDescription: '',
        thumbUrl: '',
        businessProfile: {
          address: '',
          description: '',
          email: '',
          websites: [],
          vertical: ''
        }
      },
      privateCustomFields: {
        apiKey: ''
      }
    }
  }
}
