import {
  NotificationPayload,
  NotificationSettingsState
} from '@/modules/ChatSettings/general/notifications/store/interfaces'

import legacyHeaders from '@/common/services/legacy-headers'

import { getClient } from './http'
const API_NAME = 'notificationCenter'

export class NotificationCenterService {
  getClient() {
    return getClient(API_NAME)
  }

  async getNotificationCount(status: string): Promise<number | Error> {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(
          `/api/messages/count/${status}?v=${Date.now()}`,
          legacyHeaders()
        )
      )
      return data
    } catch (ex) {
      throw new Error('Error at notificationCenter Service getCount: ' + ex)
    }
  }

  async getNotificationsSettings(): Promise<NotificationSettingsState | Error> {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(`/api/settings/web`, legacyHeaders())
      )
      return data
    } catch (ex) {
      throw new Error('Error at notificationCenter Service getSettings: ' + ex)
    }
  }
  async createNotification(
    notification: NotificationPayload
  ): Promise<NotificationSettingsState | Error> {
    try {
      const { data } = await this.getClient().then(client =>
        client.post(`/api/settings/web`, notification, legacyHeaders())
      )
      return data
    } catch (ex) {
      throw new Error(
        'Error at notificationCenter Service createNotification: ' + ex
      )
    }
  }
  async updateNotification(
    notificationSettingsId: string,
    notification: NotificationPayload
  ): Promise<NotificationSettingsState | Error> {
    try {
      const { data } = await this.getClient().then(client =>
        client.patch(
          `/api/settings/${notificationSettingsId}/web`,
          notification,
          legacyHeaders()
        )
      )
      return data
    } catch (ex) {
      throw new Error(
        'Error at notificationCenter Service updateNotification: ' + ex
      )
    }
  }
}
