import { AxiosInstance } from 'axios'

import { headers } from '@octadesk-tech/services'

import { getClient } from '@/common/services/http'

export class MessengerService {
  getClient(): Promise<AxiosInstance> {
    return getClient('multiChannel')
  }

  async retrievePages(subDomain: string) {
    return this.getClient().then(client =>
      client.get(`/accounts/${subDomain}`, headers.getAuthorizedHeaders())
    )
  }

  async retrieveAthorizedPages({ token }: any) {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(`/admin/accounts`, {
          params: {
            token
          },
          ...headers.getAuthorizedHeaders()
        })
      )

      return data
    } catch (err) {
      throw new Error('Error in retrieve athorized pages: ' + err)
    }
  }

  async updatePages({ pages }: { pages: any }) {
    return this.getClient().then(client =>
      client.post('/admin/accounts/link', pages, headers.getAuthorizedHeaders())
    )
  }

  async deleteFacebookPage(page: any) {
    try {
      await this.getClient().then(client =>
        client.post(
          '/api/facebook/UnlinkPage',
          page,
          headers.getAuthorizedHeaders()
        )
      )
    } catch (err) {
      throw new Error('Error on delete facebook page: ' + err)
    }
  }

  async deleteInstagramAccount(id: string) {
    try {
      await this.getClient().then(client =>
        client.delete(`/api/instagram/${id}`, headers.getAuthorizedHeaders())
      )
    } catch (err) {
      throw new Error('Error on delete instagram account: ' + err)
    }
  }

  async instagramRetrievePages(subDomain: string) {
    try {
      const { data } = await this.getClient().then(client =>
        client.get('/api/instagram/business-account', {
          params: {
            subDomain: subDomain
          },
          ...headers.getAuthorizedHeaders()
        })
      )

      return data
    } catch (err) {
      throw new Error('Error on get instagram retrieve pages: ' + err)
    }
  }
}
