import { LanguageTypes } from '@/common/helpers/enums/language'

export const languages = [
  {
    shortName: 'pt',
    fullName: LanguageTypes.ptBR
  },
  {
    shortName: 'en',
    fullName: LanguageTypes.enUS
  }
]
