export const productInitialState = {
  active: true,
  imageUrl: '',
  name: '',
  description: '',
  link: '',
  currency: 'BRL',
  price: 0,
  offerPrice: null,
  condition: '',
  sku: '',
  brand: 'brand',
  stock: 1,
  attributes: [],
  variants: []
}

const state = {
  loader: true,
  data: productInitialState,
  attributes: {
    loader: true,
    items: []
  }
}

export default state
