import { getClient } from './http'
import legacyHeaders from './legacy-headers'

export class CompanyService {
  private API_NAME = 'company'

  async getClient() {
    return getClient(this.API_NAME)
  }

  async getModel() {
    try {
      const client = await this.getClient()
      const { data } = await client.get('/model', legacyHeaders())
      return data
    } catch {
      console.error('Error on get company model')
    }
  }
}
