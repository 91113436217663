import { services } from '@octadesk-tech/services'
import { headers } from '@octadesk-tech/services'

let _client
export const getClient = async () => {
  if (_client) {
    return _client
  }
  return (_client = await services.chat.getClient())
}

export const createFlux = flux =>
  getClient().then(client =>
    client.post('flux', flux, headers.getAuthorizedHeaders())
  )

export const getFlux = fluxId =>
  getClient()
    .then(client =>
      client.get(`flux/${fluxId}`, headers.getAuthorizedHeaders())
    )
    .then(res => res.data)

export const updateFlux = flux =>
  getClient().then(client =>
    client.put(`flux/${flux.id}`, flux, headers.getAuthorizedHeaders())
  )

export const activeFlux = flux =>
  getClient().then(client =>
    client.put(`flux/active`, flux, headers.getAuthorizedHeaders())
  )

export const inactiveFlux = flux =>
  getClient().then(client =>
    client.put(`flux/inactive`, flux, headers.getAuthorizedHeaders())
  )

export const duplicateFlux = (fluxId, request) =>
  getClient()
    .then(client =>
      client.post(
        `flux/${fluxId}/duplicate`,
        request,
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)

export const migrateFlux = (fluxId, request) =>
  getClient()
    .then(client =>
      client.post(
        `flux/${fluxId}/migrate`,
        request,
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)

export const sortFluxes = sortedFluxes =>
  getClient().then(client =>
    client.put(`flux/sort`, sortedFluxes, headers.getAuthorizedHeaders())
  )

export const deleteFlux = fluxId =>
  getClient().then(client =>
    client.delete(`flux/${fluxId}`, headers.getAuthorizedHeaders())
  )

export const getFluxesById = botIds =>
  getClient()
    .then(client =>
      client.get(`flux?botId=${botIds}`, headers.getAuthorizedHeaders())
    )
    .then(res => {
      if (res.data) {
        return res.data.map(bot => bot.name)
      }

      return []
    })

export const getFlowsByNumbers = numbers =>
  getClient().then(client => {
    const payload = {
      sessions: numbers.map(({ number }) => ({
        isAutomation: true,
        onlyEnabled: false,
        channel: 'whatsapp',
        room: {
          isProActive: 'false',
          domainFrom: number,
          hasAgentMessage: 'true'
        },
        event: 'after-close-async'
      }))
    }

    return client
      .post('flux/choose', payload, headers.getAuthorizedHeaders())
      .then(response => response.data)
  })

export const createFluxAndBot = payload =>
  getClient().then(client =>
    client
      .post('flux/bots', payload, headers.getAuthorizedHeaders())
      .then(response => response.data)
  )
