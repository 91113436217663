import { AxiosInstance } from 'axios'

import { createClient } from './http'

export default class HubspotService {
  private baseUrl: string
  private http: AxiosInstance
  constructor() {
    this.baseUrl = 'https://api.hsforms.com'
    this.http = createClient({ baseURL: this.baseUrl })
  }

  async submitForm(
    portalId = '5475045',
    formId = '5840b318-15c9-46e9-869a-1fb7038f8df2',
    payload: any
  ) {
    try {
      const url = `submissions/v3/integration/submit/${portalId}/${formId}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json, text/plain, */*'
        }
      }
      const { data } = await this.http.post(url, payload, options)
      return data
    } catch (ex) {
      throw new Error('Error at hubspot submitForm' + ex)
    }
  }
}
