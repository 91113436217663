/* eslint-disable sonarjs/no-duplicate-string */
export default greetings => ({
  status: 0,
  messages: [
    {
      key: '92252565-e24a-4210-8573-a47da3e8a0c3',
      user: {
        Id: '06e30b6e-e610-46da-9022-623d43bc8b82',
        id: '06e30b6e-e610-46da-9022-623d43bc8b82',
        isEnabled: true,
        dateCreation: '2019-03-20T18:51:22.129Z',
        name: 'Octadesk',
        email: 'octadesk@octadesk.com',
        thumbUrl: null,
        customerCode: null,
        isLocked: false,
        othersEmail: [],
        type: 2,
        permissionView: 1,
        roleType: 5,
        permissionType: 2,
        participantPermission: 0,
        othersCustomerCode: [],
        organization: {
          Id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
          id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
          isEnabled: true,
          dateCreation: '2018-03-15T21:58:00.777Z',
          name: 'Octadesk',
          idCompany: 2715,
          default: false,
          description: null,
          domain: 'octadesk.com',
          domains: ['octadesk.com'],
          products: [],
          phoneContacts: []
        },
        organizations: [
          {
            Id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
            id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
            isEnabled: true,
            dateCreation: '2018-03-15T21:58:00.777Z',
            name: 'Octadesk',
            idCompany: 2715,
            default: false,
            description: null,
            domain: 'octadesk.com',
            domains: ['octadesk.com'],
            products: [],
            phoneContacts: []
          }
        ],
        myApps: [],
        products: [],
        facebookId: null,
        facebookUserName: null,
        myTicketViews: [],
        groups: [],
        lastLoginId: null,
        loginTries: 0,
        apps: [
          {
            productId: '67e299e2-b573-4d1a-8b0b-f9b6068a5262',
            productName: 'Chat',
            fields: { name: 'Octadesk' }
          }
        ],
        phoneContacts: [],
        deviceTokens: [],
        webPushSubscriptions: []
      },
      comment:
        greetings +
        'Esta é sua primeira mensagem no Octadesk e estou aqui para te ajudar a respondê-la.\nNesta tela, é possível ver todas as interações feitas entre você e seu cliente.',
      type: 'public',
      time: new Date(),
      domainFrom: '',
      attachments: [],
      status: 'sending'
    }
  ],
  domainFrom: '',
  organization: {
    dateCreation: '2018-03-15T21:58:00.777Z',
    name: 'Octadesk',
    idCompany: 2715,
    domain: 'octadesk.com',
    phoneContacts: [],
    products: [],
    domains: [],
    description: null,
    isEnabled: true,
    id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
    _id: '83d6dc28-6595-4a76-bb10-b45c68becd69'
  },
  flux: {
    id: '5bfec94e20b0c200153659e8',
    _id: '5bfec94e20b0c200153659e8',
    name: 'Fluxo Principal',
    enabled: true,
    order: 0,
    allConditions: true,
    formRequired: true,
    statistics: {
      messages: {
        sended: 1596,
        opened: 4
      },
      contacts: {
        new: 10,
        existent: 158
      },
      chats: {
        auto: 0,
        manual: 431,
        closed: 206,
        missed: 34,
        offline: 2
      },
      survey: {
        satisfied: 4,
        unsatisfied: 0
      }
    },
    conditions: [],
    rules: [],
    kb: {
      enabled: true,
      title: 'Olá! Em que podemos te ajudar?'
    },
    message: {
      enabled: true,
      sender: {
        id: '',
        _id: '',
        name: 'Sem remetente',
        type: 2,
        roleType: 4,
        customFields: [],
        phoneContacts: [],
        organizations: [],
        products: [],
        webPushSubscriptions: [],
        guest: false,
        active: false,
        connectionStatus: 1,
        connectionStatusManual: false,
        lastService: '-271821-04-20T00:00:00.000Z'
      },
      comment: 'salve'
    },
    survey: {
      enabled: true,
      title: 'Avalie o atendimento dessa conversa'
    }
  },
  user: {
    Id: '06e30b6e-e610-46da-9022-623d43bc8b82',
    id: '06e30b6e-e610-46da-9022-623d43bc8b82',
    isEnabled: true,
    dateCreation: '2019-03-20T17:17:01.828Z',
    name: 'Octadesk',
    email: 'octadesk@octadesk.com',
    thumbUrl: null,
    customerCode: null,
    isLocked: false,
    othersEmail: [],
    type: 2,
    permissionView: 1,
    roleType: 5,
    permissionType: 2,
    participantPermission: 0,
    othersCustomerCode: [],
    organization: {
      Id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
      id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
      isEnabled: true,
      dateCreation: '2018-03-15T21:58:00.777Z',
      name: 'Octadesk',
      idCompany: 2715,
      default: false,
      description: null,
      domain: 'octadesk.com',
      domains: ['octadesk.com'],
      products: [],
      phoneContacts: []
    },
    organizations: [
      {
        Id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
        id: '83d6dc28-6595-4a76-bb10-b45c68becd69',
        isEnabled: true,
        dateCreation: '2018-03-15T21:58:00.777Z',
        name: 'Octadesk',
        idCompany: 2715,
        default: false,
        description: null,
        domain: 'octadesk.com',
        domains: ['octadesk.com'],
        products: [],
        phoneContacts: []
      }
    ],
    myApps: [],
    products: [],
    facebookId: null,
    facebookUserName: null,
    myTicketViews: [],
    groups: [],
    lastLoginId: null,
    loginTries: 0,
    apps: [
      {
        productId: '67e299e2-b573-4d1a-8b0b-f9b6068a5262',
        productName: 'Chat',
        fields: {
          name: 'Octadesk'
        }
      }
    ],
    phoneContacts: [],
    deviceTokens: [],
    webPushSubscriptions: []
  },
  widgetEnvironmentInformation: {
    os: 'Windows 8.1',
    device: null,
    browser: 'Chrome',
    language: 'pt-BR'
  },
  clientTimeZone: 180
})
