import * as types from '@/modules/Chat/components/conversation/store/mutations-types'
import isAllowedToTriggerNotification from '@/modules/Chat/helpers/is-allowed-to-trigger-notification'

import store from '@/store'

const STOP_TYPING_TIMEOUT_TIME = 3500 // 3.5s

let forceStopTypingTimeout = null
const forceStopTyping = (context, user) => {
  if (forceStopTypingTimeout) clearTimeout(forceStopTypingTimeout)

  forceStopTypingTimeout = setTimeout(() => {
    if (context.getters.typing) socketUserTypingStop(context, user)
  }, STOP_TYPING_TIMEOUT_TIME)
}

export const socketUserTypingStart = (context, user) => {
  if (context.getters.agentId !== user.id) {
    context.commit(types.START_TYPING)
    context.commit(types.TYPING_USER, user)
  }

  if (context.getters.typing) forceStopTyping(context, user)
}

export const socketUserTypingStop = (context, user) => {
  if (context.getters.agentId !== user.id) {
    context.commit(types.STOP_TYPING)
    context.dispatch('clearTypingUser')
  }
}

export const socketRoomUpdate = (context, room) => {
  if (context.getters.chatKey === room.key) {
    context.dispatch('updateChatLoop')

    context.commit(types.UPDATE_CHAT, room)
  }
}

export const socketRoomMessage = (context, room) => {
  if (context.getters.chatKey === room.key) {
    context.dispatch('updateChatLoop')

    context.commit(types.UPDATE_CHAT, room)

    context.dispatch('readMessages')
  }
}

export const socketRoomRelease = (context, { key } = {}) => {
  if (context.getters.chatKey === key)
    context.dispatch('setPersonById', context.getters?.createdBy?.id)
}

export const socketAllNotification = (_context, notification) => {
  if (isAllowedToTriggerNotification(notification)) {
    store.dispatch('notification/showVisualNotification', notification)
    store.dispatch('notification/pushSoundNotification', notification.sound)
  }
}

export const socketSoundNotification = (_context, notification) => {
  if (isAllowedToTriggerNotification(notification)) {
    store.dispatch('notification/pushSoundNotification', notification.sound)
  }
}
export const socketVisualNotification = (_context, notification) => {
  if (isAllowedToTriggerNotification(notification)) {
    store.dispatch('notification/showVisualNotification', notification)
  }
}
