import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import Storage from '@octadesk-tech/storage'

import { channels } from '@/modules/Chat/components/conversation/enums/chat-channels'
import { ProfileTypes } from '@/modules/Chat/components/conversation/enums/profile-types'
import { RoleTypes } from '@/modules/Chat/components/conversation/enums/role-types'

dayjs.extend(utc)

export const fetchedInitialData = ({ fetchedInitialData }) => fetchedInitialData

export const company = ({ userLogged }) =>
  (userLogged && userLogged.subDomain) || Storage.getItem('company')

export const agent = ({ agent }) => agent

export const agentId = ({ agent }) => (agent ? agent.id : undefined)

export const agents = ({ agents }) => agents

export const ascendingOrderedAgents = ({ agents }) =>
  // eslint-disable-next-line no-undef
  structuredClone(agents)?.sort((a, b) => a.name.localeCompare(b.name))

export const groups = ({ groups }) => groups

export const restrictedGroups = ({ restrictedGroups }) => restrictedGroups

export const visibleGroups = ({ groups, restrictedGroups, agent }) => {
  if (agent && [RoleTypes.owner, RoleTypes.admin].includes(agent.roleType))
    return groups

  if (!restrictedGroups || !restrictedGroups.length) return groups

  return groups.filter(group =>
    restrictedGroups.every(restricted => restricted.id !== group.id)
  )
}
export const connectedSocket = ({ connectedSocket }) => connectedSocket

export const onlineAgents = ({ onlineAgents }) => onlineAgents

export const allChatsCount = ({ allChatsCount }) => allChatsCount

export const owners = ({ owners }) => owners

export const integratorWhatsappNumbers = ({ integratorWhatsappNumbers }) =>
  integratorWhatsappNumbers

export const whatsappNumbers = ({
  integratorWhatsappNumbers,
  whatsappNumbers
}) => [].concat(integratorWhatsappNumbers, whatsappNumbers) || []

export const proActiveWhatsAppNumbers = (
  _,
  { isProActiveEnabled, whatsappNumbers }
) => whatsappNumbers.filter(number => isProActiveEnabled(number))

export const isProActiveEnabled = (state, getters) => number => {
  const currentIntegrator = getCurrentIntegrator(state, getters)(number)

  const channel =
    currentIntegrator &&
    currentIntegrator.channels.find(ch => ch.name === channels.whatsapp)

  return (
    (channel && channel.proactive && channel.proactive.isEnabled) ||
    (currentIntegrator && currentIntegrator.hoursToAnswer > 0)
  )
}

export const getCurrentWhatsAppNumber =
  (_, { currentChat, whatsappNumbers }) =>
  () => {
    if (!currentChat || currentChat.channel !== channels.whatsapp) return null

    const number = currentChat && currentChat.domainFrom

    const cleanedNumber = number && number.toString().replace(/\D/g, '')

    return whatsappNumbers.find(
      w => w.number.toString().replace(/\D/g, '') === cleanedNumber
    )
  }

export const getCurrentFacebookPage =
  (_, { currentChat }) =>
  () => {
    if (!currentChat || currentChat.channel !== channels.facebookMessenger)
      return null

    return currentChat && currentChat.domainFrom
  }

export const getCurrentIntegrator = (state, getters) => reference => {
  const wppNumberOrFacebookPage =
    reference ||
    getCurrentWhatsAppNumber(state, getters)() ||
    getCurrentFacebookPage(state, getters)()

  if (!wppNumberOrFacebookPage) return null

  const { integrators } = state

  const integratorName = wppNumberOrFacebookPage.integrator || 'octadesk'

  return integrators && integrators.find(i => integratorName === i.name)
}

export const hasChargeWindows = (_, { currentChat }) => {
  if (!currentChat) return false

  return !!currentChat.chargeWindows.length
}

export const hasSomeNowBeforeChargeWindowExpiresAt = (_, { currentChat }) => {
  if (!currentChat) return false

  return currentChat.chargeWindows
    .map(item => dayjs().utc().valueOf() < dayjs(item?.expiresAt).valueOf())
    .some(item => item)
}

export const lastMessageIsOfContact = (_, { currentChat }) => {
  if (!currentChat) return false

  return currentChat?.lastMessageUserType === ProfileTypes.Contact
}

export const hoursToAnswer = (state, getters) => {
  const currentIntegrator = getCurrentIntegrator(state, getters)()

  return currentIntegrator?.hoursToAnswer || 24
}

export const nowIsBeforeClientLastMessageDate = (state, getters) => {
  const { currentChat } = getters

  const currentIntegrator = getCurrentIntegrator(state, getters)()

  const hoursToAnswer = currentIntegrator?.hoursToAnswer || 24

  return (
    dayjs().utc().subtract(hoursToAnswer, 'hours').valueOf() <
    dayjs(currentChat?.clientLastMessageDate).valueOf()
  )
}

export const isReceptiveMessageUnanswered = (state, getters) => () => {
  const {
    lastMessageIsOfContact,
    nowIsBeforeClientLastMessageDate,
    hasChargeWindows,
    hasSomeNowBeforeChargeWindowExpiresAt,
    currentChat
  } = getters

  const currentIntegrator = getCurrentIntegrator(state, getters)()

  return (
    lastMessageIsOfContact &&
    nowIsBeforeClientLastMessageDate &&
    (!hasChargeWindows || !hasSomeNowBeforeChargeWindowExpiresAt) &&
    currentChat?.channel === channels.whatsapp &&
    currentIntegrator
  )
}

export const isSendingMessageWindowOpen = (state, getters) => () => {
  const {
    currentChat,
    hasChargeWindows,
    hasSomeNowBeforeChargeWindowExpiresAt,
    lastMessageIsOfContact,
    nowIsBeforeClientLastMessageDate
  } = getters

  if (!currentChat) return false

  if (currentChat.channel === channels.web) return true

  const currentIntegrator = getCurrentIntegrator(state, getters)()

  if (!currentIntegrator || currentIntegrator.hoursToAnswer <= 0) return true

  if (!currentChat || !currentChat.clientLastMessageDate) return false

  return (
    (hasChargeWindows &&
      hasSomeNowBeforeChargeWindowExpiresAt &&
      lastMessageIsOfContact) ||
    (lastMessageIsOfContact && nowIsBeforeClientLastMessageDate) ||
    nowIsBeforeClientLastMessageDate
  )
}

export const isRestrictedToOpenSession = (state, getters) => () => {
  const currentIntegrator = getCurrentIntegrator(state, getters)()

  if (!currentIntegrator) return false

  const { currentChat } = getters

  const channel = currentIntegrator.channels.find(
    ch => ch.name === currentChat.channel
  )

  return channel && channel.proactive && channel.proactive.needTemplate
}

export const settings = ({ settings }) => settings

export const serviceTimeoutSettings = ({ settings }) =>
  settings?.conversation?.serviceTimeout

export const responseServiceTimeout = (
  _,
  { serviceTimeoutSettings },
  rootState,
  { isResponseServiceTimeoutEnabled }
) =>
  isResponseServiceTimeoutEnabled &&
  serviceTimeoutSettings &&
  Object.entries(serviceTimeoutSettings).some(([, config]) => config.enabled)

export const useDistributor = ({ settings }) =>
  settings &&
  settings.useDistributor !== undefined &&
  settings.useDistributor !== null
    ? settings.useDistributor
    : false
export const integrators = ({ integrators }) => integrators

export const getResponsive = ({ responsive }) => responsive

export const defaultEmailAddress = ({ defaultEmailAddress }) =>
  defaultEmailAddress

export const isEmbedded = () => self !== top

export const restrictedWhatsappNumbers = ({ restrictedWhatsappNumbers }) =>
  restrictedWhatsappNumbers
