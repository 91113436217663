import { features } from '@/modules/Chat/components/sidebar/enums/features'
import { notificationType } from '@/modules/Chat/components/sidebar/enums/notification-type'

export default [
  {
    enabled: true,
    forbiddenFeatures: [features.whatsAppBusinessAPI],
    mandatoryFeatures: [features.whatsAppAPIAds],
    notificationType: notificationType.WHATSAPP_API_ADS_NOTIFICATION
  }
]
