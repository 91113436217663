import types from '@/modules/Bot/store/mutations-types'

import botStructure from './bot'
import botFlux from './bot-flux'
import breadcrumb from './breadcrumb'
import commerce from './commerce'
import contentProperties from './content-properties'
import customFields from './custom-fields'
import interactions from './interactions'
import officeHours from './office-hours'
import sidebar from './sidebar'

const botMutations = {
  [types.SET_CHATBOTS](state, chatBots) {
    state.chatBots = chatBots ? [...chatBots] : []
  },
  [types.UPSERT_CHATBOT](state, chatBot) {
    if (chatBot) {
      const index = state.chatBots.findIndex(cBot => cBot.id === chatBot.id)

      if (index >= 0) {
        state.chatBots.splice(index, 1, chatBot)
      } else {
        state.chatBots = [...state.chatBots, chatBot]
      }
    }
  },
  [types.SET_BOT_BUILDER](state, status) {
    state.botBuilderVersion = status
  },
  [types.REMOVE_CHATBOT](state, chatBot) {
    const index = state.chatBots.findIndex(cbot => cbot.id === chatBot.id)

    if (index >= 0) {
      state.chatBots.splice(index, 1)
    }
  },
  [types.SET_CURRENT_CHATBOT](state, chatBot) {
    if (chatBot) {
      state.currentChatBot = {
        ...chatBot
      }
    } else {
      state.currentChatBot = null
    }
  },
  [types.SET_BOT_CHANNEL](state, channel) {
    state.botChannel = channel
  },
  [types.TOGGLE_MODAL_CHANNEL_RESTRICTIONS](state) {
    state.openModalChannelRestrictions = !state.openModalChannelRestrictions
  },
  [types.SET_CHANNELS_SPECIFICATIONS](state, specifications) {
    state.botSpecifications.specifications = specifications
  },
  [types.SET_BOT_CHANNELS_SPECIFICATIONS](state, channels) {
    state.botSpecifications.channels = channels
  },
  [types.SET_CHANNELS_INFO_SPECIFICATIONS](state, channelsInfo) {
    state.botSpecifications.channelsInfo = channelsInfo
  },
  [types.SET_DUPLICATE_BOT_FLUX](state, botDuplicate) {
    state.duplicateBotFlux = botDuplicate
  },
  [types.SET_DUPLICATE_FLUX_ID](state, fluxId) {
    state.duplicateFluxId = fluxId
  },
  [types.SET_MIGRATE_BOT_FLUX](state, botMigrate) {
    state.migrateBotFlux = botMigrate
  },
  [types.SET_MIGRATE_FLUX_ID](state, fluxId) {
    state.migrateFluxId = fluxId
  },
  [types.SET_WHATSAPP_NUMBERS](state, numbers) {
    state.whatsappNumbers = numbers
  },
  [types.SET_FACEBOOK_PAGES](state, value) {
    state.facebookPages = value
  },
  [types.SET_INSTAGRAM_PAGES](state, value) {
    state.instagramPages = value
  }
}

export default {
  ...botStructure,
  ...botMutations,
  ...contentProperties,
  ...breadcrumb,
  ...botFlux,
  ...interactions,
  ...sidebar,
  ...commerce,
  ...officeHours,
  ...customFields
}
