import {
  TagsManagerState,
  TagsManagerNewTag,
  TagsManagerTag,
  TagsManagerTagsCount,
  TagsManagerTagsConfirmAction
} from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SORT_TAGS_BY_STATUS](
    state: TagsManagerState,
    tags: Array<TagsManagerTag>
  ) {
    state.tags = tags
  },
  [types.ADD_TAG](state: TagsManagerState, tag: TagsManagerTag) {
    state.tags.push(tag)
  },
  [types.UPDATE_TAG](state: TagsManagerState, updatedTag: TagsManagerTag) {
    const currentIndex = state.tags.findIndex(tag => tag.id === updatedTag.id)

    if (currentIndex > -1) {
      const updatedTags = [...state.tags]

      updatedTags[currentIndex] = {
        ...updatedTag
      }

      state.tags = updatedTags
    }
  },
  [types.REMOVE_TAG](state: TagsManagerState, tagId: string) {
    const updatedTags = state.tags.filter(t => t.id !== tagId)

    state.tags = updatedTags
  },
  [types.REMOVE_TAGS](state: TagsManagerState, tags: Array<TagsManagerTag>) {
    const updatedTags = state.tags.filter(
      sTag => !tags.some(tag => tag.id === sTag.id)
    )

    state.tags = updatedTags
  },
  [types.SET_NEW_TAG](state: TagsManagerState, newTag: TagsManagerNewTag) {
    state.newTag = newTag
  },
  [types.UPDATE_SEARCH_FILTER](state: TagsManagerState, searchFilter: string) {
    state.searchFilter = searchFilter
  },
  [types.SET_TAGS_COUNTS](
    state: TagsManagerState,
    counts: TagsManagerTagsCount
  ) {
    state.tagsCounts = { ...counts }
  },
  [types.SET_TAGS_CONFIRM_ACTION](
    state: TagsManagerState,
    payload: TagsManagerTagsConfirmAction
  ) {
    state.tagsConfirmAction = payload
  }
}
