import * as types from './mutations-types'

export default {
  [types.SET_FETCHED_INITIAL_DATA](state, hasFetchedInitialData) {
    state.fetchedInitialData = hasFetchedInitialData
  },
  [types.SET_AGENT](state, agent) {
    state.agent = agent
  },
  [types.SET_AGENT_STATUS](state, status) {
    state.agent.connectionStatus = status
  },
  [types.SET_AGENTS](state, agents) {
    state.agents = agents
  },
  [types.SET_GROUPS](state, groups) {
    state.groups = groups
  },
  [types.SET_RESTRICTED_GROUPS](state, groups) {
    state.restrictedGroups = groups
  },
  [types.SET_CONNECTED](state, connectedSocket) {
    state.connectedSocket = connectedSocket
  },
  [types.SET_ONLINE_AGENTS](state, onlineAgents) {
    state.onlineAgents = onlineAgents
  },
  [types.SET_ALL_CHATS_COUNT](state, count) {
    state.allChatsCount = count
  },
  [types.SET_OWNERS](state, owners) {
    state.owners = owners
  },
  [types.SET_INTEGRATOR_WHATSAPP_NUMBERS](state, numbers) {
    state.integratorWhatsappNumbers = numbers
  },
  [types.SET_WHATSAPP_NUMBERS](state, numbers) {
    state.whatsappNumbers = numbers
  },
  [types.SET_RESTRICTED_WHATSAPP_NUMBERS](state, numbers) {
    state.restrictedWhatsappNumbers = numbers
  },
  [types.SET_SETTINGS](state, settings) {
    state.settings = settings
  },
  [types.SET_SETTINGS_NOTIFY_AGENTS](state, toggleEnable) {
    state.settings.conversation.notifyAgents.enabled = toggleEnable
  },
  [types.SET_INTEGRATORS](state, integrators) {
    state.integrators = integrators
  },
  [types.SET_TRY_CREATE_ROOM](state, tryData) {
    state.tryCreateRoomData = tryData
  },
  [types.SET_SELECT_TEMPLATE_MESSAGE](state, chat) {
    state.selectTemplateChat = chat
  },
  [types.SET_RESPONSIVE](state, responsive) {
    const self = state.responsive
    state.responsive = { ...self, ...responsive }
  }
}
