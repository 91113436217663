export enum StatusSalesLead {
  ID = '8240dda2-efc3-bbc2-b7ad-0db35e603472'
}

export enum StatusSalesCustomer {
  ID = '3cbca8fe-a032-9d10-bf31-92d2aa283426'
}

export enum Filters {
  TEXT = 'TextSearch',
  ENABLED = 'IsEnabled',
  TYPE = 'Type',
  RESPONSIBLE = 'ResponsibleId'
}

export enum ContactsBulkAction {
  OnlyIds = 'onlyIds',
  IncludesAll = 'includesAll'
}
