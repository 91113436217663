import { TicketSettingsState } from './interfaces'

const state: TicketSettingsState = {
  ticketsCustomFields: [],
  policy: {
    isCreateNewTicketEnabled: false,
    afterDays: 3,
    fieldsToInherit: {},
    isNewTicketRestrictionEnabled: false,
    archivingTicketsConfig: {
      archiveDoneAfterDays: 120
    }
  }
}

export default state
