import { TokensApiState } from './interfaces'

const state: TokensApiState = {
  tokens: [],
  tokensLoading: false,
  tokensError: false,
  editToken: {},
  removeToken: {}
}

export default state
