import UserTypes from '@/modules/Bot/helpers/userTypes'

import i18n from '@/common/i18n'

import store from '@/store'

import blank from './blank'
import connectWithSales from './connect-with-sales'
import onboardingTemplate from './onboarding-template'
import preScheduling from './pre-scheduling'
import qualifyLead from './qualify-lead'
import selfService from './self-service'
import smartQualification from './smart-qualification'
import takeOrderAndConnect from './take-order-and-connect'

export const generateFluxTemplate = (fluxName, channel) => ({
  name: fluxName,
  channel,
  formId: '',
  formRequired: false,
  kb: {
    title: '',
    enabled: false
  },
  conditions: [],
  statistics: {
    chats: {
      manual: 0,
      auto: 0
    },
    contacts: {
      existent: 0,
      new: 0
    },
    messages: {
      opened: 0,
      sended: 0
    }
  },
  order: 0,
  enabled: false,
  allConditions: true,
  rules: [],
  botId: null,
  message: {
    enabled: false,
    comment: ''
  }
})

export const generateDefaultPersona = channel => ({
  name: !store.getters.isTrayWhitelabel ? 'Octabot' : 'Bot',
  email: !store.getters.isTrayWhitelabel
    ? 'octabot@octachat.com'
    : 'bot@chat.com',
  type: UserTypes.system,
  thumbUrl: `https://cdn.octadesk.com/chatbot-thumbs/${channel}.svg`
})

export const generateDefaultButton = (value, id = '') => {
  const translation = i18n?.te(value) ? i18n?.t(value) : value

  return {
    label: translation,
    value: translation,
    id: id || translation
  }
}

export const proActiveMessage = comment => ({
  enabled: true,
  comment,
  sender: {
    name: 'Octabot',
    email: 'octabot@octachat.com',
    type: UserTypes.system,
    thumbUrl: 'https://octadeskstorage.blob.core.windows.net/avatar/OctaBot.png'
  }
})

export {
  blank,
  qualifyLead,
  smartQualification,
  selfService,
  connectWithSales,
  takeOrderAndConnect,
  preScheduling,
  onboardingTemplate
}
