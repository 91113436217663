import {
  FormSteps,
  TemplateCategories,
  OptionsPermissions
} from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v2/template-messages.enum'

import { TemplateMessagesState, FormPayload } from './interfaces'

export const TEMPLATE_MESSAGE_FORM_PAYLOAD_INITIAL_DATA: FormPayload = {
  name: '',
  category: TemplateCategories.Marketing,
  permissions: {
    view: [OptionsPermissions.Everyone],
    edit: [OptionsPermissions.Everyone]
  },
  components: {} as any
}

const state: TemplateMessagesState = {
  whatsAppNumbers: {
    loader: false,
    items: []
  },
  availableCategories: {
    loader: false,
    items: []
  },
  templateMessages: {
    loader: false,
    items: []
  },
  confirmDeleteTemplateMessage: {
    show: false,
    id: ''
  },
  listPreviewTemplate: {
    open: false,
    id: ''
  },
  templateMessage: {
    step: FormSteps.BasicInformations,
    initialFormPayload: TEMPLATE_MESSAGE_FORM_PAYLOAD_INITIAL_DATA,
    formPayload: TEMPLATE_MESSAGE_FORM_PAYLOAD_INITIAL_DATA,
    showDiscardContentAlert: false,
    showReview: false,
    showSuccessModal: false,
    validTemplateMessageName: {
      isValid: true,
      loader: false
    }
  },
  personFields: [],
  chatFields: [],
  organizationFields: []
}

export default state
