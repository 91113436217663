import { ActionContext } from 'vuex'

import { WozPreferencesService } from '@/modules/Woz/preferences/preferences.service'

import { RootState } from '@/store/interfaces'

import {
  WozPreferencesState,
  WozTranscriptPreferences,
  WozAgentPreferences,
  WozSummaryPreferences
} from './interfaces'
import * as types from './mutations-types'

const wozPreferencesService = new WozPreferencesService()

export const setFetchPreferencesLoading = (
  context: ActionContext<WozPreferencesState, RootState>,
  isLoading: boolean
) => {
  context.commit(types.SET_WOZ_PREFERENCES_LOADING, isLoading)
}

export const setFetchPreferencesError = (
  context: ActionContext<WozPreferencesState, RootState>,
  isError: boolean
) => {
  context.commit(types.SET_WOZ_PREFERENCES_ERROR, isError)
}

export const fetchWozPreferences = async (
  context: ActionContext<WozPreferencesState, RootState>
) => {
  context.dispatch('setFetchPreferencesLoading', true)

  context.dispatch('setFetchPreferencesError', false)

  try {
    const settings = await wozPreferencesService.fetchPreferences()

    context.dispatch('setWozTranscriptPreferences', settings.transcript)

    context.dispatch('setWozAgentPreferences', settings.agent)

    context.dispatch('setWozSummaryPreferences', settings.summary)
  } catch (error) {
    console.error(error)

    context.dispatch('setFetchPreferencesError', true)
  } finally {
    context.dispatch('setFetchPreferencesLoading', false)
  }
}

export const setWozTranscriptPreferences = (
  context: ActionContext<WozPreferencesState, RootState>,
  preferences: WozTranscriptPreferences
) => {
  context.commit(types.SET_WOZ_TRANSCRIPT_PREFERENCES, preferences)
}

export const setWozAgentPreferences = (
  context: ActionContext<WozPreferencesState, RootState>,
  preferences: WozAgentPreferences
) => {
  context.commit(types.SET_WOZ_AGENT_PREFERENCES, preferences)
}

export const setWozSummaryPreferences = (
  context: ActionContext<WozPreferencesState, RootState>,
  preferences: WozSummaryPreferences
) => {
  context.commit(types.SET_WOZ_SUMMARY_PREFERENCES, preferences)
}
