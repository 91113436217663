import { ActionContext } from 'vuex'

import { RootState } from '@/store/interfaces'
import {
  DataSourceState,
  DataSourceValue
} from '@/store/interfaces/data-source-state'

import * as DataSourceResolvers from './resolvers'
import { DataSourceResolver } from './resolvers/interfaces'

const Resolvers: { [key: string]: DataSourceResolver } = DataSourceResolvers

export const getStorageDataSource = async (
  context: ActionContext<DataSourceState, RootState>,
  data: { dataSource: string; searchValue?: string }
): Promise<DataSourceValue[]> => {
  const resolver = Resolvers[data.dataSource]
  if (!resolver) {
    return []
  }

  return await resolver(data.searchValue)
}
