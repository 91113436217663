import { ActionContext } from 'vuex'

import { FormService } from '@/modules/Ticket/talktous/form.service'

import { RootState } from '@/store/interfaces'

import { ContactForm, ContactFormSelected } from './interfaces'
import * as types from './mutations-types'

const formService = new FormService()

export const list = async (context: ActionContext<any, RootState>) => {
  context.commit(types.SET_FORMS_LOADING, true)
  try {
    const res = await formService.list()
    const forms = getFormListCompleted(res)
    const idLastForm = getLastFormId(forms)
    context.commit(types.SET_FORMS, forms)
    context.commit(types.SET_ID_LAST_FORM, idLastForm)
  } catch (err) {
    console.error(err)
    context.commit(types.SET_FORMS_ERROR, true)
    context.commit(types.SET_FORMS_LOADING, false)
    throw new Error((err as any).message)
  } finally {
    context.commit(types.SET_FORMS_LOADING, false)
  }
}

const getLastFormId = (forms: ContactForm[]) => {
  if (forms && forms.length > 0) {
    if (forms[forms.length - 1].id === null) {
      return forms[forms.length - 2].id
    } else {
      return forms[forms.length - 1].id
    }
  }
  return ''
}

const getFormListCompleted = (data: any) => {
  let forms: ContactForm[] = []
  if (data && data.length > 0) {
    forms = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Ticket Padrão'
      }
    ].concat(data) as ContactForm[]
  }
  forms.push({
    id: null,
    name: 'Novo Formulário'
  })
  return forms
}

export const setLinkStyle = async (
  context: ActionContext<any, RootState>,
  linkStyle: string
) => {
  context.commit(types.SET_FORM_SELECTED_LINK_STYLE, linkStyle)
}

export const setLink = async (
  context: ActionContext<any, RootState>,
  link: string
) => {
  context.commit(types.SET_FORM_SELECTED_LINK, link)
}

export const setEmbeddedCode = async (
  context: ActionContext<any, RootState>,
  embeddedCode: string
) => {
  context.commit(types.SET_EMBEDDED_CODE, embeddedCode)
}

export const setFormSelectedEmpty = async (
  context: ActionContext<any, RootState>
) => {
  const formSelected: ContactFormSelected = {
    id: '',
    name: '',
    link: '',
    linkStyle: '',
    embeddedCode: '',
    loading: false,
    formLinkCss: {},
    formSelectedError: false
  }
  context.commit(types.SET_FORM_SELECTED, formSelected)
}

export const setFormSelected = async (
  context: ActionContext<any, RootState>,
  form: ContactForm
) => {
  const formSelected: ContactFormSelected = {
    id: form.id,
    name: form.name ? form.name : '',
    link: '',
    linkStyle: '',
    embeddedCode: '',
    loading: false,
    formLinkCss: {},
    formSelectedError: false
  }
  context.commit(types.SET_FORM_SELECTED, formSelected)
  try {
    await buildEmbeddedCode(context, formSelected)
  } catch (err) {
    console.error(err)
    throw new Error((err as any).message)
  } finally {
    context.commit(types.SET_FORM_SELECTED_LOADING, false)
  }
}

const buildEmbeddedCode = async (
  context: ActionContext<any, RootState>,
  formSelected: ContactForm
) => {
  context.commit(types.SET_FORM_SELECTED_LOADING, true)
  try {
    const embeddedCode = await formService.buildEmbeddedCode(
      formSelected.id ? formSelected.id : ''
    )
    context.commit(types.SET_EMBEDDED_CODE, embeddedCode)
  } catch (err) {
    console.error(err)
    context.commit(types.SET_FORM_SELECTED_ERROR, true)
    context.commit(types.SET_FORM_SELECTED_LOADING, false)
    throw new Error((err as any).message)
  }
}

const createStyleContactFormCompleted = (response: any) => {
  const fields = response.fields
  const selectedForm = {
    css: '',
    fields: [
      '.octadesk__custom-form__alert { }',
      '.octadesk__custom-form__alert--success { }',
      '.octadesk__custom-form__alert--warning { }',
      '.octadesk__custom-form__alert--danger { }',
      '.octadesk__custom-form__message--error { }',
      '.octadesk__custom-form__container {}',
      '.octadesk__custom-form__label__required',
      '.octadesk__custom-form__label, label.octadesk__custom-form__label { }',
      '.octadesk__custom-form__submit, button.octadesk__custom-form__submit { }',
      '.octadesk__custom-form__field, input.octadesk__custom-form__field { }',
      '.has-error .octadesk__custom-form__field, .has-error input.octadesk__custom-form__field { }',
      '.octadesk__custom-form__comment_description { }',
      '.octadesk__custom-form__field#comment_description { }'
    ]
  }

  if (fields) {
    fields.forEach((field: any) => {
      selectedForm.fields.push(
        '.octadesk__custom-form__' + field.fieldId + ' { }'
      )
      selectedForm.fields.push(
        '.octadesk__custom-form__field#' + field.fieldId + ' { }'
      )
    })

    selectedForm.css = selectedForm.fields.join('\n')
  }

  return selectedForm
}

export const createStyleContactForm = async (
  context: ActionContext<any, RootState>,
  formSelected: ContactFormSelected
) => {
  if (!formSelected.id || formSelected.id === '') {
    throw new Error('ticket.ticketTalktous.alerts.noFormError')
  }
  try {
    let data = await formService.getToRender(
      formSelected.id ? formSelected.id : '',
      'helpCenter'
    )

    if (data) {
      data = createStyleContactFormCompleted(data)
      return data
    }
  } catch (err) {
    console.error(err)
    throw new Error('ticket.ticketTalktous.alerts.cssDownloadError')
  }
}
