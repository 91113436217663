import { AxiosInstance } from 'axios'

import { WhatsAppNumberValidationData } from '@/modules/ChatSettings/whatsapp-oficial/numbers/store/interfaces'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

type BusinessAccountDTO = {
  name: string
  id: string
}

type WhatsAppBusinessAccountPhoneNumberDTO = {
  verifiedName: string
  displayPhoneNumber: string
  id: string
}

type WhatsAppBusinessAccountDTO = {
  whatsAppBusinessAccountId: string
  name: string
  phoneNumbers: WhatsAppBusinessAccountPhoneNumberDTO[]
  businessAccount: BusinessAccountDTO
}

type DefaultResponse = { success: boolean }

type RegisterWabaResultDTO = DefaultResponse | WhatsAppBusinessAccountDTO[]

type RegisterWabaArgs = {
  phoneID: string
  wabaID: string
}

export class OctaBspService {
  private static instance?: OctaBspService

  public static getInstance(): OctaBspService {
    if (!this.instance) {
      this.instance = new OctaBspService()
    }

    return this.instance
  }

  private multiChannelClient?: AxiosInstance

  private async getMultiChannelClient(): Promise<AxiosInstance> {
    if (!this.multiChannelClient) {
      this.multiChannelClient = await getClient('multiChannel')
    }

    return this.multiChannelClient
  }

  public async registerWaba(payload: RegisterWabaArgs): Promise<void> {
    const client = await this.getMultiChannelClient()

    try {
      await client.post<RegisterWabaResultDTO>(`/whatsapp/register`, payload, {
        ...legacyHeaders()
      })
    } catch (err) {
      throw new Error((err as any).response.data.message)
    }
  }

  public async migrationSendValidationNumber(
    payload: WhatsAppNumberValidationData
  ): Promise<any> {
    const client = await this.getMultiChannelClient()

    const { wabaID, countryCode, phoneNumber, codeMethod } = payload

    try {
      const { data } = await client.post(
        `/whatsapp/${wabaID}/phone-numbers`,
        {
          countryCode,
          phoneNumber,
          codeMethod,
          language: 'pt_BR'
        },
        {
          ...legacyHeaders()
        }
      )

      return data
    } catch (err) {
      throw new Error((err as any).response.data.message)
    }
  }

  public async migrationResendCode({
    wabaID,
    phoneNumberId,
    codeMethod
  }: {
    wabaID: string
    phoneNumberId: string
    codeMethod: string
  }): Promise<void> {
    const client = await this.getMultiChannelClient()

    try {
      const { data } = await client.post(
        `/whatsapp/${wabaID}/phone-numbers/${phoneNumberId}/request-code`,
        {
          codeMethod,
          language: 'pt_BR'
        },
        {
          ...legacyHeaders()
        }
      )

      return data
    } catch (err) {
      throw new Error((err as any).response.data.message)
    }
  }

  public async migrationCodeConfirmation({
    wabaID,
    phoneNumberId,
    code
  }: {
    wabaID: string
    phoneNumberId: string
    code: string
  }): Promise<void> {
    const client = await this.getMultiChannelClient()

    try {
      const { data } = await client.post(
        `/whatsapp/${wabaID}/phone-numbers/${phoneNumberId}`,
        { code },
        {
          ...legacyHeaders()
        }
      )

      return data
    } catch (err) {
      throw new Error((err as any).response.data.message)
    }
  }
}
