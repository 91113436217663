import { WhatsAppWebService } from '@/modules/ChatSettings/other-channels/whatsapp-web/whatsapp-web.service'

import * as types from './mutations-types'

const whatsAppWebService = WhatsAppWebService.getInstance()

export const openQRCodeWS = (context: any, contact: any) => {
  context.commit(types.WHATSAPP_QRCODE_ERROR, null)

  context.commit(types.WHATSAPP_QRCODE_SUCCESS, null)

  context.commit(types.WHATSAPP_SET_QRCODE, '')

  context.commit(types.WHATSAPP_SET_QRCODE_READING)

  const { userLogged } = context.rootGetters

  whatsAppWebService.openQRCodeWS(contact, context, userLogged.id)

  const { autoRetries, maxRetries } = context.getters

  if (autoRetries >= maxRetries) {
    context.dispatch('resetAutoRetry')
  }
}

export const confirmQRCodeOverrideWS = (
  { commit, dispatch }: any,
  { confirmed }: any
) => {
  commit(types.WHATSAPP_QRCODE_ERROR, null)

  commit(types.WHATSAPP_QRCODE_SUCCESS, null)

  whatsAppWebService.confirmQRCodeOverrideWS({ confirmed })

  if (!confirmed) {
    dispatch('closeQRCodeWS')
  }
}

export const closeQRCodeWS = (context: any) => {
  whatsAppWebService.closeQRCodeWS()

  context.commit(types.WHATSAPP_QRCODE_RESET)
}

export const retryQrCode = async (context: any, contact: any) => {
  await context.dispatch('closeQRCodeWS')

  await context.dispatch('openQRCodeWS', contact)

  await context.dispatch('addAutoRetry')
}

export const addAutoRetry = (context: any) => {
  context.commit(types.ADD_AUTO_RETRY)
}

export const resetAutoRetry = (context: any) => {
  context.commit(types.RESET_AUTO_RETRY)
}

/* As actions com prefixo ws_ são disparados por socket. */

export const ws_error = (context: any, payload: any) => {
  const { autoRetries, maxRetries } = context.getters

  if (payload) {
    const { data, contact } = payload

    if (
      data &&
      data.match(/error during login: login timed out/) &&
      autoRetries < maxRetries
    ) {
      context.dispatch('retryQrCode', contact)
    } else {
      context.commit(types.WHATSAPP_QRCODE_ERROR, data)
    }
  }
}

export const ws_qrcode = (context: any, payload: any) => {
  context.commit(types.WHATSAPP_SET_QRCODE, payload.data)
}

export const ws_logged = (context: any, session: any) => {
  context.commit(types.WHATSAPP_QRCODE_LOGGED, session.data)
}

export const ws_creatingSuccess = (context: any, payload: any) => {
  try {
    const client = JSON.parse(payload.data)

    if (client && client.id) {
      context.dispatch('numberWebConnectInterval', client.id)
    }
  } catch (err) {
    console.error('err ws_creatingSuccess', err)
  }

  context.commit(types.WHATSAPP_QRCODE_SUCCESS, payload.data)
}
