import { MessengerState } from '@/store/interfaces'

export const authorizedFacebookPages = ({
  authorizedFacebookPages
}: MessengerState) => authorizedFacebookPages

export const authorizedInstagramAccounts = ({
  authorizedInstagramAccounts
}: MessengerState) => authorizedInstagramAccounts

export const isAuthorizedPagesModalOpen = ({
  isAuthorizedPagesModalOpen
}: MessengerState) => isAuthorizedPagesModalOpen

export const isRemovePageConfirmationModalOpen = ({
  isRemovePageConfirmationModalOpen
}: MessengerState) => isRemovePageConfirmationModalOpen

export const isInstagramAppSettingsModalOpen = ({
  isInstagramAppSettingsModalOpen
}: MessengerState) => isInstagramAppSettingsModalOpen

export const pageItemToRemove = ({ pageItemToRemove }: MessengerState) =>
  pageItemToRemove

export const facebookPages = ({ facebookPages }: MessengerState) =>
  facebookPages

export const instagramPages = ({ instagramPages }: MessengerState) =>
  instagramPages
