import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

import { DomainType } from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v1/template-messages.enum'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import {
  TemplateMessageFieldType,
  TemplateMessageItem,
  TemplateMessagePayload
} from './store/interfaces'

export class TemplateMessagesService {
  async getChatClient(): Promise<AxiosInstance> {
    return (await services.chat.getClient()) as AxiosInstance
  }

  async getChatAgentsClient(): Promise<AxiosInstance> {
    return (await services.chatAgents.getClient()) as AxiosInstance
  }

  async getChatIntegratorClient(): Promise<AxiosInstance> {
    return (await services.chatIntegrator.getClient()) as AxiosInstance
  }

  async createTemplateMessage(payload: TemplateMessagePayload) {
    await this.getChatClient()
      .then(client => client.post('/quick-reply', payload, legacyHeaders()))
      .catch(err => {
        throw new Error(err.response.data.customCode)
      })
  }

  async retrieveTemplateMessages(payload: any) {
    const { data } = await this.getChatClient().then(client =>
      client.post(
        '/quick-reply/filter',
        {
          ...payload,
          type: 'template',
          fullResponse: true,
          sort: {
            createdAt: -1
          }
        },
        legacyHeaders()
      )
    )

    return data
  }

  async updateTemplateMessage(payload: {
    id: string
    permissions: {
      view: Array<string>
      edit: Array<string>
    }
  }) {
    await this.getChatClient().then(client =>
      client.put(`/quick-reply/${payload.id}`, payload, legacyHeaders())
    )
  }

  async deleteTemplateMessage(payload: TemplateMessageItem) {
    await this.getChatClient().then(client =>
      client.delete(`/quick-reply/${payload.id}`, legacyHeaders())
    )
  }

  async uploadFile(data: any, params = {}) {
    return this.getChatClient().then(client => {
      const options = {
        ...legacyHeaders(),
        progress: data.progress,
        params
      }

      return client
        .post('upload', data.body, options)
        .then(res => res.data)
        .then(data.success)
        .catch(data.error)
    })
  }

  async getChatIntegrators() {
    const integrators = Storage.getItem('chat:integrator')

    if (integrators) {
      return integrators
    }

    const res = await this.getChatIntegratorClient().then(client =>
      client.get('/', legacyHeaders())
    )

    if (res.data) {
      Storage.setItem('chat:integrator', res.data, 3600)
    }

    return res.data
  }

  async getCustomFields() {
    const client = await getClient('customFields')

    const { Person, Chat } = DomainType

    const values = await Promise.all([
      client.get(
        `/system-type/${Person}?showEnabledItems=true`,
        legacyHeaders()
      ),
      client.get(`/system-type/${Chat}?showEnabledItems=true`, legacyHeaders())
    ])

    return values.reduce((fields, current) => fields.concat(current.data), [])
  }

  async createCustomField(field: TemplateMessageFieldType) {
    const response = await getClient('customFields')
      .then(client => client.post('/', field, legacyHeaders()))
      .catch(error => {
        console.error('Error on create custom field', error)
      })

    return (response as any)?.data
  }

  async syncTemplateMessages() {
    await this.getChatClient().then(client =>
      client.post('/quick-reply/sync/meta', undefined, legacyHeaders())
    )
  }
}
