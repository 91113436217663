import { headers, services } from '@octadesk-tech/services'

let _client

const getClient = async () => {
  if (_client) {
    return _client
  }

  _client = await services.whatsApp.getClient(headers.getAuthorizedHeaders())

  return _client
}

const getChatClient = async () => {
  return await services.chat.getClient()
}

export const getWhatsappNumbers = async () => {
  const response = await getClient().then(client =>
    client.get('/numbers', headers.getAuthorizedHeaders())
  )

  return response.data
}

export const getRestrictedWhatsappNumbers = async () => {
  const response = await getChatClient().then(client =>
    client.get('/channel/numbers/permission', headers.getAuthorizedHeaders())
  )

  return response.data
}
