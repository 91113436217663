import { AxiosInstance } from 'axios'

import { AvailableIntegrations } from '@/modules/Commerce/integrations/store/interfaces'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

export class CommerceIntegrationsService {
  getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  private apiName = 'commerceIntegrator'

  async getCommerceIntegrations(): Promise<string | undefined> {
    try {
      const { data } = await this.getClient(this.apiName).then(client =>
        client.get('/configs', legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on get commerce integrations: ' + err)
    }
  }

  async getCommerceAvailableIntegrations(): Promise<
    AvailableIntegrations[] | undefined
  > {
    try {
      const { data } = await this.getClient(this.apiName).then(client =>
        client.get('/integrations', legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on get commerce available integrations: ' + err)
    }
  }

  async setCommerceIntegration(payload: {
    name: string
    settings: Array<{ key: string; value: string }>
  }): Promise<{ id: string; lastSync?: { syncing: boolean } } | undefined> {
    try {
      const { data } = await this.getClient(this.apiName).then(client =>
        client.post('/configs', payload, legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on set commerce integration: ' + err)
    }
  }

  async syncCommerceIntegration({
    integrationId,
    catalogId
  }: {
    integrationId: string
    catalogId: string
  }) {
    try {
      const { data } = await this.getClient(this.apiName).then(client =>
        client.post(
          `/${integrationId}/sync/products/`,
          {
            catalogId
          },
          legacyHeaders()
        )
      )

      return data
    } catch (err) {
      throw new Error((err as any).response.data)
    }
  }

  async deleteCommerceIntegration({
    integrationId
  }: {
    integrationId: string
  }) {
    try {
      const { data } = await this.getClient(this.apiName).then(client =>
        client.delete(`/configs/${integrationId}`, legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on delete commerce integration: ' + err)
    }
  }
}
