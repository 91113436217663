import { ActionContext } from 'vuex'

import { AxiosError } from 'axios'

import {
  FacebookStatus,
  IntegrationStatus,
  SourceTypes
} from '@/modules/Commerce/catalog/catalog.enum'
import {
  CatalogsService,
  formatCatalogProductPrice
} from '@/modules/Commerce/catalog/catalog.service'
import {
  CommerceCatalogProduct,
  CommerceIntegration,
  CommerceProduct,
  CommerceProductIntegration,
  IntegrationResponse
} from '@/modules/Commerce/interfaces'

import { RootState } from '@/store/interfaces'

import { CommerceState, CommerceCatalogInfo } from './interfaces'
import * as types from './mutation-types'

const catalogsService = CatalogsService.getInstance()

// [TEMPORARY]
// this code is temporary until the refactoring of the
// create and update catalog products actions
type IntegrationCodeAndTheme = {
  code: IntegrationStatus
  theme: string
}

function getIntegrationStatusCodeAndTheme(
  status: FacebookStatus
): IntegrationCodeAndTheme {
  const INTEGRATION_CODES = new Map<FacebookStatus, IntegrationCodeAndTheme>([
    [
      FacebookStatus.failed,
      { code: IntegrationStatus.FAILED, theme: 'danger' }
    ],
    [
      FacebookStatus.published,
      { code: IntegrationStatus.PUBLISHED, theme: 'success' }
    ],
    [
      FacebookStatus.waiting,
      { code: IntegrationStatus.WAITING, theme: 'warning' }
    ]
  ])

  return <IntegrationCodeAndTheme>INTEGRATION_CODES.get(status)
}

function formatIntegrationResponse(
  response?: IntegrationResponse
): string | undefined {
  if (!response || !Object.keys(response)?.length) return

  return `${response.key}: ${response.value}`
}

function getFacebookIntegration(
  integrations: CommerceProductIntegration[]
): CommerceIntegration | undefined {
  const facebookIntegration = integrations.find(
    (integration: CommerceProductIntegration) =>
      integration.integrationName === SourceTypes.facebook
  )
  if (!facebookIntegration) return

  const { code, theme } = getIntegrationStatusCodeAndTheme(
    facebookIntegration.integrationStatus
  )

  return {
    id: facebookIntegration.integrationId,
    status: {
      code,
      theme,
      responseMessage: formatIntegrationResponse(facebookIntegration?.response)
    }
  }
}

function commerceProdutToCommerceCatalogProduct(
  product: CommerceProduct
): CommerceCatalogProduct {
  return {
    id: product.id,
    image: product.imageUrl,
    name: product.name,
    price: <string>formatCatalogProductPrice(product.price),
    sku: product.sku,
    source: product.source,
    status: product.active,
    stock: !!product.stock,
    offerPrice: formatCatalogProductPrice(product?.offerPrice),
    facebookIntegration: getFacebookIntegration(product?.integrations)
  }
}
// [TEMPORARY]

export const setInitialDataFetched = (
  context: ActionContext<CommerceState, RootState>,
  isFetched: boolean
) => {
  context.commit(types.SET_COMMERCE_INITIAL_DATA_FETCHED, isFetched)
}

export const deleteCommerceCatalog = async (
  context: ActionContext<CommerceState, RootState>
) => {
  const { commerceCatalog } = context.getters

  const commerceIntegrationsItems =
    context.rootGetters['commerceIntegrations/commerceIntegrationsItems']

  if (commerceIntegrationsItems.length) {
    const integrationsIds = commerceIntegrationsItems.map(
      (integration: { id: string }) => integration.id
    )

    for await (const integrationId of integrationsIds) {
      await context.dispatch(
        'commerceIntegrations/deleteCommerceIntegration',
        integrationId,
        { root: true }
      )
    }
  }

  try {
    await catalogsService.deleteCommerceCatalog(commerceCatalog.id)

    context.commit(types.DELETE_COMMERCE_CATALOG)
  } catch {
    throw new Error('Error on delete commerce catalog')
  }
}

export const getCommerceCatalog = async (
  context: ActionContext<CommerceState, RootState>
) => {
  try {
    const catalog = await catalogsService.getCommerceCatalog()

    context.dispatch('setCommerceCatalogInfo', catalog)
  } catch (error) {
    console.error(
      'commerce_catalog_getCommerceCatalog_error',
      (error as AxiosError)?.response?.data
    )
  }
}

export const getCommerceCatalogProducts = async (
  context: ActionContext<CommerceState, RootState>,
  nextProductsPage?: string
) => {
  context.dispatch('setCommerceCatalogProductsLoader', true)

  if (!nextProductsPage) context.commit(types.RESET_COMMERCE_CATALOG_PRODUCTS)

  const { commerceCatalog } = context.getters

  const { results, nextPage } =
    await catalogsService.getCommerceCatalogProducts(
      commerceCatalog.id,
      nextProductsPage
    )

  context.dispatch('setCommerceCatalogProductsLoader', false)

  context.commit(types.SET_COMMERCE_CATALOG_PRODUCTS, results)

  context.commit(types.SET_COMMERCE_CATALOG_NEXT_PRODUCTS_PAGE, nextPage)
}

export const setCommerceCatalogProductsLoader = async (
  context: ActionContext<CommerceState, RootState>,
  isLoader: boolean
) => {
  context.commit(types.SET_COMMERCE_CATALOG_PRODUCTS_LOADER, isLoader)
}

export const setCommerceCatalogInfo = (
  context: ActionContext<CommerceState, RootState>,
  catalogInfo: CommerceCatalogInfo
) => {
  context.commit(types.SET_COMMERCE_CATALOG_INFO, catalogInfo)
}

export const setCommerceCatalogProduct = (
  context: ActionContext<CommerceState, RootState>,
  product: CommerceProduct
) => {
  context.commit(types.SET_COMMERCE_CATALOG_PRODUCTS, [
    commerceProdutToCommerceCatalogProduct(product)
  ])
}

export const updateCommerceCatalogProduct = (
  context: ActionContext<CommerceState, RootState>,
  product: CommerceProduct
) => {
  const { commerceCatalog } = context.getters
  const products = commerceCatalog?.products ?? []

  const updatedProducts = products.map(
    (catalogProduct: CommerceCatalogProduct) => {
      if (product.id === catalogProduct.id) {
        return {
          ...catalogProduct,
          ...commerceProdutToCommerceCatalogProduct(product)
        }
      }

      return catalogProduct
    }
  )

  context.commit(types.UPDATE_COMMERCE_CATALOG_PRODUCTS, updatedProducts)
}
