import all from './all'
import attributionByAgent from './attribution-by-agent'
import byAgent from './by-agent'
import byGroup from './by-group'
import chatbot from './chatbot'
import group from './group'
import mentioned from './mentioned'
import participation from './participation'
import timeExceeded from './time-exceeded'
import unread from './unread-answered'
import withoutResponsible from './without-responsible'

export default {
  all,
  byAgent,
  byGroup,
  mentioned,
  participation,
  withoutResponsible,
  chatbot,
  unread,
  timeExceeded,
  group,
  attributionByAgent
}
