import {
  OldWidgetSettings,
  OldWidgetState
} from '@/modules/ChatSettings/other-channels/chat/types'

import * as types from './mutations-types'

export default {
  [types.SET_SETTINGS](state: OldWidgetState, settings: OldWidgetSettings) {
    state.settings = settings
  },
  [types.SET_UNAVAILABLE_MESSAGE](state: OldWidgetState, value: string) {
    state.settings.messages.unavailable = value
  },
  [types.SET_TITLE](state: OldWidgetState, value: string) {
    state.settings.title = value
  },
  [types.SET_INTRODUCTION](state: OldWidgetState, value: string) {
    state.settings.introduction = value
  },
  [types.SET_UNAVAILABLE_MESSAGE](state: OldWidgetState, value: string) {
    state.settings.messages.unavailable = value
  },
  [types.SHOW_SEND_INSTRUCTIONS_MODAL](state: OldWidgetState) {
    state.enableSendInstructionsModal = true
  },
  [types.HIDE_SEND_INSTRUCTIONS_MODAL](state: OldWidgetState) {
    state.enableSendInstructionsModal = false
  },
  [types.SET_EMBED_CODE](state: OldWidgetState, value: string) {
    state.settings.embedCode = value
  },
  [types.INSTRUCTIONS_SENT](state: OldWidgetState, value: boolean) {
    state.instructionsSent = value
  },
  [types.SET_POSITION](state: OldWidgetState, value: string) {
    state.settings.position = value
  },
  [types.SET_FONT_COLOR_RGBA](state: OldWidgetState, value: string) {
    const fontArray = value.split(',')

    const colorNumbers = fontArray.map((value: string) => Number(value))

    state.settings.fontColorRGBA = value

    state.settings.fontColor.r = colorNumbers[0]

    state.settings.fontColor.g = colorNumbers[1]

    state.settings.fontColor.b = colorNumbers[2]

    state.settings.fontColor.a = colorNumbers[3]
  },
  [types.SET_BG_COLOR_RGBA](state: OldWidgetState, value: string) {
    const bgArray = value.split(',')

    const colorNumbers = bgArray.map((value: string) => Number(value))

    state.settings.bgColorRGBA = value

    state.settings.bgColor.r = colorNumbers[0]

    state.settings.bgColor.g = colorNumbers[1]

    state.settings.bgColor.b = colorNumbers[2]

    state.settings.bgColor.a = colorNumbers[3]
  },
  [types.SET_LIGHTNESS](state: OldWidgetState, value: number) {
    state.settings.bgColor.lightness = value
  },
  [types.SET_EMBED_CODE](state: OldWidgetState, value: string) {
    state.settings.embedCode = value
  },
  [types.SET_SHOW_AGENTS](state: OldWidgetState, value: boolean) {
    state.settings.showAgentsThumb = value
  }
}
