import i18n from '@/common/i18n'

import { getClient } from './http'
import legacyHeaders from './legacy-headers'

const API_NAME = 'whatsapp'

export default class WhatsappServices {
  getClient() {
    return getClient(API_NAME)
  }

  getWhatsappNumbers() {
    return this.getClient()
      .then(client => client.get('/numbers', legacyHeaders()))
      .then(res => res.data)
  }

  translateName = (name = '') => {
    if (!name.startsWith('untitled')) {
      return name
    } else {
      const splittedName = name.split('-')

      return [
        i18n?.t(`listNumbers.webList.label.${splittedName[0]}`),
        splittedName[1]
      ].join(' ')
    }
  }
}
