import { ActionContext } from 'vuex'

import { ChatPreferencesService } from '@/modules/ChatSettings/general/chat-preferences/chat-preferences.service'

import { RootState } from '@/store/interfaces'

import { ChatPreferencesState } from './interfaces'
import * as types from './mutations-types'

const chatPreferencesService = new ChatPreferencesService()

export const fetchSettings = async (
  context: ActionContext<ChatPreferencesState, RootState>
) => {
  const settings = await chatPreferencesService.getSettings()

  context.commit(types.SET_SETTINGS, settings.conversation)
}

export const setAutomaticOpenTicket = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_AUTOMATIC_OPEN_TICKET, value)
}

export const setCanShare = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_CAN_SHARE, value)
}

export const setMaxServicesEnabled = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_MAX_SERVICES_ENABLED, value)
}

export const setMaxServicesLimit = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: number
) => {
  context.commit(types.SET_MAX_SERVICES_LIMIT, value)
}

export const setRoulette = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_ROULETTE, value)
}

export const setAgentNotificationEnabled = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_AGENT_NOTIFICATION_ENABLED, value)
}

export const setAgentNotificationOption = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: string
) => {
  context.commit(types.SET_AGENT_NOTIFICATION_OPTION, value)
}

export const setAgentNotificationDelay = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: number
) => {
  context.commit(types.SET_AGENT_NOTIFICATION_DELAY, value)
}

export const setClientCanClose = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_CLIENT_CAN_CLOSE, value)
}

export const setInactivityTimeoutEnabled = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_INACTIVITY_TIMEOUT_ENABLED, value)
}

export const setInactivityTimeoutDelay = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: number
) => {
  context.commit(types.SET_INACTIVITY_TIMEOUT_DELAY, value)
}

export const setSendChatToClient = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_SEND_CHAT_TO_CLIENT, value)
}

export const setShowAgentNameInWhatsappMessage = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_SHOW_AGENT_NAME_IN_MESSAGE, value)
}

export const setAutomaticSetReponsible = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_AUTOMATIC_RESPONSIBLE, value)
}

export const setAgentsServiceTimeoutEnabled = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => context.commit(types.SET_AGENTS_SERVICE_TIMEOUT_ENABLED, value)

export const setAgentsServiceTimeoutDelay = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: number
) => context.commit(types.SET_AGENTS_SERVICE_TIMEOUT_DELAY, value)

export const setClientsServiceTimeoutEnabled = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: boolean
) => context.commit(types.SET_CLIENTS_SERVICE_TIMEOUT_ENABLED, value)

export const setClientsServiceTimeoutDelay = (
  context: ActionContext<ChatPreferencesState, RootState>,
  value: number
) => context.commit(types.SET_CLIENTS_SERVICE_TIMEOUT_DELAY, value)

export const saveSettings = async (
  context: ActionContext<ChatPreferencesState, RootState>
) => {
  const { settings } = context.getters

  const resSettings = await chatPreferencesService.saveSettings(settings)

  context.commit(types.SET_SETTINGS, resSettings.data.conversation)

  return resSettings.data
}
