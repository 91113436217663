import {
  TemplateMessagesState,
  TemplateMessageModel,
  TemplateMessagePayload,
  TemplateMessageNumber,
  TemplateMessageItem,
  TemplateMessageSelectedMessage,
  TemplateMessageConfirm,
  TemplateMessageFieldType
} from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_CREATE_MESSAGE_MODAL_IS_OPEN](
    state: TemplateMessagesState,
    value: boolean
  ) {
    state.createMessageModalIsOpen = value
  },
  [types.SET_CREATE_MESSAGE_STEP](state: TemplateMessagesState, value: number) {
    state.createMessageStep = value
  },
  [types.SET_CREATE_MESSAGE_MODEL](
    state: TemplateMessagesState,
    value: TemplateMessageModel
  ) {
    state.createMessageModel = value
  },
  [types.SET_CREATE_MESSAGE_PAYLOAD](
    state: TemplateMessagesState,
    value: TemplateMessagePayload
  ) {
    state.createMessagePayload = value
  },
  [types.SET_CREATE_MESSAGE_ERROR](state: TemplateMessagesState, value: any) {
    state.createMessageError = value
  },
  [types.SET_CREATE_MESSAGE_NUMBER](
    state: TemplateMessagesState,
    value: TemplateMessageNumber
  ) {
    state.createMessageNumber = value
  },
  [types.SET_CREATE_MESSAGE_SUCCESS](state: TemplateMessagesState, value: any) {
    state.createMessageSuccess = value
  },
  [types.SET_CREATE_MESSAGE_CONFIRM](
    state: TemplateMessagesState,
    value: TemplateMessageConfirm
  ) {
    state.createMessageConfirm = value
  },
  [types.SET_SELECTED_MESSAGE](
    state: TemplateMessagesState,
    value: TemplateMessageSelectedMessage
  ) {
    state.selectedMessage = value
  },
  [types.SET_TEMPLATE_MESSAGES](
    state: TemplateMessagesState,
    value: Array<TemplateMessageItem>
  ) {
    state.templateMessages = value
  },
  [types.SET_TEMPLATE_MESSAGES_IS_LOADED](
    state: TemplateMessagesState,
    value: boolean
  ) {
    state.templateMessagesIsLoaded = value
  },
  [types.SET_PERSON_FIELDS](
    state: TemplateMessagesState,
    fields: Array<TemplateMessageFieldType>
  ) {
    state.personFields = fields
  },
  [types.SET_CHAT_FIELDS](
    state: TemplateMessagesState,
    fields: Array<TemplateMessageFieldType>
  ) {
    state.chatFields = fields
  },
  [types.SET_CREATE_LOADER](state: TemplateMessagesState, isLoader: boolean) {
    state.createCustomFieldLoaderActive = isLoader
  }
}
