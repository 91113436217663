import financialManagement from './financial-management/routes'
import generalRoutes from './general/routes'
import otherChannelsRoutes from './other-channels/routes'
import whatsappOficialRoutes from './whatsapp-oficial/routes'

export default {
  path: '/chat-settings',
  name: 'chat-settings',
  component: () => import('@/views/ChatSettings.vue'),
  children: [
    otherChannelsRoutes,
    whatsappOficialRoutes,
    generalRoutes,
    financialManagement,
    {
      path: 'groups',
      name: 'chat-settings-groups',
      redirect: '/chat-settings/groups/list',
      component: () => import('./groups/components/GroupsContainer.vue'),
      children: [
        {
          path: 'list',
          name: 'chat-settings-groups-list',
          component: () =>
            import('./groups/components/GroupList/GroupListContainer.vue')
        },
        {
          path: 'edit/:id',
          name: 'chat-settings-groups-edit',
          component: () =>
            import('./groups/components/GroupEdit/GroupEditContainer.vue')
        },
        {
          path: 'create',
          name: 'chat-settings-groups-create',
          component: () =>
            import('./groups/components/GroupEdit/GroupEditContainer.vue')
        }
      ]
    }
  ]
}
