import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import legacyHeaders from '@/common/services/legacy-headers'

import { WozPreferences, WozPreferencesPayload } from './store/interfaces'

export class WozPreferencesService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.chat.getClient()) as AxiosInstance
  }

  async fetchPreferences(): Promise<WozPreferences> {
    return this.getClient()
      .then(client => client.get('configs', legacyHeaders()))
      .then(res => res.data.woz)
  }

  async savePreferences(preferences: WozPreferencesPayload) {
    return this.getClient().then(client =>
      client.put('configs', { woz: preferences }, legacyHeaders())
    )
  }
}
