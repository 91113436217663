export const channels = {
  instagram: 'instagram',
  facebookMessenger: 'facebook-messenger',
  whatsapp: 'whatsapp',
  web: 'web',
  email: 'email',
  widget: 'widget'
}

export const channelsNames = {
  instagram: 'Instagram',
  'facebook-messenger': 'Messenger',
  whatsapp: 'WhatsApp',
  web: 'Chat Web',
  email: 'Email'
}

export const channelIcons = {
  whatsapp: 'ChannelWhatsapp',
  'official-whatsapp': 'OfficialChannelWhatsapp',
  web: 'ChannelOctachat',
  'facebook-messenger': 'ChannelMessenger',
  instagram: 'ChannelInstagram',
  email: 'ChannelEmail'
}
