import Vuex from 'vuex'

import Vue from 'vue'

import modules from './modules'

Vue.use(Vuex)

const store = {
  modules
}

const vuex = new Vuex.Store(store)

export default vuex
export const useStore = () => vuex
