import { User, SignUpState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_CURRENT_STEP](state: SignUpState, currentStep: number) {
    state.currentStep = currentStep
  },
  [types.SET_USER](state: SignUpState, user: User) {
    state.user = user
  },
  [types.SET_TENANT](state: SignUpState, tenant: string) {
    state.user!.tenant = tenant
  },
  [types.SET_USERNAME](state: SignUpState, username: string) {
    state.user!.name = username
  },
  [types.SET_COMPANY_SIZE](state: SignUpState, companySize: string) {
    state.user!.companySize = companySize
  },
  [types.SET_PHONE](state: SignUpState, phone: string) {
    state.user!.phone = phone
  },
  [types.SET_FORMATTED_PHONE](state: SignUpState, formattedPhone: string) {
    state.user!.formattedPhone = formattedPhone
  },
  [types.SET_VARIANT](state: SignUpState, variant: string) {
    state.variant = variant
  },
  [types.SET_COMPANY_SITE](state: SignUpState, site: string) {
    state.user!.site = site
  }
}
