import OctaAnalyticsClient from '@octadesk-tech/analytics-client'

import { EVENTS } from '@/common/helpers/enums/events.enum'
import { LOCATION_TYPES } from '@/common/helpers/enums/location-types'
import { PRODUCT_TARGETS } from '@/common/helpers/enums/product-targets.enum'

export const segmentTracking = async () => {
  await OctaAnalyticsClient.SegmentIntegration.track(EVENTS.USER_ADD_PAGEVIEW, {
    target: PRODUCT_TARGETS.NEW_PRODUCT,
    location: LOCATION_TYPES.CONFIG
  })
}
