export const SET_FLUX = 'SET_FLUX'

export const SET_BOT_ID = 'SET_BOT_ID'

export const SET_EXITING_CONFIGURATION = 'SET_EXITING_CONFIGURATION'

export const SET_CURRENT_CHATBOT_FLUX = 'SET_CURRENT_CHATBOT_FLUX'

export const UPSERT_CHATBOT_FLUX = 'UPSERT_CHATBOT_FLUX'

export const UPSERT_CHATBOT = 'UPSERT_CHATBOT'

export const SET_CURRENT_CHATBOT = 'SET_CURRENT_CHATBOT'

export const SET_INVALID_CONFIGURATION = 'SET_INVALID_CONFIGURATION'

export const REMOVE_INVALID_CONFIGURATION = 'REMOVE_INVALID_CONFIGURATION'

export const CLEAR_INVALID_CONFIGURATION = 'CLEAR_INVALID_CONFIGURATION'

export const SET_CURRENT_CONDITIONS_VIEW = 'SET_CURRENT_CONDITIONS_VIEW'

export const REMOVE_PAGE_CONDITION = 'REMOVE_PAGE_CONDITION'

export const ADD_PAGE_CONDITION = 'ADD_PAGE_CONDITION'

export const SET_BOT_UNSAVED_CHANGES = 'SET_BOT_UNSAVED_CHANGES'

export const SET_SHOW_EXIT_WARNING = 'SET_SHOW_EXIT_WARNING'

export const SET_GROUPS_WITHOUT_CONNECTION = 'SET_GROUPS_WITHOUT_CONNECTION'

export const SET_EMPTY_FIELDS = 'SET_EMPTY_FIELDS'

export const SET_CURRENT_CHATBOT_FLUX_CHANNEL =
  'SET_CURRENT_CHATBOT_FLUX_CHANNEL'

export const SET_ACTIVATION_CHATBOT = 'SET_ACTIVATION_CHATBOT'

export const SET_CURRENT_CHATBOT_PERSONA = 'SET_CURRENT_CHATBOT_PERSONA'

export const CLOSE_EDITING_PERSONA = 'CLOSE_EDITING_PERSONA'

export const TOGGLE_EDITING_PERSONA = 'TOGGLE_EDITING_PERSONA'
