import * as types from '@/modules/Chat/components/conversation/store/mutations-types/commerce-orders'

export default {
  [types.SET_COMMERCE_CLIENT_ORDERS_LOADER](state, isLoader) {
    state.commerce.orders.loader = isLoader
  },
  [types.SET_COMMERCE_CLIENT_ORDER_PRODUCTS_LOADER](state, isLoader) {
    state.commerce.orders.productsLoader = isLoader
  },
  [types.SET_COMMERCE_CLIENT_ORDERS](state, orders) {
    state.commerce.orders.items = orders
  },
  [types.SET_COMMERCE_CLIENT_ORDERS_NOT_FOUND](state, isNotFound) {
    state.commerce.orders.clientNotFound = isNotFound
  },
  [types.SET_COMMERCE_CLIENT_ORDER_PAYMENT_URL](state, paymentUrl) {
    state.commerce.orders.paymentUrl = paymentUrl
  }
}
