export type WhatsAppOfficialState = {
  balancies: Balancies
  lastCheck: Date | undefined
  isLoading: boolean
  hasError: boolean
}

export type Balancies = {
  whatsapp: number
  general: number
}

export default {
  balancies: { whatsapp: 0, general: 0 },
  lastCheck: undefined,
  isLoading: true,
  hasError: false
} as WhatsAppOfficialState
