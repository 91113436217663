import { FEATURE_CODE } from '@/common/helpers/enums/feature-code'
import { RoleTypes } from '@/common/helpers/enums/role-types'

import { FeatureToggleState } from '@/store/interfaces'

export const getFeature =
  (state: FeatureToggleState) => (featureCode: FEATURE_CODE) => {
    const { featureFlags } = state

    if (!featureFlags) {
      return undefined
    } else {
      return featureFlags[featureCode]
    }
  }

export const isFeatureActive =
  (_state: FeatureToggleState, getters: any) => (featureCode: FEATURE_CODE) => {
    const feature = getters.getFeature(featureCode)
    return feature?.active
  }

export const hasNewChatEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.OPEN_NEW_CHAT)

export const isOctaLogoEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.OCTA_LOGO)

export const isApiKeyManagementEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.API_KEY_MANAGEMENT)

export const isUserManagementEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.USER_MANAGEMENT)

export const isAvailableWhatsAppWeb = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.WHATSAPP)

export const isNewBotBuilderEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.USE_NEW_BOT_BUILDER)

export const isOldBotBuilderEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.USE_OLD_BOT_BUILDER)

export const isCommerceEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.COMMERCE_ENABLED)

export const isConditionalWebhookFeature =
  (_state: FeatureToggleState, getters: any) => (feature: FEATURE_CODE) =>
    getters.isFeatureActive(feature)

export const isInstagramFeatureActive = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.INSTAGRAM)

export const isInboxBotDisabled =
  (_state: FeatureToggleState, getters: any) => (userLoggedRole: number) => {
    const isActive = getters.isFeatureActive(FEATURE_CODE.INBOX_BOT_DISABLED)
    const isAgent = userLoggedRole === RoleTypes.Agent

    return isActive && isAgent
  }

export const isMetaAdsEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.META_ADS_ENABLED)

export const isNewBilling = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.NEW_BILLING)

export const isWozTranscript = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.WOZ_TRANSCRIPTION)

export const isResponsibleContactEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.RESPONSIBLE_CONTACT_ENABLED)

export const isResponseServiceTimeoutEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.RESPONSIBLE_SERVICE_TIMEOUT)

export const isRouletteTicketEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.ROULETTE_TICKET)

export const isRouletteTicketToggleEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.ROULETTE_TICKET_TOGGLE)

export const isSocialMinerBspOnboarding = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.BSP_SOCIALMINER_ONBOARDING)

export const isTemplateMessageVip = (
  _state: FeatureToggleState,
  getters: any
) =>
  getters.isFeatureActive(FEATURE_CODE.WHATSAPP_API_PKG_MSG_OCTA_VIP_SINGLE_250)

export const isTicketEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.NEW_OCTA_TICKET)

export const isWhatsAppApiEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.WHATSAPP_API)

export const isWhatsappBusinessApiEnabled = (
  _state: FeatureToggleState,
  getters: any
) => {
  const { companyModel } = getters

  return (
    companyModel?.company?.features['whatsappBusinessApi'] ||
    getters.isFeatureActive(FEATURE_CODE.WHATSAPP_API)
  )
}

export const isNewWidget = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.NEW_WIDGET)

export const isMessengerEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.MESSENGER)

export const isChatbotEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.CHATBOT)

export const isNewNfboxEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.NEW_OCTA_NFBOX)

export const isChatbotEmailEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.CHATBOT_EMAIL)

export const isEventHandlerEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.EVENT_HANDLER_ENABLED)

export const isTemplateMessageV2Enabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.TEMPLATE_MESSAGE_V2)

export const isBulkMessagesV2Enabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.BULK_MESSAGES_V2)

export const iNewOctaMigratedEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.NEW_OCTA_MIGRATED)

export const isExternalUserEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.EXTERNAL_USER)

export const isNewChannelsNotificationsEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.NEW_CHANNELS_NOTIFICATIONS)

export const isProactiveEmailEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.PRO_ACTIVE_EMAIL)

export const isAskMeEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.ASK_ME)

export const isChatReminderEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.CHAT_REMINDER)

export const isWozEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.CHAT_AI)

export const isWozInBot = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.WOZ_IN_BOT)

export const isOctaStoreEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.OCTA_STORE)

export const isWozTabDefaultEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.CHAT_AI_DEFAULT_TAB)

export const isCreateTrayOrderEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.TRAY_ORDER)

export const isCreateNuvemShopOrderEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.NUVEM_SHOP_ORDER)

export const isHomeEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.NEW_OCTA_HOME)

export const isCreditManagementActive = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.CREDIT_MANAGEMENT_ENABLED)

export const isCommerceAgentCreateCartEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.COMMERCE_AGENT_CREATE_CART_ENABLED)

export const hasNewAreaNotificationsEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.NOTIFICATIONS)

export const hasGroupsInbox = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.GROUPS_INBOX)

export const isBannerImagesEnabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.BANNER_IMAGES_ENABLED)

export const isChatNewConversationV2Enabled = (
  _state: FeatureToggleState,
  getters: any
) => getters.isFeatureActive(FEATURE_CODE.CHAT_NEW_CONVERSATION_V2_ENABLED)

export const isWozSummaryEnabled = (_state: FeatureToggleState, getters: any) =>
  getters.isFeatureActive(FEATURE_CODE.WOZ_SUMMARY_ENABLED)
