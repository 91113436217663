const prefix: 'qa' | 'prd' | 'dev' | '' =
  import.meta.env?.MODE !== 'production'
    ? import.meta.env?.VITE_ENVIRONMENT_PREFIX
    : ''

interface Config {
  apis: {
    viaCep: string
  }
  prefix: 'qa' | 'prd' | 'dev' | ''
  baseIframeHost: string
  botBuilderBasePath: string
  knowledgeBase: string
  statusPage: string
  newsPage: string
  notion: {
    NPSFeedbackId: string
  }
  helpdesk: string
  firebase: Record<string, string>
}

const config: Config = {
  apis: {
    viaCep: 'https://viacep.com.br/ws/'
  },
  prefix,
  baseIframeHost:
    import.meta.env.VITE_BASE_IFRAME_HOST || window.location.origin,
  knowledgeBase:
    import.meta.env.VITE_KNOWLEDGEBASE || 'https://help.octadesk.com/kb/',
  statusPage: 'https://status.octadesk.com',
  newsPage: 'https://novidades.octadesk.com/',
  notion: {
    NPSFeedbackId:
      import.meta.env.VITE_NOTION_NPS_FEEDBACK_ID ||
      'b29aaeaa880c4c62b48ee50d9637d28c'
  },
  helpdesk:
    import.meta.env.VITE_LINK_HELPDESK || 'https://help.octadesk.com/kb/',
  botBuilderBasePath: import.meta.env.VITE_BOT_BUILDER_BASE_PATH?.length
    ? import.meta.env.VITE_BOT_BUILDER_BASE_PATH.trim()
    : 'embed/builder',
  firebase: {
    apiKey: 'AIzaSyCPFm6D1DLnjFKi2d1NpADvktd6W7_OzN0',
    authDomain: 'cross-tenant.firebaseapp.com',
    databaseURL: 'https://cross-tenant.firebaseio.com',
    projectId: 'cross-tenant',
    storageBucket: 'cross-tenant.appspot.com',
    messagingSenderId: '957195134793'
  }
}
export default config
