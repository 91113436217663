import { enums } from '@/modules/Chat/components/sidebar/services/enums'

export default id => ({
  filter: {
    'agent._id': id,
    status: enums.chatStatus.talking,
    lastMessageUserType: {
      $in: [
        enums.userTypes.system,
        enums.userTypes.customer,
        enums.userTypes.none
      ]
    }
  },
  remove: [
    'messages.mentions._id',
    'users._id',
    'channel',
    'status',
    '$or',
    'flux.botId',
    'botInfo.hasStarted',
    'lastMessageTimeExceeded',
    'group._id'
  ]
})
