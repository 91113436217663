import { ActionContext } from 'vuex'

import CustomFieldService from '@/common/services/custom-fields'

import { RootState } from '@/store/interfaces'

import { CustomFieldState } from './interfaces'
import * as types from './mutation-types'

export const fetchCustomFields = async (
  context: ActionContext<CustomFieldState, RootState>
) => {
  const customFieldService = new CustomFieldService()

  const customFields = await customFieldService.getCustomFields()

  context.commit(types.SET_CUSTOM_FIELDS, customFields)
}

export const fetchOrganizationCustomFields = async (
  context: ActionContext<CustomFieldState, RootState>
) => {
  const customFieldService = new CustomFieldService()

  const organizationCustomFields =
    await customFieldService.getOrganizationCustomFields()

  context.commit(types.SET_ORGANIZATION_CUSTOM_FIELDS, organizationCustomFields)
}
