import { NotAllowedError } from '@/modules/Bot/helpers/errors'
import types from '@/modules/Bot/store/mutations-types'
import {
  ClientBotAction,
  ClientBotConnection,
  QuickReplyTypes
} from '@/modules/Bot/store/types'

const connectionMutations = {
  [types.ADD_CONNECTION](state, connection) {
    if (connection) {
      const botConnection =
        connection instanceof ClientBotConnection
          ? connection
          : new ClientBotConnection(connection)
      state.connections = [...state.connections, botConnection]
    }
  },
  [types.SET_CONNECTIONS](state, connections) {
    if (connections && connections.length) {
      const botConnections = connections.map(
        connection => new ClientBotConnection(connection)
      )

      state.connections = [...botConnections]
    } else {
      state.connections = []
    }
  },
  [types.UPDATE_CONNECTION](state, connection) {
    const existentConnectionIndex = state.connections.findIndex(
      conn => conn.id === connection.id
    )

    if (existentConnectionIndex >= 0) {
      state.connections.splice(existentConnectionIndex, 1, connection)
    }
  },
  [types.REMOVE_CONNECTION](state, connection) {
    const existentConnectionIndex = state.connections.findIndex(
      conn => conn.id === connection.id
    )

    if (existentConnectionIndex >= 0) {
      state.connections.splice(existentConnectionIndex, 1)
    }
  }
}

const actionMutations = {
  [types.ADD_ACTION](state, action) {
    if (action) {
      const botAction = new ClientBotAction(action)
      state.actions = [...state.actions, botAction]
    }
  },
  [types.UPDATE_ACTION](state, action) {
    const index = state.actions.findIndex(act => act.id === action.id)

    if (index >= 0) {
      state.actions.splice(index, 1, action)
    }
  },
  [types.REMOVE_ACTION](state, action) {
    const existsConnectionToAction = state.connections.filter(
      connection =>
        connection.nextActionId === action.id ||
        connection.parentActionId === action.id
    )

    if (existsConnectionToAction.length) {
      throw new NotAllowedError(
        `Action is connected to ${existsConnectionToAction.length} connection(s)`
      )
    }

    const index = state.actions.findIndex(act => act.id === action.id)

    if (index >= 0) {
      state.actions.splice(index, 1)
    }
  },
  [types.SET_ACTIONS](state, actions) {
    if (actions && actions.length) {
      const botActions = actions.map(action => {
        const botAction = new ClientBotAction(action)
        botAction.setDirty()
        return botAction
      })

      state.actions = [...botActions]
    } else {
      state.actions = []
    }
  },
  [types.SET_EDITING_ACTION](state, editingAction) {
    state.currentEditingAction = editingAction
  },
  [types.SET_BRANCH_WARNING_MODAL](state, payload) {
    state.branchWarningModal = payload
  },
  [types.REMOVE_EDITING_ACTION](state) {
    if (state.currentEditingAction && state.currentEditingAction.setDirty) {
      state.currentEditingAction.setDirty()
    }

    state.currentEditingAction = null
  },
  [types.SET_SELECTED_ACTION_BUTTON](state, { actionId, button }) {
    const currentActionIndex = state.actions.findIndex(
      action => action.id === actionId
    )

    if (currentActionIndex >= 0) {
      const currentAction = state.actions[currentActionIndex]

      let buttons

      if (currentAction?.content?.buttons?.length) {
        buttons = currentAction.content.buttons
      } else if (
        currentAction?.content?.subType === QuickReplyTypes.InteractiveList &&
        currentAction?.content.list?.sections[0]?.options?.length
      ) {
        buttons = currentAction.content.list.sections[0].options
      }

      buttons.forEach(btn => {
        btn.selected = false
      })

      const selectedButton = buttons.find(h => h.label === button.label)

      if (selectedButton) {
        selectedButton.selected = true
      }

      state.actions.splice(currentActionIndex, 1, currentAction)
    }
  },
  [types.SET_PROACTIVE_MESSAGE](state, proActiveMessage) {
    state.proActiveMessage = proActiveMessage
  },
  [types.UPDATE_ACTION_CONTENT_PROPERTY](state, { action, property }) {
    const index = state.actions.findIndex(act => act.id === action.id)

    const currentAction = state.actions[index]

    if (!currentAction.content) {
      currentAction.content = {}
    }

    currentAction.content = {
      ...currentAction.content,
      property
    }
  },
  [types.UPDATE_ACTION_CONTENT_MEDIAS](state, { action, medias }) {
    const index = state.actions.findIndex(act => act.id === action.id)

    const currentAction = state.actions[index]

    if (!currentAction.content) {
      currentAction.content = {}
    }

    currentAction.content = {
      ...currentAction.content,
      medias
    }
  }
}

const personaMutations = {
  [types.TOGGLE_EDITING_PERSONA](state) {
    state.editingPersona = !state.editingPersona
  },
  [types.CLOSE_EDITING_PERSONA](state) {
    state.editingPersona = false
  },
  [types.SET_PERSONA](state, persona) {
    state.persona = persona || undefined
  }
}

const chatBotMutations = {
  [types.ADD_CHAT_BOT](state, chatBot) {
    state.chatBots.push(chatBot)
  }
}

export default {
  ...actionMutations,
  ...connectionMutations,
  ...personaMutations,
  ...chatBotMutations,
  [types.SET_BOT_UNSAVED_CHANGES](state, hasChanges) {
    state.hasBotUnsavedChanges = hasChanges
  },
  [types.SET_EXITING_CONFIGURATION](state, payload) {
    state.isExitingConfiguration = payload.state
    state.isExternalExiting = payload.external || false
  },
  [types.SET_SIDEBAR_EL_FOCUS](state, ref) {
    state.sidebarFocusEl = ref
  },
  [types.SET_BOT_OFFICE_HOURS](state, payload) {
    const { show, id, actionId, modal } = payload

    state.botOfficeHours = {
      show,
      id,
      actionId,
      modal
    }
  },
  [types.SET_CALENDAR_ID_IN_ACTION](state, payload) {
    const { actionId, calendarId } = payload

    const findActionIndex = state.actions.findIndex(
      action => action.id === actionId
    )

    if (findActionIndex >= 0) {
      state.actions[findActionIndex].content.calendarId = calendarId

      state.botOfficeHours = {
        show: false,
        id: '',
        actionId: undefined
      }
    }
  },
  [types.SET_INVALID_CONFIGURATION](state, payload) {
    state.invalidConfigurations.push({
      ...payload
    })
  },
  [types.REMOVE_INVALID_CONFIGURATION](state, configurationId) {
    state.invalidConfigurations = state.invalidConfigurations.filter(
      config => config.id !== configurationId
    )
  },
  [types.CLEAR_INVALID_CONFIGURATION](state) {
    state.invalidConfigurations = []
  },
  [types.ACTIONS_INVALID](state, payload) {
    state.actionsInvalid = payload
  }
}
