import { WhatsappNumbers } from '@/common/helpers/interfaces/whatsapp-numbers'
import { handleParsePhoneNumber } from '@/common/helpers/utils/parsePhoneNumber'

import WhatsappServices from '@/common/services/whatsapp-services'

import * as types from './mutations-types'

export default {
  [types.SET_WHATSAPP_NUMBERS](state: any, numbers: Array<WhatsappNumbers>) {
    const whatsapp = new WhatsappServices()
    state.whatsappNumbers = numbers.map((n, index) => ({
      ...n,
      index,
      valid: true,
      translate: whatsapp.translateName(n.name),
      customSelect: `${whatsapp.translateName(n.name)} ${handleParsePhoneNumber(
        n.number
      )}`
    }))
  }
}
