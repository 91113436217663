import { ActionContext } from 'vuex'

import Storage from '@octadesk-tech/storage'

import { loadLanguageAsync } from '@/common/i18n'

import { RootState, LanguageState, Lang } from '@/store/interfaces'

import * as types from './mutations-types'

export const setLanguage = async (
  context: ActionContext<LanguageState, RootState>,
  lang: Lang
) => {
  Storage.setItem('lang', lang)

  return loadLanguageAsync(lang.name)
    .then()
    .catch(ex => {
      console.error('error on loadLanguageAsync: ', ex)
    })
    .finally(() => context.commit(types.SET_LANGUAGE, lang))
}
