import { AxiosInstance } from 'axios'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import { Token } from './store/interfaces'

export class TokenApiService {
  async getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async getAllTokens() {
    try {
      const client = await this.getClient('identityServer')
      const { data } = await client.get<Token[]>('apiToken', {
        ...legacyHeaders()
      })
      return data
    } catch (ex) {
      throw new Error(
        `Could not get API tokens: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }

  async getApiToken(id: string) {
    try {
      const client = await this.getClient('identityServer')
      const { data } = await client.get<Token>(`apiToken/${id}`, {
        ...legacyHeaders()
      })
      return data
    } catch (ex) {
      throw new Error(
        `Could not get API token: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }

  async createApiToken(token: Token) {
    try {
      const client = await this.getClient('identityServer')
      const { data } = await client.post<Token>('apiToken', token, {
        ...legacyHeaders()
      })
      return data
    } catch (ex) {
      throw new Error(
        `Could not create API token: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }

  async updateApiToken(token: Token) {
    try {
      const client = await this.getClient('identityServer')
      const { data } = await client.put<Token>(`apiToken`, token, {
        ...legacyHeaders()
      })
      return data
    } catch (ex) {
      throw new Error(
        `Could not update API token: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }

  async removeApiToken(tokenId: string) {
    try {
      const client = await this.getClient('identityServer')
      await client.delete(`apiToken/${tokenId}`, {
        ...legacyHeaders()
      })
    } catch (ex) {
      throw new Error(
        `Could not remove API token: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }
}
