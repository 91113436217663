import { ReminderState, ReminderAlert } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.PUSH_REMINDER_ALERT](state: ReminderState, alert: ReminderAlert) {
    state.reminderAlerts = [...state.reminderAlerts, alert].sort((a, b) => {
      if (a.body?.date && b.body?.date) {
        const dateA = new Date(a.body.date)
        const dateB = new Date(b.body.date)

        if (dateA > dateB) {
          return -1
        } else if (dateA < dateB) {
          return 1
        } else {
          return 0
        }
      }
      return 0
    })
  },
  [types.REMOVE_REMINDER_ALERT](state: ReminderState, alert: ReminderAlert) {
    state.reminderAlerts = state.reminderAlerts.filter(item => item !== alert)
  },
  [types.CLEAR_REMINDER_ALERTS](state: ReminderState) {
    state.reminderAlerts = []
  }
}
