import { headers } from '@octadesk-tech/services'

import { getBaseClient } from '@/modules/Bot/services/http'

const getCommerceClient = () => getBaseClient('commerce')

export const getCommerceCatalogService = async () => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get('/catalogs', { ...headers.getAuthorizedHeaders() })
    )

    return data.results
  } catch (ex) {
    throw new Error('Error in get Catalogs: ' + ex)
  }
}

export const getCommerceProductsService = async () => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get(`/store/products`, {
        params: {
          page: '1',
          limit: '200',
          shouldIncludeVariants: true
        },
        ...headers.getAuthorizedHeaders()
      })
    )

    return data.results
  } catch (ex) {
    throw new Error('Error in get products: ' + ex)
  }
}

export const getCommerceProductDetailsService = async ({ productId }) => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get(`/store/products/${productId}`, {
        ...headers.getAuthorizedHeaders()
      })
    )

    return data
  } catch (ex) {
    throw new Error('Error in get product details: ' + ex)
  }
}

export const getCommerceProductAttributesCombinationsService = async ({
  productId,
  attributes
}) => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get(
        `/store/products/${productId}/variations?attributeValueIds=${attributes}`,
        {
          ...headers.getAuthorizedHeaders()
        }
      )
    )

    return data
  } catch (ex) {
    throw new Error('Error in get product attributes combinations: ' + ex)
  }
}
