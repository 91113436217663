import * as BotService from '@/modules/Bot/services/bots'
import * as BotFluxesService from '@/modules/Bot/services/fluxes'
import * as OfficeHoursService from '@/modules/Bot/services/office-hours'
import types from '@/modules/Bot/store/mutations-types'
import * as officeHourTypes from '@/modules/Bot/store/types'

import i18n from '@/common/i18n'

export const getTimezonesList = async context => {
  const { language } = context.getters

  const timezonesList = await OfficeHoursService.timezonesList({
    culture: language
  })

  const removeDuplicatesTimezonesList = timezonesList.filter(function (a) {
    return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
  }, Object.create(null))

  context.commit(types.SET_TIMEZONES_LIST, removeDuplicatesTimezonesList)
}

export const getListOfficeHours = async context => {
  const officeHoursList = await OfficeHoursService.getListOfficeHours()

  context.commit(types.SET_OFFICE_HOURS_LIST, officeHoursList)
}

export const getOfficeHourById = async (context, id) => {
  return await OfficeHoursService.getOfficeHourById(id)
}

export const setOfficeHours = async (context, payload) => {
  if (!context.getters.timezonesList.length) {
    await context.dispatch('getTimezonesList')
  }

  if (payload.isNew) {
    context.commit(types.SET_OFFICE_HOURS, {
      ...officeHourTypes.officeHourModel,
      timeZone: i18n?.t('bot.newChatBot.timezone.timezone')
    })
  } else {
    const officeHours = await context.dispatch('getOfficeHourById', payload.id)

    context.commit(types.SET_OFFICE_HOURS, officeHours)
  }
}

export const clearCurrentOfficeHours = context => {
  context.commit(types.CLEAR_CURRENT_OFFICE_HOURS)

  context.dispatch('getListOfficeHours')
}

export const setCurrentOfficeHoursId = (context, officeHoursId) => {
  context.commit(types.SET_CURRENT_OFFICE_HOURS_ID, officeHoursId)
}

export const setOfficeHoursName = (context, name) => {
  context.commit(types.SET_OFFICE_HOURS_NAME, name)
}

export const setTimeZoneOfficeHours = (context, timeZone) => {
  context.commit(types.SET_TIMEZONE, timeZone)
}

export const setOperation24hours = (context, is24hours) => {
  context.commit(types.SET_OPERATION_24_HOURS, is24hours)
}

export const toggleSameSchedule = context => {
  context.commit(types.TOGGLE_SAME_SCHEDULE)
}

export const setSameScheduleHour = (context, payload) => {
  context.commit(types.SET_SAME_SCHEDULE_HOUR, payload)
}

export const addSameScheduleHour = context => {
  context.commit(types.ADD_SAME_SCHEDULE_HOUR)
}

export const deleteSameScheduleHour = (context, hourIndex) => {
  context.commit(types.DELETE_SAME_SCHEDULE_HOUR, hourIndex)
}

export const toggleAvailableDay = (context, dayOfWeek) => {
  context.commit(types.TOGGLE_AVAILABLE_DAY, dayOfWeek)
}

export const addNewHourInDay = (context, dayOfWeek) => {
  context.commit(types.ADD_NEW_HOUR_IN_DAY, dayOfWeek)
}

export const setHourInDay = (context, payload) => {
  context.commit(types.SET_HOUR_IN_DAY, payload)
}

export const deleteHourInDay = (context, payload) => {
  context.commit(types.DELETE_HOUR_IN_DAY, payload)
}

export const toggleSpecialHours = context => {
  context.commit(types.TOGGLE_SPECIAL_HOURS)
}

export const addNewSpecialDate = context => {
  context.commit(types.ADD_NEW_SPECIAL_DATE)
}

export const deleteSpecialDate = (context, dateIndex) => {
  context.commit(types.DELETE_SPECIAL_DATE, dateIndex)
}

export const setSpecialDateDay = (context, payload) => {
  context.commit(types.SET_SPECIAL_DATE_DAY, payload)
}

export const updateSpecialDateAvailable = (context, payload) => {
  context.commit(types.UPDATE_SPECIAL_DATE_AVAILABLE, payload)
}

export const setSpecialDateHourInDate = (context, payload) => {
  context.commit(types.SET_SPECIAL_DATE_HOUR_IN_DATE, payload)
}

export const deleteSpecialDateHourInDate = (context, payload) => {
  context.commit(types.DELETE_SPECIAL_DATE_HOUR_IN_DATE, payload)
}

export const addSpecialDateNewHourInDate = (context, dateIndex) => {
  context.commit(types.ADD_SPECIAL_DATE_NEW_HOUR_IN_DATE, dateIndex)
}

export const createNewOfficeHours = async context => {
  const { currentOfficeHours } = context.state

  const officeHours = { ...currentOfficeHours }

  delete officeHours.isNew

  try {
    const response = await OfficeHoursService.createNewOfficeHours(officeHours)

    context.dispatch('setCurrentOfficeHoursId', response.id)

    context.dispatch('getListOfficeHours')

    context.dispatch(
      'setModalContent',
      officeHourTypes.modalTypes.created.success
    )
  } catch (err) {
    console.error(err)

    context.dispatch(
      'setModalContent',
      officeHourTypes.modalTypes.created.error
    )
  }
}

export const setModalContent = (context, modalType) => {
  context.commit(types.SET_MODAL_CONTENT, modalType)
}

export const updateOfficeHours = async context => {
  const { currentOfficeHours } = context.state

  try {
    await OfficeHoursService.updateOfficeHours(currentOfficeHours)

    context.dispatch(
      'setModalContent',
      officeHourTypes.modalTypes.updated.success
    )
  } catch (err) {
    console.error(err)

    context.dispatch(
      'setModalContent',
      officeHourTypes.modalTypes.updated.error
    )
  }
}

export const setFormLoading = (context, isLoading) => {
  context.commit(types.SET_FORM_LOADING, isLoading)
}

export const clearBotsWithOfficeHours = context => {
  context.commit(types.CLEAR_BOTS_WITH_OFFICE_HOURS)
}

export const deleteOfficeHours = async (context, officeHoursId) => {
  context.dispatch('setFormLoading', true)

  const bots = await BotService.botsWithOfficeHours(officeHoursId)

  if (bots) {
    const botIds = bots.map(bot => bot.id).join(',')

    const botNames = await BotFluxesService.getFluxesById(botIds)

    context.commit(types.SET_BOTS_WITH_OFFICE_HOURS, botNames)

    context.dispatch(
      'setModalContent',
      officeHourTypes.modalTypes.delete.conflict
    )
  } else {
    await OfficeHoursService.deleteOfficeHours(officeHoursId)

    context.dispatch('getListOfficeHours')

    context.dispatch(
      'setModalContent',
      officeHourTypes.modalTypes.delete.success
    )
  }

  context.dispatch('setFormLoading', false)
}
