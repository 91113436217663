import { AxiosError } from 'axios'

export default class APIError extends Error {
  constructor(error: AxiosError) {
    super()
    const { response } = error

    this.name = 'APIError'
    this.status = response?.status
    this.message =
      error.message || `${response?.status} ${response?.statusText}`
    this.data = response?.data
  }

  status: number | undefined
  data: any
}
