import { SurveyConfig, SurveyState } from './interfaces'

const surveyDefaultConfig: SurveyConfig = {
  customTitle: '',
  customMessage: '',
  isEnabled: false,
  enableDate: undefined,
  id: '',
  idCompany: 0
}

const state: SurveyState = {
  surveyConfig: surveyDefaultConfig,
  isConfigured: false
}

export default state
