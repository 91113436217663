export default {
  SET_CHATBOTS_FLUXES: 'SET_CHATBOTS_FLUXES',
  SET_CURRENT_CHATBOT_FLUX: 'SET_CURRENT_CHATBOT_FLUX',
  UPSERT_CHATBOT_FLUX: 'UPSERT_CHATBOT_FLUX',
  ADD_PAGE_CONDITION: 'ADD_PAGE_CONDITION',
  REMOVE_PAGE_CONDITION: 'REMOVE_PAGE_CONDITION',
  SET_CURRENT_CONDITIONS_VIEW: 'SET_CURRENT_CONDITIONS_VIEW',
  DELETE_BOT_FLUX: 'DELETE_BOT_FLUX',
  SORT_BOT_FLUXES: 'SORT_BOT_FLUXES',
  ADD_BOT_FLUX: 'ADD_BOT_FLUX',
  ADD_BOT_FLUX_AFTER: 'ADD_BOT_FLUX_AFTER'
}
