import { WhatsAppIntegratorService } from '@/modules/ChatSettings/whatsapp-oficial/numbers/services/whatsapp-integrator.service'

import * as types from './mutations-types'

const whatsAppIntegratorService = WhatsAppIntegratorService.getInstance()

export const updateWhatsappApiNumber = async ({ getters, dispatch }: any) => {
  const response = await whatsAppIntegratorService.updateWhatsappApiNumber(
    getters.wppApiNumber
  )

  if (response.data) {
    dispatch('updateStateWhatsappOfficialNumberName', response.data)
  }

  return response
}

export const setCurrentWhatsappApiNumber = (
  context: any,
  updatedValues: any
) => {
  context.commit(types.SET_WPP_API_NUMBER, updatedValues)
}

export const setCurrentWhatsappApiState = (context: any, state: any) => {
  context.commit(types.SET_CURRENT_WPP_API_STATE, state)
}

export const clearCurrentWhatsappApiNumber = (context: any) => {
  context.commit(types.CLEAR_CURRENT_WPP_API_NUMBER)
}
