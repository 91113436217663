import { TemplateMessagesState } from './interfaces'

export const createMessageModalIsOpen = ({
  createMessageModalIsOpen
}: TemplateMessagesState) => createMessageModalIsOpen

export const createMessageStep = ({
  createMessageStep
}: TemplateMessagesState) => createMessageStep

export const createMessageModel = ({
  createMessageModel
}: TemplateMessagesState) => createMessageModel

export const createMessagePayload = ({
  createMessagePayload
}: TemplateMessagesState) => createMessagePayload

export const createMessageError = ({
  createMessageError
}: TemplateMessagesState) => createMessageError

export const createMessageNumber = ({
  createMessageNumber
}: TemplateMessagesState) => createMessageNumber

export const createMessageSuccess = ({
  createMessageSuccess
}: TemplateMessagesState) => createMessageSuccess

export const createMessageConfirm = ({
  createMessageConfirm
}: TemplateMessagesState) => createMessageConfirm

export const selectedMessage = ({ selectedMessage }: TemplateMessagesState) =>
  selectedMessage

export const templateMessages = ({ templateMessages }: TemplateMessagesState) =>
  templateMessages

export const templateMessagesIsLoaded = ({
  templateMessagesIsLoaded
}: TemplateMessagesState) => templateMessagesIsLoaded

export const personFields = ({ personFields }: TemplateMessagesState) =>
  personFields

export const chatFields = ({ chatFields }: TemplateMessagesState) => chatFields

export const createCustomFieldLoaderActive = ({
  createCustomFieldLoaderActive
}: TemplateMessagesState) => createCustomFieldLoaderActive
