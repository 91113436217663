/* eslint-disable sonarjs/no-duplicate-string */
import axios, { AxiosInstance, CancelTokenSource } from 'axios'

import Storage from '@octadesk-tech/storage'

import { OrganizationEnum } from '@/common/helpers/enums/organization.enum'
import { PersonRoleTypes } from '@/common/helpers/types/person-role-types.type'
import { emailValidator } from '@/common/helpers/utils/email-validator'
import { parseToUrl } from '@/common/helpers/utils/parse-object-query'

import legacyHeaders from '@/common/services/legacy-headers'

import { FilterContact, FilterOrganization } from '@/store/interfaces'
import authStore from '@/store/modules/auth'

import { getClient } from './http'

const API_NAME = 'personsService'

export default class PersonsService {
  autocompleteEmployeesCancelToken?: CancelTokenSource
  autocompleteAssigneesCancelToken?: CancelTokenSource

  getClient(): Promise<AxiosInstance> {
    return getClient(API_NAME)
  }

  async getPersons() {
    const payload = {
      Take: 50,
      PropertySort: 'Name',
      SortDirection: 'asc',
      ExternalQueries: [
        { PropertyName: 'Type', Operator: 2, ValueCompare: '1' },
        { PropertyName: 'TextSearch', Operator: 4, ValueCompare: '' }
      ],
      ExternalQueriesOr: [],
      includeExcludedRecords: true,
      Skip: 0
    }
    try {
      const { data } = await this.getClient().then(client =>
        client.post(`/filter`, payload, legacyHeaders())
      )
      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error in filter Persons: ' + ex)
      }
    }
  }

  async suggestAssignees(search: string) {
    if (this.autocompleteAssigneesCancelToken) {
      this.autocompleteAssigneesCancelToken.cancel()
    }

    this.autocompleteAssigneesCancelToken = axios.CancelToken.source()

    try {
      const client = await this.getClient()
      const response = await client.get(`/suggest/assignees?search=${search}`, {
        ...legacyHeaders(),
        cancelToken: (
          this.autocompleteAssigneesCancelToken as CancelTokenSource
        ).token
      })
      return response.data
    } catch (ex) {
      if (axios.isCancel(ex)) {
        return []
      }

      throw ex
    }
  }

  async autocompleteEmployees(search: string) {
    if (this.autocompleteEmployeesCancelToken) {
      this.autocompleteEmployeesCancelToken.cancel()
    }

    this.autocompleteEmployeesCancelToken = axios.CancelToken.source()

    try {
      const client = await this.getClient()
      const response = await client.get(`/suggest/employees?search=${search}`, {
        ...legacyHeaders(),
        cancelToken: (
          this.autocompleteEmployeesCancelToken as CancelTokenSource
        ).token
      })
      return response.data
    } catch (ex) {
      if (axios.isCancel(ex)) {
        return []
      }

      throw ex
    }
  }

  async createContact(data: object) {
    const contact = await this.getClient().then(client =>
      client.post('/', data, legacyHeaders())
    )
    return contact.data
  }

  async updatePerson(personId: string, payload: Record<string, any>) {
    try {
      const client = await this.getClient()
      const { data } = await client.put(
        `/${personId}`,
        payload,
        legacyHeaders()
      )
      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error in editPerson: ' + ex)
      }
    }
  }

  async addMultiplePeople(people: any) {
    await this.getClient()
      .then(client => client.post('/bulk', people, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in addMultiplePeople ${ex}`)
      })
  }

  async getGroupPersons(filter: any) {
    return await this.getClient()
      .then(client => client.get(`/groups?${filter}`, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in getGroupPersons ${ex}`)
      })
  }

  async getPaginatedContacts(filter: FilterContact) {
    return this.getClient()
      .then(client => client.post(`/search`, filter, legacyHeaders()))
      .then(response => response.data)
  }

  async getContact(id: string) {
    const contact = await this.getClient()
      .then(client => client.get(`/getPerson?id=${id}`, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in getContact ${ex}`)
      })
    return contact.data?.person
  }

  async getContactStatus() {
    const contactStatus = await this.getClient()
      .then(client => client.get(`/contact-status`, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in getContactStatus ${ex}`)
      })

    return contactStatus.data
  }

  async updateContact(id: string, data: object) {
    const contact = await this.getClient()
      .then(client => client.put(`/${id}`, data, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in updateContact ${ex}`)
      })
    return contact.data
  }

  async toggleContact(id: string, toggle: boolean) {
    const operation = toggle

    return await this.getClient()
      .then(client => client.put(`/bulk/${id}`, operation, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in toggleContact ${ex}`)
      })
  }

  async toggleContacts(list: any) {
    return await this.getClient().then(client =>
      client.put('/bulk', list, legacyHeaders())
    )
  }

  async countFilteredContacts(filter: FilterContact) {
    const count = await this.getClient()
      .then(client => client.post('/count', filter, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in countFilteredContacts ${ex}`)
      })
    return count.data
  }

  async getTotalContacts() {
    const count = await this.getClient()
      .then(client => client.post('/count', {}, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in getTotalContacts ${ex}`)
      })
    return count.data
  }

  async getOrganizations(take: number, skip = 0) {
    return await this.getClient()
      .then(client =>
        client.get(
          `/organizations?take=${take}&skip=${skip}&PropertySort=Name&SortDirection=asc&includeExcludedRecords=true`,
          legacyHeaders()
        )
      )
      .catch(ex => {
        throw new Error(`Error in getOrganizations ${ex}`)
      })
  }

  async getFilteredOrganizations(filter: FilterOrganization) {
    const queryParams = parseToUrl(filter)

    const organizations = await this.getClient()
      .then(client =>
        client.get(`/organizations?${queryParams}`, legacyHeaders())
      )
      .catch(ex => {
        throw new Error(`Error in getFilteredOrganizations ${ex}`)
      })
    return organizations.data
  }

  async countFilteredOrganizations(filter: FilterOrganization) {
    const queryParams = parseToUrl(filter)

    const count = await this.getClient()
      .then(client =>
        client.get(`/organizations/count?${queryParams}`, legacyHeaders())
      )
      .catch(ex => {
        throw new Error(`Error in countFilteredOrganizations ${ex}`)
      })
    return count.data
  }

  async countOrganizations() {
    return await this.getClient()
      .then(client =>
        client.get(
          `/organizations/count?includeExcludedRecords=true`,
          legacyHeaders()
        )
      )
      .catch(ex => {
        throw new Error(`Error in countOrganizations ${ex}`)
      })
  }

  async searchOrganizations(search: string, includeExcludedRecords = true) {
    return await this.getClient()
      .then(client =>
        client.get(
          `/organizations?includeExcludedRecords=${includeExcludedRecords}&textSearch=true&search=${search}`,
          legacyHeaders()
        )
      )
      .catch(ex => {
        throw new Error(`Error in searchOrganizations ${ex}`)
      })
  }

  async toggleOrganization(id: string, toggle = false) {
    const operation = toggle
      ? OrganizationEnum.ENABLE
      : OrganizationEnum.DISABLE

    return await this.getClient()
      .then(client =>
        client.patch(`/organizations/${id}/${operation}`, {}, legacyHeaders())
      )
      .catch(ex => {
        throw new Error(`Error in toggleOrganization ${ex}`)
      })
  }

  async toggleOrganizations(
    list: Array<string>,
    operation = OrganizationEnum.DISABLE
  ) {
    return await this.getClient()
      .then(client =>
        client.put(`/organizations/bulk/${operation}`, list, legacyHeaders())
      )
      .catch(ex => {
        throw new Error(`Error in toggleOrganizations ${ex}`)
      })
  }

  async updateOrganization(id: string, data: object) {
    const organization = await this.getClient()
      .then(client => client.put(`/organizations/${id}`, data, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in updateOrganization ${ex}`)
      })
    return organization.data
  }

  async createOrganization(data: object) {
    const organization = await this.getClient()
      .then(client => client.post(`/organizations`, data, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in createOrganization ${ex}`)
      })
    return organization.data
  }

  async enqueuePersonExport(payload: any) {
    const userlogged = Storage.encrypt(authStore.state.userlogged)
    return this.getClient().then(client =>
      client.post('/export/enqueue', payload, {
        headers: { ...legacyHeaders().headers, userlogged }
      })
    )
  }

  async countFilteredMembersOrganization(filter: FilterOrganization) {
    const count = await this.getClient()
      .then(client => client.post('/count', filter, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in countFilteredMembersOrganization ${ex}`)
      })
    return count.data
  }

  async getFilteredMembersOrganization(filter: FilterOrganization) {
    const result = await this.getClient()
      .then(client => client.post('/search', filter, legacyHeaders()))
      .catch(ex => {
        throw new Error(`Error in getFilteredMembersOrganization ${ex}`)
      })
    return result.data
  }

  async getRouletteStatus(userId: string) {
    const IdOrEmail = emailValidator.test(userId) ? 'email' : 'id'
    try {
      const client = await this.getClient()
      const { data } = await client.get(
        `/available-roulette?${IdOrEmail}=${userId}`,
        legacyHeaders()
      )
      return data
    } catch (ex) {
      throw new Error(`Error in get user roulette status: ${ex}`)
    }
  }

  async setRouletteStatus(userId: string, status: boolean) {
    const IdOrEmail = emailValidator.test(userId) ? 'email' : 'id'
    try {
      const client = await this.getClient()
      const result = await client.put(
        `/set-roulette/${status}?${IdOrEmail}=${userId}`,
        {},
        legacyHeaders()
      )
      return result.data
    } catch (ex) {
      throw new Error(`Error in set user roulette status: ${ex}`)
    }
  }

  async getGroups() {
    const client = await this.getClient()
    const { data } = await client.get(`/groups`, legacyHeaders())
    return data
  }

  async addProductsPerson(personId: string, products: Array<string>) {
    try {
      const client = await this.getClient()
      const result = await client.patch(
        `/${personId}/products`,
        { products },
        legacyHeaders()
      )
      return result.data
    } catch (ex) {
      throw new Error(
        `Error in add products to person: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }

  async deletePersonsProduct(personId: string, productId: string) {
    try {
      const client = await this.getClient()
      const result = await client.delete(
        `/${personId}/products/${productId}`,
        legacyHeaders()
      )
      return result.data
    } catch (ex) {
      throw new Error(
        `Error in delete persons product: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }

  async getAllPersonRoleTypeConfig() {
    try {
      const client = await this.getClient()
      const { data }: { data: PersonRoleTypes[] } = await client.get(
        `/${authStore.state?.userlogged?.id}/role-types`,
        legacyHeaders()
      )
      return data
    } catch (ex) {
      throw new Error(
        `Error in get all person role type config: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }
}
