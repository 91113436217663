import { ContactForm, ContactFormSelected, TalktousState } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_FORMS](state: TalktousState, forms: ContactForm[]) {
    state.forms = forms
  },
  [types.SET_FORMS_LOADING](state: TalktousState, formsLoading: boolean) {
    state.formsLoading = formsLoading
  },
  [types.SET_FORM_SELECTED](
    state: TalktousState,
    formSelected: ContactFormSelected
  ) {
    state.formSelected = formSelected
  },
  [types.SET_AREA](state: TalktousState, area: string) {
    state.area = area
  },
  [types.SET_ID_LAST_FORM](state: TalktousState, idLastForm: string) {
    state.idLastForm = idLastForm
  },
  [types.SET_EMBEDDED_CODE](state: TalktousState, embeddedCode: string) {
    state.formSelected.embeddedCode = embeddedCode
  },
  [types.SET_FORM_SELECTED_LOADING](state: TalktousState, loading: boolean) {
    state.formSelected.loading = loading
  },
  [types.SET_FORMS_ERROR](state: TalktousState, formsError: boolean) {
    state.formsError = formsError
  },
  [types.SET_FORM_SELECTED_LINK](
    state: TalktousState,
    formSelectedLink: string
  ) {
    state.formSelected.link = formSelectedLink
  },
  [types.SET_FORM_SELECTED_LINK_STYLE](
    state: TalktousState,
    formSelectedLinkStyle: string
  ) {
    state.formSelected.linkStyle = formSelectedLinkStyle
  },
  [types.SET_FORM_SELECTED_ERROR](
    state: TalktousState,
    formSelectedError: boolean
  ) {
    state.formSelected.formSelectedError = formSelectedError
  }
}
