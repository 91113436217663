import { ActionContext } from 'vuex'

import AppsService from '@/common/services/app.service'

import { RootState } from '@/store/interfaces'

import * as types from './mutations-types'

export const setApps = (context: ActionContext<any, RootState>, apps: any) => {
  context.commit(types.SET_APPS, apps)
}

export const loadUserApps = async (context: ActionContext<any, RootState>) => {
  const appsService = new AppsService()
  try {
    const userApps = await appsService.getUserApps()
    context.commit(types.SET_APPS, userApps)
  } catch (error) {
    console.error((error as any).message)
    throw new Error((error as any).message)
  }
}
