import { FilterField } from '@/store/interfaces/field'

import { EventHandlerState } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_EVENT_HANDLER_FIELDS](
    state: EventHandlerState,
    fields: Array<FilterField>
  ) {
    state.fields = fields
  },
  [types.SET_UNSAVED_CHANGES](state: EventHandlerState, unsaved = true) {
    state.hasUnsavedChanges = unsaved
  }
}
