import { WOZAgentsState, WozAgent } from './interfaces'

const defaultAgent: WozAgent = {
  voice: '',
  wordsToAvoid: [],
  instructions: '',
  isWoz: false,
  name: '',
  onlyMyContent: true,
  active: true,
  specialties: []
}

const state: WOZAgentsState = {
  agents: null,
  loadingAgents: false,
  currentAgent: undefined,
  defaultAgent,
  copilotAgent: undefined
}

export default state
