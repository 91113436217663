import { NewPasswordSteps } from '@/common/helpers/enums/new-password-steps'

import { NewPasswordState } from '@/store/interfaces'

const state: NewPasswordState = {
  currentStep: NewPasswordSteps.RequestNewPassword,
  userId: undefined,
  token: undefined
}

export default state
