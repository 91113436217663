import bot from './bot'
import botFlux from './bot-flux'
import breadcrumb from './breadcrumb'
import commerce from './commerce'
import contentProperty from './content-properties'
import customFields from './custom-fields'
import interactions from './interactions'
import officeHours from './office-hours'
import sidebar from './sidebar'

export default {
  SET_CURRENT_CHATBOT: 'SET_CURRENT_CHATBOT',
  SET_CHATBOTS: 'SET_CHATBOTS',
  UPSERT_CHATBOT: 'UPSERT_CHATBOT',
  REMOVE_CHATBOT: 'REMOVE_CHATBOT',
  SET_BOT_CHANNEL: 'SET_BOT_CHANNEL',
  TOGGLE_MODAL_CHANNEL_RESTRICTIONS: 'TOGGLE_MODAL_CHANNEL_RESTRICTIONS',
  SET_CHANNELS_SPECIFICATIONS: 'SET_CHANNELS_SPECIFICATIONS',
  SET_BOT_CHANNELS_SPECIFICATIONS: 'SET_BOT_CHANNELS_SPECIFICATIONS',
  SET_CHANNELS_INFO_SPECIFICATIONS: 'SET_CHANNELS_INFO_SPECIFICATIONS',
  SET_DUPLICATE_BOT_FLUX: 'SET_DUPLICATE_BOT_FLUX',
  SET_DUPLICATE_FLUX_ID: 'SET_DUPLICATE_FLUX_ID',
  SET_MIGRATE_BOT_FLUX: 'SET_MIGRATE_BOT_FLUX',
  SET_MIGRATE_FLUX_ID: 'SET_MIGRATE_FLUX_ID',
  SET_BOT_BUILDER: 'SET_BOT_BUILDER',
  SET_WHATSAPP_NUMBERS: 'SET_WHATSAPP_NUMBERS',
  SET_FACEBOOK_PAGES: 'SET_FACEBOOK_PAGES',
  SET_INSTAGRAM_PAGES: 'SET_INSTAGRAM_PAGES',
  ...bot,
  ...breadcrumb,
  ...contentProperty,
  ...botFlux,
  ...interactions,
  ...sidebar,
  ...commerce,
  ...officeHours,
  ...customFields
}
