import { AxiosInstance } from 'axios'

import { CommerceCatalogProducts } from '@/modules/Commerce/interfaces'

import i18n from '@/common/i18n'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import { CommerceCatalogInfo } from './store/interfaces'

const COMMERCE_API_NAME = 'commerce'

const COMMERCE_BFF_NAME = 'commerceBff'

const FORMATTERS = new Map<string, Intl.NumberFormat>()

function getPriceFormatter(locale: string): Intl.NumberFormat {
  let formatter = FORMATTERS.get(locale)

  if (!formatter) {
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'BRL'
    })

    FORMATTERS.set(locale, formatter)
  }

  return formatter
}

export function formatCatalogProductPrice(price?: number): string | undefined {
  if (!price) return

  const formatter = getPriceFormatter(i18n?.locale)

  return formatter.format(price)
}

function addLocaleParam(
  params: Record<string, unknown> = {}
): Record<string, string> {
  return {
    ...params,
    locale: i18n?.locale
  }
}

export class CatalogsService {
  private static instance?: CatalogsService

  public static getInstance(): CatalogsService {
    if (!this.instance) {
      this.instance = new CatalogsService()
    }

    return this.instance
  }

  getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async deleteCommerceCatalog(catalogId: string) {
    try {
      await this.getClient(COMMERCE_API_NAME).then(client =>
        client.delete(`/catalogs/${catalogId}`, { ...legacyHeaders() })
      )
    } catch (err) {
      throw new Error('Error on delete catalog: ' + err)
    }
  }

  async getCommerceCatalog(): Promise<CommerceCatalogInfo> {
    const client = await this.getClient(COMMERCE_BFF_NAME)

    const { data } = await client.get<CommerceCatalogInfo>('/catalogs', {
      params: addLocaleParam(),
      ...legacyHeaders()
    })

    return data
  }

  async getCommerceCatalogProducts(
    catalogId: string,
    pageToken?: string
  ): Promise<CommerceCatalogProducts> {
    const client = await this.getClient(COMMERCE_BFF_NAME)

    const { data } = await client.get<CommerceCatalogProducts>(
      `/catalogs/${catalogId}/products`,
      {
        params: addLocaleParam({ pageToken }),
        ...legacyHeaders()
      }
    )

    return data
  }
}
