import { ActionContext } from 'vuex'

import { v4 as uuid } from 'uuid'

import { RootState } from '@/store/interfaces'

import WebhookService from '../webhook.service'
import { WebhookState, WebhooksResponse, WebhooksStateInfo } from './interfaces'
import * as types from './mutations-types'

// Number must be in milliseconds
const TEN_MINUTES = 1000 * 60 * 10

const generateEmptyWebhook = () => ({
  data: {
    id: uuid(),
    configuration: {
      attempts: 3,
      secondsIntervalAttempt: TEN_MINUTES,
      method: 'POST',
      url: ''
    },
    eventName: '',
    isEnabled: false
  },
  errors: {
    eventName: false,
    url: {
      empty: false,
      invalid: false
    }
  },
  isFirstFill: true
})

const getFilledStateData = (responseData: WebhooksResponse) => {
  return responseData.map(responseDataItem => {
    return {
      data: responseDataItem,
      errors: {
        eventName: false,
        url: {
          empty: false,
          invalid: false
        }
      },
      isFirstFill: false
    }
  })
}

const getUpdatedWebhooksAfterEventNameChange = (
  webhooks: WebhooksStateInfo[],
  payload: { id: string; eventName: string }
) => {
  return webhooks.map(webhook => {
    if (webhook.data.id === payload.id) {
      const updatedWebhook = {
        ...webhook,
        data: {
          ...webhook.data,
          eventName: payload.eventName
        },
        errors: {
          ...webhook.errors,
          eventName: payload.eventName === ''
        }
      }

      if (
        updatedWebhook.data.configuration.url &&
        updatedWebhook.data.eventName
      ) {
        updatedWebhook.isFirstFill = false
      }

      return updatedWebhook
    }

    return webhook
  })
}

const getUpdatedWebhooksAfterUrlChange = (
  webhooks: WebhooksStateInfo[],
  payload: { id: string; url: string }
) => {
  const urlRegex =
    /^(https:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?(\?[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/

  return webhooks.map(webhook => {
    if (webhook.data.id === payload.id) {
      const updatedWebhook = {
        ...webhook,
        data: {
          ...webhook.data,
          configuration: {
            ...webhook.data.configuration,
            url: payload.url
          }
        },
        errors: {
          ...webhook.errors,
          url: {
            empty: payload.url === '',
            invalid: !urlRegex.test(payload.url)
          }
        }
      }

      if (
        updatedWebhook.data.configuration.url &&
        updatedWebhook.data.eventName
      ) {
        updatedWebhook.isFirstFill = false
      }

      return updatedWebhook
    }

    return webhook
  })
}

const removeAndRetrieveWebhooks = (
  webhooks: WebhooksStateInfo[],
  payload: { id: string }
) => {
  return webhooks.reduce<[WebhooksStateInfo | undefined, WebhooksStateInfo[]]>(
    (acc, currentItem) => {
      if (currentItem.data.id === payload.id) {
        acc[0] = currentItem
      } else {
        acc[1].push(currentItem)
      }
      return acc
    },
    [undefined, []]
  )
}

const getWebhookDataFilteringId = (webhooks: WebhooksStateInfo[]) => {
  return webhooks.map(webhook => {
    const { id, ...data } = webhook.data

    if (data.isEnabled) {
      return {
        id,
        ...data
      }
    }

    return {
      ...data,
      isEnabled: true
    }
  })
}

export const getWebhooks = async (
  context: ActionContext<WebhookState, RootState>
) => {
  try {
    const responseData = await WebhookService.fetchWebhooks()
    const stateData = getFilledStateData(responseData)
    context.commit(types.SET_WEBHOOK_DATA, stateData)
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const createWebhook = (
  context: ActionContext<WebhookState, RootState>
) => {
  const emptyWebhook = generateEmptyWebhook()
  context.commit(types.CREATE_WEBHOOK, emptyWebhook)
}

export const updateWebhookEventName = (
  context: ActionContext<WebhookState, RootState>,
  payload: { id: string; eventName: string }
) => {
  if (!context.state.hasChangedWebhooksList) {
    context.commit(types.SET_HAS_CHANGED_LIST)
  }

  const updatedWebhooks = getUpdatedWebhooksAfterEventNameChange(
    context.state.webhooks,
    payload
  )

  context.commit(types.UPDATE_WEBHOOK_EVENT_NAME, updatedWebhooks)
}

export const updateWebhookUrl = (
  context: ActionContext<WebhookState, RootState>,
  payload: { id: string; url: string }
) => {
  if (!context.state.hasChangedWebhooksList) {
    context.commit(types.SET_HAS_CHANGED_LIST)
  }

  const updatedWebhooks = getUpdatedWebhooksAfterUrlChange(
    context.state.webhooks,
    payload
  )

  context.commit(types.UPDATE_WEBHOOK_URL, updatedWebhooks)
}

export const removeWebhook = async (
  context: ActionContext<WebhookState, RootState>,
  payload: { id: string }
) => {
  const [removedWebhook, remainingWebhooks] = removeAndRetrieveWebhooks(
    context.state.webhooks,
    payload
  )

  if (!removedWebhook?.data.isEnabled) {
    context.commit(types.REMOVE_WEBHOOK, remainingWebhooks)
    return
  }

  try {
    await WebhookService.removeWebhook(payload.id)
    context.commit(types.REMOVE_WEBHOOK, remainingWebhooks)
  } catch (error) {
    throw new Error((error as any).message)
  }
}

export const saveWebhooks = async (
  context: ActionContext<WebhookState, RootState>
) => {
  try {
    const webhookData = getWebhookDataFilteringId(context.state.webhooks)
    await WebhookService.saveWebhooks(webhookData)
    await getWebhooks(context)
  } catch (error) {
    throw new Error((error as any).message)
  }
}
