import { ActionContext } from 'vuex'

import { EmailService } from '@/modules/ChatSettings/other-channels/email/email.services'

import { RootState } from '@/store/interfaces'

import { EmailState } from './interfaces'
import * as types from './mutations-types'

const emailService = new EmailService()

export const setRedirectEmailAddress = (
  context: ActionContext<EmailState, RootState>,
  address: any
) => {
  context.commit(types.SET_REDIRECT_EMAIL_ADDRESS, address)
}

export const setEmailRedirection = async (
  _: ActionContext<EmailState, RootState>,
  payload: any
) => {
  try {
    return await emailService.setEmailRedirection(payload)
  } catch (ex) {
    throw new Error(ex as any)
  }
}
