import { WOZAgentsState } from './interfaces'

export const wozAgents = ({ agents }: WOZAgentsState) => agents

export const loadingAgents = ({ loadingAgents }: WOZAgentsState) =>
  loadingAgents

export const currentAgent = ({ currentAgent }: WOZAgentsState) => currentAgent

export const defaultAgent = ({ defaultAgent }: WOZAgentsState) => defaultAgent

export const copilotAgent = ({ copilotAgent }: WOZAgentsState) => copilotAgent
