import Storage from '@octadesk-tech/storage'

import { BulkMessageState } from './interfaces'

export const language = () =>
  ((Storage.getItem('language') as string) || 'pt-br').toLowerCase()

export const initialData = ({ initialData }: BulkMessageState) => initialData

export const whatsappOfficialNumbers = ({
  whatsappOfficialNumbers
}: BulkMessageState) => whatsappOfficialNumbers

export const approvedTemplateMessages = ({
  approvedTemplateMessages
}: BulkMessageState) => approvedTemplateMessages

export const bulkMessages = ({ bulkMessages }: BulkMessageState) => bulkMessages

export const selectedBulkMessage = ({
  selectedBulkMessage
}: BulkMessageState) => selectedBulkMessage

export const bulkMessagesReport = ({ bulkMessagesReport }: BulkMessageState) =>
  bulkMessagesReport

export const retrievedTemplateMessage = ({
  retrievedTemplateMessage
}: BulkMessageState) => retrievedTemplateMessage

export const availableTemplateMessages = ({
  availableTemplateMessages
}: BulkMessageState) => availableTemplateMessages

export const currentListPage = ({ currentListPage }: BulkMessageState) =>
  currentListPage

export const currentListLoader = ({ currentListLoader }: BulkMessageState) =>
  currentListLoader

export const showBtnLoadMore = ({
  bulkMessages,
  totalBulkMessages
}: BulkMessageState) => bulkMessages.length < totalBulkMessages

export const formStep = ({ bulkMessage }: BulkMessageState) => bulkMessage.step

export const formPayload = ({ bulkMessage }: BulkMessageState) =>
  bulkMessage.formPayload

export const isSpreadSheetEmpty = ({ bulkMessage }: BulkMessageState) =>
  bulkMessage.isSpreadSheetEmpty

export const isFormPayloadDirty = ({ bulkMessage }: BulkMessageState) => {
  const { initialFormPayload, formPayload } = bulkMessage

  return JSON.stringify(formPayload) === JSON.stringify(initialFormPayload)
}

export const showDiscardContentAlert = ({ bulkMessage }: BulkMessageState) =>
  bulkMessage.showDiscardContentAlert

export const showReviewModal = ({ bulkMessage }: BulkMessageState) =>
  bulkMessage.showReviewModal

export const successModal = ({ bulkMessage }: BulkMessageState) =>
  bulkMessage.successModal
