import { GroupsService } from '@/modules/ChatSettings/groups/groups.service'

import { DataSourceResolver } from './interfaces'

let service: GroupsService

const getService = () => {
  if (service) {
    return service
  }

  service = new GroupsService()
  return service
}

export const chatGroups: DataSourceResolver = () =>
  getService()
    .getGroups()
    .then(groups => groups.map(group => ({ id: group.id, name: group.name })))
