export enum DomainType {
  Ticket = 1,
  Person = 2,
  Product = 3,
  Chat = 4,
  Organization = 5,
  Contact = 6
}

export const DomainTypeString = {
  Ticket: 'ticket',
  Person: 'person',
  Product: 'product',
  Chat: 'chat',
  Organization: 'organization',
  Contact: 'contact',
  [DomainType.Ticket]: 'ticket',
  [DomainType.Person]: 'person',
  [DomainType.Product]: 'product',
  [DomainType.Chat]: 'chat',
  [DomainType.Organization]: 'organization',
  [DomainType.Contact]: 'contact'
}
