import { ActionContext } from 'vuex'

import { MetaAdsService } from '@/modules/Adverts/meta-ads/meta-ads.service'

import { FacebookSdkService } from '@/common/services/facebook-sdk.service'

import { RootState } from '@/store/interfaces'

import { MetaAdsState } from './interfaces'
import * as types from './mutation-types'

const metaAdsService = new MetaAdsService()
const facebookSdkService = FacebookSdkService.getInstance()

export const metaAdsIntialFetch = async (
  context: ActionContext<MetaAdsState, RootState>
) => {
  const storageAccessToken = await facebookSdkService.getPermanentToken()

  if (storageAccessToken) {
    context.dispatch('setMetaAdsAccessToken', storageAccessToken)
  }

  await context.dispatch('setMetaAdsWppNumbers')

  context.commit(types.SET_META_ADS_INITIAL_DATA_FETCH, true)
}

export const setMetaAdsWppNumbers = async (
  context: ActionContext<MetaAdsState, RootState>
) => {
  const whatsAppNumbers = await metaAdsService.getWhatsAppNumbers()

  context.commit(types.SET_META_ADS_WHATSAPP_NUMBERS, whatsAppNumbers)
}

export const setMetaAdsAccessToken = async (
  context: ActionContext<MetaAdsState, RootState>,
  accessToken: string
) => {
  await facebookSdkService.setPermanentToken({
    accessToken
  })

  context.commit(types.SET_META_ADS_ACCESS_TOKEN, accessToken)
}

export const removeMetaAdsAccessToken = async (
  context: ActionContext<MetaAdsState, RootState>
) => {
  await facebookSdkService.removePermanentToken()

  context.commit(types.SET_META_ADS_ACCESS_TOKEN, null)
}
