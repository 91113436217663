import {
  ProductsCommerceState,
  CommerceSingleProduct,
  CommerceAvailableAttributes,
  CommerceProductAttributes,
  CommerceProductVariants
} from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_COMMERCE_PRODUCT](
    state: ProductsCommerceState,
    product: CommerceSingleProduct
  ) {
    state.data = product
  },
  [types.TOGGLE_COMMERCE_PRODUCT_LOADER](
    state: ProductsCommerceState,
    isLoader: boolean
  ) {
    state.loader = isLoader
  },
  [types.TOGGLE_COMMERCE_AVAILABLE_ATTRIBUTES_LOADER](
    state: ProductsCommerceState,
    isLoader: boolean
  ) {
    state.attributes.loader = isLoader
  },
  [types.SET_COMMERCE_PRODUCT_AVAILABLE_ATTRIBUTES](
    state: ProductsCommerceState,
    availableAttributes: Array<CommerceAvailableAttributes>
  ) {
    state.attributes.items = availableAttributes
  },
  [types.SET_COMMERCE_PRODUCT_ATTRIBUTES](
    state: ProductsCommerceState,
    productAttributes: Array<CommerceProductAttributes>
  ) {
    state.data.attributes = [...productAttributes]
  },
  [types.ADD_COMMERCE_PRODUCT_VARIANTS](
    state: ProductsCommerceState,
    productVariants: Array<CommerceProductVariants>
  ) {
    state.data.variants.push(...productVariants)
  },
  [types.CLEAR_COMMERCE_PRODUCT_VARIANTS](state: ProductsCommerceState) {
    state.data.variants = []
  },
  [types.UPDATE_COMMERCE_PRODUCT_VARIANTS](
    state: ProductsCommerceState,
    productVariants: Array<CommerceProductVariants>
  ) {
    state.data.variants = productVariants
  }
}
