import { TemplateMessagesState } from './interfaces'

export const whatsAppNumbersLoader = ({
  whatsAppNumbers
}: TemplateMessagesState) => whatsAppNumbers.loader

export const whatsAppNumbers = ({ whatsAppNumbers }: TemplateMessagesState) =>
  whatsAppNumbers.items

export const availableCategoriesLoader = ({
  availableCategories
}: TemplateMessagesState) => availableCategories.loader

export const availableCategories = ({
  availableCategories
}: TemplateMessagesState) => availableCategories.items

export const templateMessagesLoader = ({
  templateMessages
}: TemplateMessagesState) => templateMessages.loader

export const templateMessages = ({ templateMessages }: TemplateMessagesState) =>
  templateMessages.items

export const confirmDeleteTemplateMessage = ({
  confirmDeleteTemplateMessage
}: TemplateMessagesState) => confirmDeleteTemplateMessage

export const formStep = ({ templateMessage }: TemplateMessagesState) =>
  templateMessage.step

export const formPayload = ({ templateMessage }: TemplateMessagesState) =>
  templateMessage.formPayload

export const isFormPayloadDirty = ({
  templateMessage
}: TemplateMessagesState) => {
  const { initialFormPayload, formPayload } = templateMessage

  return JSON.stringify(formPayload) === JSON.stringify(initialFormPayload)
}

export const onlyAvailabilityChanged = ({
  templateMessage
}: TemplateMessagesState) => {
  const { initialFormPayload, formPayload } = templateMessage

  if (!initialFormPayload || !formPayload) return false

  const changes: { [key: string]: boolean } = {}

  type FormPayload = {
    [key: string]: any
  }

  Object.keys(initialFormPayload).forEach((key: string) => {
    changes[key] =
      JSON.stringify((initialFormPayload as FormPayload)[key]) !==
      JSON.stringify((formPayload as FormPayload)[key])
  })

  return Object.keys(changes).every(
    key => key === 'permissions' || changes[key] === false
  )
}

export const showDiscardContentAlert = ({
  templateMessage
}: TemplateMessagesState) => templateMessage.showDiscardContentAlert

export const showReview = ({ templateMessage }: TemplateMessagesState) =>
  templateMessage.showReview

export const showSuccessModal = ({ templateMessage }: TemplateMessagesState) =>
  templateMessage.showSuccessModal

export const chatFields = ({ chatFields }: TemplateMessagesState) => chatFields

export const personFields = ({ personFields }: TemplateMessagesState) =>
  personFields

export const organizationFields = ({
  organizationFields
}: TemplateMessagesState) => organizationFields

export const listPreviewTemplate = ({
  listPreviewTemplate
}: TemplateMessagesState) => listPreviewTemplate

export const validTemplateMessageName = ({
  templateMessage
}: TemplateMessagesState) => templateMessage.validTemplateMessageName
