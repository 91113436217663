import { objectId } from '@/modules/Bot/helpers/object-id'

const i18nTemplatesKey = 'bot.newChatBot.models.templates'

const storage = 'https://octadeskstorage.blob.core.windows.net/chat/chatoctaqa'

const qualifyLead = () => ({
  id: objectId(),
  analyticTemplateId: 'qualify-lead',
  title: `${i18nTemplatesKey}.qualifyLead.title`,
  name: 'qualifyLead',
  description: `${i18nTemplatesKey}.qualifyLead.description`,
  icon: 'QualifyLead'
})

const smartQualification = () => ({
  id: objectId(),
  analyticTemplateId: 'smart-qualification',
  title: `${i18nTemplatesKey}.smartQualification.title`,
  name: 'smartQualification',
  description: `${i18nTemplatesKey}.smartQualification.description`,
  icon: 'SmartQualification',
  featuresByPlan: ['botconditional']
})

const selfService = () => ({
  id: objectId(),
  analyticTemplateId: 'self-service',
  title: `${i18nTemplatesKey}.selfService.title`,
  name: 'selfService',
  description: `${i18nTemplatesKey}.selfService.description`,
  icon: 'SelfService',
  featuresByPlan: ['office-hours']
})

const connectWithSales = () => ({
  id: objectId(),
  analyticTemplateId: 'connect-with-sales',
  title: `${i18nTemplatesKey}.connectWithSales.title`,
  name: 'connectWithSales',
  description: `${i18nTemplatesKey}.connectWithSales.description`,
  icon: 'ConnectWithSales'
})

const takeOrderAndConnect = () => ({
  id: objectId(),
  analyticTemplateId: 'takeOrder-and-connect',
  title: `${i18nTemplatesKey}.takeOrderAndConnect.title`,
  name: 'takeOrderAndConnect',
  description: `${i18nTemplatesKey}.takeOrderAndConnect.description`,
  icon: 'TakeOrderAndConnect'
})

const preScheduling = () => ({
  id: objectId(),
  analyticTemplateId: 'pre-scheduling',
  title: `${i18nTemplatesKey}.preScheduling.title`,
  name: 'preScheduling',
  description: `${i18nTemplatesKey}.preScheduling.description`,
  icon: 'PreScheduling'
})

const facebookTemplates = [
  qualifyLead(),
  smartQualification(),
  {
    ...selfService(),
    preview: {
      'pt-BR': `${storage}/tccaacatta-5rBr822ghmcfI58OfFzOR.mp4`,
      'en-US': `${storage}/tccaacatta-5rBr822ghmcfI58OfFzOR.mp4`
    }
  },
  {
    ...connectWithSales(),
    preview: {
      'pt-BR': `${storage}/atahaaqatc-xGcYOXRUw8Kp17byrr0zp.mp4`,
      'en-US': `${storage}/atahaaqatc-xGcYOXRUw8Kp17byrr0zp.mp4`
    }
  },
  {
    ...takeOrderAndConnect(),
    preview: {
      'pt-BR': `${storage}/cqqhattaat-fU8W59PQmkPs4dTV1nwnV.mp4`,
      'en-US': `${storage}/cqqhattaat-fU8W59PQmkPs4dTV1nwnV.mp4`
    }
  },
  {
    ...preScheduling(),
    preview: {
      'pt-BR': `${storage}/ctchtahctt-4MCN-t03yD18YyRrD7KEW.mp4`,
      'en-US': `${storage}/ctchtahctt-4MCN-t03yD18YyRrD7KEW.mp4`
    }
  }
]

export default {
  templateList: {
    web: [
      {
        ...qualifyLead(),
        preview: {
          'pt-BR': `${storage}/985e7c20.mp4`,
          'en-US': `${storage}/9846ae60.mp4`
        }
      },
      {
        ...smartQualification(),
        preview: {
          'pt-BR': `${storage}/ahaaqhcaqa-uHRzp_392P5ePTRkjUnn1.mp4`,
          'en-US': `${storage}/ahaaqhcaqa-uHRzp_392P5ePTRkjUnn1.mp4`
        }
      },
      {
        ...selfService(),
        preview: {
          'pt-BR': `${storage}/cacathqttc-SRdRAjck9TsCw0u-6bLOC.mp4`,
          'en-US': `${storage}/cacathqttc-SRdRAjck9TsCw0u-6bLOC.mp4`
        }
      },
      {
        ...connectWithSales(),
        preview: {
          'pt-BR': `${storage}/aaacqtttta-2GUwThFDwA0xBV1GjnAxn.mp4`,
          'en-US': `${storage}/aaacqtttta-2GUwThFDwA0xBV1GjnAxn.mp4`
        }
      },
      {
        ...takeOrderAndConnect(),
        preview: {
          'pt-BR': `${storage}/chqtaaotqh-_4qSmbCWlVuGAtOtmNVRf.mp4`,
          'en-US': `${storage}/chqtaaotqh-_4qSmbCWlVuGAtOtmNVRf.mp4`
        }
      },
      {
        ...preScheduling(),
        preview: {
          'pt-BR': `${storage}/coaaattacc-CyfMt6sRq9W_yorN6QVCd.mp4`,
          'en-US': `${storage}/coaaattacc-CyfMt6sRq9W_yorN6QVCd.mp4`
        }
      }
    ],
    whatsapp: [
      {
        ...qualifyLead(),
        preview: {
          'pt-BR': `${storage}/265164901cf9.mp4`,
          'en-US': `${storage}/265164901cf9.mp4`
        }
      },
      smartQualification(),
      {
        ...selfService(),
        preview: {
          'pt-BR': `${storage}/ahaoccaoaq-examMjd8sEV9cbNNLa23d.mp4`,
          'en-US': `${storage}/ahaoccaoaq-examMjd8sEV9cbNNLa23d.mp4`
        }
      },
      {
        ...connectWithSales(),
        preview: {
          'pt-BR': `${storage}/tcttatcctc-_zcHpwbqUv0zO7jgk39ny.mp4`,
          'en-US': `${storage}/tcttatcctc-_zcHpwbqUv0zO7jgk39ny.mp4`
        }
      },
      {
        ...takeOrderAndConnect(),
        preview: {
          'pt-BR': `${storage}/cctacctcqa-pXekl-Fmj6Wh3AITaoSiF.mp4`,
          'en-US': `${storage}/cctacctcqa-pXekl-Fmj6Wh3AITaoSiF.mp4`
        }
      },
      {
        ...preScheduling(),
        preview: {
          'pt-BR': `${storage}/haahccacto-PIzTeU7qf532Hypf-Cgve.mp4`,
          'en-US': `${storage}/haahccacto-PIzTeU7qf532Hypf-Cgve.mp4`
        }
      }
    ],
    'facebook-messenger': facebookTemplates,
    instagram: facebookTemplates
  },
  emptyTemplate: {
    id: objectId(),
    analyticTemplateId: 'empty-template',
    title: `${i18nTemplatesKey}.blank.title`,
    name: 'blank',
    description: `${i18nTemplatesKey}.blank.description`,
    icon: 'empty-template'
  }
}
