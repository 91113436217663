import { ContactForm, ContactFormSelected, TalktousState } from './interfaces'

export const forms = ({ forms }: TalktousState): ContactForm[] => forms

export const formSelected = ({
  formSelected
}: TalktousState): ContactFormSelected => formSelected

export const area = ({ area }: TalktousState): string => area

export const idLastForm = ({ idLastForm }: TalktousState): string => idLastForm

export const formsLoading = ({ formsLoading }: TalktousState): boolean =>
  formsLoading

export const formSelectedLoading = ({ formSelected }: TalktousState): boolean =>
  formSelected.loading

export const embeddedCode = ({ formSelected }: TalktousState): string =>
  formSelected.embeddedCode
