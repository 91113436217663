export default {
  commerce: {
    catalog: {},
    catalogLoader: false,
    products: {
      loader: false,
      items: [],
      filteredItems: []
    }
  }
}
