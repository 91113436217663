import { AxiosInstance } from 'axios'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import { MetaAdsWhatsAppNumbers } from './store/interfaces'

export class MetaAdsService {
  getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async getWhatsAppNumbers(): Promise<Array<MetaAdsWhatsAppNumbers>> {
    const { data } = await this.getClient('chatIntegratorUrl').then(client =>
      client.get('/whatsapp/numbers', {
        ...legacyHeaders()
      })
    )

    return data as Array<MetaAdsWhatsAppNumbers>
  }
}
