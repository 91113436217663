import { TicketVoiceState, TicketVoiceSettings } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_TICKET_VOICE_PROVIDER_SETTINGS](
    state: TicketVoiceState,
    settings: TicketVoiceSettings
  ) {
    state.providerSettings = settings
  },
  [types.SET_TICKET_VOICE_PROVIDERS](
    state: TicketVoiceState,
    providers: string[]
  ) {
    state.providers = providers
  },
  [types.SET_TICKET_VOICE_SETTINGS](
    state: TicketVoiceState,
    settings: TicketVoiceSettings[]
  ) {
    state.settings = settings
  },
  [types.SET_TICKET_VOICE_BASE_URL](state: TicketVoiceState, baseURL: string) {
    state.baseURL = baseURL
  }
}
