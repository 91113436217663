import { OrganizationState } from '@/store/interfaces'

export const organizations = (state: OrganizationState) => state.organizations

export const organizationsCounter = (state: OrganizationState) =>
  state.organizationsCounter

export const organizationMembers = (state: OrganizationState) =>
  state.organizationMembers

export const organizationMembersCount = (state: OrganizationState) =>
  state.organizationMembersCount
