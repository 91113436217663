export enum whatsappConnectionStatus {
  connecting = -1,
  disconnected = 1,
  connected = 2,
  recentConnected = 2
}

export enum EVENTS {
  REMOVE_WHATSAPP_NUMBER = 'remove whatsapp number',
  CHANNEL_SETUP = 'channel setup'
}
