import Vue, {
  CreateElement,
  FunctionalComponentOptions,
  RenderContext
} from 'vue'

type Props = {
  feature: string
  negate: boolean
}

const component: FunctionalComponentOptions<Props> = {
  name: 'FeatureRender',
  functional: true,
  props: {
    feature: {
      type: String,
      required: true
    },
    negate: Boolean
  },
  // @ts-ignore
  render(_createElement: CreateElement, context: RenderContext<Props>) {
    const getters = context.parent.$store.getters

    let result

    if (context.props.feature === 'whitelabel') {
      result = getters.isTrayWhitelabel
    } else {
      result = getters.isFeatureActive(context.props.feature)
    }

    const shouldRender = context.props.negate ? !result : result

    return shouldRender ? context.scopedSlots.default(context.data) : undefined
  }
}

// @ts-ignore
Vue.component('feature-render', component)

export default component
