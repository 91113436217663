import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import { getClient } from '@/common/services/http'

import legacyHeaders from './legacy-headers'

export class WhatsAppOfficialService {
  private static instance: WhatsAppOfficialService

  public static getInstance(): WhatsAppOfficialService {
    if (!this.instance) {
      this.instance = new WhatsAppOfficialService()
    }

    return this.instance
  }

  private getClient(): Promise<AxiosInstance> {
    return getClient('creditManagement')
  }

  private _chatClient?: AxiosInstance

  private async getChatClient(): Promise<AxiosInstance> {
    if (!this._chatClient) {
      this._chatClient = (await services.chat.getClient()) as AxiosInstance
    }

    return this._chatClient
  }

  public async getBalance(): Promise<number> {
    try {
      const client = await this.getChatClient()

      const { data } = await client.get(
        '/core/plans/availability?filter=whatsappApiTemplateMessagePlan',
        {
          ...legacyHeaders(),
          timeout: 500
        }
      )

      return data?.whatsapp_api_template_messages?.available || 0
    } catch (error) {
      return 9999
    }
  }

  async getCreditsBalance() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(`/balance?products=WHATSAPP,GENERAL`, {
          ...legacyHeaders()
        })
      )

      return { whatsapp: data?.WHATSAPP || 0, general: data?.GENERAL || 0 }
    } catch (error) {
      console.error(error)

      throw new Error(`Error on getCreditsBalance: ${error}`)
    }
  }
}
