import { WebhookState, WebhooksStateInfo } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_WEBHOOK_DATA](
    state: WebhookState,
    data: Array<WebhooksStateInfo>
  ) {
    state.webhooks = data
    state.hasChangedWebhooksList = false
  },
  [types.CREATE_WEBHOOK](state: WebhookState, emptyWebhook: WebhooksStateInfo) {
    state.webhooks = state.webhooks.concat(emptyWebhook)
  },
  [types.UPDATE_WEBHOOK_EVENT_NAME](
    state: WebhookState,
    payload: Array<WebhooksStateInfo>
  ) {
    state.webhooks = payload
  },
  [types.UPDATE_WEBHOOK_URL](
    state: WebhookState,
    payload: Array<WebhooksStateInfo>
  ) {
    state.webhooks = payload
  },
  [types.REMOVE_WEBHOOK](
    state: WebhookState,
    payload: Array<WebhooksStateInfo>
  ) {
    state.webhooks = payload
  },
  [types.SET_HAS_CHANGED_LIST](state: WebhookState) {
    state.hasChangedWebhooksList = true
  }
}
