import { AxiosInstance } from 'axios'

import { createClient } from './http'

export default class ZapierService {
  private baseUrl: string
  private http: AxiosInstance
  constructor() {
    this.baseUrl = 'https://hooks.zapier.com/hooks/catch'
    this.http = createClient({ baseURL: this.baseUrl })
  }

  async postWebhook(payload: any, firstId: string, secondId: string) {
    try {
      const url = `/${firstId}/${secondId}`
      const options = {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
      const { data } = await this.http.post(url, payload, options)
      return data
    } catch (ex) {
      throw new Error('Error at zapier post webhook' + ex)
    }
  }
}
