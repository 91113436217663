import types from '@/modules/Bot/store/mutations-types'

export default {
  [types.SET_PERSON_FIELDS](state, fields) {
    state.personFields = fields
  },
  [types.SET_CHAT_FIELDS](state, fields) {
    state.chatFields = fields
  },
  [types.SET_ORGANIZATION_FIELDS](state, fields) {
    state.organizationFields = fields
  },
  [types.SET_CREATE_LOADER](state, isLoader) {
    state.createCustomFieldLoaderActive = isLoader
  }
}
