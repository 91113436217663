export default {
  fetchedInitialData: false,
  connectedSocket: false,
  agent: undefined,
  agents: [],
  groups: [],
  restrictedGroups: [],
  notification: false,
  miniClusterStatus: undefined,
  onlineAgents: [],
  allChatsCount: undefined,
  owners: [],
  whatsappNumbers: [],
  restrictedWhatsappNumbers: [],
  integratorWhatsappNumbers: [],
  settings: undefined,
  integrators: [],
  tryCreateRoomData: undefined,
  selectTemplateChat: undefined,
  responsive: {
    showMobileChatSidebar: false,
    showMobileChatMain: false,
    showMobileUserInformations: true
  }
}
