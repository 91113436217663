const getCookie = name => {
  if (typeof window !== 'undefined') {
    const value = '; ' + window.document.cookie
    const parts = value.split('; ' + name + '=')
    if (parts.length == 2) return parts.pop().split(';').shift()
  }
}

const setCookie = (name, value) => {
  window.document.cookie = `${name}=${value};`
}

export default {
  getCookie,
  setCookie
}
