import { services } from '@octadesk-tech/services'

import legacyHeaders from '@/common/services/legacy-headers'

export class GlobalService {
  async getChatClient() {
    return await services.chat.getClient()
  }

  async getChatAgentsClient() {
    return await services.chatAgents.getClient()
  }

  async getGroups() {
    return this.getChatClient()
      .then(client => client.get('groups', legacyHeaders()))
      .then(res => res.data)
  }

  async getAgents() {
    return this.getChatAgentsClient()
      .then(client => client.get('', legacyHeaders()))
      .then(res => res.data)
  }
}
