import advertsRoutes from './Adverts/routes'
import automationStudio from './AutomationStudio/routes'
import botRoutes from './Bot/routes'
import botBuilderRoutes from './BotBuilder/routes'
import chatRoutes from './Chat/routes'
import chatSettingsRoutes from './ChatSettings/routes'
import commerceRoutes from './Commerce/routes'
import dashboardRoutes from './Dashboard/routes'
import octaStoreRoutes from './OctaStore/routes'
import onboardingRoutes from './Onboarding/routes'
import ticketRoutes from './Ticket/routes'
import wozRoutes from './Woz/routes'

export default [
  advertsRoutes,
  botRoutes,
  botBuilderRoutes,
  automationStudio,
  ...chatRoutes,
  chatSettingsRoutes,
  commerceRoutes,
  dashboardRoutes,
  onboardingRoutes,
  ...ticketRoutes,
  wozRoutes,
  octaStoreRoutes
]
