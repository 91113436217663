import types from '@/modules/Bot/store/mutations-types'

export default {
  [types.SET_ACTIVE_BREADCRUMB_STEP](state, stepName) {
    state.breadcrumbSteps = state.breadcrumbSteps.map(step => ({
      ...step,
      active: step.name === stepName
    }))
  }
}
