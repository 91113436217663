export const SET_COMMERCE_CLIENT_ORDERS_LOADER =
  'SET_COMMERCE_CLIENT_ORDERS_LOADER'

export const SET_COMMERCE_CLIENT_ORDER_PRODUCTS_LOADER =
  'SET_COMMERCE_CLIENT_ORDER_PRODUCTS_LOADER'

export const SET_COMMERCE_CLIENT_ORDERS = 'SET_COMMERCE_CLIENT_ORDERS'

export const SET_COMMERCE_CLIENT_ORDERS_NOT_FOUND =
  'SET_COMMERCE_CLIENT_ORDERS_NOT_FOUND'

export const SET_COMMERCE_CLIENT_ORDER_PAYMENT_URL =
  'SET_COMMERCE_CLIENT_ORDER_PAYMENT_URL'
