import * as types from './mutations-types'

export default {
  [types.SET_IS_ENABLE_NUMBER_MODAL_OPEN](state: any, value: any) {
    state.isEnableNumberModalOpen = value
  },
  [types.SET_ENABLE_NUMBER_CURRENT_STEP](state: any, value: any) {
    state.enableNumberCurrentStep = value
  },
  [types.SET_ENABLE_NUMBER_STEP_PAYLOAD](state: any, { name, payload }: any) {
    state[`enableNumber${name}Payload`] = payload
  },
  [types.CLEAR_ENABLE_NUMBER_STEP_PAYLOADS](state: any) {
    Object.keys(state)
      .filter(key => /enableNumber[A-Z][A-z]*?Payload/.test(key))
      .forEach(key => (state[key] = undefined))
  },
  [types.SET_EDITABLE_NUMBER_PROFILE](state: any, value: any) {
    state.editableNumberProfile = value
  },
  [types.SET_INTEGRATOR_ACCOUNT_STATUS](state: any, value: any) {
    state.integratorAccountStatus = value
  }
}
