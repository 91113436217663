import { getOrganizations } from '@/modules/Chat/components/conversation/services/person'

export const organizations = (searchValue?: string) =>
  getOrganizations(
    `?search=${searchValue}&textSearch=true&take=15&skip=0`
  ).then(organizations =>
    organizations.map((organization: { id: string; name: string }) => ({
      id: organization.id,
      name: organization.name
    }))
  )
