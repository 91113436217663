import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import legacyHeaders from '@/common/services/legacy-headers'

import { SurveyConfig } from './store/interfaces'

const SURVEY_PATH = '/survey-config'

export class SurveyService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.createClient('ticket')) as AxiosInstance
  }

  async createConfiguration() {
    try {
      const client = await this.getClient()
      const { data } = await client.post<SurveyConfig>(
        SURVEY_PATH,
        {},
        legacyHeaders()
      )
      return data
    } catch (ex) {
      throw new Error(
        `Error in create survey configuration: ${
          (ex as any).response?.data?.message
        }`
      )
    }
  }

  async changeConfiguration(config: SurveyConfig) {
    try {
      const client = await this.getClient()
      const { data } = await client.put<SurveyConfig>(
        SURVEY_PATH,
        config,
        legacyHeaders()
      )
      return data
    } catch (ex) {
      throw new Error(
        `Error in change survey configuration: ${
          (ex as any).response?.data?.message
        }`
      )
    }
  }

  async getConfiguration() {
    try {
      const client = await this.getClient()
      const { data } = await client.get<SurveyConfig>(
        SURVEY_PATH,
        legacyHeaders()
      )
      return data
    } catch (ex) {
      throw new Error(
        `Error in get survey configuration: ${
          (ex as any).response?.data?.message
        }`
      )
    }
  }
}
