import { EmailState } from './interfaces'

export const redirectEmailAddress = ({ redirectEmailAddress }: EmailState) =>
  redirectEmailAddress

export const getCurrentEmailRedirectIntegration =
  ({ settings }: any) =>
  (redirectedEmail: string) => {
    if (!redirectedEmail) return null

    const redirections = settings?.integrations?.email?.redirections || []

    return redirections.find(
      (redir: any) => redir.redirectedEmail === redirectedEmail
    )
  }
