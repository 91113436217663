import { IntegrationsCommerceState } from './interfaces'

export const commerceIntegrationsItems = (state: IntegrationsCommerceState) =>
  state.items

export const commerceConnectIntegrationCurrentStep = (
  state: IntegrationsCommerceState
) => state.connect.currentStep

export const commerceConnectIntegrationAvailableIntegrations = (
  state: IntegrationsCommerceState
) => state.availableIntegrations

export const commerceConnectIntegrationFields = (
  state: IntegrationsCommerceState
) => state.connect
