import { FeatureFlags, FeatureToggleState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_FEATURE_FLAGS](
    state: FeatureToggleState,
    featureFlags: FeatureFlags
  ) {
    state.featureFlags = featureFlags
  }
}
