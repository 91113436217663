import dayjs from 'dayjs'
import Vue from 'vue'

import Storage from '@octadesk-tech/storage'

import { arrangeChat } from '@/modules/Chat/components/conversation/services/chat-arranger'

import store from '@/store'

import * as types from './mutations-types'

// eslint-disable-next-line
const updateChatList = (rooms, state, options) => {
  if (!rooms || !rooms.length) return

  const { remove, upsert } = options || {}

  const stateChats = state.chats || []

  let chats = [...stateChats]

  const roomKeysToRemove = []

  rooms.forEach(function (room) {
    const roomIndex = state.chatsHashMap[room.key]

    if (remove) {
      roomKeysToRemove.push(room.key)
    } else {
      if (store.getters?.getChatKeysChecked?.includes(room.key))
        Vue.set(room, 'checked', true)

      if (typeof roomIndex === 'number' && roomIndex >= 0) {
        Vue.set(chats, roomIndex, arrangeChat(room, chats[roomIndex]))
      } else if (upsert) {
        const arrangedRoom = arrangeChat(room)

        chats.push(arrangedRoom)
      }
    }
  })

  if (roomKeysToRemove.length) {
    chats = chats.filter(chat => !roomKeysToRemove.includes(chat.key))
  }

  sortChats(chats, state)
}

const sortChats = (chats, state) => {
  const { sort } = {
    ...state.filter
  }

  let directionToSort = 'desc'

  if (sort?.direction) directionToSort = sort.direction

  chats = chats.sort((current, next) => {
    const currentLastMessageDate = dayjs(current.lastMessageDate)

    const currentLastEventDate =
      current?.lastEventDate && dayjs(current?.lastEventDate)

    const currentDate =
      (currentLastEventDate?.isAfter(currentLastMessageDate) &&
        currentLastEventDate) ||
      currentLastMessageDate

    const nextLastMessageDate = dayjs(next.lastMessageDate)

    const nextLastEventDate = next?.lastEventDate && dayjs(next?.lastEventDate)

    const nextDate =
      (nextLastEventDate?.isAfter(nextLastMessageDate) && nextLastEventDate) ||
      nextLastMessageDate

    return currentDate.isBefore(nextDate) ? -1 : 1
  })

  if (directionToSort == 'desc') chats = chats.reverse()

  const limit = state.page * 20

  const slicedChats = chats.length > limit ? chats.slice(0, limit) : chats

  state.chatsHashMap = slicedChats.reduce((acc, curr, index) => {
    acc[curr.key] = index
    return acc
  }, {})

  state.chats = slicedChats

  state.chatsLength = state.chats.length
}

const CACHED_FILTER_KEY = 'chat-selected-filter-v0.1'

const getCachedFilterKey = () => {
  const { company, agent } = store.getters

  if (agent) {
    return CACHED_FILTER_KEY + ':' + (agent.id || '')
  } else {
    return (company || '') + ':' + CACHED_FILTER_KEY
  }
}

const cacheFilter = state => {
  const filterToCache = {
    selectedFilter: state.selectedFilter,
    filter: state.filter,
    filterName: state.filterName
  }

  Storage.setItem(getCachedFilterKey(), filterToCache)
}

const clearCachedFilter = () => {
  Storage.removeItem(getCachedFilterKey())
}

export default {
  [types.SET_SELECTED_SIDEBAR_TAB](state, selectedSidebarTab) {
    state.selectedSidebarTab = selectedSidebarTab
  },
  [types.SET_STATUS](state, status) {
    if (state.status !== status) {
      state.status = status
    }
  },
  [types.SET_CHATS](state, rooms) {
    state.chatsLength = 0

    const arrangedChats = rooms.map(c => arrangeChat(c))

    sortChats(arrangedChats, state)
  },
  [types.APPEND_CHATS](state, rooms) {
    updateChatList(rooms, state, { upsert: true })
  },
  [types.UPDATE_CHAT](state, room) {
    updateChatList([room], state)
  },
  [types.APPEND_CHAT](state, room) {
    updateChatList([room], state, { upsert: true })
  },
  [types.REMOVE_CHAT](state, room) {
    updateChatList([room], state, { remove: true })
  },
  [types.UPDATE_CHAT_LIST_PROPERTIES](state, { chatKey, props }) {
    const propKeys = Object.keys(props)
    if (!propKeys.length)
      throw new Error(
        `Missing parameter 'props' in '${types.UPDATE_CHAT_LIST_PROPERTIES}'`
      )

    const updatePropKeys = chat =>
      propKeys.forEach(key => Vue.set(chat, key, props[key]))

    if (!chatKey) return state.chats.forEach(chat => updatePropKeys(chat))

    const chat = state.chats.find(chat => chat.key == chatKey)

    if (chat) updatePropKeys(chat)
  },
  [types.SET_CHATS_FILTER](state, { filter, name }) {
    state.filterName = name

    if (filter.remove) {
      if (filter.remove.includes('clear-all')) {
        state.filter = {}
      } else if (state.filter) {
        filter.remove.forEach(r => delete state.filter[r])
      }
    }

    state.filter = {
      ...state.filter,
      ...filter.filter
    }

    cacheFilter(state)
  },
  [types.SET_WAITING_COUNT](state, count) {
    state.waitingChats = count
  },
  [types.SET_UNREDEDCHATS](state, count) {
    state.unrededChats = count
  },
  [types.SET_SEARCH_RESULT](state, result) {
    state.page = result.page
    state.hasMorePages = result.hasMorePages
  },
  [types.SET_IS_SEARCHING](state, toggle) {
    state.searchStatus = toggle
  },
  [types.SET_IS_LOADING_MORE](state, toggle) {
    state.isLoadingMore = toggle
  },
  [types.SET_PAGE](state, page) {
    state.page = page
  },
  [types.TOGGLE_FILTER_PANEL](state) {
    state.showFilterPanel = !state.showFilterPanel
  },
  [types.LOAD_CACHED_FILTER](state) {
    const chatSelectedFilter = Storage.getItem(getCachedFilterKey())

    for (const key in chatSelectedFilter) {
      if (chatSelectedFilter[key]) {
        state[key] = chatSelectedFilter[key]

        if (key === 'selectedFilter') {
          state.temporaryFilter = chatSelectedFilter[key]
        }
      }
    }
  },
  [types.SET_TEMPORARY_FILTER](state, filter) {
    state.temporaryFilter = {
      ...state.temporaryFilter,
      ...filter
    }
  },
  [types.SET_SELECTED_FILTER](state) {
    state.selectedFilter = {
      ...state.temporaryFilter
    }
    cacheFilter(state)
  },
  [types.CLEAN_FILTERS](state) {
    state.selectedFilter = {}

    state.temporaryFilter = {}

    clearCachedFilter()
  },
  [types.SET_PHONE_CONTACTS](state, phoneContacts) {
    state.phoneContacts = [...phoneContacts]
  },
  [types.UPDATE_PHONE_CONTACT_PROPERTY](state, { contactId, props }) {
    const propKeys = Object.keys(props)
    if (!propKeys.length)
      throw new Error(
        `Missing parameter 'props' in '${types.UPDATE_PHONE_CONTACT_PROPERTY}'`
      )

    const updatePropKeys = object =>
      propKeys.forEach(key => Vue.set(object, key, props[key]))

    if (!contactId)
      return state.phoneContacts.forEach(contact => updatePropKeys(contact))

    const contact = state.phoneContacts.find(contact => contact.id == contactId)
    updatePropKeys(contact)
  },
  [types.SET_HAS_MORE_CONTATCS](state, hasMore) {
    state.hasMoreContacts = hasMore
  },
  [types.SET_CONTACT_PAGE](state, page) {
    state.contactPage = page
  },
  [types.SET_BOTS_FLUXES](state, botFluxes) {
    state.botFluxes = botFluxes
  },
  [types.SET_HAS_CHAT_CHANNEL](state, channelUsage) {
    if (!state.channelsUsage.some(ch => ch.channel === channelUsage.channel))
      state.channelsUsage.push(channelUsage)
  },
  [types.SET_USER_LOGGED_ASSIGNED_COUNT](state, count) {
    state.userLoggedAssignedCount = count
  },
  [types.SET_CURRENT_CONTACT](state, currentContact) {
    state.currentContact = currentContact
  },
  [types.SET_CURRENT_INBOX](state, payload) {
    const { inboxName, agentId } = payload

    state.currentInbox = {
      inboxName,
      agentId
    }
  },
  [types.SET_CONVERSATIONS_LIST_TYPE](state, listType) {
    state.conversationsListType = listType
  },
  [types.SET_LOADING_CONVERSATION_LIST](state, inProgress) {
    state.loadingConversationList = inProgress
  },
  [types.SET_QTD_SELECTED_FILTERS](state, qtdSelectedFilters) {
    state.qtdSelectedFilters = qtdSelectedFilters
  },
  [types.FETCHING_CHAT_LIST](state, fetchingChatList) {
    state.fetchingChatList = fetchingChatList
  },
  [types.SET_RESPONSE_SERVICE_TIMEOUT_COUNT](state, fetchingChatList) {
    state.responseServiceTimeoutCount = fetchingChatList
  },
  [types.UPDATE_SELECTED_FILTERS](state) {
    state.updateSelectedFilters++
  },
  [types.SET_RESPONSE_SERVICE_TIMEOUT_INBOX](state, fetchingChatList) {
    state.responseServiceTimeoutCount = fetchingChatList
  }
}
