import { ContactState } from '@/store/interfaces'

export const contacts = (state: ContactState) => state.contacts

export const contact = (state: ContactState) => state.contact

export const contactsCounter = (state: ContactState) => state.contactsCounter

export const fetchingPaginatedContacts = (state: ContactState) =>
  state.fetchingPaginatedContacts

export const agents = (state: ContactState) => state.agents

export const ascendingOrderedAgents = (state: ContactState) =>
  structuredClone(state?.agents)?.sort((a, b) => a.name.localeCompare(b.name))

export const groupAgents = (state: ContactState) => state.groupAgents

export const contactStatusList = (state: ContactState) =>
  state.contactStatusList

export const totalContacts = (state: ContactState) => state.totalContacts
