import configs from '@/common/helpers/configs'
import { OctaConfig } from '@/common/helpers/interfaces/octa-config'

const octaConfig: () => OctaConfig = () => {
  window.octadesk = window.octadesk || {}

  window.octadesk.apis = { ...configs.apis }

  window.octadesk = window.octadesk || {}

  window.octadesk.prefix = configs.prefix.replace('prd', '')

  return configs
}

export default octaConfig
