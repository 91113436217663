import { PermissionsCommerceState } from './interfaces'

export const commercePermissionsCurrentStep = (
  state: PermissionsCommerceState
) => state.currentStep

export const commerceWhatsAppNumbers = (state: PermissionsCommerceState) =>
  state.whatsappNumbers

export const commerceFacebookBusinessAccounts = (
  state: PermissionsCommerceState
) => state.facebook.businessAccounts

export const commerceFacebookToken = (state: PermissionsCommerceState) =>
  state.facebook.token

export const commerceFacebookPermissionsError = (
  state: PermissionsCommerceState
) => state.facebook.permissionsError

export const commerceFacebookTwoFactoryStep = (
  state: PermissionsCommerceState
) => state.facebook.twoFactoryStep

export const commerceFacebookSelectedAccount = (
  state: PermissionsCommerceState
) => state.facebook.selectedAccount

export const commerceCreateCatalogError = (state: PermissionsCommerceState) =>
  state.createCatalogError

export const commerceIsSyncCatalog = (
  state: PermissionsCommerceState,
  getters: any,
  rootActions: any,
  rootGetters: any
) => {
  return (
    state.whatsappNumbers.length &&
    rootGetters['commerceCatalogs/commerceHasCatalog'] &&
    !rootGetters['commerceCatalogs/commerceCatalog']?.integrations?.length
  )
}

export const commerceCatalogName = (
  state: PermissionsCommerceState,
  { isTrayWhitelabel }: any
) => {
  if (isTrayWhitelabel) {
    return 'ChatCommerce'
  }

  return 'OctaCommerce'
}
