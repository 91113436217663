import { ContactState } from '@/store/interfaces'

const state: ContactState = {
  contacts: [],
  contact: { id: '' },
  agents: [],
  groupAgents: undefined,
  contactsCounter: 0,
  fetchingPaginatedContacts: false,
  contactStatusList: [],
  totalContacts: 0
}

export default state
