import { services, headers } from '@octadesk-tech/services'

let _clientBot

const getBotsClient = async () => {
  if (_clientBot) {
    return _clientBot
  }
  return (_clientBot = await services.chatBots.getClient())
}

let _clientChat

const getChatClient = async () => {
  if (_clientChat) {
    return _clientChat
  }
  return (_clientChat = await services.chat.getClient())
}

export const getBots = async (channel, version) => {
  const { data } = await getChatClient().then(client =>
    client.get(
      `flux/slim/bot?channel=${channel}&version=${version}`,
      headers.getAuthorizedHeaders()
    )
  )

  return data
}

export const searchBots = async (domainType, area, keyword) =>
  getBotsClient()
    .then(client =>
      client.get(
        `search?domainType=${domainType}&availableFor=${area}&keyword=${keyword}`,
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)

export const getBot = idBot =>
  getBotsClient()
    .then(client => client.get(`${idBot}`, headers.getAuthorizedHeaders()))
    .then(res => res.data)

export const createBot = bot =>
  getBotsClient()
    .then(client => client.post('', bot, headers.getAuthorizedHeaders()))
    .then(res => res.data)

export const updateBot = ({ botId, bot, validator }) =>
  getBotsClient()
    .then(client =>
      client.put(`${botId}`, { bot, validator }, headers.getAuthorizedHeaders())
    )
    .then(res => res.data)

export const deleteBot = idBot =>
  getBotsClient().then(client =>
    client
      .delete(`${idBot}`, headers.getAuthorizedHeaders())
      .then(res => res.data)
  )

export const validate = (action, payload) =>
  getBotsClient()
    .then(client =>
      client.post(
        '/validate/' + action,
        payload,
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)

export const duplicateBot = ({ botId, validator }) =>
  getBotsClient()
    .then(client =>
      client.post(
        `${botId}/duplicate`,
        { validator },
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)

export const migrateBot = ({ botId, validator }) =>
  getBotsClient()
    .then(client =>
      client.post(
        `${botId}/migrate`,
        { validator },
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)

export const botsWithOfficeHours = async officeHoursId => {
  try {
    const response = await getBotsClient().then(client =>
      client.get(
        `/using-action/office-hours?contentProperty=calendarId&contentValue=${officeHoursId}`,
        headers.getAuthorizedHeaders()
      )
    )

    if (response?.data) {
      return response.data
    }

    return false
  } catch (err) {
    return false
  }
}

export const getBotSidebarPreSets = () =>
  getBotsClient()
    .then(client =>
      client.get(
        'templates/actions?newOcta=true',
        headers.getAuthorizedHeaders()
      )
    )
    .then(({ data }) => data)

export const getBotSpecifications = async () => {
  const { data } = await getChatClient().then(client =>
    client.get(`integrator?active=true`, headers.getAuthorizedHeaders())
  )

  return data
}

export const getValidateWarnings = ({ botId, component, validator }) => {
  return getBotsClient()
    .then(client =>
      client.post(
        `${botId}/validate-component`,
        { component, validator },
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)
}
