import { getCookie, setCookie } from '@/common/helpers/utils/cookie'
import { objectId } from '@/common/helpers/utils/object-id'

export const getAnonymousId = () => {
  const cookie = getCookie('octa-anonymous-id')
  if (cookie) {
    return cookie
  } else {
    const id = objectId()
    setCookie('octa-anonymous-id', id)
    return id
  }
}
