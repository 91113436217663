import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'

import { url as URLResolver } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

let httpLink: any

const setHttpLink = async () => {
  try {
    const url = await URLResolver.getAPIURL('ticket').then(url => url)
    httpLink = createHttpLink({
      uri: url + '/api/graphql/query'
    })
  } catch {
    httpLink = createHttpLink({
      uri: Storage.getItem('octa_api_url_ticket') + '/api/graphql/query'
    })
  }
}

const authLink = setContext((_, { headers }) => {
  const token = Storage.getItem('userToken')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })
}

export { setHttpLink, getApolloClient }
