import { WhatsAppState } from './interfaces'
import moduleState from './modules/state'

const state: WhatsAppState = {
  whatsappOfficialNumbers: [],
  companyModel: undefined,
  isWppActiveNumber: false,
  migration: {
    number: undefined,
    numberValidationData: {
      wabaID: undefined,
      countryCode: '',
      phoneNumber: '',
      codeMethod: 'SMS'
    },
    phoneNumberId: ''
  },
  ...moduleState
}

export default state
