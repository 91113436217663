import { parsePhoneNumberFromString } from 'libphonenumber-js'

const parse = phone => {
  if (!phone) return undefined

  if (phone.nationalNumber) return phone

  if (phone.countryCode && phone.number)
    phone = `+${phone.countryCode}${phone.number}`
  else if (phone.number) phone = phone.number

  phone = phone.toString()

  if (!phone.startsWith('+')) {
    phone = phone.replace(/\D/g, '')
  }

  return parsePhoneNumberFromString(phone)
}

const format = (phone, region) => {
  const parsedPhone = parse(phone)

  if (parsedPhone) {
    return region === 'national'
      ? parsedPhone.formatNational()
      : parsedPhone.formatInternational()
  }

  return phone
}

const validate = phone => {
  const libPhone = parse(phone)
  const valid = libPhone && libPhone.isValid()

  return { valid, data: libPhone }
}

const normalizePhoneNumber = phone => {
  return `${phone}`.replace(/^\+?/g, '+')
}

export default {
  validate,
  parse,
  format,
  parsePhoneNumberFromString,
  normalizePhoneNumber
}
