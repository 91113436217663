import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import legacyHeaders from '@/common/services/legacy-headers'

import { TicketVoiceSettings } from './store/interfaces'

export class TicketVoiceService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.createClient('ticketVoice')) as AxiosInstance
  }

  async getProviders() {
    return this.getClient()
      .then(client =>
        client.get<string[]>('settings/providers', legacyHeaders())
      )
      .then(res => res.data)
  }

  async getBaseURL() {
    return this.getClient().then(client => client.defaults.baseURL)
  }

  async getSettings() {
    return this.getClient()
      .then(client =>
        client.get<TicketVoiceSettings[]>('settings', legacyHeaders())
      )
      .then(res => res.data)
  }

  async getSetting(provider: string) {
    return this.getClient()
      .then(client =>
        client.get<TicketVoiceSettings | undefined>(
          `settings/${provider}`,
          legacyHeaders()
        )
      )
      .then(res => res.data)
  }

  async saveSettings(settings: TicketVoiceSettings) {
    const client = await this.getClient()

    const response = await client.post<TicketVoiceSettings>(
      'settings',
      settings,
      legacyHeaders()
    )

    return response.data
  }
}
