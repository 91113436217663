import dayjs from 'dayjs'

import * as services from '@/modules/Chat/components/conversation/services/reminder'

import i18n from '@/common/i18n'

import * as types from './mutation-types'

const sucessAlert = (context, message, messageParams) => {
  context.dispatch('clearGlobalAlerts', {}, { root: true })

  context.dispatch(
    'dispatchAlert',
    {
      body: i18n?.t(
        message,
        Array.isArray(messageParams) ? messageParams : [messageParams]
      ),
      variant: 'success',
      duration: 10000 // 10 seconds
    },
    { root: true }
  )
}

const failAlert = (context, message) => {
  context.dispatch('clearGlobalAlerts', {}, { root: true })

  context.dispatch(
    'dispatchAlert',
    {
      body: i18n?.t('chat.conversation.reminder.alerts.failMessage'),
      variant: 'danger',
      duration: 10000 // 10 seconds
    },
    { root: true }
  )

  console.error(message)
}

const loadRequest = (context, key) => {
  context.dispatch('clearGlobalAlerts', {}, { root: true })

  context.dispatch(
    'dispatchAlert',
    {
      body: i18n?.t(`chat.conversation.reminder.alerts.${key}`),
      variant: 'info',
      duration: null
    },
    { root: true }
  )

  context.commit(types.SET_REMINDER_CRUD_REQUESTING, true)
}

export const createReminder = async (
  context,
  { data, updateCurrentConversationReminder } = {}
) => {
  context.commit(types.SET_REMINDER_CRUD_REQUESTING, true)

  services
    .createReminder(data)
    .then(reminder => {
      if (updateCurrentConversationReminder && context.getters?.chatKey)
        context.dispatch('updateCurrentChatProperty', {
          chatKey: context.getters?.chatKey,
          props: { reminders: [reminder] }
        })

      const date = dayjs(data.date)

      sucessAlert(
        context,
        'chat.conversation.reminder.alerts.reminderCreated',
        [date.format('DD/MM/YYYY'), date.format('HH[h]mm')]
      )
    })
    .catch(e => {
      failAlert(context, `Error in reminder > actions > createReminder. ${e}`)
    })
    .finally(() => {
      context.commit(types.SET_REMINDER_CRUD_REQUESTING, false)
    })
}

export const updateReminder = async (
  context,
  { data, updateCurrentConversationReminder } = {}
) => {
  context.commit(types.SET_REMINDER_CRUD_REQUESTING, true)

  services
    .updateReminder(data)
    .then(reminder => {
      if (updateCurrentConversationReminder && context.getters?.chatKey)
        context.dispatch('updateCurrentChatProperty', {
          chatKey: context.getters?.chatKey,
          props: { reminders: [reminder] }
        })

      const date = dayjs(data.date)

      sucessAlert(
        context,
        'chat.conversation.reminder.alerts.reminderUpdated',
        [date.format('DD/MM/YYYY'), date.format('HH[h]mm')]
      )
    })
    .catch(e => {
      failAlert(context, `Error in reminder > actions > updateReminder. ${e}`)
    })
    // eslint-disable-next-line sonarjs/no-identical-functions
    .finally(() => {
      context.commit(types.SET_REMINDER_CRUD_REQUESTING, false)
    })
}

export const createUpdateReminder = async (context, payload) => {
  return !payload?.data?._id
    ? await createReminder(context, payload)
    : await updateReminder(context, payload)
}

export const deactivateReminder = async (
  context,
  { _id, chatKey, cleanCurrentConversationReminder = true } = {}
) => {
  loadRequest(context, 'deactivatingReminder')

  services
    .deactivateReminder(_id)
    .then(() => {
      if (cleanCurrentConversationReminder)
        context.dispatch('updateCurrentChatProperty', {
          chatKey,
          props: { reminders: [] }
        })

      sucessAlert(
        context,
        'chat.conversation.reminder.alerts.reminderDeactivated'
      )
    })
    .catch(e => {
      failAlert(
        context,
        `Error in reminder > actions > deactivateReminder. ${e}`
      )
    })
    .finally(() => {
      context.commit(types.SET_REMINDER_CRUD_REQUESTING, false)
    })
}

export const markReminderAsDone = async (
  context,
  { _id, chatKey, cleanCurrentConversationReminder = true } = {}
) => {
  loadRequest(context, 'markingReminderAsDone')

  services
    .markReminderAsDone(_id)
    .then(async () => {
      if (cleanCurrentConversationReminder)
        await context.dispatch('updateCurrentChatProperty', {
          chatKey,
          props: { reminders: [] }
        })

      await context.dispatch('updateChatListProperties', {
        chatKey,
        props: { reminders: [] }
      })

      sucessAlert(context, 'chat.conversation.reminder.alerts.reminderDone')
    })
    .catch(e => {
      failAlert(context)

      console.error(`Error in reminder > actions > markReminderAsDone. ${e}`)
    })
    .finally(() => {
      context.commit(types.SET_REMINDER_CRUD_REQUESTING, false)
    })
}
