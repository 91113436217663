const connectionMutations = {
  SET_CONNECTIONS: 'SET_CONNECTIONS',
  ADD_CONNECTION: 'ADD_CONNECTION',
  UPDATE_CONNECTION: 'UPDATE_CONNECTION',
  REMOVE_CONNECTION: 'REMOVE_CONNECTIOn'
}

const actionMutations = {
  SET_ACTIONS: 'SET_ACTIONS',
  ADD_ACTION: 'ADD_ACTION',
  UPDATE_ACTION: 'UPDATE_ACTION',
  REMOVE_ACTION: 'REMOVE_ACTION',
  SET_SELECTED_ACTION_BUTTON: 'SET_SELECTED_ACTION_BUTTON',
  SET_EDITING_ACTION: 'SET_EDITING_ACTION',
  SET_BRANCH_WARNING_MODAL: 'SET_BRANCH_WARNING_MODAL',
  REMOVE_EDITING_ACTION: 'REMOVE_EDITING_ACTION',
  SET_PROACTIVE_MESSAGE: 'SET_PROACTIVE_MESSAGE',
  UPDATE_ACTION_CONTENT_PROPERTY: 'UPDATE_ACTION_CONTENT_PROPERTY',
  UPDATE_ACTION_CONTENT_MEDIAS: 'UPDATE_ACTION_CONTENT_MEDIAS',
  ACTIONS_INVALID: 'ACTIONS_INVALID'
}

const personaMutations = {
  TOGGLE_EDITING_PERSONA: 'TOGGLE_EDITING_PERSONA',
  CLOSE_EDITING_PERSONA: 'CLOSE_EDITING_PERSONA',
  SET_PERSONA: 'SET_PERSONA'
}

const chatBotMutations = {
  ADD_CHAT_BOT: 'ADD_CHAT_BOT'
}

export default {
  ...personaMutations,
  ...connectionMutations,
  ...actionMutations,
  ...chatBotMutations,
  SET_BOT_UNSAVED_CHANGES: 'SET_BOT_UNSAVED_CHANGES',
  SET_EXITING_CONFIGURATION: 'SET_EXITING_CONFIGURATION',
  SET_SIDEBAR_EL_FOCUS: 'SET_SIDEBAR_EL_FOCUS',
  SET_BOT_OFFICE_HOURS: 'SET_BOT_OFFICE_HOURS',
  SET_CALENDAR_ID_IN_ACTION: 'SET_CALENDAR_ID_IN_ACTION',
  SET_INVALID_CONFIGURATION: 'SET_INVALID_CONFIGURATION',
  REMOVE_INVALID_CONFIGURATION: 'REMOVE_INVALID_CONFIGURATION',
  CLEAR_INVALID_CONFIGURATION: 'CLEAR_INVALID_CONFIGURATION'
}
