import { FormSteps } from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v2/template-messages.enum'

import {
  TemplateMessagesState,
  WhatsAppNumbers,
  TemplateMessageItems,
  ConfirmDeleteTemplateMessage,
  AvailableCategories,
  FormPayload,
  TemplateMessageFieldType,
  validTemplateMessageName
} from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_WHATSAPP_NUMBERS_LOADER](
    state: TemplateMessagesState,
    isLoader: boolean
  ) {
    state.whatsAppNumbers.loader = isLoader
  },
  [types.SET_WHATSAPP_NUMBERS](
    state: TemplateMessagesState,
    numbers: Array<WhatsAppNumbers>
  ) {
    state.whatsAppNumbers.items = numbers
  },
  [types.SET_AVAILABLE_CATEGORIES_LOADER](
    state: TemplateMessagesState,
    isLoader: boolean
  ) {
    state.availableCategories.loader = isLoader
  },
  [types.SET_AVAILABLE_CATEGORIES](
    state: TemplateMessagesState,
    availableCategories: AvailableCategories[]
  ) {
    state.availableCategories.items = availableCategories
  },
  [types.SET_TEMPLATE_MESSAGES_LOADER](
    state: TemplateMessagesState,
    isLoader: boolean
  ) {
    state.templateMessages.loader = isLoader
  },
  [types.SET_TEMPLATE_MESSAGES](
    state: TemplateMessagesState,
    payload: Array<TemplateMessageItems>
  ) {
    state.templateMessages.items = payload
  },
  [types.CONFIRM_DELETE_TEMPLATE_MESSAGE](
    state: TemplateMessagesState,
    payload: ConfirmDeleteTemplateMessage
  ) {
    state.confirmDeleteTemplateMessage = payload
  },
  [types.SET_LIST_PREVIEW_TEMPLATE](
    state: TemplateMessagesState,
    payload: {
      open: boolean
      id: string
    }
  ) {
    state.listPreviewTemplate = payload
  },
  [types.SET_TEMPLATE_MESSAGE_STEP](
    state: TemplateMessagesState,
    step: FormSteps
  ) {
    state.templateMessage.step = step
  },
  [types.SET_TEMPLATE_MESSAGE_INITIAL_FORM_PAYLOAD](
    state: TemplateMessagesState,
    initialFormPayload: FormPayload
  ) {
    state.templateMessage.initialFormPayload = initialFormPayload
  },
  [types.SET_TEMPLATE_MESSAGE_FORM_PAYLOAD](
    state: TemplateMessagesState,
    formPayload: FormPayload
  ) {
    state.templateMessage.formPayload = { ...formPayload }
  },
  [types.SET_TEMPLATE_MESSAGE_SHOW_DISCARD_CONTENT_ALERT](
    state: TemplateMessagesState,
    showAlert: boolean
  ) {
    state.templateMessage.showDiscardContentAlert = showAlert
  },
  [types.SET_TEMPLATE_MESSAGE_SHOW_REVIEW](
    state: TemplateMessagesState,
    showReview: boolean
  ) {
    state.templateMessage.showReview = showReview
  },
  [types.SET_TEMPLATE_MESSAGE_SHOW_SUCCESS_MODAL](
    state: TemplateMessagesState,
    showSuccessModal: boolean
  ) {
    state.templateMessage.showSuccessModal = showSuccessModal
  },
  [types.SET_PERSON_FIELDS](
    state: TemplateMessagesState,
    fields: Array<TemplateMessageFieldType>
  ) {
    state.personFields = fields
  },
  [types.SET_CHAT_FIELDS](
    state: TemplateMessagesState,
    fields: Array<TemplateMessageFieldType>
  ) {
    state.chatFields = fields
  },
  [types.SET_ORGANIZATION_FIELDS](
    state: TemplateMessagesState,
    fields: Array<TemplateMessageFieldType>
  ) {
    state.organizationFields = fields
  },
  [types.SET_VALID_TEMPLATE_MESSAGE_NAME](
    state: TemplateMessagesState,
    payload: validTemplateMessageName
  ) {
    state.templateMessage.validTemplateMessageName = payload
  }
}
