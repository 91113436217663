import { ActionContext } from 'vuex'

import { NotificationCenterService } from '@/common/services/notification-center'

import { RootState, NotificationState } from '@/store/interfaces'

import * as types from './mutations-types'

export const setNotificationCount = (
  context: ActionContext<NotificationState, RootState>,
  count: number
) => {
  context.commit(types.SET_NOTIFICATION_COUNT, count)
}

export const getNotificationCount = async (
  context: ActionContext<NotificationState, RootState>,
  status = 'new'
) => {
  const notificationCenterService = new NotificationCenterService()
  const count = await notificationCenterService.getNotificationCount(status)
  context.dispatch('setNotificationCount', count)
  return count
}

export const setUpdateNotificationList = (
  context: ActionContext<NotificationState, RootState>,
  update: boolean
) => {
  context.commit(types.SET_NOTIFICATION_UPDATE_LIST, update)
}
