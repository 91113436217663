import { SurveyState } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_PAYLOAD](state: SurveyState, value: any) {
    state.payload = value
  },
  [types.SET_EDIT_SURVEY_DATA](state: SurveyState, value: any) {
    state.editSurveyData = value
  }
}
