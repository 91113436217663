import { AxiosInstance } from 'axios'
import qs from 'qs'

import { services } from '@octadesk-tech/services'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import { FilterKeys } from './credit-management.enum'

export class CreditManagementService {
  private static instance: CreditManagementService

  public static getInstance(): CreditManagementService {
    if (!this.instance) {
      this.instance = new CreditManagementService()
    }

    return this.instance
  }

  _client: any

  private getClient(): Promise<AxiosInstance> {
    return getClient('creditManagement')
  }

  async getChatIntegratorClient() {
    if (this._client) {
      return this._client
    }

    // eslint-disable-next-line
    if (!services['chatIntegrator']) {
      throw new Error('Cannot get http client: missing service chatIntegrator')
    }

    // eslint-disable-next-line
    this._client = await services['chatIntegrator'].getClient(legacyHeaders())

    return this._client
  }

  async getBalance() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get('/balance', {
          ...legacyHeaders()
        })
      )

      return data?.balance
    } catch (error) {
      console.error(error)

      throw new Error(`Error on getBalance: ${error}`)
    }
  }

  async getConsumptionEstimation() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get('/consumption-estimation', {
          ...legacyHeaders()
        })
      )

      return data?.daysUntilZero
    } catch (error) {
      console.error(error)

      throw new Error(`Error on getConsumptionEstimation: ${error}`)
    }
  }

  async getCategorizedReport({
    phone,
    startDate,
    endDate
  }: {
    phone: string
    startDate: string
    endDate: string
  }) {
    try {
      const query = {
        [FilterKeys.Phone]: phone,
        [FilterKeys.StartDate]: startDate,
        [FilterKeys.EndDate]: endDate
      }

      const { data } = await this.getClient().then(client =>
        client.get(`/categorized-report?${qs.stringify(query)}`, {
          ...legacyHeaders()
        })
      )

      return data
    } catch (error) {
      console.error(error)

      throw new Error(`Error on getConsumptionEstimation: ${error}`)
    }
  }

  async getIntegratorWhatsappNumbers() {
    try {
      const { data } = await this.getChatIntegratorClient().then(client =>
        client.get('/whatsapp/numbers', {
          ...legacyHeaders()
        })
      )

      return data
    } catch (ex) {
      console.error(ex)
    }
  }
}
