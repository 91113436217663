import { phoneNumbers } from '@/modules/ChatSettings/whatsapp-oficial/numbers/helpers'

export const connectingNumbers = ({ connectingNumbers }: any) => {
  return connectingNumbers
}

export const disconnectedNumbers = ({ disconnectedNumbers }: any) => {
  return disconnectedNumbers
}

export const recentConnectedNumbers = ({ recentConnectedNumbers }: any) =>
  recentConnectedNumbers

export const disableReconnectingReOpen = ({ disableReconnectingReOpen }: any) =>
  disableReconnectingReOpen

export const canShowBar = ({ canShowBar }: any) => canShowBar

export const canVerifyConfigurations = () => false

export const socketEventsQueue = ({ socketEventsQueue }: any) =>
  socketEventsQueue

export const discoverNumberByState =
  ({ disconnectedNumbers, connectingNumbers, recentConnectedNumbers }: any) =>
  (number: any, state: any) => {
    if (state === 'disconnected') {
      return disconnectedNumbers.find((n: any) =>
        phoneNumbers.equals(n, number)
      )
    } else if (state === 'connecting') {
      return connectingNumbers.find((n: any) => phoneNumbers.equals(n, number))
    } else if (state === 'recent-connected') {
      return recentConnectedNumbers.find((n: any) =>
        phoneNumbers.equals(n, number)
      )
    }

    return null
  }

export const inLoaderShowBar = ({ inLoaderShowBar }: any) => inLoaderShowBar
