import { ActionContext } from 'vuex'

import { NewPasswordState, RootState } from '@/store/interfaces'

import * as types from './mutations-types'

export const setCurrentStep = (
  context: ActionContext<NewPasswordState, RootState>,
  currentStep: number
) => {
  context.commit(types.SET_CURRENT_STEP, currentStep)
}

export const setUserId = (
  context: ActionContext<NewPasswordState, RootState>,
  userId: string
) => {
  context.commit(types.SET_USER_ID, userId)
}

export const setToken = (
  context: ActionContext<NewPasswordState, RootState>,
  token: string
) => {
  context.commit(types.SET_TOKEN, token)
}
