import Vue from 'vue'

import diff from './diff'
import format from './format'
import timeFormat from './time'

Vue.filter('dateFormat', format)
Vue.filter('dateDiff', diff)
Vue.filter('timeFormat', timeFormat)

export { format, diff, timeFormat }
