import { ActionContext } from 'vuex'

import AuthenticationService from '@/common/services/authentication'
import { GlobalService } from '@/common/services/global.service'

import { RootState, GlobalState } from '@/store/interfaces'

import * as types from './mutation-types'

const globalService = new GlobalService()

export const fetchGroups = async (
  context: ActionContext<GlobalState, RootState>
) => {
  const { groups } = context.getters

  if (!groups.length) {
    const listGroups = await globalService.getGroups()

    context.commit(types.SET_GROUPS, listGroups)
  }
}

export const fetchAgents = async (
  context: ActionContext<GlobalState, RootState>
) => {
  const { agents } = context.getters

  if (!agents.length) {
    const listAgents = await globalService.getAgents()

    context.commit(
      types.SET_AGENTS,
      listAgents.sort((a: any, b: any) =>
        a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())
      )
    )
  }
}

export const getConfig = async (
  context: ActionContext<GlobalState, RootState>
) => {
  const authenticationService = new AuthenticationService()

  const config = await authenticationService.getConfig()

  context.commit(types.SET_CONFIG, config)
}

export const setReloadPageAlert = (
  context: ActionContext<GlobalState, RootState>
) => {
  context.commit(types.SET_RELOAD_PAGE_ALERT)
}
