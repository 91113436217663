export const CommerceEnum = {
  MaxProdutctsSend: 30,
  ProductsPerPage: 20
}

export const FilterTypes = {
  Checkbox: 'checkbox',
  Range: 'range',
  Switch: 'switch'
}

export const SourceTypes = {
  facebook: 'facebook',
  octadesk: 'octadesk'
}

export const ProductStatus = {
  waiting: 0,
  published: 1,
  failed: 2
}

export const IntegrationTypes = {
  magento: 'magento',
  tray: 'tray',
  nuvemshop: 'nuvemshop'
}

export const OrderErrorTypes = {
  notFound: 'Customer not found'
}

export const CommerceMethodType = {
  sendProducts: 'sendProducts',
  createCart: 'createCart'
}
