import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import legacyHeaders from '@/common/services/legacy-headers'

import { TagsManagerTag } from './store/interfaces'

export class TagsManagerService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.chat.getClient()) as AxiosInstance
  }

  async getTagsByStatus(status = 'active') {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(`/public-tags/status/${status}`, legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on getTagsByStatus: ' + err)
    }
  }

  async updateTag(payload: TagsManagerTag) {
    try {
      if (!payload.id) {
        throw new Error('Cannot update tag: missing tag id')
      }

      const { data } = await this.getClient().then(client =>
        client.put('/public-tags', payload, legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on updateTag: ' + err)
    }
  }

  async bulkUpdate(tags: Array<TagsManagerTag>) {
    try {
      const { data } = await this.getClient().then(client =>
        client.put('/public-tags/bulk', tags, legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on bulkUpdate: ' + err)
    }
  }

  async deleteTag(id: string) {
    try {
      if (!id) {
        throw new Error('Cannot delete tag: missing tag id')
      }

      await this.getClient().then(client =>
        client.delete(`/public-tags/${id}`, legacyHeaders())
      )

      return true
    } catch (err) {
      throw new Error('Error on deleteTag: ' + err)
    }
  }

  async bulkDelete(ids: Array<string>) {
    try {
      const { data } = await this.getClient().then(client =>
        client.post('/public-tags/bulk/delete', ids, legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on bulkDelete: ' + err)
    }
  }

  async createTag(tag: { name: string; status: string; totalUsed: number }) {
    try {
      const { data } = await this.getClient().then(client =>
        client.post('/public-tags', tag, legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on createTag: ' + err)
    }
  }

  async getTagsCounts() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get('/public-tags/status/count', legacyHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on getTagsCounts: ' + err)
    }
  }

  async verifyTagName(name: string) {
    try {
      const { data: isValid } = await this.getClient().then(client =>
        client.get('public-tags/is-available', {
          ...legacyHeaders(),
          params: {
            name
          }
        })
      )

      if (isValid === true || isValid === false) {
        return isValid
      }

      throw new Error('Invalid endpoint response')
    } catch (err) {
      throw new Error('Error on verifyTagName: ' + err)
    }
  }
}
