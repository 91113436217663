export enum RoleTypes {
  None,
  Owner,
  Admin,
  AgentMaster,
  Agent,
  NaturalPerson,
  CorporatePerson,
  ExternalPerson
}
