export const createSequentialEnum = (...keys: any) => {
  let enumerators: any
  ;(enumerators =>
    keys.forEach(
      (key: any, index: any) => (enumerators[(enumerators[key] = index)] = key)
    ))(enumerators || (enumerators = {}))

  return enumerators
}

export const CreateMessageStep = createSequentialEnum(
  'Number',
  'Models',
  'Form',
  'Preview',
  'Success',
  'Error',
  'Detail',
  'Edit',
  'Delete',
  'Confirm'
)

export const EVENTS = {
  BOT_CREATION_FLUX: 'Bot Creation flux',
  BOT_STEP_INTERACTED: 'Bot Step Interacted',
  TEMPLATE_MESSAGE_EVENTS: 'Create Template Message',
  CREATE_CAMPAIGN_FLUX: 'Create Campaign Flux',
  USER_IDENTIFICATION: 'user identification',
  CHANNEL_SETUP: 'channel setup',
  CHANNEL_SETUP_FAIL: 'channel setup fail',
  MESSENGER_INSTAGRAM_PAGEVIEW: 'messenger instagram pageview',
  CHAT_CONFIG_PAGEVIEW: 'chat config pageview',
  WHATSAPP_NUMBERS_PAGEVIEW: 'whatsapp numbers pageview',
  REMOVE_MESSENGER_INSTAGRAM_PAGE: 'remove messenger instagram page',
  REMOVE_WHATSAPP_NUMBER: 'remove whatsapp number',
  ENABLE_WABA_NUMBER_FLUX: 'enable waba number flux',
  CREATE_SATISFACTION_SURVEY_FLUX: 'create satisfaction survey flux'
}

export const OptionsPermissions = {
  AllGroups: 'all-groups',
  AllAgents: 'all-agents'
}

export const DomainType = {
  Ticket: 1,
  Person: 2,
  Product: 3,
  Chat: 4,
  Organization: 5
}

export const FieldType = {
  Text: 1,
  Number: 5,
  Decimal: 6,
  Date: 7
}

export const CreateMessageModalEvents = {
  Create: 'create-template-message',
  Edit: 'update-template-message',
  Preview: 'preview-template-message'
}
