import {
  GeneralFilters,
  GeneralFiltersData
} from '@/modules/ChatSettings/financial-management/credits/credits.enum'

import { Credits } from './interfaces'

export const productExpenses = ({ productExpenses }: Credits) =>
  productExpenses.expenses

export const filteredProductExpenses = ({ productExpenses }: Credits) => {
  if (
    productExpenses.filters[GeneralFilters.Product] === GeneralFiltersData.All
  ) {
    return productExpenses.expenses
  }

  return productExpenses.expenses.filter(
    item => item.product === productExpenses.filters[GeneralFilters.Product]
  )
}

export const productExpensesTotal = ({ productExpenses }: Credits) =>
  productExpenses.total

export const productExpensesFilters = ({ productExpenses }: Credits) =>
  productExpenses.filters

export const consumptionEstimation = ({ consumptionEstimation }: Credits) =>
  consumptionEstimation

export const categorizedReport = ({ categorizedReport }: Credits) =>
  categorizedReport

export const integratorWhatsappNumbers = ({
  integratorWhatsappNumbers
}: Credits) => integratorWhatsappNumbers
