export const currentChatBotFlux = ({ currentChatBotFlux }) => currentChatBotFlux

export const chatBotFluxes = ({ chatBotFluxes }) => chatBotFluxes

export const chatBotFluxConditions = ({ currentChatBotFlux }) => {
  return (currentChatBotFlux && currentChatBotFlux.conditions) || []
}

export const currentChatBotFluxChannel = ({ currentChatBotFluxChannel }) =>
  currentChatBotFluxChannel

export const getChatBotConditionsToSave =
  (
    { currentConditionsView },
    {
      chatBotFluxSpecificPagesConditions,
      chatBotFluxExceptPagesConditions,
      currentChatBotFluxChannel
    }
  ) =>
  () => {
    let conditions = []
    if (currentConditionsView === 'all-pages') {
      conditions = chatBotFluxExceptPagesConditions.filter(
        condition => condition.value
      )
    } else {
      conditions = chatBotFluxSpecificPagesConditions.filter(
        condition => condition.value
      )
    }

    return {
      conditions,
      allConditions:
        currentChatBotFluxChannel !== 'web' ||
        conditions.length === 1 ||
        currentConditionsView === 'all-pages'
    }
  }

export const chatBotFluxSpecificPagesConditions = (
  _,
  { chatBotFluxConditions }
) => {
  return chatBotFluxConditions.filter(
    condition =>
      condition.comparator &&
      ['$eq', 'contains'].includes(condition.comparator.type)
  )
}

export const chatBotFluxExceptPagesConditions = (
  _,
  { chatBotFluxConditions }
) => {
  return chatBotFluxConditions.filter(
    condition =>
      condition.comparator &&
      ['$ne', 'no-contains'].includes(condition.comparator.type)
  )
}

export const currentConditionsView = ({ currentConditionsView }) =>
  currentConditionsView
