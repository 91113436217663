import { MessengerState } from '@/store/interfaces'

const state: MessengerState = {
  facebookPages: [],
  instagramPages: [],
  authorizedFacebookPages: [],
  authorizedInstagramAccounts: [],
  isAuthorizedPagesModalOpen: false,
  isRemovePageConfirmationModalOpen: false,
  isInstagramAppSettingsModalOpen: false,
  pageItemToRemove: undefined
}

export default state
