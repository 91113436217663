import { services, headers } from '@octadesk-tech/services'

let _client

const getClient = async () => {
  if (_client) {
    return _client
  }

  _client = await services.chat.getClient()

  _client.defaults.baseURL += '/macros'

  return _client
}

export const getMacros = userId => {
  return getClient()
    .then(client =>
      client.post(
        'avaliables',
        {
          creatorId: userId
        },
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)
}

export const createMacro = payload => {
  return getClient()
    .then(client => client.post('', payload, headers.getAuthorizedHeaders()))
    .then(res => res.data)
}

export const updateMacro = payload => {
  return getClient()
    .then(client =>
      client.put(payload.id, payload, headers.getAuthorizedHeaders())
    )
    .then(res => res.data)
}

export const removeMacro = id => {
  return getClient().then(client =>
    client.delete(id, headers.getAuthorizedHeaders())
  )
}
