export const SET_AUTHORIZED_PAGES = 'SET_AUTHORIZED_PAGES'

export const SET_IS_AUTHORIZED_PAGES_MODAL_OPEN =
  'SET_IS_AUTHORIZED_PAGES_MODAL_OPEN'

export const SET_IS_REMOVE_PAGE_CONFIRMATION_MODAL_OPEN =
  'SET_IS_REMOVE_PAGE_CONFIRMATION_MODAL_OPEN'

export const SET_IS_INSTAGRAM_APP_SETTINGS_MODAL_OPEN =
  'SET_IS_INSTAGRAM_APP_SETTINGS_MODAL_OPEN'

export const SET_PAGE_ITEM_TO_REMOVE = 'SET_PAGE_ITEM_TO_REMOVE'

export const SET_FACEBOOK_PAGES = 'SET_FACEBOOK_PAGES'

export const SET_INSTAGRAM_PAGES = 'SET_INSTAGRAM_PAGES'
