import { TicketVoiceState } from './interfaces'

const state: TicketVoiceState = {
  baseURL: undefined,
  settings: [],
  providers: [],
  providerSettings: undefined
}

export default state
