import { CommerceState } from './interfaces'

export const commerceIsInitialDataFetched = (state: CommerceState) =>
  state.initialDataFetched

export const commerceCatalog = (state: CommerceState) => state.catalog

export const commerceHasCatalog = (state: CommerceState) =>
  Boolean(state.catalog)

export const commerceCatalogTotalProducts = (state: CommerceState) =>
  state.totalProducts

export const commerceCatalogNextProductsPage = (state: CommerceState) =>
  state.nextProductsPage

export const commerceCatalogProductsLoader = (state: CommerceState) =>
  state.productsLoader

export const commerceCatalogProducts = (state: CommerceState) =>
  state.catalog?.products
