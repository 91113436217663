import { Location } from 'vue-router'

import {
  EmbeddedNavigationState,
  EmbeddedRoute,
  EmbeddedVueRoute,
  EmbeddedAngularRoute,
  EmbeddedRouteTypes
} from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_CURRENT_EMBEDDED_ROUTE](
    state: EmbeddedNavigationState,
    embeddedRoute: EmbeddedRoute<
      EmbeddedVueRoute<string | Location> | EmbeddedAngularRoute
    >
  ) {
    state.currentEmbeddedRoute = embeddedRoute
  },
  [types.SET_CURRENT_EMBEDDED_ROUTE_TYPE](
    state: EmbeddedNavigationState,
    type: EmbeddedRouteTypes
  ) {
    state.currentEmbeddedRouteType = type
  }
}
