import i18n from '@/common/i18n'

import * as types from './mutations-types'

export default {
  [types.WHATSAPP_SET_QRCODE](state: any, qrcode: any) {
    state.qrcodeReading = true

    state.qrcode = qrcode
  },
  [types.WHATSAPP_QRCODE_ERROR](state: any, error: any) {
    state.qrcodeErrorDetails = undefined

    state.qrcodeReading = false

    state.qrcodeSuccess = null

    state.qrcodeLogged = false

    state.qrcodeError = error

    try {
      const errorData = JSON.parse(error)

      if (errorData) {
        state.qrcodeError = errorData

        if (errorData.code === 'NUMBER_CONFLICT') {
          state.qrcodeErrorDetails = i18n?.t(
            'chatSettings.whatsappWeb.qrCode.error.errorDifferentNumber'
          )
        } else if (errorData.code === 'AVAILABLE_CONFLICT') {
          state.qrcodeErrorDetails = errorData.code
        }
      }
    } catch (err) {
      console.error('err WHATSAPP_QRCODE_ERROR', err)
    }
  },
  [types.WHATSAPP_QRCODE_LOGGED](state: any) {
    state.qrcodeLogged = true

    state.qrcode = ''
  },
  [types.WHATSAPP_QRCODE_SUCCESS](state: any, session: any) {
    state.qrcodeReading = false

    state.qrcodeSuccess = session
  },
  [types.WHATSAPP_SET_QRCODE_READING](state: any) {
    state.qrcodeReading = true

    state.qrcodeSuccess = null

    state.qrcodeLogged = false
  },
  [types.WHATSAPP_QRCODE_RESET](state: any) {
    state.qrcodeError = undefined

    state.qrcodeErrorDetails = undefined

    state.qrcodeSuccess = undefined

    state.qrcodeReading = false

    state.qrcode = ''
  },
  [types.ADD_AUTO_RETRY](state: any) {
    state.autoRetries += 1
  },
  [types.RESET_AUTO_RETRY](state: any) {
    state.autoRetries = 0
  }
}
