import { enums } from '@/modules/Chat/components/sidebar/services/enums'

export const sidebarTabs = [
  {
    id: 'open',
    name: 'list-waiting',
    meta: {
      waiting: true,
      sortOptions: [
        {
          label: 'mostRecent',
          property: 'lastMessageDate',
          direction: 'desc'
        },
        {
          label: 'older',
          property: 'lastMessageDate',
          direction: 'asc'
        }
      ],
      listType: enums.listTypes.open
    },
    closeCurrentChat: false,
    component: 'ChatSidebarList'
  },
  {
    id: 'closed',
    name: 'list-closed',
    meta: {
      sortOptions: [
        {
          label: 'mostRecent',
          property: 'lastMessageDate',
          direction: 'desc'
        },
        {
          label: 'older',
          property: 'lastMessageDate',
          direction: 'asc'
        }
      ],
      listType: enums.listTypes.close
    },
    closeCurrentChat: false,
    component: 'ChatSidebarList'
  },
  {
    id: 'listContacts',
    name: 'list-contacts',
    meta: {
      listType: enums.listTypes.contacts
    },
    closeCurrentChat: true,
    component: 'ChatSidebarListContacts'
  }
]
