import { environment } from '@octadesk-tech/services'

export const getPrefixedDomain = (): string => {
  const domains: Record<string, string> = {
    [environment.DEFAULT_ENVIRONMENTS.dev]: 'devoctadesk',
    [environment.DEFAULT_ENVIRONMENTS.qa]: 'qaoctadesk',
    [environment.DEFAULT_ENVIRONMENTS.beta]: 'octadesk',
    [environment.DEFAULT_ENVIRONMENTS.prd]: 'octadesk'
  }

  const env = environment.getEnvironment() as string

  if (!domains[env]) {
    return 'localhost'
  }

  return domains[env]
}
