import APIError from './APIError'

export default class AuthError extends Error {
  constructor(error: APIError) {
    super()

    this.name = 'AuthError'
    this.status = error.status
    this.code = error.data.code
    this.message = error.message
  }

  status?: number
  code?: string
}
