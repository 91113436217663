export const SET_SETTINGS = 'SET_SETTINGS'

export const SET_AUTOMATIC_OPEN_TICKET = 'SET_AUTOMATIC_OPEN_TICKET'

export const SET_CAN_SHARE = 'SET_CAN_SHARE'

export const SET_MAX_SERVICES_ENABLED = 'SET_MAX_SERVICES_ENABLED'

export const SET_MAX_SERVICES_LIMIT = 'SET_MAX_SERVICES_LIMIT'

export const SET_ROULETTE = 'SET_ROULETTE'

export const SET_AGENT_NOTIFICATION_ENABLED = 'SET_AGENT_NOTIFICATION_ENABLED'

export const SET_AGENT_NOTIFICATION_OPTION = 'SET_AGENT_NOTIFICATION_OPTION'

export const SET_AGENT_NOTIFICATION_DELAY = 'SET_AGENT_NOTIFICATION_DELAY'

export const SET_CLIENT_CAN_CLOSE = 'SET_CLIENT_CAN_CLOSE'

export const SET_INACTIVITY_TIMEOUT_ENABLED = 'SET_INACTIVITY_TIMEOUT_ENABLED'

export const SET_INACTIVITY_TIMEOUT_DELAY = 'SET_INACTIVITY_TIMEOUT_DELAY'

export const SET_SEND_CHAT_TO_CLIENT = 'SET_SEND_CHAT_TO_CLIENT'

export const SET_SHOW_AGENT_NAME_IN_MESSAGE = 'SET_SHOW_AGENT_NAME_IN_MESSAGE'

export const SET_AUTOMATIC_RESPONSIBLE = 'SET_AUTOMATIC_RESPONSIBLE'

export const SET_AGENTS_SERVICE_TIMEOUT_ENABLED =
  'SET_AGENTS_SERVICE_TIMEOUT_ENABLED'

export const SET_AGENTS_SERVICE_TIMEOUT_DELAY =
  'SET_AGENTS_SERVICE_TIMEOUT_DELAY'

export const SET_CLIENTS_SERVICE_TIMEOUT_ENABLED =
  'SET_CLIENTS_SERVICE_TIMEOUT_ENABLED'

export const SET_CLIENTS_SERVICE_TIMEOUT_DELAY =
  'SET_CLIENTS_SERVICE_TIMEOUT_DELAY'
