import { SummaryOptions } from '@/modules/Woz/preferences/preferences.enum'

import { WozPreferencesState } from './interfaces'

const state: WozPreferencesState = {
  fetchLoading: true,
  fetchError: false,
  preferences: {
    transcript: {
      enabled: false,
      summarize: {
        enabled: false,
        minLength: 0
      }
    },
    agent: {
      enabled: false
    },
    summary: {
      enabled: false,
      selectedOption: SummaryOptions.BulletPoints
    }
  }
}

export default state
