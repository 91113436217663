import { IntegratorSteps } from '@/modules/Commerce/integrations/integrations.enum'

import {
  IntegrationsCommerceState,
  IntegrationsItems,
  AvailableIntegrations
} from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_COMMERCE_INTEGRATIONS](
    state: IntegrationsCommerceState,
    items: Array<IntegrationsItems>
  ) {
    state.items = items
  },
  [types.SET_COMMERCE_CONNECT_INTEGRATION_CURRENT_STEP](
    state: IntegrationsCommerceState,
    step: IntegratorSteps
  ) {
    state.connect.currentStep = step
  },
  [types.SET_COMMERCE_CONNECT_AVAILABLE_INTEGRATIONS](
    state: IntegrationsCommerceState,
    availableIntegrations: Array<AvailableIntegrations>
  ) {
    state.availableIntegrations = availableIntegrations
  },
  [types.SET_COMMERCE_CONNECT_FIELDS](
    state: IntegrationsCommerceState,
    fields: any
  ) {
    state.connect = {
      ...state.connect,
      ...fields
    }
  }
}
