import { NucleusRecurrence } from '@/modules/Billing'
import { overwriteObject } from '@/modules/support'

import * as types from './mutation-types'
import {
  CHECKOUT_TYPES,
  Checkout,
  CheckoutCompany,
  CheckoutDisclaimer,
  CheckoutDiscount,
  CheckoutItem,
  CheckoutPaymentMethod,
  CheckoutPaymentType,
  CheckoutTerm,
  Plan,
  UnloggedInfo
} from './types'

function overwriteOrPush(
  array: any[],
  target: any,
  fn: (args: any) => boolean
) {
  const index = array.findIndex(fn)
  index === -1
    ? array.push(target)
    : overwriteObject(array[index], target, false)
}

export default {
  [types.SET_CURRENT_RECURRENCE]: (
    state: Checkout,
    recurrence: Omit<NucleusRecurrence, 'plans'>
  ) => {
    state.currentRecurrence = recurrence
  },
  [types.SET_CURRENT_PLAN]: (state: Checkout, plan: Plan) => {
    state.currentPlan = plan
  },
  [types.ADD_TERM]: (state: Checkout, term: CheckoutTerm) => {
    overwriteOrPush(state.terms, term, t => t.code === term.code)
  },
  [types.ADD_DISCLAIMER]: (state: Checkout, disclaimer: CheckoutDisclaimer) => {
    state.disclaimers.push(disclaimer)
  },
  [types.ADD_DISCOUNT]: (state: Checkout, discount: CheckoutDiscount) => {
    overwriteOrPush(state.discounts, discount, d => d.code === discount.code)
  },
  [types.ADD_ITEM]: (state: Checkout, item: CheckoutItem) => {
    overwriteOrPush(state.items, item, i => i.code === item.code)
  },
  [types.SET_PAYMENT_METHOD]: (
    state: Checkout,
    payment: CheckoutPaymentMethod
  ) => {
    state.payment = payment
  },
  [types.SET_COMPANY]: (state: Checkout, company: CheckoutCompany) => {
    state.company = company
  },
  [types.SET_TERMS]: (state: Checkout, terms: CheckoutTerm[]) => {
    state.terms = terms
  },
  [types.SET_TENANT]: (state: Checkout, id: string) => {
    state.tenantId = id
  },
  [types.SET_PAYMENTS]: (state: Checkout, payments: CheckoutPaymentType[]) => {
    state.payments = [...payments]
  },
  [types.SET_TOTAL]: (state: Checkout, value: number) => {
    state.total = value
  },
  [types.SET_SUB_TOTAL]: (state: Checkout, value: number) => {
    state.subTotal = value
  },
  [types.REMOVE_TERM]: (state: Checkout, code: string | number) => {
    state.terms = state.terms.filter(t => t.code !== code)
  },
  [types.REMOVE_DISCOUNT]: (state: Checkout, code: string | number) => {
    state.discounts = state.discounts.filter(d => d.code !== code)
  },
  [types.REMOVE_ITEM]: (state: Checkout, code: string | number) => {
    state.items = state.items.filter(i => i.code !== code)
  },
  [types.CLEAR_TERMS]: (state: Checkout) => {
    state.terms = []
  },
  [types.CLEAR_ITEMS]: (state: Checkout) => {
    state.items = []
  },
  [types.CLEAR_DISCOUNT]: (state: Checkout) => {
    state.discounts = []
  },
  [types.CLEAR_TOTAL]: (state: Checkout) => {
    state.total = 0
  },
  [types.CLEAR_DISCLAIMERS]: (state: Checkout) => {
    state.disclaimers = []
  },
  [types.SET_TYPE]: (state: Checkout, type: CHECKOUT_TYPES) => {
    state.type = type
  },
  [types.SET_TYPE_STORE]: (state: Checkout, status: boolean) => {
    state.typeStore = status
  },
  [types.TOGGLE_TEMS]: (state: Checkout) => {
    state.terms = state.terms.map(term => {
      return {
        ...term
      }
    })
  },
  [types.SET_UNLOGGED_INFO](state: Checkout, payload: UnloggedInfo) {
    state.unloggedInfo = payload
  },
  [types.SET_IS_FRANCHISE_CHECKOUT](state: Checkout, payload: boolean) {
    state.isFranchiseCheckout = payload
  },
  [types.SET_IS_ONE_TIME](state: Checkout, payload: boolean) {
    state.isOneTime = payload
  },
  [types.SET_USER_ITEM](state: Checkout, payload: CheckoutItem) {
    state.userItem = payload
  },
  [types.SET_PLAN_ITEM](state: Checkout, payload: CheckoutItem) {
    state.planItem = payload
  },
  [types.SET_PREVIEW_ITEMS](state: Checkout, payload: CheckoutItem[]) {
    state.previewItems = payload
  },
  [types.SET_COUPON_CODE](state: Checkout, payload: string) {
    state.couponCode = payload
  },
  [types.SET_DISCOUNT_TOTAL](state: Checkout, payload: number) {
    state.discountTotal = payload
  }
}
