import { ActionContext } from 'vuex'

import { FilterKeys } from '@/modules/ChatSettings/whatsapp-oficial/credit-management/credit-management.enum'
import { CreditManagementService } from '@/modules/ChatSettings/whatsapp-oficial/credit-management/credit-management.service'

import { RootState } from '@/store/interfaces'

import { CreditManagementState } from './interfaces'
import * as types from './mutations-types'

const creditManagementService = CreditManagementService.getInstance()

export const getCategorizedReport = async (
  context: ActionContext<CreditManagementState, RootState>,
  payload: {
    phone: string
    startDate: string
    endDate: string
  }
) => {
  context.commit(types.SET_REPORT_LOADER, true)

  context.commit(types.SET_REPORT_ERROR, false)

  try {
    const result = await creditManagementService.getCategorizedReport(payload)

    context.commit(types.SET_CATEGORIZED_REPORT, result)
  } catch {
    throw new Error('Error on getCategorizedReport')
  } finally {
    context.commit(types.SET_REPORT_LOADER, false)

    context.commit(types.SET_REPORT_ERROR, true)
  }
}

export const setReportFilter = async (
  context: ActionContext<CreditManagementState, RootState>,
  { fieldKey, value }: { fieldKey: FilterKeys; value: string }
) => {
  const { reportFilter } = context.getters

  const filter = {
    ...reportFilter,
    [fieldKey]: value
  }

  context.commit(types.SET_REPORT_FILTER, filter)

  await context.dispatch('getCategorizedReport', filter)
}
