import dayjs from 'dayjs'

import { headers } from '@octadesk-tech/services'
import storage from '@octadesk-tech/storage'

import { createClient } from '@/modules/Chat/services/http'

import store from '@/store'

const getClient = () => createClient('personsService')

const updateActivityTime = () => {
  storage.setItem('activity_time', dayjs().format())
}

const verifyActivation = () => {
  const activityTime = storage.getItem('activity_time')
  if (activityTime) {
    return dayjs().isAfter(dayjs(activityTime), 'day')
  }

  updateActivityTime()
  return true
}

const trackActivity = domain => {
  const userLogged = store.getters.userLogged || storage.getItem('userLogged')

  if (userLogged && domain && verifyActivation()) {
    const userid = userLogged.id

    getClient()
      .then(client =>
        client.post(
          `/activity`,
          { userid, domain },
          headers.getAuthorizedHeaders()
        )
      )
      .then(() => {
        updateActivityTime()
      })
      .catch(err => console.error('Error at track activity: ', err))
  }
}

export const statistics = {
  trackActivity
}
