import Storage from '@octadesk-tech/storage'

import { UserLogged } from '@/common/helpers/interfaces/user-logged'

import { AuthState } from '@/store/interfaces'

const REQUIRED_KEYS = ['userToken', 'company']

export const getUserLogged = () => Storage.getItem('userLogged') as UserLogged

export const getUserLoggedEncrypted = () =>
  Storage.getItemEncrypted('userLogged')

export const setCulture = (culture: string) =>
  Storage.setItem('culture', culture)

export const clearCulture = () => {
  Storage.removeItem('culture')

  Storage.removeItem('language')
}

export const getAuthState = () => Storage.getItem('auth') as AuthState

window.addEventListener('storage', e => {
  if (
    (e.key !== null &&
      REQUIRED_KEYS.indexOf(e.key) !== -1 &&
      e.oldValue &&
      !e.newValue) ||
    (e.key === null && e.newValue === null)
  ) {
    Storage.clear()
    window.location.href = '/login'
  }
})
