export const WHATSAPP_SET_QRCODE = 'WHATSAPP_SET_QRCODE'

export const WHATSAPP_QRCODE_LOGGED = 'WHATSAPP_QRCODE_LOGGED'

export const WHATSAPP_QRCODE_SUCCESS = 'WHATSAPP_QRCODE_SUCCESS'

export const WHATSAPP_QRCODE_ERROR = 'WHATSAPP_QRCODE_ERROR'

export const WHATSAPP_SET_QRCODE_READING = 'WHATSAPP_SET_QRCODE_READING'

export const WHATSAPP_QRCODE_RESET = 'WHATSAPP_QRCODE_RESET'

export const ADD_AUTO_RETRY = 'ADD_AUTO_RETRY'

export const RESET_AUTO_RETRY = 'RESET_AUTO_RETRY'
