export default () => ({
  filter: {
    'agent._id': null,
    status: [4],
    'flux.botId': { $ne: null },
    'botInfo.hasStarted': true
  },
  remove: [
    'messages.mentions._id',
    'users._id',
    'group._id',
    'messages',
    'lastMessageUserType',
    'lastMessageTimeExceeded'
  ]
})
