export enum EVENTS {
  LOGIN_PAGEVIEW = 'login pageview',
  LOGIN_SELECTED = 'login selected',
  LOGIN_COMPANY = 'login company',
  LOGIN_SUCCESS = 'login success',
  SIGNUP_PAGEVIEW = 'signup pageview',
  SIGNUP_SELECTED = 'signup selected',
  SIGNUP_COMPANY = 'signup company',
  SIGNUP_SUCCESS = 'signup success',
  PHONE_VALIDATION = 'trial data verification flow',
  LOGOUT = 'logout',
  BILLING_PURCHASE_SUCCESS = 'purchase success',
  ONBOARDING_CHANNELS_PAGEVIEW = 'onboarding channels pageview',
  ONBOARDING_FIRST_CHANNEL_SELECTED = 'first channel selected',
  ONBOARDING_CHANNEL_SETUP = 'channel setup',
  ONBOARDING_CHANNEL_SETUP_FAIL = 'channel setup fail',
  ONBOARDING_WHATSAPP_EMAIL_CLICK = 'whatsapp_email_click',
  ONBOARDING_SKIP_STEP = 'onboarding skip step',
  ONBOARDING_QRCODE_EXPRESS_SKIP = 'qrcode express skip',
  ONBOARDING_QRCODE_EXPRESS_PAGEVIEW = 'qrcode express pageview',
  ONBOARDING_QRCODE_CONFLICT = 'qr code conflict',
  ONBOARDING_QRCODE_CONFLICT_DISCONNECTED_NUMBER = 'conflict disconnected number',
  ONBOARDING_QRCODE_CONFLICT_READ_OTHER_NUMBER = 'conflict read other number',
  ONBOARDING_PAGEVIEW = 'onboarding pageview',
  ONBOARDING_FLUX = 'onboarding flux',
  DASHBOARD_VIEWED = 'dashboard viewed',
  DASHBOARD_GROUP_FILTER_SELECTED = 'group filter selected',
  DASHBOARD_CHANNEL_FILTER_SELECTED = 'channel filter selected',
  DASHBOARD_TAG_FILTER_SELECTED = 'tag filter selected',
  DASHBOARD_PERIOD_FILTER_SELECTED = 'period filter selected',
  NEW_PRODUCT_ROLLBACK = 'open old chat',
  SEND_FEEDBACK = 'send feedback',
  USER_ADD_PAGEVIEW = 'user add pageview',
  CHECK_AUTH_STATE = 'check auth state',
  WHITELABEL_AUTH_MISSING_DATA = 'whitelabel auth missing data',
  WHITELABEL_AUTH_CLICKED_RETRY = 'whitelabel auth clicked retry',
  WHITELABEL_CHANNEL_SETUP = 'whitelabel channel setup',
  WHITELABEL_SIGNUP_SUCCESS = 'whitelabel signup success',
  WHITELABEL_LANDINGPAGE_PAGEVIEW = 'whitelabel landingpage pageview',
  CHANNEL_REMOVED = 'channel removed',
  CHANNEL_UPDATED = 'channel updated',
  SCHEDULE_CALL = 'intention to schedule',
  TRY_CHAT = 'intent to try chat',
  WHATSAPP_LP_CLICK_KNOW = 'click on learn more',
  TRY_BOT = 'intent to try bot',
  HOME_VIDEO_CLICK = 'home video click',
  LP_PAGEVIEW = 'lp features pageview',
  LP_BUY = 'buy intention',
  ADDITIONAL_STORE_PAGEVIEW = 'additional store pageview',
  ADDITIONAL_PURCHASE_INTENTION = 'additional purchase intention',
  ADDITIONAL_TRIAL_ACTIVATION = 'additional trial activation'
}
