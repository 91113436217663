import Storage from '@octadesk-tech/storage'

export * from './breadcrumb'

export * from './bot'

export * from './content-properties'

export * from './bot-flux'

export * from './bot-channel-config'

export * from './interactions'

export * from './templates'

export * from './sidebar'

export * from './commerce'

export * from './office-hours'

export * from './custom-fields'

export const chatBots = ({ chatBots }) => chatBots

export const botChannel = ({ botChannel }) => botChannel

export const openModalChannelRestrictions = ({
  openModalChannelRestrictions
}) => openModalChannelRestrictions

export const botChannelsSpecifications = ({ botSpecifications }) =>
  botSpecifications.specifications

export const botSpecificationsChannels = ({ botSpecifications }) =>
  botSpecifications.channels

export const botSpecificationsChannelsInfo = ({ botSpecifications }) =>
  botSpecifications.channelsInfo

export const getDuplicateBotFlux = ({ duplicateBotFlux }) => duplicateBotFlux

export const getDuplicateFluxId = ({ duplicateFluxId }) => duplicateFluxId

export const getBotBuilderVersion = ({ botBuilderVersion }) => botBuilderVersion

export const isLegacyBot = ({ botBuilderVersion }) => botBuilderVersion === 1

export const whatsappNumbers = ({ whatsappNumbers }) => whatsappNumbers

export const facebookPages = ({ facebookPages }) => facebookPages

export const instagramPages = ({ instagramPages }) => instagramPages

export const language = () =>
  (Storage.getItem('language') || 'pt-br').toLowerCase()
