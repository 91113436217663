import Storage from '@octadesk-tech/storage'

import { OldWidgetState } from '@/modules/ChatSettings/other-channels/chat/types'

export const chatAgents = ({ agents }: OldWidgetState) =>
  agents?.filter((a: any) => a.active)

export const enableSendInstructionsModal = ({
  enableSendInstructionsModal
}: OldWidgetState) => enableSendInstructionsModal

export const company = ({ userLogged }: OldWidgetState) =>
  userLogged ? userLogged.subDomain : Storage.getItem('company')

export const embedCode = ({ settings }: OldWidgetState) => settings!.embedCode

export const instructionsSent = ({ instructionsSent }: OldWidgetState) =>
  instructionsSent

export const userLogged = ({ userLogged }: OldWidgetState) =>
  userLogged || Storage.getItem('userLogged')

export const settings = ({ settings }: OldWidgetState) => settings

export const settingsTitle = ({ settings }: OldWidgetState) => settings!.title

export const settingsMessageUnavailable = ({ settings }: OldWidgetState) =>
  settings!.messages
