import { ActionContext } from 'vuex'

import ZapierService from '@/common/services/zapier.service'

import { RootState, OnboardingState } from '@/store/interfaces'

import * as types from './mutations-types'

export const setChannel = async (
  context: ActionContext<OnboardingState, RootState>,
  channel: Record<string, string>
) => {
  context.commit(types.SET_CHANNEL, channel)
}

export const setQrcodeModal = async (
  context: ActionContext<OnboardingState, RootState>,
  qrcodeModal: boolean
) => {
  context.commit(types.SET_QRCODE_MODAL, qrcodeModal)
}

export const setHasSelectedChannel = async (
  context: ActionContext<OnboardingState, RootState>,
  hasSelectedChannel: boolean
) => {
  context.commit(types.SET_HAS_SELECTED_CHANNEL, hasSelectedChannel)
}

export const setCurrentStep = async (
  context: ActionContext<OnboardingState, RootState>,
  currentStep: number
) => {
  context.commit(types.SET_CURRENT_STEP, currentStep)
}

export const setGroupType = async (
  context: ActionContext<OnboardingState, RootState>,
  groupType: string
) => {
  context.commit(types.SET_GROUP_TYPE, groupType)
}

export const setGroupModal = (
  context: ActionContext<OnboardingState, RootState>,
  groupModal: boolean
) => {
  context.commit(types.SET_GROUP_MODAL, groupModal)
}

export const setLoadingError = (
  context: ActionContext<OnboardingState, RootState>,
  loadingError: boolean
) => {
  context.commit(types.SET_LOADING_ERROR, loadingError)
}

export const setModalSequence = (
  context: ActionContext<OnboardingState, RootState>,
  modalSequence: boolean
) => {
  context.commit(types.SET_MODAL_SEQUENCE, modalSequence)
}
export const sendScheduleNotification = async (
  context: ActionContext<OnboardingState, RootState>
) => {
  const zapierService = new ZapierService()
  await zapierService.postWebhook(
    { email: context.rootGetters.email },
    '1943265',
    '36dig6q'
  )
}
export const setLoadedWidget = async (
  context: ActionContext<OnboardingState, RootState>,
  loadedWidget: boolean
) => {
  context.commit(types.SET_LOADED_WIDGET, loadedWidget)
}
