import { headers } from '@octadesk-tech/services'

import { createClient } from '@/modules/Chat/services/http'

const getClient = () => createClient('personsService')

export const getPhoneNumbers = async filter => {
  const {
    data: { items, total }
  } = await getClient().then(client =>
    client.post('/search', filter, headers.getAuthorizedHeaders())
  )

  return {
    contacts: items,
    totalFilteredContacts: total
  }
}
