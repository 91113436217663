export enum BotActions {
  Normal,
  Happy,
  Nervous,
  EyesClosed,
  InLove,
  Dizzy,
  Damage,
  Scare,
  Lookup,
  Sleepy,
  Suspicious,
  Headset
}
