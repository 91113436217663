import { ActionContext } from 'vuex'

import { Location } from 'vue-router'

import {
  EmbeddedNavigationState,
  RootState,
  EmbeddedRoute,
  EmbeddedAngularRoute,
  EmbeddedVueRoute,
  EmbeddedRouteTypes
} from '@/store/interfaces'

import * as types from './mutations-types'

export const setCurrentEmbeddedRoute = (
  context: ActionContext<EmbeddedNavigationState, RootState>,
  payload: EmbeddedRoute<
    EmbeddedAngularRoute | EmbeddedVueRoute<string | Location>
  >
) => {
  context.commit(types.SET_CURRENT_EMBEDDED_ROUTE, payload)
}

export const setCurrentEmbeddedRouteType = (
  context: ActionContext<EmbeddedNavigationState, RootState>,
  payload: EmbeddedRouteTypes
) => {
  context.commit(types.SET_CURRENT_EMBEDDED_ROUTE_TYPE, payload)
}
