import { ActionContext } from 'vuex'

import Storage from '@octadesk-tech/storage'

import { RootState, NavigationState } from '@/store/interfaces'

import * as types from './mutations-types'

const NAVIGATION_PIN_STORAGE_KEY = 'octa-pinned'

export const setNavigationPinValue = (
  context: ActionContext<NavigationState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_NAVIGATION_PIN, value)
  Storage.setItem(NAVIGATION_PIN_STORAGE_KEY, value)
}

export const setNavigationPinClose = (
  context: ActionContext<NavigationState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_NAVIGATION_PIN_CLOSE, value)
  Storage.setItem(NAVIGATION_PIN_STORAGE_KEY, value)
}

export const restoreNavigationPinValue = (
  context: ActionContext<NavigationState, RootState>
) => {
  const pinValue = Storage.getItem(NAVIGATION_PIN_STORAGE_KEY)
  context.commit(types.SET_NAVIGATION_PIN, pinValue)
}
