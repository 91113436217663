import { getCommerceIntegrationsService } from '@/modules/Chat/components/conversation/services/commerce-integrations'
import * as types from '@/modules/Chat/components/conversation/store/mutations-types/commerce-integrations'

export const getCommerceIntegrations = async context => {
  context.commit(types.SET_COMMERCE_INTEGRATIONS_LOADER, true)

  const integrations = await getCommerceIntegrationsService()

  context.commit(types.SET_COMMERCE_INTEGRATIONS, integrations)

  context.commit(types.SET_COMMERCE_INTEGRATIONS_LOADER, false)
}

export const setCommerceSelectedIdIntegration = async (
  context,
  { integrationId, email, cellphone }
) => {
  context.commit(types.SET_COMMERCE_SELECTED_ID_INTEGRATION, integrationId)

  context.dispatch('getCommerceClientOrders', { email, cellphone })
}
