export const enums = {
  chatStatus: {
    waiting: 0,
    talking: 1,
    closed: 2,
    missed: 3,
    started: 4,
    offline: 5,
    closedKb: 6,
    closedMissed: 7
  },
  connectionStatus: {
    online: 0,
    offline: 1
  },
  userTypes: {
    none: 0,
    employee: 1,
    customer: 2,
    handler: 3,
    system: 4,
    forwardingEmployee: 5
  },
  listTypes: {
    open: 1,
    close: 2,
    contacts: 3
  },
  inbox: {
    all: 'all',
    byAgent: 'byAgent',
    byGroup: 'group',
    chatbot: 'chatbot',
    mentioned: 'mentioned',
    participation: 'participation',
    unread: 'unread',
    withoutResponsible: 'withoutResponsible',
    timeExceeded: 'timeExceeded',
    group: 'group'
  }
}
