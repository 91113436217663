import debounce from 'lodash.debounce'

import privateRouter from '@/routes/router/private-router'

const embedRoutes = [
  {
    area: 'ticket',
    action: 'edit',
    url: '/ticket/edit'
  },
  {
    area: 'ticket',
    action: 'copy',
    url: '/ticket/copy'
  },
  {
    area: 'tickets',
    action: 'new-list',
    url: '/ticket/list'
  },
  {
    area: 'tickets',
    action: 'new-customlist',
    url: '/ticket/list'
  },
  {
    area: 'dashboards',
    action: 'dashboard',
    url: '/ticket/dashboard'
  },
  {
    area: 'report',
    action: 'view',
    url: '/ticket/report/view'
  },
  {
    area: 'report',
    action: 'list',
    url: '/ticket/report'
  },
  {
    area: 'trigger',
    action: 'edit',
    url: '/ticket/trigger/edit'
  },
  {
    area: 'trigger',
    action: 'list',
    url: '/ticket/trigger'
  },
  {
    area: 'smart-form',
    action: 'edit',
    url: '/ticket/smartform/edit'
  },
  {
    area: 'smart-form',
    action: 'list',
    url: '/ticket/smartform'
  },
  {
    area: 'macro',
    action: 'edit',
    url: '/ticket/macro/edit'
  },
  {
    area: 'macro',
    action: 'list',
    url: '/ticket/macro'
  },
  {
    area: 'custom-workflow',
    action: 'register',
    url: '/ticket/workflow/edit'
  },
  {
    area: 'custom-workflow',
    action: 'list',
    url: '/ticket/workflow'
  },
  {
    area: 'automation',
    action: 'edit',
    url: '/ticket/automation/edit'
  },
  {
    area: 'automation',
    action: 'list',
    url: '/ticket/automation'
  },
  {
    area: 'forms',
    action: 'edit',
    url: '/ticket/forms/edit'
  },
  {
    area: 'forms',
    action: 'list',
    url: '/ticket/forms'
  },
  {
    area: 'groups',
    action: 'profile',
    url: '/ticket/groups/profile'
  },
  {
    area: 'groups',
    action: 'list',
    url: '/ticket/groups'
  },
  {
    area: 'subject',
    action: 'edit',
    url: '/ticket/subject/edit'
  },
  {
    area: 'subject',
    action: 'list',
    url: '/ticket/subject'
  },
  {
    area: 'product',
    action: 'edit',
    url: '/ticket/product/edit'
  },
  {
    area: 'product',
    action: 'list-category',
    url: '/ticket/products'
  },
  {
    area: 'calendar',
    action: 'edit-calendar',
    url: '/ticket/calendar/edit-calendar'
  },
  {
    area: 'calendar',
    action: 'edit-holidays',
    url: '/ticket/calendar/edit-holidays'
  },
  {
    area: 'calendar',
    action: 'list',
    url: '/ticket/calendar'
  }
]

const handleRouteWithId = (
  action: string,
  routeUrl: string,
  routeId: string,
  currentRoutePath: string
) => {
  const isDifferentRoute = currentRoutePath !== routeUrl
  const routeWithId = `${routeUrl}/${routeId}`

  const shouldPushNewCustomListOrDashboard =
    ['new-customlist', 'dashboard'].includes(action) && isDifferentRoute
  const shouldPushRouteWithId =
    isDifferentRoute && currentRoutePath !== routeWithId
  const isProfileRoute = routeUrl.includes('groups/profile')

  if (shouldPushNewCustomListOrDashboard) {
    privateRouter.push(routeUrl)
    return
  }

  if (currentRoutePath === routeWithId) return

  if (action !== 'list' && action !== 'new-list' && shouldPushRouteWithId) {
    privateRouter.push(routeWithId)
  } else if (isProfileRoute) {
    if (currentRoutePath !== routeWithId) privateRouter.push(routeWithId)
  } else if (isDifferentRoute) {
    privateRouter.push(routeUrl)
  }
}

const handleCalendarRoute = (
  routeUrl: string,
  routeId: string,
  currentRoutePath: string
) => {
  const calendarReturnToHolidaysList = ['2', 'list-holidays'].includes(routeId)
  const calendarReturnToCalendarList = ['1', 'list'].includes(routeId)

  if (
    currentRoutePath !== routeUrl &&
    currentRoutePath !== `${routeUrl}/${routeId}`
  ) {
    if (routeId && calendarReturnToHolidaysList) {
      privateRouter.push(`${routeUrl}/list-holidays`)
    } else if (routeId && !calendarReturnToCalendarList) {
      privateRouter.push(`${routeUrl}/${routeId}`)
    } else {
      privateRouter.push(routeUrl)
    }
  }
}

export const handleTicketRoutes = (event: MessageEvent) => {
  const { area, action, id: routeId } = event?.data?.routeData || {}

  const matchedRoute = embedRoutes.find(
    route => route.area === area && route.action === action
  )

  if (!matchedRoute) return

  const { url: routeUrl } = matchedRoute
  const currentRoutePath = privateRouter.currentRoute.path

  if (area === 'calendar') {
    handleCalendarRoute(routeUrl, routeId, currentRoutePath)
  } else if (routeId) {
    handleRouteWithId(action, routeUrl, routeId, currentRoutePath)
  } else if (currentRoutePath !== routeUrl && action !== 'edit') {
    privateRouter.push(routeUrl)
  }
}

export const debouncedHandleTicketRoutes = debounce(handleTicketRoutes, 250)
