export enum OrganizationEnum {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export enum FilterMembers {
  TEXT = 'TextSearch',
  ENABLED = 'IsEnabled',
  TYPE = 'Type'
}
