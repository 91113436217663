import store from '@/store'

export default notification => {
  const chatKey = store.getters['chat/currentChat']?.key

  const hasNewAreaNotificationsEnabled =
    store.getters.hasNewAreaNotificationsEnabled

  return (
    hasNewAreaNotificationsEnabled &&
    notification.data.roomKey &&
    chatKey !== notification.data.roomKey
  )
}
