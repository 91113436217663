export default () => ({
  filter: {
    'agent._id': null
  },
  remove: [
    'messages.mentions._id',
    'users._id',
    'group._id',
    'channel',
    'status',
    '$or',
    'messages',
    'flux.botId',
    'botInfo.hasStarted',
    'lastMessageUserType',
    'lastMessageTimeExceeded'
  ]
})
