import { services } from '@octadesk-tech/services'

import { loadParameterHeader } from './headers'

let _client: any
export const getClient = async () => {
  if (_client) {
    return _client
  }
  return (_client = await services.chat.getClient(loadParameterHeader()))
}

export const getSettings = () =>
  getClient()
    .then(client => client.get('configs', loadParameterHeader()))
    .then(res => res.data)

export const saveSettings = (settings: any) =>
  getClient().then(client =>
    client.put('configs', settings, loadParameterHeader())
  )

export const getUrlEmbed = () => `${services.cdn.getCDNURL()}/embed.js`
