import { TagsManagerState } from '@/store/interfaces'

const state: TagsManagerState = {
  newTag: undefined,
  tags: [],
  searchFilter: '',
  tagsCounts: {
    active: 0,
    archived: 0
  },
  tagsConfirmAction: {
    show: false,
    tag: [],
    actionName: '',
    i18nKey: '',
    analyticsEventName: ''
  }
}

export default state
