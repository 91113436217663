import { ActionContext } from 'vuex'

import PersonsService from '@/common/services/persons'

import { RootState } from '@/store/interfaces'

import { TicketService } from '../ticket.service'
import { TicketState } from './interfaces'
import * as types from './mutation-types'

export const setLatestListRoute = (
  context: ActionContext<TicketState, RootState>,
  ListRoute: string
) => {
  context.commit(types.SET_LATEST_LIST_ROUTE, ListRoute)
}

export const setTicketStatus = async (
  context: ActionContext<TicketState, RootState>
) => {
  const ticketService = new TicketService()
  if (context.state.ticketStatus?.length) {
    return
  }

  const ticketStatus = await ticketService.getTicketStatus()
  context.commit(types.SET_TICKET_STATUS, ticketStatus)
}

export const setUserRouletteStatus = async (
  context: ActionContext<TicketState, RootState>,
  payload: { userId: string; status: boolean }
) => {
  const isRouletteTicketEnabled = context.rootGetters?.isRouletteTicketEnabled

  if (!isRouletteTicketEnabled) return

  const { userId, status } = payload
  const personService = new PersonsService()
  try {
    const rouletteStatus = await personService.setRouletteStatus(userId, status)
    context.commit(types.SET_ROULETTE_STATUS, rouletteStatus)
  } catch (error) {
    console.error((error as Error).message)
  }
  return status
}

export const getUserRouletteStatus = async (
  context: ActionContext<TicketState, RootState>,
  userId: string
) => {
  const isRouletteTicketEnabled = context.rootGetters?.isRouletteTicketEnabled

  if (!isRouletteTicketEnabled) return

  const personService = new PersonsService()
  try {
    const rouletteStatus = await personService.getRouletteStatus(userId)
    context.commit(types.SET_ROULETTE_STATUS, rouletteStatus)
  } catch (error) {
    console.error((error as Error).message)
  }
}

export const getRouletteStatus = async (
  context: ActionContext<TicketState, RootState>,
  userId: string
) => {
  const personService = new PersonsService()
  try {
    return await personService.getRouletteStatus(userId)
  } catch (error) {
    console.error((error as Error).message)
  }
}

export const setBulkRouletteAction = async (
  context: ActionContext<TicketState, RootState>,
  payload: {
    emailsPerson?: Array<string>
    idsPerson?: Array<string>
    action: string
    properties: {
      isEnabled: boolean
    }
  }
) => {
  const isRouletteTicketEnabled = context.rootGetters?.isRouletteTicketEnabled

  if (!isRouletteTicketEnabled) return

  const personService = new PersonsService()
  try {
    return await personService.toggleContacts(payload)
  } catch (error) {
    console.error((error as Error).message)
  }
}

export const getPerson = async (
  context: ActionContext<TicketState, RootState>,
  id: string
) => {
  const personService = new PersonsService()
  try {
    return await personService.getContact(id)
  } catch (error) {
    console.error((error as Error).message)
  }
}
