export default {
  path: '/onboarding/',
  name: 'onboarding',
  redirect: '/onboarding/welcome',
  component: () => import('@/views/Onboarding.vue'),
  children: [
    {
      path: 'channel-selection',
      name: 'channel-selection',
      component: () => import('./components/ChannelSelection.vue')
    },
    {
      path: 'welcome',
      name: 'onboarding-welcome',
      component: () => import('./components/OnboardingWelcome.vue')
    },
    {
      path: 'function',
      name: 'onboarding-function',
      component: () => import('./components/OnboardingFunction.vue')
    },
    {
      path: 'area',
      name: 'onboarding-area',
      component: () => import('./components/OnboardingArea.vue')
    },
    {
      path: 'pains',
      name: 'onboarding-pains',
      component: () => import('./components/OnboardingPains.vue')
    },
    {
      path: 'widget',
      name: 'widget-test',
      alias: '/teste',
      component: () => import('./components/WidgetTest.vue')
    },
    {
      path: 'welcome',
      name: 'welcome ',
      component: () => import('./components/OnboardingWelcome.vue')
    }
  ]
}
