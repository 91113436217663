export default {
  currentModalContent: undefined,
  currentOfficeHours: undefined,
  currentOfficeHoursOriginal: undefined,
  officeHoursList: undefined,
  sameScheduleHours: [],
  formLoading: false,
  botsWithOfficeHours: [],
  timezonesList: []
}
