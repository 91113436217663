export const EVENTS = {
  BOT_CREATION_FLUX: 'Bot Creation flux',
  BOT_STEP_INTERACTED: 'Bot Step Interacted',
  TEMPLATE_MESSAGE_EVENTS: 'Create Template Message',
  CREATE_CAMPAIGN_FLUX: 'Create Campaign Flux',
  USER_IDENTIFICATION: 'user identification',
  CHANNEL_SETUP: 'channel setup',
  CHANNEL_SETUP_FAIL: 'channel setup fail',
  MESSENGER_INSTAGRAM_PAGEVIEW: 'messenger instagram pageview',
  CHAT_CONFIG_PAGEVIEW: 'chat config pageview',
  WHATSAPP_NUMBERS_PAGEVIEW: 'whatsapp numbers pageview',
  REMOVE_MESSENGER_INSTAGRAM_PAGE: 'remove messenger instagram page',
  REMOVE_WHATSAPP_NUMBER: 'remove whatsapp number',
  ENABLE_WABA_NUMBER_FLUX: 'enable waba number flux',
  CREATE_SATISFACTION_SURVEY_FLUX: 'create satisfaction survey flux'
}

export enum BspName {
  '360_DIALOG' = '360dialog',
  ALL_IN = 'allin',
  OCTADESK = 'octadesk'
}

export const RoleType = {
  None: 0,
  Owner: 1,
  Admin: 2,
  AgentMaster: 3,
  Agent: 4,
  NaturalPerson: 5,
  CorporatePerson: 6
}
