export const RoleTypes = {
  none: 0,
  owner: 1,
  admin: 2,
  agentMaster: 3,
  agent: 4,
  naturalPerson: 5,
  corporatePerson: 6,
  externalUser: 7
}
