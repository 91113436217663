import OctaAnalyticsClient from '@octadesk-tech/analytics-client'

import { EVENTS } from '@/common/helpers/enums/events.enum'

export const bigqueryTracking = async () => {
  OctaAnalyticsClient.BigQueryIntegration.send({
    name: EVENTS.NEW_PRODUCT_ROLLBACK,
    new_analytics: true
  })
}
