export const SET_INITIAL_DATA_LOADER = 'SET_INITIAL_DATA_LOADER'

export const SET_INITIAL_DATA_ERROR = 'SET_INITIAL_DATA_ERROR'

export const SET_WHATSAPP_OFFICIAL_NUMBERS = 'SET_WHATSAPP_OFFICIAL_NUMBERS'

export const SET_APPROVED_TEMPLATE_MESSAGES = 'SET_APPROVED_TEMPLATE_MESSAGES'

export const SET_BULK_MESSAGES = 'SET_BULK_MESSAGES'

export const SET_SELECTED_BULK_MESSAGE = 'SET_SELECTED_BULK_MESSAGE'

export const SET_BULK_MESSAGES_REPORT = 'SET_BULK_MESSAGES_REPORT'

export const SET_RETRIEVED_TEMPLATE_MESSAGE = 'SET_RETRIEVED_TEMPLATE_MESSAGE'

export const SET_CURRENT_LIST_PAGE = 'SET_CURRENT_LIST_PAGE'

export const SET_TOTAL_BULK_MESSAGES = 'SET_TOTAL_BULK_MESSAGES'

export const SET_CURRENT_LIST_LOADER = 'SET_CURRENT_LIST_LOADER'

export const SET_TEMPLATE_MESSAGE_STEP = 'SET_TEMPLATE_MESSAGE_STEP'

export const SET_BULK_MESSAGE_INITIAL_FORM_PAYLOAD =
  'SET_BULK_MESSAGE_INITIAL_FORM_PAYLOAD'

export const SET_IS_SPREAD_SHEET_EMPTY = 'SET_IS_SPREAD_SHEET_EMPTY'

export const SET_BULK_MESSAGE_FORM_PAYLOAD = 'SET_BULK_MESSAGE_FORM_PAYLOAD'

export const SET_TEMPLATE_BULK_SHOW_DISCARD_CONTENT_ALERT =
  'SET_TEMPLATE_BULK_SHOW_DISCARD_CONTENT_ALERT'

export const SET_TEMPLATE_MESSAGE_SHOW_REVIEW =
  'SET_TEMPLATE_MESSAGE_SHOW_REVIEW'

export const SET_TEMPLATE_MESSAGE_SUCCESS_MODAL =
  'SET_TEMPLATE_MESSAGE_SUCCESS_MODAL'
