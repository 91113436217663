import { ASSIGN_TO } from '@/modules/Bot/enums/assign-to.enum'
import { generateDefaultButton } from '@/modules/Bot/store/getters/template-models'

import i18n from '@/common/i18n'

/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-nested-template-literals */
export default (i18nTemplatesKey, channel, getPersonProperty, userLogged) => {
  const name = getPersonProperty('name')
  const email = getPersonProperty('email')
  const phone = getPersonProperty('phone')
  return [
    {
      id: 'check-if-you-are-a-customer',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.check-if-you-are-a-customer.displayName`
      ),
      type: 'conditional',
      content: {
        messages: [],
        rules: [
          {
            input: {
              token: '#status-do-contato',
              example: '',
              domain: 'PERSON',
              type: 'select',
              name: 'idContactStatus',
              dataSource: 'contactStatus',
              resolvedAllowedValues: []
            },
            operator: '$eq',
            comparators: [],
            comparator: {}
          },
          {
            input: {
              token: '#status-do-contato',
              example: '',
              domain: 'PERSON',
              type: 'select',
              name: 'idContactStatus',
              dataSource: 'contactStatus',
              resolvedAllowedValues: []
            },
            operator: '$empty',
            comparators: []
          }
        ],
        aggregator: '$or',
        buttons: [
          {
            id: 'true',
            value: '@CONDITIONAL_TRUE',
            label: '@CONDITIONAL_TRUE',
            selected: true
          },
          {
            id: 'false',
            value: '@CONDITIONAL_FALSE',
            label: '@CONDITIONAL_FALSE',
            selected: false
          }
        ]
      }
    },
    {
      id: 'd87cc829-2629-424e-a67f-de2e3f7de037',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.greeting-new-leads.displayName`
      ),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.greeting-new-leads.description.item0`
            )
          },
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.greeting-new-leads.description.item1`
            )
          }
        ]
      }
    },
    {
      id: '79da4b08-686f-420c-8c03-40cfc1e51fae',
      displayName: i18n?.t(`${i18nTemplatesKey}.collect-lead-name.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          ...name,
          token: i18n?.t(name.token),
          example: i18n?.t(name.example)
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-lead-name.description`),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: '50dc5076-d6b8-4af8-9abe-f90a9785be60',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.collect-lead-email.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          ...email,
          token: i18n?.t(email.token),
          example: i18n?.t(email.token)
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-lead-email.description`),
        buttons: [],
        validationMessage: i18n?.t(
          `${i18nTemplatesKey}.collect-lead-email.validationMessage`
        ),
        validationMessages: [
          {
            level: 0,
            message: i18n?.t(
              `${i18nTemplatesKey}.collect-lead-email.validationMessages.item0`
            )
          }
        ],
        allowUserInput: true
      }
    },
    {
      id: '0863437e-c0b9-478f-8154-8ad77e1bc0ce',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-collected-email.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-collected-email.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-email.buttons.item0`,
            'email-confirmation-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-email.buttons.item1`,
            'email-confirmation-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'confirm-name-collected',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-name-collected.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-name-collected.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-name-collected.buttons.item0`,
            'name-confirmation-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-name-collected.buttons.item1`,
            'name-confirmation-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'collect-lead-phone',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.collect-lead-phone.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          ...phone,
          token: i18n?.t(phone.token),
          example: i18n?.t(phone.token)
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-lead-phone.description`),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'collect-company-segment',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.collect-company-segment.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.collect-company-segment.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.collect-company-segment.buttons.item0`,
            'segment-ecommerce'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.collect-company-segment.buttons.item1`,
            'segment-education'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.collect-company-segment.buttons.item2`,
            'segment-health'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.collect-company-segment.buttons.item3`,
            'segment-technology'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.collect-company-segment.buttons.item4`,
            'segment-other'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'disqualifies-lead-and-terminates',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.disqualifies-lead-and-terminates.displayName`
      ),
      type: 'close-conversation',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.disqualifies-lead-and-terminates.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'closing-message',
      displayName: i18n?.t(`${i18nTemplatesKey}.closing-message.displayName`),
      type: 'close-conversation',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.closing-message.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'customer-or-second-visit',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.customer-or-second-visit.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.customer-or-second-visit.description.item0`
            )
          }
        ],
        referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: userLogged?.id || '',
        successMessageContent: {
          messages: [
            {
              text: i18n?.t(
                `${i18nTemplatesKey}.customer-or-second-visit.successMessageContent.item0`
              )
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text: i18n?.t(
                `${i18nTemplatesKey}.customer-or-second-visit.fallbackMessageContent.item0`
              )
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: true,
        selectedView: 'available'
      }
    },
    {
      id: 'unavailable-agents',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.unavailable-agents.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: ''
          }
        ],
        referenceProperty: ASSIGN_TO.noOne,
        successMessageContent: {
          messages: [
            {
              text: ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text: ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: false,
        selectedView: 'available'
      }
    },
    {
      id: 'collecting-company-size',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.collecting-company-size.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.collecting-company-size.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.collecting-company-size.buttons.item0`,
            'company-size-1-9'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.collecting-company-size.buttons.item1`,
            'company-size-10-49'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.collecting-company-size.buttons.item2`,
            'company-size-50-249'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.collecting-company-size.buttons.item3`,
            'company-size-250'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'microenterprise-service',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.microenterprise-service.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.microenterprise-service.description.item0`
            )
          }
        ],
        referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: userLogged?.id || '',
        successMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.microenterprise-service.successMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.microenterprise-service.fallbackMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: channel === 'web',
        selectedView: null
      }
    },
    {
      id: 'small-business-service',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.small-business-service.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            id: '15146ebc-aab4-4f85-885a-c6c56cf55475',
            text: i18n?.t(
              `${i18nTemplatesKey}.small-business-service.description.item0`
            )
          }
        ],
        referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: userLogged?.id || '',
        successMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.small-business-service.successMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.small-business-service.fallbackMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: channel === 'web',
        selectedView: 'available'
      }
    },
    {
      id: 'medium-sized-company-service',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.medium-sized-company-service.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.medium-sized-company-service.description.item0`
            )
          }
        ],
        referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: userLogged?.id || '',
        successMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.medium-sized-company-service.successMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.medium-sized-company-service.fallbackMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: channel === 'web',
        selectedView: null
      }
    }
  ]
}
