import { default as ConnectionTypeFallback } from '@/modules/Bot/helpers/connection-type-fallback'
import * as helpers from '@/modules/Bot/store/helpers'
import types from '@/modules/Bot/store/mutations-types'
import {
  QuickReplyTypes,
  BotContentResolver,
  ClientBotAction,
  ClientBotActionTypes,
  ClientBotConnection
} from '@/modules/Bot/store/types'

import i18n from '@/common/i18n'

const ERROR_ID_REQUIRED = 'Action ids are required'

const HTTP_STATUS_CODE_CLIENT_ERROR = '@HTTP_STATUS_CODE_CLIENT_ERROR'

const CONDITIONAL_FALSE = '@CONDITIONAL_FALSE'

export const submitCurrentBot = (
  { commit, dispatch },
  { structure, proActiveMessage }
) => {
  dispatch('removeEditingAction')

  commit(types.SET_ACTIONS, structure.actions || [])

  commit(types.SET_CONNECTIONS, structure.connections || [])

  commit(types.SET_PERSONA, structure.persona)

  if (proActiveMessage && proActiveMessage.enabled) {
    const proActiveMessageAction = new ClientBotAction({
      type: ClientBotActionTypes.ProActiveMessage,
      content: {
        messages: [
          {
            text: proActiveMessage.comment
          }
        ]
      }
    })

    commit(types.SET_PROACTIVE_MESSAGE, proActiveMessageAction)
  } else {
    commit(types.SET_PROACTIVE_MESSAGE, null)
  }
}

export const addAction = (context, action) => {
  const botAction = new ClientBotAction(action)

  if (botAction.validate()) {
    context.commit(types.ADD_ACTION, botAction)

    context.dispatch('setBotUnsavedChanges')
  }
}

export const addFirstConnection = (context, { nextAction, connectionType }) => {
  if (!nextAction.id) {
    throw new Error(ERROR_ID_REQUIRED)
  }

  const botConnection = new ClientBotConnection({
    parentActionId: '',
    nextActionId: nextAction.id,
    type: connectionType || null
  })

  context.dispatch('addBotConnection', botConnection)
}

const hasWithoutAttachedButton = async ({
  context,
  currentAction,
  connectionType,
  currentButton,
  getActionById,
  getNextPossibleButtonConnections,
  withoutAttachedButton
}) => {
  const connectionWithAction = withoutAttachedButton[0]

  const nextAction = getActionById(connectionWithAction.nextActionId)

  let buttons = undefined

  if (currentAction.content?.subType === QuickReplyTypes.InteractiveList) {
    buttons = currentAction.content.list.sections[0].options
  } else {
    buttons = currentAction.content.buttons
  }

  for (const button of buttons) {
    if (!currentButton || button.id !== currentButton.id) {
      const buttonConnections = getNextPossibleButtonConnections({
        currentAction,
        button
      }).filter(
        connection =>
          connection.connectionRules && connection.connectionRules.length
      )

      if (!buttonConnections.length) {
        const buttonRules = getButtonConnectionRules(currentAction, button)

        await context.dispatch('addConnection', {
          currentAction: currentAction,
          nextAction: nextAction,
          connectionRules: buttonRules,
          connectionType,
          keepOlderConnections: true
        })
      }
    }
  }

  if (!currentButton)
    await context.dispatch('removeBotConnection', connectionWithAction)
}

export const fixActionConnections = async (context, payload) => {
  const { currentAction, connectionType } = payload

  const {
    getActionById,
    getNextPossibleConnections,
    getNextPossibleButtonConnections
  } = context.getters

  if (
    currentAction.type === ClientBotActionTypes.QuickReply &&
    (currentAction.content?.buttons?.length ||
      currentAction.content?.list?.sections[0]?.options?.length)
  ) {
    const actionConnections = getNextPossibleConnections({
      currentAction,
      connectionType
    })

    const withoutAttachedButton = actionConnections.filter(
      connection =>
        !connection.connectionRules || !connection.connectionRules.length
    )

    if (withoutAttachedButton.length == 1) {
      await hasWithoutAttachedButton({
        ...payload,
        context,
        getActionById,
        getNextPossibleButtonConnections,
        withoutAttachedButton
      })
    }
  }
}

export const addConnection = (
  context,
  {
    currentAction,
    nextAction,
    connectionType,
    connectionRules,
    currentButton,
    keepOlderConnections
  }
) => {
  if ((currentAction && !currentAction.id) || (nextAction && !nextAction.id)) {
    throw new Error(ERROR_ID_REQUIRED)
  }

  const { getNextPossibleConnections } = context.getters

  const currentPossibleConnections = getNextPossibleConnections({
    currentAction,
    connectionType,
    currentButton
  })

  if (!keepOlderConnections) {
    currentPossibleConnections.forEach(possibleConnection => {
      context.dispatch('removeBotConnection', possibleConnection)
    })
  }

  const botConnection = new ClientBotConnection({
    parentActionId: currentAction.id,
    nextActionId: nextAction.id,
    type:
      connectionType ||
      (currentAction.type == ClientBotActionTypes.AssignRoom &&
      currentAction?.content?.mustHasAvailability === true
        ? 'fallback'
        : null) ||
      null,
    connectionRules: connectionRules || []
  })

  context.dispatch('addBotConnection', botConnection)
}

export const upsertButtonConnection = (
  context,
  { currentAction, nextAction, button, connectionRules }
) => {
  if (!currentAction?.id || !nextAction?.id) {
    throw new Error(ERROR_ID_REQUIRED)
  }

  if (!button) {
    throw new Error('Button is required')
  }

  if (!button.value) {
    throw new Error('Button value is required')
  }

  if (!nextAction && currentAction.type === ClientBotActionTypes.QuickReply) {
    const { getActionById, getNextPossibleConnections } = context.getters
    const myNextConnections = getNextPossibleConnections({
      currentAction,
      considerMultipleConnections: true
    })

    if (myNextConnections.length == 1) {
      const parentConnection = myNextConnections[0]
      nextAction = getActionById(parentConnection.nextActionId)
      context.dispatch('removeBotConnection', parentConnection)
    }
  }

  context.dispatch('removeButtonConnection', {
    currentAction,
    nextAction,
    button
  })

  const connectionRule = helpers.getButtonConnectionRule(button)

  const botConnection = new ClientBotConnection({
    parentActionId: currentAction.id,
    nextActionId: nextAction?.id,
    connectionRules: connectionRules ? connectionRules : [connectionRule]
  })

  context.dispatch('addBotConnection', botConnection)

  const { getNextPossibleButtonConnections, getNextPossibleConnections } =
    context.getters

  const allButtonConnections = getNextPossibleButtonConnections({
    currentAction,
    button
  })

  const genericButtonConnections = allButtonConnections.filter(
    connection => !connection.connectionRules?.length
  )

  if (genericButtonConnections.length) {
    const allExistentConnections = getNextPossibleConnections({
      currentAction,
      considerMultipleConnections: true
    })

    const buttons = currentAction.getButtons()

    const hasMoreConnectionsThanButtons =
      (buttons?.length && allExistentConnections.length > buttons.length) ||
      (currentAction.content.list?.sections[0]?.options?.length &&
        allExistentConnections.length >
          currentAction.content.list.sections[0].options.length)

    if (hasMoreConnectionsThanButtons) {
      genericButtonConnections.forEach(btnConnection => {
        context.dispatch('removeBotConnection', btnConnection)
      })
    }
  }

  context.dispatch('setBotUnsavedChanges')
}

export const removeButtonConnection = (
  context,
  { currentAction, nextAction, button }
) => {
  if ((currentAction && !currentAction.id) || (nextAction && !nextAction.id)) {
    throw new Error(ERROR_ID_REQUIRED)
  }

  if (!button) {
    throw new Error('Button is required')
  }

  if (!button.value) {
    throw new Error('Button value is required')
  }

  const { getNextPossibleButtonConnections } = context.getters

  const allButtonConnections = getNextPossibleButtonConnections({
    currentAction,
    button
  })

  const specificButtonConnections = allButtonConnections.filter(
    connection =>
      connection.connectionRules && connection.connectionRules.length
  )

  if (specificButtonConnections.length) {
    specificButtonConnections.forEach(btnConnection => {
      context.dispatch('removeBotConnection', btnConnection)
    })
  }
}

export const setSelectedActionButton = ({ commit }, { actionId, button }) => {
  commit(types.SET_SELECTED_ACTION_BUTTON, {
    actionId,
    button
  })
}

export const addFirstAction = context => {
  const { firstBotAction } = context.getters

  if (firstBotAction) {
    throw new Error('First action already exists')
  }

  const notSpecifiedAction = ClientBotAction.getNotSpecifiedAction()

  context.dispatch('addAction', notSpecifiedAction)

  context.dispatch('addFirstConnection', {
    nextAction: notSpecifiedAction,
    connectionType: null
  })

  context.commit(types.SET_EDITING_ACTION, notSpecifiedAction)
}

export const addNewActionBefore = (context, actionId) => {
  const { getActionById, firstBotStructureConnection } = context.getters

  const botAction = getActionById(actionId)

  if (botAction.id === firstBotStructureConnection.nextActionId) {
    const notSpecifiedAction = ClientBotAction.getNotSpecifiedAction()

    context.dispatch('addAction', notSpecifiedAction)

    context.dispatch('addConnection', {
      currentAction: notSpecifiedAction,
      nextAction: botAction,
      connectionType: null
    })

    context.dispatch('updateBotConnection', {
      ...firstBotStructureConnection,
      nextActionId: notSpecifiedAction.id
    })

    context.commit(types.SET_EDITING_ACTION, notSpecifiedAction)
  }
}

export const resolveButtonToAppendNewConnection = (context, { actionId }) => {
  const {
    getActionById,
    getNextPossibleConnections,
    getNextPossibleButtonConnections,
    getSelectedButton
  } = context.getters

  const botAction = getActionById(actionId)

  const selectedButton = getSelectedButton(botAction)

  //considera o botão selecionado
  if (selectedButton) {
    const buttons = botAction.getButtons()

    const connectionType = botAction.isFallbackFlow()
      ? ConnectionTypeFallback.Fallback
      : null

    //carrega todas as conexões atreladas a interação atual
    const allPossibleConnections = getNextPossibleConnections({
      currentAction: botAction,
      connectionType,
      considerMultipleConnections: true
    })

    const genericConnection = allPossibleConnections.find(
      conn => !conn.connectionRules || !conn.connectionRules.length
    )

    //há conexão genérica? (ou seja, não especifica botão)
    if (genericConnection) {
      //quando houver mais botões do que conexões, a próxima conexão precisa ser somente do botão
      if (buttons.length > allPossibleConnections.length) {
        return selectedButton
      } else {
        //carrega a conexão que resolve o botão (pode ser genérica ou específica)
        const selectedButtonConnections = getNextPossibleButtonConnections({
          currentAction: botAction,
          button: selectedButton
        })

        const buttonConnection = selectedButtonConnections[0]

        //se não for genérica, precisa manter resolvendo a específica
        if (buttonConnection.id !== genericConnection.id) {
          return selectedButton
        }
      }
    }
  }

  return null
}
export const addNewActionAfter = async (
  context,
  { actionId, button, connectionType, connectionRules }
) => {
  const notSpecifiedAction = ClientBotAction.getNotSpecifiedAction()
  const { botActionsTree, getActionById, getPreviousConnections } =
    context.getters

  const currentActionData = botActionsTree.find(
    action => action.id === actionId
  )
  const botAction = getActionById(actionId)

  if (!connectionType) {
    connectionType = determineConnectionType(
      botAction,
      getPreviousConnections,
      botActionsTree
    )
  }

  await context.dispatch('addAction', notSpecifiedAction)
  await context.dispatch('fixActionConnections', {
    currentAction: botAction,
    connectionType,
    currentButton: button
  })

  if (!button) {
    button = await context.dispatch('resolveButtonToAppendNewConnection', {
      actionId
    })
  }

  const possibleConnections = getPossibleConnections(
    context,
    botAction,
    button,
    connectionType
  )

  if (button && (!connectionRules || connectionRules.length === 0)) {
    connectionRules = getDefaultConnectionRules(botAction, button)
  }

  if (possibleConnections.length) {
    processPossibleConnections(
      context,
      possibleConnections,
      notSpecifiedAction,
      currentActionData,
      connectionRules,
      connectionType
    )
  } else if (botAction.type === ClientBotActionTypes.ProActiveMessage) {
    await context.dispatch('addFirstConnection', {
      nextAction: notSpecifiedAction
    })
  } else {
    await handleNoPossibleConnections(
      context,
      botAction,
      notSpecifiedAction,
      button,
      connectionRules,
      connectionType
    )
  }

  context.commit(types.SET_EDITING_ACTION, notSpecifiedAction)

  return notSpecifiedAction
}

function determineConnectionType(
  botAction,
  getPreviousConnections,
  botActionsTree
) {
  let connectionType = botAction.isFallbackFlow()
    ? ConnectionTypeFallback.Fallback
    : null

  if (botAction.isFallbackFlow()) {
    connectionType = ConnectionTypeFallback.Fallback
  }

  const connections = getPreviousConnections({ currentAction: botAction })

  if (
    connections?.length &&
    connections.find(
      c =>
        c.type === ConnectionTypeFallback.Fallback &&
        botActionsTree.find(action => action.id === c.parentActionId)
    )
  ) {
    connectionType = ConnectionTypeFallback.Fallback
  }

  return connectionType
}

function getPossibleConnections(context, botAction, button, connectionType) {
  const { getNextPossibleButtonConnections, getNextPossibleConnections } =
    context.getters

  if (button) {
    return getNextPossibleButtonConnections({
      currentAction: botAction,
      button
    }).filter(
      connection =>
        connection.connectionRules && connection.connectionRules.length
    )
  }

  return getNextPossibleConnections({
    currentAction: botAction,
    connectionType,
    currentButton: button
  })
}

function getDefaultConnectionRules(botAction, button) {
  const fallbackButtonValues = [
    HTTP_STATUS_CODE_CLIENT_ERROR,
    CONDITIONAL_FALSE
  ]
  if (
    fallbackButtonValues.includes(button.value) ||
    botAction.type === ClientBotActionTypes.QuickReply
  ) {
    return getButtonConnectionRules(botAction, button)
  }
  return []
}

function processPossibleConnections(
  context,
  possibleConnections,
  notSpecifiedAction,
  currentActionData,
  connectionRules,
  connectionType
) {
  possibleConnections.forEach(connection => {
    const nextAction = context.getters.getActionById(connection.nextActionId)

    const actionTypeThatNeedsConnectionRules = [
      'conditional',
      'webhook',
      'office-hours',
      'quick-reply'
    ]

    if (
      currentActionData &&
      actionTypeThatNeedsConnectionRules.includes(currentActionData.type)
    ) {
      context.dispatch('updateBotConnection', {
        ...connection,
        nextActionId: notSpecifiedAction.id,
        connectionRules: connectionRules
      })
    } else {
      context.dispatch('updateBotConnection', {
        ...connection,
        nextActionId: notSpecifiedAction.id
      })
    }

    context.dispatch('addConnection', {
      currentAction: notSpecifiedAction,
      nextAction,
      connectionType
    })
  })
}

async function handleNoPossibleConnections(
  context,
  botAction,
  notSpecifiedAction,
  button,
  connectionRules,
  connectionType
) {
  if (button) {
    await context.dispatch('upsertButtonConnection', {
      currentAction: botAction,
      nextAction: notSpecifiedAction,
      button,
      connectionRules
    })
  } else {
    if (connectionRules) {
      await context.dispatch('addConnectionWithRules', {
        currentAction: botAction,
        nextAction: notSpecifiedAction,
        connectionType,
        connectionRules
      })
    } else {
      await context.dispatch('addConnection', {
        currentAction: botAction,
        nextAction: notSpecifiedAction,
        connectionType
      })
    }
  }
}

export const editAction = (context, actionId) => {
  context.dispatch('removeEditingAction')

  const { getActionById } = context.getters

  const botAction = getActionById(actionId)

  context.commit(types.SET_EDITING_ACTION, botAction)
}

export const resetActionType = (context, { actionId }) => {
  const { getActionById, currentEditingAction } = context.getters

  if (currentEditingAction.id !== actionId) {
    throw new Error('Cannot change type of a non current editing action')
  }

  const botAction = getActionById(actionId)

  if (botAction.isDirty()) {
    throw new Error('Cannot change type of a dirty action')
  }

  const notSpecifiedAction = ClientBotAction.getNotSpecifiedAction()

  notSpecifiedAction.id = actionId

  context.commit(types.UPDATE_ACTION, notSpecifiedAction)

  context.commit(types.SET_EDITING_ACTION, notSpecifiedAction)

  context.dispatch('setBotUnsavedChanges')
}

export const specifyAction = (
  context,
  { actionId, type, title, content, presetName }
) => {
  const { getActionById, currentEditingAction, getActionByDisplayName } =
    context.getters

  const botAction = getActionById(actionId)

  if (botAction.type !== ClientBotActionTypes.NotSpecified)
    throw new Error('Cannot specify a typed action')

  if (content) {
    content = JSON.parse(JSON.stringify(content))
  }

  const clonedBotAction = JSON.parse(JSON.stringify(botAction))

  const newBotAction = new ClientBotAction(clonedBotAction)

  const splittedType = type.split(':')

  newBotAction.type = splittedType[0]

  newBotAction.isNew = true

  let titleNumber = 1

  const originalTitle = title

  while (getActionByDisplayName(title)) {
    title = `${originalTitle} ${titleNumber}`

    titleNumber++
  }

  newBotAction.displayName = title

  const contentFunction = BotContentResolver[newBotAction.type]

  const isCloseConversation =
    newBotAction.type === ClientBotActionTypes.CloseConversation

  if (contentFunction) {
    let params = ''

    if (splittedType.length > 1) {
      params = splittedType[1]
    }

    newBotAction.content = contentFunction(
      isCloseConversation ? content : {},
      params
    )
  }

  if (!isCloseConversation)
    newBotAction.content = {
      ...newBotAction.content,
      ...content
    }

  newBotAction.presetName = presetName

  if (type === ClientBotActionTypes.ProActiveMessage) {
    context.dispatch('removeAction', botAction.id)

    context.commit(types.SET_PROACTIVE_MESSAGE, newBotAction)
  } else {
    context.commit(types.UPDATE_ACTION, newBotAction)

    context.dispatch('setBotUnsavedChanges')

    if (type === ClientBotActionTypes.AssignRoom) {
      context.dispatch('transformConnectionsInFallback', newBotAction.id)
    }
  }

  if (currentEditingAction && currentEditingAction.id === newBotAction.id) {
    context.commit(types.SET_EDITING_ACTION, newBotAction)
  }
}

export const transformConnectionsInFallback = (context, actionId) => {
  const { getActionById, getNextPossibleConnections } = context.getters

  const botAction = getActionById(actionId)

  const possibleConnections = getNextPossibleConnections({
    currentAction: botAction
  })

  possibleConnections.forEach(connection => {
    if (connection.connectionType !== ConnectionTypeFallback.Fallback) {
      const updateConnection = {
        ...connection,
        type: ConnectionTypeFallback.Fallback
      }

      context.dispatch('updateBotConnection', updateConnection)
    }
  })
}

/* eslint-disable */
export const removeAction = (context, actionId) => {
  const { getActionById, getPreviousConnections, getNextPossibleConnections } =
    context.getters
  const botAction = getActionById(actionId)

  const queryAction = {
    currentAction: botAction,
    considerMultipleConnections: true
  }

  const previousConnections = getPreviousConnections(queryAction)

  const nextConnections = [
    ...getNextPossibleConnections(queryAction),
    ...getNextPossibleConnections({
      ...queryAction,
      connectionType: ConnectionTypeFallback.Fallback
    })
  ]

  if (botAction.type === ClientBotActionTypes.ProActiveMessage) {
    //mensagem pró-ativa é uma ação fake na árvore, com isso, não tem conexão com demais
    context.commit(types.SET_PROACTIVE_MESSAGE, null)
    return
  }

  if (botAction.isNotSpecifiedAction()) {
    if (nextConnections.length > 1) {
      console.error('removeAction - nextConnections', nextConnections.length)
    }

    if (nextConnections.length) {
      //conecta a ação anterior com a ação posterior
      previousConnections.forEach(previousConnection => {
        const connection = {
          ...previousConnection,
          nextActionId: nextConnections[0].nextActionId
        }

        context.dispatch('updateBotConnection', connection)
      })

      nextConnections.forEach(nextConnection => {
        context.dispatch('removeBotConnection', nextConnection)
      })
    }

    if (!nextConnections.length) {
      previousConnections.forEach(previousConnection => {
        context.dispatch('removeBotConnection', previousConnection)
      })
    }

    context.dispatch('removeBotAction', botAction)

    context.dispatch('removeEditingAction')

    return
  }

  if (!previousConnections.length) {
    if (nextConnections.length !== 1) {
      throw new Error(
        'You cannot delete first element due to multiple destinations'
      )
    }

    nextConnections.forEach(nextConnection => {
      context.dispatch('removeBotConnection', nextConnection)
    })

    context.dispatch('removeBotAction', botAction)
    return
  }

  const sameDestination = nextConnections?.every(
    connection => connection.nextActionId === nextConnections[0].nextActionId
  )

  if (!sameDestination) {
    context.dispatch('setBranchWarningModal', {
      show: true,
      description: i18n?.t('bot.newChatBot.modals.excludeInteraction'),
      actionId
    })

    return
  }

  let connectionsToUpdate = previousConnections

  const { botActionsTree } = context.getters

  const indexTreeAction = (botActionsTree || []).findIndex(
    treeAction => treeAction.id === botAction.id
  )

  if (previousConnections.length > 1 && indexTreeAction >= 0) {
    //quando a mesma ação pertence a mais de uma ramificação
    const currentPreviousAction = botActionsTree[indexTreeAction - 1]

    if (currentPreviousAction) {
      connectionsToUpdate = previousConnections.filter(
        connection =>
          connection.parentActionId === currentPreviousAction.id &&
          connection.nextActionId === botAction.id
      )
    }

    if (currentPreviousAction.content?.buttons?.length) {
      const selectedButton = currentPreviousAction.content.buttons.find(
        b => b.selected
      )
      connectionsToUpdate = connectionsToUpdate.filter(
        connection =>
          !connection.connectionRules?.length ||
          connection.connectionRules.find(
            cr =>
              cr.values.includes(selectedButton.id) ||
              cr.values.includes(selectedButton.value)
          )
      )
    }
  }

  if (nextConnections.length) {
    connectionsToUpdate.forEach(connectionToUpdate => {
      const toSend = {
        ...connectionToUpdate,
        nextActionId: nextConnections[0].nextActionId
      }

      if (toSend.nextActionId === toSend.parentActionId)
        context.dispatch('removeBotConnection', toSend)
      else context.dispatch('updateBotConnection', toSend)
    })
  } else {
    connectionsToUpdate.forEach(previousConnection => {
      context.dispatch('removeBotConnection', previousConnection)
    })
  }

  if (previousConnections.length === 1) {
    //nesse caso, o botAction será removido, logo as conexões ser removidas
    nextConnections.forEach(nextConnection => {
      context.dispatch('removeBotConnection', nextConnection)
    })

    context.dispatch('removeBotAction', botAction)
  }
}
/* eslint-enable */

export const setBranchWarningModal = (context, payload) =>
  context.commit(types.SET_BRANCH_WARNING_MODAL, payload)

export const removeEditingAction = context =>
  context.commit(types.REMOVE_EDITING_ACTION)

export const addBotConnection = (context, connection) => {
  context.commit(types.ADD_CONNECTION, connection)

  context.dispatch('setBotUnsavedChanges')
}

export const updateBotConnection = (context, connection) => {
  context.commit(types.UPDATE_CONNECTION, connection)

  context.dispatch('setBotUnsavedChanges')
}

export const removeBotConnection = (context, connection) => {
  context.commit(types.REMOVE_CONNECTION, connection)

  context.dispatch('setBotUnsavedChanges')
}

export const removeBotAction = (context, action) => {
  context.commit(types.REMOVE_ACTION, action)

  context.dispatch('setBotUnsavedChanges')
}

export const closeConversationOnAllBranches = context => {
  const { getActionsWithoutDirection, getButtonsWithoutConnection } =
    context.getters

  const actionsWithoutDirection = getActionsWithoutDirection()

  const actionTypesEndConversation = [
    ClientBotActionTypes.CloseConversation,
    ClientBotActionTypes.AssignRoom
  ]

  const matchedActions = actionsWithoutDirection.filter(action => {
    if (action.type === ClientBotActionTypes.AssignRoom) {
      return action?.content && action?.content?.mustHasAvailability === true
    } else {
      return !actionTypesEndConversation.includes(action.type)
    }
  })

  matchedActions.forEach(action => {
    const buttons = getButtonsWithoutConnection(action)

    if (!buttons || !buttons.length) {
      context.dispatch('createCloseAction', {
        currentAction: action
      })
    }

    buttons.forEach(btn => {
      context.dispatch('createCloseAction', {
        currentAction: action,
        connectionRules: action.getConnectionRule(btn),
        currentButton: btn
      })
    })
  })
}

export const createCloseAction = (context, connection) => {
  const { getActionByDisplayName, currentChatBotFluxChannel } = context.getters

  let titleNumber = 1

  let title = i18n?.t(
    `bot.newChatBot.actions.closeConversation.${currentChatBotFluxChannel}.title`
  )

  const originalTitle = title

  while (getActionByDisplayName(title)) {
    title = `${originalTitle} ${titleNumber}`
    titleNumber++
  }

  const closeConversationAction = new ClientBotAction({
    type: ClientBotActionTypes.CloseConversation,
    displayName: title,
    content: {
      messages: [
        {
          text: i18n?.t('bot.newChatBot.store.seeYou')
        }
      ]
    }
  })

  context.dispatch('addAction', closeConversationAction)

  connection.nextAction = closeConversationAction
  context.dispatch('addConnection', connection)
}

export const removeAllNotSpecifiedInteractions = context => {
  const { botActions } = context.getters

  const notSpecifiedActions = botActions.filter(
    action => action.type === ClientBotActionTypes.NotSpecified
  )

  notSpecifiedActions.forEach(action => {
    context.dispatch('removeAction', action.id)
  })
}

export const clearAllValidationMessages = context => {
  const { botActions } = context.getters

  const quickRepliesWithProperty = botActions.filter(
    action =>
      action.type === ClientBotActionTypes.QuickReply &&
      action.content &&
      action.content.property
  )

  quickRepliesWithProperty.forEach(action => {
    if (action.content.property && action.content.property.type !== 'email') {
      action.content.validationMessage = ''
    }
  })
}

export const toggleEditingPersona = ({ commit }) => {
  commit(types.TOGGLE_EDITING_PERSONA)
}

export const closeEditingPersona = ({ commit }) => {
  commit(types.CLOSE_EDITING_PERSONA)
}

export const updateBotPersona = ({ commit }, persona) => {
  commit(types.SET_PERSONA, persona)
}

export const setSidebarFocusEl = ({ commit }, ref) => {
  commit(types.SET_SIDEBAR_EL_FOCUS, ref)
}

export const getButtonConnectionRules = (botAction, button) => {
  if (button.value == HTTP_STATUS_CODE_CLIENT_ERROR) {
    return [
      {
        source: 'CURRENT_SESSION',
        matchType: '$eq',
        values: [
          HTTP_STATUS_CODE_CLIENT_ERROR,
          '@HTTP_STATUS_CODE_SERVER_ERROR',
          '@HTTP_STATUS_CODE_REDIRECT',
          '@HTTP_STATUS_CODE_INFORMATION'
        ],
        referenceProperty: `properties.SESSION.${botAction.id}.value.status`,
        referenceValue: null
      }
    ]
  }

  if (button.value == CONDITIONAL_FALSE) {
    return [
      {
        source: 'CURRENT_SESSION',
        matchType: '$eq',
        values: [CONDITIONAL_FALSE],
        referenceProperty: `properties.SESSION.${botAction.id}.value`,
        referenceValue: null
      }
    ]
  }

  if (botAction.type === ClientBotActionTypes.QuickReply)
    return [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: [button.id]
      }
    ]

  return []
}

export const setBotOfficeHours = (context, payload) => {
  context.commit(types.SET_BOT_OFFICE_HOURS, payload)
}

export const setCalendarIdInAction = (context, payload) => {
  context.commit(types.SET_CALENDAR_ID_IN_ACTION, payload)
}

export const setInvalidConfiguration = (context, payload) => {
  const { invalidConfigurations } = context.getters
  if (!invalidConfigurations.find(config => config.id === payload.id)) {
    context.commit(types.SET_INVALID_CONFIGURATION, payload)
  }
}

export const removeInvalidConfiguration = (context, configurationId) => {
  context.commit(types.REMOVE_INVALID_CONFIGURATION, configurationId)
}

export const clearInvalidConfigurations = context => {
  context.commit(types.CLEAR_INVALID_CONFIGURATION)
}

export const getInvalidActions = context => {
  const { botActions } = context.getters

  const errosAction = []

  if (botActions.length) {
    botActions.forEach(action => {
      if (action && action.validate && action.isOnTree) {
        const validate = action.validate()
        if (validate.status === false) {
          errosAction.push({ error: validate, action })
        }
      }
    })

    context.commit(types.ACTIONS_INVALID, errosAction)
  }
}

export const updateContentMedias = (context, payload) => {
  context.commit(types.UPDATE_ACTION_CONTENT_MEDIAS, payload)
}

export const updateContentProperty = (context, payload) => {
  context.commit(types.UPDATE_ACTION_CONTENT_PROPERTY, payload)
}
