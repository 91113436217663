export const SET_FORMS = 'SET_FORMS'
export const SET_FORMS_LOADING = 'SET_FORMS_LOADING'
export const SET_FORM_SELECTED = 'SET_FORM_SELECTED'
export const SET_AREA = 'SET_AREA'
export const SET_ID_LAST_FORM = 'SET_ID_LAST_FORM'
export const SET_EMBEDDED_CODE = 'SET_EMBEDDED_CODE'
export const SET_FORM_SELECTED_LOADING = 'SET_FORM_SELECTED_LOADING'
export const SET_FORMS_ERROR = 'SET_FORMS_ERROR'
export const SET_FORM_SELECTED_LINK = 'SET_FORM_SELECTED_LINK'
export const SET_FORM_SELECTED_LINK_STYLE = 'SET_FORM_SELECTED_LINK_STYLE'
export const SET_FORM_SELECTED_ERROR = 'SET_FORM_SELECTED_ERROR'
