import { ActionContext } from 'vuex'

import { RootState, FeatureToggleState } from '@/store/interfaces'

import * as types from './mutations-types'

export const setFeaturesToggle = async (
  context: ActionContext<FeatureToggleState, RootState>
) => {
  const featureFlags = context.getters?.subDomainStatus?.featureFlags
  context.commit(types.SET_FEATURE_FLAGS, featureFlags)
}
