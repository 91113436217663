const CONFIRM_ORDER = 'confirm-order'

const SET_QUANTITY = 'set-quantity'

const IDENTIFY_WHO_THE_SELLER_WAS = 'identify-who-the-seller-was'

const CONNECT_TO_THE_SALES_TEAM = 'connect-to-the-sales-team'

const CHECK_IF_YOU_ARE_ALREADY_A_CUSTOMER =
  'check-if-you-are-already-a-customer'

const REASON_FOR_CONVERSATION = 'reason-for-conversation'

const OFFER_PRODUCTS = 'offer-products'

const CLOSING_MESSAGE = 'closing-message'

const COLLECT_THE_CUSTOMERS_NAME = 'collect-the-customers-name'

const COMMON = 'common'

const CONFIRM_COLLECTED_NAME = 'confirm-collected-name'

const COLLECT_CUSTOMER_EMAIL = 'collect-customer-email'

const CONFIRM_COLLECTED_EMAIL = 'confirm-collected-email'

/* eslint-disable sonarjs/no-duplicate-string */

export default channel => {
  let connections = []

  if (channel !== 'web') {
    connections = [
      {
        id: 'step-0',
        type: COMMON,
        parentActionId: '',
        nextActionId: 'initial-greeting',
        connectionRules: []
      }
    ]
  }

  connections = [
    ...connections,
    {
      id: 'step-1',
      type: COMMON,
      parentActionId: channel !== 'web' ? 'initial-greeting' : '',
      nextActionId: COLLECT_THE_CUSTOMERS_NAME,
      connectionRules: []
    },
    {
      id: 'step-2',
      type: COMMON,
      parentActionId: COLLECT_THE_CUSTOMERS_NAME,
      nextActionId: CONFIRM_COLLECTED_NAME,
      connectionRules: []
    },
    {
      id: 'step-3',
      type: COMMON,
      parentActionId: COLLECT_CUSTOMER_EMAIL,
      nextActionId: CONFIRM_COLLECTED_EMAIL,
      connectionRules: []
    },
    {
      id: 'step-4',
      type: COMMON,
      parentActionId: CONFIRM_COLLECTED_EMAIL,
      nextActionId: COLLECT_CUSTOMER_EMAIL,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-email-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-5',
      type: COMMON,
      parentActionId: CONFIRM_COLLECTED_NAME,
      nextActionId: CLOSING_MESSAGE,
      connectionRules: []
    },
    {
      id: 'step-6',
      type: COMMON,
      parentActionId: CONFIRM_COLLECTED_EMAIL,
      nextActionId: OFFER_PRODUCTS,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-email-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-7',
      type: 'fallback',
      parentActionId: 'connect-with-the-support-team',
      nextActionId: CLOSING_MESSAGE,
      connectionRules: []
    },
    {
      id: 'step-8',
      type: COMMON,
      parentActionId: REASON_FOR_CONVERSATION,
      nextActionId: 'connect-with-the-support-team',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['reason-for-conversation-doubts'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-9',
      type: COMMON,
      parentActionId: REASON_FOR_CONVERSATION,
      nextActionId: CHECK_IF_YOU_ARE_ALREADY_A_CUSTOMER,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['reason-for-conversation-purchase'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-10',
      type: 'fallback',
      parentActionId: CONNECT_TO_THE_SALES_TEAM,
      nextActionId: CLOSING_MESSAGE,
      connectionRules: []
    },
    {
      id: 'step-11',
      type: COMMON,
      parentActionId: CHECK_IF_YOU_ARE_ALREADY_A_CUSTOMER,
      nextActionId: CONNECT_TO_THE_SALES_TEAM,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['check-if-you-are-already-a-customer-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-12',
      type: COMMON,
      parentActionId: CHECK_IF_YOU_ARE_ALREADY_A_CUSTOMER,
      nextActionId: IDENTIFY_WHO_THE_SELLER_WAS,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['check-if-you-are-already-a-customer-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-13',
      type: COMMON,
      parentActionId: IDENTIFY_WHO_THE_SELLER_WAS,
      nextActionId: 'connect-with-seller-1',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['identify-who-the-seller-was-seller-0'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-14',
      type: 'fallback',
      parentActionId: 'connect-with-seller-1',
      nextActionId: CLOSING_MESSAGE,
      connectionRules: []
    },
    {
      id: 'step-15',
      type: COMMON,
      parentActionId: IDENTIFY_WHO_THE_SELLER_WAS,
      nextActionId: 'connect-with-seller-2',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['identify-who-the-seller-was-seller-1'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-16',
      type: 'fallback',
      parentActionId: 'connect-with-seller-2',
      nextActionId: CLOSING_MESSAGE,
      connectionRules: []
    },
    {
      id: 'step-17',
      type: COMMON,
      parentActionId: IDENTIFY_WHO_THE_SELLER_WAS,
      nextActionId: 'connect-with-seller-3',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['identify-who-the-seller-was-seller-2'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-18',
      type: 'fallback',
      parentActionId: 'connect-with-seller-3',
      nextActionId: CLOSING_MESSAGE,
      connectionRules: []
    },
    {
      id: 'step-19',
      type: COMMON,
      parentActionId: CONFIRM_COLLECTED_NAME,
      nextActionId: COLLECT_CUSTOMER_EMAIL,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-name-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-20',
      type: COMMON,
      parentActionId: CONFIRM_COLLECTED_NAME,
      nextActionId: COLLECT_THE_CUSTOMERS_NAME,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-name-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-21',
      type: COMMON,
      parentActionId: OFFER_PRODUCTS,
      nextActionId: REASON_FOR_CONVERSATION,
      connectionRules: []
    },
    {
      id: 'step-22',
      type: COMMON,
      parentActionId: OFFER_PRODUCTS,
      nextActionId: 'webcams',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['offer-products-webcams'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-23',
      type: COMMON,
      parentActionId: 'webcams',
      nextActionId: OFFER_PRODUCTS,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['webcams-webcam-3'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-24',
      type: COMMON,
      parentActionId: OFFER_PRODUCTS,
      nextActionId: 'headphones',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['offer-products-headphones'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-25',
      type: COMMON,
      parentActionId: OFFER_PRODUCTS,
      nextActionId: 'chairs',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['offer-products-chairs'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-26',
      type: COMMON,
      parentActionId: 'chairs',
      nextActionId: OFFER_PRODUCTS,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['chairs-chair-3'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-27',
      type: COMMON,
      parentActionId: 'headphones',
      nextActionId: OFFER_PRODUCTS,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['headphones-earphone-3'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-28',
      type: COMMON,
      parentActionId: 'chairs',
      nextActionId: SET_QUANTITY,
      connectionRules: []
    },
    {
      id: 'step-29',
      type: COMMON,
      parentActionId: SET_QUANTITY,
      nextActionId: CONFIRM_ORDER,
      connectionRules: []
    },
    {
      id: 'step-30',
      type: COMMON,
      parentActionId: CONFIRM_ORDER,
      nextActionId: OFFER_PRODUCTS,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-order-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-31',
      type: COMMON,
      parentActionId: 'webcams',
      nextActionId: SET_QUANTITY,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['webcams-webcam-0'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-32',
      type: COMMON,
      parentActionId: 'webcams',
      nextActionId: SET_QUANTITY,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['webcams-webcam-1'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-33',
      type: COMMON,
      parentActionId: 'webcams',
      nextActionId: SET_QUANTITY,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['webcams-webcam-2'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-34',
      type: COMMON,
      parentActionId: 'headphones',
      nextActionId: SET_QUANTITY,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['headphones-earphone-0'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-35',
      type: COMMON,
      parentActionId: 'headphones',
      nextActionId: SET_QUANTITY,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['headphones-earphone-1'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-36',
      type: COMMON,
      parentActionId: 'headphones',
      nextActionId: SET_QUANTITY,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['headphones-earphone-2'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-37',
      type: COMMON,
      parentActionId: CONFIRM_ORDER,
      nextActionId: CHECK_IF_YOU_ARE_ALREADY_A_CUSTOMER,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-order-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-38',
      type: COMMON,
      parentActionId: IDENTIFY_WHO_THE_SELLER_WAS,
      nextActionId: CONNECT_TO_THE_SALES_TEAM,
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['identify-who-the-seller-was-seller-3'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    }
  ]

  return connections
}
