import { ASSIGN_TO } from '@/modules/Bot/enums/assign-to.enum'
import { generateDefaultButton } from '@/modules/Bot/store/getters/template-models'

import i18n from '@/common/i18n'

/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-nested-template-literals */
export default (i18nTemplatesKey, channel, getPersonProperty, userLogged) => {
  let actions = [
    {
      id: 'collect-customer-name',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.collect-customer-name.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: getPersonProperty('name'),
        prompt: i18n?.t(
          `${i18nTemplatesKey}.collect-customer-name.description`
        ),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'confirm-collected-name',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-collected-name.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-collected-name.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-name.buttons.item0`,
            'confirm-collected-name-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-name.buttons.item1`,
            'confirm-collected-name-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'collect-customer-email',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.collect-customer-email.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: getPersonProperty('email'),
        prompt: i18n?.t(
          `${i18nTemplatesKey}.collect-customer-email.description`
        ),
        buttons: [],
        validationMessage: i18n?.t(
          `${i18nTemplatesKey}.collect-customer-email.validationMessage`
        ),
        validationMessages: [
          {
            level: 0,
            message: i18n?.t(
              `${i18nTemplatesKey}.collect-customer-email.validationMessages.item0`
            )
          }
        ],
        allowUserInput: true
      }
    },
    {
      id: 'confirm-collected-email',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-collected-email.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-collected-email.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-email.buttons.item0`,
            'confirm-collected-email-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-email.buttons.item1`,
            'confirm-collected-email-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'connect-with-the-support-team',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.connect-with-the-support-team.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.connect-with-the-support-team.description.item0`
            )
          }
        ],
        referenceProperty:
          userLogged && userLogged.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: (userLogged && userLogged.id) || '',
        successMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.connect-with-the-support-team.successMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text:
                channel === 'web'
                  ? i18n?.t(
                      `${i18nTemplatesKey}.connect-with-the-support-team.fallbackMessageContent.item0`
                    )
                  : ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: channel === 'web',
        selectedView: 'available'
      }
    },
    {
      id: 'closing-message',
      displayName: i18n?.t(`${i18nTemplatesKey}.closing-message.displayName`),
      type: 'close-conversation',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.closing-message.description.item0`
            )
          }
        ]
      }
    }
  ]

  if (channel !== 'web') {
    actions = [
      {
        id: 'initial-greeting',
        displayName: i18n?.t(
          `${i18nTemplatesKey}.initial-greeting.displayName`
        ),
        type: 'message',
        content: {
          messages: [
            {
              text: i18n?.t(
                `${i18nTemplatesKey}.initial-greeting.description.item0`
              )
            }
          ]
        }
      },
      ...actions
    ]
  }

  return actions
}
