import { ActionContext } from 'vuex'

import { OrganizationEnum } from '@/common/helpers/enums/organization.enum'

import PersonsService from '@/common/services/persons'

import {
  RootState,
  OrganizationState,
  Organization,
  FilterOrganization,
  Payload,
  PayloadToggle
} from '@/store/interfaces'

import * as types from './mutations-types'

export const getOrganizations = async (
  context: ActionContext<OrganizationState, RootState>,
  filter: FilterOrganization
) => {
  const personService = new PersonsService()
  const result = await personService.getFilteredOrganizations(filter)
  context.commit(types.SET_ORGANIZATIONS, result)
}

export const loadMoreOrganizations = async (
  context: ActionContext<OrganizationState, RootState>,
  filter: FilterOrganization
) => {
  const personService = new PersonsService()
  const result = await personService.getFilteredOrganizations(filter)
  context.commit(types.APPEND_ORGANIZATIONS, result)
}

export const createOrganization = async (
  context: ActionContext<OrganizationState, RootState>,
  payload: Payload
) => {
  const personService = new PersonsService()
  const result = await personService.createOrganization({
    ...payload.organization
  })
  const counter = payload.counter + 1
  context.commit(types.CREATE_ORGANIZATION, result)
  context.commit(types.SET_ORGANIZATIONS_COUNT, counter)
}

export const updateOrganization = async (
  context: ActionContext<OrganizationState, RootState>,
  payload: Organization
) => {
  const personService = new PersonsService()
  const organization = await personService.updateOrganization(payload.id, {
    ...payload,
    id: undefined
  })
  context.commit(types.UPDATE_ORGANIZATION, organization)
}

export const toggleOrganization = async (
  context: ActionContext<OrganizationState, RootState>,
  payload: PayloadToggle
) => {
  const personService = new PersonsService()
  await personService.toggleOrganization(payload.id, payload.toggle)
  context.commit(types.TOGGLE_ORGANIZATION, payload)
}

export const disableOrganizations = async (
  context: ActionContext<OrganizationState, RootState>,
  organizations: Array<string>
) => {
  const personService = new PersonsService()
  await personService.toggleOrganizations(
    organizations,
    OrganizationEnum.DISABLE
  )
  context.commit(types.DISABLE_ORGANIZATIONS, organizations)
}

export const enableOrganizations = async (
  context: ActionContext<OrganizationState, RootState>,
  organizations: Array<string>
) => {
  const personService = new PersonsService()
  await personService.toggleOrganizations(
    organizations,
    OrganizationEnum.ENABLE
  )
  context.commit(types.ENABLE_ORGANIZATIONS, organizations)
}

export const countOrganizations = async (
  context: ActionContext<OrganizationState, RootState>,
  filter: FilterOrganization
) => {
  const personService = new PersonsService()
  const result = await personService.countFilteredOrganizations(filter)
  context.commit(types.SET_ORGANIZATIONS_COUNT, result)
}

export const membersOrganization = async (
  context: ActionContext<OrganizationState, RootState>,
  filter: FilterOrganization
) => {
  const personService = new PersonsService()
  const { items, total } = await personService.getFilteredMembersOrganization(
    filter
  )
  context.commit(types.SET_ORGANIZATION_MEMBERS, items)
  context.commit(types.SET_ORGANIZATION_MEMBERS_COUNT, total)
}

export const countMembersOrganization = async (
  context: ActionContext<OrganizationState, RootState>,
  filter: FilterOrganization
) => {
  const personService = new PersonsService()
  const result = await personService.countFilteredMembersOrganization(filter)
  context.commit(types.SET_ORGANIZATION_MEMBERS_COUNT, result)
}

export const loadMoreMembersOrganization = async (
  context: ActionContext<OrganizationState, RootState>,
  filter: FilterOrganization
) => {
  const personService = new PersonsService()
  const { items } = await personService.getFilteredMembersOrganization(filter)
  context.commit(types.APPEND_ORGANIZATION_MEMBERS, items)
}
