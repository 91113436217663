import { ActionContext } from 'vuex'

import i18n from '@/common/i18n'

import { ConditionComparator } from '@/common/helpers/enums/condition-comparator'
import { ConditionType } from '@/common/helpers/enums/condition-type'
import { InstagramPayload } from '@/common/helpers/interfaces/instagram-payload'
import { objectId } from '@/common/helpers/utils/object-id'

import BotsServices from '@/common/services/bots'
import FluxesService from '@/common/services/fluxes'

import {
  RootState,
  BotState,
  GroupsWithoutConnection,
  EmptyFields
} from '@/store/interfaces'

import * as types from './mutations-types'

export const getFlux = async (
  context: ActionContext<BotState, RootState>,
  fluxId: string
) => {
  const fluxesService = new FluxesService()
  const flux = await fluxesService.getFlux(fluxId)

  context.commit(types.SET_BOT_ID, flux.botId)
  context.commit(types.SET_CURRENT_CHATBOT_FLUX, flux)

  const botsServices = new BotsServices()
  const chatBot = await botsServices.getBot(flux.botId)

  context.commit(types.SET_CURRENT_CHATBOT, chatBot)

  context.commit(types.SET_CURRENT_CHATBOT_FLUX_CHANNEL, flux.channel)

  const defaultPersona = {
    name: 'Octabot',
    thumbUrl: `https://cdn.octadesk.com/chatbot-thumbs/${flux.channel}.svg`
  }

  const persona = chatBot.draft.persona ? chatBot.draft.persona : defaultPersona
  context.commit(types.SET_CURRENT_CHATBOT_PERSONA, persona)

  const { chatBotFluxSpecificPagesConditions } = context.getters

  if (chatBotFluxSpecificPagesConditions.length) {
    context.dispatch('changeCurrentConditionsView', { view: 'specific-pages' })
  } else {
    context.dispatch('changeCurrentConditionsView', { view: 'all-pages' })
  }
}

export const setInvalidConfiguration = (
  context: ActionContext<BotState, RootState>,
  payload: Array<any>
) => {
  const { invalidConfigurations } = context.getters
  if (!invalidConfigurations.find((config: Array<any>) => config === payload)) {
    context.commit(types.SET_INVALID_CONFIGURATION, payload)
  }
}

export const removeInvalidConfiguration = (
  context: ActionContext<BotState, RootState>,
  configurationId: string
) => {
  context.commit(types.REMOVE_INVALID_CONFIGURATION, configurationId)
}

export const clearInvalidConfigurations = (
  context: ActionContext<BotState, RootState>
) => {
  context.commit(types.CLEAR_INVALID_CONFIGURATION)
}

export const addInstagramPageCondition = (
  context: ActionContext<BotState, RootState>,
  selected: InstagramPayload
) => {
  context.dispatch('addPageCondition', {
    url: selected.businessAccountId,
    isException: false
  })
}

export const addPageCondition = (
  context: ActionContext<BotState, RootState>,
  {
    url,
    isException,
    ignoreEvent
  }: {
    url?: string
    isException?: boolean
    ignoreEvent?: boolean
  }
) => {
  context.dispatch('addCondition', {
    value: url,
    name: ConditionType.PAGE_URL,
    description: i18n?.t('flux.conditions.pageUrl'),
    comparator: isException
      ? ConditionComparator.NO_CONTAINS
      : ConditionComparator.CONTAINS,
    comparatorDescription: i18n?.t(
      `flux.comparators.${isException ? 'no' : ''}contains`
    ),
    ignoreEvent
  })
}

export const addInteractionTypeCondition = (
  context: ActionContext<BotState, RootState>,
  selected: Array<any>
) => {
  context.dispatch('addCondition', {
    value: selected.length ? selected : ['none'],
    name: ConditionType.INTERACTION_TYPE,
    description: i18n?.t('flux.condition.interactionTypes'),
    comparator: ConditionComparator.CONTAINS,
    comparatorDescription: i18n?.t(`flux.comparators.contains`)
  })
}

export const addCondition = (
  context: ActionContext<BotState, RootState>,
  {
    value,
    name,
    description,
    comparator,
    comparatorDescription,
    ignoreEvent
  }: {
    value?: string
    name?: string
    description?: string
    comparator?: string
    comparatorDescription?: string
    ignoreEvent?: boolean
  }
) => {
  const conditionId = objectId()
  const comparatorId = objectId()
  const condition = {
    id: conditionId,
    field: {
      name,
      component: 'text',
      types: ['flux'],
      dynamic: false,
      isFormField: false,
      description,
      options: []
    },
    comparator: {
      id: comparatorId,
      type: comparator,
      description: comparatorDescription,
      _id: comparatorId
    },
    _id: conditionId,
    value
  }

  context.commit(types.ADD_PAGE_CONDITION, condition)

  if (ignoreEvent === undefined || !ignoreEvent)
    context.dispatch('setBotUnsavedChanges')
}

export const changeCurrentConditionsView = (
  context: ActionContext<BotState, RootState>,
  {
    view
  }: {
    view?: string
    ignoreEvent?: boolean
  }
) => {
  context.commit(types.SET_CURRENT_CONDITIONS_VIEW, view)
}

export const resetBotUnsavedChanges = (
  context: ActionContext<BotState, RootState>
) => {
  context.commit(types.SET_BOT_UNSAVED_CHANGES, false)
}

export const setActivationChatBot = (
  context: ActionContext<BotState, RootState>,
  status: boolean
) => {
  context.commit(types.SET_ACTIVATION_CHATBOT, status)
}

export const setBotUnsavedChanges = (
  context: ActionContext<BotState, RootState>
) => {
  context.commit(types.SET_BOT_UNSAVED_CHANGES, true)
}

export const setGroupsWithoutConnection = (
  context: ActionContext<BotState, RootState>,
  payload: GroupsWithoutConnection
) => {
  context.commit(types.SET_GROUPS_WITHOUT_CONNECTION, payload)
}

export const setEmptyFields = (
  context: ActionContext<BotState, RootState>,
  payload: EmptyFields
) => {
  context.commit(types.SET_EMPTY_FIELDS, payload.fields)
}

export const removePageCondition = (
  context: ActionContext<BotState, RootState>,
  conditionToRemove: Array<any>
) => {
  context.commit(types.REMOVE_PAGE_CONDITION, conditionToRemove)
}

export const setShowExitWarning = (
  context: ActionContext<BotState, RootState>,
  shouldShowExitWarning: boolean
) => {
  context.commit(types.SET_SHOW_EXIT_WARNING, shouldShowExitWarning)
}

export const resetCurrentChatBotFlux = (
  context: ActionContext<BotState, RootState>
) => {
  context.commit(types.SET_CURRENT_CHATBOT_FLUX)

  context.commit(types.SET_CURRENT_CHATBOT)

  context.dispatch('resetBotUnsavedChanges')

  context.dispatch('clearInvalidConfigurations')
}

export const setCurrentChatBotFlux = async (
  context: ActionContext<BotState, RootState>,
  botFluxId: string
) => {
  const fluxesService = new FluxesService()
  const botFlux = await fluxesService.getFlux(botFluxId)

  context.commit(types.SET_CURRENT_CHATBOT_FLUX, botFlux)

  const { chatBotFluxSpecificPagesConditions } = context.getters

  if (chatBotFluxSpecificPagesConditions.length) {
    context.dispatch('changeCurrentConditionsView', { view: 'specific-pages' })
  } else {
    context.dispatch('changeCurrentConditionsView', { view: 'all-pages' })
  }
}

export const updateFlux = async (
  context: ActionContext<BotState, RootState>,
  activateFlux?: boolean
) => {
  const { currentChatBotFlux, getChatBotConditionsToSave } = context.getters

  const conditions = getChatBotConditionsToSave

  const flux = {
    ...currentChatBotFlux,
    ...conditions,
    enabled: activateFlux || currentChatBotFlux.enabled
  }
  const fluxesService = new FluxesService()
  const { data } = await fluxesService.updateFlux(flux)

  context.commit(types.UPSERT_CHATBOT_FLUX, data)

  await context.dispatch('setCurrentChatBotFlux', flux.id)
  return data
}

export const updateBotPersona = (
  context: ActionContext<BotState, RootState>,
  persona: Array<any>
) => {
  context.commit(types.SET_CURRENT_CHATBOT_PERSONA, persona)
}
