import { services, headers } from '@octadesk-tech/services'

import store from '@/store'

let _client

const getClient = async () => {
  if (_client) {
    return _client
  }
  return (_client = await services.chatAgents.getClient())
}

export const setAgentStatus = status => {
  return getClient()
    .then(client =>
      client.put(
        `/${store.getters.userLogged.id}/status`,
        { status },
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)
}

export const getAgent = () => {
  return getClient()
    .then(client =>
      client.get(
        `/${store.getters.userLogged.id}`,
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)
}

export const getAgents = () => {
  return getClient()
    .then(client => client.get('/', headers.getAuthorizedHeaders()))
    .then(res => res.data.filter(a => a.active))
}

export const getOnlineAgents = () => {
  return getClient()
    .then(client =>
      client.get('/filter/status/0', headers.getAuthorizedHeaders())
    )
    .then(res => res.data)
}

export const setAgentAvailability = availability => {
  return getClient()
    .then(client =>
      client.put(
        `/${store.getters.userLogged.id}/availability`,
        { availability },
        headers.getAuthorizedHeaders()
      )
    )
    .then(res => res.data)
}
