import { NotificationState, NotificationSettingsState } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.UPDATE_NOTIFICATION_PERMISSION](
    state: NotificationState,
    permission: NotificationPermission
  ) {
    state.notificationPermission = permission
  },
  [types.UPDATE_NOTIFICATION_SETTINGS](
    state: NotificationState,
    settings: NotificationSettingsState
  ) {
    state.notificationSettings = settings
  }
}
