import { GlobalState } from '@/store/interfaces'

const state: GlobalState = {
  agents: [],
  groups: [],
  config: null,
  reloadPage: false
}

export default state
