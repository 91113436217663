import { ActionContext } from 'vuex'

import { RootState, LoaderState } from '@/store/interfaces'

import * as types from './mutations-types'

export const showLoader = (
  context: ActionContext<LoaderState, RootState>,
  show: boolean
) => {
  context.commit(types.SHOW_LOADER, show)
}

export const setTextLoader = (
  context: ActionContext<LoaderState, RootState>,
  text: boolean
) => {
  context.commit(types.SET_TEXT_LOADER, text)
}
