import { headers } from '@octadesk-tech/services'

import { createClient } from '@/modules/Chat/services/http'

const getClient = () => createClient('reminders')

export const createReminder = async data =>
  getClient()
    .then(client => client.post('/', data, headers.getAuthorizedHeaders()))
    .then(response => response.data)

export const updateReminder = async data => {
  return getClient()
    .then(client =>
      client.patch(`/${data._id}`, data, headers.getAuthorizedHeaders())
    )
    .then(response => response.data)
}

export const markReminderAsDone = async _id => {
  return getClient()
    .then(client =>
      client.post(`${_id}/done`, {}, headers.getAuthorizedHeaders())
    )
    .then(response => response.data)
}

export const deactivateReminder = async _id =>
  getClient()
    .then(client => client.delete(`/${_id}`, headers.getAuthorizedHeaders()))
    .then(response => response.data)
