import { headers } from '@octadesk-tech/services'

import { createClient } from '@/modules/Chat/services/http'

const API_NAME = 'customFields'

const getClient = () => createClient(API_NAME)

export const getCustomFields = async type => {
  try {
    return getClient()
      .then(client =>
        client.request({
          method: 'GET',
          url: `/system-type/${type}?showEnabledItems=true`,
          ...headers.getAuthorizedHeaders()
        })
      )
      .then(response => response.data)
  } catch (e) {
    console.error(`Error at getCustomFields: ${e}`)
  }
}
