export const parseUtmsToRecord = (utms: any): Array<Record<string, string>> => {
  const record: Record<any, any> = {
    source: 'not_provided',
    campaign: 'not_provided',
    medium: 'not_provided',
    term: 'not_provided',
    content: 'not_provided'
  }
  if (utms) {
    for (const utm in utms) {
      const key = utm.replace('utm_', '')
      const value = utms[utm]
      if (value && key && typeof record[key] !== 'undefined') {
        record[key] = value
      }
    }
  }
  return [record]
}
