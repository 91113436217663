export const currentOfficeHours = ({ currentOfficeHours }) => currentOfficeHours

export const currentModalContent = ({ currentModalContent }) =>
  currentModalContent

export const currentOfficeHoursOriginal = ({ currentOfficeHoursOriginal }) =>
  currentOfficeHoursOriginal

export const sameScheduleHours = ({ sameScheduleHours }) => sameScheduleHours

export const officeHoursList = ({ officeHoursList }) => officeHoursList

export const formLoading = ({ formLoading }) => formLoading

export const botsWithOfficeHours = ({ botsWithOfficeHours }) =>
  botsWithOfficeHours

export const timezonesList = ({ timezonesList }) => timezonesList
