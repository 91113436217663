export const SET_CREATE_MESSAGE_MODAL_IS_OPEN =
  'SET_CREATE_MESSAGE_MODAL_IS_OPEN'

export const SET_CREATE_MESSAGE_STEP = 'SET_CREATE_MESSAGE_STEP'

export const SET_CREATE_MESSAGE_MODEL = 'SET_CREATE_MESSAGE_MODEL'

export const SET_CREATE_MESSAGE_PAYLOAD = 'SET_CREATE_MESSAGE_PAYLOAD'

export const SET_CREATE_MESSAGE_ERROR = 'SET_CREATE_MESSAGE_ERROR'

export const SET_CREATE_MESSAGE_NUMBER = 'SET_CREATE_MESSAGE_NUMBER'

export const SET_SELECTED_MESSAGE = 'SET_SELECTED_MESSAGE'

export const SET_CREATE_MESSAGE_SUCCESS = 'SET_CREATE_MESSAGE_SUCCESS'

export const SET_CREATE_MESSAGE_CONFIRM = 'SET_CREATE_MESSAGE_CONFIRM'

export const SET_TEMPLATE_MESSAGES = 'SET_TEMPLATE_MESSAGES'

export const SET_TEMPLATE_MESSAGES_IS_LOADED = 'SET_TEMPLATE_MESSAGES_IS_LOADED'

export const SET_PERSON_FIELDS = 'SET_PERSON_FIELDS'

export const SET_CHAT_FIELDS = 'SET_CHAT_FIELDS'

export const SET_CREATE_LOADER = 'SET_CREATE_LOADER'
