export const parseArray = (object: any, key: any) => {
  const array = object[key]
  for (const [index, item] of array.entries()) {
    if (item instanceof Object) {
      return Object.keys(item).map(keyItem => {
        return `${key}[${index}].${keyItem}=${item[keyItem]}`
      })
    }
  }
}

export const convertToString = (array: any, string = '') => {
  for (const [index, elem] of array.entries()) {
    if (Array.isArray(elem)) {
      string += convertToString(elem, string) + '&'
    } else {
      string += elem
      if (index !== array.length - 1) {
        string += '&'
      }
    }
  }
  return string
}

export const parseToUrl = (obj: any) => {
  Object.keys(obj).forEach(key =>
    obj[key] === undefined ? delete obj[key] : {}
  )
  const array = Object.keys(obj).map(item => {
    if (Array.isArray(obj[item])) {
      return parseArray(obj, item)
    } else {
      return `${item}=${obj[item]}`
    }
  })
  return convertToString(array)
}
