import { NavigationGuardNext, Route } from 'vue-router'

export default [
  {
    path: '/home-octa',
    name: 'home-octa',
    component: () => import('@/modules/HomeOcta/HomeOcta.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    redirect: '/chat/empty',
    beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
      if (to.path === '/chat' || to.path === '/chat/')
        return next('/chat/empty')
      return next()
    },
    meta: {
      isChatRoute: true
    },
    component: () => import('@/views/NewChat.vue'),
    children: [
      {
        path: 'empty/:inbox?/:id?',
        name: 'chat-empty-conversation',
        visibility: true,
        component: () =>
          import(
            './components/conversation/components/chat-conversation/empty/Container.vue'
          ),
        meta: {
          isChatRoute: true
        }
      },
      {
        path: ':key?/:inbox?/:id?',
        name: 'chat-open-conversation',
        visibility: true,
        component: () =>
          import('./components/conversation/components/ChatMain.vue'),
        meta: {
          isChatRoute: true,
          isChatRoomRoute: true
        }
      }
    ]
  }
]
