import { ActionContext } from 'vuex'

import { RootState } from '@/store/interfaces'

import { SurveyService } from '../survey.service'
import { SurveyConfig, SurveyState } from './interfaces'
import * as types from './mutations-types'

export const getConfiguration = async (
  context: ActionContext<SurveyState, RootState>
) => {
  const surveyService = new SurveyService()
  try {
    const configuration = await surveyService.getConfiguration()
    context.commit(types.SET_SURVEY_CONFIG, configuration)
    context.commit(types.SET_SURVEY_CONFIG_STATUS, true)
  } catch (err) {
    context.commit(types.SET_SURVEY_CONFIG_STATUS, false)
    throw new Error((err as any).message)
  }
}

export const createConfiguration = async (
  context: ActionContext<SurveyState, RootState>
) => {
  const surveyService = new SurveyService()
  try {
    const configuration = await surveyService.createConfiguration()
    context.commit(types.SET_SURVEY_CONFIG, configuration)
    context.commit(types.SET_SURVEY_CONFIG_STATUS, true)
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const changeConfiguration = async (
  context: ActionContext<SurveyState, RootState>,
  config: SurveyConfig
) => {
  const surveyService = new SurveyService()
  try {
    await surveyService.changeConfiguration(config)
  } catch (err) {
    throw new Error((err as any).message)
  }
}
