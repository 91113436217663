import { ActionContext } from 'vuex'

import lodash from 'lodash'

import {
  FormSteps,
  DomainType,
  FieldType,
  Channels
} from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v2/template-messages.enum'
import {
  parseTemplateMessageV1ToV2,
  parseCancelMarketingBtn
} from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v2/template-messages.helper'
import { TemplateMessagesService } from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v2/template-messages.service'

import { WhatsappIntegratorService } from '@/common/services/whatsapp-integrator'

import { RootState } from '@/store/interfaces'

import {
  TemplateMessagesState,
  ConfirmDeleteTemplateMessage,
  TemplateMessageItems,
  FormPayload,
  WhatsAppNumbers,
  TemplateMessageFieldType,
  validTemplateMessageName
} from './interfaces'
import * as types from './mutations-types'
import { TEMPLATE_MESSAGE_FORM_PAYLOAD_INITIAL_DATA } from './state'

const templateMessagesService = new TemplateMessagesService()

export const getWhatsAppNumbers = async (
  context: ActionContext<TemplateMessagesState, RootState>
) => {
  const whatsappIntegratorService = new WhatsappIntegratorService()

  context.commit(types.SET_WHATSAPP_NUMBERS_LOADER, true)

  try {
    const numbers =
      await whatsappIntegratorService.getIntegratorWhatsappNumbers()

    const parsedNumbers = numbers.map((item: WhatsAppNumbers) => {
      const { number, name } = item

      return {
        ...item,
        displayLabel: `${number} (${name})`
      }
    })

    context.commit(types.SET_WHATSAPP_NUMBERS, parsedNumbers)
  } catch {
    throw new Error('Error on getWhatsAppNumbers')
  } finally {
    context.commit(types.SET_WHATSAPP_NUMBERS_LOADER, false)
  }
}

export const getAvailableCategories = async (
  context: ActionContext<TemplateMessagesState, RootState>
) => {
  context.commit(types.SET_AVAILABLE_CATEGORIES_LOADER, true)

  try {
    const response = await templateMessagesService.getChatIntegrators()

    if (Array.isArray(response) && response.length) {
      const integrator = response.find(item => item.name === 'octabsp')

      if (Array.isArray(integrator.channels) && integrator.channels.length) {
        const channel = integrator.channels.find(
          (item: { name: string }) => item.name === Channels.WhatsApp
        )

        if (
          channel.template &&
          Array.isArray(channel.template.categories) &&
          channel.template.categories.length
        ) {
          context.commit(
            types.SET_AVAILABLE_CATEGORIES,
            channel.template.categories
          )
        }
      }
    }
  } catch (error) {
    console.error('Error on getChatIntegrators : ', error)
  } finally {
    context.commit(types.SET_AVAILABLE_CATEGORIES_LOADER, false)
  }
}

export const setTableTemplatesLoader = (
  context: ActionContext<TemplateMessagesState, RootState>,
  value: boolean
) => {
  context.commit(types.SET_TEMPLATE_MESSAGES_LOADER, value)
}

export const getTemplateMessages = async (
  context: ActionContext<TemplateMessagesState, RootState>,
  payload: any
) => {
  context.dispatch('setTableTemplatesLoader', true)

  try {
    const data = await templateMessagesService.retrieveTemplateMessages(payload)

    let itens = data

    if (Array.isArray(data)) {
      if (payload.page > 1) {
        const { templateMessages } = context.getters

        itens = [...templateMessages, ...data]
      }

      context.commit(types.SET_TEMPLATE_MESSAGES, itens)
    }

    return data
  } catch {
    throw new Error('Error on getTemplateMessages')
  } finally {
    context.dispatch('setTableTemplatesLoader', false)
  }
}

export const updateTemplateMessagesList = (
  context: ActionContext<TemplateMessagesState, RootState>,
  payload: TemplateMessageItems
) => {
  const { templateMessages } = context.getters

  const parsedItems = templateMessages.map((item: TemplateMessageItems) => {
    if (item.id === payload.id) {
      return payload
    }

    return item
  })

  context.commit(types.SET_TEMPLATE_MESSAGES, parsedItems)
}

export const confirmDeleteTemplateMessage = (
  context: ActionContext<TemplateMessagesState, RootState>,
  payload: ConfirmDeleteTemplateMessage
) => {
  context.commit(types.CONFIRM_DELETE_TEMPLATE_MESSAGE, payload)
}

export const deleteTemplateMessage = async (
  context: ActionContext<TemplateMessagesState, RootState>
) => {
  const {
    confirmDeleteTemplateMessage: { id },
    templateMessages
  } = context.getters

  try {
    await templateMessagesService.deleteTemplateMessages(id)

    const templates = templateMessages.filter(
      (item: TemplateMessageItems) => item.id !== id
    )

    context.commit(types.SET_TEMPLATE_MESSAGES, templates)
  } catch {
    throw new Error('Error on deleteTemplateMessage')
  }
}

export const setListPreviewTemplate = (
  context: ActionContext<TemplateMessagesState, RootState>,
  payload: {
    open: boolean
    id: string
  }
) => {
  context.commit(types.SET_LIST_PREVIEW_TEMPLATE, payload)
}

export const setStep = (
  context: ActionContext<TemplateMessagesState, RootState>,
  step: FormSteps
) => {
  context.commit(types.SET_TEMPLATE_MESSAGE_STEP, step)
}

export const setInitialFormPayload = (
  context: ActionContext<TemplateMessagesState, RootState>,
  initialFormPayload: FormPayload
) => {
  context.commit(
    types.SET_TEMPLATE_MESSAGE_INITIAL_FORM_PAYLOAD,
    lodash.cloneDeep(initialFormPayload)
  )
}

export const setFormPayload = (
  context: ActionContext<TemplateMessagesState, RootState>,
  formPayload: FormPayload
) => {
  context.commit(types.SET_TEMPLATE_MESSAGE_FORM_PAYLOAD, formPayload)
}

export const setShowDiscardContentAlert = (
  context: ActionContext<TemplateMessagesState, RootState>,
  showAlert: boolean
) => {
  context.commit(
    types.SET_TEMPLATE_MESSAGE_SHOW_DISCARD_CONTENT_ALERT,
    showAlert
  )
}

export const setShowReview = (
  context: ActionContext<TemplateMessagesState, RootState>,
  showReview: boolean
) => {
  context.commit(types.SET_TEMPLATE_MESSAGE_SHOW_REVIEW, showReview)
}

export const setShowSuccessModal = (
  context: ActionContext<TemplateMessagesState, RootState>,
  showSuccessModal: boolean
) => {
  context.commit(
    types.SET_TEMPLATE_MESSAGE_SHOW_SUCCESS_MODAL,
    showSuccessModal
  )
}

export const setPersonFields = (
  context: ActionContext<TemplateMessagesState, RootState>,
  fields: Array<TemplateMessageFieldType>
) => {
  context.commit(types.SET_PERSON_FIELDS, fields)
}

export const setChatFields = (
  context: ActionContext<TemplateMessagesState, RootState>,
  fields: Array<TemplateMessageFieldType>
) => {
  context.commit(types.SET_CHAT_FIELDS, fields)
}

export const setOrganizationFields = (
  context: ActionContext<TemplateMessagesState, RootState>,
  fields: Array<TemplateMessageFieldType>
) => {
  context.commit(types.SET_ORGANIZATION_FIELDS, fields)
}

export const fetchCustomFields = async (
  context: ActionContext<TemplateMessagesState, RootState>
) => {
  const fields = await templateMessagesService.getCustomFields()

  const { isResponsibleContactEnabled } = context.rootGetters

  const personFields = [
    {
      key: 'nome-contato',
      fieldId: 'nome-contato',
      property: 'room.createdBy.name',
      type: 'text',
      domainType: DomainType.Person
    },
    {
      key: 'email-contato',
      fieldId: 'email-contato',
      property: 'room.createdBy.email',
      type: 'text',
      domainType: DomainType.Person
    },
    ...fields.filter(
      (f: TemplateMessageFieldType) => f.domainType === DomainType.Person
    )
  ]

  if (isResponsibleContactEnabled) {
    personFields.splice(2, 0, {
      key: 'responsavel-contato',
      fieldId: 'responsavel-contato',
      property: 'room.createdBy.responsibleContact',
      type: 'text',
      domainType: DomainType.Person
    })
  }

  const chatFields = [
    {
      key: 'id-conversa',
      fieldId: 'id-conversa',
      property: 'room.key',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: true,
      fieldType: FieldType.Text
    },
    {
      key: 'numero-conversa',
      fieldId: 'numero-conversa',
      property: 'room.number',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: true,
      fieldType: FieldType.Text
    },
    {
      key: 'nome-empresa',
      fieldId: 'nome-empresa',
      property: 'room.organization.name',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: false,
      fieldType: FieldType.Text
    },
    {
      key: 'nome-agente',
      fieldId: 'nome-agente',
      property: 'room.agent.name',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: false,
      fieldType: FieldType.Text
    },
    ...fields.filter(
      (f: TemplateMessageFieldType) => f.domainType === DomainType.Chat
    )
  ]

  const organizationFields =
    fields.filter(
      (f: TemplateMessageFieldType) => f.domainType === DomainType.Organization
    ) || []

  context.dispatch('setPersonFields', personFields)

  context.dispatch('setChatFields', chatFields)

  context.dispatch('setOrganizationFields', organizationFields)

  return fields
}

export const setCurrentTemplateMessage = async (
  context: ActionContext<TemplateMessagesState, RootState>,
  id: string
) => {
  try {
    const templateMessage =
      await templateMessagesService.getTemplateMessageById(id)

    let parsedTemplateMessage = templateMessage

    if (!Object.prototype.hasOwnProperty.call(templateMessage, 'components')) {
      parsedTemplateMessage = parseTemplateMessageV1ToV2(templateMessage)
    }

    parsedTemplateMessage = parseCancelMarketingBtn(
      parsedTemplateMessage,
      'edit'
    )

    context.dispatch('setInitialFormPayload', parsedTemplateMessage)

    context.dispatch('setFormPayload', parsedTemplateMessage)
  } catch (err) {
    throw new Error('Error on setCurrentTemplateMessage: ' + err)
  }
}

export const resetFormData = (
  context: ActionContext<TemplateMessagesState, RootState>
) => {
  context.dispatch('setFormPayload', TEMPLATE_MESSAGE_FORM_PAYLOAD_INITIAL_DATA)

  context.dispatch('setInitialFormPayload')

  context.dispatch('setStep', FormSteps.BasicInformations)
}

export const setValidTemplateMessageName = (
  context: ActionContext<TemplateMessagesState, RootState>,
  payload: validTemplateMessageName
) => {
  context.commit(types.SET_VALID_TEMPLATE_MESSAGE_NAME, payload)
}
