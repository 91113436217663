import {
  CreditManagementState,
  CategorizedReport,
  ReportFilter
} from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_REPORT_LOADER](state: CreditManagementState, isLoader: boolean) {
    state.report.loader = isLoader
  },
  [types.SET_REPORT_ERROR](state: CreditManagementState, hasError: boolean) {
    state.report.hasError = hasError
  },
  [types.SET_CATEGORIZED_REPORT](
    state: CreditManagementState,
    payload: CategorizedReport
  ) {
    state.report.categorized = payload
  },
  [types.SET_REPORT_FILTER](
    state: CreditManagementState,
    payload: ReportFilter
  ) {
    state.report.filter = payload
  }
}
