import { Route } from 'vue-router'

import { socketEmit } from '@/common/services/socket'
import { getUserLogged } from '@/common/services/storage'

export const entryToTheSocketChat = async (to: Route, from: Route) => {
  const userLogged = getUserLogged()

  if (to.meta?.isChatRoute && !from.meta?.isChatRoute) {
    return socketEmit('joinUserChat', userLogged.id)
  } else if (from.meta?.isChatRoute && !to.meta?.isChatRoute) {
    return socketEmit('leaveUserChat', userLogged.id)
  }
}
