import { channels } from '@/modules/Chat/components/conversation/enums/chat-channels'

const AUDIO_RECORDING_PROPERTIES = {
  [channels.whatsapp]: {
    saveas: 'audio/ogg; codecs=opus',
    extension: 'opus',
    audioBitrate: '48k',
    audioChannels: '1'
  },
  [channels.instagram]: {
    saveas: 'audio/mp4'
  }
}

export const isWebChannel = ({ chat }) => chat && chat.channel === channels.web

export const isFacebookMessengerChannel = ({ chat }) =>
  chat && chat.channel === channels.facebookMessenger

export const audioRecordingProperties = ({ chat }) =>
  AUDIO_RECORDING_PROPERTIES[chat && chat.channel] || { saveas: 'audio/wav' }

export const getCustomFields = state => state.customFieldsData

export const organizationsCustomFieldsData = state =>
  state.organizationsCustomFieldsData

export const selectOrganizationOptions = state =>
  state.selectOrganizationOptions

export const selectedOrganization = state => state.selectedOrganization
