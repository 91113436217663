import { AbVariants } from '@/common/helpers/enums/ab-variants'
import { SignUpSteps } from '@/common/helpers/enums/signup-steps'

import { SignUpState } from '@/store/interfaces'

const state: SignUpState = {
  user: { email: '', site: '' },
  currentStep: SignUpSteps.EmailData,
  variant: AbVariants.A
}

export default state
