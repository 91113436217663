export enum FieldTypes {
  TEXT = 1,
  TEXTAREA = 2,
  SELECT = 3,
  RADIO = 4,
  NUMBER = 5,
  DECIMAL = 6,
  DATE = 7,
  STORAGE_LIST = 15,
  URL_LIST = 16
}
