import {
  CommerceSingleProduct,
  CommerceProductAttributes,
  CommerceProductVariants
} from '@/modules/Commerce/products/store/interfaces'

export const cartesianProductLong = (...arrays: any[]): string[][] => {
  const [arrayA, arrayB, ...rest] = arrays

  if (!arrayB) {
    return arrayA.map((el: any) => [el])
  }

  const result = []

  for (const itemA of arrayA) {
    for (const itemB of arrayB) {
      if (Array.isArray(itemA)) {
        result.push([...itemA, itemB])
      } else {
        result.push([itemA, itemB])
      }
    }
  }

  if (rest.length > 0) {
    return cartesianProductLong(result, ...rest)
  }

  return result
}

export const parseAttributes = (
  attributes: Array<CommerceProductAttributes>
): Array<CommerceProductAttributes> =>
  attributes.map((attribute: CommerceProductAttributes) => {
    const isNewAttribute = !!attribute.isNew

    if (isNewAttribute) {
      delete attribute.isNew

      delete attribute.id
    }

    attribute.values.forEach((value, index) => {
      if (value.isNew) {
        delete attribute.values[index].id

        delete attribute.values[index].isNew

        if (isNewAttribute) {
          delete attribute.values[index].attributeId
        }
      }
    })

    return attribute
  })

const parseVariants = ({
  variants,
  attributes
}: {
  variants: Array<CommerceProductVariants>
  attributes: Array<CommerceProductAttributes>
}): Array<CommerceProductVariants> =>
  variants.map((variation: CommerceProductVariants) => {
    const parsedVariation: any = { ...variation }

    delete parsedVariation.attributeValues

    delete parsedVariation.attributeValuesHash

    const data = {
      ...parsedVariation,
      attributes: {}
    }

    variation.attributeValues.forEach(item => {
      const hasAttribute = attributes.find(
        attribute => attribute.id === item.attributeId
      )

      if (hasAttribute) {
        data.attributes[hasAttribute.code] = item.code
      }
    })

    return data
  })

export const mountProductPayload = (commerceProduct: CommerceSingleProduct) => {
  const product: any = { ...commerceProduct }

  if (product?.variants?.length) {
    product.variants = parseVariants({
      variants: product.variants,
      attributes: commerceProduct.attributes
    })
  } else {
    delete product.variants
  }

  if (product?.attributes?.length) {
    product.attributes = parseAttributes(product.attributes)
  } else {
    delete product.attributes
  }

  return product
}

export const convertPriceInNumber = (price: string | number): number => {
  if (typeof price === 'number') return price

  const priceToNumber = Number(price.replace(/[^0-9-]+/g, ''))

  return priceToNumber / 100
}

export const slugify = (string: string): string => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'

  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'

  const p = new RegExp(a.split('').join('|'), 'g')

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}
