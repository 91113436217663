import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import { ChatPreferencesState } from '@/modules/ChatSettings/general/chat-preferences/store/interfaces'

import legacyHeaders from '@/common/services/legacy-headers'

export class ChatPreferencesService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.chat.getClient()) as AxiosInstance
  }

  async getSettings() {
    return this.getClient()
      .then(client => client.get('configs', legacyHeaders()))
      .then(res => res.data)
  }

  async saveSettings(settings: ChatPreferencesState) {
    return this.getClient().then(client =>
      client.put('configs', { conversation: settings }, legacyHeaders())
    )
  }

  async getUrlEmbed() {
    return `${services.cdn.getCDNURL()}/embed.js`
  }
}
