import { Channel } from '@/modules/ChatSettings/general/chat-preferences/chat-preferences.enum'

import { ChatPreferencesState } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_SHOW_AGENT_NAME_IN_MESSAGE](
    state: ChatPreferencesState,
    value: boolean
  ) {
    const channels = state.channels

    const whatsappConfiguration = channels.find(
      ch => ch.name === Channel.whatsapp
    )

    if (!whatsappConfiguration) {
      return state.channels.push({
        name: Channel.whatsapp,
        configurations: {
          showAgentNameInWhatsappMessage: value
        }
      })
    }

    const updatedChannels = channels.map(ch => {
      if (ch.name === Channel.whatsapp) {
        return {
          ...ch,
          configurations: {
            ...ch.configurations,
            showAgentNameInWhatsappMessage: value
          }
        }
      }

      return ch
    })

    state.channels = updatedChannels
  },
  [types.SET_INACTIVITY_TIMEOUT_ENABLED](
    state: ChatPreferencesState,
    value: boolean
  ) {
    state.inactivityTimeout.enabled = value
  },
  [types.SET_INACTIVITY_TIMEOUT_DELAY](
    state: ChatPreferencesState,
    value: number
  ) {
    state.inactivityTimeout.delay = value
  },
  [types.SET_CLIENT_CAN_CLOSE](state: ChatPreferencesState, value: boolean) {
    state.clientCanClose = value
  },
  [types.SET_MAX_SERVICES_ENABLED](
    state: ChatPreferencesState,
    value: boolean
  ) {
    state.maxServices.enabled = value
  },
  [types.SET_MAX_SERVICES_LIMIT](state: ChatPreferencesState, value: number) {
    state.maxServices.limit = value
  },
  [types.SET_SEND_CHAT_TO_CLIENT](state: ChatPreferencesState, value: boolean) {
    state.sendChatToClient = value
  },
  [types.SET_AGENT_NOTIFICATION_ENABLED](
    state: ChatPreferencesState,
    value: boolean
  ) {
    state.notifyAgents.enabled = value
  },
  [types.SET_AGENT_NOTIFICATION_OPTION](
    state: ChatPreferencesState,
    value: string
  ) {
    state.notifyAgents.option = value
  },
  [types.SET_AGENT_NOTIFICATION_DELAY](
    state: ChatPreferencesState,
    value: number
  ) {
    state.notifyAgents.delay = value
  },
  [types.SET_CAN_SHARE](state: ChatPreferencesState, value: boolean) {
    state.canShare = value
  },
  [types.SET_ROULETTE](state: ChatPreferencesState, value: boolean) {
    state.roulette = value
  },
  [types.SET_AUTOMATIC_OPEN_TICKET](
    state: ChatPreferencesState,
    value: boolean
  ) {
    state.automaticOpenTicket = value
  },
  [types.SET_AUTOMATIC_RESPONSIBLE](
    state: ChatPreferencesState,
    value: boolean
  ) {
    state.automaticSetReponsible = value
  },
  [types.SET_AGENTS_SERVICE_TIMEOUT_ENABLED](
    state: ChatPreferencesState,
    value: boolean
  ) {
    state.serviceTimeout.agents.enabled = value
  },
  [types.SET_AGENTS_SERVICE_TIMEOUT_DELAY](
    state: ChatPreferencesState,
    value: number
  ) {
    state.serviceTimeout.agents.delay = value
  },
  [types.SET_CLIENTS_SERVICE_TIMEOUT_ENABLED](
    state: ChatPreferencesState,
    value: boolean
  ) {
    state.serviceTimeout.clients.enabled = value
  },
  [types.SET_CLIENTS_SERVICE_TIMEOUT_DELAY](
    state: ChatPreferencesState,
    value: number
  ) {
    state.serviceTimeout.clients.delay = value
  },
  [types.SET_SETTINGS](
    state: ChatPreferencesState,
    settings: ChatPreferencesState
  ) {
    state.automaticOpenTicket = settings.automaticOpenTicket

    state.sendChatToClient = settings.sendChatToClient

    state.canShare = settings.canShare

    state.roulette = settings.roulette

    state.maxServices = settings.maxServices

    state.notifyAgents = settings.notifyAgents

    state.clientCanClose = settings.clientCanClose

    state.inactivityTimeout = settings.inactivityTimeout

    state.channels = settings.channels

    state.automaticSetReponsible = settings.automaticSetReponsible

    state.serviceTimeout = settings.serviceTimeout
  }
}
