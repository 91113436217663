import { ActionContext } from 'vuex'

import { GroupsService } from '@/modules/ChatSettings/groups/groups.service'

import { STATUS_CODE } from '@/common/helpers/enums/http-status-codes'

import { RootState } from '@/store/interfaces'

import { GroupsState, Group } from './interfaces'
import * as types from './mutations-types'

const groupsService = new GroupsService()

export const fetchGroups = async (
  context: ActionContext<GroupsState, RootState>
) => {
  const groups = await groupsService.getGroups()

  context.commit(types.SET_GROUPS, groups)

  return groups
}

export const createGroup = async (
  context: ActionContext<GroupsState, RootState>,
  group: Group
) => {
  const res = await groupsService.createGroup(group)

  context.commit(types.CREATE_GROUP, res.data)

  return res.data
}

export const updateGroup = async (
  context: ActionContext<GroupsState, RootState>,
  group: Group
) => {
  const res = await groupsService.updateGroup(group)

  context.commit(types.UPDATE_GROUP, res.data)

  return res
}

export const removeGroup = async (
  context: ActionContext<GroupsState, RootState>,
  group: Group
) => {
  const res = await groupsService.removeGroup(group)

  if (res.status === STATUS_CODE.OK) {
    context.commit(types.REMOVE_GROUP, group)

    return res
  }
}

export const removeGroups = async (
  context: ActionContext<GroupsState, RootState>,
  groups: Array<Group>
) => {
  const res = await groupsService.removeGroups(groups)

  if (res.status === STATUS_CODE.NO_CONTENT) {
    context.commit(types.REMOVE_GROUPS, groups)

    return res
  }
}

export const enableGroup = async (
  context: ActionContext<GroupsState, RootState>,
  group: Group
) => {
  const res = await groupsService.enableGroup(group)

  context.commit(types.ENABLE_GROUP, res.data)

  return res.data
}

export const disableGroup = async (
  context: ActionContext<GroupsState, RootState>,
  group: Group
) => {
  const res = await groupsService.disableGroup(group)

  context.commit(types.DISABLE_GROUP, res.data)

  return res.data
}

export const removeAgentInGroup = (
  context: ActionContext<GroupsState, RootState>,
  payload: { groupId: string; agentEmail: string }
) => {
  context.commit(types.REMOVE_AGENT_IN_GROUP, payload)
}

export const fetchAgents = async (
  context: ActionContext<GroupsState, RootState>
) => {
  const agents = await groupsService.getAgents()

  context.commit(types.SET_AGENTS_SORTED, agents)

  return agents
}

export const activeAgent = async (
  context: ActionContext<GroupsState, RootState>,
  idAgent: string
) => {
  const activeAgent = await groupsService.activeAgent(idAgent)

  context.commit(types.SET_AGENT, activeAgent)

  return activeAgent
}

export const fetchWhatsappNumbers = async (
  context: ActionContext<GroupsState, RootState>
) => {
  const whatsappNumbers = await groupsService.getWhatsappNumbers()

  context.commit(types.SET_WHATSAPP_NUMBERS, whatsappNumbers)

  return whatsappNumbers
}
