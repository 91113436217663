import { ActionContext } from 'vuex'

import { TokenApiService } from '@/modules/Ticket/token-api/token-api.service'

import { RootState } from '@/store/interfaces'

import { Token } from './interfaces'
import * as types from './mutations-types'

const tokenApiService = new TokenApiService()

export const getAllTokens = async (context: ActionContext<any, RootState>) => {
  context.commit(types.SET_TOKENS_LOADING, true)
  try {
    const tokens = await tokenApiService.getAllTokens()
    context.commit(types.SET_TOKENS, tokens)
  } catch (err) {
    context.commit(types.SET_TOKENS_ERROR, true)
    throw new Error((err as any).message)
  } finally {
    context.commit(types.SET_TOKENS_LOADING, false)
  }
}

export const createApiToken = async (
  context: ActionContext<any, RootState>,
  token: Token
) => {
  try {
    const newToken = await tokenApiService.createApiToken(token)
    context.commit(types.SET_EDIT_TOKEN, newToken)
    context.commit(types.PUSH_CREATED_TOKEN, newToken)
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const setEditToken = async (
  context: ActionContext<any, RootState>,
  token: any
) => {
  context.commit(types.SET_EDIT_TOKEN, token)
}

export const updateApiToken = async (
  context: ActionContext<any, RootState>,
  token: Token
) => {
  try {
    await tokenApiService.updateApiToken(token)
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const setRemoveToken = async (
  context: ActionContext<any, RootState>,
  token: any
) => {
  context.commit(types.SET_REMOVE_TOKEN, token)
}

export const removeApiToken = async (
  context: ActionContext<any, RootState>,
  token: Token
) => {
  try {
    await tokenApiService.removeApiToken(token.id)
    context.commit(types.REMOVE_SELECTED_TOKEN)
  } catch (err) {
    throw new Error((err as any).message)
  }
}
