import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

dayjs.extend(LocalizedFormat)

function resolveTimezone() {
  if (
    typeof Intl !== 'undefined' &&
    Intl.DateTimeFormat &&
    Intl.DateTimeFormat().resolvedOptions
  ) {
    const timezone =
      Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Sao_Paulo'

    if (timezone?.startsWith('Etc/GMT')) {
      const timezoneNumber = parseInt(timezone.replace('Etc/GMT', ''), 10)
      return timezoneNumber * -60
    }
  }

  return new Date().getTimezoneOffset()
}

const localTimeZone = resolveTimezone()

export default function formatDate(value, mask) {
  return dayjs.utc(value).subtract(localTimeZone, 'minutes').format(mask)
}
