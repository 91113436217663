export const Filters = {
  TEXT: 'TextSearch',
  ENABLED: 'IsEnabled',
  TYPE: 'Type',
  RESPONSIBLE: 'ResponsibleId'
}

export const OperatorTypes = {
  IS_LESS_THAN_EQUAL: 1,
  EQUALS: 2,
  IS_GREATER_THAN_EQUAL: 3,
  CONTAINS: 4,
  IS_NOT_EQUALS: 5,
  IS_LESS_THAN: 7,
  IS_MORE_THAN: 8,
  LESS_THAN: 12,
  GREATER_THAN: 13,
  NOT_CONTAINS: 17
}

export const contactsPerPage = 10

export const ContactsFilterTypes = {
  LOAD: 'Load',
  PAGINATION: 'pagination'
}
