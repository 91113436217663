import { CHECKOUT_TYPES, Checkout } from './types'

const state: Checkout = {
  currentRecurrence: null,
  currentPlan: null,
  type: CHECKOUT_TYPES.BUY_TICKET,
  tenantId: '',
  items: [],
  previewItems: [],
  discounts: [],
  disclaimers: [],
  payment: {
    code: '',
    description: '',
    details: {}
  },
  terms: [],
  company: {
    address1: '',
    address2: '',
    city: '',
    contactName: '',
    country: '',
    district: '',
    email: '',
    name: '',
    phone: '',
    postal: '',
    state: '',
    contactEmail: ''
  },
  payments: [],
  subTotal: 0,
  total: 0,
  discountTotal: 0,
  isFranchiseCheckout: false,
  isOneTime: false,
  userItem: null,
  planItem: null,
  couponCode: ''
}

export default state
