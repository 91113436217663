import { headers } from '@octadesk-tech/services'

import { getClient } from './http'

const API_NAME = 'core'

export default class CoreService {
  getClient() {
    return getClient(API_NAME)
  }

  async getIdOctadesk(subdomain: string) {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(
          `api/company-setup/number/${subdomain}`,
          headers.getAuthorizedHeaders()
        )
      )
      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error in getIdOctadesk: ' + ex)
      }
    }
  }
}
