/* eslint-disable sonarjs/no-duplicate-string */
export default channel => {
  let connections = []

  if (channel !== 'web') {
    connections = [
      {
        id: 'step-0',
        type: 'common',
        parentActionId: '',
        nextActionId: 'initial-greeting',
        connectionRules: []
      }
    ]
  }

  connections = [
    ...connections,
    {
      id: 'step-1',
      type: 'common',
      parentActionId: channel !== 'web' ? 'initial-greeting' : '',
      nextActionId: 'collect-customer-name',
      connectionRules: []
    },
    {
      id: 'step-2',
      type: 'common',
      parentActionId: 'collect-customer-name',
      nextActionId: 'confirm-collected-name',
      connectionRules: []
    },
    {
      id: 'step-3',
      type: 'common',
      parentActionId: 'collect-customer-email',
      nextActionId: 'confirm-collected-email',
      connectionRules: []
    },
    {
      id: 'step-4',
      type: 'common',
      parentActionId: 'confirm-collected-email',
      nextActionId: 'collect-customer-email',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-email-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-5',
      type: 'common',
      parentActionId: 'confirm-collected-name',
      nextActionId: 'collect-customer-email',
      connectionRules: []
    },
    {
      id: 'step-6',
      type: 'common',
      parentActionId: 'confirm-collected-email',
      nextActionId: 'connect-with-the-support-team',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-name-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-7',
      type: 'common',
      parentActionId: 'confirm-collected-name',
      nextActionId: 'collect-customer-name',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-name-no'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-8',
      type: 'common',
      parentActionId: 'confirm-collected-email',
      nextActionId: 'connect-with-the-support-team',
      connectionRules: [
        {
          source: 'USER_INPUT',
          matchType: '$eq',
          values: ['confirm-collected-email-yes'],
          referenceProperty: null,
          referenceValue: null
        }
      ]
    },
    {
      id: 'step-9',
      type: 'fallback',
      parentActionId: 'connect-with-the-support-team',
      nextActionId: 'closing-message',
      connectionRules: []
    }
  ]

  return connections
}
