import { ActionContext } from 'vuex'

import { TicketVoiceService } from '@/modules/Ticket/apps/ticket-voice/ticket-voice.service'

import { RootState } from '@/store/interfaces'

import { TicketVoiceSettings, TicketVoiceState } from './interfaces'
import * as types from './mutations-types'

const ticketVoiceService = new TicketVoiceService()

export const fetchSettings = async (
  context: ActionContext<TicketVoiceState, RootState>
) => {
  const settings = await ticketVoiceService.getSettings()
  context.commit(types.SET_TICKET_VOICE_SETTINGS, settings)
}

export const fetchProviders = async (
  context: ActionContext<TicketVoiceState, RootState>
) => {
  const providers = await ticketVoiceService.getProviders()
  context.commit(types.SET_TICKET_VOICE_PROVIDERS, providers)
}

export const fetchProviderSettings = async (
  context: ActionContext<TicketVoiceState, RootState>,
  provider: string
) => {
  const settings = await ticketVoiceService.getSetting(provider)
  context.commit(types.SET_TICKET_VOICE_PROVIDER_SETTINGS, settings)
}

export const setVoiceBaseURL = async (
  context: ActionContext<TicketVoiceState, RootState>
) => {
  const url = await ticketVoiceService.getBaseURL()
  context.commit(types.SET_TICKET_VOICE_BASE_URL, url)
}

export const saveSettings = async (
  context: ActionContext<TicketVoiceState, RootState>,
  settings: TicketVoiceSettings
) => {
  const saved = await ticketVoiceService.saveSettings(settings)

  context.commit(types.SET_TICKET_VOICE_PROVIDER_SETTINGS, saved)

  return saved
}
