import { services, url } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

import i18n from '@/common/i18n'

import legacyHeaders from '@/common/services/legacy-headers'

export class WhatsAppWebService {
  private static instance: WhatsAppWebService

  public static getInstance(): WhatsAppWebService {
    if (!this.instance) {
      this.instance = new WhatsAppWebService()
    }

    return this.instance
  }

  private _clients: any = {}

  private _whatsappUrl: any

  private conn: any

  async getWhatsAppClient() {
    if (this._clients && this._clients['whatsApp']) {
      return this._clients['whatsApp']
    }

    if (!services['whatsApp']) {
      throw new Error('Cannot get http client: missing service whatsApp')
    }

    this._clients['whatsApp'] = await services['whatsApp'].getClient(
      legacyHeaders()
    )

    return this._clients['whatsApp']
  }

  async getChatIntegratorClient() {
    if (this._clients && this._clients['chatIntegrator']) {
      return this._clients['chatIntegrator']
    }

    if (!services['chatIntegrator']) {
      throw new Error('Cannot get http client: missing service chatIntegrator')
    }

    this._clients['chatIntegrator'] = await services[
      'chatIntegrator'
    ].getClient(legacyHeaders())

    return this._clients['chatIntegrator']
  }

  async getChatClient() {
    if (this._clients && this._clients['chat']) {
      return this._clients['chat']
    }

    if (!services['chat']) {
      throw new Error('Cannot get http client: missing service chat')
    }

    this._clients['chat'] = await services['chat'].getClient(legacyHeaders())

    return this._clients['chat']
  }

  async getWhatsappUrl() {
    if (this._whatsappUrl && this._whatsappUrl.length) {
      return this._whatsappUrl
    }
    this._whatsappUrl = await url.getAPIURL('whatsapp')
    return this._whatsappUrl
  }

  async getWhatsappNumbers() {
    const response = await this.getWhatsAppClient().then(client =>
      client.get('/numbers')
    )

    return response.data
  }

  closeQRCodeWS() {
    if (this.conn) {
      this.conn.close()
    }
  }

  /* eslint-disable */
  async openQRCodeWS(
    contact: { number: string },
    context: any,
    userLoggedId: string
  ) {
    const subDomain = Storage.getItem('company') as string

    if (!subDomain || !subDomain.length) {
      throw new Error('SubDomain not provided')
    }

    const basePath = await this.getWhatsappUrl()

    const socketBasePath = basePath
      ?.replace(/^https:\/\//, 'wss://')
      .replace(/^http:\/\//, 'ws://')

    if (contact) {
      this.conn = new WebSocket(
        `${socketBasePath}/qrcode/${contact.number}?sb=${subDomain}&user=${userLoggedId}`
      )
    } else {
      this.conn = new WebSocket(
        `${socketBasePath}/qrcode?sb=${subDomain}&user=${userLoggedId}`
      )
    }

    this.conn.onmessage = (message: any) => {
      try {
        const data = JSON.parse(message.data)

        const qrCodeActions = [
          'ws_error',
          'ws_qrcode',
          'ws_logged',
          'ws_creatingSuccess'
        ]

        if (data) {
          const actionName = 'ws_' + data.Command

          qrCodeActions.forEach((action: string) => {
            if (action!.startsWith('ws_') && action === actionName) {
              const wsInfo = {
                data: data.Data,
                contact
              }

              context.dispatch(action, wsInfo)
            }
          })
        }
      } catch (ex) {
        if (ex) {
          console.error('Error on qrcode on message', ex)
        }
      }
    }
  }
  /* eslint-enable */

  confirmQRCodeOverrideWS({ confirmed }: { confirmed: string }) {
    if (!this.conn) {
      throw new Error('Socket disconnected')
    }

    const data = {
      Command: 'confirm',
      Data: confirmed.toString().toLowerCase()
    }

    this.conn.send(JSON.stringify(data))
  }

  async getNumberStatus(numberId: string) {
    const response = await this.getWhatsAppClient().then(client =>
      client.get(`/numbers/${numberId}`)
    )

    return response.data
  }

  async updateNumberName(
    number: { id: string; name: string },
    userLogged: { [key: string]: any }
  ) {
    const response = await this.getWhatsAppClient().then(client =>
      client.patch(`/numbers/${number.id}/name?user=${userLogged}`, {
        name: number.name
      })
    )

    return response.data
  }

  async enableNumber(id: string) {
    const response = await this.getWhatsAppClient().then(client =>
      client.patch(`/numbers/${id}/enable`)
    )

    return response.data
  }

  async getNumber(id: string) {
    const client = await this.getWhatsAppClient()

    return client.get(`/numbers/${id}`).then((r: any) => r.data)
  }

  async disableNumber(id: string) {
    const client = await this.getWhatsAppClient()

    return client.patch(`/numbers/${id}/disable`).then((r: any) => r.data)
  }

  async deleteNumber(id: string, userLogged: { [key: string]: any }) {
    const client = await this.getWhatsAppClient()

    return client
      .delete(`/numbers/${id}?user=${userLogged}`)
      .then((r: any) => r.data)
  }

  translateName(name = '') {
    if (!name.startsWith('untitled')) {
      return name
    }

    const splittedName = name.split('-')

    return [
      i18n?.t(`listNumbers.webList.label.${splittedName[0]}`),
      splittedName[1]
    ].join(' ')
  }

  isLostQRCodeError(errorMessage: any) {
    const disconnectionCauses = {
      lostQrCodeErrors: ['401', '419', 'unknown error', 'unpaired from phone']
    }

    return !!disconnectionCauses.lostQrCodeErrors.find(code => {
      const regex = new RegExp(code)

      return regex.test(errorMessage)
    })
  }

  async getChatBotsFluxes() {
    const client = await this.getChatClient()

    const response = await client.get('flux', legacyHeaders())

    if (response?.data) {
      return response.data.filter((flux: { botId: string }) => flux.botId)
    }

    return []
  }

  async inactivateFluxes(fluxes: any) {
    const client = await this.getChatClient()

    await client.put(`flux/inactive`, fluxes, legacyHeaders())
  }

  async createNumbersByChannelID(integratorName: string, payload: any) {
    return (await this.getChatIntegratorClient()).post(
      `/whatsapp/${integratorName}/ids`,
      payload
    )
  }
}
