import { RoleTypes } from '@/common/helpers/enums/role-types'

export default {
  path: '/adverts',
  name: 'adverts',
  redirect: '/adverts/meta-ads',
  component: () => import('@/views/Adverts.vue'),
  children: [
    {
      path: 'meta-ads',
      name: 'meta-ads',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      component: () => import('./meta-ads/components/MetaAds.vue')
    }
  ]
}
