export const GET_WHATSAPP_OFFICIAL_BALANCE_STARTED =
  'GET_WHATSAPP_OFFICIAL_BALANCE_STARTED'

export const GET_WHATSAPP_OFFICIAL_BALANCE_SUCCEED =
  'GET_WHATSAPP_OFFICIAL_BALANCE_SUCCEED'

export const GET_WHATSAPP_OFFICIAL_BALANCE_FAILED =
  'GET_WHATSAPP_OFFICIAL_BALANCE_FAILED'

export const SET_WHATSAPP_OFFICIAL_LAST_CHECK =
  'SET_WHATSAPP_OFFICIAL_LAST_CHECK'
