import * as BotService from '@/modules/Bot/services/bots'
import types from '@/modules/Bot/store/mutations-types/interactions/webhook'

export const setValidateIntegrationLoader = context => {
  context.commit(types.SET_VALIDATE_INTEGRATION_LOADER)
}

export const setValidateIntegrationSuccess = context => {
  context.commit(types.SET_VALIDATE_INTEGRATION_SUCCESS)
}

export const setValidateIntegrationError = context => {
  context.commit(types.SET_VALIDATE_INTEGRATION_ERROR)
}

export const setValidateIntegrationErrorDetails = context => {
  context.commit(types.SET_VALIDATE_INTEGRATION_ERROR_DETAILS)
}

export const triggerValidation = (context, payload) => {
  const session = { propertySpecs: [], properties: {} }

  if (payload.parameters) {
    payload.parameters.forEach(parameter => {
      if (parameter.properties) {
        parameter.properties.forEach(p => {
          session.properties[p.domain] = session.properties[p.domain] || {}
          session.properties[p.domain][p.name] = {
            spec: p,
            value: p.value
          }
          session.propertySpecs.push(p)
        })
      }
    })
  }
  BotService.validate('webhook', {
    webhook: payload,
    session: session
  })
    .then(response => {
      if (response.success) {
        context.commit(types.SET_VALIDATE_INTEGRATION_SUCCESS, response)
      } else {
        context.commit(types.SET_VALIDATE_INTEGRATION_ERROR, response)
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export const closeValidateIntegrationModals = context => {
  context.commit(types.CLOSE_VALIDATE_INTEGRATION_MODALS)
}
