import { ASSIGN_TO } from '@/modules/Bot/enums/assign-to.enum'
import { generateDefaultButton } from '@/modules/Bot/store/getters/template-models'

import i18n from '@/common/i18n'

const QUICK_REPLY = 'quick-reply'

const ASSIGN_ROOM = 'assign-room'

const createCollectTheCustomersName = (i18nTemplatesKey, name) => ({
  id: 'collect-the-customers-name',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.collect-the-customers-name.displayName`
  ),
  type: QUICK_REPLY,
  content: {
    messages: [],
    property: {
      ...name,
      token: i18n?.t(name.token),
      example: i18n?.t(name.example)
    },
    prompt: i18n?.t(
      `${i18nTemplatesKey}.collect-the-customers-name.description`
    ),
    buttons: [],
    validationMessage: '',
    validationMessages: [],
    allowUserInput: true
  }
})

const createConfirmCollectedName = (i18nTemplatesKey, channel) => ({
  id: 'confirm-collected-name',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.confirm-collected-name.displayName`
  ),
  type: QUICK_REPLY,
  content: {
    messages: [],
    prompt: i18n?.t(
      `${i18nTemplatesKey}.confirm-collected-name.description.${channel}`
    ),
    buttons: [
      generateDefaultButton(
        `${i18nTemplatesKey}.confirm-collected-name.buttons.item0`,
        'confirm-collected-name-yes'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.confirm-collected-name.buttons.item1`,
        'confirm-collected-name-no'
      )
    ],
    validationMessages: [],
    allowUserInput: false
  }
})

const createCollectCustomerEmail = (i18nTemplatesKey, email) => ({
  id: 'collect-customer-email',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.collect-customer-email.displayName`
  ),
  type: QUICK_REPLY,
  content: {
    messages: [],
    property: {
      ...email,
      token: i18n?.t(email.token),
      example: i18n?.t(email.example)
    },
    prompt: i18n?.t(`${i18nTemplatesKey}.collect-customer-email.description`),
    buttons: [],
    validationMessage: i18n?.t(
      `${i18nTemplatesKey}.collect-customer-email.validationMessage`
    ),
    validationMessages: [
      {
        level: 0,
        message: i18n?.t(
          `${i18nTemplatesKey}.collect-customer-email.validationMessages.item0`
        )
      }
    ],
    allowUserInput: true
  }
})

const createConfirmCollectedEmail = (i18nTemplatesKey, channel) => ({
  id: 'confirm-collected-email',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.confirm-collected-email.displayName`
  ),
  type: QUICK_REPLY,
  content: {
    messages: [],
    prompt: i18n?.t(
      `${i18nTemplatesKey}.confirm-collected-email.description.${channel}`
    ),
    buttons: [
      generateDefaultButton(
        `${i18nTemplatesKey}.confirm-collected-email.buttons.item0`,
        'confirm-collected-email-yes'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.confirm-collected-email.buttons.item1`,
        'confirm-collected-email-no'
      )
    ],
    validationMessages: [],
    allowUserInput: false
  }
})

const createClosingMessage = i18nTemplatesKey => ({
  id: 'closing-message',
  displayName: i18n?.t(`${i18nTemplatesKey}.closing-message.displayName`),
  type: 'close-conversation',
  content: {
    messages: [
      {
        text: i18n?.t(`${i18nTemplatesKey}.closing-message.description.item0`)
      }
    ]
  }
})

const createReasonForConversation = (i18nTemplatesKey, channel) => ({
  id: 'reason-for-conversation',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.reason-for-conversation.displayName`
  ),
  type: QUICK_REPLY,
  content: {
    messages: [],
    prompt: i18n?.t(
      `${i18nTemplatesKey}.reason-for-conversation.description.${channel}`
    ),
    buttons: [
      generateDefaultButton(
        `${i18nTemplatesKey}.reason-for-conversation.buttons.item0`,
        'reason-for-conversation-doubts'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.reason-for-conversation.buttons.item1`,
        'reason-for-conversation-purchase'
      )
    ],
    validationMessages: [],
    allowUserInput: false
  }
})

const createConnectWithTheSupportTeam = (
  i18nTemplatesKey,
  channel,
  userLogged
) => ({
  id: 'connect-with-the-support-team',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.connect-with-the-support-team.displayName`
  ),
  type: ASSIGN_ROOM,
  content: {
    messages: [
      {
        text: i18n?.t(
          `${i18nTemplatesKey}.connect-with-the-support-team.description.item0`
        )
      }
    ],
    referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
    referenceValue: userLogged?.id || '',
    successMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-with-the-support-team.successMessageContent.item0`
                )
              : ''
        }
      ]
    },
    fallbackMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-with-the-support-team.fallbackMessageContent.item0`
                )
              : ''
        }
      ]
    },
    afterAssignMessageContent: {
      messages: []
    },
    mustHasAvailability: channel === 'web',
    selectedView: 'available'
  }
})

const createCheckIfYouAreAlreadyACustomer = (i18nTemplatesKey, channel) => ({
  id: 'check-if-you-are-already-a-customer',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.check-if-you-are-already-a-customer.displayName`
  ),
  type: QUICK_REPLY,
  content: {
    messages: [],
    prompt: i18n?.t(
      `${i18nTemplatesKey}.check-if-you-are-already-a-customer.description.${channel}`
    ),
    buttons: [
      generateDefaultButton(
        `${i18nTemplatesKey}.check-if-you-are-already-a-customer.buttons.item0`,
        'check-if-you-are-already-a-customer-yes'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.check-if-you-are-already-a-customer.buttons.item1`,
        'check-if-you-are-already-a-customer-no'
      )
    ],
    validationMessages: [],
    allowUserInput: false
  }
})

const createConnectToTheSalesTeam = (
  i18nTemplatesKey,
  channel,
  userLogged
) => ({
  id: 'connect-to-the-sales-team',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.connect-to-the-sales-team.displayName`
  ),
  type: ASSIGN_ROOM,
  content: {
    messages: [
      {
        text: i18n?.t(
          `${i18nTemplatesKey}.connect-to-the-sales-team.description.item0`
        )
      }
    ],
    referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
    referenceValue: userLogged?.id || '',
    successMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-to-the-sales-team.successMessageContent.item0`
                )
              : ''
        }
      ]
    },
    fallbackMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-to-the-sales-team.fallbackMessageContent.item0`
                )
              : ''
        }
      ]
    },
    afterAssignMessageContent: {
      messages: []
    },
    mustHasAvailability: channel === 'web',
    selectedView: null
  }
})

const createIdentifyWhoTheSellerWas = (i18nTemplatesKey, channel) => ({
  id: 'identify-who-the-seller-was',
  displayName: i18n?.t(
    `${i18nTemplatesKey}.identify-who-the-seller-was.displayName`
  ),
  type: QUICK_REPLY,
  content: {
    messages: [],
    property: {
      name: i18n?.t(`${i18nTemplatesKey}.identify-who-the-seller-was.var-name`),
      domain: 'CHAT',
      type: 'string',
      token: `#${i18n?.t(
        `${i18nTemplatesKey}.identify-who-the-seller-was.var-name`
      )}`
    },
    prompt: i18n?.t(
      `${i18nTemplatesKey}.identify-who-the-seller-was.description.${channel}`
    ),
    buttons: [
      generateDefaultButton(
        `${i18nTemplatesKey}.identify-who-the-seller-was.buttons.item0`,
        'identify-who-the-seller-was-seller-0'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.identify-who-the-seller-was.buttons.item1`,
        'identify-who-the-seller-was-seller-1'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.identify-who-the-seller-was.buttons.item2`,
        'identify-who-the-seller-was-seller-2'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.identify-who-the-seller-was.buttons.item3`,
        'identify-who-the-seller-was-seller-3'
      )
    ],
    validationMessage: '',
    validationMessages: [],
    allowUserInput: false
  }
})

const createConnectWithSeller1 = (i18nTemplatesKey, channel, userLogged) => ({
  id: 'connect-with-seller-1',
  displayName: i18n?.t(`${i18nTemplatesKey}.connect-with-seller-1.displayName`),
  type: ASSIGN_ROOM,
  content: {
    messages: [
      {
        text: i18n?.t(
          `${i18nTemplatesKey}.connect-with-seller-1.description.item0`
        )
      }
    ],
    referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
    referenceValue: userLogged?.id || '',
    successMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-with-seller-1.successMessageContent.item0`
                )
              : ''
        }
      ]
    },
    fallbackMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-with-seller-1.fallbackMessageContent.item0`
                )
              : ''
        }
      ]
    },
    afterAssignMessageContent: {
      messages: []
    },
    mustHasAvailability: channel === 'web',
    selectedView: null
  }
})

const createConnectWithSeller2 = (i18nTemplatesKey, channel, userLogged) => ({
  id: 'connect-with-seller-2',
  displayName: i18n?.t(`${i18nTemplatesKey}.connect-with-seller-2.displayName`),
  type: ASSIGN_ROOM,
  content: {
    messages: [
      {
        text: i18n?.t(
          `${i18nTemplatesKey}.connect-with-seller-2.description.item0`
        )
      }
    ],
    referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
    referenceValue: userLogged?.id || '',
    successMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-with-seller-2.successMessageContent.item0`
                )
              : ''
        }
      ]
    },
    fallbackMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-with-seller-2.fallbackMessageContent.item0`
                )
              : ''
        }
      ]
    },
    afterAssignMessageContent: {
      messages: []
    },
    mustHasAvailability: channel === 'web',
    selectedView: 'available'
  }
})

const createConnectWithSeller3 = (i18nTemplatesKey, channel, userLogged) => ({
  id: 'connect-with-seller-3',
  displayName: i18n?.t(`${i18nTemplatesKey}.connect-with-seller-3.displayName`),
  type: ASSIGN_ROOM,
  content: {
    messages: [
      {
        text: i18n?.t(
          `${i18nTemplatesKey}.connect-with-seller-3.description.item0`
        )
      }
    ],
    referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
    referenceValue: userLogged?.id || '',
    successMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-with-seller-3.successMessageContent.item0`
                )
              : ''
        }
      ]
    },
    fallbackMessageContent: {
      messages: [
        {
          text:
            channel === 'web'
              ? i18n?.t(
                  `${i18nTemplatesKey}.connect-with-seller-3.fallbackMessageContent.item0`
                )
              : ''
        }
      ]
    },
    afterAssignMessageContent: {
      messages: []
    },
    mustHasAvailability: channel === 'web',
    selectedView: 'available'
  }
})

const createOfferProducts = (i18nTemplatesKey, channel) => ({
  id: 'offer-products',
  displayName: i18n?.t(`${i18nTemplatesKey}.offer-products.displayName`),
  type: QUICK_REPLY,
  content: {
    messages: [],
    prompt: i18n?.t(
      `${i18nTemplatesKey}.offer-products.description.${channel}`
    ),
    buttons: [
      generateDefaultButton(
        `${i18nTemplatesKey}.offer-products.buttons.item0`,
        'offer-products-webcams'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.offer-products.buttons.item1`,
        'offer-products-headphones'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.offer-products.buttons.item2`,
        'offer-products-chairs'
      )
    ],
    validationMessages: [],
    allowUserInput: false
  }
})

const createWebcams = (i18nTemplatesKey, channel) => {
  const webcamsVarName = i18n?.t(`${i18nTemplatesKey}.webcams.var-name`)

  return {
    id: 'webcams',
    displayName: i18n?.t(`${i18nTemplatesKey}.webcams.displayName`),
    type: QUICK_REPLY,
    content: {
      messages: [],
      property: {
        name: webcamsVarName,
        domain: 'CHAT',
        type: 'string',
        token: `#${webcamsVarName}`
      },
      prompt: i18n?.t(`${i18nTemplatesKey}.webcams.description.${channel}`),
      buttons: [
        generateDefaultButton(
          `${i18nTemplatesKey}.webcams.buttons.item0`,
          'webcams-webcam-0'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.webcams.buttons.item1`,
          'webcams-webcam-1'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.webcams.buttons.item2`,
          'webcams-webcam-2'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.webcams.buttons.item3`,
          'webcams-webcam-3'
        )
      ],
      validationMessage: '',
      validationMessages: [],
      allowUserInput: false
    }
  }
}

const createHeadphones = (i18nTemplatesKey, channel) => {
  const headphonesVarName = i18n?.t(`${i18nTemplatesKey}.headphones.var-name`)

  return {
    id: 'headphones',
    displayName: i18n?.t(`${i18nTemplatesKey}.headphones.displayName`),
    type: QUICK_REPLY,
    content: {
      messages: [],
      property: {
        name: headphonesVarName,
        domain: 'CHAT',
        type: 'string',
        token: `#${headphonesVarName}`
      },
      prompt: i18n?.t(`${i18nTemplatesKey}.headphones.description.${channel}`),
      buttons: [
        generateDefaultButton(
          `${i18nTemplatesKey}.headphones.buttons.item0`,
          'headphones-earphone-0'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.headphones.buttons.item1`,
          'headphones-earphone-1'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.headphones.buttons.item2`,
          'headphones-earphone-2'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.headphones.buttons.item3`,
          'headphones-earphone-3'
        )
      ],
      validationMessage: '',
      validationMessages: [],
      allowUserInput: false
    }
  }
}

const createChairs = (i18nTemplatesKey, channel) => {
  const chairsVarName = i18n?.t(`${i18nTemplatesKey}.chairs.var-name`)

  return {
    id: 'chairs',
    displayName: i18n?.t(`${i18nTemplatesKey}.chairs.displayName`),
    type: QUICK_REPLY,
    content: {
      messages: [],
      property: {
        name: chairsVarName,
        domain: 'CHAT',
        type: 'string',
        token: `#${chairsVarName}`
      },
      prompt: i18n?.t(`${i18nTemplatesKey}.chairs.description.${channel}`),
      buttons: [
        generateDefaultButton(
          `${i18nTemplatesKey}.chairs.buttons.item0`,
          'chairs-chair-0'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.chairs.buttons.item1`,
          'chairs-chair-1'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.chairs.buttons.item2`,
          'chairs-chair-2'
        ),
        generateDefaultButton(
          `${i18nTemplatesKey}.chairs.buttons.item3`,
          'chairs-chair-3'
        )
      ],
      validationMessages: [],
      allowUserInput: false
    }
  }
}

const createSetQuantity = i18nTemplatesKey => {
  const setQuantityVarName = i18n?.t(
    `${i18nTemplatesKey}.set-quantity.var-name`
  )

  return {
    id: 'set-quantity',
    displayName: i18n?.t(`${i18nTemplatesKey}.set-quantity.displayName`),
    type: QUICK_REPLY,
    content: {
      messages: [],
      property: {
        name: setQuantityVarName,
        domain: 'CHAT',
        type: 'number',
        token: `#${setQuantityVarName}`
      },
      prompt: i18n?.t(`${i18nTemplatesKey}.set-quantity.description`),
      buttons: [],
      validationMessage: '',
      validationMessages: [],
      allowUserInput: true
    }
  }
}

const createConfirmOrder = (i18nTemplatesKey, channel) => ({
  id: 'confirm-order',
  displayName: i18n?.t(`${i18nTemplatesKey}.confirm-order.displayName`),
  type: QUICK_REPLY,
  content: {
    messages: [],
    prompt: i18n?.t(`${i18nTemplatesKey}.confirm-order.description.${channel}`),
    buttons: [
      generateDefaultButton(
        `${i18nTemplatesKey}.confirm-order.buttons.item0`,
        'confirm-order-yes'
      ),
      generateDefaultButton(
        `${i18nTemplatesKey}.confirm-order.buttons.item1`,
        'confirm-order-no'
      )
    ],
    validationMessages: [],
    allowUserInput: false
  }
})

export default (i18nTemplatesKey, channel, getPersonProperty, userLogged) => {
  const name = getPersonProperty('name')

  const email = getPersonProperty('email')

  let actions = [
    createCollectTheCustomersName(i18nTemplatesKey, name),
    createConfirmCollectedName(i18nTemplatesKey, channel),
    createCollectCustomerEmail(i18nTemplatesKey, email),
    createConfirmCollectedEmail(i18nTemplatesKey, channel),
    createClosingMessage(i18nTemplatesKey),
    createReasonForConversation(i18nTemplatesKey, channel),
    createConnectWithTheSupportTeam(i18nTemplatesKey, channel, userLogged),
    createCheckIfYouAreAlreadyACustomer(i18nTemplatesKey, channel),
    createConnectToTheSalesTeam(i18nTemplatesKey, channel, userLogged),
    createIdentifyWhoTheSellerWas(i18nTemplatesKey, channel),
    createConnectWithSeller1(i18nTemplatesKey, channel, userLogged),
    createConnectWithSeller2(i18nTemplatesKey, channel, userLogged),
    createConnectWithSeller3(i18nTemplatesKey, channel, userLogged),
    createOfferProducts(i18nTemplatesKey, channel),
    createWebcams(i18nTemplatesKey, channel),
    createHeadphones(i18nTemplatesKey, channel),
    createChairs(i18nTemplatesKey, channel),
    createSetQuantity(i18nTemplatesKey),
    createConfirmOrder(i18nTemplatesKey, channel)
  ]

  if (channel !== 'web') {
    actions = [
      {
        id: 'initial-greeting',
        displayName: i18n?.t(
          `${i18nTemplatesKey}.initial-greeting.displayName`
        ),
        type: 'message',
        content: {
          messages: [
            {
              text: i18n?.t(
                `${i18nTemplatesKey}.initial-greeting.description.item0`
              )
            }
          ]
        }
      },
      ...actions
    ]
  }

  return actions
}
