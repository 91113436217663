import axios from 'axios'

import { loadParameterHeader } from './headers'

export class SpreadsheetService {
  private static instance: SpreadsheetService

  public static getInstance(): SpreadsheetService {
    if (!this.instance) {
      this.instance = new SpreadsheetService()
    }

    return this.instance
  }
  async downloadSpreadsheet(url: string) {
    const { headers } = loadParameterHeader()
    return await axios({
      url: url,
      responseType: 'blob',
      method: 'GET',
      headers
    })
  }
}
