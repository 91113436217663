export default (id, isAdmin) => ({
  filter: {
    ...(!isAdmin && {
      ...{
        'agent._id': {
          $in: [id, null]
        }
      }
    }),
    lastMessageTimeExceeded: true
  },
  remove: [
    'agent._id',
    'group._id',
    'messages.mentions._id',
    'users._id',
    'channel',
    'status',
    '$or',
    'messages',
    'flux.botId',
    'botInfo.hasStarted',
    'lastMessageUserType'
  ]
})
