/* eslint-disable sonarjs/no-duplicate-string */
export default [
  {
    id: 'step-1',
    type: 'common',
    parentActionId: '',
    nextActionId: 'check-if-you-are-a-customer',
    connectionRules: []
  },
  {
    id: 'step-2',
    type: 'common',
    parentActionId: 'd87cc829-2629-424e-a67f-de2e3f7de037',
    nextActionId: '79da4b08-686f-420c-8c03-40cfc1e51fae',
    connectionRules: []
  },
  {
    id: 'step-3',
    type: 'common',
    parentActionId: '79da4b08-686f-420c-8c03-40cfc1e51fae',
    nextActionId: 'confirm-name-collected',
    connectionRules: []
  },
  {
    id: 'step-4',
    type: 'common',
    parentActionId: '50dc5076-d6b8-4af8-9abe-f90a9785be60',
    nextActionId: '0863437e-c0b9-478f-8154-8ad77e1bc0ce',
    connectionRules: []
  },
  {
    id: 'step-5',
    type: 'common',
    parentActionId: '0863437e-c0b9-478f-8154-8ad77e1bc0ce',
    nextActionId: '50dc5076-d6b8-4af8-9abe-f90a9785be60',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['email-confirmation-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-6',
    type: 'common',
    parentActionId: 'confirm-name-collected',
    nextActionId: '50dc5076-d6b8-4af8-9abe-f90a9785be60',
    connectionRules: []
  },
  {
    id: 'step-7',
    type: 'common',
    parentActionId: 'confirm-name-collected',
    nextActionId: '79da4b08-686f-420c-8c03-40cfc1e51fae',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['name-confirmation-no'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-8',
    type: 'common',
    parentActionId: '0863437e-c0b9-478f-8154-8ad77e1bc0ce',
    nextActionId: 'collect-lead-phone',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['email-confirmation-yes'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-9',
    type: 'common',
    parentActionId: 'collect-lead-phone',
    nextActionId: 'collect-company-segment',
    connectionRules: []
  },
  {
    id: 'step-10',
    type: 'common',
    parentActionId: 'check-if-you-are-a-customer',
    nextActionId: 'd87cc829-2629-424e-a67f-de2e3f7de037',
    connectionRules: [
      {
        source: 'CURRENT_SESSION',
        matchType: '$eq',
        values: ['@CONDITIONAL_TRUE'],
        referenceProperty:
          'properties.SESSION.check-if-you-are-a-customer.value',
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-11',
    type: 'common',
    parentActionId: 'check-if-you-are-a-customer',
    nextActionId: 'customer-or-second-visit',
    connectionRules: [
      {
        source: 'CURRENT_SESSION',
        matchType: '$eq',
        values: ['@CONDITIONAL_FALSE'],
        referenceProperty:
          'properties.SESSION.check-if-you-are-a-customer.value',
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-12',
    type: 'fallback',
    parentActionId: 'customer-or-second-visit',
    nextActionId: 'unavailable-agents',
    connectionRules: []
  },
  {
    id: 'step-13',
    type: 'common',
    parentActionId: 'collect-company-segment',
    nextActionId: 'collecting-company-size',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['segment-ecommerce'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-14',
    type: 'common',
    parentActionId: 'collect-company-segment',
    nextActionId: 'disqualifies-lead-and-terminates',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['segment-other'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-15',
    type: 'common',
    parentActionId: 'collect-company-segment',
    nextActionId: 'collecting-company-size',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['segment-education'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-16',
    type: 'common',
    parentActionId: 'collect-company-segment',
    nextActionId: 'collecting-company-size',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['segment-health'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-17',
    type: 'common',
    parentActionId: 'collect-company-segment',
    nextActionId: 'collecting-company-size',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['segment-technology'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-18',
    type: 'common',
    parentActionId: 'collecting-company-size',
    nextActionId: 'disqualifies-lead-and-terminates',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['company-size-250'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-19',
    type: 'fallback',
    parentActionId: 'microenterprise-service',
    nextActionId: 'closing-message',
    connectionRules: []
  },
  {
    id: 'step-20',
    type: 'common',
    parentActionId: 'collecting-company-size',
    nextActionId: 'microenterprise-service',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['company-size-1-9'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-21',
    type: 'common',
    parentActionId: 'collecting-company-size',
    nextActionId: 'small-business-service',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['company-size-10-49'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-22',
    type: 'fallback',
    parentActionId: 'small-business-service',
    nextActionId: 'closing-message',
    connectionRules: []
  },
  {
    id: 'step-23',
    type: 'common',
    parentActionId: 'collecting-company-size',
    nextActionId: 'medium-sized-company-service',
    connectionRules: [
      {
        source: 'USER_INPUT',
        matchType: '$eq',
        values: ['company-size-50-249'],
        referenceProperty: null,
        referenceValue: null
      }
    ]
  },
  {
    id: 'step-24',
    type: 'fallback',
    parentActionId: 'medium-sized-company-service',
    nextActionId: 'closing-message',
    connectionRules: []
  }
]
