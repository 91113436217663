import { services, headers } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

let _client
const getClient = async () => {
  if (_client) {
    return _client
  }
  return (_client = await services.chatIntegrator.getClient())
}

export const getIntegratorWhatsappNumbers = () =>
  getClient()
    .then(client =>
      client.get('/whatsapp/numbers', headers.getAuthorizedHeaders())
    )
    .then(response => response.data)

export const getChatIntegrators = async () => {
  let integrators = Storage.getItem('chat:integrator')

  if (integrators) {
    return integrators
  }

  const res = await getClient().then(client =>
    client.get('/', headers.getAuthorizedHeaders())
  )

  integrators = res.data

  Storage.setItem('chat:integrator', integrators, 3600)

  return integrators
}

export const getNumberTemplatesMessages = async function (number) {
  const cacheKey = `chat:integrator:template:messages:${number}`

  let templateMessages = Storage.getItem(cacheKey)

  if (templateMessages) {
    return templateMessages
  }

  const res = await getClient().then(client =>
    client.get(`/templates/${number}`, headers.getAuthorizedHeaders())
  )

  templateMessages = res.data

  Storage.setItem(cacheKey, templateMessages, 86400)

  return templateMessages
}
