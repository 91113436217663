import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import legacyHeaders from '@/common/services/legacy-headers'

import { ContactForm } from './store/interfaces'

const LIST_FORMS_PATH = '?domain=1'

export class FormService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.createClient('forms')) as AxiosInstance
  }

  async list() {
    try {
      const client = await this.getClient()
      const { data } = await client.get<ContactForm[]>(
        LIST_FORMS_PATH,
        legacyHeaders()
      )
      return data
    } catch (err) {
      console.error(err)
      throw new Error((err as any).message)
    }
  }

  async buildEmbeddedCode(formId: string) {
    try {
      const client = await this.getClient()
      const { data } = await client.get<any>(
        `contact/embedded-code/${formId}`,
        legacyHeaders()
      )
      return data
    } catch (err) {
      console.error(err)
      throw new Error((err as any).message)
    }
  }

  async getToRender(id: string, area: string) {
    try {
      const client = await this.getClient()
      const { data } = await client.get<any>(
        `render/${id}/${area}/?loadSmartFormEvents=false&v=${new Date().getHours()}`,
        legacyHeaders()
      )
      return data
    } catch (err) {
      console.error(err)
      throw new Error((err as any).message)
    }
  }
}
