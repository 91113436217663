export const SET_COMMERCE_PERMISSIONS_CURRENT_STEP =
  'SET_COMMERCE_PERMISSIONS_CURRENT_STEP'

export const SET_COMMERCE_WHATSAPP_NUMBERS = 'SET_COMMERCE_WHATSAPP_NUMBERS'

export const SET_COMMERCE_FACEBOOK_BUSINESS_ACCOUNTS =
  'SET_COMMERCE_FACEBOOK_BUSINESS_ACCOUNTS'

export const SET_COMMERCE_FACEBOOK_TOKEN = 'SET_COMMERCE_FACEBOOK_TOKEN'

export const SET_COMMERCE_FACEBOOK_PERMISSIONS_ERROR =
  'SET_COMMERCE_FACEBOOK_PERMISSIONS_ERROR'

export const SET_COMMERCE_FACEBOOK_TWO_FACTORY_STEP =
  'SET_COMMERCE_FACEBOOK_TWO_FACTORY_STEP'

export const SET_COMMERCE_FACEBOOK_SELECTED_ACCOUNT =
  'SET_COMMERCE_FACEBOOK_SELECTED_ACCOUNT'

export const SET_COMMERCE_CREATE_CATALOG_ERROR =
  'SET_COMMERCE_CREATE_CATALOG_ERROR'
