import { TokensApiState, Token } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_TOKENS](state: TokensApiState, tokens: Token[]) {
    state.tokens = tokens
  },
  [types.SET_TOKENS_LOADING](state: TokensApiState, loading: boolean) {
    state.tokensLoading = loading
  },
  [types.SET_TOKENS_ERROR](state: TokensApiState, error: boolean) {
    state.tokensError = error
  },
  [types.SET_EDIT_TOKEN](state: TokensApiState, token: Token) {
    state.editToken = token
  },
  [types.SET_REMOVE_TOKEN](state: TokensApiState, token: Token) {
    state.removeToken = token
  },
  [types.PUSH_CREATED_TOKEN](state: TokensApiState, token: Token) {
    state.tokens.push(token)
  },
  [types.REMOVE_SELECTED_TOKEN](state: TokensApiState) {
    state.tokens = state.tokens.filter(
      token => token.id !== (state.removeToken as Token).id
    )
  }
}
