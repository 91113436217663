import { GroupsState, Group, GroupAgent, WhatsappNumber } from './interfaces'
import * as types from './mutations-types'

const getGroupIndex = (state: GroupsState, groupId: string) =>
  state.groups.findIndex(g => g.id === groupId)

export default {
  [types.SET_GROUPS](state: GroupsState, groups: Array<Group>) {
    state.groups = groups
  },
  [types.CREATE_GROUP](state: GroupsState, group: Group) {
    state.groups.push(group)
  },
  [types.UPDATE_GROUP](state: GroupsState, group: Group) {
    state.groups.forEach((g, index) => {
      if (g.id === group.id) {
        state.groups[index] = group
      }
    })
  },
  [types.REMOVE_GROUP](state: GroupsState, group: Group) {
    if (state.groups) {
      state.groups = state.groups.filter(g => g.id !== group.id)
    }
  },
  [types.REMOVE_GROUPS](state: GroupsState, groups: Array<Group>) {
    if (state.groups) {
      state.groups = state.groups.filter(g => !groups.includes(g.id))
    }
  },
  [types.ENABLE_GROUP](state: GroupsState, group: Group) {
    state.groups.forEach((g, index) => {
      if (g.id === group.id) {
        state.groups[index].enabled = true
      }
    })
  },
  [types.DISABLE_GROUP](state: GroupsState, group: Group) {
    state.groups.forEach((g, index) => {
      if (g.id === group.id) {
        state.groups[index].enabled = false
      }
    })
  },
  [types.REMOVE_AGENT_IN_GROUP](
    state: GroupsState,
    { groupId, agentEmail }: { groupId: string; agentEmail: string }
  ) {
    const groupIndex = getGroupIndex(state, groupId)

    state.groups[groupIndex].agents.find((a: { email: string }, i: number) => {
      if (a.email === agentEmail) {
        state.groups[groupIndex].agents.splice(i, 1)
      }
    })
  },
  [types.SET_AGENTS_SORTED](state: GroupsState, agents: Array<GroupAgent>) {
    state.agents = agents.sort((a, b) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())
    )
  },
  [types.SET_AGENT](state: GroupsState, agent: GroupAgent) {
    const agentIndex = state.agents.findIndex(a => a.id === agent.id)

    const agents = [...state.agents]

    if (agentIndex !== -1) {
      agents[agentIndex] = {
        ...agent
      }
    }
    state.agents = agents
  },
  [types.SET_WHATSAPP_NUMBERS](
    state: GroupsState,
    whatsappNumbers: Array<WhatsappNumber>
  ) {
    state.whatsappNumbers = whatsappNumbers
  }
}
