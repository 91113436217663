import { AxiosInstance } from 'axios'

import { headers } from '@octadesk-tech/services'

import { getClient } from './http'

const API_NAME = 'marketPlace'

const REGIONS = [
  'ticket',
  'ticketRequester',
  'ticketSettings',
  'reports',
  'dashboard',
  'appLink',
  'configApp',
  'appHeaderLink',
  'channelApp'
]

export default class AppsService {
  getClient(): Promise<AxiosInstance> {
    return getClient(API_NAME)
  }

  async getUserApps() {
    try {
      const { data } = await this.getClient().then(client => {
        const queryParams = REGIONS.map(
          (region, index) => `regions[${index}]=${region}`
        ).join('&')

        return client.get(
          `/apps/?${queryParams}`,
          headers.getAuthorizedHeaders()
        )
      })

      return data
    } catch (e) {
      const errorMessage = `Error at getUserApps: ${e}`

      console.error(errorMessage)

      throw new Error(errorMessage)
    }
  }
}
