import { CONDITION_COMPARATOR } from '@/modules/Bot/enums/ConditionComparator.enum.js'
import { CONDITION_TYPE } from '@/modules/Bot/enums/ConditionType.enum.js'
import { objectId } from '@/modules/Bot/helpers/object-id'
import * as BotFluxesService from '@/modules/Bot/services/fluxes'
import types from '@/modules/Bot/store/mutations-types'

import i18n from '@/common/i18n'

export const changeCurrentConditionsView = (context, { view, ignoreEvent }) => {
  context.commit(types.SET_CURRENT_CONDITIONS_VIEW, view)

  if (ignoreEvent == undefined || !ignoreEvent)
    context.dispatch('setBotUnsavedChanges')
}

export const addInstagramPageCondition = (context, selected) => {
  context.dispatch('addPageCondition', {
    url: selected.businessAccountId,
    isException: false
  })
}

export const addPageCondition = (
  context,
  { url, isException, ignoreEvent }
) => {
  context.dispatch('addCondition', {
    value: url,
    name: CONDITION_TYPE.PAGE_URL,
    description: i18n?.t('flux.conditions.pageUrl'),
    comparator: isException
      ? CONDITION_COMPARATOR.NO_CONTAINS
      : CONDITION_COMPARATOR.CONTAINS,
    comparatorDescription: i18n?.t(
      `flux.comparators.${isException ? 'no' : ''}contains`
    ),
    ignoreEvent
  })
}

export const addInteractionTypeCondition = (context, selected) => {
  context.dispatch('addCondition', {
    value: selected.length ? selected : ['none'],
    name: CONDITION_TYPE.INTERACTION_TYPE,
    description: i18n?.t('flux.condition.interactionTypes'),
    comparator: CONDITION_COMPARATOR.CONTAINS,
    comparatorDescription: i18n?.t(`flux.comparators.contains`)
  })
}

export const addCondition = (
  context,
  { value, name, description, comparator, comparatorDescription, ignoreEvent }
) => {
  const condition = {
    id: objectId(),
    field: {
      name,
      component: 'text',
      types: ['flux'],
      dynamic: false,
      isFormField: false,
      description
    },
    comparator: {
      id: objectId(),
      type: comparator,
      description: comparatorDescription
    },
    value
  }

  context.commit(types.ADD_PAGE_CONDITION, condition)

  if (ignoreEvent === undefined || !ignoreEvent)
    context.dispatch('setBotUnsavedChanges')
}

export const removePageCondition = (context, conditionToRemove) => {
  context.commit(types.REMOVE_PAGE_CONDITION, conditionToRemove)

  context.dispatch('setBotUnsavedChanges')
}

export const updateBotFlux = async ({ commit }, updatedBotFlux) => {
  const { data } = await BotFluxesService.updateFlux(updatedBotFlux)

  commit(types.UPSERT_CHATBOT_FLUX, data)
}

export const sortBotFluxes = async ({ commit }, fluxes) => {
  commit(types.SORT_BOT_FLUXES, fluxes)

  const sortedFluxes = fluxes.map((f, index) => ({
    id: f.id,
    order: index
  }))

  BotFluxesService.sortFluxes(sortedFluxes)
}
