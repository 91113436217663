import { Store } from 'vuex'

import Vue, { VueConstructor } from 'vue'
import VueRouter from 'vue-router'
import { CombinedVueInstance } from 'vue/types/vue'

export type DependencyContainer = {
  router: { private: VueRouter; public: VueRouter }
  store: Store<any>
  app: CombinedVueInstance<Vue, object, object, object, Record<string, any>>
  vue: VueConstructor
}

export const defineModuleBootstrap = (
  fn: (container: DependencyContainer) => void
) => fn
