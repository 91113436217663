export * from './breadcrumb'

export * from './bot'

export * from './content-properties'

export * from './bot-flux'

export * from './interactions'

export * from './sidebar'

export * from './commerce'

export * from './office-hours'

export * from './custom-fields'

import dayjs from 'dayjs'

import { Channel } from '@/modules/Bot/enums/Channel'
import { hasContactStatus, mountDynamicRules } from '@/modules/Bot/helpers/bot'
import {
  handleParsePhoneNumber,
  translateName
} from '@/modules/Bot/helpers/bot.whatsapp.helper'
import * as whatsapp from '@/modules/Bot/services/bot.whatsapp.service'
import * as BotService from '@/modules/Bot/services/bots'
import * as BotFluxesService from '@/modules/Bot/services/fluxes'
import {
  retrievePages,
  instagramRetrievePages
} from '@/modules/Bot/services/messenger-instagram.service'
import * as OfficeHoursService from '@/modules/Bot/services/office-hours'
import types from '@/modules/Bot/store/mutations-types'
import { ClientBotActionTypes } from '@/modules/Bot/store/types'

import i18n from '@/common/i18n'

import privateRouter from '@/routes/router/private-router'

export const getBots = async (context, { channel, version }) => {
  const fluxesAndBots = await BotService.getBots(channel, version)

  const { fluxes, bots } = fluxesAndBots

  context.commit(types.SET_CHATBOTS_FLUXES, fluxes)

  context.commit(types.SET_CHATBOTS, bots)
}

export const getBotsChannel = async (context, { channel, version }) => {
  const fluxesAndBots = await BotService.getBots(channel, version)

  const { fluxes, bots } = fluxesAndBots

  return {
    fluxes,
    bots
  }
}

export const setBotBuilder = (context, status) => {
  context.commit(types.SET_BOT_BUILDER, status)
}

export const setCurrentChatBotFlux = async (
  context,
  { botFluxId, setInitialBreadcrumb = false }
) => {
  const { breadcrumbPages } = context.getters

  const botFlux = await BotFluxesService.getFlux(botFluxId)

  context.commit(types.SET_CURRENT_CHATBOT_FLUX, botFlux)

  const chatBot = await BotService.getBot(botFlux.botId)

  context.commit(types.SET_CURRENT_CHATBOT, chatBot)

  const { chatBotFluxSpecificPagesConditions } = context.getters

  if (chatBotFluxSpecificPagesConditions.length) {
    context.dispatch('changeCurrentConditionsView', { view: 'specific-pages' })
  } else {
    context.dispatch('changeCurrentConditionsView', { view: 'all-pages' })
  }

  let structure = {}

  if (
    chatBot.draft &&
    chatBot.draft.actions &&
    chatBot.draft.connections &&
    chatBot.draft.actions.length &&
    chatBot.draft.connections.length
  ) {
    structure = chatBot.draft
  } else {
    structure = chatBot.published
  }

  context.dispatch('submitCurrentBot', {
    structure: structure,
    proActiveMessage: botFlux.message
  })

  if (setInitialBreadcrumb) {
    context.dispatch(
      'setActiveBreadcrumbStep',
      breadcrumbPages.CONVERSATION_PAGE
    )
  }

  context.dispatch('resetBotUnsavedChanges')
}

export const resetCurrentChatBotFlux = context => {
  context.commit(types.SET_CURRENT_CHATBOT_FLUX)

  context.commit(types.SET_CURRENT_CHATBOT)

  context.dispatch('resetBotUnsavedChanges')

  context.dispatch('clearInvalidConfigurations')
}

/* eslint-disable */
export const createBot = async (
  context,
  { templateName, fluxName, channel }
) => {
  let botFlux = null

  const { getBotBuilderVersion, isLegacyBot } = context.getters

  botFlux = context.getters.getTemplate(fluxName, channel, templateName)

  // Conditional with Contact Status
  const hasConditionalWithContactStatus = botFlux.bot.published.actions.filter(
    action =>
      action.type === 'conditional' &&
      action.content.rules.find(
        rule => rule.input.dataSource === 'contactStatus'
      )
  )

  if (hasConditionalWithContactStatus.length) {
    await context.dispatch('getAllowedValuesFromFixedFields')

    const { personProperties } = context.getters

    if (hasContactStatus({ personProperties })) {
      botFlux.bot.published.actions.forEach((action, actionIndex) => {
        if (action.type === ClientBotActionTypes.Conditional) {
          const rules = mountDynamicRules({
            personProperties,
            rules: action.content.rules
          })

          botFlux.bot.published.actions[actionIndex].content.rules = rules
        }
      })
    } else {
      throw new Error('Error on get contactStatus properties')
    }
  }

  // Office Hours Component
  const hasOfficeHoursActions = botFlux.bot.published.actions.filter(
    action => action.type === 'office-hours'
  )

  if (hasOfficeHoursActions.length) {
    try {
      const morning = [
        {
          start: '08:00',
          end: '12:00'
        }
      ]

      const afternoon = [
        {
          start: '14:00',
          end: '18:00'
        }
      ]

      const response = await OfficeHoursService.createNewOfficeHours({
        daysOfWeek: {
          is24hours: false,
          sameSchedule: true,
          days: [
            {
              dayOfWeek: 1,
              hours: [...morning, ...afternoon]
            },
            {
              dayOfWeek: 2,
              hours: [...morning, ...afternoon]
            },
            {
              dayOfWeek: 3,
              hours: [...morning, ...afternoon]
            },
            {
              dayOfWeek: 4,
              hours: [...morning, ...afternoon]
            },
            {
              dayOfWeek: 5,
              hours: [...morning, ...afternoon]
            }
          ]
        },
        specialDates: {
          active: false
        },
        name: `${i18n?.t('bot.newChatBot.calendar')} ${dayjs().format(
          'YYYY-MM-DD HH:mm:ss'
        )}`,
        timeZone: i18n?.t('bot.newChatBot.timezone.timezone')
      })

      botFlux.bot.published.actions = botFlux.bot.published.actions.map(
        action => {
          if (action.type === 'office-hours') {
            return {
              ...action,
              content: {
                ...action.content,
                calendarId: response.id
              }
            }
          }

          return action
        }
      )
    } catch (err) {
      throw new Error('Error creating new calendar')
    }
  }

  try {
    const builderVersion = getBotBuilderVersion
    botFlux.bot.version = builderVersion

    let createBotResponse

    const res = await BotService.createBot(botFlux.bot)

    const bot = res

    if (bot && bot.id) {
      context.commit(types.UPSERT_CHATBOT, bot)

      botFlux.flux.version = builderVersion

      botFlux.flux.botId = bot.id

      try {
        const response = await BotFluxesService.createFlux(botFlux.flux)

        createBotResponse = response

        context.commit(types.UPSERT_CHATBOT_FLUX, response.data)
      } catch (err) {
        throw new Error('Error creating bot flow', err)
      }
    }

    return {
      channel: botFlux.flux.channel,
      version: builderVersion,
      fluxid: createBotResponse.data.id
    }
  } catch (err) {
    throw new Error('Error creating new bot')
  }
}
/* eslint-enable */

export const saveBot = async (
  context,
  { sendToHome, activateFlux = undefined }
) => {
  context.dispatch('removeAllNotSpecifiedInteractions')

  context.dispatch('closeConversationOnAllBranches')

  context.dispatch('clearAllValidationMessages')

  const {
    currentChatBotFlux,
    currentChatBotFluxChannel,
    currentBotStructure,
    currentChatBot,
    getChatBotConditionsToSave
  } = context.getters

  const { persona, actions, connections, proActiveMessage } =
    currentBotStructure

  const bot = {
    ...currentChatBot,
    published: {
      persona,
      actions,
      connections
    }
  }

  const conditions = getChatBotConditionsToSave()

  const flux = {
    ...currentChatBotFlux,
    ...conditions,
    enabled: activateFlux || currentChatBotFlux.enabled
  }

  if (proActiveMessage) {
    flux.message = {
      enabled: true,
      comment: proActiveMessage.content.messages[0].text,
      sender: persona || null
    }
  } else {
    flux.message = {
      enabled: false
    }
  }
  const validator = getValidator(context, currentChatBotFlux.channel)

  const updatedBot = await BotService.updateBot({
    botId: flux.botId,
    bot,
    validator
  })

  if (updatedBot) {
    const { data } = await BotFluxesService.updateFlux(flux)

    context.commit(types.UPSERT_CHATBOT_FLUX, data)

    context.commit(types.UPSERT_CHATBOT, updatedBot)

    context.dispatch('setCurrentChatBotFlux', {
      botFluxId: flux.id
    })

    context.dispatch('resetBotUnsavedChanges')

    if (sendToHome) {
      privateRouter.push(`/automation-studio/bot/${currentChatBotFluxChannel}`)
    }
  } else {
    console.error('Error on update')
  }
  return updatedBot.warning || false
}

export const deleteBotFlux = async (context, botFluxId) => {
  const { chatBotFluxes } = context.getters

  const botFlux = chatBotFluxes.find(h => h.id === botFluxId)

  if (botFlux) {
    await BotFluxesService.deleteFlux(botFlux.id)

    await BotService.deleteBot(botFlux.botId)

    context.commit(types.DELETE_BOT_FLUX, botFlux.id)
  }
}

export const saveBotAsDraft = async () => {
  //fluxo não possui draft ainda, somente o bot
}

export const resetBotUnsavedChanges = context => {
  context.commit(types.SET_BOT_UNSAVED_CHANGES, false)
}

export const setBotUnsavedChanges = context => {
  context.commit(types.SET_BOT_UNSAVED_CHANGES, true)
}

export const exitingBotConfiguration = (context, payload) => {
  context.commit(types.SET_EXITING_CONFIGURATION, {
    state: true,
    external: payload && payload.external
  })
}

export const cancelExitingBotConfiguration = context => {
  context.commit(types.SET_EXITING_CONFIGURATION, {
    state: false
  })
}

export const exitsBotConfiguration = context => {
  context.dispatch('cancelExitingBotConfiguration')
}

export const setBotChannel = (context, channel) => {
  context.commit(types.SET_BOT_CHANNEL, channel)
}

const getValidator = (context, requestChannel) => {
  const { botChannelsSpecifications } = context.getters

  const { isWhatsappBusinessApiEnabled } = context.rootGetters

  let validator = {}

  let requestDisplayName = requestChannel

  if (isWhatsappBusinessApiEnabled && requestChannel == Channel.whatsapp) {
    requestDisplayName = Channel.waba
  }

  botChannelsSpecifications.forEach(integrators => {
    integrators.channels.forEach(channel => {
      if (channel.displayName.toLowerCase() === requestDisplayName) {
        validator = { ...channel }
      }
    })
  })

  return validator
}

export const duplicateBot = async (context, { request, currentChannel }) => {
  const validator = getValidator(context, request.channel)

  const bot = await BotService.duplicateBot({ botId: request.botId, validator })

  if (bot) {
    request.botId = bot.id

    const data = await BotFluxesService.duplicateFlux(request.fluxId, request)

    const duplicateFlux = await BotService.getBot(request.botId)

    duplicateFlux.channel = request.channel

    context.commit(types.SET_DUPLICATE_FLUX_ID, data.id)

    context.commit(types.SET_DUPLICATE_BOT_FLUX, duplicateFlux)

    await context.dispatch('getBots', {
      channel: currentChannel,
      version: data.version
    })
  }

  return false
}

export const migrateBot = async (context, { request, currentChannel }) => {
  const validator = getValidator(context, request.channel)

  const bot = await BotService.migrateBot({ botId: request.botId, validator })

  if (bot) {
    request.botId = bot.id

    const data = await BotFluxesService.migrateFlux(request.fluxId, request)

    const migrateFlux = await BotService.getBot(request.botId)

    migrateFlux.channel = request.channel

    context.commit(types.SET_MIGRATE_FLUX_ID, data.id)

    context.commit(types.SET_MIGRATE_BOT_FLUX, migrateFlux)

    await context.dispatch('getBots', {
      channel: currentChannel,
      version: data.version
    })
    return data
  }

  return false
}

export const verifyValidateWarnings = async (context, action) => {
  const { currentChatBotFlux } = context.getters

  const validator = getValidator(context, currentChatBotFlux.channel)

  const botVerified = await BotService.getValidateWarnings({
    botId: currentChatBotFlux.botId,
    component: action,
    validator
  })

  action.warning = botVerified.warning
  action.content = botVerified.content
}

export const toggleModalChannelRestrictions = context => {
  context.commit(types.TOGGLE_MODAL_CHANNEL_RESTRICTIONS)
}

export const setBotSpecifications = async context => {
  const botSpecifications = await BotService.getBotSpecifications()

  context.commit(types.SET_CHANNELS_SPECIFICATIONS, botSpecifications)

  context.dispatch('setChannelsSpecifications', botSpecifications)
}

export const setChannelsSpecifications = (context, specifications) => {
  const channels = []

  const channelsSpecifications = []

  if (specifications) {
    specifications.forEach(integrators => {
      integrators.channels.forEach(channel => {
        channels.push(channel.displayName)

        channelsSpecifications.push({ ...channel })
      })
    })
  }

  context.commit(types.SET_BOT_CHANNELS_SPECIFICATIONS, channels)

  context.dispatch('setChannelsInfoSpecifications', {
    channels,
    channelsSpecifications
  })
}

export const setChannelsInfoSpecifications = (context, payload) => {
  const { channels, channelsSpecifications } = payload

  const resources = [
    'template.components.body.textStyles.bold',
    'template.components.body.textStyles.italic',
    'template.components.body.textStyles.lineThrough',
    'template.components.body.textStyles.underline',
    'template.components.body.textStyles.emoji',
    'hoursToAnswer',
    'attachmentMaxSize',
    'supportedExtensions',
    'bots.exclusiveComponents'
  ]

  const getMultiLevelProp = (obj, keys) => {
    return keys.split('.').reduce((cur, key) => {
      return cur[key]
    }, obj)
  }

  const getValue = (channelName, key) => {
    let value = ''
    channelsSpecifications.map(channel => {
      if (channel.displayName === channelName) {
        value = getMultiLevelProp(channel, key)
      }
    })

    return value
  }

  const options = resources.map(resource => {
    const key = resource.split('.').pop()

    return {
      id: key,
      resources: i18n?.t(
        `bot.newChatBot.modals.channelRules.tableLines.${key}`
      ),
      ...channels.reduce(
        (acc, channelName) => (
          (acc[channelName] = {
            name: channelName,
            value: getValue(channelName, resource)
          }),
          acc
        ),
        {}
      )
    }
  })

  context.commit(types.SET_CHANNELS_INFO_SPECIFICATIONS, options)
}

export const setDuplicateBotFlux = (context, botDuplicate) => {
  context.commit(types.SET_DUPLICATE_BOT_FLUX, botDuplicate)
}

export const setDuplicatFluxId = (context, fluxId) => {
  context.commit(types.SET_DUPLICATE_FLUX_ID, fluxId)
}

export const setMigrateBotFlux = (context, botMigrate) => {
  context.commit(types.SET_MIGRATE_BOT_FLUX, botMigrate)
}

export const setMigrateFluxId = (context, fluxId) => {
  context.commit(types.SET_MIGRATE_FLUX_ID, fluxId)
}

export const hasCurrentCompanyFeature = (context, feature) => {
  return context.rootGetters?.companyModel?.company?.features[feature]
}

export const getWhatsappNumbers = async (context, options = {}) => {
  const qrCodeNumbers = await whatsapp.getWhatsappNumbers()

  let numbers = [...qrCodeNumbers]

  if (options.fetchIntegrators) {
    const integratorNumbers = await whatsapp.getIntegratorWhatsappNumbers()

    numbers = [...numbers, ...integratorNumbers]
  }

  context.commit(
    types.SET_WHATSAPP_NUMBERS,
    numbers.map((number, index) => ({
      ...number,
      index,
      valid: true,
      translate: translateName(number?.name),
      customSelect: `${translateName(number?.name)} ${handleParsePhoneNumber(
        number.number
      )}`
    }))
  )

  return numbers
}

export const getFacebookPages = async context => {
  const { subDomain } = context.rootGetters

  await retrievePages(subDomain)
    .then(response => {
      if (!response || !response.data) return

      const { facebookPages, instagramAccounts } = response.data

      context.commit(types.SET_FACEBOOK_PAGES, facebookPages)

      context.commit(types.SET_INSTAGRAM_PAGES, instagramAccounts)
    })
    .catch(err => {
      console.error('Error at getting facebook pages: ', err)
    })
}

export const getInstagramPages = async context => {
  const { subDomain } = context.rootGetters

  await instagramRetrievePages(subDomain)
    .then(response => {
      context.commit(types.SET_INSTAGRAM_PAGES, response.data)
    })
    .catch(err => {
      console.error('Error at getting instagram pages: ', err)
    })
}
