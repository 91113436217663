import { ActionContext } from 'vuex'

import { TicketsSettingsService } from '@/modules/Ticket/ticket-settings/ticket-settings.service'

import { RootState } from '@/store/interfaces'

import { TicketSettingsState } from './interfaces'
import * as types from './mutations-types'

const ticketsSettingsService = new TicketsSettingsService()

export const getTicketsCustomFields = async (
  context: ActionContext<TicketSettingsState, RootState>
) => {
  const ticketsCustomFields =
    await ticketsSettingsService.getTicketsCustomFields()

  context.commit(types.SET_TICKETS_CUSTOM_FIELDS, ticketsCustomFields)
}

export const getPolicy = async (
  context: ActionContext<TicketSettingsState, RootState>
) => {
  const ticketsPolicy = await ticketsSettingsService.getTicketReopenPolicy()

  if (ticketsPolicy) {
    context.commit(types.SET_POLICY, ticketsPolicy)
  }
}

export const setPolicy = async (
  context: ActionContext<TicketSettingsState, RootState>,
  policy: TicketSettingsState['policy']
) => {
  await ticketsSettingsService.setTicketReopenPolicy(policy)
}
