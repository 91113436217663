import { ActionContext } from 'vuex'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { GroupsService } from '@/modules/ChatSettings/groups/groups.service'
import { whatsappConnectionStatus } from '@/modules/ChatSettings/other-channels/whatsapp-web/whatsapp-web.enums'
import { WhatsAppWebService } from '@/modules/ChatSettings/other-channels/whatsapp-web/whatsapp-web.service'

import { RootState } from '@/store/interfaces'

import { WhatsAppWebState, WhatsAppWebNumber } from './interfaces'
import * as types from './mutations-types'

export * from './modules/actions'

const whatsAppWebService = WhatsAppWebService.getInstance()

const connectingNumbers: any = {}

const handleParsePhoneNumber = (phoneNumber: any) => {
  if (!phoneNumber) return ''

  if (!/^\+/.test(phoneNumber)) phoneNumber = `+${phoneNumber}`

  const parsedNumber = parsePhoneNumberFromString(phoneNumber)

  return `+${
    parsedNumber!.countryCallingCode
  } ${parsedNumber!.formatNational()}`
}

const parseWppNumbersList = (wppNumbers: WhatsAppWebNumber[]) => {
  const remapStatus = (status: number) => {
    if (status === whatsappConnectionStatus.connecting) {
      return 1
    }

    if (status === whatsappConnectionStatus.disconnected) {
      return 0
    }

    return 2
  }

  let aux = [...wppNumbers]

  aux = aux.sort((a: WhatsAppWebNumber, b: WhatsAppWebNumber): any => {
    const remappedStatusA = remapStatus(a.status) as number

    const remappedStatusB = remapStatus(b.status) as number

    if (remappedStatusA > remappedStatusB) return 1

    if (remappedStatusA < remappedStatusB) return -1

    if (a.translate > b.translate) return 1

    if (a.translate < b.translate) return -1
  })

  return aux.map((number: any, index: any) => ({
    ...number,
    index,
    valid: true,
    translate: whatsAppWebService.translateName(number.name),
    customSelect: `${whatsAppWebService.translateName(
      number.name
    )} ${handleParsePhoneNumber(number.number)}`
  }))
}

export const getWhatsappWebNumbers = async (
  context: ActionContext<WhatsAppWebState, RootState>
) => {
  const qrCodeNumbers = await whatsAppWebService.getWhatsappNumbers()

  context.commit(
    types.SET_WHATSAPP_WEB_NUMBERS,
    parseWppNumbersList(qrCodeNumbers)
  )

  return qrCodeNumbers
}

export const updateWhatsappWebNumbersStatus = (
  context: ActionContext<WhatsAppWebState, RootState>,
  payload: {
    number: WhatsAppWebNumber
    queue: string
  }
) => {
  context.commit(types.UPDATE_WHATSAPP_WEB_NUMBERS_STATUS, payload)
}

export const updateStateWhatsappWebNumberName = async (
  context: ActionContext<WhatsAppWebState, RootState>,
  number: WhatsAppWebNumber
) => {
  const { id, name } = number

  const { numbers } = context.getters

  const updatedNumbers = [...numbers]

  const currentIndex = numbers.findIndex(
    (number: WhatsAppWebNumber) => number.id === id
  )

  if (currentIndex >= 0) {
    updatedNumbers[currentIndex].name = name

    updatedNumbers[currentIndex].translate =
      whatsAppWebService.translateName(name)
  }

  context.commit(types.SET_WHATSAPP_WEB_NUMBERS, updatedNumbers)

  await whatsAppWebService.updateNumberName(
    number,
    context.rootGetters.userLogged.id
  )
}

export const removeWhatsappWebNumber = async (
  context: ActionContext<WhatsAppWebState, RootState>,
  phone: WhatsAppWebNumber
) => {
  const chatBotFluxes = await whatsAppWebService.getChatBotsFluxes()

  const fluxes = chatBotFluxes
    .filter((b: any) => {
      if (
        b.channel &&
        b.channel === 'whatsapp' &&
        b.conditions.length === 1 &&
        b.conditions[0].value == phone.number
      ) {
        return b
      }
    })
    .map((b: any) => {
      return {
        id: b.id,
        enabled: false
      }
    })

  if (fluxes) {
    whatsAppWebService.inactivateFluxes(fluxes)
  }

  if (connectingNumbers[phone.id]) {
    clearInterval(connectingNumbers[phone.id])

    delete connectingNumbers[phone.id]
  }

  await whatsAppWebService.deleteNumber(
    phone.id,
    context.rootGetters.userLogged.id
  )

  const groupsService = new GroupsService()

  await groupsService.pullAllowedNumbers(phone.number)

  context.commit(types.REMOVE_WHATSAPP_WEB_NUMBER, phone.id)
}

/* eslint-disable */
export const numberWebConnectInterval = async (
  context: ActionContext<WhatsAppWebState, RootState>,
  numberId: any
) => {
  if (connectingNumbers[numberId]) return

  const connected = await whatsAppWebService.getNumberStatus(numberId)

  if (connected.status !== 2) {
    const failedConnect = { id: connected.id, status: -1 }

    context.commit(types.SET_WEB_NUMBER_STATUS, failedConnect)

    let connectAttempts = 0

    connectingNumbers[numberId] = setInterval(async () => {
      try {
        const connectRetry = await whatsAppWebService.getNumberStatus(numberId)

        if (connectRetry.status === 2) {
          context.commit(types.SET_WEB_NUMBER_STATUS, connectRetry)

          clearInterval(connectingNumbers[numberId])

          delete connectingNumbers[numberId]
        } else {
          const failedRetry = { id: connectRetry.id, status: -1 }

          context.commit(types.SET_WEB_NUMBER_STATUS, failedRetry)
        }

        if (connectAttempts >= 30) {
          context.commit(types.SET_WEB_NUMBER_STATUS, connectRetry)

          clearInterval(connectingNumbers[numberId])

          delete connectingNumbers[numberId]
        }

        connectAttempts += 1
      } catch (error) {
        console.error('Error at connect interval attemp: ', error)

        connectAttempts += 1

        if (connectAttempts >= 30) {
          context.commit(types.SET_WEB_NUMBER_STATUS, connected)

          clearInterval(connectingNumbers[numberId])

          delete connectingNumbers[numberId]
        }
      }
    }, 2000)
  } else {
    context.commit(types.SET_WEB_NUMBER_STATUS, connected)

    clearInterval(connectingNumbers[numberId])

    delete connectingNumbers[numberId]
  }
}
/* eslint-enable */
