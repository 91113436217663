export const SET_CONTACTS = 'SET_CONTACTS'

export const SET_CONTACT = 'SET_CONTACT'

export const SET_CONTACTS_COUNT = 'SET_CONTACTS_COUNT'

export const FETCHING_PAGINATED_CONTACTS = 'FETCHING_PAGINATED_CONTACTS'

export const SET_AGENTS = 'SET_AGENTS'

export const REMOVE_CONTACT = 'REMOVE_CONTACT'

export const UPDATE_CONTACT = 'UPDATE_CONTACT'

export const TOGGLE_CONTACT = 'TOGGLE_CONTACT'

export const APPEND_CONTACTS = 'APPEND_CONTACTS'

export const SET_GROUP_AGENTS = 'SET_GROUP_AGENTS'

export const SET_CONTACT_STATUS_LIST = 'SET_CONTACT_STATUS_LIST'

export const SET_SUBDOMAIN_TOTAL_CONTACTS = 'SET_SUBDOMAIN_TOTAL_CONTACTS'

export const UPDATE_CONTACTS_PROPERTIES = 'UPDATE_CONTACTS_PROPERTIES'
