import store from '@/store'

import { CHECKOUT_TYPES, Checkout, CheckoutItem } from './types'

export const checkout = (state: Checkout): Checkout => state

export const checkoutTerms = (state: Checkout) => state.terms

export const checkoutCompany = (state: Checkout) => state.company

export const hasTerms = (state: Checkout): boolean => state.terms.length > 0

export const allTermsIsAccepted = (state: Checkout): boolean =>
  state.terms.find(t => !t.accept) === undefined

export const isEmpty = (state: Checkout): boolean => state.items.length === 0

export const discountIsEmpty = (state: Checkout): boolean =>
  state.discounts.length === 0

export const getTotal = (state: Checkout): number => state.total

export const recurringSubtotal = (state: Checkout) =>
  state.payments?.find(payment => payment.code === 'gross_recurring_billed')
    ?.value

export const getRecurringPayment = (state: Checkout): number =>
  state.payments?.find(payment => payment.code === 'recurring_billed')?.value ||
  0

export const getProRataPayment = (state: Checkout): number =>
  state.payments?.find(payment => payment.code === 'proportional')?.value ?? 0

export const immediateProRata = (state: Checkout) =>
  state.payments?.find(payment => payment.code === 'immediate_proportional')
    ?.value

export const getOneTimePayment = (state: Checkout): number =>
  state.payments?.find(payment => payment.name === 'Cobrado uma única vez')
    ?.value ?? 0

const findFeature = (
  items: CheckoutItem[],
  code: string
): CheckoutItem | object => items.find(item => item.code === code) || {}

export const ticketPreview = (state: Checkout) =>
  findFeature(state.items, 'new-octa-ticket')

export const whatsappPreview = (state: Checkout) =>
  findFeature(state.items, 'whatsapp-api')

export const additionalsPreview = (state: Checkout) =>
  state.items.filter(item => item.isAdditional)

export const usersPreview = (state: Checkout) =>
  findFeature(state.items, 'user')

export const planPreview = (state: Checkout) =>
  state.items.find(item => item.isExcludedFromCheckout)

export const isChangePlan = (state: Checkout): boolean =>
  state.type === CHECKOUT_TYPES.CHANGE_PLAN

export const isAdditionals = (state: Checkout): boolean =>
  state.type === CHECKOUT_TYPES.ADDITIONALS

export const isBuyTicket = (state: Checkout): boolean =>
  state.type === CHECKOUT_TYPES.BUY_TICKET

export const isStore = (state: Checkout): boolean => state.typeStore === true

export const paymentRecurrence = (state: Checkout): number => {
  const code = planPreview(state)?.code
  let recurrence = 1
  if (code?.includes('mensal')) {
    recurrence = 1
  } else if (code?.includes('semestral')) {
    recurrence = 6
  } else if (code?.includes('anual')) {
    recurrence = 12
  }
  return recurrence
}

export const unloggedInfo = (state: Checkout) => state.unloggedInfo

export const tenantId = (state: Checkout): string | undefined =>
  store.state.auth?.octaAuthenticated?.tenantId || state.unloggedInfo?.tenantId
