import { ActionContext } from 'vuex'

import * as BotService from '@/modules/Bot/services/bots'
import * as BotFluxesService from '@/modules/Bot/services/fluxes'
import { WhatsAppIntegratorService } from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v1/services/whatsapp-integrator.service'

import { RootState } from '@/store/interfaces'

import { SurveyState } from './interfaces'
import * as types from './mutations-types'

const whatsAppIntegratorService = WhatsAppIntegratorService.getInstance()

export const setPayload = (
  context: ActionContext<SurveyState, RootState>,
  value: any
) => {
  context.commit(types.SET_PAYLOAD, value)
}

export const setEditSurveyData = (
  context: ActionContext<SurveyState, RootState>,
  value: any
) => {
  context.commit(types.SET_EDIT_SURVEY_DATA, value)
}

export const retrieveIntegratorWhatsappNumbers = () =>
  whatsAppIntegratorService.getIntegratorWhatsappNumbers()

export const updateBot = (_: any, payload: any) => BotService.updateBot(payload)

export const activeFlux = (_: any, payload: any) =>
  BotFluxesService.activeFlux(payload)

export const inactiveFlux = (_: any, payload: any) =>
  BotFluxesService.inactiveFlux(payload)

export const createFluxAndBot = (_: any, payload: any) =>
  BotFluxesService.createFluxAndBot(payload)

export const getBot = (_: any, botID: any) => BotService.getBot(botID)

export const retrieveFlowsByNumbers = (_: any, numbers: any) =>
  BotFluxesService.getFlowsByNumbers(numbers)
