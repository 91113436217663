import { mapGetters, Store } from 'vuex'

import { defineModuleSchema } from '@/modules/Billing/store-schema'

import { BILLING_STORE_ACTIONS, BILLING_STORE_NS } from './enums'

let storeInstance: Store<any> | undefined = undefined

export const useStore = () => {
  return {
    dispatch(action: BILLING_STORE_ACTIONS, payload?: any) {
      if (storeInstance) {
        return storeInstance.dispatch(`${BILLING_STORE_NS}/${action}`, payload)
      }
      return Promise.resolve()
    },
    mapGetter(key: string) {
      return storeInstance?.getters[`${BILLING_STORE_NS}/${key}`]
    },
    mapGetters(keys: string[]) {
      return mapGetters(BILLING_STORE_NS, keys)
    }
  }
}

export const defineStore = (store: Store<any>): void => {
  storeInstance = store
  store.registerModule(BILLING_STORE_NS, defineModuleSchema(store))
}
