import { ChatPreferencesState } from './interfaces'

const state: ChatPreferencesState = {
  automaticOpenTicket: false,
  sendChatToClient: false,
  canShare: false,
  roulette: false,
  maxServices: {
    enabled: false,
    limit: 1
  },
  notifyAgents: {
    enabled: true,
    option: 'all',
    delay: 60
  },
  clientCanClose: false,
  inactivityTimeout: {
    enabled: true,
    delay: 20
  },
  channels: [],
  automaticSetReponsible: false,
  serviceTimeout: {
    unloaded: true,
    agents: {
      enabled: false,
      delay: null
    },
    clients: {
      enabled: false,
      delay: null
    }
  }
}

export default state
