export const modalTypes = {
  default: 'FormContent',
  loadError: 'LoadError',
  created: {
    success: 'SuccessCreated',
    error: 'ErrorCreated'
  },
  updated: {
    success: 'SuccessUpdated',
    error: 'ErrorUpdated',
    warning: 'SaveInformation'
  },
  delete: {
    warning: 'ConfirmDelete',
    success: 'SuccessDelete',
    conflict: 'ConflictDelete'
  }
}

export const officeHourModel = {
  isNew: true,
  name: '',
  daysOfWeek: {
    is24hours: false,
    sameSchedule: false,
    days: [
      {
        dayOfWeek: 1,
        hours: [
          {
            start: null,
            end: null
          }
        ]
      },
      {
        dayOfWeek: 2,
        hours: [
          {
            start: null,
            end: null
          }
        ]
      },
      {
        dayOfWeek: 3,
        hours: [
          {
            start: null,
            end: null
          }
        ]
      },
      {
        dayOfWeek: 4,
        hours: [
          {
            start: null,
            end: null
          }
        ]
      },
      {
        dayOfWeek: 5,
        hours: [
          {
            start: null,
            end: null
          }
        ]
      }
    ]
  },
  specialDates: {
    active: false
  }
}

export const officeHourDays = officeHourModel.daysOfWeek.days
