const SELF_DOCUMENT = window?.document || document
let SRC_AUDIO, OCTADESK_SOUND_ID
const ORIGINAL_TITLE = SELF_DOCUMENT?.title
const TITLE_CHANGER_INTERVAL_TIMER = 1000 // 1s
const STOP_TITLE_CHANGER_TIMEOUT_TIMER = 10000 // 10s
async function createAudioElement(sound) {
  const BASE_URL = 'https://cdn.octadesk.com/main-client/sounds'
  switch (sound) {
    case 'Alerta':
      SRC_AUDIO = `${BASE_URL}/chime.m4a`
      OCTADESK_SOUND_ID = 'octadesk-Alerta'
      break
    case 'Whoosh':
      SRC_AUDIO = `${BASE_URL}/whoosh.m4a`
      OCTADESK_SOUND_ID = 'octadesk-Whoosh'
      break
    case 'Campainha':
      SRC_AUDIO = `${BASE_URL}/ping.m4a`
      OCTADESK_SOUND_ID = 'octadesk-Campainha'
      break
    default:
      SRC_AUDIO = `${BASE_URL}/bubble.m4a`
      OCTADESK_SOUND_ID = 'octadesk-Bubble'
  }

  const audio = SELF_DOCUMENT?.createElement('audio')

  audio.setAttribute('src', SRC_AUDIO)
  audio.setAttribute('autostart', 'false')
  audio.setAttribute('width', '0')
  audio.setAttribute('height', '0')
  audio.setAttribute('id', OCTADESK_SOUND_ID)
  audio.setAttribute('enablejavascript', 'true')
  return SELF_DOCUMENT.body.appendChild(audio)
}
export function deleteAudioElement() {
  const audio = getAudioElement()
  audio?.remove()
}

function getAudioElement() {
  return SELF_DOCUMENT.getElementById(OCTADESK_SOUND_ID)
}
const changeWindowTitle = title => (window.document.title = title)

let titleChangerInteval = null
const startTitleChanger = message => {
  stopNotificationTimers()

  titleChangerInteval = setInterval(
    () =>
      changeWindowTitle(
        message !== window.document.title ? message : ORIGINAL_TITLE
      ),
    TITLE_CHANGER_INTERVAL_TIMER
  )
}
let stopTitleChangerTimeout = null
const stopTitleChanger = () => {
  stopTitleChangerTimeout = setTimeout(
    () => stopAllNotifications(),
    STOP_TITLE_CHANGER_TIMEOUT_TIMER
  )
}

const stopNotificationTimers = () => {
  if (titleChangerInteval) clearInterval(titleChangerInteval)
  if (stopTitleChangerTimeout) clearTimeout(stopTitleChangerTimeout)
}

export const titleNotification = (message = ORIGINAL_TITLE) => {
  startTitleChanger(message)
  stopTitleChanger()
}

export const stopAllNotifications = () => {
  stopNotificationTimers()
  changeWindowTitle(ORIGINAL_TITLE)
  soundNotification(false)
}
export const soundNotification = async (sound, play = true) => {
  if (!SELF_DOCUMENT) {
    throw new Error('[ERROR] document undefined for beep in chat')
  }

  const event = play ? 'play' : 'stop'
  let element = getAudioElement()
  if (
    !element ||
    `octadesk-${sound}` !== OCTADESK_SOUND_ID ||
    (sound === 'Bolhas (Padrão)' && OCTADESK_SOUND_ID === 'octadesk-Bubble')
  ) {
    await createAudioElement(sound)
    element = getAudioElement()
  }
  await element?.[event]?.()
}
