import {
  WhatsAppState,
  WhatsAppNumber,
  WhatsAppNumberValidationData
} from './interfaces'
import moduleMutations from './modules/mutations'
import * as types from './mutations-types'

export default {
  ...moduleMutations,
  [types.SET_WHATSAPP_OFFICIAL_NUMBERS](
    state: WhatsAppState,
    numbers: WhatsAppNumber[]
  ) {
    state.whatsappOfficialNumbers = numbers
  },
  [types.SET_COMPANY_MODEL](state: WhatsAppState, company: any) {
    state.companyModel = company
  },
  [types.SET_IS_WPP_ACTIVE_NUMBER](
    state: WhatsAppState,
    isWppActiveNumber: boolean
  ) {
    state.isWppActiveNumber = isWppActiveNumber
  },
  [types.SET_MIGRATION_NUMBER](state: WhatsAppState, number: WhatsAppNumber) {
    state.migration.number = number
  },
  [types.SET_MIGRATION_NUMBER_VALIDATION_DATA](
    state: WhatsAppState,
    payload: WhatsAppNumberValidationData
  ) {
    state.migration.numberValidationData = payload
  },
  [types.SET_MIGRATION_PHONE_NUMBER_ID](
    state: WhatsAppState,
    phoneNumberId: string
  ) {
    state.migration.phoneNumberId = phoneNumberId
  }
}
