import { BotConnectionRule } from '@octadesk-tech/chat-bot-domain'

export const getButtonConnectionRule = button => {
  if (!button) return null

  if (button.value === '@HTTP_STATUS_CODE_CLIENT_ERROR') {
    return new BotConnectionRule({
      source: 'CURRENT_SESSION',
      matchType: '$eq',
      values: [
        '@HTTP_STATUS_CODE_CLIENT_ERROR',
        '@HTTP_STATUS_CODE_SERVER_ERROR',
        '@HTTP_STATUS_CODE_REDIRECT',
        '@HTTP_STATUS_CODE_INFORMATION'
      ]
    })
  } else if (
    [
      '@CONDITIONAL_FALSE',
      '@CONDITIONAL_TRUE',
      '@HTTP_STATUS_CODE_SUCCESS',
      '@OFFICE_HOURS_TRUE',
      '@OFFICE_HOURS_FALSE'
    ].includes(button.value)
  ) {
    return new BotConnectionRule({
      source: 'CURRENT_SESSION',
      matchType: '$eq',
      values: [`${button.value}`]
    })
  } else {
    return new BotConnectionRule({
      source: 'USER_INPUT',
      matchType: '$eq',
      values: [button.id]
    })
  }
}

export const isValidButtonConnectionRule = (rule, button) => {
  const connectionRule = getButtonConnectionRule(button)

  return (
    button &&
    rule &&
    connectionRule &&
    rule.source === connectionRule.source &&
    rule.matchType === connectionRule.matchType &&
    rule.values.some(value =>
      connectionRule.values.some(cRuleValue => cRuleValue === value)
    )
  )
}
