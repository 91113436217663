export enum FormTypesSuccess {
  created = 'created',
  updated = 'updated'
}

export enum FacebookStatus {
  waiting = 0,
  published = 1,
  failed = 2,
  inactive = 3
}

export enum IntegrationStatus {
  WAITING = 'waiting',
  PUBLISHED = 'published',
  FAILED = 'failed'
}

export enum SourceTypes {
  facebook = 'facebook',
  octadesk = 'octadesk'
}
