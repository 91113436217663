import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { v4 } from 'uuid'

dayjs.extend(utc)

import Storage from '@octadesk-tech/storage'

import { channels } from '@/modules/Chat/components/conversation/enums/chat-channels'
import {
  MESSAGE_TYPES,
  MESSAGE_TABS
} from '@/modules/Chat/components/conversation/enums/message-types'
import { arrangeChat } from '@/modules/Chat/components/conversation/services/chat-arranger'
import * as contactService from '@/modules/Chat/components/conversation/services/contact'
import { getCustomFields } from '@/modules/Chat/components/conversation/services/custom-fields'
import * as servicesEmail from '@/modules/Chat/components/conversation/services/email'
import * as sercicesMacro from '@/modules/Chat/components/conversation/services/macros'
import * as servicesPerson from '@/modules/Chat/components/conversation/services/person'
import * as tagsService from '@/modules/Chat/components/conversation/services/public-tags'
import * as types from '@/modules/Chat/components/conversation/store/mutations-types'
import AnalyticsEvents from '@/modules/Chat/helpers/analytics-events'
import fileTypes from '@/modules/Chat/helpers/file-types'
import isClient from '@/modules/Chat/helpers/is-client'
import roomStatus from '@/modules/Chat/helpers/room-status'
import typesHelper from '@/modules/Chat/helpers/types-helper'
import * as chatServices from '@/modules/Chat/services/chat'
import { statistics } from '@/modules/Chat/services/statistics'

import { SystemTypes } from '@/common/helpers/enums/system-types'

import privateRouter from '@/routes/router/private-router'

export * from './commerce'

export * from './commerce-orders'

export * from './commerce-integrations'

export * from './ticket'

export const cleanCurrentChat = context => {
  context.commit(types.CLEAN_CHAT)
}

export const setMessageType = (context, type) => {
  context.commit(types.SET_MESSAGE_TYPE, type)

  context.dispatch('triggerAutoFocus')
}

export const setMessageTab = (context, tab) => {
  context.commit(types.SET_MESSAGE_TAB, tab)
}

export const addAttachment = (context, attachment) => {
  context.commit(types.ADD_ATTACHMENT, attachment)

  context.dispatch('triggerAutoFocus')
}

export const setIsUploadingRecordedAudio = (context, isUploading) => {
  context.commit(types.IS_UPLOADING_RECORDED_AUDIO, isUploading)
}

export const removeAttachment = (context, attachment) => {
  context.commit(types.REMOVE_ATTACHMENT, attachment)

  context.dispatch('triggerAutoFocus')
}

const stringHasLength = comment => comment && comment.trim()

const acceptsMediaCaption = attachment =>
  fileTypes.isImage(attachment.url) ||
  fileTypes.isVideo(attachment.url) ||
  !fileTypes.isAudio(attachment.url)

const getScaffoldMessage = (context, payload) => {
  const { agent, chatKey } = context.getters

  const user = Object.assign({ type: 1 }, agent)

  return {
    key: v4(),
    user,
    comment: '',
    quotedMessage: payload.quotedMessage,
    quotedMessageKey: payload.quotedMessageKey,
    type: context.state.message.type,
    // eslint-disable-next-line
    // time: ServerDate.toISOString(),
    time: dayjs().toISOString(),
    attachments: [],
    chatKey,
    mentions: [],
    customFields: payload.customFields,
    extendedData: payload.extendedData,
    status: 'sending',
    origin: payload.origin,
    metadata: {
      woz: {
        memoryIds: payload?.memoryIds ?? [],
        correlationId: payload?.correlationId
      }
    }
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const createMessages = (context, payload) => {
  const messages = []
  const attachments = context.state.message.attachments

  const hasTemplate = !!payload.customFields?.template

  const hasInteractiveMessage = !!payload.extendedData?.interactive

  if (
    stringHasLength(payload.comment) ||
    hasTemplate ||
    hasInteractiveMessage ||
    (attachments && attachments.length)
  ) {
    const { currentChat } = context.getters

    if (
      [
        channels.whatsapp,
        channels.facebookMessenger,
        channels.instagram,
        channels.web,
        channels.widget
      ].includes(currentChat.channel) &&
      attachments.length
    ) {
      attachments.forEach((attachment, idx) => {
        const message = getScaffoldMessage(context, payload)

        message.attachments = [attachment]

        message.mentions = (idx === 0 && payload.mentions) || []

        messages.push(message)
      })

      if (payload.comment && payload.comment.trim()) {
        const acceptCaptionMessage = messages.find(message =>
          acceptsMediaCaption(message.attachments[0])
        )

        if (acceptCaptionMessage) {
          acceptCaptionMessage.comment = payload.comment.trim()
        } else {
          const message = getScaffoldMessage(context, payload)

          message.comment = payload.comment.trim()

          messages.unshift(message)
        }
      }

      messages.forEach(message => {
        context.commit(types.CREATE_MESSAGE, message)
      })
    } else {
      const message = getScaffoldMessage(context, payload)

      message.comment = (payload.comment || '').trim()

      message.attachments = attachments || []

      message.mentions = payload.mentions || []

      messages.push(message)

      context.commit(types.CREATE_MESSAGE, message)
    }

    context.commit(types.CLEAR_ATTACHMENTS)

    context.dispatch('removeUnsendedComment', context.getters.chatKey)
  }

  return messages
}

export const sendMessage = async (context, payload) => {
  const messages = await context.dispatch('createMessages', payload)

  if (messages) {
    messages.forEach(async message => {
      try {
        const messageSended = await chatServices.sendMessage(
          context.getters.chatKey,
          message
        )

        statistics.trackActivity('chat')

        const assignedMsg = Object.assign({}, message, messageSended)

        context.commit(types.SET_MESSAGE_SENDED, assignedMsg)

        context.dispatch('updateChatLoop')
      } catch (ex) {
        context.commit(types.SET_MESSAGE_ERROR, {
          message,
          customCode: ex.customCode
        })
      }
    })
  }
}

export const retrySendMessage = async (context, message) => {
  context.commit(types.SET_MESSAGE_SENDING, message)

  try {
    const messageSended = await chatServices.sendMessage(
      message.chatKey || context.getters.chatKey,
      message
    )

    context.commit(
      types.SET_MESSAGE_SENDED,
      Object.assign({}, message, messageSended)
    )

    context.dispatch('updateChatLoop')
  } catch (ex) {
    console.error('Error on send Message', ex.message)

    context.commit(types.SET_MESSAGE_ERROR, {
      message,
      customCode: ex.customCode
    })
  }
}

const handleSetPerson = async chat => {
  let parsedChat = chat

  const person = await servicesPerson.getPerson(chat?.createdBy?._id)

  if (person?.id && chat.createdBy?.name !== person?.name) {
    try {
      parsedChat = {
        ...chat,
        createdBy: getCreatedBy(person || chat?.createdBy)
      }

      delete parsedChat.createdBy.organization

      await chatServices.updateCustomer(chat.createdBy)

      return { parsedChat, person }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    parsedChat,
    person
  }
}

export const openChat = async (context, { chatKey, page, limit }) => {
  let chat = await chatServices.fetchPaginatedChat(chatKey, page, limit)

  context.commit(types.SET_CHAT, chat)

  const { parsedChat, person } = await handleSetPerson(chat)

  chat = parsedChat

  context.commit(types.SET_PERSON, person)

  context.commit(types.STOP_TYPING)

  context.commit(types.UPDATE_CHAT, chat)

  context.commit(types.SET_CHAT, chat)

  context.commit(types.SET_CURRENT_CHAT_PAGINATION, chat.messagesPagination)

  context.commit(types.SET_CURRENT_CHAT_PAGES_LOADED, 1)

  context.dispatch('setCurrentChatTags', chat.publicTags || [])

  context.dispatch('setMessageType', MESSAGE_TYPES.public)

  const { isWozEnabled, isWozTabDefaultEnabled } = context.rootGetters

  const { isSendingMessageWindowOpen, isCurrentChatWhatsappOfficial } =
    context.getters

  const openWozTab =
    isWozEnabled &&
    isWozTabDefaultEnabled &&
    (isSendingMessageWindowOpen() || !isCurrentChatWhatsappOfficial)

  context.dispatch(
    'setMessageTab',
    openWozTab && isClient(chat.lastMessageUserType)
      ? MESSAGE_TABS.woz
      : MESSAGE_TABS.public
  )

  await context.dispatch('updateSidebarChat', chat)

  context.dispatch('triggerAutoFocus')
}

const getCreatedBy = person => {
  return {
    name: person?.name,
    email: person?.email,
    id: person?.id,
    type: person?.type,
    roleType: person?.roleType,
    idContactStatus: person?.idContactStatus,
    contactStatus: person?.contactStatus,
    thumbUrl: person?.thumbUrl,
    customFields: person?.customFields,
    products: person?.products,
    organization: person?.organization,
    organizations: person?.organizations,
    externalIds: person?.externalIds,
    phoneContacts: person?.phoneContacts,
    devices: person?.devices,
    apps: person?.apps
  }
}

export const setChatKey = (context, { key, redirect = true }) => {
  if (!Storage.getItem('hasOpenedAConversation')) {
    Storage.setItem('hasOpenedAConversation', true)
  }

  context.commit(types.SET_CHAT_KEY, key)

  if (redirect) {
    privateRouter.push({ name: 'chat-open-conversation', params: { key } })
  }
}

export const updateChat = async context => {
  if (context.getters.chatKey) {
    const lastUpdate = await chatServices.getRoomLastUpdate(
      context.getters.chatKey
    )

    if (
      context.getters.currentChat &&
      new Date(lastUpdate) > new Date(context.getters.currentChat.updatedAt)
    ) {
      const chat = await chatServices.getChat(context.getters.chatKey)

      context.commit(types.UPDATE_CHAT, chat)
    }
  }
}

let updateChatLoopTimeout

export const updateChatLoop = async context => {
  updateChatLoopTimeout && clearTimeout(updateChatLoopTimeout)

  const { currentChat, inConversationStatus } = context.getters
  if (
    currentChat &&
    (inConversationStatus ||
      !currentChat.survey.sended ||
      !currentChat.survey.responseTime)
  ) {
    const lastUpdatedAt = new Date(currentChat.updatedAt)

    const utcDate = dayjs.utc().toDate()

    const diffMS = Math.floor((utcDate - lastUpdatedAt) / 60)

    updateChatLoopTimeout = setTimeout(async () => {
      await context.dispatch('updateChat')

      context.dispatch('updateChatLoop')
    }, Math.max(Math.min(diffMS, 30000), 10000))
  }
}

export const fetchHistoryChat = (_, { createdById, page }) =>
  chatServices.getUserChats(createdById, page)

export const fetchHistoryChatById = (context, chatKey) => {
  return chatServices
    .viewChat(chatKey)
    .then(chat => {
      const data = arrangeChat(chat)
      context.dispatch('setChatHistory', data)
      return data
    })
    .catch(err => console.error('getChat', err))
}

export const setChatHistory = (context, chat) =>
  context.commit(types.SET_CHAT_HISTORY, chat)

export const setGroup = async (context, { group, roomKeys }) => {
  if (roomKeys?.length) {
    const payload = {
      roomKeys,
      groupId: group.id
    }

    const event = {
      new_analytics: true,
      object_type: 'conversation'
    }

    try {
      await chatServices.setGroupBatch(payload)
      context.dispatch(
        'trackEvent',
        {
          ...event,
          name: AnalyticsEvents.CONVERSATION_MASS_TRANSFER
        },
        { root: true }
      )
    } catch (ex) {
      context.dispatch(
        'trackEvent',
        {
          ...event,
          name: AnalyticsEvents.CONVERSATION_MASS_TRANSFER_FAIL
        },
        { root: true }
      )
      throw ex
    }
  } else {
    await chatServices.setGroup(context.getters.chatKey, group.id)
  }

  context.dispatch('updateChatLoop')

  context.dispatch('triggerAutoFocus')
}

export const setAgent = async (context, { agent, group, roomKeys }) => {
  if (roomKeys?.length) {
    const payload = {
      roomKeys,
      agentId: agent.id
    }

    if (group && group.id) {
      payload.groupId = group.id
    }

    const event = {
      new_analytics: true,
      object_type: 'conversation'
    }

    try {
      await chatServices.setAgentBatch(payload)

      context.dispatch(
        'trackEvent',
        {
          ...event,
          name: AnalyticsEvents.CONVERSATION_MASS_TRANSFER
        },
        { root: true }
      )
    } catch (ex) {
      context.dispatch(
        'trackEvent',
        {
          ...event,
          name: AnalyticsEvents.CONVERSATION_MASS_TRANSFER_FAIL
        },
        { root: true }
      )

      throw ex
    }
  } else {
    await chatServices.setAgent(context.getters.chatKey, {
      agent,
      group
    })
  }

  context.dispatch('updateChatLoop')

  context.dispatch('triggerAutoFocus')
}

export const closeConversation = async (context, { removeReminderId } = {}) => {
  const { currentChat, currentChatType } = context.getters

  const closedChat = await chatServices.closeConversation(
    context.getters.chatKey,
    context.getters.agent,
    removeReminderId
  )

  if (closedChat) {
    context.dispatch(
      'trackEvent',
      {
        name: AnalyticsEvents.CONVERSATION_CLOSED,
        new_analytics: true,
        object_type: 'conversation',
        object_id: currentChat.key,
        channel: Storage.getItem('onboardingChannel'),
        event_method: currentChatType
      },
      { root: true }
    )
  }

  context.commit(types.UPDATE_CHAT, closedChat)

  context.dispatch('updateChatLoop')
}

export const closeConversationBatch = async (
  context,
  { roomKeys, removeRemindersIds } = {}
) => {
  const event = {
    new_analytics: true,
    object_type: 'conversation'
  }

  try {
    const data = await chatServices.closeConversationBatch({
      roomKeys,
      user: context.getters.agent,
      ...(removeRemindersIds?.length ? { removeRemindersIds } : undefined)
    })

    if (data && data.length)
      data.forEach(closedRoom => context.commit(types.UPDATE_CHAT, closedRoom))

    context.dispatch('updateChatLoop')

    context.dispatch(
      'trackEvent',
      {
        ...event,
        name: AnalyticsEvents.CONVERSATION_MASS_CLOSE
      },
      { root: true }
    )

    return data
  } catch (ex) {
    context.dispatch(
      'trackEvent',
      {
        ...event,
        name: AnalyticsEvents.CONVERSATION_MASS_CLOSE_FAIL
      },
      { root: true }
    )
    throw ex
  }
}

export const createTicket = async context => {
  await chatServices.createTicket(context.getters.chatKey)

  context.dispatch('updateChatLoop')

  context.dispatch('triggerAutoFocus')
}

export const readMessages = async context => {
  if (
    context.getters.chatKey &&
    context.getters.currentChat &&
    [roomStatus.waiting, roomStatus.talking].indexOf(
      context.getters.currentChat.status
    ) !== -1
  ) {
    const hasUnreadedMessages = (
      context.getters.currentChat.messages || []
    ).some(m => !m.agentRead)

    if (hasUnreadedMessages) {
      await chatServices.setMessagesReaded(context.getters.chatKey)
    }

    context.dispatch('updateChatLoop')
  }
}

export const setTyping = (context, type) =>
  chatServices.setTyping(context.getters.chatKey, type, context.getters.agent)

export const setTypingUser = (context, user) =>
  context.commit(types.TYPING_USER, user)

export const clearTypingUser = context => context.commit(types.TYPING_USER, {})

export const openMacroCreationModal = (context, payload) =>
  context.commit(types.MACRO_CREATION_MODAL, payload)

export const openMacroEditModal = (context, payload) =>
  context.commit(types.MACRO_EDIT_MODAL, payload)

export const setMacroConfirmDeleteModal = (context, payload) =>
  context.commit(types.SET_MACRO_CONFIRM_DELETE_MODAL, payload)

export const setShowVariablesModal = (context, isActive) =>
  context.commit(types.SET_SHOW_VARIABLES_MODAL, isActive)

export const setCategoryChangeModal = (context, payload) =>
  context.commit(types.SET_CATEGORY_CHANGE_MODAL, payload)

export const setFocusedMessageKey = (context, msgKey) =>
  context.commit(types.SET_FOCUSED_MESSAGE_KEY, msgKey)

export const clearFocusedMessage = context =>
  context.commit(types.CLEAR_FOCUSED_MESSAGE_KEY)

// MACRO
export const getMacros = async (context, userId) => {
  const macros = await sercicesMacro.getMacros(userId)

  context.commit(types.SET_MACROS, macros)

  return macros
}

export const createMacro = async (context, payload) => {
  const macro = await sercicesMacro.createMacro(payload)

  context.commit(types.CREATE_MACRO, macro)

  return true
}
export const updateMacro = async (context, payload) => {
  const updated = await sercicesMacro.updateMacro(payload)

  context.commit(types.UPDATE_MACRO, updated)
}
export const removeMacro = async (context, id) => {
  await sercicesMacro.removeMacro(id)

  context.commit(types.REMOVE_MACRO, id)
}

export const identifyUser = async (context, payload) => {
  const updatedRoom = await chatServices.updateCreatedBy(
    context.getters.currentChat.key,
    payload
  )

  const updatedRooms = [...context.getters.chats]

  const roomIndex = context.getters.chats.findIndex(
    r => r.id === updatedRoom.id
  )

  if (roomIndex !== -1) {
    updatedRooms[roomIndex] = updatedRoom

    context.dispatch('updateChats', updatedRooms)
  }

  context.dispatch('updateChatLoop')
}

export const updateUser = async (context, payload) => {
  const { syncChat } = payload || {}

  delete payload.syncChat

  await chatServices.updateCustomer(payload)

  if (!syncChat) return

  const chat = await chatServices.getChat(context.getters.chatKey)

  context.commit(types.UPDATE_CHAT, chat)

  await context.dispatch('updateSidebarChat', chat)

  context.dispatch('triggerAutoFocus')
}

export const storeUnsendedComment = (context, payload) =>
  context.commit(types.STORE_UNSENDEND_COMMENT, payload)

export const removeUnsendedComment = (context, payload) =>
  context.commit(types.REMOVE_UNSENDEND_COMMENT, payload)

// quote message
export const storeUnsendedQuotedMessage = async (context, payload) => {
  if (payload.message.type === MESSAGE_TYPES.internal) {
    await context.dispatch('setMessageType', MESSAGE_TYPES.internal)
  }

  const { messageType } = context.getters

  context.commit(types.STORE_UNSENDEND_QUOTEDMESSAGE, {
    ...payload,
    messageType
  })
}

export const removeUnsendedQuotedMessage = (context, roomId) => {
  const { messageType } = context.getters

  context.commit(types.REMOVE_UNSENDEND_QUOTEDMESSAGE, { roomId, messageType })
}

// quote message
export const quoteMessage = (context, message) => {
  context.commit(types.QUOTE_MESSAGE, message)
}

export const cancelQuote = context => {
  context.commit(types.CANCEL_QUOTE_MESSAGE)
}

export const setMentionedMessageKey = (context, key) => {
  context.commit(types.SET_MENTIONED_MESSAGE_KEY, key)
}

export const setListMentionedMessageKey = (context, key) => {
  context.commit(types.SET_LIST_MENTIONED_MESSAGE_KEY, key)
}

// conversation scroll
export const setConversationPage = (context, page) => {
  context.commit(types.SET_CONVERSATION_PAGE, page)
}

export const setScrollToBottom = (context, payload) => {
  context.commit(types.SET_SCROLL_BOTTOM, payload)
}

export const setIsTagsPopoverOpen = (context, value) => {
  context.commit(types.SET_IS_TAGS_POPOVER_OPEN, value)
}

export const setCurrentChatTags = (context, value) => {
  context.commit(types.SET_CURRENT_CHAT_TAGS, value)
}

export const recordConversationPublicTags = (context, value) => {
  tagsService
    .recordRoomPublicTags(value)
    .then(
      ({ data }) =>
        Array.isArray(data) && context.commit(types.SET_CURRENT_CHAT_TAGS, data)
    )
}

export const recordAutocompletePublicTags = async (context, value) => {
  const tags = await tagsService.recordAutocompletePublicTags({ params: value })

  if (Array.isArray(tags.data)) {
    context.commit(types.SET_AUTOCOMPLETE_PUBLIC_TAGS, tags.data)
  }

  return tags
}

export const deleteRoomPublicTags = async (context, value) => {
  const { data } = await tagsService.deleteRoomPublicTags(value)

  context.commit(types.SET_CURRENT_CHAT_TAGS, data)

  context.dispatch('triggerAutoFocus')
}

export const createPublicTag = async (context, tagName) => {
  const { data } = await tagsService.createPublicTags({
    payload: { name: tagName, status: 'active', totalUsed: 0 }
  })

  context.commit(types.SET_AUTOCOMPLETE_PUBLIC_TAGS, [
    ...context.getters.autocompletePublicTags,
    data
  ])

  return data
}

export const getAllContactStatus = async context => {
  let statusList = Storage.getItem('contactStatusList')

  if (!statusList || !statusList.length) {
    statusList = await servicesPerson.getAllContactStatus()

    Storage.setItem('contactStatusList', statusList)
  }

  context.commit(types.SET_CONTACT_STATUS_LIST, statusList)

  return statusList
}

export const getOrganizations = async (_, filters) => {
  let localFilters = filters

  if (typesHelper.isObject(localFilters))
    localFilters = Object.entries(localFilters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

  return servicesPerson.getOrganizations(localFilters)
}

export const setCurrentIntegrator = (context, integrator) => {
  context.commit(types.SET_CURRENT_INTEGRATOR, integrator)
}

export const setSendWindow = (context, open) => {
  context.commit(types.SET_SEND_WINDOW, open)
}

export const setIntegratorMessageError = (context, messageType) => {
  context.commit(types.SET_INTEGRATOR_MESSAGE_ERROR, messageType)
}

export const setIsAddContactModalOpen = (context, value) =>
  context.commit(types.SET_IS_ADD_CONTACT_MODAL_OPEN, value)

export const setSelectedContactToAdd = (context, value) =>
  context.commit(types.SET_SELECTED_CONTACT_TO_ADD, value)

export const setSelectedContactToUpdate = (context, value) =>
  context.commit(types.SET_SELECTED_CONTACT_TO_UPDATE, value)

export const setExistingContacts = (context, value) =>
  context.commit(types.SET_EXISTING_CONTACTS, value)

export const setAddContactModalStep = (context, value) =>
  context.commit(types.SET_ADD_CONTACT_MODAL_STEP, value)

export const setAddContactLastPayload = (context, value) =>
  context.commit(types.SET_ADD_CONTACT_LAST_PAYLOAD, value)

export const setUpdateContactLastPayload = (context, value) =>
  context.commit(types.SET_UPDATE_CONTACT_LAST_PAYLOAD, value)

export const createContact = (_, payload) =>
  contactService.createContact(payload)

export const closeCurrentChat = context => {
  context.commit(types.SET_CHAT_KEY, undefined)
}

export const setSelectedTemplateMessage = (context, value) =>
  context.commit(types.SET_SELECTED_TEMPLATE_MESSAGE, value)

export const setRefAutoFocus = (context, ref) =>
  context.commit(types.SET_REF_AUTO_FOCUS, ref)

export const triggerAutoFocus = ({ state }) =>
  state.refAutoFocus && state.refAutoFocus.focus()

export const getRespectiveWhatsAppNumber = context => {
  const { currentChat, whatsappNumbers } = context.getters

  const number = currentChat && currentChat.domainFrom

  const cleanedNumber = number && number.toString().replace(/\D/g, '')

  return whatsappNumbers.find(
    w => w.number.toString().replace(/\D/g, '') === cleanedNumber
  )
}

export const handleShowConversationAssumeErrorModal = (context, showValue) => {
  context.commit(types.SET_SHOW_CONVERSATION_ASSUME_ERROR_MODAL, showValue)
}

export const getUpdatedTagsByIds = async (context, ids) => {
  try {
    const updatedTags = await tagsService.getTagsByIds(ids)

    if (updatedTags && updatedTags.length) {
      context.commit(types.SET_UPDATED_TAGS_MAP, updatedTags)
    }

    const notFoundIds = ids.filter(
      id => !updatedTags.some(tag => tag.id === id)
    )

    if (notFoundIds && notFoundIds.length) {
      context.commit(types.SET_REMOVED_UPDATED_TAGS_MAP, notFoundIds)
    }
  } catch (ex) {
    throw new Error(ex)
  }
}

export const openAttachmentFullscreen = context =>
  context.commit(types.OPEN_ATTACHMENT_FULLSCREEN)

export const closeAttachmentFullscreen = context =>
  context.commit(types.CLOSE_ATTACHMENT_FULLSCREEN)

export const setAttachmentFullscreenInitialIndex = (context, index) =>
  context.commit(types.SET_ATTACHMENT_FULLSCREEN_INDEX, index)

export const setPersonById = async (context, idPerson) => {
  const fetchedPerson = await servicesPerson.getPerson(idPerson)

  context.commit(types.SET_PERSON, fetchedPerson)
}

export const updatePerson = (context, person) =>
  context.commit(types.SET_PERSON, person)

export const updatePersonProperty = (context, props) =>
  context.commit(types.UPDATE_PERSON_PROPERTY, props)

export const fetchCustomFields = async context => {
  const customFields = await getCustomFields(SystemTypes.PERSON)

  context.commit(types.SET_CUSTOM_FIELDS, customFields)
}

export const fetchOrganizationCustomFields = async context => {
  const customFields = await getCustomFields(SystemTypes.ORGANIZATION)

  context.commit(types.SET_ORGANIZATIONS_CUSTOM_FIELDS, customFields)
}

export const setSelectOrganizationOptions = (context, options) => {
  context.commit(types.SET_SELECT_ORGANIZATION_OPTIONS, options)
}

export const setSelectedOrganization = (context, organization) => {
  context.commit(types.SET_SELECTED_ORGANIZATION, organization)
}

export const getPaginatedMessages = (context, { page, limit }) => {
  const { currentChat } = context.getters

  const selfCurrentChat = JSON.parse(JSON.stringify(currentChat))

  if (currentChat) {
    return chatServices
      .fetchPaginatedMessages(currentChat.key, page, limit)
      .then(async messagesPage => {
        if (messagesPage) {
          selfCurrentChat.messages = messagesPage.messages
        }

        context.commit(types.UPDATE_CHAT, selfCurrentChat)

        context.commit(types.SET_CURRENT_CHAT_PAGES_LOADED, page)

        return messagesPage
      })
      .catch(ex => {
        console.error('error on getPaginatedMessages', ex)
        throw ex
      })
  }
}

export const cancelPaginatedMessagesRequest = () => {
  chatServices.cancelPaginatedMessagesRequest()
}

export const getQuotedMessagePage = async (context, quotedRoomKey) => {
  const { currentChat, currentChatPagesLoaded } = context.getters

  const selfCurrentChat = JSON.parse(JSON.stringify(currentChat))

  return chatServices
    .fetchQuotedMessagePage(currentChat.key, quotedRoomKey)
    .then(async quotedMessagesPage => {
      const lastPageLoaded =
        currentChatPagesLoaded[currentChatPagesLoaded.length - 1]

      let messages = []

      for (
        let index = lastPageLoaded + 1;
        index <= quotedMessagesPage.page;
        index++
      ) {
        await chatServices
          .fetchPaginatedMessages(currentChat.key, index)
          .then(messagesPage => {
            messages = messages.concat(messagesPage.messages)

            context.commit(types.SET_CURRENT_CHAT_MESSAGES_PAGE, index)

            context.commit(types.SET_CURRENT_CHAT_PAGES_LOADED, index)
          })
      }
      if (messages) {
        selfCurrentChat.messages = messages
      }

      context.commit(types.UPDATE_CHAT, selfCurrentChat)

      return currentChat
    })
    .catch(ex => {
      console.error('error on getPaginatedMessages', ex)
      throw ex
    })
}

export const cancelQuotedMessagePageRequest = () => {
  chatServices.cancelQuotedMessagePageRequest()
}

export const increaseMessagesPage = (context, amount) => {
  const { currentChatPage } = context.getters

  context.commit(types.SET_CURRENT_CHAT_MESSAGES_PAGE, currentChatPage + amount)
}

export const setCurrentChatNewPageLoading = (context, isLoading) => {
  context.commit(types.SET_CURRENT_CHAT_NEW_PAGE_LOADING, isLoading)
}

export const updateDateToUpdateLastMessageTimer = async (context, date) => {
  context.commit(types.SET_DATE_TO_UPDATE_LAST_MESSAGE_TIMER, date)
}

export const setBuildingChatMessageList = (
  context,
  buildingChatMessageList
) => {
  context.commit(types.SET_BUILDING_CHAT_MESSAGE_LIST, buildingChatMessageList)
}

export const updateCurrentChatProperty = (context, { chatKey, props }) =>
  context.commit(types.UPDATE_CURRENT_CHAT_PROPERTY, { chatKey, props })

export const updateCurrentChatPermissions = async context => {
  context.getters?.currentChat?.key &&
    chatServices
      .fetchConversationAgentPermissions(context.getters.currentChat.key)
      .then(permissions => {
        if (!permissions.open) return context.dispatch('closeCurrentChat')

        context.dispatch('updateCurrentChatProperty', {
          props: permissions
        })
      })
      .catch(ex => {
        console.error('error on updateCurrentChatPermissions', ex)

        throw ex
      })
}

export const getBulkConversationsAgentPermissions = async (_, chatKeys) =>
  chatServices.fetchBulkConversationsAgentPermissions(chatKeys)

export const getAvailableConversationsByPermission = async (
  context,
  { chatKeys, permissionType }
) => {
  if (!chatKeys?.length || !permissionType) return

  const chats = context.getters?.chats?.filter(chat =>
    chatKeys.includes(chat.key)
  )

  return getBulkConversationsAgentPermissions(context, chatKeys).then(
    conversationsPermissions => {
      const allowedKeys = conversationsPermissions
        .filter(cp => cp.permissions?.[permissionType])
        .map(cp => cp.key)

      const allowedConversations = []

      const notAllowedConversations = []

      for (const chat of chats) {
        if (allowedKeys.includes(chat.key)) {
          allowedConversations.push(chat)
        } else {
          notAllowedConversations.push(chat)
        }
      }

      return { allowedKeys, notAllowedConversations, allowedConversations }
    }
  )
}

export const executeAskMe = async (_, { roomKey, field }) =>
  chatServices.fetchAskMe(roomKey, field)

export const getDefaultEmailAddress = async context => {
  const defaultEmail = await servicesEmail.getDefaultEmailAddress()

  context.commit(types.SET_DEFAULT_EMAIL_ADDRESS, defaultEmail)

  return defaultEmail
}

export const setCurrentChatAside = (context, payload) => {
  context.commit(types.SET_CURRENT_CHAT_ASIDE, payload)
}

export const setChatAiModalProps = (context, payload) => {
  context.commit(types.SET_CHAT_AI_MODAL_PROPS, payload)
}

export const setDropdownReadyMessagesOpen = (context, isOpen) => {
  context.commit(types.SET_DROPDOWN_READY_MESSAGES_OPEN, isOpen)
}
