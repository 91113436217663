import { sidebarTabs } from '@/modules/Chat/components/sidebar/enums/sidebar-tabs'
import { enums } from '@/modules/Chat/components/sidebar/services/enums'
import { chatListOpenConversations } from '@/modules/Chat/components/sidebar/services/inbox'

export default {
  selectedSidebarTab: sidebarTabs[0],
  status: chatListOpenConversations,
  page: 1,
  hasMorePages: false,
  waitingChats: 0,
  unrededChats: 0,
  chats: [],
  chatsLength: 0,
  chatsHashMap: {},
  filter: undefined,
  filterName: undefined,
  showFilterPanel: false,
  qtdSelectedFilters: 0,
  selectedFilter: {
    name: undefined,
    func: undefined,
    id: undefined,
    searches: undefined,
    startDate: undefined,
    endDate: undefined,
    channel: undefined,
    channelLabel: undefined,
    lastMessageTimeExceeded: undefined
  },
  temporaryFilter: {
    name: undefined,
    func: undefined,
    id: undefined,
    searches: undefined,
    startDate: undefined,
    endDate: undefined,
    channel: undefined,
    channelLabel: undefined,
    unreadOnly: false,
    publicTags: [],
    organization: [],
    lastMessageTimeExceeded: undefined
  },
  phoneContacts: [],
  hasMoreContacts: false,
  contactPage: 1,
  botFluxes: undefined,
  searchStatus: 'ok',
  isLoadingMore: false,
  channelsUsage: [],
  userLoggedAssignedCount: undefined,
  currentContact: undefined,
  conversationsListType: enums.listTypes.open,
  currentInbox: {
    inboxName: undefined,
    agentId: null
  },
  loadingConversationList: false,
  fetchingChatList: false,
  responseServiceTimeoutCount: 0,
  updateSelectedFilters: 0
}
