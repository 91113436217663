export const SET_COMMERCE_PRODUCT = 'SET_COMMERCE_PRODUCT'

export const TOGGLE_COMMERCE_PRODUCT_LOADER = 'TOGGLE_COMMERCE_PRODUCT_LOADER'

export const TOGGLE_COMMERCE_AVAILABLE_ATTRIBUTES_LOADER =
  'TOGGLE_COMMERCE_AVAILABLE_ATTRIBUTES_LOADER'

export const SET_COMMERCE_PRODUCT_AVAILABLE_ATTRIBUTES =
  'SET_COMMERCE_PRODUCT_AVAILABLE_ATTRIBUTES'

export const SET_COMMERCE_PRODUCT_ATTRIBUTES = 'SET_COMMERCE_PRODUCT_ATTRIBUTES'

export const ADD_COMMERCE_PRODUCT_VARIANTS = 'ADD_COMMERCE_PRODUCT_VARIANTS'

export const CLEAR_COMMERCE_PRODUCT_VARIANTS = 'CLEAR_COMMERCE_PRODUCT_VARIANTS'

export const UPDATE_COMMERCE_PRODUCT_VARIANTS =
  'UPDATE_COMMERCE_PRODUCT_VARIANTS'
