export const SET_GROUPS = 'octadesk/chat/setGroups'

export const CREATE_GROUP = 'octadesk/chat/createGroup'

export const UPDATE_GROUP = 'octadesk/chat/updateGroup'

export const REMOVE_GROUP = 'octadesk/chat/removeGroup'

export const REMOVE_GROUPS = 'octadesk/chat/removeGroups'

export const ENABLE_GROUP = 'octadesk/chat/enableGroup'

export const DISABLE_GROUP = 'octadesk/chat/disableGroup'

export const REMOVE_AGENT_IN_GROUP = 'octadesk/chat/removeAgentInGroup'

export const SET_AGENTS_SORTED = 'SET_AGENTS_SORTED'

export const SET_AGENT = 'SET_AGENT'

export const SET_WHATSAPP_NUMBERS = 'SET_WHATSAPP_NUMBERS'
