export default (id, _isAdmin, isTimeExceededInbox) => {
  const paramsFitler = {
    filter: {
      'agent._id': id
    },
    remove: [
      'messages.mentions._id',
      'users._id',
      'channel',
      'status',
      '$or',
      'messages',
      'flux.botId',
      'botInfo.hasStarted',
      'lastMessageUserType',
      'group._id'
    ]
  }

  if (!isTimeExceededInbox) {
    paramsFitler.remove.push('lastMessageTimeExceeded')
  }
  return paramsFitler
}
