import axios, { AxiosInstance, AxiosError } from 'axios'

import { services, headers } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

import { DomainType } from '@/modules/ChatSettings/whatsapp-oficial/template-messages/v2/template-messages.enum'

import { getClient } from '@/common/services/http'

import { FormPayload, Permissions, GoogleAddressDTO } from './store/interfaces'

export class TemplateMessagesService {
  async getChatClient(): Promise<AxiosInstance> {
    return (await services.chat.getClient()) as AxiosInstance
  }

  async getChatAgentsClient(): Promise<AxiosInstance> {
    return (await services.chatAgents.getClient()) as AxiosInstance
  }

  async getChatIntegratorClient(): Promise<AxiosInstance> {
    return (await services.chatIntegrator.getClient()) as AxiosInstance
  }

  async getChatIntegrators() {
    const integrators = Storage.getItem('chat:integrator')

    if (integrators) {
      return integrators
    }

    const res = await this.getChatIntegratorClient().then(client =>
      client.get('/', headers.getAuthorizedHeaders())
    )

    if (res.data) {
      Storage.setItem('chat:integrator', res.data, 3600)
    }

    return res.data
  }

  async syncTemplateMessages() {
    try {
      const client = await this.getChatClient()

      await client.post(
        '/quick-reply/sync/meta',
        undefined,
        headers.getAuthorizedHeaders()
      )
    } catch (error) {
      throw (error as AxiosError)?.response?.data ?? error
    }
  }

  async retrieveTemplateMessages(payload: any) {
    const { data } = await this.getChatClient().then(client =>
      client.post(
        '/quick-reply/filter',
        {
          ...payload,
          type: 'template',
          fullResponse: true,
          sort: {
            createdAt: -1
          }
        },
        headers.getAuthorizedHeaders()
      )
    )

    return data
  }

  async deleteTemplateMessages(id: string) {
    await this.getChatClient().then(client =>
      client.delete(`/quick-reply/${id}`, headers.getAuthorizedHeaders())
    )
  }

  async uploadFile(data: any, params = {}) {
    return this.getChatClient().then(client => {
      const options = {
        ...headers.getAuthorizedHeaders(),
        progress: data.progress,
        params
      }

      return client
        .post('upload', data.body, options)
        .then(res => res.data)
        .then(data.success)
        .catch(data.error)
        .finally(data.finally)
    })
  }

  async getCustomFields() {
    const client = await getClient('customFields')

    const { Person, Chat } = DomainType

    const values = await Promise.all([
      client.get(
        `/system-type/${Person}?showEnabledItems=true`,
        headers.getAuthorizedHeaders()
      ),
      client.get(
        `/system-type/${Chat}?showEnabledItems=true`,
        headers.getAuthorizedHeaders()
      )
    ])

    return values.reduce((fields, current) => fields.concat(current.data), [])
  }

  async createCustomField(field: any) {
    const response = await getClient('customFields')
      .then(client => client.post('/', field, headers.getAuthorizedHeaders()))
      .catch(error => {
        console.error('Error on create custom field', error)
      })

    return (response as any)?.data
  }

  async createTemplateMessage(payload: FormPayload) {
    try {
      await this.getChatClient().then(client =>
        client.post(
          '/quick-replies/v2',
          payload,
          headers.getAuthorizedHeaders()
        )
      )
    } catch (err) {
      throw new Error((err as any).response.data.customCode)
    }
  }

  async getTemplateMessageById(id: string) {
    try {
      const { data } = await this.getChatClient().then(client =>
        client.get(`/quick-replies/${id}`, headers.getAuthorizedHeaders())
      )

      return data
    } catch (err) {
      throw new Error('Error on get template message v2 by id: ' + err)
    }
  }

  async updateTemplateMessage({
    payload,
    id
  }: {
    payload: FormPayload
    id: string
  }) {
    try {
      await this.getChatClient().then(client =>
        client.put(
          `/quick-replies/v2/${id}`,
          payload,
          headers.getAuthorizedHeaders()
        )
      )
    } catch (err) {
      throw new Error((err as any).response.data.customCode)
    }
  }

  async templateMessageNameIsAvailable(name: string) {
    try {
      const { data } = await this.getChatClient().then(client =>
        client.post(
          '/quick-replies/name/available',
          {
            name
          },
          headers.getAuthorizedHeaders()
        )
      )

      return data
    } catch (err) {
      throw new Error('Error on validate template message name v2: ' + err)
    }
  }

  private _mountGoogleApiUrl(query: string): string {
    const GOOGLE_MAPS_API_URL =
      'https://maps.googleapis.com/maps/api/geocode/json'

    const GOOGLE_MAPS_API_KEY = 'AIzaSyAV8-odPeNViGEovDrqkORQ4KsWcrPu4q8'

    return `${GOOGLE_MAPS_API_URL}?key=${GOOGLE_MAPS_API_KEY}&${encodeURI(
      query
    )}`
  }

  private async _getLocation(query: string): Promise<any> {
    const { data } = await axios.get<{ results?: GoogleAddressDTO[] }>(
      this._mountGoogleApiUrl(query)
    )

    return data?.results
  }

  private _onlyLettersAndSpaces(value: string) {
    return value.replace(/[,-/]/gi, ' ').replace(/[ ]+/gi, ' ')
  }

  getAddressByAddress(address: string) {
    return this._getLocation(`address=${this._onlyLettersAndSpaces(address)}`)
  }

  async updateAvailability({
    templateMessageId,
    permissions
  }: {
    templateMessageId: string
    permissions: Permissions
  }) {
    try {
      const { data } = await this.getChatClient().then(client =>
        client.put(
          `/quick-reply/permissions/${templateMessageId}`,
          {
            permissions
          },
          headers.getAuthorizedHeaders()
        )
      )

      return data
    } catch (err) {
      throw new Error('Error updating availability: ' + err)
    }
  }
}
