import { ASSIGN_TO } from '@/modules/Bot/enums/assign-to.enum'
import { generateDefaultButton } from '@/modules/Bot/store/getters/template-models'

import i18n from '@/common/i18n'

/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-nested-template-literals */
export default (i18nTemplatesKey, channel, getPersonProperty) => {
  let actions = []

  const name = getPersonProperty('name')

  const email = getPersonProperty('email')

  const phone = getPersonProperty('phone')

  const tokenName = i18n?.t(`${i18nTemplatesKey}.choose-sectors.var-name`)

  if (channel !== 'web') {
    actions = [
      {
        id: 'initial-greeting',
        displayName: i18n?.t(
          `${i18nTemplatesKey}.initial-greeting.displayName`
        ),
        type: 'message',
        content: {
          messages: [
            {
              text: i18n?.t(
                `${i18nTemplatesKey}.initial-greeting.description.item0`
              )
            }
          ]
        }
      }
    ]
  }

  actions = [
    ...actions,
    {
      id: 'collect-the-name',
      displayName: i18n?.t(`${i18nTemplatesKey}.collect-the-name.displayName`),
      type: 'quick-reply',
      content: {
        delay: {
          time: 500,
          style: 'typing'
        },
        messages: [],
        property: {
          ...name,
          token: i18n?.t(name.token),
          example: i18n?.t(name.example)
        },
        prompt: i18n?.t(
          `${i18nTemplatesKey}.collect-the-name.description.${channel}`
        ),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'confirm-the-collected-name',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-the-collected-name.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-the-collected-name.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-the-collected-name.buttons.item0`,
            'confirm-the-collected-name-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-the-collected-name.buttons.item1`,
            'confirm-the-collected-name-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'already-a-customer',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.already-a-customer.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.already-a-customer.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.already-a-customer.buttons.item0`,
            'already-a-customer-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.already-a-customer.buttons.item1`,
            'already-a-customer-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'collect-customer-cpf',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.collect-customer-cpf.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          name: i18n?.t(`${i18nTemplatesKey}.collect-customer-cpf.var-name`),
          domain: 'CHAT',
          type: 'number',
          token: `#${i18n?.t(
            `${i18nTemplatesKey}.collect-customer-cpf.var-name`
          )}`
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-customer-cpf.description`),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'collect-email',
      displayName: i18n?.t(`${i18nTemplatesKey}.collect-email.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          ...email,
          token: i18n?.t(email.token),
          example: i18n?.t(email.example)
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-email.description`),
        buttons: [],
        validationMessage: i18n?.t(
          `${i18nTemplatesKey}.collect-email.validationMessage`
        ),
        validationMessages: [
          {
            id: 'f6966694-1861-4f35-8dfa-863ebfd42fd1',
            level: 0,
            message: i18n?.t(
              `${i18nTemplatesKey}.collect-email.validationMessages.item0`
            )
          }
        ],
        allowUserInput: true
      }
    },
    {
      id: 'confirm-collected-cpf',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-collected-cpf.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-collected-cpf.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-cpf.buttons.item0`,
            'confirm-collected-cpf-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-cpf.buttons.item1`,
            'confirm-collected-cpf-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'choose-sectors',
      displayName: i18n?.t(`${i18nTemplatesKey}.choose-sectors.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          name: tokenName,
          domain: 'CHAT',
          type: 'string',
          token: `#${tokenName}`
        },
        prompt: i18n?.t(
          `${i18nTemplatesKey}.choose-sectors.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.choose-sectors.buttons.item0`,
            'choose-sectors-sector-1'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.choose-sectors.buttons.item1`,
            'choose-sectors-sector-2'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.choose-sectors.buttons.item2`,
            'choose-sectors-sector-3'
          )
        ],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'confirm-collected-email',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-collected-email.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-collected-email.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-email.buttons.item0`,
            'confirm-collected-email-confirm'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-email.buttons.item1`,
            'confirm-collected-email-correct'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'collect-the-phone',
      displayName: i18n?.t(`${i18nTemplatesKey}.collect-the-phone.displayName`),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          ...phone,
          token: i18n?.t(phone.token),
          example: i18n?.t(phone.example)
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.collect-the-phone.description`),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'confirm-collected-phone',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirm-collected-phone.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirm-collected-phone.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-phone.buttons.item0`,
            'confirm-collected-phone-confirm'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirm-collected-phone.buttons.item1`,
            'confirm-collected-phone-correct'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'scheduling-forecast',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.scheduling-forecast.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          name: i18n?.t(`${i18nTemplatesKey}.scheduling-forecast.var-name`),
          domain: 'CHAT',
          type: 'string',
          token: `#${i18n?.t(
            `${i18nTemplatesKey}.scheduling-forecast.var-name`
          )}`
        },
        prompt: i18n?.t(
          `${i18nTemplatesKey}.scheduling-forecast.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.scheduling-forecast.buttons.item0`,
            'scheduling-forecast-possible'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.scheduling-forecast.buttons.item1`,
            'scheduling-forecast-days'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.scheduling-forecast.buttons.item2`,
            'scheduling-forecast-week'
          )
        ],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'add-additional-details',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.add-additional-details.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.add-additional-details.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.add-additional-details.buttons.item0`,
            'add-additional-details-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.add-additional-details.buttons.item1`,
            'add-additional-details-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'additional-details',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.additional-details.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        property: {
          name: i18n?.t(`${i18nTemplatesKey}.additional-details.var-name`),
          domain: 'CHAT',
          type: 'string',
          token: `#${i18n?.t(
            `${i18nTemplatesKey}.additional-details.var-name`
          )}`
        },
        prompt: i18n?.t(`${i18nTemplatesKey}.additional-details.description`),
        buttons: [],
        validationMessage: '',
        validationMessages: [],
        allowUserInput: true
      }
    },
    {
      id: 'confirmation-without-details',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirmation-without-details.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirmation-without-details.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirmation-without-details.buttons.item0`,
            'confirmation-without-details-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirmation-without-details.buttons.item1`,
            'confirmation-without-details-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    },
    {
      id: 'pre-booking-confirmation',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.pre-booking-confirmation.displayName`
      ),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.pre-booking-confirmation.description.item0`
            )
          }
        ]
      }
    },
    {
      id: 'forward-pre-scheduling',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.forward-pre-scheduling.displayName`
      ),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t(
              `${i18nTemplatesKey}.forward-pre-scheduling.description.item0`
            )
          }
        ],
        referenceProperty: ASSIGN_TO.noOne,
        successMessageContent: {
          messages: [
            {
              text: ''
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text: ''
            }
          ]
        },
        afterAssignMessageContent: {
          messages: []
        },
        mustHasAvailability: false,
        selectedView: 'available'
      }
    },
    {
      id: 'confirmation-with-details',
      displayName: i18n?.t(
        `${i18nTemplatesKey}.confirmation-with-details.displayName`
      ),
      type: 'quick-reply',
      content: {
        messages: [],
        prompt: i18n?.t(
          `${i18nTemplatesKey}.confirmation-with-details.description.${channel}`
        ),
        buttons: [
          generateDefaultButton(
            `${i18nTemplatesKey}.confirmation-with-details.buttons.item0`,
            'confirmation-with-details-yes'
          ),
          generateDefaultButton(
            `${i18nTemplatesKey}.confirmation-with-details.buttons.item1`,
            'confirmation-with-details-no'
          )
        ],
        validationMessages: [],
        allowUserInput: false
      }
    }
  ]

  return actions
}
