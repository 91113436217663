export const channels = {
  instagram: 'instagram',
  facebookMessenger: 'facebook-messenger',
  whatsapp: 'whatsapp',
  web: 'web',
  email: 'email',
  widget: 'widget'
}

export const channelsNames = {
  instagram: 'Instagram',
  'facebook-messenger': 'Messenger',
  whatsapp: 'WhatsApp',
  web: 'Chat Web',
  email: 'Email'
}

export const apps = {
  instagram: 'Instagram',
  'facebook-messenger': 'Facebook Messenger',
  whatsapp: 'WhatsApp'
}

export const commentCharacterLimit = {
  instagram: 1000,
  'facebook-messenger': 2000,
  whatsapp: false,
  web: false,
  email: false
}

export const maxFileSize = {
  instagram: 8,
  'facebook-messenger': 25,
  whatsapp: 30,
  web: 30,
  email: 20
}

export const enableCommentHeader = {
  instagram: false,
  'facebook-messenger': false,
  whatsapp: false,
  web: false,
  email: true
}

export const channelIcons = {
  whatsapp: 'ChannelWhatsapp',
  'official-whatsapp': 'OfficialChannelWhatsapp',
  web: 'ChannelOctachat',
  'facebook-messenger': 'ChannelMessenger',
  instagram: 'ChannelInstagram',
  email: 'ChannelEmail'
}
