import { LoaderState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SHOW_LOADER](state: LoaderState, show: boolean) {
    state.show = show
  },
  [types.SET_TEXT_LOADER](state: LoaderState, text: string) {
    state.text = text
  }
}
