export enum PersonTypes {
  None = 0,
  Employee = 1,
  Customer = 2,
  Handler = 3,
  System = 4,
  ForwardingEmployee = 5
}

export enum EntityPersonTypes {
  PERSON = 'person',
  CONTACT = 'contact',
  ORGANIZATION = 'organization'
}
