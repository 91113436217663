import { headers } from '@octadesk-tech/services'

import { getBaseClient } from '@/modules/Chat/services/http'

const getCommerceBff = () => getBaseClient('commerceBff')

export const getCommerceClientOrdersService = async ({
  integrationId,
  cellphone,
  email
}) => {
  try {
    const { data } = await getCommerceBff().then(client =>
      client.get(
        `/orders/configs/${integrationId}/customers?phone=${cellphone}&email=${email}`,
        headers.getAuthorizedHeaders()
      )
    )

    return data
  } catch (err) {
    throw new Error(err.response.data)
  }
}

export const getCommerceClientOrderProductsService = async ({
  integrationId,
  orderId
}) => {
  try {
    const { data } = await getCommerceBff().then(client =>
      client.get(
        `/orders/${orderId}/configs/${integrationId}`,
        headers.getAuthorizedHeaders()
      )
    )

    return data
  } catch (ex) {
    throw new Error('Error on get client order details: ' + ex)
  }
}

export const createCommerceClientOrderService = async payload => {
  try {
    const { data } = await getCommerceBff().then(client =>
      client.post('orders', payload, headers.getAuthorizedHeaders())
    )

    return data
  } catch (ex) {
    throw new Error('Error on get client order details: ' + ex)
  }
}

export const createCommerceSales = async payload => {
  try {
    const { data } = await getCommerceBff().then(client =>
      client.post('sales', payload, headers.getAuthorizedHeaders())
    )

    return data
  } catch (err) {
    throw err.response.data
  }
}
