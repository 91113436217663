import i18n from '@/common/i18n'

import { ChatStatus } from '@/common/helpers/enums/chat-status'

import { DataSourceResolver } from './interfaces'

export const roomStatus: DataSourceResolver = () =>
  Promise.resolve(
    Object.values(ChatStatus)
      .filter(val => !Number.isNaN(Number(val)))
      .map(val => ({
        id: val.toString(),
        name: i18n?.t(`dataSources.roomStatus.${val}`) as string
      }))
  )
