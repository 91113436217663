import { ActionContext } from 'vuex'

import { RootState } from '@/store/interfaces'

import {
  getWozAgents,
  toggleEnable,
  getWozAgent,
  updateAgent,
  deleteAgent
} from '../services/ai-api'
import { WOZAgentsState, WozAgent } from './interfaces'
import * as types from './mutation-types'

export const loadAgents = async (
  context: ActionContext<WOZAgentsState, RootState>
) => {
  context.commit(types.SET_WOZ_AGENTS_LOADING, true)

  context.commit(types.SET_WOZ_CURRENT_AGENT, null)

  const agents = await getWozAgents()
    .catch(ex => console.error('Error on get woz agents', { ex }))
    .finally(() => context.commit(types.SET_WOZ_AGENTS_LOADING, false))

  const wozCopilotAgent = agents.find((d: WozAgent) => d.isWoz)

  context.commit(
    types.SET_WOZ_AGENTS,
    agents.filter((d: WozAgent) => !d.isWoz)
  )

  context.commit(types.SET_WOZ_COPILOT_AGENT, wozCopilotAgent)
}

export const toggleAgentState = async (
  context: ActionContext<WOZAgentsState, RootState>,
  payload: {
    id: string
    state: boolean
  }
) => {
  const { id, state } = payload

  await toggleEnable(id, state).catch(ex =>
    console.error('Error on disable agent', { ex, id })
  )

  const agents = context.state.agents

  if (agents) {
    const agent = agents.find(agent => agent.id === id)

    if (agent) {
      agent.active = state

      context.commit(types.SET_WOZ_AGENTS, agents)
    }
  }
}

export const getAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  id: string
) => {
  const agent = id
    ? await getWozAgent(id).catch(ex =>
        console.error('Error on get agent', { ex, id })
      )
    : {
        voice: '',
        wordsToAvoid: [],
        instructions: '',
        isWoz: false,
        name: '',
        onlyMyContent: true,
        active: false,
        specialties: []
      }

  agent.specialties = agent.specialties ?? []

  context.commit(types.SET_WOZ_CURRENT_AGENT, agent)

  return agent
}

export const upsertWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  agent: WozAgent
) => {
  const id = await updateAgent(agent).catch(ex =>
    console.error('Error on update agent', { ex, agent })
  )

  agent.id = id

  context.commit(types.SET_WOZ_CURRENT_AGENT, agent)
}

export const updateCurrentWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  agent: WozAgent
) => {
  context.commit(types.SET_WOZ_CURRENT_AGENT, agent)
}

export const deleteWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  id: string
) => {
  await deleteAgent(id).then(() => {
    const agents = context.state.agents

    if (agents) {
      const agentIndex = agents.findIndex(agent => agent.id === id)

      if (agentIndex >= 0) {
        agents.splice(agentIndex, 1)

        context.commit(types.SET_WOZ_AGENTS, agents)
      }
    }
  })
}
