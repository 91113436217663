export default {
  isEnableNumberModalOpen: false,
  enableNumberCurrentStep: undefined,
  enableNumberIntegratorPayload: undefined,
  enableNumberHasKeyPayload: undefined,
  enableNumberNumberStatusPayload: undefined,
  enableNumberDisableAccountPayload: undefined,
  enableNumberRegisterPayload: undefined,
  enableNumberKeyPayload: undefined,
  enableNumberProfilePayload: undefined,
  enableNumberProfileDescriptionPayload: undefined,
  enableNumberProfileDetailsPayload: undefined,
  editableNumberProfile: undefined,
  integratorAccountStatus: {}
}
