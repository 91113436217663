import { ActionContext } from 'vuex'

import { v4 as uuid } from 'uuid'

import { RootState, GlobalAlert, GlobalAlertState } from '@/store/interfaces'

import * as types from './mutations-types'

export const dispatchAlert = (
  context: ActionContext<GlobalAlertState, RootState>,
  { dismiss, ...payload }: GlobalAlert
) => {
  if (!(payload.title || payload.body)) {
    throw new Error(
      'At least one of the following properties must be filled: "title" or "body".'
    )
  }

  const globalAlert: GlobalAlert = {
    id: uuid(),
    dismissible: true,
    variant: 'info',
    duration: 5000,
    ...payload
  }

  globalAlert.dismiss = () => {
    if (typeof dismiss === 'function') {
      dismiss()
    }

    context.dispatch('dismissAlert', alert)
  }

  context.commit(types.UNSHIFT_GLOBAL_ALERT, globalAlert)

  return globalAlert
}

export const dismissAlert = (
  context: ActionContext<GlobalAlertState, RootState>,
  payload: GlobalAlert
) => {
  context.commit(types.REMOVE_GLOBAL_ALERT, payload)
}

export const clearGlobalAlerts = (
  context: ActionContext<GlobalAlertState, RootState>
) => {
  context.commit(types.CLEAR_GLOBAL_ALERTS)
}
