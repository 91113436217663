import { ModalSteps } from '@/modules/ChatSettings/whatsapp-oficial/numbers/helpers/ModalSteps'
import { WhatsAppIntegratorService } from '@/modules/ChatSettings/whatsapp-oficial/numbers/services/whatsapp-integrator.service'

import * as types from './mutations-types'

const whatsAppIntegratorService = WhatsAppIntegratorService.getInstance()

export const setIsEnableNumberModalOpen = (context: any, value: any) =>
  context.commit(types.SET_IS_ENABLE_NUMBER_MODAL_OPEN, value)

export const setEnableNumberCurrentStep = (context: any, value: any) =>
  context.commit(types.SET_ENABLE_NUMBER_CURRENT_STEP, value)

export const setEnableNumberStepPayload = (context: any, value: any) =>
  context.commit(types.SET_ENABLE_NUMBER_STEP_PAYLOAD, value)

export const clearEnableNumberStepPayloads = (context: any) =>
  context.commit(types.CLEAR_ENABLE_NUMBER_STEP_PAYLOADS)

export const createWhatsAppOfficialNumber = (
  context: any,
  { payload, integratorName }: any
) => whatsAppIntegratorService.addWhatsappApiNumber(payload, integratorName)

export const updateWhatsAppOfficialNumber = (context: any, payload: any) =>
  whatsAppIntegratorService.updateWhatsappApiNumber(payload)

export const setEditableNumberProfile = (context: any, value: any) =>
  context.commit(types.SET_EDITABLE_NUMBER_PROFILE, value)

export const setIntegratorAccountStatus = (context: any, value: any) =>
  context.commit(types.SET_INTEGRATOR_ACCOUNT_STATUS, value)

export const socketWhatsappOnboardingConnected = (context: any, data: any) => {
  if (data.success) {
    context.commit(
      types.SET_ENABLE_NUMBER_CURRENT_STEP,
      ModalSteps.RegisterSuccess
    )
    context.dispatch('getWhatsappWebNumbers', {
      fetchIntegrators: true
    })
  } else {
    context.commit(
      types.SET_ENABLE_NUMBER_CURRENT_STEP,
      ModalSteps.RegisterError
    )
  }
}
