import { TicketSettingsState, TicketsCustomFields, Policy } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_TICKETS_CUSTOM_FIELDS](
    state: TicketSettingsState,
    ticketsCustomFields: TicketsCustomFields[]
  ) {
    state.ticketsCustomFields = ticketsCustomFields
  },
  [types.SET_POLICY](state: TicketSettingsState, policy: Policy) {
    state.policy = policy
  }
}
