import { ASSIGN_TO } from '@/modules/Bot/enums/assign-to.enum'
import { generateDefaultButton } from '@/modules/Bot/store/getters/template-models'

import i18n from '@/common/i18n'

/* eslint-disable sonarjs/no-duplicate-string */

export default (getPersonProperty, userLogged) => {
  const name = getPersonProperty('name')
  const email = getPersonProperty('email')
  const phone = getPersonProperty('phone')
  return [
    {
      id: 'mensagem-de-boas-vindas',
      displayName: i18n?.t('bot.newChatBot.models.welcomeMessage.0'),
      type: 'message',
      content: {
        messages: [
          {
            text: i18n?.t('bot.newChatBot.models.welcomeMessage.1')
          },
          {
            text: i18n?.t('bot.newChatBot.models.welcomeMessage.2')
          }
        ]
      }
    },
    {
      id: 'coletar-nome',
      displayName: i18n?.t('bot.newChatBot.models.collectName.0'),
      type: 'quick-reply',
      content: {
        subType: 'text',
        prompt: i18n?.t('bot.newChatBot.models.collectName.1'),
        property: {
          ...name,
          token: i18n?.t(name.token),
          example: i18n?.t(name.example)
        }
      }
    },
    {
      id: 'coletar-email',
      displayName: i18n?.t('bot.newChatBot.templates.captureEmail.item0'),
      type: 'quick-reply',
      content: {
        subType: 'text',
        prompt: i18n?.t('bot.newChatBot.templates.captureEmail.item1'),
        property: {
          ...email,
          token: i18n?.t(email.token),
          example: i18n?.t(email.token)
        },
        validationMessage: i18n?.t(
          'bot.newChatBot.models.captureEmail.validationMessage.1'
        )
      }
    },
    {
      id: 'coletar-telefone',
      displayName: i18n?.t('bot.newChatBot.models.capturePhone.0'),
      type: 'quick-reply',
      content: {
        subType: 'text',
        prompt: i18n?.t('bot.newChatBot.models.capturePhone.1'),
        property: {
          ...phone,
          token: i18n?.t(phone.token),
          example: i18n?.t(phone.token)
        }
      }
    },
    {
      id: 'coletar-segmento',
      displayName: i18n?.t('bot.newChatBot.models.collectSegment.0'),
      type: 'quick-reply',
      content: {
        prompt: i18n?.t('bot.newChatBot.models.collectSegment.1'),
        buttons: [
          generateDefaultButton('bot.newChatBot.models.commerce'),
          generateDefaultButton('bot.newChatBot.models.communication'),
          generateDefaultButton('bot.newChatBot.models.ecommerce'),
          generateDefaultButton('bot.newChatBot.models.finances'),
          generateDefaultButton('bot.newChatBot.models.industry'),
          generateDefaultButton('bot.newChatBot.models.health'),
          generateDefaultButton('bot.newChatBot.models.serviceProvision'),
          generateDefaultButton('bot.newChatBot.models.technology'),
          generateDefaultButton('bot.newChatBot.models.retail'),
          generateDefaultButton('bot.newChatBot.models.other')
        ],
        property: {
          domain: 'CHAT',
          type: 'string',
          name: i18n?.t('bot.newChatBot.models.properties.chat.business'),
          token: i18n?.t('bot.newChatBot.models.properties.chat.business')
        }
      }
    },
    {
      id: 'coletar-tamanho-empresa',
      displayName: i18n?.t('bot.newChatBot.models.captureEmployees.0'),
      type: 'quick-reply',
      content: {
        prompt: i18n?.t('bot.newChatBot.models.captureEmployees.1'),
        buttons: [
          generateDefaultButton('bot.newChatBot.templates.option.1-15'),
          generateDefaultButton('bot.newChatBot.templates.option.16-30'),
          generateDefaultButton('bot.newChatBot.templates.option.+30'),
          generateDefaultButton('bot.newChatBot.templates.option.dontKnow')
        ],
        property: {
          domain: 'CHAT',
          type: 'string',
          name: i18n?.t('bot.newChatBot.models.properties.chat.employees'),
          token: i18n?.t('bot.newChatBot.models.properties.chat.employees')
        }
      }
    },
    {
      id: 'atribuir-conversa',
      displayName: i18n?.t('bot.newChatBot.templates.assignRoom.item0'),
      type: 'assign-room',
      content: {
        messages: [
          {
            text: i18n?.t('bot.newChatBot.templates.assignRoom.item1')
          }
        ],
        referenceProperty: userLogged?.id ? ASSIGN_TO.agent : ASSIGN_TO.noOne,
        referenceValue: userLogged?.id || '',
        successMessageContent: {
          messages: [
            {
              text: i18n?.t('bot.newChatBot.templates.assignRoom.item2')
            }
          ]
        },
        fallbackMessageContent: {
          messages: [
            {
              text: i18n?.t('bot.newChatBot.templates.assignRoom.item3')
            }
          ]
        },
        mustHasAvailability: true
      }
    },
    {
      id: 'desqualifica-e-encerra',
      displayName: i18n?.t('bot.newChatBot.templates.closeConversation.item0'),
      type: 'close-conversation',
      content: {
        messages: [
          {
            text: i18n?.t('bot.newChatBot.templates.closeConversation.item1')
          }
        ]
      }
    }
  ]
}
