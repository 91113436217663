import { IntegratorSteps } from '@/modules/Commerce/integrations/integrations.enum'

import { IntegrationsCommerceState } from './interfaces'

const state: IntegrationsCommerceState = {
  availableIntegrations: [],
  connect: {
    loader: false,
    currentStep: IntegratorSteps.storeConnect,
    name: '',
    settings: []
  },
  items: []
}

export default state
