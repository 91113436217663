import { parsePhoneNumberFromString } from 'libphonenumber-js'

import i18n from '@/common/i18n'

export const translateName = name => {
  if (!name) return ''

  if (name.startsWith('untitled')) {
    const splittedName = name.split('-')

    return [i18n?.t(`${splittedName[0]}`), splittedName[1]].join(' ')
  }

  return name
}

export const handleParsePhoneNumber = phoneNumber => {
  if (!phoneNumber) return ''

  if (!/^\+/.test(phoneNumber)) phoneNumber = `+${phoneNumber}`

  const parsedNumber = parsePhoneNumberFromString(phoneNumber)

  return `+${parsedNumber.countryCallingCode} ${parsedNumber.formatNational()}`
}
