import { services } from '@octadesk-tech/services'
import { headers } from '@octadesk-tech/services'

let _client
export const getClient = async () => {
  if (_client) {
    return _client
  }

  return (_client = await services.chatIntegrator.getClient())
}

let _whatsAppClient
export const getWhatsAppClient = async () => {
  if (_whatsAppClient) {
    return _whatsAppClient
  }

  return (_whatsAppClient = await services.whatsApp.getClient(
    headers.getAuthorizedHeaders()
  ))
}

export const getWhatsappNumbers = () =>
  getWhatsAppClient()
    .then(client => client.get('/numbers'))
    .then(response => response.data)

export const getIntegratorWhatsappNumbers = async () => {
  try {
    const response = await getClient().then(client =>
      client.get('/whatsapp/numbers', headers.getAuthorizedHeaders())
    )

    if (response.data) {
      return response.data
    }

    throw new Error('Error on getIntegratorWhatsappNumbers')
  } catch (ex) {
    console.error(ex)
  }
}
