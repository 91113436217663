import i18n from '@/common/i18n'
/* eslint-disable sonarjs/no-duplicate-string */
export default [
  {
    id: 'passo-1',
    parentActionId: '',
    nextActionId: 'mensagem-de-boas-vindas',
    connectionRules: []
  },
  {
    id: 'passo-2',
    parentActionId: 'mensagem-de-boas-vindas',
    nextActionId: 'coletar-nome',
    connectionRules: []
  },
  {
    id: 'passo-3',
    parentActionId: 'coletar-nome',
    nextActionId: 'coletar-email',
    connectionRules: []
  },
  {
    id: 'passo-4',
    parentActionId: 'coletar-email',
    nextActionId: 'coletar-telefone',
    connectionRules: []
  },
  {
    id: 'passo-5',
    parentActionId: 'coletar-telefone',
    nextActionId: 'coletar-segmento',
    connectionRules: []
  },
  {
    id: 'passo-6',
    parentActionId: 'coletar-segmento',
    nextActionId: 'desqualifica-e-encerra',
    connectionRules: [
      {
        source: 'USER_INPUT',
        values: [i18n?.t('bot.newChatBot.models.other')],
        matchType: '$eq'
      }
    ]
  },
  {
    id: 'passo-7',
    parentActionId: 'coletar-segmento',
    nextActionId: 'coletar-tamanho-empresa',
    connectionRules: []
  },
  {
    id: 'passo-8',
    parentActionId: 'coletar-tamanho-empresa',
    nextActionId: 'desqualifica-e-encerra',
    connectionRules: [
      {
        source: 'USER_INPUT',
        values: [i18n?.t('bot.newChatBot.templates.option.dontKnow')],
        matchType: '$eq'
      }
    ]
  },
  {
    id: 'passo-9',
    parentActionId: 'coletar-tamanho-empresa',
    nextActionId: 'atribuir-conversa',
    connectionRules: []
  }
]
