import {
  OldWidgetState,
  OldWidgetSettings
} from '@/modules/ChatSettings/other-channels/chat/types'

const defaultSettings: OldWidgetSettings = {
  id: '63864b3e70610dbdf7a2a2fc',
  subDomain: 'qas319100-a32',
  isEnabled: true,
  isChatActive: true,
  title: 'Converse Conosco',
  introduction: 'Estamos aqui para te ajudar!',
  showAgentsThumb: false,
  position: 'bottomRight',
  fontColor: {
    r: 255,
    g: 255,
    b: 255,
    a: 1,
    hue: 0,
    saturation: 0,
    value: 0,
    lightness: 0,
    format: 'HSL'
  },
  bgColor: {
    r: 93,
    g: 169,
    b: 221,
    a: 1,
    hue: 204,
    saturation: 65,
    value: 0,
    lightness: 61,
    format: 'HSL'
  },
  conversation: {
    automaticOpenTicket: false,
    sendChatToClient: false,
    canShare: false,
    roulette: false,
    maxServices: { limit: 1, enabled: false },
    notifyAgents: { enabled: true, option: 'all', delay: 60 },
    clientCanClose: false,
    inactivityTimeout: { enabled: false, delay: 20 },
    channels: [],
    disableMentionAgentsByEmail: false
  },
  messages: {
    unavailable: 'Nossos agentes estão indisponíveis no momento.',
    whatsappUnavailable: 'Nossos agentes estão indisponíveis no momento.'
  },
  integrations: { whatsapp: true },
  fontColorRGBA: '255,255,255,1',
  bgColorRGBA: '93,169,221,1',
  bgColorRGB: '93,169,221',
  useDistributor: false,
  embedCode: 'https://cdn.qaoctadesk.com/embed.js'
}

const state: OldWidgetState = {
  settings: defaultSettings,
  agents: undefined,
  embedCode: '',
  userLogged: undefined,
  userToken: '',
  subDomainStatus: undefined,
  miniClusterStatus: undefined,
  companyModel: undefined,
  whatsappNumbers: undefined,
  enableSendInstructionsModal: false,
  instructionsSent: false,
  ticketApp: false,
  companyFeatures: undefined
}

export default state
