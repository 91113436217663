import { AxiosInstance } from 'axios'

import { headers } from '@octadesk-tech/services'

import { SystemTypes } from '@/common/helpers/enums/system-types'

import { getClient } from './http'

const API_NAME = 'customFields'

export default class CustomFieldService {
  getClient(): Promise<AxiosInstance> {
    return getClient(API_NAME)
  }

  async getCustomFields() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(
          `/system-type/${SystemTypes.PERSON}?showEnabledItems=true`,
          headers.getAuthorizedHeaders()
        )
      )
      return data
    } catch (e) {
      const errorMessage = `Error at getCustomFields: ${e}`

      console.error(errorMessage)

      throw new Error(errorMessage)
    }
  }

  async getOrganizationCustomFields() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(
          `/system-type/${SystemTypes.ORGANIZATION}?showEnabledItems=true`,
          headers.getAuthorizedHeaders()
        )
      )

      return data
    } catch (e) {
      const errorMessage = `Error at getOrganizationCustomFields: ${e}`

      console.error(errorMessage)

      throw new Error(errorMessage)
    }
  }
}
