import { OrganizationState } from '@/store/interfaces'

const state: OrganizationState = {
  organizations: [],
  organizationsCounter: 0,
  organizationMembers: [],
  organizationMembersCount: 0
}

export default state
