import { WozContentState } from './interfaces'

const state: WozContentState = {
  documentsLoading: false,
  documents: [],
  hasAnyDocuments: false,
  tableDocuments: [],
  lastSearchParameters: undefined,
  agent: {
    documentsLoading: false,
    documents: [],
    hasAnyDocuments: false,
    tableDocuments: []
  }
}

export default state
