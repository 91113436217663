import { HomeVideosIds } from '@/modules/Onboarding/enums'
const sales_1 = '/images/Onboarding/videos/sales_1.webp'
const sales_2 = '/images/Onboarding/videos/sales_2.webp'
const sales_3 = '/images/Onboarding/videos/sales_3.webp'
const support_1 = '/images/Onboarding/videos/support_1.webp'
const support_2 = '/images/Onboarding/videos/support_2.webp'
const support_3 = '/images/Onboarding/videos/support_3.webp'

export const homeSteps: Record<string, Record<string, string>> = {
  'onb_vendas_centralizando-e-profissionalizando-os canais-de-contato': {
    titleTag: 'onboarding.pains.items.marketing.first.title',
    titleDescriptionTag:
      'onboarding.pains.items.marketing.first.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.marketing.first.videoDescription',
    videoImg: sales_2,
    time: 'onboarding.pains.items.marketing.first.time',
    id: 'onb_vendas_centralizando-e-profissionalizando-os canais-de-contato',
    videoId: HomeVideosIds.CENTRALIZING
  },
  onb_vendas_aumentando_a_eficiencia_e_produtividade: {
    titleTag: 'onboarding.pains.items.marketing.second.title',
    id: 'onb_vendas_captura_mais_demanda_a_qualquer_hora',
    videoId: HomeVideosIds.SALES_PRODUCTIVITY,
    titleDescriptionTag:
      'onboarding.pains.items.marketing.second.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.marketing.second.videoDescription',
    videoImg: sales_3,

    time: 'onboarding.pains.items.marketing.second.time'
  },
  onb_vendas_visualizando_e_controlando_as_conversas_de_vendas: {
    titleTag: 'onboarding.pains.items.marketing.third.title',
    id: 'onb_vendas_qualificando_a_demanda_automaticamente',
    videoId: HomeVideosIds.SALES,
    titleDescriptionTag:
      'onboarding.pains.items.marketing.third.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.marketing.third.videoDescription',
    videoImg: sales_1,
    time: 'onboarding.pains.items.marketing.third.time'
  },
  onb_vendas_captura_mais_demanda_a_qualquer_hora: {
    titleTag: 'onboarding.pains.items.marketing.fourth.title',
    id: 'onb_vendas_visualizando_e_controlando_as_conversas_de_vendas',
    videoId: HomeVideosIds.CAPTURING,
    titleDescriptionTag:
      'onboarding.pains.items.marketing.fourth.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.marketing.fourth.videoDescription',
    videoImg: sales_1,
    time: 'onboarding.pains.items.marketing.fourth.time'
  },
  onb_vendas_qualificando_a_demanda_automaticamente: {
    titleTag: 'onboarding.pains.items.marketing.fifth.title',
    id: 'onb_vendas_aumentando_a_eficiencia_e_produtividade',
    videoId: HomeVideosIds.SALES,
    titleDescriptionTag:
      'onboarding.pains.items.marketing.fifth.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.marketing.fifth.videoDescription',
    videoImg: sales_1,
    time: 'onboarding.pains.items.marketing.fifth.time'
  },
  onb_atendim_centralizando_todos_os_atendimentos_num_unico_lugar: {
    titleTag: 'onboarding.pains.items.support.first.title',
    id: 'onb_atendim_centralizando_todos_os_atendimentos_num_unico_lugar',
    videoId: HomeVideosIds.CENTRALIZING_SUPPORT,
    titleDescriptionTag:
      'onboarding.pains.items.support.first.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.support.first.videoDescription',
    videoImg: support_2,
    time: 'onboarding.pains.items.support.first.time'
  },
  onb_atendim_melhorando_a_eficiencia_e_produtividade: {
    titleTag: 'onboarding.pains.items.support.second.title',
    id: 'onb_atendim_melhorando_a_eficiencia_e_produtividade',
    videoId: HomeVideosIds.SUPPORT,
    titleDescriptionTag:
      'onboarding.pains.items.support.second.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.support.second.videoDescription',
    videoImg: support_1,
    time: 'onboarding.pains.items.support.second.time'
  },
  onb_atendim_automatizando_atendimentos_recorrentes: {
    titleTag: 'onboarding.pains.items.support.third.title',
    id: 'onb_atendim_automatizando_atendimentos_recorrentes',
    videoId: HomeVideosIds.AUTOMATING_SUPPORT,
    titleDescriptionTag:
      'onboarding.pains.items.support.third.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.support.third.videoDescription',
    videoImg: support_3,
    time: 'onboarding.pains.items.support.third.time'
  },
  onb_atendim_trazendo_dados_e_controles_para_gestão: {
    titleTag: 'onboarding.pains.items.support.fourth.title',
    id: 'onb_atendim_trazendo_dados_e_controles_para_gestão',
    videoId: HomeVideosIds.SUPPORT,
    titleDescriptionTag:
      'onboarding.pains.items.support.fourth.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.support.fourth.videoDescription',
    videoImg: support_1,
    time: 'onboarding.pains.items.support.fourth.time'
  },
  onb_atendim_disponibilizando_autoatendimento: {
    titleTag: 'onboarding.pains.items.support.fifth.title',
    id: 'onb_atendim_disponibilizando_autoatendimento',
    videoId: HomeVideosIds.SUPPORT,
    titleDescriptionTag:
      'onboarding.pains.items.support.fifth.titleDescription',
    videoDescriptionTag:
      'onboarding.pains.items.support.fifth.videoDescription',
    videoImg: support_1,
    time: 'onboarding.pains.items.support.fifth.time'
  }
}
