export const getParameterByName = (name: string, url?: string) => {
  if (!url) url = window.location.href
  name = name.replace(/[[]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const removeQueryString = (url?: string) => {
  const self = !url ? window.location.href : url
  return self.split('?')[0]
}
