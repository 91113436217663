import { BotState } from '@/store/interfaces'

export const botAvatars = (state: BotState) => state.avatars

export const botAvatarsAlt = (state: BotState) => state.avatarsAlt

export const botFlux = (state: BotState) => state.flux

export const botId = (state: BotState) => state.botId || state.flux?.botId

export const currentChatBotFluxChannel = (state: BotState) =>
  state.currentChatBotFluxChannel

export const currentChatBotFlux = (state: BotState) => state.currentChatBotFlux

export const currentChatBot = (state: BotState) => state.currentChatBot

export const currentChatBotPersona = (state: BotState) =>
  state.currentChatBotPersona

export const invalidConfigurations = (state: BotState) =>
  state.invalidConfigurations

export const hasInvalidConfigurations = (state: BotState) =>
  state.invalidConfigurations.length > 0

export const chatBotFluxConditions = (state: BotState) => {
  const flux = currentChatBotFlux(state)
  return (flux && flux.conditions) || []
}

export const getActivationChatbot = (state: BotState) => {
  return state.activationChatBot
}

export const hasDuplicateWarning = (state: BotState) => {
  const chatBot = currentChatBot(state)
  return chatBot?.warning
}

export const chatBotFluxSpecificPagesConditions = (state: BotState) => {
  const conditions = chatBotFluxConditions(state)
  return conditions.filter(
    (condition: any) =>
      condition.comparator &&
      ['$eq', 'contains'].includes(condition.comparator.type)
  )
}

export const chatBotFluxExceptPagesConditions = (state: BotState) => {
  const conditions = chatBotFluxConditions(state)
  return conditions.filter(
    (condition: any) =>
      condition.comparator &&
      ['$ne', 'no-contains'].includes(condition.comparator.type)
  )
}

export const currentConditionsView = (state: BotState) => {
  return state.currentConditionsView
}

export const getChatBotConditionsToSave = (state: BotState) => {
  const conditionsView = currentConditionsView(state)

  const specificPagesConditions = chatBotFluxSpecificPagesConditions(state)
  const pagesConditions = chatBotFluxExceptPagesConditions(state)

  let conditions = []
  if (conditionsView === 'all-pages') {
    conditions = pagesConditions.filter(condition => condition.value)
  } else {
    conditions = specificPagesConditions.filter(condition => condition.value)
  }
  const currentChatBotFluxChannel = state?.currentChatBotFlux?.channel

  return {
    conditions,
    allConditions:
      currentChatBotFluxChannel !== 'web' ||
      conditions.length === 1 ||
      conditionsView === 'all-pages'
  }
}

export const hasBotUnsavedChanges = (state: BotState) =>
  state.hasBotUnsavedChanges

export const showExitWarningModal = (state: BotState) =>
  state.showExitWarningModal

export const groupsWithoutConnection = ({
  groupsWithoutConnection
}: BotState) => {
  return groupsWithoutConnection
}

export const emptyFields = ({ emptyFields }: BotState) => {
  return emptyFields || []
}
