import { DomainType, FieldType } from '@/modules/Bot/enums/custom-fields-enum'
import * as CustomFieldsService from '@/modules/Bot/services/custom-fields'
import types from '@/modules/Bot/store/mutations-types'

export const updatePersonFields = async (context, payload) => {
  context.commit(types.SET_CREATE_LOADER, true)

  await CustomFieldsService.updatePersonFields(payload)

  await context.dispatch('fetchCustomFields')

  context.commit(types.SET_CREATE_LOADER, false)
}

export const fetchCustomFields = async context => {
  const fields = await CustomFieldsService.getCustomFields()

  const { isResponsibleContactEnabled } = context.rootGetters

  const personFields = [
    {
      key: 'nome-contato',
      fieldId: 'nome-contato',
      property: 'room.createdBy.name',
      type: 'text',
      domainType: DomainType.Person
    },
    {
      key: 'email-contato',
      fieldId: 'email-contato',
      property: 'room.createdBy.email',
      type: 'text',
      domainType: DomainType.Person
    },
    ...fields.filter(f => f.domainType === DomainType.Person)
  ]

  if (isResponsibleContactEnabled) {
    personFields.splice(2, 0, {
      key: 'responsavel-contato',
      fieldId: 'responsavel-contato',
      property: 'room.createdBy.responsibleContact',
      type: 'text',
      domainType: DomainType.Person
    })
  }

  const organizationFields = fields.filter(
    f => f.domainType === DomainType.Organization
  )

  const chatFields = [
    {
      key: 'id-conversa',
      fieldId: 'id-conversa',
      property: 'room.key',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: true,
      fieldType: FieldType.Text
    },
    {
      key: 'primeira-mensagem-cliente',
      fieldId: 'primeira-mensagem-cliente',
      property: 'room.messages[0].comment',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: true,
      fieldType: FieldType.Text
    },
    {
      key: 'numero-conversa',
      fieldId: 'numero-conversa',
      property: 'room.number',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: true,
      fieldType: FieldType.Text
    },
    {
      key: 'nome-empresa',
      fieldId: 'nome-empresa',
      property: 'room.organization.name',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: false,
      fieldType: FieldType.Text
    },
    {
      key: 'nome-agente',
      fieldId: 'nome-agente',
      property: 'room.agent.name',
      type: 'text',
      domainType: DomainType.Chat,
      defaultOnBot: false,
      fieldType: FieldType.Text
    },
    ...fields.filter(f => f.domainType === DomainType.Chat)
  ]

  context.dispatch('setPersonFields', personFields)

  context.dispatch('setChatFields', chatFields)

  context.dispatch('setOrganizationFields', organizationFields)

  return fields
}

export const setPersonFields = (context, fields) => {
  context.commit(types.SET_PERSON_FIELDS, fields)
}

export const setChatFields = (context, fields) => {
  context.commit(types.SET_CHAT_FIELDS, fields)
}

export const setOrganizationFields = (context, fields) => {
  context.commit(types.SET_ORGANIZATION_FIELDS, fields)
}
