import { headers } from '@octadesk-tech/services'

import { createClient } from '@/modules/Chat/services/http'

const getClient = () => createClient('personsService')

export const getAllContactStatus = () => {
  return getClient()
    .then(client =>
      client.request({
        method: 'GET',
        url: '/contact-status',
        ...headers.getAuthorizedHeaders()
      })
    )
    .then(response => response.data)
}

export const getOrganizations = filters => {
  return getClient()
    .then(client =>
      client.request({
        method: 'GET',
        url: `/organizations?${filters}`,
        ...headers.getAuthorizedHeaders()
      })
    )
    .then(response => response.data)
}

export const updateOrganization = ({ id, organization }) => {
  return getClient()
    .then(client =>
      client.request({
        method: 'PUT',
        url: `/organizations/${id}`,
        data: organization,
        ...headers.getAuthorizedHeaders()
      })
    )
    .then(response => response.data)
}

export const getPerson = id => {
  return getClient()
    .then(client =>
      client.request({
        method: 'GET',
        url: `?id=${id}`,
        ...headers.getAuthorizedHeaders()
      })
    )
    .then(response => response.data)
    .catch(ex => console.error('Error in getPerson: ', ex.message))
}

export const searchPersons = email => {
  return getClient()
    .then(client =>
      client.request({
        method: 'GET',
        url: `/suggest/requesters?search=${email}&showEnabledItems=true`,
        ...headers.getAuthorizedHeaders()
      })
    )
    .then(r => r.data)
}

export const getOwners = () => {
  const filter = {
    Take: 50,
    PropertySort: 'Name',
    SortDirection: 'asc',
    ExternalQueries: [
      { PropertyName: 'Type', Operator: 2, ValueCompare: '1' },
      { PropertyName: 'RoleType', Operator: 2, ValueCompare: '1' }
    ],
    ExternalQueriesOr: [],
    includeExcludedRecords: false,
    Skip: 0
  }

  return getClient()
    .then(client =>
      client.request({
        method: 'POST',
        url: '/filter',
        data: filter,
        ...headers.getAuthorizedHeaders()
      })
    )
    .then(r => r.data)
}
