export default {
  SET_MODAL_CONTENT: 'SET_MODAL_CONTENT',
  SET_TIMEZONES_LIST: 'SET_TIMEZONES_LIST',
  SET_OFFICE_HOURS_LIST: 'SET_OFFICE_HOURS_LIST',
  SET_OFFICE_HOURS: 'SET_OFFICE_HOURS',
  CLEAR_CURRENT_OFFICE_HOURS: 'CLEAR_CURRENT_OFFICE_HOURS',
  SET_CURRENT_OFFICE_HOURS_ID: 'SET_CURRENT_OFFICE_HOURS_ID',
  SET_OFFICE_HOURS_NAME: 'SET_OFFICE_HOURS_NAME',
  SET_TIMEZONE: 'SET_TIMEZONE',
  SET_OPERATION_24_HOURS: 'SET_OPERATION_24_HOURS',
  TOGGLE_SAME_SCHEDULE: 'TOGGLE_SAME_SCHEDULE',
  SET_SAME_SCHEDULE_HOUR: 'SET_SAME_SCHEDULE_HOUR',
  ADD_SAME_SCHEDULE_HOUR: 'ADD_SAME_SCHEDULE_HOUR',
  DELETE_SAME_SCHEDULE_HOUR: 'DELETE_SAME_SCHEDULE_HOUR',
  TOGGLE_AVAILABLE_DAY: 'TOGGLE_AVAILABLE_DAY',
  ADD_NEW_HOUR_IN_DAY: 'ADD_NEW_HOUR_IN_DAY',
  SET_HOUR_IN_DAY: 'SET_HOUR_IN_DAY',
  DELETE_HOUR_IN_DAY: 'DELETE_HOUR_IN_DAY',
  TOGGLE_SPECIAL_HOURS: 'TOGGLE_SPECIAL_HOURS',
  ADD_NEW_SPECIAL_DATE: 'ADD_NEW_SPECIAL_DATE',
  DELETE_SPECIAL_DATE: 'DELETE_SPECIAL_DATE',
  SET_SPECIAL_DATE_DAY: 'SET_SPECIAL_DATE_DAY',
  UPDATE_SPECIAL_DATE_AVAILABLE: 'UPDATE_SPECIAL_DATE_AVAILABLE',
  SET_SPECIAL_DATE_HOUR_IN_DATE: 'SET_SPECIAL_DATE_HOUR_IN_DATE',
  DELETE_SPECIAL_DATE_HOUR_IN_DATE: 'DELETE_SPECIAL_DATE_HOUR_IN_DATE',
  ADD_SPECIAL_DATE_NEW_HOUR_IN_DATE: 'ADD_SPECIAL_DATE_NEW_HOUR_IN_DATE',
  SET_FORM_LOADING: 'SET_FORM_LOADING',
  CLEAR_BOTS_WITH_OFFICE_HOURS: 'CLEAR_BOTS_WITH_OFFICE_HOURS',
  SET_BOTS_WITH_OFFICE_HOURS: 'SET_BOTS_WITH_OFFICE_HOURS'
}
