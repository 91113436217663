import * as actions from './actions'
import * as getters from './getters'
import modules from './modules'
import mutations from './mutations'
import state from './state'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules
}
