import types from '@/modules/Bot/store/mutations-types/interactions/webhook'

export default {
  [types.SET_VALIDATE_INTEGRATION_LOADER](state) {
    state.validateIntegration = {
      ...state.validateIntegration,
      loader: !state.validateIntegration.loader,
      success: false,
      error: false
    }
  },
  [types.SET_VALIDATE_INTEGRATION_SUCCESS](state, validation) {
    state.validateIntegration = {
      ...state.validateIntegration,
      loader: false,
      error: false
    }

    state.validateResponse = {
      url: validation.url,
      success: validation.success,
      status: validation.status,
      headersSent: validation.headersSent,
      response: validation.response
    }

    state.validateRequest = validation.request

    setTimeout(() => {
      state.validateIntegration = {
        ...state.validateIntegration,
        success: true
      }
    }, 1000)
  },
  [types.SET_VALIDATE_INTEGRATION_ERROR](state, validation) {
    state.validateIntegration = {
      ...state.validateIntegration,
      loader: false,
      success: false
    }

    state.validateResponse = {
      url: validation.url,
      success: validation.success,
      status: validation.status,
      headersSent: validation.headersSent,
      response: validation.response
    }

    state.validateRequest = validation.request

    setTimeout(() => {
      state.validateIntegration = {
        ...state.validateIntegration,
        error: true
      }
    }, 1000)
  },
  [types.SET_VALIDATE_INTEGRATION_ERROR_DETAILS](state) {
    state.validateIntegration = {
      ...state.validateIntegration,
      loader: false,
      success: false,
      error: false
    }

    setTimeout(() => {
      state.validateIntegration = {
        ...state.validateIntegration,
        errorDetails: true
      }
    }, 1000)
  },
  [types.CLOSE_VALIDATE_INTEGRATION_MODALS](state) {
    state.validateIntegration = {
      ...state.validateIntegration,
      loader: false,
      success: false,
      error: false,
      errorDetails: false
    }
  }
}
