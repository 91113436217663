import { ActionContext } from 'vuex'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { whatsappConnectionStatus } from '@/modules/ChatSettings/whatsapp-oficial/numbers/helpers'
import { OctaBspService } from '@/modules/ChatSettings/whatsapp-oficial/numbers/services/octa-bsp.services'
import { WhatsAppIntegratorService } from '@/modules/ChatSettings/whatsapp-oficial/numbers/services/whatsapp-integrator.service'

import { RootState } from '@/store/interfaces'

import {
  WhatsAppState,
  WhatsAppNumber,
  WhatsAppNumberValidationData
} from './interfaces'
import * as types from './mutations-types'

export * from './modules/actions'

const whatsAppIntegratorService = WhatsAppIntegratorService.getInstance()

const octaBspService = OctaBspService.getInstance()

const handleParsePhoneNumber = (phoneNumber: any) => {
  if (!phoneNumber) return ''

  if (!/^\+/.test(phoneNumber)) phoneNumber = `+${phoneNumber}`

  const parsedNumber = parsePhoneNumberFromString(phoneNumber)

  return `+${
    parsedNumber!.countryCallingCode
  } ${parsedNumber!.formatNational()}`
}

const parseWppNumbersList = (wppNumbers: WhatsAppNumber[]) => {
  const remapStatus = (status: number) => {
    if (status === whatsappConnectionStatus.connecting) {
      return 1
    }

    if (status === whatsappConnectionStatus.disconnected) {
      return 0
    }

    return 2
  }

  let aux = [...wppNumbers]

  aux = aux.sort((a: WhatsAppNumber, b: WhatsAppNumber): any => {
    const remappedStatusA = remapStatus(a.status) as number

    const remappedStatusB = remapStatus(b.status) as number

    if (remappedStatusA > remappedStatusB) return 1

    if (remappedStatusA < remappedStatusB) return -1

    if (a.translate > b.translate) return 1

    if (a.translate < b.translate) return -1
  })

  return aux.map((number: any, index: any) => ({
    ...number,
    index,
    valid: true,
    translate: whatsAppIntegratorService.translateName(number.name),
    customSelect: `${whatsAppIntegratorService.translateName(
      number.name
    )} ${handleParsePhoneNumber(number.number)}`
  }))
}

export const getWhatsappOfficialNumbers = async (
  context: ActionContext<WhatsAppState, RootState>
) => {
  const integratorNumbers =
    await whatsAppIntegratorService.getIntegratorWhatsappNumbers()

  context.commit(
    types.SET_WHATSAPP_OFFICIAL_NUMBERS,
    parseWppNumbersList(integratorNumbers)
  )
}

export const updateStateWhatsappOfficialNumberName = (
  context: ActionContext<WhatsAppState, RootState>,
  { id, name }: any
) => {
  const { whatsappOfficialNumbers } = context.getters

  const updatedNumbers = [...whatsappOfficialNumbers]

  const currentIndex = whatsappOfficialNumbers.findIndex(
    (number: WhatsAppNumber) => number.id === id
  )

  if (currentIndex >= 0) {
    updatedNumbers[currentIndex].name = name

    updatedNumbers[currentIndex].translate =
      whatsAppIntegratorService.translateName(name)
  }

  context.commit(types.SET_WHATSAPP_OFFICIAL_NUMBERS, updatedNumbers)
}

export const setCompanyModel = async (
  context: ActionContext<WhatsAppState, RootState>
) => {
  const companyModel = await whatsAppIntegratorService.getCompanyModel()

  context.commit(types.SET_COMPANY_MODEL, companyModel)
}

export const createNumbersByChannelID = (
  context: ActionContext<WhatsAppState, RootState>,
  payload: any
) => whatsAppIntegratorService.createNumbersByChannelID('360dialog', payload)

export const getChatIntegrators = async () =>
  await whatsAppIntegratorService.getChatIntegrators()

export const uploadFile = (
  _: ActionContext<WhatsAppState, RootState>,
  { file, params }: any
) =>
  new Promise(resolve => {
    if (file) {
      const formData = new FormData()

      formData.append('file', file, file.name)

      whatsAppIntegratorService.uploadFile(
        {
          body: formData,
          success({ name, url }: { name: string; url: string }) {
            resolve({ name, url })
          }
        },
        params
      )
    }
  })

export const setMigrationNumberValidationData = (
  context: ActionContext<WhatsAppState, RootState>,
  payload: WhatsAppNumberValidationData
) => {
  context.commit(types.SET_MIGRATION_NUMBER_VALIDATION_DATA, payload)
}

export const setMigrationPhoneNumberId = async (
  context: ActionContext<WhatsAppState, RootState>
) => {
  try {
    const { migrationNumberValidationData } = context.getters

    const { id } = await octaBspService.migrationSendValidationNumber(
      migrationNumberValidationData
    )

    context.commit(types.SET_MIGRATION_PHONE_NUMBER_ID, id)
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const setIsWppActiveNumber = (
  context: ActionContext<WhatsAppState, RootState>,
  isWppActiveNumber: boolean
) => {
  context.commit(types.SET_IS_WPP_ACTIVE_NUMBER, isWppActiveNumber)
}

export const setMigrationNumber = (
  context: ActionContext<WhatsAppState, RootState>,
  number: WhatsAppNumber
) => {
  context.commit(types.SET_MIGRATION_NUMBER, number)
}

export const resetMigrationData = (
  context: ActionContext<WhatsAppState, RootState>
) => {
  context.commit(types.SET_MIGRATION_NUMBER, undefined)
}

export const activateNumber = (
  _: any,
  { integratorName, number }: { integratorName: string; number: string }
) => {
  return whatsAppIntegratorService.activateNumber(integratorName, number)
}
