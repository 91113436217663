import { ActionContext } from 'vuex'

import { getDomainFields } from '@/modules/ChatSettings/general/event-handler/event-handlers.service'

import { DomainType } from '@/common/helpers/enums/domain-type.enum'

import { RootState } from '@/store/interfaces'

import { EventHandlerState } from './interfaces'
import * as types from './mutations-types'

export const setDomainFields = async (
  context: ActionContext<EventHandlerState, RootState>,
  domainType: DomainType
) => {
  const fields = await getDomainFields(domainType)

  context.commit(types.SET_EVENT_HANDLER_FIELDS, fields)
  return fields
}

export const setUnsavedChanges = (
  context: ActionContext<EventHandlerState, RootState>
) => {
  context.commit(types.SET_UNSAVED_CHANGES, true)
}

export const resetUnsavedChanges = (
  context: ActionContext<EventHandlerState, RootState>
) => {
  context.commit(types.SET_UNSAVED_CHANGES, false)
}
