import { BotActions } from '@/common/helpers/enums/bot-actions'

import { BotState } from '@/store/interfaces'

const state: BotState = {
  avatars: {
    [BotActions.Normal]: '/images/BotBuilder/animation-bot-thumb-normal.svg',
    [BotActions.InLove]: '/images/BotBuilder/animation-bot-thumb-inlove.svg',
    [BotActions.Dizzy]: '/images/BotBuilder/bot-thumb-dizzy.png',
    [BotActions.Damage]: '/images/BotBuilder/animation-bot-thumb-damaged.svg',
    [BotActions.Scare]: '/images/BotBuilder/bot-thumb-scare.png',
    [BotActions.Lookup]: '/images/BotBuilder/bot-thumb-lookup.svg',
    [BotActions.Sleepy]: '/images/BotBuilder/bot-thumb-sleepy.png',
    [BotActions.Suspicious]: '/images/BotBuilder/bot-thumb-suspicious.png',
    [BotActions.Headset]: '/images/BotBuilder/animation-bot-thumb-headset.svg'
  },
  avatarsAlt: {
    [BotActions.Normal]: 'Bot avatar',
    [BotActions.InLove]: 'Bot in Love',
    [BotActions.Dizzy]: 'Dizzy Bot',
    [BotActions.Damage]: 'Damaged bot',
    [BotActions.Scare]: 'Scared Bot',
    [BotActions.Lookup]: 'Bot looking upside',
    [BotActions.Sleepy]: 'Sleepy Bot',
    [BotActions.Suspicious]: 'Suspicious Bot',
    [BotActions.Headset]: 'Headset Bot'
  },
  flux: {},
  botId: '',
  invalidConfigurations: [],
  currentChatBot: {
    id: '',
    _id: '',
    subDomain: '',
    isEnabled: false,
    enabled: false,
    name: '',
    deleted: null,
    isDeleted: false,
    draft: {
      actions: [],
      connections: [],
      persona: []
    },
    published: {
      actions: [],
      connections: [],
      persona: []
    },
    culture: '',
    warning: false,
    version: 2,
    created: {
      by: '',
      at: ''
    }
  },
  currentChatBotFlux: {
    allConditions: true,
    botId: '',
    channel: '',
    conditions: [],
    duplicated: {
      by: {
        id: ''
      },
      originalId: '',
      warning: true
    },
    enabled: true,
    enabledAt: '',
    form: {
      id: '',
      domain: 2,
      name: '',
      description: '',
      isDefault: true
    },
    formRequired: true,
    id: '',
    isAutomation: true,
    ivr: { enabled: true, name: '', levels: [] },
    kb: { enabled: true, title: '' },
    message: {
      enabled: true,
      sender: '',
      comment: ''
    },
    name: '',
    order: 2,
    rules: [],
    statistics: {
      messages: '',
      contacts: '',
      chats: '',
      survey: '',
      bots: ''
    },
    stories: { enabled: true, medias: [] },
    subDomain: '',
    survey: { enabled: true, title: '' },
    updatedAt: '',
    version: '',
    _id: ''
  },
  currentChatBotFluxChannel: '',
  currentChatBotPersona: { name: '', thumbUrl: '', type: '', email: '' },
  currentConditionsView: '',
  hasBotUnsavedChanges: false,
  groupsWithoutConnection: {
    show: false,
    quantity: 0
  },
  emptyFields: [],
  activationChatBot: true,
  instagraPage: {
    businessAccountId: '',
    facebookPageId: 156431151084299,
    subDomain: '',
    userName: '',
    profilePicture: '',
    accessToken: '',
    name: '',
    dateCreation: '',
    id: '',
    isEnabled: true
  },
  showExitWarningModal: false,
  chatBotFluxes: []
}

export default state
