import { Chatbot, Persona } from '@/common/helpers/interfaces/chat-bot'
import {
  Chatbotfluxes,
  Conditions
} from '@/common/helpers/interfaces/chat-bot-fluxes'

import { BotState, GroupsWithoutConnection } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_BOT_ID](state: BotState, botId: string) {
    state.botId = botId
  },
  [types.UPSERT_CHATBOT_FLUX](state: BotState, chatBotFlux: any) {
    if (chatBotFlux) {
      const index = state.chatBotFluxes.findIndex(
        (flux: any) => flux.id === chatBotFlux.id
      )

      if (index >= 0) {
        state.chatBotFluxes.splice(index, 1, chatBotFlux)
      } else {
        state.chatBotFluxes = [...state.chatBotFluxes, chatBotFlux]
      }
    }
  },
  [types.SET_CURRENT_CHATBOT_PERSONA](state: BotState, persona: Persona) {
    state.currentChatBotPersona = persona
  },
  [types.SET_CURRENT_CHATBOT_FLUX_CHANNEL](
    state: BotState,
    currentChatbotFluxChannel: string
  ) {
    state.currentChatBotFluxChannel = currentChatbotFluxChannel
  },
  [types.SET_CURRENT_CHATBOT_FLUX](state: any, chatBotFlux: Chatbotfluxes) {
    if (chatBotFlux) {
      state.currentChatBotFlux = {
        ...chatBotFlux
      }

      state.currentChatBotFluxChannel = chatBotFlux.channel || 'web'
    } else {
      state.currentChatBotFlux = null

      state.currentChatBotFluxChannel = null
    }
  },
  [types.SET_INVALID_CONFIGURATION](state: BotState, payload: Array<any>) {
    state.invalidConfigurations.push({
      ...payload
    })
  },
  [types.REMOVE_INVALID_CONFIGURATION](
    state: BotState,
    configurationId: string
  ) {
    state.invalidConfigurations = state.invalidConfigurations.filter(
      config => config.id !== configurationId
    )
  },
  [types.CLEAR_INVALID_CONFIGURATION](state: BotState) {
    state.invalidConfigurations = []
  },
  [types.SET_CURRENT_CHATBOT](state: BotState, bot: Chatbot) {
    state.currentChatBot = bot
  },
  [types.SET_ACTIVATION_CHATBOT](state: BotState, status: boolean) {
    state.activationChatBot = status
  },
  [types.ADD_PAGE_CONDITION](state: BotState, pageCondition: Conditions) {
    if (state.currentChatBotFlux.conditions) {
      state.currentChatBotFlux.conditions = [
        ...(state.currentChatBotFlux.conditions || []),
        pageCondition
      ]
    }
  },
  [types.REMOVE_PAGE_CONDITION](
    state: BotState,
    conditionToRemove: Chatbotfluxes
  ) {
    state.currentChatBotFlux.conditions =
      state.currentChatBotFlux.conditions?.filter(
        c => c.id !== conditionToRemove.id
      )
  },
  [types.SET_CURRENT_CONDITIONS_VIEW](state: BotState, view: string) {
    state.currentConditionsView = view
  },
  [types.SET_BOT_UNSAVED_CHANGES](state: BotState, hasChanges: boolean) {
    state.hasBotUnsavedChanges = hasChanges
  },
  [types.SET_SHOW_EXIT_WARNING](
    state: BotState,
    showExitWarningModal: boolean
  ) {
    state.showExitWarningModal = showExitWarningModal
  },
  [types.SET_GROUPS_WITHOUT_CONNECTION](
    state: BotState,
    groupsWithoutConnection: GroupsWithoutConnection
  ) {
    state.groupsWithoutConnection = groupsWithoutConnection
  },
  [types.SET_EMPTY_FIELDS](state: BotState, emptyFields: []) {
    state.emptyFields = emptyFields
  }
}
