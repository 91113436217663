<template>
  <div>
    <octa-alert variant="info" :class="$style['reload-alert']">
      <template #title>{{ $t('reloadAlert.title') }}</template>

      <template #body>
        <div class="w-full flex flex-col gap-2">
          <octa-description size="sm">
            <span v-if="reloadLoader">
              {{ $t('reloadAlert.description.updateNow') }}
            </span>

            <span v-else>
              {{ $t('reloadAlert.description.updateIn', { countDown }) }}
            </span>
          </octa-description>

          <div class="mt-2 w-full flex justify-end items-center gap-2">
            <octa-btn
              outlined
              size="sm"
              theme="primary"
              class="w-full"
              @click="handleShowConfirmModal"
            >
              {{ $t('reloadAlert.buttons.snooze') }}
            </octa-btn>

            <octa-btn
              size="sm"
              theme="primary"
              :disabled="reloadLoader"
              :loading="reloadLoader"
              class="w-full"
              @click="handleReloadPage"
            >
              {{ $t('reloadAlert.buttons.updateNow') }}
            </octa-btn>
          </div>
        </div>
      </template>
    </octa-alert>

    <octa-modal :open="showConfirmModal" :title="$t('reloadAlert.modal.title')">
      <template #body>
        <octa-description>
          {{ $t('reloadAlert.modal.description.alert') }}
          <br />
          <br />

          <span v-if="reloadLoader">
            {{ $t('reloadAlert.modal.description.updateNow') }}
          </span>

          <span v-else>
            {{ $t('reloadAlert.modal.description.updateIn', { countDown }) }}
          </span>
        </octa-description>
      </template>

      <template #footer>
        <octa-btn outlined @click="handleSnooze">
          {{ $t('reloadAlert.modal.buttons.snooze') }}
        </octa-btn>

        <octa-btn
          :disabled="reloadLoader"
          :loading="reloadLoader"
          @click="handleReloadPage"
        >
          {{ $t('reloadAlert.modal.buttons.updateNow') }}
        </octa-btn>
      </template>
    </octa-modal>
  </div>
</template>

<script>
  import {
    OctaAlert,
    OctaDescription,
    OctaBtn,
    OctaModal
  } from '@octadesk-tech/web-components'

  export default {
    components: {
      OctaAlert,
      OctaDescription,
      OctaBtn,
      OctaModal
    },
    data() {
      return {
        oneMinute: 60,
        countDown: 0,
        showConfirmModal: false,
        reloadLoader: false
      }
    },
    watch: {
      countDown(value) {
        if (value <= 0) {
          this.handleReloadPage()
        }
      }
    },
    mounted() {
      this.setCountDown()
    },
    methods: {
      handleShowConfirmModal() {
        this.showConfirmModal = true
      },
      handleReloadPage() {
        this.reloadLoader = true

        location.reload()
      },
      handleSnooze() {
        this.countDown = this.oneMinute

        this.showConfirmModal = false
      },
      setCountDown() {
        this.countDown = this.oneMinute

        const downloadTimer = setInterval(() => {
          if (this.countDown <= 0) {
            clearInterval(downloadTimer)
          }

          this.countDown -= 1
        }, 1000)
      }
    }
  }
</script>

<style lang="scss" module>
  .reload-alert {
    @apply absolute z-50 top-2 right-2;
  }
</style>
