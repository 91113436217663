export const SET_WOZ_CONTENT_DOCUMENTS_LOADING =
  'SET_WOZ_CONTENT_DOCUMENTS_LOADING'

export const SET_WOZ_CONTENT_DOCUMENTS = 'SET_WOZ_CONTENT_DOCUMENTS'

export const SET_HAS_ANY_DOCUMENTS = 'SET_HAS_ANY_DOCUMENTS'

export const SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE =
  'SET_WOZ_CONTENT_DOCUMENTS_FOR_TABLE'

export const SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING =
  'SET_AGENT_WOZ_CONTENT_DOCUMENTS_LOADING'

export const SET_AGENT_WOZ_CONTENT_DOCUMENTS = 'SET_AGENT_WOZ_CONTENT_DOCUMENTS'

export const SET_AGENT_HAS_ANY_DOCUMENTS = 'SET_AGENT_HAS_ANY_DOCUMENTS'

export const SET_AGENT_WOZ_CONTENT_DOCUMENTS_FOR_TABLE =
  'SET_AGENT_WOZ_CONTENT_DOCUMENTS_FOR_TABLE'

export const SET_LAST_SEARCH_PARAMETERS = 'SET_LAST_SEARCH_PARAMETERS'

export const SET_AGENT_LAST_SEARCH_PARAMETERS =
  'SET_AGENT_LAST_SEARCH_PARAMETERS'
