import types from '@/modules/Bot/store/mutations-types'

const mutations = {
  [types.ADD_SESSION_CHAT_PROPERTY](state, property) {
    if (property.domain !== 'CHAT') return

    const matched = state.sessionChatProperties.find(
      p => p.name === property.name
    )

    if (!matched) {
      state.sessionChatProperties = [...state.sessionChatProperties, property]
    }
  },
  [types.SET_ALLOWED_VALUES](
    _,
    { allowedValuesByFieldName, fixedPersonProperties }
  ) {
    for (const fieldData of allowedValuesByFieldName) {
      const fixedField = fixedPersonProperties.find(
        f => f.name === fieldData.fieldName
      )
      fixedField.resolvedAllowedValues = (fieldData.allowedValues || [])
        .filter(s => s.isEnabled)
        .map(status => {
          return { key: status.id, value: status.name }
        })
    }
  }
}

export default {
  ...mutations
}
