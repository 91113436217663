export const MESSAGE_TYPES = {
  public: 'public',
  internal: 'internal',
  automatic: 'automatic'
}

export const MESSAGE_TABS = {
  public: 0,
  internal: 1,
  woz: 2
}
