import { RoleTypes } from '@/common/helpers/enums/role-types'

const COMPARE_CHANNELS = 'compare-channels'

export default {
  path: 'bot',
  name: 'bot',
  component: () => import('@/views/Bot.vue'),
  redirect: '/bot/web',
  children: [
    {
      name: 'bot-whatsapp',
      path: 'whatsapp',
      component: () => import('@/modules/Bot/components/bot-list/List.vue'),
      meta: {
        channelName: 'whatsapp',
        permissionLevel: RoleTypes.Admin
      }
    },
    {
      name: 'bot-web',
      path: 'web',
      component: () => import('@/modules/Bot/components/bot-list/List.vue'),
      meta: {
        title: 'Bot Web',
        channelName: 'web',
        permissionLevel: RoleTypes.Admin
      }
    },
    {
      name: 'bot-messenger',
      path: 'messenger',
      component: () => import('@/modules/Bot/components/bot-list/List.vue'),
      meta: {
        channelName: 'facebook-messenger',
        permissionLevel: RoleTypes.Admin
      }
    },
    {
      name: 'bot-instagram',
      path: 'instagram',
      component: () => import('@/modules/Bot/components/bot-list/List.vue'),
      meta: {
        channelName: 'instagram',
        permissionLevel: RoleTypes.Admin
      }
    },
    {
      name: COMPARE_CHANNELS,
      path: COMPARE_CHANNELS,
      component: () =>
        import('@/modules/Bot/components/bot-compare/BotCompare.vue'),
      meta: {
        permissionLevel: RoleTypes.Admin
      }
    },
    {
      name: 'bot-templates',
      path: 'templates/:channelName?',
      component: () =>
        import('@/modules/Bot/components/bot-templates/BotTemplates.vue')
    },
    {
      name: 'bot-edit',
      path: ':botFluxId',
      props: (route: any) => ({
        botFluxId: route.params.botFluxId
      }),
      component: () =>
        import('@/modules/Bot/components/bot-content/bot-main/BotContent.vue')
    },
    {
      name: 'bot-activation',
      path: ':botFluxId/activation',
      props: (route: any) => ({
        botFluxId: route.params.botFluxId
      }),
      component: () =>
        import('@/modules/Bot/components/bot-content/bot-main/BotContent.vue')
    }
  ]
}
