import { AxiosInstance } from 'axios'

import { getClient } from './http'
import legacyHeaders from './legacy-headers'

export class WhatsappIntegratorService {
  getClient(): Promise<AxiosInstance> {
    return getClient('chatIntegratorUrl')
  }

  async getIntegratorWhatsappNumbers() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get('/whatsapp/numbers', {
          ...legacyHeaders()
        })
      )

      return data
    } catch (e) {
      console.error(e)
    }
  }
}
