import { AxiosInstance } from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import Storage from '@octadesk-tech/storage'

import { STATUS_CODE } from '@/common/helpers/enums/http-status-codes'
import { User } from '@/common/helpers/interfaces/user'

import store from '@/store'

import { getNucleusAuthClient, getNucleusClient } from './nucleus'
import { getAuthState } from './storage'

dayjs.extend(utc)

export default class UsersService {
  private http: AxiosInstance
  private httpAuth: AxiosInstance
  private tenantId?: string
  private accessToken?: string
  private headers: object

  constructor() {
    this.http = getNucleusClient()
    this.httpAuth = getNucleusAuthClient()

    const authState = getAuthState()

    this.tenantId = authState?.octaAuthenticated?.tenantId

    this.accessToken = authState?.access_token

    this.headers = {
      Authorization: `Bearer ${this.accessToken}`
    }
  }

  async getUsers(search: { keyword?: string }) {
    try {
      let url = 'usersmanagement/users'
      if (search?.keyword?.length) {
        url += `?keyword=${search.keyword}`
      }

      const headers = this.headers

      const { data } = await this.http.get(url, { headers })

      return data
    } catch (ex) {
      throw new Error('Error in get users', { cause: ex })
    }
  }

  async getUser(userId: string) {
    try {
      const url = `usersmanagement/users/${userId}`

      const headers = this.headers

      const { data } = await this.http.get(url, { headers })

      if (data) Storage.setItem('nucleusTenantUser', data)

      return data
    } catch (ex) {
      if (ex) {
        console.error('Error in get user: ' + ex)
      }
      return Storage.getItem('nucleusTenantUser')
    }
  }

  async getSelfUser() {
    try {
      const url = `usersmanagement/users/self`

      const headers = this.headers

      const { data } = await this.http.get(url, { headers })

      if (data) Storage.setItem('nucleusTenantUser', data)

      return data
    } catch (ex) {
      if (ex) {
        console.error('Error in get self user: ' + ex)
      }
      return Storage.getItem('nucleusTenantUser')
    }
  }

  async addUser(user: User) {
    try {
      const url = `usersmanagement/users`

      const headers = this.headers

      const { data } = await this.http.post(url, user, { headers })

      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error in add user: ' + ex)
      }
    }
  }

  async removeUser(userId: string) {
    try {
      const url = `usersmanagement/users/${userId}`

      const headers = this.headers

      const { data } = await this.http.delete(url, { headers })

      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error in remove user: ' + ex)
      }
    }
  }

  async updateUser(userId: string, payload: any) {
    try {
      const url = `usersmanagement/users/${userId}`

      const headers = this.headers

      const res = await this.http.put(url, payload, { headers })

      return res.data
    } catch (ex) {
      throw new Error('Error in update user: ' + ex)
    }
  }

  async updateSelfUser(payload: any) {
    try {
      const url = `usersmanagement/users/self`

      const headers = this.headers

      const res = await this.http.put(url, payload, { headers })

      return res.data
    } catch (ex) {
      throw new Error('Error in update user: ' + ex)
    }
  }

  async deleteSelfAvatarUrl(tenentId: any, payload: any) {
    try {
      const url = `usersmanagement/users/self/clearAvatarUrl/${tenentId}`

      const headers = this.headers

      const res = await this.http.put(url, payload, { headers })

      return res.data
    } catch (ex) {
      throw new Error('Error in delete user avatarURL: ' + ex)
    }
  }

  async getStatus(path = '') {
    try {
      const url = `billing/status`

      const headers = this.headers

      const { data } = await this.httpAuth.get(url, { headers })

      if (data) {
        Storage.setItem('statusLastCheck', dayjs().utc().format())

        Storage.setItem('nucleusStatus', data)
      }

      return data
    } catch (ex: any) {
      if (ex) {
        console.error('Error in get status: ' + ex)

        if (
          ex.response.status &&
          (ex.response.status === STATUS_CODE.UNAUTHORIZED ||
            (ex.response.status === STATUS_CODE.FORBIDDEN &&
              !path.startsWith('/billing/first-buy')))
        ) {
          await store.dispatch('logout', {
            logoutReason: 'expiredSession'
          })
        }
      }

      return Storage.getItem('nucleusStatus')
    }
  }

  async getInvoices() {
    try {
      const url = `SubscriptionManager/${this.tenantId}/invoices`

      const headers = this.headers

      const { data } = await this.http.get(url, { headers })

      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error in get invoices: ' + ex)
      }
    }
  }
}
