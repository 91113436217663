import { SurveyState, SurveyConfig } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_SURVEY_CONFIG](state: SurveyState, config: SurveyConfig) {
    state.surveyConfig = config
  },
  [types.SET_SURVEY_CONFIG_STATUS](state: SurveyState, status: boolean) {
    state.isConfigured = status
  }
}
