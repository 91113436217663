export const SET_CURRENT_RECURRENCE = 'SET_CURRENT_RECURRENCE'
export const SET_CURRENT_PLAN = 'SET_CURRENT_PLAN'
export const SET_FROM_OLD_CHECKOUT = 'SET_FROM_OLD_CHECKOUT'
export const ADD_TERM = 'ADD_TERM'
export const ADD_DISCLAIMER = 'ADD_DISCLAIMER'
export const ADD_DISCOUNT = 'ADD_DISCOUNT'
export const ADD_ITEM = 'ADD_ITEM'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const SET_COMPANY = 'SET_COMPANY'
export const SET_TERMS = 'SET_TERMS'
export const SET_TENANT = 'SET_TENANT'
export const SET_PAYMENTS = 'SET_PAYMENTS'
export const SET_TOTAL = 'SET_TOTAL'
export const SET_SUB_TOTAL = 'SET_SUB_TOTAL'
export const REMOVE_TERM = 'REMOVE_TERM'
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const CLEAR_TERMS = 'CLEAR_TERMS'
export const CLEAR_ITEMS = 'CLEAR_ITEMS'
export const CLEAR_DISCOUNT = 'CLEAR_DISCOUNT'
export const CLEAR_TOTAL = 'CLEAR_TOTAL'
export const CLEAR_DISCLAIMERS = 'CLEAR_DISCLAIMERS'
export const SET_TYPE = 'SET_TYPE'
export const TOGGLE_TEMS = 'TOGGLE_TEMS'
export const SET_UNLOGGED_INFO = 'SET_UNLOGGED_INFO'
export const SET_TYPE_STORE = 'SET_TYPE_STORE'
export const SET_IS_FRANCHISE_CHECKOUT = 'SET_IS_FRANCHISE_CHECKOUT'
export const SET_IS_ONE_TIME = 'SET_IS_ONE_TIME'
export const SET_USER_ITEM = 'SET_USER_ITEM'
export const SET_PLAN_ITEM = 'SET_PLAN_ITEM'
export const SET_PREVIEW_ITEMS = 'SET_PREVIEW_ITEMS'
export const SET_COUPON_CODE = 'SET_COUPON_CODE'
export const SET_DISCOUNT_TOTAL = 'SET_DISCOUNT_TOTAL'
