<template>
  <octa-modal open :title="title" @dismiss="hookCancel">
    <template #body>
      <octa-description :description="message" />
    </template>

    <template #footer>
      <octa-btn v-if="cancelButton" link @click="hookCancel">Cancelar</octa-btn>

      <octa-btn v-if="secondaryAction" @click="secondaryHook" outlined>
        {{ secondaryAction }}
      </octa-btn>

      <octa-btn @click="primaryHook">{{ primaryAction }}</octa-btn>
    </template>
  </octa-modal>
</template>

<script>
  import {
    OctaModal,
    OctaDescription,
    OctaBtn
  } from '@octadesk-tech/web-components'

  export default {
    components: { OctaModal, OctaDescription, OctaBtn },
    props: {
      title: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        required: true
      },
      primaryAction: {
        type: String,
        required: true
      },
      secondaryAction: {
        type: String,
        default: undefined
      },
      secondaryHook: {
        type: Function,
        default: () => ({})
      },
      primaryHook: {
        type: Function,
        default: () => ({})
      },
      hookCancel: {
        type: Function,
        default: () => ({})
      },
      hookDismiss: {
        type: Function,
        default: () => ({})
      },
      cancelButton: Boolean
    }
  }
</script>
