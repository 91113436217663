import { AxiosInstance } from 'axios'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

export class PermissionsService {
  getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async getCommerceWppNumbers(): Promise<
    Array<{
      id: string
      name: string
      number: string
      customFields: {
        partnerChannel: {
          waba_account: {
            on_behalf_of_business_info: {
              id: string
              name: string
            }
          }
        }
      }
    }>
  > {
    try {
      const { data } = await this.getClient('chatIntegratorUrl').then(client =>
        client.get('/whatsapp/numbers', {
          ...legacyHeaders()
        })
      )

      return data
    } catch (err) {
      throw new Error('Error on get whatsapp numbers: ' + err)
    }
  }

  async linkCommerceOctaCatalogWithIntegrator({
    catalogId,
    integrationId,
    integrationName
  }: {
    catalogId: string
    integrationId: string
    integrationName: string
  }): Promise<void> {
    try {
      await this.getClient('commerce').then(client =>
        client.post(
          `integrations/catalogs/${catalogId}`,
          {
            integrationId,
            integrationName
          },
          {
            ...legacyHeaders()
          }
        )
      )
    } catch (err) {
      throw new Error('Error in link octa commerce with integrator: ' + err)
    }
  }

  async createCommerceOctaCatalog({
    name,
    integrationId,
    integrationName
  }: {
    name: string
    integrationId?: string
    integrationName?: string
  }): Promise<void> {
    const params = {
      name,
      active: true
    }

    if (integrationId) {
      Object.assign(params, {
        integration: { id: integrationId, name: integrationName }
      })
    }

    try {
      await this.getClient('commerce').then(client =>
        client.post('catalogs', params, {
          ...legacyHeaders()
        })
      )
    } catch (err) {
      throw new Error('Error in create octa commerce catalog: ' + err)
    }
  }
}
