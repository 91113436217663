export default () => ({
  filter: {},
  remove: [
    'agent._id',
    'group._id',
    'messages.mentions._id',
    'users._id',
    'channel',
    'status',
    '$or',
    'messages',
    'flux.botId',
    'botInfo.hasStarted',
    'lastMessageUserType',
    'lastMessageTimeExceeded'
  ]
})
