import { CommerceState } from './interfaces'

const state: CommerceState = {
  initialDataFetched: false,
  productsLoader: false,
  totalProducts: 0,
  catalog: undefined,
  nextProductsPage: undefined
}

export default state
