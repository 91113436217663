import { NavigationState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_NAVIGATION_PIN](state: NavigationState, value: boolean) {
    state.pinned = value
  },
  [types.SET_NAVIGATION_PIN_CLOSE](state: NavigationState, value: boolean) {
    state.pinEnabled = value
  }
}
