import { WebhookState } from './interfaces'

export const webhooks = (state: WebhookState) => state.webhooks
export const hasExistingWebhooks = (state: WebhookState) =>
  state.webhooks.length > 0
export const cannotSaveWebhooks = (state: WebhookState) =>
  !state.hasChangedWebhooksList ||
  state.webhooks.some(webhook => {
    return (
      webhook.isFirstFill ||
      Object.values(webhook.errors).some(error => {
        if (typeof error === 'boolean') {
          return error
        }
        return Object.values(error).some(Boolean)
      })
    )
  })
