import { Bot } from '@octadesk-tech/chat-bot-domain'

import {
  generateFluxTemplate,
  generateDefaultPersona
} from '@/modules/Bot/store/getters/template-models'

import i18n from '@/common/i18n'

export default (language, fluxName, channel) => {
  const flux = generateFluxTemplate(fluxName, channel)

  const persona = generateDefaultPersona(channel)

  return {
    flux,
    bot: new Bot({
      isEnabled: true,
      culture: language,
      draft: null,
      published: {
        actions: [],
        connections: [],
        persona
      },
      name: i18n?.t('bot.newChatBot.models.newBot')
    })
  }
}
