import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { headers } from '@octadesk-tech/services'

import { createClient } from '@/modules/Chat/services/http'

const getClient = () => createClient('personsService')

export const createContact = payload =>
  getClient().then(client =>
    client.post('/addPerson', payload, headers.getAuthorizedHeaders())
  )

export const recordExistingContacts = contact => {
  const payload = {
    Take: 50,
    PropertySort: 'Name',
    SortDirection: 'asc',
    ExternalQueries: [],
    ExternalQueriesOr: [],
    includeExcludedRecords: false,
    Skip: 0
  }

  if (contact?.phones?.length) {
    contact.phones.forEach(phone => {
      const parsedNumber = parsePhoneNumberFromString(phone.phoneNumber, 'BR')

      payload.ExternalQueriesOr.push({
        PropertyName: 'PhoneContacts.Number',
        Operator: 2,
        ValueCompare: parsedNumber.nationalNumber
      })
    })
  }

  if (contact?.emails?.length) {
    contact.emails.forEach(email =>
      payload.ExternalQueriesOr.push({
        PropertyName: 'Email',
        Operator: 2,
        ValueCompare: email
      })
    )
  }

  return getClient().then(client =>
    client.post('/filter', payload, headers.getAuthorizedHeaders())
  )
}

export const updateContact = payload =>
  getClient().then(client =>
    client.post('find-and-upsert', payload, headers.getAuthorizedHeaders())
  )

export const recordContactsByTerm = term =>
  getClient().then(client =>
    client.get('/getRequesterByNameOrEmail', {
      params: {
        search: term,
        showEnabledItems: false
      },
      ...headers.getAuthorizedHeaders()
    })
  )
