import i18n from '@/common/i18n'

import {
  ComponentVariableDTO,
  ComponentDTO,
  ComponentButtonDTO
} from './store/interfaces'
import {
  LanguadeCode,
  TemplateMessageComponentType,
  TemplateMessageButtonType,
  HeaderMediaTypes
} from './template-messages.enum'
import { TemplateMessagesService } from './template-messages.service'

export const reVariable =
  /<span(?:\s+[A-z]+(?:="[^"]*")?)*(?:\s+variable(?:="([^"]*)")?)*(?:\s+[A-z]+(?:="[^"]*")?)*>\1<\/span>/g

export const has3WordsForEachOfTheVariables = ({
  message,
  variables
}: {
  message: string
  variables: ComponentVariableDTO[]
}) => {
  if (!message) return true

  const removeVariables = message.replace(reVariable, '')

  const removeHtmlTags = removeVariables.replace(/(<([^>]+)>)/gi, '')

  const clearSpaces = removeHtmlTags
    .replace(/&nbsp;/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim()

  const totalWords = clearSpaces.split(/\s+/).length

  const totalVariables = variables?.length

  return totalWords >= 3 * totalVariables
}

export const parseTemplateMessageV1ToV2 = (templateMessage: any) => {
  const { name, permissions, channels, contents } = templateMessage

  const [channel] = channels

  const components: any = {}

  contents.forEach(
    (item: { culture: LanguadeCode; components: ComponentDTO }) => {
      components[item.culture.replace('-', '_')] = item.components
    }
  )

  const convertedData: any = {}

  for (const [key, value] of Object.entries(components) as any) {
    convertedData[key] = value.map((item: any) => {
      const parsedItem = item

      if (parsedItem?.buttons?.length) {
        parsedItem.buttons.forEach((button: any, index: number) => {
          parsedItem.buttons[index] = {
            ...parsedItem.buttons[index],
            type: button?.type?.toUpperCase()
          }
        })
      }

      return {
        ...parsedItem,
        message: parseMessageVariables({
          message: item?.message
        }),
        type: item.type.toUpperCase()
      }
    })
  }

  Object.keys(convertedData).forEach((lng: string) => {
    Object.values(TemplateMessageComponentType).forEach(type => {
      if (
        !convertedData[lng].find((item: ComponentDTO) => item.type === type)
      ) {
        convertedData[lng].push({
          message: '',
          type
        })
      }
    })
  })

  return {
    name,
    category: channel?.integrator.category,
    permissions,
    components: convertedData,
    channels
  }
}

export const parseMessageVariables = ({ message }: { message: string }) => {
  if (!message) return message

  return message
    .replace(/<span[^>]*>(.*?)<\/span>/g, (match, content) => {
      let variable

      if (content.trim() !== '') {
        variable = content
      } else {
        const dataTemplateVarRegex = /data-template-var="([^"]+)"/

        const matches = dataTemplateVarRegex.exec(match)

        if (matches !== null) {
          variable = matches[1]
        }
      }

      const variableClass =
        'py-0.5 px-1.5 rounded text-blue-500 text-xs bg-blue-200 border-solid'

      return `<span variable="${variable}" class="${variableClass}">${variable}</span>`
    })
    .replace(/\n/g, '<br>')
}

export const parseCancelMarketingBtn = (
  payload: any,
  parseType: 'send' | 'edit'
) => {
  const parsedPayload = { ...payload }

  Object.keys(parsedPayload.components).forEach(lang => {
    parsedPayload.components[lang].forEach(
      (item: ComponentDTO, index: number) => {
        if (
          item?.type === TemplateMessageComponentType.Buttons &&
          item?.buttons?.length
        ) {
          parsedPayload.components[lang][index].buttons =
            parsedPayload.components[lang][index].buttons.map(
              (item: ComponentButtonDTO) => {
                if (parseType === 'send') {
                  item.type = item.type.replace(
                    TemplateMessageButtonType.CancelMarketing,
                    TemplateMessageButtonType.QuickReply
                  ) as TemplateMessageButtonType
                } else {
                  const cancelMarketingMessages = Object.values(
                    i18n?.t(
                      'chatSettings.templateMessagesV2.form.templateContent.fields.buttons.button.footer.value'
                    )
                  )

                  if (cancelMarketingMessages.includes(item.value)) {
                    item.type = TemplateMessageButtonType.CancelMarketing
                  }
                }

                return item
              }
            )
        }
      }
    )
  })

  return parsedPayload
}

export const parseSetHeaderLocationLatAndLng = async (payload: any) => {
  const parsedPayload = { ...payload }

  for (const lang in payload.components) {
    for (const item of payload.components[lang]) {
      if (
        item?.type === TemplateMessageComponentType.Header &&
        item?.variables?.some(
          (variable: ComponentVariableDTO) =>
            variable?.type === HeaderMediaTypes.Location
        )
      ) {
        const { address, number, state, city } =
          item.variables[0].metadata || {}

        const fullAddress = `${address} ${number} ${city}-${state}`

        const templateMessagesService = new TemplateMessagesService()

        const [addressFound] =
          await templateMessagesService.getAddressByAddress(fullAddress)

        if (addressFound) {
          const { lat, lng } = addressFound.geometry.location

          item.variables[0].metadata!.latitude = lat

          item.variables[0].metadata!.longitude = lng
        }
      }
    }
  }

  return parsedPayload
}
