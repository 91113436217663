import { ActionContext } from 'vuex'

import NucleusService from '@/common/services/nucleus'

import {
  IUserAsResponsible,
  ITransferAndDeletePayload
} from '@/store/interfaces/nucleus-state'
import { IApiKey } from '@/store/interfaces/nucleus-state'

const nucleusService = new NucleusService()

export const getIsUserResponsible = async (
  context: ActionContext<any, any>,
  usersIds: Array<string>
): Promise<IUserAsResponsible[]> => {
  if (!usersIds?.length) return []

  const { tenantId = '' } = context.getters

  return nucleusService.getIsUserResponsible(
    tenantId,
    usersIds.map(id => `userIds=${id}`).join('&')
  )
}

export const transferContactsAndDeleteUsers = async (
  context: ActionContext<any, any>,
  transferAndDeletePayload: ITransferAndDeletePayload[]
) => {
  return nucleusService.transferContactsAndDeleteUsers(transferAndDeletePayload)
}

export const getApiKeys = async (): Promise<IApiKey[]> =>
  nucleusService.getApiKeys()

export const createApiKey = async (
  context: ActionContext<any, any>,
  payload: IApiKey
): Promise<IApiKey> => {
  const { tenantId = '' } = context.getters

  if (!payload?.tenant?.id) payload.tenant = { id: tenantId }

  return nucleusService.createApiKey(payload)
}

export const updateApiKey = async (
  context: ActionContext<any, any>,
  payload: IApiKey
): Promise<void> => {
  const { tenantId = '' } = context.getters

  if (!payload?.tenant?.id) payload.tenant = { id: tenantId }

  return nucleusService.updateApiKey(payload)
}

export const deleteApiKey = async (
  _: ActionContext<any, any>,
  apiKeyId: string
): Promise<void> => nucleusService.deleteApiKey(apiKeyId)

export const getApiBaseURL = async (): Promise<string> =>
  nucleusService.getApiBaseURL()
