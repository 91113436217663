<template functional>
  <transition
    :duration="duration"
    :enter-class="$style[`${props.transition}-enter`]"
    :leave-class="$style[`${props.transition}-leave`]"
    :leave-to-class="$style[`${props.transition}-leave-to`]"
    :enter-active-class="$style[`${props.transition}-enter-active`]"
    :leave-active-class="$style[`${props.transition}-leave-active`]"
  >
    <slot />
  </transition>
</template>

<script>
  export default {
    functional: true,
    props: {
      transition: {
        type: String,
        default: 'fade',
        validator: v =>
          [
            'fade',
            'slide-y',
            'slide-y-reverse',
            'slide-x',
            'slide-x-reverse'
          ].includes(v)
      },
      duration: {
        type: Number,
        default: 300
      }
    }
  }
</script>

<style lang="scss" module>
  .fade {
    &-enter,
    &-leave-to {
      opacity: 0;
    }
    &-enter-active {
      transition: all 0.3s ease;
    }
    &-leave {
      &-active {
        transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
      }
    }
  }
  .slide-y {
    &-enter,
    &-leave-to {
      transform: translateY(10px);
      opacity: 0;
    }
    &-enter-active {
      transition: all 0.5s ease;
    }
    &-leave {
      &-active {
        transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
      }
    }
  }

  .slide-y-reverse {
    &-enter,
    &-leave-to {
      transform: translateY(-10px);
      opacity: 0;
    }
    &-enter-active {
      transition: all 0.5s ease;
    }
    &-leave {
      &-active {
        transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
      }
    }
  }

  .slide-x {
    &-enter,
    &-leave-to {
      transform: translateX(10px);
      opacity: 0;
    }
    &-enter-active {
      transition: all 0.5s ease;
    }
    &-leave {
      &-active {
        transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
      }
    }
  }

  .slide-x-reverse {
    &-enter,
    &-leave-to {
      transform: translateX(-10px);
      opacity: 0;
    }
    &-enter-active {
      transition: all 0.5s ease;
    }
    &-leave {
      &-active {
        transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
      }
    }
  }
</style>
