import { NewPasswordState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_CURRENT_STEP](state: NewPasswordState, currentStep: number) {
    state.currentStep = currentStep
  },
  [types.SET_USER_ID](state: NewPasswordState, userId: string) {
    state.userId = userId
  },
  [types.SET_TOKEN](state: NewPasswordState, token: string) {
    state.token = token
  }
}
