import { AxiosInstance } from 'axios'

import { getClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import {
  FacebookBusinessAccounts,
  CommerceUnlinkWppCatalog
} from './store/interfaces'

interface CreateCatalog {
  name: string
  businessAccountId: string
  whatsAppBusinessAccountId?: string
  token: string
}

export class PermissionsFacebookService {
  getClient(apiName: string): Promise<AxiosInstance> {
    return getClient(apiName)
  }

  async getFacebookBusinessAccounts(
    token: string
  ): Promise<Array<FacebookBusinessAccounts> | undefined> {
    try {
      const { data } = await this.getClient('multiChannel').then(client =>
        client.get('/admin/accounts/commerce', {
          params: {
            token
          },
          ...legacyHeaders()
        })
      )

      return data.data
    } catch (err) {
      throw new Error('Error in get bussines accounts: ' + err)
    }
  }

  async createCommerceFacebookCatalog({
    name,
    businessAccountId,
    whatsAppBusinessAccountId,
    token
  }: CreateCatalog): Promise<{
    businessAccountId: string
    name: string
    externalId: string
  }> {
    try {
      const { data } = await this.getClient('multiChannel').then(client =>
        client.post(
          '/admin/accounts/commerce',
          {
            catalogs: [{ name, businessAccountId }],
            whatsAppBusinessAccountId,
            token
          },
          {
            ...legacyHeaders()
          }
        )
      )

      return data.data[0]
    } catch (err) {
      throw new Error((err as any).response.data.message)
    }
  }

  async unlinkCommerceWppCatalog(
    payload: CommerceUnlinkWppCatalog
  ): Promise<void> {
    try {
      await this.getClient('multiChannel').then(client =>
        client.delete('/admin/accounts/commerce/unlink-waba', {
          data: payload,
          ...legacyHeaders()
        })
      )
    } catch (err) {
      throw new Error('Error on unlink whatsapp catalog: ' + err)
    }
  }
}
