import { whatsappConnectionStatus } from '@/modules/ChatSettings/other-channels/whatsapp-web/whatsapp-web.enums'

import { WhatsAppWebState, WhatsAppWebNumber } from './interfaces'
import moduleMutations from './modules/mutations'
import * as types from './mutations-types'

export default {
  ...moduleMutations,
  [types.SET_WHATSAPP_WEB_NUMBERS](
    state: WhatsAppWebState,
    numbers: WhatsAppWebNumber[]
  ) {
    state.numbers = numbers
  },
  [types.UPDATE_WHATSAPP_WEB_NUMBERS_STATUS](
    state: WhatsAppWebState,
    payload: {
      queue: 'connecting' | 'disconnected' | 'connected' | 'recentConnected'
      number: WhatsAppWebNumber
    }
  ) {
    const updatedNumbers = state.numbers.map((item: any) => {
      if (item.number === payload.number.number) {
        return {
          ...item,
          status: whatsappConnectionStatus[payload.queue],
          consumer: payload.number.consumer
        }
      } else {
        return item
      }
    })

    state.numbers = updatedNumbers
  },
  [types.REMOVE_WHATSAPP_WEB_NUMBER](
    state: WhatsAppWebState,
    numberId: string
  ) {
    const currentIndex = state.numbers.findIndex(
      (number: WhatsAppWebNumber) => number.id === numberId
    )

    if (currentIndex >= 0) {
      const updatedNumbers = state.numbers.filter(
        (number: WhatsAppWebNumber) => number.id !== numberId
      )

      state.numbers = updatedNumbers
    }
  },
  [types.SET_WEB_NUMBER_STATUS](
    state: WhatsAppWebState,
    { id, status, isEnabled }: WhatsAppWebNumber
  ) {
    const currentIndex = state.numbers.findIndex(
      (number: WhatsAppWebNumber) => number.id === id
    )

    if (currentIndex >= 0) {
      const updatedNumbers = [...state.numbers]

      updatedNumbers[currentIndex].status = status

      updatedNumbers[currentIndex].isEnabled = isEnabled

      state.numbers = updatedNumbers
    }
  }
}
