import { headers } from '@octadesk-tech/services'

import { createClient } from '@/modules/Chat/services/http'

import { language } from '@/common/i18n'

const getClient = () => createClient('emailService')

const parsedHeaders = {
  headers: {
    ...headers.getAuthorizedHeaders()?.headers,
    Culture: language
  }
}

export const getEmail = async key => {
  const client = await getClient()
  return client
    .get(`/inbound/${key}`, headers.getAuthorizedHeaders())
    .then(res => res.data)
}

export const getDefaultEmailAddress = async () => {
  const client = await getClient()
  return client
    .get('/outbound/default-address', parsedHeaders)
    .then(res => res.data)
}
