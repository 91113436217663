import { headers } from '@octadesk-tech/services'

import { getChatClient } from '@/modules/Chat/services/http'

export const getBots = () =>
  getChatClient()
    .then(client => client.get('flux/bot', headers.getAuthorizedHeaders()))
    .then(res => res.data)

export const getChatBots = async () => {
  const response = await getChatClient().then(client =>
    client.get('flux/', headers.getAuthorizedHeaders())
  )

  if (response?.data) {
    return response.data.filter(flux => flux.botId)
  }

  return []
}
