import { v4 as uuid } from 'uuid'

import { ASSIGN_TO } from '@/modules/Bot/enums/assign-to.enum'
import { hasContactStatus, mountDynamicRules } from '@/modules/Bot/helpers/bot'
import * as BotService from '@/modules/Bot/services/bots'
import types from '@/modules/Bot/store/mutations-types'
import { ClientBotActionTypes } from '@/modules/Bot/store/types'

import i18n from '@/common/i18n'

/* eslint-disable */
export const setBotSidebarPreSets = async context => {
  const preSetActions = await BotService.getBotSidebarPreSets()

  await context.dispatch('getAllowedValuesFromFixedFields')

  const { userLogged, groups } = context.rootGetters

  const { personProperties, currentChatBotFlux } = context.getters

  const channel = currentChatBotFlux.channel

  let sidebarPreSets = preSetActions.map(group => {
    const ordenedActions = group.actions.sort(
      (a, b) => a.orderOnGroup - b.orderOnGroup
    )

    const generateI18nText = (i18nKey, i18nKeyByChannel) => {
      if (i18n?.t(i18nKeyByChannel) === i18nKeyByChannel) {
        return i18n?.t(i18nKey)
      } else {
        return i18n?.t(i18nKeyByChannel)
      }
    }

    const actions = ordenedActions.map(action => {
      const updatedAction = {
        ...action,
        i18nDisplayName: i18n?.t(
          `bot.newChatBot.sidebarPreSets.groups.${group.groupType}.actions.${action.displayName}`
        )
      }

      const preSetActionsI18n = 'bot.newChatBot.preSetActions'

      if (updatedAction.content && updatedAction.content.prompt) {
        const i18nKey = `${preSetActionsI18n}.${updatedAction.category}.${updatedAction.content.prompt}`

        const i18nKeyByChannel = `${i18nKey}.${channel}`

        Object.assign(updatedAction.content, {
          prompt: generateI18nText(i18nKey, i18nKeyByChannel)
        })
      }

      if (
        updatedAction.content &&
        updatedAction.content.messages &&
        updatedAction.content.messages.length
      ) {
        updatedAction.content.messages.forEach((message, index) => {
          const i18nKey = `${preSetActionsI18n}.${updatedAction.category}.${message.text}`

          const i18nKeyByChannel = `${i18nKey}.${channel}`

          updatedAction.content.messages[index].text = generateI18nText(
            i18nKey,
            i18nKeyByChannel
          )
        })
      }

      if (updatedAction.type === ClientBotActionTypes.AssignRoom) {
        let referenceValue = null

        if (updatedAction.content.referenceProperty === ASSIGN_TO.agent) {
          referenceValue = (userLogged && userLogged.id) || ''
        }

        if (
          updatedAction.content.referenceProperty === ASSIGN_TO.group &&
          groups.length === 1
        ) {
          referenceValue = groups[0].id
        }

        Object.assign(updatedAction.content, {
          referenceValue
        })
      }

      if (
        updatedAction.type === ClientBotActionTypes.Conditional &&
        updatedAction.content &&
        updatedAction.content.rules &&
        updatedAction.content.rules.length &&
        hasContactStatus({ personProperties })
      ) {
        const rules = mountDynamicRules({
          personProperties,
          rules: updatedAction.content.rules
        })

        updatedAction.content.rules = rules
      }

      return updatedAction
    })

    return {
      ...group,
      i18nGroupDisplayName: i18n?.t(
        `bot.newChatBot.sidebarPreSets.groups.${group.groupType}.groupName`
      ),
      id: uuid(),
      actions: [...actions]
    }
  })

  const { isCommerceEnabled } = context.rootGetters

  if (!isCommerceEnabled) {
    sidebarPreSets = sidebarPreSets.map(item => ({
      ...item,
      actions: item.actions.filter(
        action => action.type !== ClientBotActionTypes.CommerceCatalog
      )
    }))
  }

  context.commit(types.SET_BOT_SIDEBAR_PRE_SETS, sidebarPreSets)
}
/* eslint-enable */
