import * as types from './mutation-types'
import { WhatsAppOfficialState, Balancies } from './state'

export default {
  [types.GET_WHATSAPP_OFFICIAL_BALANCE_STARTED](state: WhatsAppOfficialState) {
    state.isLoading = true

    state.hasError = false
  },
  [types.GET_WHATSAPP_OFFICIAL_BALANCE_SUCCEED](
    state: WhatsAppOfficialState,

    balancies: Balancies
  ) {
    state.balancies = balancies

    state.isLoading = false
  },
  [types.GET_WHATSAPP_OFFICIAL_BALANCE_FAILED](state: WhatsAppOfficialState) {
    state.isLoading = false

    state.hasError = true
  },
  [types.SET_WHATSAPP_OFFICIAL_LAST_CHECK](
    state: WhatsAppOfficialState,
    lastCheck: Date | undefined
  ) {
    state.lastCheck = lastCheck
  }
}
