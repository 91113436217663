export const qrcode = ({ qrcode }: any) => qrcode

export const qrcodeError = ({ qrcodeError }: any) => qrcodeError

export const qrcodeErrorDetails = ({ qrcodeErrorDetails }: any) =>
  qrcodeErrorDetails

export const qrcodeSuccess = ({ qrcodeSuccess }: any) => qrcodeSuccess

export const autoRetries = ({ autoRetries }: any) => autoRetries

export const maxRetries = ({ maxRetries }: any) => maxRetries
