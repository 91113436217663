import { NotificationState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_NOTIFICATION_COUNT](state: NotificationState, count: number) {
    state.notificationCount = count
  },
  [types.SET_NOTIFICATION_UPDATE_LIST](
    state: NotificationState,
    update: boolean
  ) {
    state.updateNotificationList = update
  }
}
