import { recordAutocompletePublicTags } from '@/modules/Chat/components/conversation/services/public-tags'

import { PublicTag } from '@/common/helpers/interfaces/public-tag'

import { DataSourceResolver } from './interfaces'

export const chatPublicTags: DataSourceResolver = searchValue =>
  recordAutocompletePublicTags({ params: { q: searchValue } })
    .then(response => response.data)
    .then((tags: PublicTag[]) =>
      tags.map(tag => ({ id: tag.id, name: tag.name }))
    )
