export const ADD_TAG = 'ADD_TAG'

export const UPDATE_TAG = 'UPDATE_TAG'

export const REMOVE_TAG = 'REMOVE_TAG'

export const REMOVE_TAGS = 'REMOVE_TAGS'

export const SET_NEW_TAG = 'SET_NEW_TAG'

export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER'

export const SET_TAGS_COUNTS = 'SET_TAGS_COUNTS'

export const SORT_TAGS_BY_STATUS = 'SORT_TAGS_BY_STATUS'

export const SET_TAGS_CONFIRM_ACTION = 'SET_TAGS_CONFIRM_ACTION'
