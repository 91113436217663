const intervals = {
  s: (endDate, startDate) => Math.round((endDate - startDate) / 1000),
  m: (endDate, startDate) => Math.round((endDate - startDate) / 60000),
  h: (endDate, startDate) => Math.round((endDate - startDate) / 3600000),
  d: (endDate, startDate) => Math.round((endDate - startDate) / 86400000),
  w: (endDate, startDate) => Math.round((endDate - startDate) / (86400000 * 7)),
  M: (endDate, startDate) =>
    endDate.getMonth() +
    1 +
    (endDate.getFullYear() - startDate.getFullYear()) * 12 -
    (startDate.getMonth() + 1),
  y: (endDate, startDate) => endDate.getFullYear() - startDate.getFullYear()
}

function dateDiff(endDate, startDate, interval) {
  if (typeof endDate === 'string') {
    endDate = new Date(endDate)
  }

  if (typeof startDate === 'string') {
    startDate = new Date(startDate)
  }

  return intervals[interval](endDate, startDate)
}

export default dateDiff
