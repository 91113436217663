import * as types from '@/modules/Chat/components/conversation/store/mutations-types/commerce-integrations'

export default {
  [types.SET_COMMERCE_INTEGRATIONS_LOADER](state, isLoader) {
    state.commerce.integrations.loader = isLoader
  },
  [types.SET_COMMERCE_INTEGRATIONS](state, integrations = []) {
    state.commerce.integrations.items = integrations
  },
  [types.SET_COMMERCE_SELECTED_ID_INTEGRATION](state, integrationId) {
    state.commerce.integrations.selectedIntegrationId = integrationId
  }
}
