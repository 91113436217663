import { headers } from '@octadesk-tech/services'

import { WozAgent } from '@/modules/Woz/agents/store/interfaces'
import { getAiApiClient } from '@/modules/Woz/services/http'

export const getWozAgents = async () => {
  try {
    const { data } = await getAiApiClient().then(client =>
      client.get('/agents', {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )

    return data
  } catch (ex) {
    throw new Error('Error on get woz agents', { cause: ex })
  }
}

export const updateAgent = async (agent: WozAgent) => {
  try {
    const { data } = await getAiApiClient().then(client =>
      client.put(`/agents`, agent, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )
    return data
  } catch (ex) {
    throw new Error('Error on update agent', { cause: ex })
  }
}

export const toggleEnable = async (id: string, enable: boolean) => {
  try {
    await getAiApiClient().then(client =>
      client.patch(`/agents/${id}/${enable ? 'enable' : 'disable'}`, null, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )
  } catch (ex) {
    throw new Error('Error on toggleEnable', { cause: ex })
  }
}

export const getWozAgent = async (agentId: string) => {
  try {
    const { data } = await getAiApiClient().then(client =>
      client.get(`/agents/${agentId}`, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )

    return data
  } catch (ex) {
    throw new Error('Error on get woz agent', { cause: ex })
  }
}

export const deleteAgent = async (agentId: string) => {
  try {
    await getAiApiClient().then(client =>
      client.delete(`/agents/${agentId}`, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )
  } catch (ex) {
    throw new Error('Error on delete agent', { cause: ex })
  }
}
