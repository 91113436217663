export const SET_WHATSAPP_NUMBERS_LOADER = 'SET_WHATSAPP_NUMBERS_LOADER'

export const SET_WHATSAPP_NUMBERS = 'SET_WHATSAPP_NUMBERS'

export const SET_AVAILABLE_CATEGORIES_LOADER = 'SET_AVAILABLE_CATEGORIES_LOADER'

export const SET_AVAILABLE_CATEGORIES = 'SET_AVAILABLE_CATEGORIES'

export const SET_TEMPLATE_MESSAGES_LOADER = 'SET_TEMPLATE_MESSAGES_LOADER'

export const SET_TEMPLATE_MESSAGES = 'SET_TEMPLATE_MESSAGES'

export const CONFIRM_DELETE_TEMPLATE_MESSAGE = 'CONFIRM_DELETE_TEMPLATE_MESSAGE'

export const SET_LIST_PREVIEW_TEMPLATE = 'SET_LIST_PREVIEW_TEMPLATE'

export const SET_TEMPLATE_MESSAGE_STEP = 'SET_TEMPLATE_MESSAGE_STEP'

export const SET_TEMPLATE_MESSAGE_SELECTED_LANGUAGE =
  'SET_TEMPLATE_MESSAGE_SELECTED_LANGUAGE'

export const SET_TEMPLATE_MESSAGE_INITIAL_FORM_PAYLOAD =
  'SET_TEMPLATE_MESSAGE_INITIAL_FORM_PAYLOAD'

export const SET_TEMPLATE_MESSAGE_FORM_PAYLOAD =
  'SET_TEMPLATE_MESSAGE_FORM_PAYLOAD'

export const SET_TEMPLATE_MESSAGE_SHOW_DISCARD_CONTENT_ALERT =
  'SET_TEMPLATE_MESSAGE_SHOW_DISCARD_CONTENT_ALERT'

export const SET_TEMPLATE_MESSAGE_SHOW_REVIEW =
  'SET_TEMPLATE_MESSAGE_SHOW_REVIEW'

export const SET_TEMPLATE_MESSAGE_SHOW_SUCCESS_MODAL =
  'SET_TEMPLATE_MESSAGE_SHOW_SUCCESS_MODAL'

export const SET_PERSON_FIELDS = 'SET_PERSON_FIELDS'

export const SET_CHAT_FIELDS = 'SET_CHAT_FIELDS'

export const SET_ORGANIZATION_FIELDS = 'SET_ORGANIZATION_FIELDS'

export const SET_VALID_TEMPLATE_MESSAGE_NAME = 'SET_VALID_TEMPLATE_MESSAGE_NAME'
