export const SET_WHATSAPP_OFFICIAL_NUMBERS = 'SET_WHATSAPP_OFFICIAL_NUMBERS'

export const SET_COMPANY_MODEL = 'SET_COMPANY_MODEL'

export const SET_IS_WPP_ACTIVE_NUMBER = 'SET_IS_WPP_ACTIVE_NUMBER'

export const SET_MIGRATION_NUMBER = 'SET_MIGRATION_NUMBER'

export const SET_MIGRATION_NUMBER_VALIDATION_DATA =
  'SET_MIGRATION_NUMBER_VALIDATION_DATA'

export const SET_MIGRATION_PHONE_NUMBER_ID = 'SET_MIGRATION_PHONE_NUMBER_ID'
