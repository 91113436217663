import * as chatChannels from './chat-channels'
import * as commerce from './commerce'
import * as commerceIntegrations from './commerce-integrations'
import * as commerceOrders from './commerce-orders'
import * as general from './general'
import * as tags from './tags'
import * as ticket from './ticket'

export default {
  ...chatChannels,
  ...general,
  ...tags,
  ...commerce,
  ...commerceOrders,
  ...commerceIntegrations,
  ...ticket
}
