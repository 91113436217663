import axiosRetry from 'axios-retry'

import { services, url } from '@octadesk-tech/services'

const API_CLIENTS = new Map()

export const getBaseClient = async (api, options = {}) => {
  if (API_CLIENTS.has(api)) {
    return API_CLIENTS.get(api)
  }

  const client = await services.createClient(api, options)

  axiosRetry(client, { retries: 3 })

  API_CLIENTS.set(api, client)

  return client
}

let _chatClient
export const getChatClient = async () => {
  if (_chatClient) {
    return _chatClient
  }

  _chatClient = await services.chat.getClient()

  return _chatClient
}

let _chatAPIURL

export const getChatAPIURL = async () => {
  if (_chatAPIURL && _chatAPIURL.length) {
    return _chatAPIURL
  }

  _chatAPIURL = await url.getAPIURL('chatUrl')

  return _chatAPIURL
}

export const getAPIURL = async apiKey => {
  return await url.getAPIURL(apiKey)
}
