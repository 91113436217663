import { services } from '@octadesk-tech/services'
import Storage from '@octadesk-tech/storage'

import legacyHeaders from '@/common/services/legacy-headers'

export class WhatsAppIntegratorService {
  private static instance: WhatsAppIntegratorService

  public static getInstance(): WhatsAppIntegratorService {
    if (!this.instance) {
      this.instance = new WhatsAppIntegratorService()
    }

    return this.instance
  }

  _client: any

  async getClient() {
    if (this._client) {
      return this._client
    }

    if (!services['chatIntegrator']) {
      throw new Error('Cannot get http client: missing service chatIntegrator')
    }

    this._client = await services['chatIntegrator'].getClient(legacyHeaders())

    return this._client
  }

  async getIntegratorWhatsappNumbers() {
    try {
      const client = await this.getClient()

      const response = await client.get('/whatsapp/numbers')

      if (response.data) {
        return response.data
      } else {
        throw new Error('Error on getIntegratorWhatsappNumbers')
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async getChatIntegrators() {
    const integrators = Storage.getItem('chat:integrator')

    if (integrators) {
      return integrators
    }

    const res = await this.getClient().then(client =>
      client.get('/', legacyHeaders())
    )

    if (res.data) {
      Storage.setItem('chat:integrator', res.data, 3600)
    }

    return res.data
  }
}
