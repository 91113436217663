import { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import legacyHeaders from '@/common/services/legacy-headers'

export class EmailService {
  async chatClient(): Promise<AxiosInstance> {
    return (await services.chat.getClient()) as AxiosInstance
  }

  async setEmailRedirection(payload: any): Promise<any> {
    return this.chatClient().then(client =>
      client
        .post(`/emails/redirection/test`, payload, legacyHeaders())
        .then(res => res.data)
    )
  }
}
