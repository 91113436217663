export default {
  NewConversationReceived: 'new conversation received',
  MODAL_BAN: 'modal ban',
  WPP_PROACTIVE_CONVERSATION: 'whatsapp proactive conversation',
  OCTACHAT_OPENED: 'octachat opened',
  OCTAVIO_HAS_BEEN_CALLED: 'octavio has been called',
  CONVERSATION_CLOSED: 'conversation closed',
  CONVERSATION_ANSWERED: 'conversation answered',
  CONVERSATION_VIEWED: 'conversation viewed',
  CONVERSATION_TAGGED: 'conversation tagged',
  MODAL_ADMIN_TAGS: 'modal admin tags',
  CONVERSATION_MASS_TRANSFER: 'mass transfer',
  CONVERSATION_MASS_TRANSFER_FAIL: 'mass transfer fail',
  CONVERSATION_MASS_CLOSE: 'mass close',
  CONVERSATION_MASS_CLOSE_FAIL: 'mass close fail',
  CONVERSATION_FILTERS: 'conversation filters',
  COPY_CONVERSATION__ID: 'copy conversation id',
  PROACTIVE_CONVERSATION: {
    email: 'proactive email sent',
    whatsapp: 'whatsapp proactive conversation'
  },
  INTENT_TRY_CHAT: 'intent to try chat',
  FIRST_CONVERSATION_AB_DEFINED: 'first conversation ab defined'
}
