import { headers } from '@octadesk-tech/services'

import { getClient } from './http'
import legacyHeaders from './legacy-headers'

const API_NAME = 'chatBotsUrl'
const API = 'chatUrl'

export default class BotsServices {
  getClient() {
    return getClient(API_NAME)
  }
  getClientChat() {
    return getClient(API)
  }

  getBot(botId: string) {
    return this.getClient()
      .then(client => client.get(`${botId}`, legacyHeaders()))
      .then(res => res.data)
  }

  async uploadImage({ file }: { file: any }): Promise<string> {
    try {
      const formData = new FormData()

      formData.append('file', file)

      const client = await this.getClientChat()

      const { data } = await client.post(
        '/upload',
        formData,
        headers.getAuthorizedHeaders()
      )

      return data.url
    } catch (ex) {
      throw new Error('Error in upload product image: ' + ex)
    }
  }
}
