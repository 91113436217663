import {
  WozPreferencesState,
  WozTranscriptPreferences,
  WozAgentPreferences,
  WozSummaryPreferences
} from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_WOZ_PREFERENCES_LOADING](
    state: WozPreferencesState,
    loading: boolean
  ) {
    state.fetchLoading = loading
  },
  [types.SET_WOZ_PREFERENCES_ERROR](
    state: WozPreferencesState,
    error: boolean
  ) {
    state.fetchError = error
  },
  [types.SET_WOZ_TRANSCRIPT_PREFERENCES](
    state: WozPreferencesState,
    preferences: WozTranscriptPreferences
  ) {
    state.preferences.transcript = preferences
  },
  [types.SET_WOZ_AGENT_PREFERENCES](
    state: WozPreferencesState,
    preferences: WozAgentPreferences
  ) {
    state.preferences.agent = preferences
  },
  [types.SET_WOZ_SUMMARY_PREFERENCES](
    state: WozPreferencesState,
    preferences: WozSummaryPreferences
  ) {
    state.preferences.summary = preferences
  }
}
