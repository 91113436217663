import { Bot } from '@octadesk-tech/chat-bot-domain'

import {
  generateFluxTemplate,
  generateDefaultPersona,
  proActiveMessage
} from '@/modules/Bot/store/getters/template-models'

import i18n from '@/common/i18n'

import actions from './bot-actions'
import connections from './bot-connections'

export default (getPersonProperty, userLogged, language, fluxName, channel) => {
  const flux = generateFluxTemplate(fluxName, channel)

  const i18nTemplatesKey = 'bot.newChatBot.templates.onboardingTemplate'

  if (channel === 'web') {
    flux.message = proActiveMessage(
      i18n?.t(`${i18nTemplatesKey}.proActiveMessage`)
    )
  }

  const persona = generateDefaultPersona(channel)

  return {
    flux,
    bot: new Bot({
      isEnabled: true,
      draft: {},
      culture: language,
      published: {
        actions: actions(
          i18nTemplatesKey,
          channel,
          getPersonProperty,
          userLogged
        ),
        connections: connections(channel),
        persona
      },
      name: i18n?.t('bot.newChatBot.models.newBot')
    })
  }
}
