import i18n from '@/common/i18n'

import { DataSourceResolver } from './interfaces'

export const contactsEvents: DataSourceResolver = () =>
  Promise.resolve([
    {
      id: 'created',
      name: i18n?.t('dataSources.contactsEvents.contact-created') as string
    },
    {
      id: 'updated',
      name: i18n?.t('dataSources.contactsEvents.contact-updated') as string
    }
  ])
