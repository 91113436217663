const STATUS_CODES = {}

STATUS_CODES[(STATUS_CODES.OK = 200)] = 'OK'
STATUS_CODES[(STATUS_CODES.CREATED = 201)] = 'Created'
STATUS_CODES[(STATUS_CODES.ACCEPTED = 202)] = 'Accepted'
STATUS_CODES[(STATUS_CODES.BAD_REQUEST = 400)] = 'Bad Request'
STATUS_CODES[(STATUS_CODES.FORBIDDEN = 403)] = 'Forbidden'
STATUS_CODES[(STATUS_CODES.NOT_FOUND = 404)] = 'Not Found'
STATUS_CODES[(STATUS_CODES.METHOD_NOT_ALLOWED = 405)] = 'Method Not Allowed'
STATUS_CODES[(STATUS_CODES.CONFLICT = 409)] = 'Conflict'
STATUS_CODES[(STATUS_CODES.INTERNAL_SERVER_ERROR = 500)] = 'Server Error'
STATUS_CODES[(STATUS_CODES.BAD_GATEWAY = 502)] = 'Bad Gateway'
STATUS_CODES[(STATUS_CODES.GATEWAY_TIMEOUT = 504)] = 'Gateway Timeout'

export default STATUS_CODES
