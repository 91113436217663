import { TemplateMessagesState } from './interfaces'

const state: TemplateMessagesState = {
  createMessageModalIsOpen: false,
  createMessageStep: 0,
  createMessageModel: undefined,
  createMessagePayload: undefined,
  createMessageError: undefined,
  createMessageNumber: undefined,
  createMessageSuccess: undefined,
  createMessageConfirm: undefined,
  selectedMessage: undefined,
  templateMessages: [],
  templateMessagesIsLoaded: false,
  personFields: [],
  chatFields: [],
  createCustomFieldLoaderActive: false
}

export default state
