export enum OperatorTypes {
  IS_LESS_THAN_EQUAL = 1,
  EQUALS = 2,
  IS_GREATER_THAN_EQUAL = 3,
  CONTAINS = 4,
  IS_NOT_EQUALS = 5,
  IN = 6,
  IS_LESS_THAN = 7,
  IS_MORE_THAN = 8,
  NOT_IN = 9,
  LESS_THAN = 12,
  GREATER_THAN = 13,
  NOT_CONTAINS = 17
}
