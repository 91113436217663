export enum TemplateStatus {
  Pending = 'pending',
  Submitted = 'submitted'
}

export enum FormTypes {
  Create = 'create',
  Update = 'update'
}

export enum FormSteps {
  BasicInformations = 'BasicInformationsContainer',
  TemplateContentContainer = 'TemplateContentContainer'
}

export const OptionsPermissions = {
  AllGroups: 'all-groups',
  AllAgents: 'all-agents',
  Everyone: 'everyone'
}

export enum TemplateCategories {
  Marketing = 'MARKETING',
  Utility = 'UTILITY',
  Authentication = 'AUTHENTICATION'
}

export enum HeaderTypes {
  None = 'none',
  Text = 'text',
  Media = 'media'
}

export enum HeaderMediaTypes {
  Image = 'image',
  Video = 'video',
  Document = 'document',
  Location = 'location'
}

export enum TemplateButtonTypes {
  None = 'none',
  CallToAction = 'call-to-action',
  CancelMarketing = 'cancel-marketing',
  Custom = 'custom',
  QuickAnswer = 'quick-answer'
}

export enum TemplateMessageComponentType {
  Header = 'HEADER',
  Body = 'BODY',
  Footer = 'FOOTER',
  Buttons = 'BUTTONS'
}

export enum LanguadeCode {
  PT_BR = 'pt_BR',
  EN_US = 'en_US'
}

export enum TemplateMessageButtonType {
  PhoneNumber = 'PHONE_NUMBER',
  Url = 'URL',
  Authentication = 'OTP',
  QuickReply = 'QUICK_REPLY',
  CancelMarketing = 'CANCEL_MARKETING'
}

export enum TemplateMessageButtonUrlType {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC'
}

export const DomainType = {
  Ticket: 1,
  Person: 2,
  Product: 3,
  Chat: 4,
  Organization: 5
}

export const FieldType = {
  Text: 1,
  Number: 5,
  Decimal: 6,
  Date: 7
}

export const Channels = {
  WhatsApp: 'whatsapp'
}

export enum VariablesProperty {
  Generic = 'generic'
}

export enum LocationFields {
  Name = 'name',
  ZipCode = 'zipCode',
  Address = 'address',
  Number = 'number',
  Neighborhood = 'neighborhood',
  State = 'state',
  City = 'city',
  Complement = 'complement',
  Latitude = 'latitude',
  Longitude = 'longitude'
}
