import { RoleTypes } from '@/common/helpers/enums/role-types'

export default {
  path: 'campaigns',
  name: 'campaigns',
  redirect: '/campaigns/meta-ads',
  component: () => import('@/views/Adverts.vue'),
  children: [
    {
      path: 'meta-ads',
      name: 'meta-ads',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      component: () =>
        import('@/modules/Adverts/meta-ads/components/MetaAds.vue')
    },
    {
      path: 'bulk-messages',
      name: 'automation-studio-campaigns-bulk-messages-v2',
      redirect: '/automation-studio/campaigns/bulk-messages/list',
      component: () =>
        import(
          '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/components/BulkMessages.vue'
        ),
      children: [
        {
          path: 'list',
          name: 'automation-studio-campaigns-bulk-messages-v2-list',
          component: () =>
            import(
              '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/components/list/BulkMessagesList.vue'
            )
        },
        {
          path: 'create',
          name: 'automation-studio-campaigns-bulk-messages-v2-create',
          component: () =>
            import(
              '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/components/form/BulkMessageForm.vue'
            ),
          meta: {
            formType: 'create'
          }
        },
        {
          path: 'update/:id',
          name: 'automation-studio-bulk-messages-v2-update',
          component: () =>
            import(
              '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/components/form/BulkMessageForm.vue'
            ),
          meta: {
            formType: 'update'
          }
        }
      ]
    }
  ]
}
